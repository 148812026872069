import  {Component} from 'react';
import api from './api';
import {ToastStore} from 'react-toasts';
import Moment from 'moment';

// import {Polyline} from 'google-maps-react';

export default class GetNotificacao extends Component{
    constructor(MapaKseg){
        super(MapaKseg);
        this.state = {
            MapaKseg:MapaKseg,
            count_nao_visualizado: 0,
            carregandoMaisNotificacoes: false,
            list:[],
            ultimo_notificacao_envio_id:0,
            nenhuma_notificacao:false,
            loading:true,
       }
    }
    //---------------------
    toastStore = (texto) => {
        ToastStore.success("teste: "+texto);
    };
    //--------------------
    getNotificacoes = async () => {
        try {
            this.setState({count_nao_visualizado: 0});
            this.state.MapaKseg.setState({count_nao_visualizado: 0});

            if(this.state.ultimo_notificacao_envio_id !== 0)
                this.setState({carregandoMaisNotificacoes:true});
            let body = new FormData()
            body.append('usuario_id', this.state.MapaKseg.state.usuario_id);
            body.append('notificacao_envio_id', this.state.ultimo_notificacao_envio_id);
            body.append('token', localStorage.getItem('token'));

            const response = await api.post('/mensagem_api/getNotificacoes', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                var list = this.state.list;  
                var notificacao={};
                var count_nao_visualizado=0;
                // console.log("resposta:");
                // console.log(resposta);
                // alert(resposta.length);
                for (var i = 0; i < resposta.length; i++) {
                        notificacao = {  
                            notificacao_envio_id : resposta[i].notificacao_envio_id,
                            veiculo_id : resposta[i].veiculo_id,                            
                            tipo_notificacao_id : resposta[i].tipo_notificacao_id,                        
                            enviado: resposta[i].enviado,
                            data_envio : resposta[i].data_envio,
                            mensagem : resposta[i].mensagem,
                            visualizado : resposta[i].visualizado,
                            index_scroll:0
                        };
                        // console.log("---------->");
                        // console.log(notificacao.visualizado);
                        if(notificacao.visualizado === false)
                            count_nao_visualizado+= 1;

                        list.push(notificacao);   
                        // alert(list.length);
                        this.setState({list: list});  
                    }    
                if(resposta.length>0)    
                    this.setState({ultimo_notificacao_envio_id: resposta[resposta.length-1].notificacao_envio_id});
                else this.setState({nenhuma_notificacao:true});
                }       
            

        } finally {
            this.setState({loading: false, carregandoMaisNotificacoes:false});     
            this.state.MapaKseg.setState({count_nao_visualizado: count_nao_visualizado});
            // alert(this.state.count_nao_visualizado);

            }
    };
    //--------------------------
    async componentDidMount() {
        // this.getNotificacoes();
    };
}