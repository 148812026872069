import React, {Component} from 'react';
import {Modal, Image, Spinner, Button, Form, Row,Col,Card,Nav,InputGroup,FormControl} from 'react-bootstrap';
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import GetPessoa from '../services/get_pessoa';
import GetFilial from '../services/get_filial';
import GetMunicipio from '../services/get_municipio';
import GetFilialUsuarioCad from '../services/get_filial_usuario_cad';
import GetFilialRotas from '../services/get_filial_rotas';
import PostFilialCad from '../services/post_filial_cad';
import {UsuariosGrupoGrid} from '../datagrid/usuarios_grupo_grid';
import {FilialRotaGrid} from '../datagrid/filial_rota_grid';
import NumberBox from 'devextreme-react/number-box';

const PESSOA =1;
const MUNICIPIO =2;

export class FilialCadModal extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            load:true,
            minimizar:false,
            changed:false,
            filial:null,

            GeralListModal:null,            
            FilialGrid:null,

            height_modal:520,
            height_grid:0,
            items:[],
            editados: {},
            grupo:{},

            salvando:false,            
        };

         // -------------------------------
        this.dataGridRef = React.createRef();
    
        this.selectedRowsData = [];

        this.getSelectedData = () => {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData();
            this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            });
        }

        this.setPercentualComissao = this.setPercentualComissao.bind(this);
      }
    //---------------------------------------------------------------
    get dataGrid() {
        return this.dataGridRef.current.instance;
    }
    //-------------------------------------------------------------------
    async componentDidMount() {
        // INICIALIZAR VARIÁVEIS

        this.setState({FilialGrid: this.props.objeto});

        let editados = {
              descricao:false
            , municipio:false
            , pessoa: false
            , usuarios: false
            , rotas: false
            , comissao: false
             };

        let filial = this.props.obj_selected;
        let grupo = {};        
        grupo.usuarios = [];
        grupo.rotas = [];

        this.setState({
                filial: filial   
                ,grupo:grupo    
                ,editados: editados        
                , height_grid: this.state.height_modal-370},
            () => { 
                if(filial.filial_id != 0){
                    this.getFilialUsuarios()
                    this.getFilialRotas()
                }
                this.setState({load:false}) }
          )
    }; 
    //----------------------------------------------------------------
    getFilial = () =>{
        var instance = new GetFilial(null, this, this.props.MapaKseg,0, this.state.filial.filial_id );
        instance.get();   
    };    
    //----------------------------------------------------------------
    getFilialUsuarios = () =>{
        var instance = new GetFilialUsuarioCad(null, this, this.props.MapaKseg, this.state.filial.filial_id );
        instance.get();   
    }; 
    //----------------------------------------------------------------
    getFilialRotas = () =>{
        var instance = new GetFilialRotas(null, this, this.props.MapaKseg, 0, this.state.filial.filial_id );
        instance.get();   
    }; 
    //--------------------------------------------------------------------
    setListFilial = (_list) => {
        let filial = this.state.filial;
        let obj = _list[0];

        filial.filial_id = obj.filial_id;
        filial.descricao = obj.descricao;
        filial.municipio_id = obj.municipio_id;
        filial.municipio = obj.municipio;
        filial.pessoa_id = obj.pessoa_id;
        filial.nome_social = obj.nome_social;

        this.setState({filial: filial});
    } 
    //------------------------------------------------------------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closeFilialCadModal();  
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeFilialCadModal();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarFilialCadModal();
    };    
    //------------------------------------------------------
    updateInputValue(event) {
        var editados = this.state.editados;
        var filial = this.state.filial;

        if(event.target.id==="formDescricao"){
            filial.descricao = event.target.value;
            editados.descricao = true;
        }else 
        if(event.target.id==="formMunicipio"){
            filial.municipio_id = event.target.value;
            editados.municipio = true;
        }else 
        if(event.target.id==="formPessoa"){
            filial.pessoa_id = event.target.value;
            editados.pessoa = true;
        }

        this.setState({filial: filial});
        this.setState({editados: editados, changed: true});
     }
         //-------------------------------------------------------------------
    showGeralList= (e) => {   
        if(e.target.getAttribute('value') == PESSOA)
            this.props.Modals.showGeralList(this,"Pessoa", PESSOA);      
        else
        if(e.target.getAttribute('value') == MUNICIPIO)
            this.props.Modals.showGeralList(this,"Município", MUNICIPIO);     
    };
    //-----------------------------------------------------
    getList= (geralListModal, tipo) => { 
        this.setState({GeralListModal: geralListModal});  

        if(tipo == PESSOA){             
            var instance = new GetPessoa(null, this, this.props.MapaKseg, PESSOA, 0);
            instance.get(); 
        }else
        if(tipo == MUNICIPIO){             
            var instance = new GetMunicipio(null, this, this.props.MapaKseg, MUNICIPIO, 0);
            instance.get(); 
        }
    };
    //-------------------------------------------------------------
    setListMunicipio =  (_list, tipo) => {  
        let array =[]
        let sel=false;
        for (var i = 0; i < _list.length; i++) { 
            if(this.state.filial.municipio_id == _list[i].municipio_id) sel=true;
            else sel=false;

            array.push({
                index: _list[i].index
                , id: _list[i].municipio_id
                , descricao: _list[i].descricao
                , selecionada: sel
                , index_scroll: 0});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //-------------------------------------------------------------
    setListPessoa =  (_list, tipo) => {  
        let array =[]
        let sel=false;
        for (var i = 0; i < _list.length; i++) { 
            if(this.state.filial.pessoa_id == _list[i].pessoa_id) sel=true;
            else sel=false;

            array.push({
                index: _list[i].index
                , id: _list[i].pessoa_id
                , descricao: _list[i].nome_social
                , selecionada: sel
                , index_scroll: 0});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //---------------------------------------------------
    setObjeto= (objeto, tipo) => { 
        if(tipo == PESSOA){
            let filial = this.state.filial;
            filial.pessoa_id = objeto.id;
            filial.nome_social = objeto.descricao;            
            this.setState({filial: filial});            

            let editados =this.state.editados;
            editados.pessoa =  true;
            this.setState({editados: editados });
        }else
            if(tipo == MUNICIPIO){
                let filial = this.state.filial;
                filial.municipio_id = objeto.id;
                filial.municipio = objeto.descricao;            
                this.setState({filial: filial});            

                let editados =this.state.editados;
                editados.municipio =  true;
                this.setState({editados: editados });
            }

        this.setState({changed:true});
    };
    //-------------------------------------------------------------
    setListUsuarios =  (_list) => {  
        let grupo = this.state.grupo;
        grupo.usuarios = _list;
        this.setState({grupo:grupo});
    }; 
    //-------------------------------------------------------------
    setListFilialRotas =  (_list) => {  
        let grupo = this.state.grupo;
        grupo.rotas = _list;
        this.setState({grupo:grupo});
    }; 
    //--------------------------------------------------------------
    setPercentualComissao (event) {
        let filial = this.state.filial;

        filial.comissao = event.value;
        this.setState({filial: filial});

        let editados = this.state.editados;
        editados.comissao = true;
        this.setState({editados: editados, changed: true});

        console.log(filial.comissao);
        // this.props.MapaKseg.toastError(filial.comissao);
    }
    //--------------------------------------
    concluido=()=> {
        this.getFilial();
        this.getFilialUsuarios();
        this.setState({salvando:false, changed:false });     
        this.state.FilialGrid.setFilial(this.state.filial);  
    }
    //--------------------------------------
    validar=()=> {
        let valido = true;

        if(this.state.filial.comissao=='' && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe a Comissão!");
        } 

        if(String(this.state.filial.descricao).trim()==="" && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe a Filial!");
        }

        if(this.state.filial.municipio_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Município!");
        }       

        if(this.state.filial.pessoa_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe a Pessoa!");
        }    
    
        if(valido==true){
            this.setState({salvando:true, changed:true});
            var instance = new PostFilialCad(null, this, this.props.MapaKseg);
            instance.post(); 
        }
    }
    //-------------------------
    render(){
        return(
            <div className="drag1">
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={600}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.load ?
                                        <Spinner className="centro3" animation="border" role="status">
                                            <span className="sr-only"></span>
                                        </Spinner>
                                    :
                                        <div style={{height: this.state.height_modal, overflow:'hidden'}}>
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                                                {/*------------------------------------ */}
                                                <Form>                                                       
                                                    <Form.Group className="mb-1" as={Row} controlId="formDescricao">
                                                        <Form.Label column sm={3}>
                                                            Filial
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>{this.state.filial.filial_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.filial.descricao}  onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>        

                                                    <Form.Group className="mb-1" as={Row} controlId="formMunicipio">
                                                        <Form.Label column sm={3}>
                                                        Município
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formMunicipioBt" value={MUNICIPIO} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                        <Image value={MUNICIPIO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                    </Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text onChange={this.updateInputValue.bind(this)} >{this.state.filial.municipio_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control disabled aria-describedby="basic-addon1" value={this.state.filial.municipio}  />
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>                             
                            
                                                    <Form.Group className="mb-1" as={Row} controlId="formPessoa">
                                                        <Form.Label column sm={3}>
                                                        Pessoa
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formPessoaBt" value={PESSOA} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                        <Image value={PESSOA} src={require('../img/text_box_search48.png')} width='20'/>
                                                                    </Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text onChange={this.updateInputValue.bind(this)} >{this.state.filial.pessoa_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control disabled aria-describedby="basic-addon1" value={this.state.filial.nome_social}  />
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>        

                                                    <Form.Group as={Row} controlId="formComissao">
                                                        <Form.Label column sm={3}>
                                                        Comissão
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-1" >
                                                                <NumberBox
                                                                    format="#0%"
                                                                    width={60}
                                                                    defaultValue={this.state.filial.comissao}
                                                                    onValueChanged={this.setPercentualComissao}
                                                                    valueChangeEvent="input"/>                                                        
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>                                  
                                        
                                                    {/* ----------------Tab---------------------------------------------------- */}
                                                    <Card>
                                                        <Card.Header>
                                                            <Nav variant="tabs" defaultActiveKey="#Rotas">                                                  
                                                                <Nav.Item >
                                                                    <Nav.Link  data-toggle="tab" href="#Rotas">Rotas</Nav.Link>
                                                                </Nav.Item>     
                                                                <Nav.Item >
                                                                    <Nav.Link  data-toggle="tab" href="#Usuarios">Usuários</Nav.Link>
                                                                </Nav.Item>                                                                                                                                            
                                                            </Nav>
                                                        </Card.Header>

                                                        <div class="tab-content"> 
                                                            <Card.Body id="Rotas" className="tab-pane active">
                                                                <FilialRotaGrid  
                                                                    objeto={this}
                                                                    height_grid={this.state.height_grid} 
                                                                    MapaKseg={this.props.MapaKseg} 
                                                                    Modals={this.props.Modals}/>                                                                                                                                   
                                                            </Card.Body>   

                                                            <Card.Body id="Usuarios" className="tab-pane fade">
                                                                <UsuariosGrupoGrid  
                                                                    objeto={this}
                                                                    height_grid={this.state.height_grid} 
                                                                    MapaKseg={this.props.MapaKseg} 
                                                                    Modals={this.props.Modals}/>                                                                                                                                   
                                                            </Card.Body>                                                                                                                                                                          
                                                        </div>
                                                    </Card>  
                                        
                                                                                                                                                          

                                                </Form>
                                                {/* ----------------------------------- */}
                                            </CustomScroll>


                                            <Modal.Footer >
                                                <div className="align_bottom_right">
                                                    <Button disabled={!this.state.changed} onClick={this.validar}>
                                                        {this.state.salvando
                                                            ? <Spinner size="sm"  as="span" animation="grow" role="status" aria-hidden="true"/>                                                                
                                                        :
                                                        <div>
                                                            <img src={require('../img/checkbold24.png')}/> Salvar 
                                                        </div>
                                                        }                                                                
                                                    </Button>
                                                </div> 
                                            </Modal.Footer>

                                        </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}