import  {Component} from 'react';
import api from '../api';

export default class PostMunicipioCad extends Component{
    constructor(props, objeto, MapaKseg ){
        super(props);
        this.state = {
             objeto: objeto,
             MapaKseg: MapaKseg
       }
    }
    //------------------------------------------------
    post = async () => {
        let municipio_idd =0;
        try {                    
            let editados = this.state.objeto.state.editados;
            let municipio = this.state.objeto.state.municipio;
            municipio_idd = municipio.municipio_id;

            let response = null;
            let responseJson = null;
            let body =null;            


            if(editados.descricao || editados.codigo || editados.estado || editados.local || editados.tda){

                body = new FormData();
                body.append('token', localStorage.getItem('token'));
                body.append('municipio_id', municipio.municipio_id);

                if(editados.descricao) 
                    body.append('descricao', municipio.descricao);
                if(editados.codigo) 
                    body.append('codigo', municipio.codigo);
                if(editados.estado) 
                    body.append('estado_id', municipio.estado_id);
                if(editados.local) 
                    body.append('local_id', municipio.local_id);        
                if(editados.tda) 
                    body.append('tda', municipio.tda);            

                response = await api.post('/cadastro_api/postMunicipio', body);

                if (response.data !== null  &&  response.status !== 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json); 
                    municipio_idd = resposta.municipio_id; 
                }

            }
             

        } finally {      
            let municipio = this.state.objeto.state.municipio;
            municipio.municipio_id = municipio_idd;
            this.state.objeto.setState({ municipio: municipio },
               () => { this.state.objeto.concluido()  });  

            }
    };
}