import React from 'react';

import 'devextreme/data/odata/store';
import DataGrid, { Column, Scrolling, Selection, MasterDetail, FilterRow, HeaderFilter } from 'devextreme-react/data-grid';
import {Nav,Form,Card, Spinner,Button} from 'react-bootstrap';
import './estilo.css';
import ContextMenu from 'devextreme-react/context-menu';
import SelectBox from 'devextreme-react/select-box';
import api from './api';
// import { entregas_list } from './dados/entregas.js';
import GetFilialUsuario from './services/get_filial_usuario';
import GetRotaFilial from './services/get_rotafilial';
import GetColetas from './services/get_coletas';
import GetEntregas from './services/get_entrega';
import PostColetaCancelar from './services/post_coleta_cancelar';
import PostEntregaCancelar from './services/post_entrega_cancelar';
import PostColetaReabrir from './services/post_coleta_reabrir';
import PostEntregaReabrir from './services/post_entrega_reabrir';

import { locale, loadMessages, formatMessage } from 'devextreme/localization';

import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';

// ---------Export detail -----------
import FileSaver from "file-saver";
// import service from "./data.js";

// import PieChart, {
//   Series,
//   Label,
//   Connector,
//   Size,
//   Export,
// } from 'devextreme-react/pie-chart';



const getEmployeeCaption = ({ cliente, municipio }) =>
  'Entregas:';

// const getEmployeeCaption = ({ cliente, municipio }) =>
//   `${cliente} ${municipio}'s Tasks:`;


// const getEmployeeTasks = (id) =>
//   service.getTasks().filter((task) => task.EmployeeID === id);
// --------------

export class GridColetaEntrega extends React.Component {
  constructor(props) {
    super(props);
    // this.onRowPrepared = this.logEvent.bind(this, 'RowInserting');
    this.onRowPrepared = this.onRowPrepared.bind(this); 
    this.masterRows = [];

    this.state = {
      countNotificacao: 0,
      props: props,
      GridVeiculoDetail: null,
      items_coleta:[],
      items_entrega:[],
      load:true,
      // load_geral:true,
      filterStatusFilial:'',
      filterStatusRotas:'',
      filiais:[],
      filiais_descricao:[],
      rotas_descricao:[],
      rotas:[],
      list:[],
      coletas:[],
      entregas:[],
      cadColetaModalAtivo:false,
      objeto_selecionado:{},
      locale: this.getLocale(),
      icone_visible:true,
      visible_filterrow:true,

      visible_datetime: true,
      visible_datetime_text: false,   
      count_status: [{status:'Aberta', count:0}, {status:'Fechada', count:0}, {status:'Atrasada', count:0}],
    };

    // this.locales = service.getLocales();
    locale(this.state.locale);
    this.onValueRotaChanged = this.onValueRotaChanged.bind(this);

  // -------------------------------
    this.dataGridRefColeta = React.createRef();
    this.dataGridRefEntrega = React.createRef();
 
    this.selectedRowsData = [];

    this.getSelectedData = () => {
        this.selectedRowsData = this.dataGrid.getSelectedRowsData();

        // ===== or when deferred selection is used =====
        this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            // Your code goes here
        });
    }
    //-----
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onRowExpanding = this.onRowExpanding.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);

  }
  //---------------------------------------
  getLocale() {
    const storageLocale = sessionStorage.getItem('locale');
    return storageLocale != null ? storageLocale : 'pt';
  }
//------------------------------------------
  get dataGrid() {
    return this.dataGridRefColeta.current.instance;
  }
  //------------------------------------------
  get dataGridEntrega() {
    return this.dataGridRefEntrega.current.instance;
  }
//------------------------------------------
  onRowPrepared(e) {  
    // if (e.rowType !== "data")  
    //     return  
    // alert(JSONString);

    if(e.data !== undefined){
      if (e.data.status === 1)
        e.rowElement.style.backgroundColor = "#98d4b4";
      else if(e.data.status===2)
        e.rowElement.style.backgroundColor = "#e8d979";
      else if(e.data.status===3)
        e.rowElement.style.backgroundColor = "#f095a1";        
    }  

    // if (e.rowType === "data")  
    //   e.data.selectRows([5, 10, 12], true);
     
  } 
  //---------------------------------------------------------------
  addMenuItems(e) {
    if (e.target == 'header') {
        // e.items can be undefined
        if (!e.items_coleta) e.items_coleta = [];

        // Add a custom menu item
        e.items_coleta.push({
            text: 'Log Column Caption',
            onItemClick: () => {
                // alert(e.column.caption);
                console.log(e.column.caption);
            }
        });
    }
  }
  //-------------------------------------------------------------------
  ItemTemplate (e) {
    return (
      <React.Fragment>
        <span className={ e.icon } />
        { e.items_coleta ? <span className="dx-icon-spinright" /> : null }
        { e.text }
      </React.Fragment>
    );
  }
  //---------------------------------------------------------------
  itemClick = (e) => {    
    if (!e.itemData.items_coleta) {      
      if(e.itemData.text === ' Nova Coleta'){        
        this.setState({visible_filterrow:false});
        this.props.MapaKseg.state.Modals.showCadColeta(this, null);
        
      }else if(e.itemData.text === ' Abrir Coleta'){
        if(this.state.coletas.length > 0){
          try{
          // var selectedRowData = this.dataGrid.getSelectedRowsData(); 
          // console.log("  selectedRowData[0] coleta: "+String(this.state.objeto_selecionado));
          // console.log("  selectedRowData[0] coleta.coleta_id: "+String(this.state.objeto_selecionado.coleta_id));
          this.setState({visible_filterrow:false});

          this.props.MapaKseg.state.Modals.showCadColeta(this, this.state.objeto_selecionado);
          }catch (response){this.props.MapaKseg.toastError("");}
        }
      }else if(e.itemData.text === ' Cancelar Coleta'){  
        let texto = "Deseja Cancelar a Coleta "
          +"\n -  Coleta id: "+String(this.state.objeto_selecionado.coleta_id)
          +"\n -  Cliente: "+String(this.state.objeto_selecionado.cliente)
          +"\n ?";
        let imagem = require('./img/close_100dp.png');
        this.props.MapaKseg.state.Modals.showMensagemModal(this, "Cancelar Coleta", texto, true, imagem);

      }else if(e.itemData.text === ' Excel'){  
        // this.exportDataGridToxlsx("Coletas Excel");     
        this.onExportingToxlsx("Coletas Excel");

      }else if(e.itemData.text === ' PDF'){  
        this.exportGridPDF("Coletas PDF"); 
               
      }else if(e.itemData.text === ' Pesquisar Coleta'){  
        this.setState({visible_filterrow:false});
        this.props.MapaKseg.state.Modals.showPesquisaColetaModal(this);     

      }else if(e.itemData.text === ' Concluir Coleta'){  
        this.setState({visible_filterrow:false});
        let objeto = {id: this.state.objeto_selecionado.coleta_id, cliente: this.state.objeto_selecionado.cliente, 
          local: this.state.objeto_selecionado.local, previsao: this.state.objeto_selecionado.data_previsao2, 
          tipo_pagamento: this.state.objeto_selecionado.tipo_pagamento, valor: this.state.objeto_selecionado.valor,
          pago: this.state.objeto_selecionado.pago, usuario_id:0, canhoto: false };
        this.props.MapaKseg.state.Modals.showConcluirColetaEntregaModal(this, "Concluir Coleta", objeto);  

      }else if(e.itemData.text === ' Reabrir Coleta'){       
        let texto = "Deseja Reabrir a Coleta "
          +" \n -  Coleta id: "+String(this.state.objeto_selecionado.coleta_id)
          +" \n -  Cliente: "+String(this.state.objeto_selecionado.cliente) 
          +"\n ?";
        let imagem = require('./img/revert_100dp.png');
        this.props.MapaKseg.state.Modals.showMensagemModal(this, "Reabrir Coleta", texto, true, imagem);

      }else if(e.itemData.text === ' Nova Coleta XML'){  
        let selectedRowData = this.dataGridEntrega.getSelectedRowsData();       
        // this.props.MapaKseg.state.Modals.showXmlModal(this, "XML", selectedRowData[0], "visualizar");   
        this.props.MapaKseg.state.Modals.showXmlModal(this, "XML", selectedRowData[0], "inserir");   
      }else if(e.itemData.text === ' Nova Coleta Simplificada'){  
        this.setState({visible_filterrow:false});
        this.props.MapaKseg.state.Modals.showCadColetaSimplificadaModal(this, null);   
      }else if(e.itemData.text === ' Abrir Comprovante'){  
        let selectedRowData = this.dataGrid.getSelectedRowsData();       
        this.props.MapaKseg.state.Modals.showComprovanteModal(this, "Comprovante Coleta", selectedRowData[0], "visualizar");   
      }
    }
  }
  //---------------------------------------------------------------
  itemEntregaClick = (e) => {
    if (!e.itemData.items_entrega) {
      if(e.itemData.text === ' Nova Entrega'){      
        this.props.MapaKseg.state.Modals.showFormModal(this, "Nova entrega", "Informe o Id da Coleta referente!", ['Coleta Id']);  
        // this.props.MapaKseg.state.Modals.showEntregaModal(this, null);  
      }else if(e.itemData.text === ' Abrir Entrega'){        
        try{
        // if(this.state.entregas>0){
          this.setState({visible_filterrow:false});
          let selectedRowData = this.dataGridEntrega.getSelectedRowsData();         
          this.props.MapaKseg.state.Modals.showEntregaModal(this, selectedRowData[0]);
        // }
        }catch (response){this.props.MapaKseg.toastError("");}
      }else if(e.itemData.text === ' Cancelar Entrega'){  
        let selectedRowData = this.dataGridEntrega.getSelectedRowsData();       
        let texto = "Deseja Cancelar a Entrega "
          +"\n -  Entrega id: "+String(selectedRowData[0].entrega_id)
          +"\n -  Cliente: "+String(selectedRowData[0].cliente) 
          +"\n ?";
        let imagem = require('./img/close_100dp.png');
        this.props.MapaKseg.state.Modals.showMensagemModal(this, "Cancelar Entrega", texto, true, imagem);

      }else if(e.itemData.text === ' Excel'){  
        this.exportDataGridToxlsx("Entregas Excel");        

      }else if(e.itemData.text === ' PDF'){  
        this.exportGridPDF("Entregas PDF");        

      }else if(e.itemData.text === ' Pesquisar Entrega'){  
        this.setState({visible_filterrow:false});
        this.props.MapaKseg.state.Modals.showPesquisaEntregaModal(this);     

      }else if(e.itemData.text === ' Concluir Entrega'){  
        this.setState({visible_filterrow:false});
        let objeto = {id: this.state.objeto_selecionado.entrega_id, cliente: this.state.objeto_selecionado.cliente, 
          local: this.state.objeto_selecionado.local, previsao: this.state.objeto_selecionado.data_previsao2, 
          tipo_pagamento: this.state.objeto_selecionado.tipo_pagamento, valor: this.state.objeto_selecionado.valor,
          pago: this.state.objeto_selecionado.pago, usuario_id:0, canhoto: this.state.objeto_selecionado.canhoto,
          canhoto64: ""};
        this.props.MapaKseg.state.Modals.showConcluirColetaEntregaModal(this, "Concluir Entrega", objeto);        

      }else if(e.itemData.text === ' Reabrir Entrega'){  
        let selectedRowData = this.dataGridEntrega.getSelectedRowsData();       
        let texto = "Deseja Reabrir a Entrega "
          +" \n -  Entrega id: "+String(selectedRowData[0].entrega_id)
          +" \n -  Cliente: "+String(selectedRowData[0].cliente) 
          +"\n ?";
        let imagem = require('./img/revert_100dp.png');
        this.props.MapaKseg.state.Modals.showMensagemModal(this, "Reabrir Entrega", texto, true, imagem);

      }else if(e.itemData.text === ' Abrir Comprovante'){  
        let selectedRowData = this.dataGridEntrega.getSelectedRowsData();       
        this.props.MapaKseg.state.Modals.showComprovanteModal(this, "Comprovante Entrega", selectedRowData[0], "visualizar");   
      }
    }
  }
  //------------------------------------------------
  confirmarMensagem = (texto) => {
    if(texto ==="Cancelar Coleta"){
      var instance = new PostColetaCancelar(null, this, this.props.MapaKseg, this.state.objeto_selecionado);
      instance.post(); 
    }else 
      if(texto ==="Cancelar Entrega"){
        let selectedRowData = this.dataGridEntrega.getSelectedRowsData(); 
        var instance = new PostEntregaCancelar(null, this, this.props.MapaKseg, selectedRowData[0]);
        instance.post(); 
      }else
        if(texto ==="Reabrir Coleta"){
          if(String(this.state.objeto_selecionado.data_coleta) === "")
            this.props.MapaKseg.toastError( "A Coleta já esta Aberta!");
          else{
            var instance = new PostColetaReabrir(null, this, this.props.MapaKseg, this.state.objeto_selecionado);
            instance.post(); 
          }
        }else 
        if(texto ==="Reabrir Entrega"){
          let selectedRowData = this.dataGridEntrega.getSelectedRowsData(); 
          if(String(selectedRowData[0].data_entrega) === "")
            this.props.MapaKseg.toastError( "A Entrega já esta Aberta!");
          else{
            var instance = new PostEntregaReabrir(null, this, this.props.MapaKseg, selectedRowData[0]);
            instance.post(); 
          }
        }
  }
  //--------------------------------------------------------------
  confirmarFormModal= (coleta_id) => {
    if(coleta_id !== ""){
      let entrega = { entrega_id:0, coleta_id: coleta_id };
      this.setState({visible_filterrow:false});
      this.props.MapaKseg.state.Modals.showEntregaModal(this, entrega);
    }
  }
  //--------------------------------------------------------------
  onSelectionChanged({ selectedRowsData }) {
    this.props.MapaKseg.ajustarZoom(selectedRowsData[0]);
  }
  //-------------------------------------------------------------
  onRowExpanding= (e) => {
    e.component.selectRows(e.key, false);
  }
  //-----------------------------------------
  componentDidMount() {  
    this.props.PainelGrid.setState({GridColetaEntrega: this});
    this.props.MapaKseg.state.Modals.setState({GridColetaEntrega: this});

    var instance = new GetFilialUsuario(null, this, this.props.MapaKseg);
    instance.get(); 

    this.carregarItemsColetaContextMenu();
    this.carregarItemsEntregaContextMenu();
  };
  //------------------------------------------
  carregarItemsColetaContextMenu() {
    var items_coleta = this.state.items_coleta;
    if(this.props.MapaKseg.state.pessoa_id===1 || this.props.MapaKseg.state.pessoa_id===34){ // 34 Buffon
      items_coleta.push({ text: ' Nova Coleta Simplificada', icon: "dx-icon-add"  });
    }
    items_coleta.push({ text: ' Nova Coleta', icon: "dx-icon-add"  });
    if(this.props.MapaKseg.state.pessoa_id===1)
      items_coleta.push({ text: ' Nova Coleta XML', icon: "dx-icon-file"  });
    items_coleta.push({ text: ' Abrir Coleta', icon: "dx-icon-folder"  });
    items_coleta.push({ text: ' Concluir Coleta', icon: "dx-icon-todo"  });
    items_coleta.push({ text: ' Cancelar Coleta', icon: "dx-icon-close"  });
    items_coleta.push({ text: ' Pesquisar Coleta', icon: "dx-icon-find"  });
    items_coleta.push({ text: ' Reabrir Coleta', icon: "dx-icon-revert"  });
    items_coleta.push({ text: ' Abrir Comprovante', icon: "dx-icon-file"  });
    items_coleta.push({ text: ' Exportar', icon: "dx-icon-export"
                        ,items: [
                          { text: ' Excel', icon: "dx-icon-exportxlsx" },
                          { text: ' PDF', icon: "dx-icon-exportpdf" }],  });

    // if(this.props.MapaKseg.state.pessoa_id===1)
    //   items.push({ text: ' Nova Coleta', icon: "dx-icon-activefolder"  });

    this.setState({items_coleta: items_coleta}); 
    }
  //------------------------------------------
  carregarItemsEntregaContextMenu() {
    var items_entrega = this.state.items_entrega;
    items_entrega.push({ text: ' Nova Entrega', icon: "dx-icon-add"  });
    items_entrega.push({ text: ' Abrir Entrega', icon: "dx-icon-folder"  });
    items_entrega.push({ text: ' Concluir Entrega', icon: "dx-icon-todo"  });
    items_entrega.push({ text: ' Cancelar Entrega', icon: "dx-icon-close"  });
    items_entrega.push({ text: ' Pesquisar Entrega', icon: "dx-icon-find"  });
    items_entrega.push({ text: ' Reabrir Entrega', icon: "dx-icon-revert"  });
    items_entrega.push({ text: ' Abrir Comprovante', icon: "dx-icon-file"  });
    items_entrega.push({ text: ' Exportar', icon: "dx-icon-export" 
                          ,items: [
                            { text: ' Excel', icon: "dx-icon-exportxlsx" },
                            { text: ' PDF', icon: "dx-icon-exportpdf" }],  });

    // if(this.props.MapaKseg.state.pessoa_id===1)
    //   items.push({ text: ' Nova Coleta', icon: "dx-icon-activefolder"  });

    this.setState({items_entrega: items_entrega}); 
    } 
  //------------------------------------------
  getRotaFilial(filial_id) {
    var instance = new GetRotaFilial(null, this, this.props.MapaKseg, filial_id);
    instance.get(); 
  }
  //--------------------------------------------------------------------
  getColetas = () => {
    this.setState({coletas: [], load:true});
    var instance = new GetColetas(null, this, this.props.MapaKseg, this.state.rotas);
    instance.get(); 
  }
  //--------------------------------------------------------------------
  getEntregas = () => {
    this.setState({coletas: [], load:true});
    var instance = new GetEntregas(null, this, this.props.MapaKseg, this.state.rotas);
    instance.get(); 
  }
  //--------------------------------------------------------------------------------
  onValueChangedGrupo(filial_id ) {
    var filiais = this.state.filiais;
    var list_aux = filiais.filter((item,index) =>{  
      return item.filial_id === filial_id;
    });
    this.setState({filterStatusFilial: list_aux[0].descricao});
  }
  //--------------------------  
    onValueChanged({ value }) {
      // console.log("value:");
      // console.log(value);
      // console.log(value.trim());
      // this.props.MapaKseg.toast(value.trim());

      var filiais = this.state.filiais;
      var list_aux = filiais.filter((item,index) =>{  
        return item.descricao.trim() === value.trim();
      })
  
      // console.log("list_aux:");
      // console.log(list_aux);
  
      // if (value === 'All') {
      //   dataGrid.clearFilter();
      // } else {
      //   dataGrid.filter(['nome', '=', value]);
      // }
  
      this.setState({filterStatusFilial: value});
      // localStorage.setItem('grupo_id', list_aux[0].grupo_id);
      // this.props.MapaKseg.setState({grupo_id: list_aux[0].grupo_id});
      // this.postGrupoVeiculoAtual(this.props.MapaKseg.state.usuario_id, list_aux[0].grupo_id);
      // this.props.MapaKseg.recarregarPosicoesClick();
      this.getRotaFilial(list_aux[0].filial_id);
    }
  //--------------------------  
  onValueRotaChanged= (e) => {
    this.setState(state =>{
      rotas:state.rotas.map(obj => obj.descricao === e.target.name 
                               ? (obj.selecionada = e.target.value, obj)                               
                               : obj)
    },)
  }    
  //------------------------------------------
  getRotaFilial(filial_id) {
    var instance = new GetRotaFilial(null, this, this.props.MapaKseg, filial_id);
    instance.get(); 
  }  
  //------------------------------------------------
  reloadGrid= (obj) => {
    this.getColetas();
    this.getEntregas();

    // let coletas = this.state.coletas;
    // coletas = coletas.concat(nova_coleta);
    // this.setState({coletas: coletas});

    // setTimeout(() => {
    //   coletas = this.state.coletas;
    //   coletas[0].status = 2;  
    //   coletas = coletas.concat(null);
    //   coletas.pop(coletas.length-1);
    //   this.setState({coletas: coletas});

    //   console.log("\nthis.state.coletas: ");
    //   console.log(this.state.coletas);
    // }, 5000);  
  }
  //-------------------------
  onCheckClick = ()=>{
    this.props.MapaKseg.toast("onCheckClick");
    // this.setState({render_image:false},
    //     () => { this.setState({render_image:true }) })
  }
  //-------------------------
  visibleFilterrow = ()=>{
    this.setState({visible_filterrow:true});
  }
  //-------------------------
  visibleFilterrowFalse = ()=>{
    this.setState({visible_filterrow:false});
  }
  //-------------------------------------------------------------------------------
  exportGridPDF=(e)=> {
    this.setState({icone_visible: !this.state.icone_visible}); 
    const doc = new jsPDF();

    let today = new Date();
    let time = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
    let date = today.getDate() + "-" + (today.getMonth() + 1) + '-' + today.getFullYear() + '  '+time;

    let dataGrid = this.dataGridRefColeta.current.instance;
    let nomeArquivo = "Coletas "+date+".pdf";

    if(e == "Entregas PDF"){
      dataGrid = this.dataGridRefEntrega.current.instance;
      nomeArquivo = "Entregas "+date+".pdf";
    }
  
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: dataGrid
    }).then(() => {
      doc.save(nomeArquivo);
      this.setState({icone_visible: !this.state.icone_visible});
    });    
  }
  //-------------------------------------------------------------------------------
  exportDataGridToxlsx = (e) =>  {
    this.setState({
      visible_datetime: false,
      visible_datetime_text: true,
      icone_visible: false}); 

    let today = new Date();
    let time = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
    let date = today.getDate() + "-" + (today.getMonth() + 1) + '-' + today.getFullYear() + '  '+time;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    let compo = this.dataGridRefColeta.current.instance;
    let nomeArquivo = "Coletas "+date+".xlsx";
    
    if(e == "Entregas Excel"){
      compo = this.dataGridRefEntrega.current.instance;
      nomeArquivo = "Entregas "+date+".xlsx";
    }

    exportDataGrid({
        // component: e.component,
        component: compo,
        worksheet: worksheet
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), nomeArquivo);
        this.setState({
          visible_datetime: true,
          visible_datetime_text: false,
          icone_visible: true});
        });
    });

  }
    //---------------------------------------------------------------------------------------
    onExportingToxlsx = (e) => {
      this.setState({
        visible_datetime: false,
        visible_datetime_text: true,
        icone_visible: false});

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      
      let coletas = this.state.coletas;

      let today = new Date();
      let time = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
      let date = today.getDate() + "-" + (today.getMonth() + 1) + '-' + today.getFullYear() + '  '+time;


      let compo = this.dataGridRefColeta.current.instance;
      let nomeArquivo = "Coletas "+date+".xlsx";
      
      if(e == "Entregas Excel"){
        compo = this.dataGridRefEntrega.current.instance;
        nomeArquivo = "Entregas "+date+".xlsx";
      }
  
      exportDataGrid({
        component: compo,
        worksheet: worksheet,
        autoFilterEnabled: true,
        topLeftCell: { row: 2, column: 2 },
        customizeCell: ({ gridCell, excelCell }) => {
          if (
            gridCell.column.dataField === "cliente" &&
            gridCell.rowType === "data"
          ) {
            this.masterRows.push({
              rowIndex: excelCell.fullAddress.row + 1,
              data: gridCell.data
            });
          }
        }
      })
        .then((cellRange) => {
          const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
          let offset = 0;
  
          const insertRow = (index, offset, outlineLevel) => {
            const currentIndex = index + offset;
            // debugger;
            let row = worksheet.insertRow(currentIndex, [], "n");
  
            for (var j = worksheet.rowCount + 1; j > currentIndex; j--) {
              worksheet.getRow(j).outlineLevel = worksheet.getRow(
                j - 1
              ).outlineLevel;
            }
  
            row.outlineLevel = outlineLevel;
  
            return row;
          };
  
          console.log("this.masterRows: "+String(this.masterRows));
          console.log("this.masterRows[0].data: "+String(this.masterRows[0].data));
          // if(this.masterRows != null){
          //   console.log("Não é null!");
          
          for (var i = 0; i < this.masterRows.length; i++) {
            let row = insertRow(this.masterRows[i].rowIndex + i, offset++, 2);
            let columnIndex = cellRange.from.column + 1;
            row.height = 40;
  
            let employeeData = coletas.find(
              (item) => item.coleta_id === this.masterRows[i].data.coleta_id
            );
            console.log("employeeData: ");
            console.log(employeeData);
            Object.assign(row.getCell(columnIndex), {
              value: getEmployeeCaption(employeeData),
              fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "BEDFE6" }
              }
            });
            worksheet.mergeCells(row.number, columnIndex, row.number, 7);
  
            const columns = [
              "cliente",
              "rota",
              "municipio",
              "tipo_pagamento",
              "valor",
              "data_previsao"
            ];
  
            row = insertRow(this.masterRows[i].rowIndex + i, offset++, 2);
            columns.forEach((columnName, currentColumnIndex) => {
              Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                value: columnName,
                fill: {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "BEDFE6" }
                },
                font: { bold: true },
                border: {
                  bottom: borderStyle,
                  left: borderStyle,
                  right: borderStyle,
                  top: borderStyle
                }
              });
            });
  
            employeeData.entregas.forEach((task, index) => {
              row = insertRow(this.masterRows[i].rowIndex + i, offset++, 2);
  
              columns.forEach((columnName, currentColumnIndex) => {
                Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                  value: task[columnName],
                  fill: {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "BEDFE6" }
                  },
                  border: {
                    bottom: borderStyle,
                    left: borderStyle,
                    right: borderStyle,
                    top: borderStyle
                  }
                });
              });
            });
            offset--;
          }
        
        })
        .then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            FileSaver.saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              nomeArquivo
            );

            this.setState({
              visible_datetime: true,
              visible_datetime_text: false,
              icone_visible: true});

          });
        });
    };
  //---------------------------------------------------------------------------------------
  renderDetail= (props) => {
    return (
      <React.Fragment>
        <b>Entregas</b>

        <DataGrid
          dataSource={props.data.entregas}
          showBorders={true}
          columnAutoWidth={true}
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          // onExporting={this.onExportingToxlsx}
        >

          <Selection mode="single" />
          <Scrolling mode='virtual'></Scrolling>
          
          <Column dataField="index" caption= "" width={40}/>
          <Column dataField="coleta_id" caption="coleta_id" width={60}  />
          <Column dataField="entrega_id" caption="Id" width={60}  />
          <Column dataField="cliente" width={150} />
          <Column dataField="rota" width={100} />
          <Column dataField="municipio" caption= "Município" width={100} />
          <Column dataField="tipo_pagamento" caption= "pagamento" width={100} />
          <Column dataField="valor" caption="Frete" width={70} />          
          {/* <Column dataField="data_previsao" caption={"Data Previsão"} format="EE  dd/MM/yyyy HH:mm" dataType="datetime" /> */}
          <Column dataField="data_previsao" caption={"Data Previsão"} dataType="text" />
          <Column dataField="data_entrega" caption={"Data Entrega"}  dataType="text" />
          <Column dataField="local" visible={false} width={250}/> 
        </DataGrid>
      </React.Fragment>
    );
  }
  //---------------------------------------------------------------
  render() {        
    const filialSelectBox =       
        <SelectBox
          items={this.state.filiais_descricao}
          value={this.state.filterStatusFilial}
          onValueChanged={this.onValueChanged} 
          />  ;

    const checkRotaList = 
      <Card style={{ height: '2.3rem' }} body>
        <Form className="top_negativo" >
          {this.state.rotas.map((rota) => (
              <Form.Check               
                inline              
                label={rota.descricao}

                name={rota.descricao}
                type='checkbox'
                id={rota.rota_id}
                checked
                // value={rota.selecionada}
                // defaultChecked={rota.selecionada}
                // onClick={ (e) => { this.onCheckClick() } }

                onChange={(e) => {
                  this.onValueRotaChanged({
                    target: {
                      name: e.target.name,
                      value: e.target.checked,
                    },
                  });
                }}
              />
          ))}
        </Form>
      </Card>;

    const onContextMenuPreparing = (e) =>{
        try{
          e.component.selectRows(e.row.key, false);
          var selectedRowData = this.dataGrid.getSelectedRowsData();
          // this.props.MapaKseg.ajustarZoom(selectedRowData[0]);

          // console.log(" e.row.key: ");
          // console.log(e.row.key);
          this.setState({objeto_selecionado: e.row.key});
          // alert(e.row.key);
        }catch (response){}

      // if (e.target !== "content") 
      //     return;
      // // e.items = [];
      // if(e.row){
      //   e.component.selectRows(e.row.key, false)
      //   var selectedRowData = this.dataGrid.getSelectedRowsData();
      //   this.props.MapaKseg.ajustarZoom(selectedRowData[0]);

      //   console.log(e.row.key);
      //   alert(e.row.key);
      // }
    }

    const columns_coleta = ['index', 'cliente_id','rota_id','data_previsao', 'data_coleta', 'tipo_pagamento_id', 'valor'];
    const columns_entrega = ['Cliente', 'Origem', 'Destino', 'DataEntrega', 'Frete', 'Pagante'];
    
    return (             
      this.state.load?
        <Form  style={{height: '500px', overflow: 'hidden'}}>
          <div className="centro">
              <div className="espaco_topo3">
                  <Spinner animation="border" />
              </div>
          </div>                                        
        </Form> 
      :
      <Form  style={{height: '510px', overflow: 'hidden'}}>
          <div>        
            <Nav variant="pills">
              <Nav.Item className="espaco_esq1">
              {this.state.filiais_descricao.length >0 ?
                filialSelectBox :""}
              </Nav.Item>
              <Nav.Item className="espaco_esq1">
              {this.state.rotas_descricao.length >0 ?  
                checkRotaList :""}
              </Nav.Item>  
            </Nav>
       
            <ContextMenu
                dataSource={this.state.items_coleta}
                width={180}
                target="#coletasGrid"
                itemRender={this.ItemTemplate}
                onItemClick={this.itemClick} />

             <ContextMenu
                dataSource={this.state.items_entrega}
                width={180}
                target="#entregasGrid"
                itemRender={this.ItemTemplate}
                onItemClick={this.itemEntregaClick} />   

            <div className="tables">
              <div className="column">
                <b>COLETAS

                {/* <PieChart
                  id="pie"
                  dataSource={this.state.count_status}
                  palette="coletaEntrega"
                  title=""

                > */}
                  {/* <Series
                    argumentField="status"
                    valueField="count"
                  >
                    <Label visible={false}>
                      <Connector visible={true} width={1} />
                    </Label>
                  </Series>

                  <Size width={50} height={50}/>
                </PieChart> */}

                </b>

                <DataGrid
                  id="coletasGrid"
                  dataSource={this.state.coletas}
                  // defaultColumns={columns_coleta}
                  showBorders={true}
                  onRowPrepared={this.onRowPrepared}
                  columnAutoWidth={true}
                  onRowUpdated={this.onRowUpdated}
                  height={430}
                  allowColumnResizing={true}
                  onContextMenuPreparing={onContextMenuPreparing}
                  ref={this.dataGridRefColeta}
                  // keyExpr="coleta_id"
                  // onExporting={this.onExportingToxlsx}
                > 
                  <Selection mode="single" />
                  <Scrolling mode="virtual" />
                  {/* <HeaderFilter visible={true} /> */}
                  <FilterRow visible={this.state.visible_filterrow} />
                  {/* <Export enabled /> */}

                  <Column dataField="entregas_count_icon"
                    caption=""
                    width={50}
                    allowSorting={false}
                    cellRender={cellRender}
                    visible={this.state.icone_visible}
                    // visible={false} 
                    >          
                  </Column>
                  <Column dataField="index" visible={false} />
                  <Column dataField="coleta_id" caption= "Coleta Id" visible={true} /> 
                  <Column dataField="cliente_id" visible={false} /> 
                  <Column dataField="cliente" width={100} />
                  <Column dataField="municipio" caption= "Município" width={100} />
                  <Column dataField="rota" width={100} />
                  {/* <Column caption= "Data Previsão" dataField="data_previsao"  />  */}
                  {/* <Column caption= "Data Previsão" dataField="data_previsao" dataType="datetime" format="yyyy/MM/dd HH:mm:ss" />  */}
                  {/* <Column caption= "Data Previsão" dataField="data_previsao" dataType="datetime" format="dd/MM/yyyy HH:mm" /> */}
                  {/* <Column dataField="data_previsao" caption={"data_previsao"} format="EE  dd/MM/yyyy HH:mm" dataType="datetime" /> */}
                  <Column dataField="data_previsao" caption={"Data Previsão"} format="EE dd/MM/yyyy HH:mm"  dataType="datetime"
                          visible={this.state.visible_datetime} />
                  <Column dataField="data_previsao2" caption={"Data Previsão"} dataType="text"
                          visible={this.state.visible_datetime_text} />
                  {/*   format="M/d/yyyy, HH:mm"     3/24/2017, 09:00 */}
                  {/* sáb 29/01/2022 17:19 */}
                    {/* </Column>displayFormat="EEEE, d of MMM, yyyy HH:mm"/> */}
                  {/* <Column caption= "Data Coleta" dataField="data_coleta"  /> */}
                  <Column dataField="data_coleta" caption={"Data Coleta"} format="EE  dd/MM/yyyy HH:mm" dataType="datetime"
                          visible={this.state.visible_datetime} />
                  <Column dataField="data_coleta2" caption={"Data Coleta"} dataType="text" 
                          visible={this.state.visible_datetime_text} />

                  <Column dataField="usuario" caption= "Usuário"/>
                  <Column dataField="tipo_pagamento" caption= "Pagamento"/>
                  <Column dataField="valor" caption= "Frete" />
                  <Column dataField="tda" caption= "TDA"/>
                  <Column dataField="total_frete_tda" caption= "Total Frete"/>
                  <Column dataField="cte" caption= "CTE"/>
                  <Column dataField="nota_fiscal" caption= "NF"/>
                  <Column dataField="pago_str" caption= "Pago"/>             
                  <Column dataField="canhoto" caption= "Comprovante" alignment='center'/>      

                  <MasterDetail enabled={true} render={this.renderDetail} />
                </DataGrid>
              </div>

              <div className="column">
                <b>ENTREGAS</b>            
                <DataGrid
                  id="entregasGrid"
                  
                  repaintChangesOnly={true}
                  dataSource={this.state.entregas}
                  // defaultColumns={columns_entrega}
                  showBorders={true}
                  onRowPrepared={this.onRowPrepared}
                  columnAutoWidth={true}
                  onContextMenuPreparing={onContextMenuPreparing}
                  height={430}
                  allowColumnResizing={true}
                  ref={this.dataGridRefEntrega}
                > 
                  <Selection mode="single" />
                  <Scrolling mode="virtual" />
                  {/* <HeaderFilter visible={true} /> */}
                  <FilterRow visible={this.state.visible_filterrow} />

                  <Column dataField="index" visible={false}/>
                  <Column dataField="entrega_id" caption= "Entrega Id" visible={true} />
                  <Column dataField="cliente" width={100} />
                  <Column dataField="municipio" caption= "Município" width={100} />
                  <Column dataField="rota" width={100} />
                  {/* <Column caption= "Data Previsão" dataField="data_previsao" /> */}
                  {/* <Column caption= "Data Previsão" dataField="data_previsao" dataType="datetime" format="yyyy/MM/dd HH:mm:ss" /> */}
                  {/* <Column caption= "Data Previsão" dataField="data_previsao" dataType="datetime" format="dd/MM/yyyy HH:mm" /> */}
                  {/* <Column caption= "Data Previsão" dataField="data_previsao" dataType="datetime" format="EE,  dd/MM/yyyy HH:mm" /> */}
                  {/* <Column caption= "Data Previsão" dataField="data_previsao" dataType="datetime" format="d/M/yyyy HH:mm" /> */}
                  {/* <Column caption= "Data Previsão" dataField="data_previsao" dataType="datetime"/> */}

                  <Column dataField="data_previsao" caption={"Data Previsão"} format="EE  dd/MM/yyyy HH:mm" dataType="datetime" 
                        visible={this.state.visible_datetime} />
                  <Column dataField="data_previsao2" caption={"Data Previsão"} dataType="text"
                        visible={this.state.visible_datetime_text} />

                  {/* <Column caption= "Data Entrega" dataField="data_entrega"  /> */}
                  <Column dataField="data_entrega" caption={"Data Entrega"} format="EE  dd/MM/yyyy HH:mm" dataType="datetime"
                        visible={this.state.visible_datetime} />
                  <Column dataField="data_entrega2" caption={"Data Entrega"} dataType="text" 
                        visible={this.state.visible_datetime_text}/>

                  <Column dataField="usuario" caption= "Usuário"/>
                  <Column dataField="tipo_pagamento" caption= "Pagamento"/>
                  <Column dataField="valor" caption= "Frete"/>
                  <Column dataField="tda" caption= "TDA"/>
                  <Column dataField="total_frete_tda" caption= "Total Frete"/>
                  <Column dataField="pago_str" caption= "Pago"/>
                  <Column dataField="local" visible={false} width={250}/> 
                  <Column dataField="cte" caption= "CTE"/>
                  <Column dataField="nota_fiscal" caption= "NF"/>
                  <Column dataField="canhoto" caption= "Comprovante" alignment='center'/>                   
                </DataGrid>
              </div>
            </div>          
          </div>  
          </Form>    
    );
  }


}

function cellRender(data) {
  if(data.value !== null)
    return <img src={data.value} width="20"  height="20"/>;
  else return <img src={require("./img/transparent.png")} width="20"  height="20"/>;
}
