import React, {Component} from 'react';
import {Modal, ListGroup, Spinner, Button, Navbar, Nav, Form, FormControl} from 'react-bootstrap'; 
import './estilo.css';
import { ToastStore} from 'react-toasts';
import api from './api';
import DtGrid from './dataGrid';
import {DataGridTemperatura} from './DataGridTemperatura';
import {DataGridEventos} from './DataGridEventos';
import FilterSelect from './filterSelect'
import { DateBox } from 'devextreme-react';
import {CabecalhoModal} from './CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import {GetEventos} from './GetEventos';
import { KmPercorridoGrafico } from './grafico/KmPercorridoGrafico';
import { VelocidadeMediaGrafico } from './grafico/VelocidadeMediaGrafico';
import { QuantidadePosicoesGrafico } from './grafico/QuantidadePosicoesGrafico';
import { QuantidadeEventosGrafico } from './grafico/QuantidadeEventosGrafico';
import { ColetasEntregasChart } from './grafico/ColetasEntregasChart';
import { ColetasClientesChart } from './grafico/ColetasClientesChart';
import { EntregasClientesChart } from './grafico/EntregasClientesChart';
import { ColetasMotoristasChart } from './grafico/ColetasMotoristasChart';
import { EntregasMotoristasChart } from './grafico/EntregasMotoristasChart';
import SelectBox from 'devextreme-react/select-box';
import GetFilialUsuario from './services/get_filial_usuario';

const TIPO_CHAMADA_KM_PERCORRIDO = 1;
const TIPO_CHAMADA_VELOCIDADE_MEDIA = 2;
const TIPO_CHAMADA_QUANTIDADE_POSICOES = 3;
const TIPO_CHAMADA_QUANTIDADE_EVENTOS_GERADOS = 4;
const TIPO_CHAMADA_COLETAS_ENTREGAS = 5;
const TIPO_CHAMADA_COLETAS_CLIENTES = 6;
const TIPO_CHAMADA_ENTREGAS_CLIENTES = 7;
const TIPO_CHAMADA_COLETAS_MOTORISTAS = 8;
const TIPO_CHAMADA_ENTREGAS_MOTORISTAS = 9;

//--------------------------------  
var moment = require('moment');
require('moment-precise-range-plugin');
 //--------------------------------  


export class GraficosModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true,
            posicao:{},
            list:[],
            list_velocidade_media:[],
            list_quantidade_posicoes:[],
            list_quantidade_eventos:[],
            list_coleta_entrega_porcentagem:[],
            list_quantidade_coletas_clientes:[],
            list_quantidade_entregas_clientes:[],
            list_quantidade_coletas_motoristas:[],
            list_quantidade_entregas_motoristas:[],
            loading:true,
            filter_list: [],
            data_inicial: null,
            data_final: null,
            value_data_inicial: null,
            value_data_final: null,
            btnPesquisar:false,
            veiculo_id:null,
            placa:null,
            veiculo_temperaturas_tab:false,
            MapaKseg: props.MapaKseg,
            animation: "zoom",
            minimizar:false,
            drag:"drag3",
            width:1000,
            height:520,
            grid_height:'400px',
            trecho_evento_list:[],
            filiais_descricao:[],
            filiais:[],
            filterStatusFilial:'',
            filterStatusRotas:'',
        };
        this.now = new Date();

        this.onValueChangedFilialSelectBox = this.onValueChangedFilialSelectBox.bind(this);
      }    
    //--------------------------------------------------
    async componentDidMount() {
        // this.props.MapaKseg.callToast("Width: "+String(window.innerWidth)+"   Height: "+String(window.innerHeight));
        var data_hoje= new Date();
        var data_inicial = new Date();
        // data_inicial.setDate(data_inicial.getDate() - 1);        
        data_inicial.setDate(data_inicial.getDate() - 30);

        this.setState({
            data_inicial: this.formatarDateTime(String(data_inicial))
            ,value_data_inicial: String(data_inicial)
            ,data_final: this.formatarDateTime(String(data_hoje))
            ,value_data_final: String(data_hoje)
        });


        if(this.props.tipo_chamada===TIPO_CHAMADA_KM_PERCORRIDO)
            this.setState({drag: "drag3",width: 1000 });
        else if(this.props.tipo_chamada===TIPO_CHAMADA_VELOCIDADE_MEDIA)
            this.setState({drag: "drag5",width: 1199 });
        else if(this.props.tipo_chamada===TIPO_CHAMADA_QUANTIDADE_POSICOES)
            this.setState({drag: "drag5",width: 1199 });
        else if(this.props.tipo_chamada===TIPO_CHAMADA_QUANTIDADE_EVENTOS_GERADOS)
            this.setState({drag: "drag5",width: 1199 });
        else if(this.props.tipo_chamada===TIPO_CHAMADA_COLETAS_ENTREGAS){
            this.setState({drag: "drag5",width: 1199 });        

            var instance = new GetFilialUsuario(null, this, this.props.MapaKseg);
            instance.get();
        }else if(this.props.tipo_chamada===TIPO_CHAMADA_COLETAS_CLIENTES)
            this.setState({drag: "drag5",width: 1199 });    
        else if(this.props.tipo_chamada===TIPO_CHAMADA_ENTREGAS_CLIENTES)
            this.setState({drag: "drag5",width: 1199 });       
        else if(this.props.tipo_chamada===TIPO_CHAMADA_COLETAS_MOTORISTAS)
            this.setState({drag: "drag5",width: 1199 });  
            else if(this.props.tipo_chamada===TIPO_CHAMADA_ENTREGAS_MOTORISTAS)
            this.setState({drag: "drag5",width: 1199 });                      

        // ToastStore.success("getPosicoesVeiculo componentDidMount()");
        // this.getPosicoes();
    };
    //------------------------------------------------
    getRotaFilial = async (filial_id) => {
        let list_rota = [];
        try {
            let body = new FormData()
            // body.append('usuario_id', this.state.MapaKseg.state.usuario_id)
            body.append('filial_id', filial_id)
            body.append('token', localStorage.getItem('token'))
    
            const response = await api.post('/login_api/getRotasFilial', body);
            
            var responseJson = null
    
            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let rota = {};            
    
                // ToastStore.success("resposta.length: "+ String(resposta.length));
                for (var i = 0; i < resposta.length; i++) {                        
                    rota = {    
                        rota_id: resposta[i].rota_id,
                        descricao: resposta[i].descricao,
                        selecionada: true,
                        index_scroll: (i+2)*25,
                    };
    
                    // if(localStorage.getItem('rota_id') === String(rota.rota_id) )
                    // rota.selecionada=true;
    
                    list_rota.push(rota);
                }
                
            }
        } finally {
            let rotas_descricao =[];
                for (var i = 0; i < list_rota.length; i++)
                    rotas_descricao.push(list_rota[i].descricao);  
    
            let descricao = '';
            if(list_rota.length > 0)
                if(list_rota[0].descricao !== undefined)
                    descricao = list_rota[0].descricao         
    
            this.setState({rotas: list_rota, rotas_descricao: rotas_descricao, filterStatusRotas: descricao});
            this.setState({loading: false});
            
            }
    };
    //-------------------------------------------------------------
    setVeiculoId = (veiculo_id, placa) => {
        this.setState({veiculo_id: veiculo_id,
                        placa: placa});

        var temperaturas_tab =false;
        for (var i=0; i < this.props.list.length; i++) {
            if(parseInt(this.props.list[i].veiculo_id) === parseInt(veiculo_id)){
                temperaturas_tab = this.props.list[i].temperaturas_tab;
                // ToastStore.success( "ACHOU temperaturas_tab: "+String(temperaturas_tab));
                break;
            }
        }
        this.setState({veiculo_temperaturas_tab: temperaturas_tab});
    }
    //------------------------------------------------
    onClickPesquisar = (e) => {
        const periodo_dias =this.diffInDay;
        var array_tipo_chamada = [TIPO_CHAMADA_KM_PERCORRIDO, TIPO_CHAMADA_VELOCIDADE_MEDIA, TIPO_CHAMADA_QUANTIDADE_POSICOES, TIPO_CHAMADA_QUANTIDADE_EVENTOS_GERADOS
        , TIPO_CHAMADA_COLETAS_ENTREGAS,TIPO_CHAMADA_COLETAS_CLIENTES, TIPO_CHAMADA_ENTREGAS_CLIENTES, TIPO_CHAMADA_COLETAS_MOTORISTAS
        ,TIPO_CHAMADA_ENTREGAS_MOTORISTAS];

        if(this.state.veiculo_id===null && !array_tipo_chamada.includes(this.props.tipo_chamada))
            ToastStore.error("Selecine um veículo!");
        else if(this.state.data_inicial===null || String(this.state.data_inicial).includes("undefined"))
            ToastStore.error("Data inicial está vazia!");
        else if(this.state.data_final===null || String(this.state.data_final).includes("undefined"))
            ToastStore.error("Data final está vazia!");
        else if(periodo_dias > 31)
            ToastStore.error("Período maior que 31 dias!");
        else
            this.loadData();
    }
    //------------------------------------------------
    loadData = (e) => {
        this.setState({list:[]});
        if(this.state.btnPesquisar===true){
            this.setState({btnPesquisar:false});
            this.setState({loading:true});
        }
        this.setState({btnPesquisar:true});
        if(this.props.tipo_chamada===TIPO_CHAMADA_KM_PERCORRIDO)
            this.getDadosKmPercorrido();
        else if(this.props.tipo_chamada===TIPO_CHAMADA_VELOCIDADE_MEDIA)
            this.getDadosVelocidadeMedia();
        else if(this.props.tipo_chamada===TIPO_CHAMADA_QUANTIDADE_POSICOES)
            this.getDadosQuantidadePosicoes();
        else if(this.props.tipo_chamada===TIPO_CHAMADA_QUANTIDADE_EVENTOS_GERADOS)
            this.getDadosQuantidadeEventos();        
        else if(this.props.tipo_chamada===TIPO_CHAMADA_COLETAS_ENTREGAS)
            this.getDadosColetaEntrega();              
        else if(this.props.tipo_chamada===TIPO_CHAMADA_COLETAS_CLIENTES)
            this.getDadosColetasClientes();   
        else if(this.props.tipo_chamada===TIPO_CHAMADA_ENTREGAS_CLIENTES)
            this.getDadosEntregasClientes();         
        else if(this.props.tipo_chamada===TIPO_CHAMADA_COLETAS_MOTORISTAS)
            this.getDadosColetasMotoristas();               
        else if(this.props.tipo_chamada===TIPO_CHAMADA_ENTREGAS_MOTORISTAS)
            this.getDadosEntregasMotoristas(); 

        // this.setState({loading: false});
    }
    //------------------------------------------------
    get diffInDay() {
        return `${Math.floor(Math.abs((this.state.value_data_final - this.state.value_data_inicial) / (24 * 60 * 60 * 1000))) } `;
      }
    //------------------------------------------------
      renderData = () => {    
        return(
            <div>
                { this.state.loading ? 
                    <div className="centro">
                        <div className="espaco_topo2">
                            <Spinner animation="border" />
                       </div>
                    </div>

                    :
                    <div style={{height: this.state.grid_height, overflow: 'hidden'}}>                                    
                        <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                            {this.props.tipo_chamada===TIPO_CHAMADA_KM_PERCORRIDO ?  
                                <KmPercorridoGrafico veiculo_id={this.state.veiculo_id} placa={this.state.placa} list={this.state.list} MapaKseg={this.props.MapaKseg} RelPosicoesVeiModal={this} trecho_evento_list={this.state.trecho_evento_list}/>     
                                :this.props.tipo_chamada===TIPO_CHAMADA_VELOCIDADE_MEDIA ?  
                                <VelocidadeMediaGrafico veiculo_id={this.state.veiculo_id} placa={this.state.placa} list={this.state.list_velocidade_media} MapaKseg={this.props.MapaKseg} RelPosicoesVeiModal={this} trecho_evento_list={this.state.trecho_evento_list}/>     
                                :this.props.tipo_chamada===TIPO_CHAMADA_QUANTIDADE_POSICOES ?  
                                <QuantidadePosicoesGrafico veiculo_id={this.state.veiculo_id} placa={this.state.placa} list={this.state.list_quantidade_posicoes} MapaKseg={this.props.MapaKseg} RelPosicoesVeiModal={this} trecho_evento_list={this.state.trecho_evento_list}/>     
                                :this.props.tipo_chamada===TIPO_CHAMADA_QUANTIDADE_EVENTOS_GERADOS ?  
                                <QuantidadeEventosGrafico veiculo_id={this.state.veiculo_id} placa={this.state.placa} list={this.state.list_quantidade_eventos} MapaKseg={this.props.MapaKseg} RelPosicoesVeiModal={this} trecho_evento_list={this.state.trecho_evento_list}/>     
                                :this.props.tipo_chamada===TIPO_CHAMADA_COLETAS_ENTREGAS ?  
                                <ColetasEntregasChart  list={this.state.list_coleta_entrega_porcentagem} MapaKseg={this.props.MapaKseg} RelPosicoesVeiModal={this} titulo_coleta={this.state.filterStatusFilial} titulo_entrega={this.state.filterStatusFilial}/>     
                                :this.props.tipo_chamada===TIPO_CHAMADA_COLETAS_CLIENTES ?  
                                <ColetasClientesChart  list={this.state.list_quantidade_coletas_clientes} MapaKseg={this.props.MapaKseg} RelPosicoesVeiModal={this} titulo_coleta={this.state.filterStatusFilial} titulo_entrega={this.state.filterStatusFilial}/>     
                                :this.props.tipo_chamada===TIPO_CHAMADA_ENTREGAS_CLIENTES ?  
                                <EntregasClientesChart  list={this.state.list_quantidade_entregas_clientes} MapaKseg={this.props.MapaKseg} RelPosicoesVeiModal={this} titulo_coleta={this.state.filterStatusFilial} titulo_entrega={this.state.filterStatusFilial}/>     
                                :this.props.tipo_chamada===TIPO_CHAMADA_COLETAS_MOTORISTAS ?  
                                <ColetasMotoristasChart  list={this.state.list_quantidade_coletas_motoristas} MapaKseg={this.props.MapaKseg} RelPosicoesVeiModal={this} titulo_coleta={this.state.filterStatusFilial} titulo_entrega={this.state.filterStatusFilial}/>     
                                :this.props.tipo_chamada===TIPO_CHAMADA_ENTREGAS_MOTORISTAS ?  
                                <EntregasMotoristasChart list={this.state.list_quantidade_entregas_motoristas} MapaKseg={this.props.MapaKseg} RelPosicoesVeiModal={this} titulo_coleta={this.state.filterStatusFilial} titulo_entrega={this.state.filterStatusFilial}/>     
                                :""
                            }
                        </CustomScroll>
                    </div>        
                }
            </div>
        );
      }
      //------------------------------------------------
      onValueChangedDataInicial = (e) => {
        this.setState({
          data_inicial: this.formatarDateTime(e.value),
          value_data_inicial: e.value
        });
        // ToastStore.success( String(this.state.data_inicial));
      }
      //------------------------------------------------
      onValueChangedDataFinal = (e) => {
        this.setState({
          data_final: this.formatarDateTime(e.value),
          value_data_final: e.value
        });
        // ToastStore.success( String(this.state.data_final));
      }
    //--------------------------  
    onValueChangedFilialSelectBox = (e) => {
        console.log("e.value: "+String(e.value));
        var filiais = this.state.filiais;
        var list_aux = filiais.filter((item,index) =>{  
            console.log("item.descricao "+String(index)+": "+String(item.descricao));
            return item.descricao.trim() === e.value.trim();
        })

        this.setState({filterStatusFilial: e.value});
        this.getRotaFilial(list_aux[0].filial_id);
    }      
      //------------------------------------------------
      formatarMes(mes){    
        if(mes==="Jan")
            return 1;
        else if (mes==="Feb")
            return 2;
        else if (mes==="Mar")
            return 3;     
        else if (mes==="Apr")
            return 4;     
        else if (mes==="May")
            return 5;     
        else if (mes==="Jun")
            return 6; 
        else if (mes==="Jul")
            return 7; 
        else if (mes==="Aug")
            return 8; 
        else if (mes==="Sep")
            return 9; 
        else if (mes==="Oct")
            return 10;    
        else if (mes==="Nov")
            return 11;
        else if (mes==="Dec")
            return 12;             
      }
      //------------------------------------------------
      formatarDateTime(dtTime){    
        const y = String(dtTime).substring(11,15);
        const mm = this.formatarMes(String(dtTime).substring(4,7));
        const dd = String(dtTime).substring(8,10);
        const hm = String(dtTime).substring(16,21);

        // console.log("  *** dtTime: "+String(dtTime));
        // console.log("  *** y: "+y);
        // console.log("  *** mm: "+mm);
        // console.log("  *** mm: "+String(this.formatarMes(mm)));
        // console.log("  *** dd: "+dd);
        // console.log("  *** hm: "+hm);  
        const datetime = y+"-"+mm+"-"+dd+" "+hm;  // "Y-MM-DD H:M"
        return datetime;                  
      }
     //------------------------------------------------
     formatarDateTimeGrid(dtTime){
         //  sex 21:47:10   03-07-2020  
         if(dtTime===null)
            return "";

        const diaSema = String(dtTime).substring(0,3);
        const y = String(dtTime).substring(23,25);
        const mm = String(dtTime).substring(18,20);
        const dd = String(dtTime).substring(12,17);
        const hm = String(dtTime).substring(4,12);    
        const datetime = diaSema+", "+dd+"/"+mm+"/"+y+"   "+hm;  // "Y-MM-DD H:M"
        return datetime;  
     }
    //-------------------------------------------------------------------------------------
    getDadosKmPercorrido = async () => {
        try {
            this.setState({loading: true});
            let body = new FormData()
            body.append('grupo_id', localStorage.getItem('grupo_id'))
            body.append('veiculo_id', this.state.veiculo_id)
            body.append('data_inicial', this.state.data_inicial+':00')
            body.append('data_final', this.state.data_final+':00')
            body.append('token', localStorage.getItem('token'))
            body.append('temperaturas_tab', this.state.veiculo_temperaturas_tab)

            var url = "/relatorio_api/graficoKmPercorrido";
            const response = await api.post(url, body);
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                // console.log(resposta);
                // console.log(resposta['12076']);
                let list = [];          

                // ToastStore.success("resposta.length: "+ String(resposta.length));

                if(this.props.tipo_chamada=== TIPO_CHAMADA_KM_PERCORRIDO){                    
                    for (var i = 0; i < resposta.length; i++) { 
                        // console.log(resposta[i]);
                        // list.push(resposta[i]);
                        list.push({placa: resposta[i].placa, km_percorrido: resposta[i].km_percorrido});
                        this.setState({list: list});
                    }
                }       

                this.setState({loading: false});
                
            }

        } finally {
                // ToastStore.success(" list.length: "+ String(this.state.list.length));
                // ToastStore.success("PPlaca: "+ String(lastPosition.data_receb));
                // this.state.objeto.setCoords(coords, lastPosition, markerEvento_list);

            }
    };
    //--------------------------------------------------------------------------------
    getDadosVelocidadeMedia = async () => {
        // console.log("  ***this.props.data_receb: "+this.props.data_receb)
        try {
            this.setState({loading: true});
            let body = new FormData()
            body.append('grupo_id', localStorage.getItem('grupo_id'))
            body.append('veiculo_id', this.state.veiculo_id)
            body.append('data_inicial', this.state.data_inicial+':00')
            body.append('data_final', this.state.data_final+':00')
            body.append('token', localStorage.getItem('token'))
            body.append('temperaturas_tab', this.state.veiculo_temperaturas_tab)

            var url = "/relatorio_api/graficoVelocidadeMedia";
            const response = await api.post(url, body);
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                // console.log(resposta);
                // console.log(resposta['12076']);

                let list_velocidade_media = [];
                     

                // ToastStore.success("resposta.length: "+ String(resposta.length));

                // if(this.props.tipo_chamada=== TIPO_CHAMADA_VELOCIDADE_MEDIA){                    
                    for (var i = 0; i < resposta.length; i++) { 
                        // console.log(resposta[i]);
                        // list_velocidade_media.push(resposta[i]);
                        list_velocidade_media.push({placa: resposta[i].placa, velocidade_media: resposta[i].velocidade_media});
                        this.setState({list_velocidade_media: list_velocidade_media});
                    }
                // }       

                this.setState({loading: false});
                
            }

        } finally {

            }
    };
    //-------------------------------------------------------------------------------------
    getDadosQuantidadePosicoes = async () => {
        // console.log("  ***this.props.data_receb: "+this.props.data_receb)
        try {
            this.setState({loading: true});
            let body = new FormData()
            body.append('grupo_id', localStorage.getItem('grupo_id'))
            body.append('veiculo_id', this.state.veiculo_id)
            body.append('data_ini', this.state.data_inicial+':00')
            body.append('data_final', this.state.data_final+':00')
            body.append('token', localStorage.getItem('token'))
            body.append('temperaturas_tab', this.state.veiculo_temperaturas_tab)

            var url = "/relatorio_api/relatorioCountPosicoesVeiculo";
            const response = await api.post(url, body);
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                // console.log(resposta);
                // console.log(resposta['12076']);
                let list_quantidade_posicoes = [];          

                // ToastStore.success("resposta.length: "+ String(resposta.length))                 
                    for (var i = 0; i < resposta.length; i++) { 
                        // console.log(resposta[i]);
                        // list.push(resposta[i]);
                        list_quantidade_posicoes.push({placa: resposta[i].placa, quantidade_posicoes: resposta[i].count});
                        this.setState({list_quantidade_posicoes: list_quantidade_posicoes});
                    }


                this.setState({loading: false});
                
            }

        } finally {
                // ToastStore.success(" list.length: "+ String(this.state.list.length));
                // ToastStore.success("PPlaca: "+ String(lastPosition.data_receb));
                // this.state.objeto.setCoords(coords, lastPosition, markerEvento_list);

            }
    };    
    //-------------------------------------------------------------------------------------
    getDadosQuantidadeEventos = async () => {
        // console.log("  ***this.props.data_receb: "+this.props.data_receb)
        try {
            this.setState({loading: true});
            let body = new FormData()
            body.append('grupo_id', localStorage.getItem('grupo_id'))
            body.append('veiculo_id', this.state.veiculo_id)
            body.append('data_ini', this.state.data_inicial+':00')
            body.append('data_final', this.state.data_final+':00')
            body.append('token', localStorage.getItem('token'))
            body.append('temperaturas_tab', this.state.veiculo_temperaturas_tab)

            var url = "/relatorio_api/relatorioCountEventosVeiculo";
            const response = await api.post(url, body);
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                // console.log(resposta);
                // console.log(resposta['12076']);
                let list_quantidade_eventos = [];          

                // ToastStore.success("resposta.length: "+ String(resposta.length))                 
                    for (var i = 0; i < resposta.length; i++) { 
                        // console.log(resposta[i]);
                        // list.push(resposta[i]);
                        list_quantidade_eventos.push({placa: resposta[i].placa, quantidade_eventos: resposta[i].count});
                        this.setState({list_quantidade_eventos: list_quantidade_eventos});
                    }


                this.setState({loading: false});
                
            }

        } finally {}
    };        
    //-------------------------------------------------------------------------------------
    getDadosColetaEntrega = async () => {
        try {
            this.setState({loading: true});
            let rotas = this.state.rotas;
            let rotas_id_str="";
            for (var i = 0; i < rotas.length; i++) {  
                if (i==0) rotas_id_str+=rotas[i].rota_id;
                else rotas_id_str+=","+rotas[i].rota_id;
            }

            if(rotas_id_str === "") rotas_id_str = "0"

            // ToastStore.success("rotas_id_str: "+ String(rotas_id_str))

            let body = new FormData()
            body.append('rotas_id_str', rotas_id_str)  // '1,2,6,35')
            body.append('grupo_id', localStorage.getItem('grupo_id'))
            body.append('veiculo_id', this.state.veiculo_id)
            body.append('data_ini', this.state.data_inicial+':00')
            body.append('data_final', this.state.data_final+':00')
            body.append('token', localStorage.getItem('token'))
            body.append('temperaturas_tab', this.state.veiculo_temperaturas_tab)

            var url = "/relatorio_api/graficoColetaEntrega";
            const response = await api.post(url, body);
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                console.log(resposta);
                let list_coleta_entrega_porcentagem = [];        
                
                // ToastStore.success("coletas: "+ String(resposta.coletas.concluida))

                let list_coleta = [
                    {
                      nome: 'Concluído',
                      porcentagem: resposta.coletas.concluida,
                    },
                    {
                      nome: 'Aberto',
                      porcentagem: resposta.coletas.aberta,
                    },
                    {
                      nome: 'Atrasado',
                      porcentagem: resposta.coletas.atrasada,
                    },
                  ];

                let list_entrega = [  {
                    nome: 'Concluído',
                    porcentagem: resposta.entregas.concluida,
                  },
                  {
                    nome: 'Aberto',
                    porcentagem: resposta.entregas.aberta,
                  },
                  {
                    nome: 'Atrasado',
                    porcentagem: resposta.entregas.atrasada,
                  },  ]

                list_coleta_entrega_porcentagem.push(list_coleta);
                list_coleta_entrega_porcentagem.push(list_entrega);
                // this.setState({list_coleta_entrega_porcentagem: list_coleta_entrega_porcentagem});

                this.setState({ list_coleta_entrega_porcentagem: list_coleta_entrega_porcentagem }, 
                    () => { this.setState({loading:false})});

                // ToastStore.success("resposta.length: "+ String(resposta.length))                 
                    // for (var i = 0; i < resposta.length; i++) { 
                    //     // console.log(resposta[i]);
                    //     // list.push(resposta[i]);
                    //     list_quantidade_eventos.push({placa: resposta[i].placa, quantidade_eventos: resposta[i].count});
                    //     this.setState({list_quantidade_eventos: list_quantidade_eventos});
                    // }


                // this.setState({loading: false});
                
            }

        } finally {
            // this.setState({loading: false});
        }
    };           
    //-------------------------------------------------------------------------------------
    getDadosColetasClientes = async () => {
        try {
            this.setState({loading: true});
            let body = new FormData()
            body.append('grupo_id', localStorage.getItem('grupo_id'))
            body.append('veiculo_id', this.state.veiculo_id)
            body.append('data_ini', this.state.data_inicial+':00')
            body.append('data_final', this.state.data_final+':00')
            body.append('token', localStorage.getItem('token'))
            body.append('temperaturas_tab', this.state.veiculo_temperaturas_tab)

            var url = "/relatorio_api/graficoCountColetasClientes";
            const response = await api.post(url, body);
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                // console.log(resposta);
                // console.log(resposta['12076']);
                let list_quantidade_coletas_clientes = [];          

                // ToastStore.success("resposta.length: "+ String(resposta.length))                 
                    for (var i = 0; i < resposta.length; i++) { 
                        // console.log(resposta[i]);
                        // list.push(resposta[i]);
                        list_quantidade_coletas_clientes.push({cliente: resposta[i].cliente, quantidade_coletas: resposta[i].quantidade_coletas});
                        this.setState({list_quantidade_coletas_clientes: list_quantidade_coletas_clientes});
                    }

                this.setState({loading: false});            
            }
        } finally {}
    };                  
    //-------------------------------------------------------------------------------------
    getDadosEntregasClientes = async () => {
        try {
            this.setState({loading: true});
            let body = new FormData()
            body.append('grupo_id', localStorage.getItem('grupo_id'))
            body.append('veiculo_id', this.state.veiculo_id)
            body.append('data_ini', this.state.data_inicial+':00')
            body.append('data_final', this.state.data_final+':00')
            body.append('token', localStorage.getItem('token'))
            body.append('temperaturas_tab', this.state.veiculo_temperaturas_tab)

            var url = "/relatorio_api/graficoCountEntregasClientes";
            const response = await api.post(url, body);
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                // console.log(resposta);
                // console.log(resposta['12076']);
                let list_quantidade_entregas_clientes = [];          

                // ToastStore.success("resposta.length: "+ String(resposta.length))                 
                    for (var i = 0; i < resposta.length; i++) { 
                        // console.log(resposta[i]);
                        // list.push(resposta[i]);
                        list_quantidade_entregas_clientes.push({cliente: resposta[i].cliente, quantidade_entregas: resposta[i].quantidade_entregas});
                        this.setState({list_quantidade_entregas_clientes: list_quantidade_entregas_clientes});
                    }

                this.setState({loading: false});                
            }
        } finally {}
    };     
    //-------------------------------------------------------------------------------------
    getDadosColetasMotoristas = async () => {
        try {
            this.setState({loading: true});
            let body = new FormData()
            body.append('grupo_id', localStorage.getItem('grupo_id'))
            body.append('pessoa_id', this.props.MapaKseg.state.pessoa_id)
            body.append('veiculo_id', this.state.veiculo_id)
            body.append('data_ini', this.state.data_inicial+':00')
            body.append('data_final', this.state.data_final+':00')
            body.append('token', localStorage.getItem('token'))
            body.append('temperaturas_tab', this.state.veiculo_temperaturas_tab)

            var url = "/relatorio_api/graficoCountColetasMotoristas";
            const response = await api.post(url, body);
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                let list_quantidade_coletas_motoristas = [];          

                for (var i = 0; i < resposta.length; i++) { 
                    list_quantidade_coletas_motoristas.push({motorista: resposta[i].motorista, quantidade_coletas: resposta[i].quantidade_coletas});
                    this.setState({list_quantidade_coletas_motoristas: list_quantidade_coletas_motoristas});
                }
                this.setState({loading: false});                
            }
        } finally {}
    };   
    //-------------------------------------------------------------------------------------
    getDadosEntregasMotoristas = async () => {
        try {
            this.setState({loading: true});
            let body = new FormData()
            body.append('grupo_id', localStorage.getItem('grupo_id'))
            body.append('pessoa_id', this.props.MapaKseg.state.pessoa_id)
            body.append('veiculo_id', this.state.veiculo_id)
            body.append('data_ini', this.state.data_inicial+':00')
            body.append('data_final', this.state.data_final+':00')
            body.append('token', localStorage.getItem('token'))
            body.append('temperaturas_tab', this.state.veiculo_temperaturas_tab)

            var url = "/relatorio_api/graficoCountEntregasMotoristas";
            const response = await api.post(url, body);
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                let list_quantidade_entregas_motoristas = [];          

                for (var i = 0; i < resposta.length; i++) { 
                    list_quantidade_entregas_motoristas.push({motorista: resposta[i].motorista, quantidade_entregas: resposta[i].quantidade_entregas});
                    this.setState({list_quantidade_entregas_motoristas: list_quantidade_entregas_motoristas});
                }
                this.setState({loading: false});                
            }
        } finally {}
    };                
    //--------------------------------------------------------------------------------------
    setEventosCount = (eventos_count) =>{
        let list = this.state.list;
        for(var i=0; i<eventos_count.length; i++) {
            for(var j = 0; j<list.length; j++) {
                if(eventos_count[i].recebimento_id === list[j].recebimento_id ){
                    list[j].eventos_count = eventos_count[i].count;
                    if(eventos_count[i].count===1) list[j].eventos_count_icon = require("./img/circle1.png")
                    else if(eventos_count[i].count===2) list[j].eventos_count_icon = require("./img/circle2.png")
                    else if(eventos_count[i].count===3) list[j].eventos_count_icon = require("./img/circle3.png")
                    else if(eventos_count[i].count===4) list[j].eventos_count_icon = require("./img/circle4.png")
                    else if(eventos_count[i].count===5) list[j].eventos_count_icon = require("./img/circle5.png")
                    else if(eventos_count[i].count>5) list[j].eventos_count_icon = require("./img/circle5plus.png")
                    else if(eventos_count[i].count>9) list[j].eventos_count_icon = require("./img/circle9plus.png")
                    
                    break;
                }
            }
        }
        this.setState({list:list});
    };
    //--------------------
    getDataInicial(){
        var data_inicial = new Date();
        // data_inicial.setHours(data_inicial.getHours() - 1);
        data_inicial.setDate(data_inicial.getDate() - 30);
        return data_inicial;  
     }
     //--------------------
     minimizarModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        if(this.props.tipo_chamada=== TIPO_CHAMADA_KM_PERCORRIDO)
            this.props.Modals.closeGraficosKmPercorridaModal();
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_VELOCIDADE_MEDIA)
            this.props.Modals.closeGraficosVelocidadeMediaModal();
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_QUANTIDADE_POSICOES)
            this.props.Modals.closeGraficosQuantidadePosicoesModal();
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_QUANTIDADE_EVENTOS_GERADOS)
            this.props.Modals.closeGraficosQuantidadeEventosModal();        
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_COLETAS_ENTREGAS)
            this.props.Modals.closeGraficosColetasEntregasModal();   
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_COLETAS_CLIENTES)
            this.props.Modals.closeGraficosColetasClientesModal();    
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_ENTREGAS_CLIENTES)
            this.props.Modals.closeGraficosEntregasClientesModal();      
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_COLETAS_MOTORISTAS)
            this.props.Modals.closeGraficosColetasMotoristasModal();      
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_ENTREGAS_MOTORISTAS)
            this.props.Modals.closeGraficosEntregasMotoristasModal();                               

        this.props.MapaKseg.setState({sidenav_visible:true});
    };
    //--------------------
    maximizarModal = () =>{
        var grid_h = String(window.innerHeight - 150)+"px";
        this.setState({width: window.innerWidth
                        ,height: window.innerHeight
                        ,drag: "drag0"
                        ,grid_height: grid_h});
    };
    //--------------------
    restoreModal = () =>{
        var grid_h = String(400)+"px";
        this.setState({width: 1000
                        ,height: 520
                        ,drag: "drag3"
                        ,grid_height: grid_h});                     
    };
    //---------close----------
    fecharModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "zoom"});
        this.setState({minimizar:false});
        if(this.props.tipo_chamada=== TIPO_CHAMADA_KM_PERCORRIDO)
            this.props.Modals.closeGraficosKmPercorridaModal();
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_VELOCIDADE_MEDIA)
            this.props.Modals.closeGraficosVelocidadeMediaModal();
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_QUANTIDADE_POSICOES)
            this.props.Modals.closeGraficosQuantidadePosicoesModal();      
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_QUANTIDADE_EVENTOS_GERADOS)
            this.props.Modals.closeGraficosQuantidadeEventosModal();   
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_COLETAS_ENTREGAS)
            this.props.Modals.closeGraficosColetasEntregasModal();     
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_COLETAS_CLIENTES)
            this.props.Modals.closeGraficosColetasClientesModal();        
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_ENTREGAS_CLIENTES)
            this.props.Modals.closeGraficosEntregasClientesModal();                       
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_COLETAS_MOTORISTAS)
            this.props.Modals.closeGraficosColetasMotoristasModal();      
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_ENTREGAS_MOTORISTAS)
            this.props.Modals.closeGraficosEntregasMotoristasModal();           

        this.props.MapaKseg.setState({sidenav_visible:true});    
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false){
            if(this.props.tipo_chamada=== TIPO_CHAMADA_KM_PERCORRIDO){
                if(this.state.minimizar)
                    this.props.Modals.closeGraficosKmPercorridaModal();
                else this.props.Modals.desativarGraficosKmPercorridaModal();    
            }else if(this.props.tipo_chamada=== TIPO_CHAMADA_VELOCIDADE_MEDIA){
                if(this.state.minimizar)
                    this.props.Modals.closeGraficosVelocidadeMediaModal();
                else this.props.Modals.desativarGraficosVelocidadeMediaModal(); 
            }else if(this.props.tipo_chamada=== TIPO_CHAMADA_QUANTIDADE_POSICOES){
                if(this.state.minimizar)
                    this.props.Modals.closeGraficosQuantidadePosicoesModal();
                else this.props.Modals.desativarGraficosQuantidadePosicoesModal(); 
            }else if(this.props.tipo_chamada=== TIPO_CHAMADA_QUANTIDADE_EVENTOS_GERADOS){
                if(this.state.minimizar)
                    this.props.Modals.closeGraficosQuantidadeEventosModal();
                else this.props.Modals.desativarGraficosQuantidadeEventosModal(); 
            }else if(this.props.tipo_chamada=== TIPO_CHAMADA_COLETAS_ENTREGAS){
                if(this.state.minimizar)
                    this.props.Modals.closeGraficosColetasEntregasModal();
                else this.props.Modals.desativarGraficosColetasEntregasModal(); 
            }else if(this.props.tipo_chamada=== TIPO_CHAMADA_COLETAS_CLIENTES){
                if(this.state.minimizar)
                    this.props.Modals.closeGraficosColetasClientesModal();
                else this.props.Modals.desativarGraficosColetasClientesModal(); 
            }else if(this.props.tipo_chamada=== TIPO_CHAMADA_ENTREGAS_CLIENTES){
                if(this.state.minimizar)
                    this.props.Modals.closeGraficosEntregasClientesModal();
                else this.props.Modals.desativarGraficosEntregasClientesModal(); 
            }else if(this.props.tipo_chamada=== TIPO_CHAMADA_COLETAS_MOTORISTAS){
                if(this.state.minimizar)
                    this.props.Modals.closeGraficosColetasMotoristasModal();
                else this.props.Modals.desativarGraficosColetasMotoristasModal(); 
            }else if(this.props.tipo_chamada=== TIPO_CHAMADA_ENTREGAS_MOTORISTAS){
                if(this.state.minimizar)
                    this.props.Modals.closeGraficosEntregasMotoristasModal();
                else this.props.Modals.desativarGraficosEntregasMotoristasModal(); 
            }
        }     
    };
    //---------------------
    setIcone = (tipo_evento_id) =>{    
        switch(tipo_evento_id) {
            case 1:
            return require("./img/location_marker48.png"); 
            case 3:
                return require("./img/ic_marker_bloqueio.png");                                           
            case 42:
                return require("./img/ic_antenna_black_48.png"); 
            case 66:
                return require("./img/key_off.png");                              
            case 67:
                return require("./img/card_green48.png"); 
            case 68:
                return require("./img/marker_porta_aberta36.png");  
            case 69:
                return require("./img/marker_porta_lateral_aberta2.png");  
            case 70:
                return require("./img/alert_octagon24x24.png");                     
            case 71:
                return require("./img/ic_antenna_black_48.png");
            default:
            return require("./img/alert_circle48.png");  
        }
    };
    //------------------------
    //----------------------------------------------------
    dateDiff=(data_inicio, data_final)=>{
        // data_inicio: sex 21:47:10   03-07-2020 
        if(String(data_inicio).length> 19){
            var ano_anterior = data_inicio.substring(21,25);
            var mes_anterior = data_inicio.substring(18,20);
            var dia_anterior = data_inicio.substring(15,17);
            var hora_anterior = data_inicio.substring(4,12);
            var a = moment(ano_anterior+"-"+mes_anterior+"-"+dia_anterior+"T"+hora_anterior+"-03:00");  //2020-03-17T17:39:31-03:00
    
            var ano = data_final.substring(21,25);
            var mes = data_final.substring(18,20);
            var dia = data_final.substring(15,17);
            var hora = data_final.substring(4,12);
            var b = moment(ano+"-"+mes+"-"+dia+"T"+hora+"-03:00");  //2020-03-17T17:39:31-03:00
            const precise_diff =  moment.preciseDiff(a, b, true); 
        
            const diff = b.diff(a);
            const diffDuration = moment.duration(diff);
            const diff_minutes = parseInt(diffDuration.asMinutes())
            // const diff_days = parseInt(diffDuration.asDays())
        
            // // alert(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes");
            // alert(diff_minutes + " minutes");
        
            // console.log("****  diff_minutes: "+String(diff_minutes));
        
            // return String(ano_anterior+"-"+mes_anterior+"-"+dia_anterior+"T"+hora_anterior+"-03:00");
            // return "("+String(diff_minutes)+"min)";
        
            if(precise_diff.minutes===0)
                if(precise_diff.seconds===0)
                    return "0";
                else return precise_diff.seconds+"seg";
            else if(precise_diff.hours === 0)
                return precise_diff.minutes+"min";
            else if(precise_diff.days === 0){
                if(precise_diff.hours===1)
                return String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
                else return String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
            }else if(precise_diff.months === 0){
                if(precise_diff.days===1)
                return String(precise_diff.days)+"dia "+String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
                else return String(precise_diff.days)+"dias "+String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
            }else if(precise_diff.years === 0){
                if(precise_diff.months===1)
                return String(precise_diff.months)+"mes "+String(precise_diff.hours)+"dia "+String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
                else return String(precise_diff.months)+"meses "+String(precise_diff.hours)+"dia "+String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
            }
        }
    }
    //------------------------
    render(){
        var data_hoje = new Date();
        var data_inicial = new Date();
        // data_inicial.setHours(data_inicial.getHours() - 1);
        data_inicial.setDate(data_inicial.getDate() - 30);

        const array_tipo_chamada = [TIPO_CHAMADA_KM_PERCORRIDO, TIPO_CHAMADA_VELOCIDADE_MEDIA, TIPO_CHAMADA_QUANTIDADE_POSICOES, TIPO_CHAMADA_QUANTIDADE_EVENTOS_GERADOS
            , TIPO_CHAMADA_COLETAS_ENTREGAS, TIPO_CHAMADA_COLETAS_CLIENTES, TIPO_CHAMADA_ENTREGAS_CLIENTES, TIPO_CHAMADA_COLETAS_MOTORISTAS
        ,TIPO_CHAMADA_ENTREGAS_MOTORISTAS];

        const orders = [{
            'ID': 1,
            'recebimento_id': 35703,
            'data_receb': '2017/04/13 9:00',
            'latitude': 11800,
            'longitude': 21800,
            'backup': false
          }, {
            'ID': 4,
            'recebimento_id': 35711,
            'data_receb': '2017/01/13 9:00',
            'latitude': 16050,
            'longitude': 12300,
            'backup': false
          }];

          const filialSelectBox =       
          <SelectBox
            items={this.state.filiais_descricao}
            value={this.state.filterStatusFilial}
            onValueChanged={this.onValueChangedFilialSelectBox}
            />  ;

        return(
            <div className={this.state.drag} >
                <Draggable   handle="strong"  >                
                    <div >                    
                        <Rodal 
                            // visible={this.props.Modals.state.relPosicoesVeiModalShow}
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={this.state.width}
                            height={this.state.height}
                            onAnimationEnd={()=>this.desativarModal()}
                            >  
                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={true} maximizar={true} titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">

                                    <Form inline className="espaco_bottom"> 
                                        {this.props.tipo_chamada===TIPO_CHAMADA_COLETAS_ENTREGAS ?
                                            this.state.filiais_descricao.length >0 ?
                                                filialSelectBox :""
                                            :""}

                                        { !array_tipo_chamada.includes(this.props.tipo_chamada) ?
                                            <div className="headerSearch"> 
                                                <FilterSelect list={this.props.filter_list} tipo={2} RelPosicoesVeiModal={this} />
                                            </div>
                                        :""}
                                    
                                        {this.props.tipo_chamada!==TIPO_CHAMADA_COLETAS_ENTREGAS ?
                                            <DateBox displayFormat="dd/MM/yyyy HH:mm" placeholder="Dada inicial" 
                                                className="headerDateTime"  type="datetime" 
                                                onValueChanged={this.onValueChangedDataInicial}
                                                defaultValue={data_inicial} />    
                                        :""}
                                        {this.props.tipo_chamada!==TIPO_CHAMADA_COLETAS_ENTREGAS ?
                                            <DateBox displayFormat="dd/MM/yyyy HH:mm" placeholder="Data final" 
                                                className="headerDateTime"  type="datetime" 
                                                onValueChanged={this.onValueChangedDataFinal}
                                                defaultValue={data_hoje}/> 
                                        :""}        
                                        

                                        <div className="espaco_esq">
                                            <Button variant="primary" onClick={()=>this.onClickPesquisar()}>
                                                <img src={"img/lupa.png"} alt="" Width="20" />
                                            </Button>
                                        </div>

                                    </Form>


                                    {/* <div className="dx-field"> */}
                                        {/* <div className="dx-field-label">Date and time</div> */}
                                        {/* <div className="dx-field-value"> */}
                                        {/* <DateBox defaultValue={this.now} type="datetime" /> */}
                                        {/* </div> */}

                                    {/* --------- */}


                                    {this.state.btnPesquisar ?
                                        this.renderData()
                                    :<div className={"espaco_bottom2"}></div>}

                                    {/* { this.state.loading ? 
                                            <div className="centro">
                                                <Spinner animation="border" />
                                            </div>

                                            : <DtGrid veiculo_id={666} orders={this.state.list} />
                                        } */}


                                    {/* { this.state.loading ? 
                                        <div className="centro">
                                            <Spinner animation="border" />
                                        </div>

                                        : this.state.list.map((posicao) =>
                                                <div>
                                                <ListGroup>
                                                    <ListGroup.Item action variant="">
                                                        <div>
                                                            {String(posicao.backup)}
                                                        </div>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                                </div> 
                                            )
                                    } */}

                                        
                                </div>


                        
                            </div>

                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>

            
        );
    }
}    