import React from 'react';
import DataSource from 'devextreme/data/data_source';
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Legend,
  ValueAxis,
  Title,
  Export,
  Tooltip,
  Border,
  Size,
  CommonAnnotationSettings,
  Label,
} from 'devextreme-react/chart';
import service from '../dados/jornada_linha_tempo_data.js';


const dataSource = service.getMaleAgeData();
var moment = require('moment');


function somarTempoEventos0(eventos) {
  const linha_tempo = [{evento1:12,evento2:13,evento3:14,evento4:15,evento5:16,evento6:17,}];
  let soma=0;
  console.log(" eventos: ");
  console.log(eventos);
  console.log(" eventos.length: "+String(eventos.length));
  for (var i = 0; i < eventos.length; i++) {  
    // if (i < eventos.length)
    console.log(" datas:");
    console.log(" data_inicial:"+String(eventos[i].data_inicial));
    console.log(" data_final:"+String(eventos[i].data_final));

    // let data_inicial = new Date(eventos[i].data_inicial)
    // let data_final = new Date(eventos[i].data_final )
    // let tempo = data_final - data_inicial;

    // linha_tempo[i].evento1 = tempo;
  }

  console.log(" ----> linha_tempo:");
  console.log(linha_tempo);

  return linha_tempo;
}
  //----------------------------------------------------
  const dateDiff=(data_inicio, data_final)=>{
    // data_inicio: sex 21:47:10   03-07-2020 
    // qua 03/04/2024 11:58
    if(String(data_inicio).length> 19){
        // var ano_inicio = data_inicio.substring(21,25);
        // var mes_inicio = data_inicio.substring(18,20);
        // var dia_inicio = data_inicio.substring(15,17);
        // var hora_inicio = data_inicio.substring(4,12);

        var ano_inicio = data_inicio.substring(10,14);
        var mes_inicio = data_inicio.substring(7,9);
        var dia_inicio = data_inicio.substring(4,6);
        var hora_inicio = data_inicio.substring(16,21);
        console.log("ano_inicio: "+String(ano_inicio));
        console.log("mes_inicio: "+String(mes_inicio));
        console.log("dia_inicio: "+String(dia_inicio));
        console.log("hora_inicio: "+String(hora_inicio));
        console.log(ano_inicio+"-"+mes_inicio+"-"+dia_inicio+"T"+hora_inicio+"-03:00");

        var a = moment(ano_inicio+"-"+mes_inicio+"-"+dia_inicio+"T"+hora_inicio+"-03:00");  //2020-03-17T17:39:31-03:00
        console.log("a: "+String(a));

        var ano = data_final.substring(10,14);
        var mes = data_final.substring(7,9);
        var dia = data_final.substring(4,6);
        var hora = data_final.substring(16,21);
        console.log(ano+"-"+mes+"-"+dia+"T"+hora+"-03:00");
        var b = moment(ano+"-"+mes+"-"+dia+"T"+hora+"-03:00");  //2020-03-17T17:39:31-03:00        
        console.log("b: "+String(b));
        const precise_diff =  moment.preciseDiff(a, b, true); 
        console.log("precise_diff: "+String(precise_diff));
    
        const diff = b.diff(a);
        const diffDuration = moment.duration(diff);
        let diff_minutes = parseInt(diffDuration.asMinutes())
        // return diff_minutes
        // const diff_days = parseInt(diffDuration.asDays())
    
        // // alert(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes");
        // alert(diff_minutes + " minutes");
    
        // console.log("****  diff_minutes: "+String(diff_minutes));
    
        // return String(ano_anterior+"-"+mes_anterior+"-"+dia_anterior+"T"+hora_anterior+"-03:00");
        // return "("+String(diff_minutes)+"min)";
    
        let diff_str = "";

        if(precise_diff.minutes===0)
            if(precise_diff.seconds===0)
                diff_str = "0";
            else diff_str = precise_diff.seconds+"seg";
        else if(precise_diff.hours === 0)
          diff_str = precise_diff.minutes+"min";
        else if(precise_diff.days === 0){
            if(precise_diff.hours===1)
              diff_str = String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
            else diff_str = String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
        }else if(precise_diff.months === 0){
            if(precise_diff.days===1)
              diff_str = String(precise_diff.days)+"dia "+String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
            else diff_str = String(precise_diff.days)+"dias "+String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
        }else if(precise_diff.years === 0){
            if(precise_diff.months===1)
              diff_str = String(precise_diff.months)+"mes "+String(precise_diff.hours)+"dia "+String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
            else diff_str = String(precise_diff.months)+"meses "+String(precise_diff.hours)+"dia "+String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
        }


        // return diff_minutes, diff_str

        return {
          diff_minutes: diff_minutes, 
          diff_str: diff_str
      };
    }
}
//--------------------------------------------------------
function diff_minutes(dt2, dt1) 
 {
  // Calculate the difference in milliseconds between the two provided dates and convert it to seconds
  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
  // Convert the difference from seconds to minutes
  diff /= 60;
  // Return the absolute value of the rounded difference in minutes
  return Math.abs(Math.round(diff));
 }
//--------------------------------------------------------
const somarTempoEventos2 = (data_source) => {
  const linha_tempo = [{
        jornada_id:1985,
        state: 'Linha \n Tempo'}];

  data_source.load();  
  let eventos = data_source.items(); 
  console.log(eventos[0]);

  console.log(" eventos.length: "+String(eventos.length));

  let data_inicial;
  let data_final;
  let tempo = 0;

  for (var i = 0; i < eventos.length; i++) {  
    if (String(eventos[i].data_final) !== ""){
      tempo = dateDiff(eventos[i].data_inicial, eventos[i].data_final )
      console.log(" tempo:");
      console.log(tempo);
    }else{
      data_final = moment().format('ddd DD/MM/YYYY HH:mm');
      tempo = dateDiff(eventos[i].data_inicial, data_final )
      console.log(" tempo:");
      console.log(tempo);
    }

    // let valueField = "";
    // if(eventos[i].evento === "Início Jornada") valueField = "evento1";
    // if(eventos[i].evento === "Parada Refeição") valueField = "evento2";
    // if(eventos[i].evento === "Reinício Jornada") valueField = "evento3";
    // if(eventos[i].evento === "Espera") valueField = "evento4";
    // if(eventos[i].evento === "Finalizada") valueField = "evento5";
    // if(eventos[i].evento === "Repouso") valueField = "evento6";

    // linha_tempo[0][valueField] = tempo;

    linha_tempo[0]["evento"+String(i+1)] = tempo;
  }

  console.log(" ----> linha_tempo:");
  console.log(linha_tempo);

  return linha_tempo;
};
  //----------------------------------------------------
  const formatDiaSemana=(str)=>{
    let dia_semana = "";
    if(str == "Mon") dia_semana = "seg";
    else if(str == "Tue") dia_semana = "ter";
    else if(str == "Wed") dia_semana = "qua";
    else if(str == "Thu") dia_semana = "qui";
    else if(str == "Frid") dia_semana = "sex";
    else if(str == "Sat") dia_semana = "sab";
    else if(str == "Sun") dia_semana = "dom";
    

    return dia_semana;
  }
//--------------------------------------------------------
const somarTempoEventos = (data_source) => {

  const linha_tempo = [];

  data_source.load();  
  let eventos_aux = data_source.items(); 

  let eventos = eventos_aux.sort((a, b) => a.evento_jornada_motorista_id - b.evento_jornada_motorista_id);
  // let eventos = eventos_aux;
  // console.log(eventos[0]);

  // console.log(" eventos.length: "+String(eventos.length));

  let data_inicial;
  let data_final;
  let tempo = 0;

  for (var i = 0; i < eventos.length; i++) {  
    data_final = eventos[i].data_final;

    if (String(data_final) == ""){
     data_final = moment().format('ddd DD/MM/YYYY  HH:mm');
     let dia_semana = formatDiaSemana(String(data_final.substring(0,3)));
     data_final = dia_semana + data_final.substring(3,22);
    }

    // console.log("-----------------");
    // console.log("-----tempo_evento_diff_minutes------");
    // console.log(eventos[i].tempo_evento_diff_minutes);
    // console.log("data_inicial: "+String(eventos[i].data_inicial));
    // console.log("data_final: "+String(eventos[i].data_final));
    // console.log(eventos[i].data_final);
    // console.log("length: "+String(eventos[i].data_final).length);
    // console.log("-----------------");
    
    // if (String(data_final) != ""){
    //   tempo = dateDiff(eventos[i].data_inicial+":00", data_final+":00" );
    //   // console.log(" tempo:");
    //   // console.log(tempo);
    // }else{
    //   data_final = moment().format('ddd DD/MM/YYYY  HH:mm');
    //   tempo = dateDiff(eventos[i].data_inicial+":00", data_final );
    //   // console.log(" tempo:");
    //   // console.log(tempo);
    // }

    let value_field = "evento"+String(i+1);
    let cor = "#98d4b4"

    if(i+1 == 1) cor = "#98d4b4";
    else if (i+1 == 2) cor = "#e8d979";
    else if (i+1 == 3) cor = "#B77FEB";
    else if (i+1 == 4) cor = "#7FEBD7";
    else if (i+1 == 5) cor = "#F45750";
    else if (i+1 == 6) cor = "#F1A85F";
    else if (i+1 == 7) cor = "#d9f15f";
    else if (i+1 == 8) cor = "#5ff1be";
    else if (i+1 == 9) cor = "#99f15f";
    else if (i+1 == 10) cor = "#5f72f1";
    else if (i+1 == 11) cor = "#f15fe0";
    else if (i+1 == 12) cor = "#f15f72";
    else if (i+1 == 13) cor = "#3E57BC";
    else if (i+1 == 14) cor = "#3FC77B";
    else if (i+1 == 15) cor = "#AABB3C";
    else if (i+1 == 16) cor = "#C28032";
    else if (i+1 == 17) cor = "#B22563";
    else if (i+1 == 18) cor = "#149B99";
    else if (i+1 == 19) cor = "#85149B";
    else if (i+1 == 20) cor = "#C83116";


    linha_tempo.push({
      "jornada_id":1985,
      "state": 'Linha \n Tempo',
      "tipo_evento_jornada_motorista": eventos[i].tipo_evento_jornada_motorista,
      "data_inicial": eventos[i].data_inicial,
      "data_final": data_final,
      // "soma": tempo.diff_minutes,
      "soma": parseInt(eventos[i].tempo_evento_diff_minutes),
      "cor" : cor,
      // "tempo_str": tempo.diff_str,
      "tempo_str":  String(eventos[i].tempo_evento_diff_str),
    });

    linha_tempo[i]["evento"+String(i+1)] = eventos[i].tempo_evento_diff_minutes;
  }

  return linha_tempo;
};
// ----------------------------------------------------------------
function customizeItems(items) {
  const sortedItems = [];
  items.forEach((item) => {
    const startIndex = item.series.stack === 'male' ? 0 : 3;
    sortedItems.splice(startIndex, 0, item);
  });
  return sortedItems;
}
// ----------------------------------------------------------------
const customizeTooltip= (arg) => {
  return {
    // text: `${arg.percentText} - ${arg.valueText}`,
    text: `${arg.percentText} - ${arg.seriesName}`,
  };
}
// ----------------------------------------------------------------
const customizeTooltip8 = (arg) => ({
  html: `<div><div class="tooltip-header">${arg.percentText} - ${arg.seriesName}</div>
  <div class="tooltip-body"><div class="series-name"><span class='top-series-name'>${arg.points[0].seriesName}</span>: </div>
  <div class="value-text"><span class='top-series-value'>${arg.points[0].valueText}</span></div>
  <div class="series-name"><span class='bottom-series-name'>${arg.points[1].seriesName}</span>: </div>
  <div class="value-text"><span class='bottom-series-value'>${arg.points[1].valueText}</span>% </div></div>
  </div>`,
});
//----------------------------------------------------------------
const customizeToolti4 = (arg) => ({
  text: `shitValue: $${arg.shitValue}<br/>`,
  });
//------------------------------------------------------------
function customizeTooltip6(pointInfo) {
  return {
    html: `<div><div class="tooltip-header">${
      pointInfo.argumentText
    }</div><div class="tooltip-body"><div class="series-name"><span class='top-series-name'>${
      pointInfo.points[0].seriesName
    }</span>: </div><div class="value-text"><span class='top-series-value'>${
      pointInfo.points[0].valueText
    }</span></div><div class="series-name"><span class='bottom-series-name'>${
      pointInfo.points[1].seriesName
    }</span>: </div><div class="value-text"><span class='bottom-series-value'>${
      pointInfo.points[1].valueText
    }</span>% </div></div></div>`,
  };
}  
//------------------------------------------------------------
function customizeTooltip7(pointInfo) {
  return {
    html: `<div><div class="tooltip-header">${
      pointInfo.argumentText
    }</div><div class="tooltip-body"><div class="series-name"><span class='top-series-name'>${
      pointInfo.seriesName
    }</span>: </div><div class="value-text"><span class='top-series-value'>${
      pointInfo.valueText
    }</span>% </div></div></div>`,
  };
}  
// ----------------------------------------------------------------
// function JornadaLinhaTempo() {
  const JornadaLinhaTempo = (props) => {
    let linha_tempo = somarTempoEventos(props.data_source);

  return (
    <Chart
      id="chart"
      // dataSource={dataSource}
      dataSource={linha_tempo}
      rotated={true}      
    >
      <Size height={150} />

      <CommonSeriesSettings
        argumentField="state"
        type="stackedbar"
      />

      <ValueAxis>    
        <Label visible={false} />
      </ValueAxis>

      {

      linha_tempo.map((item, index) => {
        return (
          <Series
              valueField={"evento"+String(index+1)}
              // name={"evento"+String(index+1)}
              // name={item.evento}
              name={item.tipo_evento_jornada_motorista+"\n"
              +item.data_inicial+" - "
              +item.data_final +"\n"
              +item.tempo_str}

              color={item.cor}
            />
        );
      })

      }


      <Export enabled={false} />
      <Legend visible={false} />

      <Tooltip
        enabled={true}
        shared={true}
        customizeTooltip={customizeTooltip}
      />
      
    </Chart>
  );
}
export default JornadaLinhaTempo;
