import  {Component} from 'react';
import api from '../api';

export default class PostVeiculoCad extends Component{
    constructor(props, objeto, MapaKseg ){
        super(props);
        this.state = {
             objeto: objeto,
             MapaKseg: MapaKseg
       }
    }
    //------------------------------------------------
    post = async () => {
        let veiculo_idd= 0;
        try {        
            let editados = this.state.objeto.state.editados;
            let veiculo = this.state.objeto.state.veiculo;
            veiculo_idd = veiculo.veiculo_id;

            let response = null;
            let responseJson = null;
            let body =null;            

            if(editados.placa || editados.chip || editados.pessoa || editados.edit_entrada_saida ){

                body = new FormData();
                body.append('token', localStorage.getItem('token'));
                body.append('veiculo_id', veiculo.veiculo_id);
                if(veiculo.grupos.length > 0) body.append('grupo_id_atual', veiculo.grupos[0].grupo_id);
                else body.append('grupo_id_atual', "3");  // 3 - Vazio

                if(editados.nome) body.append('nome', veiculo.nome);
                if(editados.login) body.append('login', veiculo.login);
                if(editados.senha) body.append('senha', veiculo.senha);
                if(editados.pessoa) body.append('pessoa_id', veiculo.pessoa_id);
                if(editados.ativo) body.append('ativo', veiculo.ativo);
                // if(editados.tipo_usuario) body.append('tipo_usuario_id', usuario.tipo_usuario_id);

                response = await api.post('/cadastro_api/postUsuarioPessoa', body);

                if (response.data !== null  &&  response.status !== 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json); 
                    veiculo_idd = resposta.veiculo_id; 
                }

            }
             
            if(veiculo_idd != 0){
                if(editados.grupos==true || editados.areas==true){
                    let grupos = veiculo.grupos.filter((grupo,index) =>{ return grupo.operacao !== 0;});
                    
                    if(editados.areas) grupos = veiculo.areas.filter((grupo,index) =>{ return grupo.operacao !== 0;});

                    if(grupos.length >0){
                        grupos.sort((a,b) => a.operacao - b.operacao);
                        let list_json =[];

                        for (var i = 0; i < grupos.length; i++) {   
                            list_json.push(JSON.stringify({
                                grupo_id: grupos[i].grupo_id
                                , veiculo_id: veiculo_idd
                                , operacao: grupos[i].operacao }));
                        }

                        body = new FormData();
                        body.append('token', localStorage.getItem('token'));
                        body.append('list_json', list_json);

                        response = await api.post('/cadastro_api/postUsuarioGrupos', body);

                        if (response.data !== null  &&  response.status !== 403){
                        responseJson = JSON.stringify(response.data)
                        var req = { json: responseJson };
                        var resposta = JSON.parse(req.json); 
                            // console.log(resposta.poligono_id);
                            // var coleta_id = resposta.coleta_id;    
                            // nova_coleta.coleta_id = resposta.resposta; 
                        // this.state.MapaKseg.toast(" "+String(resposta.resposta ));

                        }
                    }
                }

                if(editados.grupos){                    
                    let grupos = veiculo.grupos.filter((grupo,index) =>{ return grupo.operacao !== 0;});

                    if(grupos.length >0){
                        grupos.sort((a,b) => a.operacao - b.operacao);
                        let list_json =[];
                        for (var i = 0; i < grupos.length; i++) {   
                            list_json.push(JSON.stringify({
                                grupo_id: grupos[i].grupo_id
                                , veiculo_id: veiculo_idd
                                , operacao: grupos[i].operacao }));
                        }

                        body = new FormData();
                        body.append('token', localStorage.getItem('token'));
                        body.append('list_json', list_json);

                        response = await api.post('/cadastro_api/postGrupoVeiculos', body);

                        // if (response.data !== null  &&  response.status !== 403){
                        // responseJson = JSON.stringify(response.data)
                        // var req = { json: responseJson };
                        // var resposta = JSON.parse(req.json); 
                        //     // console.log(resposta.poligono_id);
                        //     // var coleta_id = resposta.coleta_id;    
                        //     // nova_coleta.coleta_id = resposta.resposta; 
                        // // this.state.MapaKseg.toast(" "+String(resposta.resposta ));

                        // }
                    }
                }   

                if(editados.rotas){                    
                    let rotas = veiculo.rotas.filter((rota,index) =>{ return rota.operacao !== 0;});

                    if(rotas.length >0){
                        rotas.sort((a,b) => a.operacao - b.operacao);
                        let list_json =[];
                        for (var i = 0; i < rotas.length; i++) {   
                            list_json.push(JSON.stringify({
                                rota_id: rotas[i].rota_id
                                , veiculo_id: veiculo_idd
                                , operacao: rotas[i].operacao }));
                        }

                        body = new FormData();
                        body.append('token', localStorage.getItem('token'));
                        body.append('list_json', list_json);

                        response = await api.post('/cadastro_api/postUsuarioRotas', body);
                    }
                }  

                if(editados.filiais){                    
                    let filiais = veiculo.filiais.filter((filial,index) =>{ return filial.operacao !== 0;});

                    if(filiais.length >0){
                        filiais.sort((a,b) => a.operacao - b.operacao);
                        let list_json =[];
                        for (var i = 0; i < filiais.length; i++) {   
                            list_json.push(JSON.stringify({
                                filial_id: filiais[i].filial_id
                                , veiculo_id: veiculo_idd
                                , operacao: filiais[i].operacao }));
                        }

                        body = new FormData();
                        body.append('token', localStorage.getItem('token'));
                        body.append('list_json', list_json);

                        response = await api.post('/cadastro_api/postUsuarioFiliais', body);
                    }
                }  
            }

        } finally {      
            let veiculo = this.state.objeto.state.veiculo;
            veiculo.veiculo_id = veiculo_idd;
            this.state.objeto.setState({veiculo: veiculo});
            this.state.objeto.concluido();
            }
    };
}