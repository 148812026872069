import React, {Component} from 'react';
import {Modal, ListGroup, Spinner, Button} from 'react-bootstrap';
import './estilo.css';


export class CabecalhoModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            maximizar_icon:require('./img/window_maximize24.png'),
            maximizado: false,
        };
      }
    //-------------------------
    maximizarModal = () =>{
        if (this.state.maximizado){
            this.setState({maximizar_icon: require('./img/window_maximize24.png')
                            ,maximizado: false});
            this.props.Modal.restoreModal();
            this.props.Modal.props.MapaKseg.setState({sidenav_visible:true});

        }else{
            this.setState({maximizar_icon: require('./img/window_restore24.png')
                            ,maximizado: true});
            this.props.Modal.maximizarModal();
            this.props.Modal.props.MapaKseg.setState({sidenav_visible:false});
            this.props.Modal.props.MapaKseg.setState({sidenav_div:"sidenav_invisible"});
        }
    };
    //-------------------------
    minimizarModal = () =>{
        this.props.Modal.props.MapaKseg.setState({sidenav_visible:true});
        this.props.Modal.minimizarModal();
        this.props.Modal.props.MapaKseg.setState({sidenav_div:"sidenav_visible"});
    }
    //-------------------------
    fecharModal = () =>{
        this.props.Modal.props.MapaKseg.setState({sidenav_visible:true});
        this.props.Modal.props.MapaKseg.setState({sidenav_div:"sidenav_visible"});
        this.props.Modal.fecharModal();
    }
    //-------------------------
    render(){
        //--------------------
        return(
            <div className="cabecalho"> 
                <label className="container_center"> {this.props.titulo} </label>
                <div className="botoes_window">
                    {this.props.minimizar ?
                        <Button onClick={()=>this.minimizarModal()} variant="light" className="espaco_r_t_b">
                            <img  src={require('./img/window_minimize.png')}/>
                        </Button>                        
                        : ""}
                    
                    {this.props.maximizar ?
                        <Button onClick={()=>this.maximizarModal()} variant="light" className="espaco_r_t_b">
                                <img  src={this.state.maximizar_icon}/>
                        </Button>
                        : ""}

                    <Button onClick={()=>this.fecharModal()} variant="light" className="espaco_r_t_b">
                        <img src={require('./img/close_thick2.png')} />
                    </Button>
                    
                </div>                   
            </div>
        );
    }
}