import React from 'react';
import DataGrid, { Column, RowDragging, Scrolling, Lookup, Editing } from 'devextreme-react/data-grid';
import './estilo.css';


// const initialState = {
//   data: [],
//   changes: [],
//   editRowKey: null,
//   isLoading: false
// };


class GridVeiculoNotificacao extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startEditAction: 'click',
      editOnKeyPress: true,
      AddNotificacaoVeiModal: props.AddNotificacaoVeiModal,
      // editRowKey: null
    };

    this.priorities = [{
      id: 1, text: 'Low'
    }, {
      id: 2, text: 'Normal'
    }, {
      id: 3, text: 'High'
    }, {
      id: 4, text: 'Urgent'
    }];
    

    console.log("this.props.status");
    console.log(this.props.status);
    this.filterExpr = ['status', '=', this.props.status];
    // this.filterExpr = ['veiculo_id', '=', 70];

    this.onAdd = this.onAdd.bind(this);
    this.onEditingStart = this.onEditingStart.bind(this);

    this.dataSource = {
      store: this.props.tasksStore,
      reshapeOnPush: true
    };
  }
  //---------------------------------
  onAdd(e) {
    var key = e.itemData.veiculo_id;

    for (var i = 0; i < this.props.tasksStore.length; i++) {                        
      if(this.props.tasksStore[i].veiculo_id === key)        
      this.props.tasksStore[i].status = 2;    
      this.props.tasksStore[i].editado = true; 
    }       

    this.props.AddNotificacaoVeiModal.setState({grid_list: this.props.tasksStore, loading:true});
    this.props.AddNotificacaoVeiModal.setState({loading:false});    
  }
    //------------------------
  async componentDidMount() {
    this.setState({AddNotificacaoVeiModal: this.props.AddNotificacaoVeiModal});
  }
  
//-------------------------
onEditingStart(e) {
  if(this){
    // this.props.AddNotificacaoVeiModal.state.MapaKseg.callToast("onEditingStart");
    var grid_list = this.props.AddNotificacaoVeiModal.state.grid_list;
    for (var i = 0; i < grid_list.length; i++) {                        
      if(grid_list[i].veiculo_id === e.key.veiculo_id)        
        grid_list[i].editado = true; 
    }       

    this.props.AddNotificacaoVeiModal.setState({grid_list: grid_list});
  }
}
//----------------------------
  render() {
    return (
      <DataGrid 
        dataSource={this.dataSource}
        height={200}
        showBorders={true}
        filterValue={this.filterExpr}
        // onInitNewRow={this.setHireDate}
        onEditingStart={this.onEditingStart}
      >
         <RowDragging
          data={this.props.status}
          group="tasksGroup"
          onAdd={this.onAdd}
        />
      
        <Scrolling mode="virtual" />

        <Editing
          // mode="batch"
          mode="cell"
          onEditRowKeyChange={this.onEditRowKeyChange}
          allowUpdating={true}

          
        />

        <Column
          dataField="veiculo_id"
          dataType="number"
          visible={false}
          allowEditing={false}
        />

        <Column
          dataField="placa"
          dataType="string"
          allowEditing={false}
        />

        <Column
          dataField="entrada"
          dataType="boolean"

        />

        <Column
          caption="Saída"
          dataField="saida"
          dataType="boolean"    
        />

        <Column
          dataField="status"
          dataType="number"
          visible={false}
        />

      </DataGrid>
    );
  }
}

export default GridVeiculoNotificacao;
