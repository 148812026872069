import React, {Component} from 'react';
import {Modal, Spinner, Button, Form, Row,Col, InputGroup, Image, Navbar, Container} from 'react-bootstrap';
import { Switch } from 'devextreme-react/switch';
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import api from '../api';
import PostEntrega from '../services/post_entrega';
import FilterSelect from '../filterSelect'


export class ConcluirColetaEntregaModal extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            loading:true,
            minimizar:false,
            changed:false,
            GeralListModal:null,

            pagamento: {id: 0, descricao:""},
            data_previsao_coleta: null,
            valor: null,
            disable_pagamento:false,
            disable_valor:false,

            objeto: {id:0, cliente:"", local:"", rota:"", tipo_pagamento:"", valor:0, previsao:"", pago:"",usuario_id:0, canhoto: false, canhoto64: ""},

            salvando:false,
            height_modal:520,
            editados:[],
            loading_usuarios:true,   
            usuarios_list:[],          
            titulo:'',
        };
        this.myInputRef = React.createRef();

    }

    //-----------------------------------------------------------------------
    async componentDidMount() {
        try{            
            this.setState({GridColetaEntrega: this.props.objeto, titulo: this.props.titulo});
            this.setState({minDateValue:  new Date((new Date()).getTime() - 1000 * 60 * 24 * 3)});

            this.getUsuarios();

            let editados = {
                usuario:false
                , pago:true}; 

            this.setState({
                objeto: this.props.obj_selected,
                editados: editados        
                // , height_grid: this.state.height_modal-470
            },
            () => {         
                this.setState({loading:false});
            }
            )   
 
        
        }catch (response){
            this.props.MapaKseg.toastError("");
            this.fecharModal();}
         
    }; 
    //----------------------------------------------------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closeConcluirColetaEntregaModal();  
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeConcluirColetaEntregaModal();
        this.state.GridColetaEntrega.visibleFilterrow();        
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarConcluirColetaEntregaModal();
    };       
    //---------------------------------------------
    validar = () => {              
        let valido=true;

        if(this.state.titulo === "Concluir Entrega") 
            if(this.state.objeto.canhoto !== "Sim" || this.state.objeto.canhoto64 === ""){
                valido=false;            
                this.props.MapaKseg.toastError("Insira a Foto do Comprovante!");
            }

        if(this.state.objeto.usuario_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Usuário!");
        }
        

        if(valido){
            this.setState({salvando:true},
                () => { this.post(); }
                ) 
        }
    }; 
    //--------------------------------------
    concluido=()=> {
        this.setState({salvando:false});
        this.state.GridColetaEntrega.reloadGrid(null);
        this.fecharModal();
    }
    //---------------------------------------------------------------
    setColeta = (coleta) => {
        this.setState({coleta: coleta},
            () => { this.setState({loading: false}); }
            ) 
    }
    //---------------------------------------------
    setPago= (e) => {
        let objeto = this.state.objeto;
        objeto.pago = e.value;

        let editados = this.state.editados;
        editados.pago = true;    

        this.setState({objeto: objeto, editados: editados, changed:true});
    }
    //-----------------------------------------------------------------------------------------
    setUsuario = (id) =>{
        let objeto = this.state.objeto;
        objeto.usuario_id = id;

        let editados = this.state.editados;
        editados.usuario = true;  

        this.setState({objeto: objeto, editados: editados, changed: true});                                
    };
    //------------------------------------------------------------------
    getUsuarios = async () => {
        try {
            let body = new FormData()
            body.append('pessoa_id', this.props.MapaKseg.state.pessoa_id)
            body.append('token', localStorage.getItem('token'))  

            var url = "/relatorio_api/getUsuarios";

            const response = await api.post(url, body);
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);    
                let usuarios_list = []; 

                for (var i = 0; i < resposta.length; i++) {             
                  usuarios_list.push({ value: resposta[i].usuario_id, label: resposta[i].usuario });
                    this.setState({usuarios_list: usuarios_list});
                }                                        
            }

        } finally { this.setState({loading_usuarios: false}); }
    }; 
    //------------------------------------------------
    post = async () => {
        try {        
            let editados = this.state.editados;
            let objeto = this.state.objeto;

            let response = null;
            let responseJson = null;
            let formData =null;    

            if(editados.usuario && editados.pago){
                formData = new FormData()      

                if(editados.usuario) formData.append('usuario_id', objeto.usuario_id);
                if(editados.pago) formData.append('pago', objeto.pago);
                
                if(this.state.titulo === "Concluir Coleta")
                    formData.append('coleta_id', objeto.id);
                else if(this.state.titulo === "Concluir Entrega"){
                    formData.append('entrega_id', objeto.id);
                    formData.append('canhoto_base64', objeto.canhoto64);
                }
                 
                formData.append('usuario_cadastro_id', this.props.MapaKseg.state.usuario_id);
                formData.append('pessoa_id', this.props.MapaKseg.state.pessoa_id);
                formData.append('token', localStorage.getItem('token'));

                // response = await api.post('/teste_api/postColeta', formData);

                if(this.state.titulo === "Concluir Coleta")
                    response = await api.post('/coletaweb_api/postColetaFechar', formData);
                else if(this.state.titulo === "Concluir Entrega") 
                    response = await api.post('/coletaweb_api/postEntregaFechar', formData);    


                if (response.data !== null  &&  response.status !== 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json);        
                }
            }

    
        } finally {      
            this.concluido();
            }
    };
    //---------------------------------------------------------------
    showComprovante= (e) => {   
        let objeto = {entrega_id: this.state.objeto.id, canhoto: this.state.objeto.canhoto, canhoto64: this.state.objeto.canhoto64 };
        // let objeto = this.state.objeto;
        this.props.Modals.showComprovanteModal(this, "Comprovante", objeto, "inserir");             
    };     
    //------------------------------------------------------------------------------------------------------
    render(){
        return(
            <div className="drag1_1">
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            showCloseButton={false}
                            duration={600}
                            width={500}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.state.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.loading ?
                                        <Spinner className="centro3" animation="border" role="status"/>
                                    :
                                        <div style={{height: this.state.height_modal-100, overflow:'hidden'}}>
                                            <CustomScroll  scrollWidth="15px" scrollBarRadius="0">
                                                <Form >  
                                                    {/* <Form.Group as={Row} className="mb-1" controlId="formTexto" className="background_texto">
                                                        <Col sm={12}>                                                            
                                                            {this.state.titulo === "Concluir Coleta"?  "Para Concluir a Coleta informe o Usuário (Motorista) que fez a Coleta!"
                                                                : "Para Concluir a Entrega informe o Usuário (Motorista) que fez a Entrega!" }
                                                        </Col>                             
                                                    </Form.Group>  */}

                                                    <Navbar className="mb-3" variant="light">
                                                        <Container>
                                                            <Navbar.Brand href="#"></Navbar.Brand>
                                                        </Container>
                                                    </Navbar>

                                                    <Form.Group as={Row} className="mb-1" controlId="formClienteColeta">
                                                        <Form.Label column sm={1}>
                                                            <b>Cliente</b>
                                                        </Form.Label>
                                                        <Col sm={10}>
                                                            <Form.Label column >
                                                                 {this.state.objeto.cliente} 
                                                            </Form.Label>
                                                        </Col>
                                                    
                                                    </Form.Group> 

                                                    <Form.Group as={Row} className="mb-1" controlId="formLocalColeta">
                                                        <Form.Label column sm={1}>
                                                            <b>Local</b> 
                                                        </Form.Label> 
                                                        <Col sm={10}>
                                                            <Form.Label column >
                                                                 {this.state.objeto.local}
                                                            </Form.Label> 
                                                        </Col>                                                       
                                                    </Form.Group>       

                                                    <Form.Group as={Row} className="mb-1" controlId="formPrevisaoColeta">
                                                        <Form.Label column sm={2}>
                                                            <b>Previsão</b>
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <Form.Label column >
                                                                 {this.state.objeto.previsao}
                                                            </Form.Label> 
                                                        </Col>                                                        
                                                    </Form.Group>                                                                                   

                                                    <Form.Group as={Row} className="mb-1" controlId="formPagamento">
                                                        <Form.Label column sm={2}>
                                                            <b>Pagamento</b>
                                                        </Form.Label>
                                                        <Col sm={6}>
                                                            <Form.Label column >
                                                                 {this.state.objeto.tipo_pagamento}
                                                            </Form.Label> 
                                                        </Col>                                                          
                                                    </Form.Group>                                                                 

                                                    <Form.Group as={Row} className="mb-1" controlId="formValor">
                                                        <Form.Label column sm={2}>
                                                            <b>Valor_R$</b>
                                                        </Form.Label>
                                                        <Col sm={6}>
                                                            <Form.Label column >
                                                                 {this.state.objeto.valor}
                                                            </Form.Label> 
                                                        </Col>                                                         
                                                    </Form.Group> 

                                                    <Form.Group as={Row} className="mb-1" controlId="formValor">
                                                        <Form.Label column sm={2}>
                                                            <b>Pago</b>
                                                        </Form.Label>
                                                        <Col sm={9}>
                                                            <Switch className="padding_top" defaultValue={this.state.objeto.pago} value={this.state.objeto.pago}  switchedOnText={"Sim"} switchedOffText={"Não"} onValueChanged={this.setPago}/>                                                             
                                                        </Col>                                                         
                                                    </Form.Group> 

                                                    <Form.Group as={Row} className="mb-1" controlId="formUsuario">
                                                        <Form.Label column sm={2}>
                                                            <b>Usuário</b>
                                                        </Form.Label>
                                                        <Col sm={6}>                                                         
                                                            {this.state.loading_usuarios ? <Spinner size="sm" animation="border" /> 
                                                                : <FilterSelect list={this.state.usuarios_list} tipo={5} RelColetaEntregaModal={this} placeholder={"Usuário"} menuPlacement={"top"} /> }                                               
                                                        </Col>
                                                    </Form.Group>  

                                                    {this.state.titulo === "Concluir Entrega"?  
                                                        <Form.Group as={Row} className="mb-1" controlId="formComprovante">
                                                            <Form.Label column sm={3}>
                                                                <b>Comprovante</b>                                                            
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                            <Row className="mb-3">
                                                                <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
                                                                        <Button id="formComprovanteBt" onClick={this.showComprovante} variant="outline-secondary" >
                                                                            <Image  src={require('../img/file.svg')} width='20' style={{ tintColor: '#272727'}}/>
                                                                        </Button> 
                                                                        {this.state.objeto.canhoto ?
                                                                            <Image src={require('../img/checkbold24_green.png')} width='20' /> 
                                                                        :""}
                                                                </Form.Group>
                                                                    
                                                                                                                                                                                                                                                                                   
                                                                                                                   
                                                                </Row>
                                                            </Col>                                                            
                                                        </Form.Group>
                                                                : "" }
                                                                                                                                                                                 
                                                </Form>
                                            </CustomScroll>


                                            <Modal.Footer >                  
                                                <div className="align_bottom_right_top">                                                                
                                                    <Button disabled={!this.state.changed} onClick={this.validar}>
                                                        {this.state.salvando
                                                            ? <Spinner as="span" animation="grow" role="status" aria-hidden="true"/>                                                                
                                                        :
                                                        <div>
                                                            <img src={require('../img/checkbold24.png')}/> 
                                                            {this.state.titulo === "Concluir Coleta"?  " Coletado"
                                                                : " Entregue" }
                                                        </div>
                                                        }                                                                
                                                    </Button>
                                                </div> 
                                            </Modal.Footer>

                                        </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}