import React, {Component} from 'react';
import './estilo.css';
import api from './api';
import { ToastStore} from 'react-toasts';
// import {GetTemperaturasVei} from './GetTemperaturasVei';

import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  Export,
  Legend,
  Margin,
  Title,
  Subtitle,
  Grid,
  ConstantLine,
  Label,
  ValueAxis,
  Tooltip
} from 'devextreme-react/chart';
// import service from './data.js';
// import ReactCountdownClock from 'react-countdown-clock';
// const temperaturaDataList2 = [{
//             horario: '13:40',
//             sensor1: 59.8,
//             sensor2: 937.6,
//             sensor3: 582    
//         }, {
//             horario: '13:50',
//             sensor1: 74.2,
//             sensor2: 308.6,
//             sensor3: 35.1
//         }];
const temperaturaSourcesList = [
            { value: 'sensor1', name: 'Sensor 1' },
            { value: 'sensor2', name: 'Sensor 2' },
            { value: 'sensor3', name: 'Sensor 3' },
        ];
// const types = ['line', 'stackedline', 'fullstackedline'];

var moment = require('moment');

export class LineChartTemp extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true, 
            showSheet:true,
            tempGridModalShow:false,
            type: 'line',
            maxLine: 0,
            minLine: 0,
            clockVisibility:"not-visible",
            list: props.list,
            temperatura: {},

            temperaturaData: {},
            temperaturaDataList: props.temperaturaList,
        };
        this.handleChange = this.handleChange.bind(this);
      }
      //--------------------
  dateDiff = (posicaoAnterior, posicao) => {

    // var today = new Date();
    // var Christmas = new Date("2020-07-03");
    // var diffMs = (Christmas - today); // milliseconds between now & Christmas
    // var diffDays = Math.floor(diffMs / 86400000); // days
    // var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    // var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    // alert(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes until Christmas 2009 =)");
    var inserir = true;
    var ano_anterior = posicaoAnterior.data_receb.substring(21,25);
    var mes_anterior = posicaoAnterior.data_receb.substring(18,20);
    var dia_anterior = posicaoAnterior.data_receb.substring(15,17);
    var hora_anterior = posicaoAnterior.data_receb.substring(4,12);
    var a = moment(ano_anterior+"-"+mes_anterior+"-"+dia_anterior+"T"+hora_anterior+"-03:00");  //2020-03-17T17:39:31-03:00

//   sex 21:47:10   03-07-2020  

    var ano = posicao.data_receb.substring(21,25);
    var mes = posicao.data_receb.substring(18,20);
    var dia = posicao.data_receb.substring(15,17);
    var hora = posicao.data_receb.substring(4,12);
    var b = moment(ano+"-"+mes+"-"+dia+"T"+hora+"-03:00");  //2020-03-17T17:39:31-03:00

    const diff = b.diff(a);
    const diffDuration = moment.duration(diff);
    const diff_minutes = parseInt(diffDuration.asMinutes())

    // // alert(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes");
    // alert(diff_minutes + " minutes");


    if(diff_minutes>2)
        inserir = true;
    else inserir = false;

    console.log("****  "+hora_anterior+"  "+posicaoAnterior.data_receb+"  -   "+posicao.data_receb+"   "+String(diff_minutes) + " minutes  "+String(inserir))

    return inserir;
  }
  //--------------------
    customizeTooltip(arg) {
        return { text: '' };
    }
    handleChange(e) {
        this.setState({ type: e.value });
    }
    //-------------------------
    onComplete = () => {
        this.setState({clockVisibility:"visible"});
    };
    //------------------------------------
    toastStore2 = () => {
        // ToastStore.success(this.props.veiculo_id);
    };
    //--------------------------------
    async componentDidMount() {
    };
    //-----------------
    formatarSensores=(temperatura)=>{
        let sensores = "";
        // sensores="111";
        // ToastStore.success(" "+resposta.placa);

        if(!temperatura.faixa_fracionada){
            if(temperatura.faixa_temperatura_id===1){  // 1-Nenhuma Faixa
                sensores="111";
            }else{
                if((temperatura.sensor1 < temperatura.faixa_min) || (temperatura.sensor1 > temperatura.faixa_max)){
                    sensores = '0';
                }else sensores ='1';

                if((temperatura.sensor2 < temperatura.faixa_min) || (temperatura.sensor2 > temperatura.faixa_max)){
                    sensores = sensores+'0';
                }else sensores = sensores+'1';

                if((temperatura.sensor3 < temperatura.faixa_min) || (temperatura.sensor3 > temperatura.faixa_max)){
                    sensores= sensores+'0';
                }else sensores = sensores+'1';
            }
        }else{

            if(temperatura.faixa1_id===1){  // 1-Nenhuma Faixa
                sensores="1";
            }else{
                if((temperatura.sensor1 < temperatura.faixa1_min) || (temperatura.sensor1 > temperatura.faixa1_max)){
                    sensores = '0';
                }else sensores ='1';

            }

            if(temperatura.faixa2_id===1){  // 1-Nenhuma Faixa
                sensores = sensores+'1';
            }else{
                if((temperatura.sensor2 < temperatura.faixa2_min) || (temperatura.sensor2 > temperatura.faixa2_max)){
                    sensores = sensores+'0';
                }else sensores = sensores+'1';
            }

            if(temperatura.faixa3_id===1){  // 1-Nenhuma Faixa
                sensores = sensores+'1';
            }else{
                if((temperatura.sensor3 < temperatura.faixa3_min) || (temperatura.sensor3 > temperatura.faixa3_max)){
                    sensores= sensores+'0';
                }else sensores = sensores+'1';
            }

            
        }

        return sensores;

    }    
    //--------------------------------
    renderChart = () => {
        // ToastStore.success("temperaturaDataList: "+ String(this.state.temperaturaDataList.length));
        let len = this.state.temperaturaDataList.length;

        if(len > 0){
            // ToastStore.success("temperaturaDataList: "+ String(this.state.temperaturaDataList.length));
            if(this.props.renderTemperatura != null){
            
                this.props.renderTemperatura.state.sensorr1 = this.state.temperaturaDataList[len-1].sensor1;
                this.props.renderTemperatura.state.sensorr2 = this.state.temperaturaDataList[len-1].sensor2;
                this.props.renderTemperatura.state.sensorr3 = this.state.temperaturaDataList[len-1].sensor3;
                this.props.renderTemperatura.state.sensores = this.formatarSensores(this.state.list[len-1]);
            }

            return(
                <div >
                    <Chart  className="margin_cantos5"
                        palette={'Violet'}
                        // dataSource={this.state.temperaturaDataList}
                        dataSource={this.props.temperaturaList}
                    >

                        <ValueAxis maxValueMargin={0.01}>

                            <ConstantLine
                                width={2}
                                value={this.state.minLine}
                                color={'#f3304a'}
                                dashStyle={'dash'}
                            >
                                <Label text={'min'} />
                                </ConstantLine>
                                <ConstantLine
                                    width={2}
                                    value={this.state.maxLine}
                                    color={'#f3304a'}
                                    dashStyle={'dash'}
                                >
                                    <Label text={'max'} />
                                </ConstantLine>
                        </ValueAxis>

                        <CommonSeriesSettings
                            argumentField={'horario'}
                            type={this.state.type}
                        />
                        {
                            temperaturaSourcesList.map(function(item) {
                                return <Series valueField={'Temperature'} key={item.value} valueField={item.value}   />;
                            })
                        }
                        <Margin bottom={20} />
                        <ArgumentAxis
                            valueMarginsEnabled={true}
                            discreteAxisDivisionMode={'crossLabels'}
                        >
                            <Grid visible={true} />
                        </ArgumentAxis>
                        <Legend
                            verticalAlignment={'bottom'}
                            horizontalAlignment={'center'}
                            itemTextPosition={'bottom'}
                        />
                        <Export enabled={true}  />

                        <Title text={this.props.nomeFaixa}>
                            <Subtitle text={ this.props.minLine===null ?
                                                ""
                                                :String(this.props.minLine)+" a "+String(this.props.maxLine)} />
                        </Title>

                        <Tooltip
                            enabled={true}
                            customizeTooltip={this.customizeTooltip}
                        />
                    </Chart>

                    
                </div>        
            );
        }else{
            // this.getTemperaturaList();
            // var instance = new GetTemperaturasVei(this.props, this); 
            // instance.getTemperaturas();
        }

    };
  //-------------------------
    render(){
        return(
            <div >
                {this.renderChart()}
                {/* {this.props.renderTemperatura.state.sensores !== null  ?
                    this.getTemperaturaList(): ""} */}
                {/* condição ? true : false. */}

                {/* <Button icon={'img/phone.png'}
                    text={'Call'}
                    onClick={this.toastStore2} /> */}
            </div>
        );
    }
}