import React, {Component} from 'react';
import {Spinner, Button, OverlayTrigger, Tooltip, Form, Row, Col, InputGroup, Image} from 'react-bootstrap'; 
import DataGrid, {  Scrolling, Selection, Column, FilterRow, ColumnChooser, Summary, TotalItem } from 'devextreme-react/data-grid';
import ContextMenu from 'devextreme-react/context-menu';
import '../estilo.css';
import { ToastStore} from 'react-toasts';
import api from '../api';
import FilterSelect from '../filterSelect'
import { DateBox } from 'devextreme-react';
import {CabecalhoModal} from '../CabecalhoModal';
import GetClientePessoa from '../services/get_cliente_pessoa';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';

import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';

// ---------Export detail -----------
import FileSaver from "file-saver";
// import service from "./data.js";

const CLIENTES=1;


const getEmployeeCaption = ({ cliente, municipio }) =>
  'Entregas:';


var moment = require('moment');
require('moment-precise-range-plugin');


export class RelCaixaModal extends Component{
    constructor(props) {
        super(props);

        this.masterRows = [];

        
        this.state = {
            bottomSheet:true,
            list:[],
            loading:true,
            filter_list: [],
            data_inicial: null,
            data_final: null,
            value_data_inicial: null,
            value_data_final: null,
            btnPesquisar:false,
            placa:null,
            MapaKseg: props.MapaKseg,
            animation: "zoom",
            minimizar:false,
            drag:"drag3",
            width:1100,
            height:600,
            grid_height:'450px',
            coleta:{},
            filial_id:0,
            rota_id:0,
            cliente:"",
            usuario_id:0,
            clientes_list:[],
            loading_clientes:true,
            filiais_list:[],
            rotas_list:[],
            loading_filiais:true,
            loading_rotas:true,
            items_coleta:[],
            usuarios_list:[],
            loading_usuarios:true,          
            visible_datetime_text:false,
            GeralListModal:null,
            observacao_visible:true,
            cte_visible: true,
            soma:0,
            soma_total_frete:0,
            objeto_selecionado: null,
            visibleFilterRow:true,

            // tipo_pagamentos: [{tipos_pagamento_str: '1,2,5,6', descricao:"CIF, PIX CIF, FOB, PIX FOB"}, 
                              // {tipos_pagamento_str: '3,4', descricao:"AR CIF, AR FOB"}],
            tipo_pagamentos: [{id: 1, descricao:"CIF, PIX CIF, FOB, PIX FOB"}, 
                              {id: 2, descricao:"AR CIF, AR FOB"}],                              

            tipos_pagamento: 1
        };
        this.now = new Date();
        this.dataGridRefColeta = React.createRef();
      }    
    //------------------------------------------------------------------
    async componentDidMount() {
        var data_hoje= new Date();
        var data_ontem = new Date();
        data_ontem.setDate(data_ontem.getDate() - 1);
        // this.props.MapaKseg.callToast("data_ontem: "+String(data_ontem));

        // this.getClientes();
        this.getUsuarios();
        this.getFiliais();        
        this.getRotas();

        this.setState({
            data_inicial: this.formatarDateTime(String(data_ontem))
            ,value_data_inicial: String(data_ontem)
            ,data_final: this.formatarDateTime(String(data_hoje))
            ,value_data_final: String(data_hoje)
        });


        
        this.setState({drag: "drag3",width: 1100 });
        // this.setState({drag: "drag5",width: 1199 });

        this.carregarItemsColetaContextMenu();
    };      
    //------------------------------------------
    carregarItemsColetaContextMenu() {
        var items_coleta = this.state.items_coleta;
        // items_coleta.push({ text: ' Nova Coleta', icon: "dx-icon-add"  });
        // items_coleta.push({ text: ' Abrir Coleta', icon: "dx-icon-folder"  });
        // items_coleta.push({ text: ' Cancelar Coleta', icon: "dx-icon-close"  });
        // items_coleta.push([{ text: ' Excel Exportar', icon: "dx-icon-exportxlsx"}
                            // ,{ text: ' PDF Exportar', icon: "dx-icon-exportpdf" }]);

        items_coleta.push({ text: ' Excel Exportar', icon: "dx-icon-exportxlsx"  });
        items_coleta.push({ text: ' PDF Exportar', icon: "dx-icon-exportpdf"  });
        items_coleta.push({ text: ' Abrir Comprovante', icon: "dx-icon-file"  });
        items_coleta.push({ text: ' Pago Atualizar', icon: "dx-icon-check"  });

        this.setState({items_coleta: items_coleta}); 
    }
    //-------------------------------------------------------------------
    ItemTemplate (e) {
        return (
        <React.Fragment>
            <span className={ e.icon } />
            { e.items_coleta ? <span className="dx-icon-spinright" /> : null }
            { e.text }
        </React.Fragment>
        );
    }
    //---------------------------------------------------------------
    itemClick = (e) => {    
        if (!e.itemData.items_coleta) {      
            // if(e.itemData.text === this.state.items_coleta[0].text){        
            //     this.setState({visible_filterrow:false});
            //     this.props.MapaKseg.state.Modals.showCadColeta(this, null);
            // }else 
            if(e.itemData.text === ' Abrir Coleta'){
              if(this.state.list.length > 0){
                // try{
                  var selectedRowData = this.dataGrid.getSelectedRowsData();                   
                  // console.log("  selectedRowData[0] coleta: "+String(this.state.objeto_selecionado));
                  // console.log("  selectedRowData[0] coleta.coleta_id: "+String(this.state.objeto_selecionado.coleta_id));
                    // this.setState({visible_filterrow:false});

                  // this.props.Modals.showCadColeta(this, this.state.objeto_selecionado);      
                  this.props.MapaKseg.toast(String(selectedRowData.length));
                  this.props.Modals.showColetaEntregaModal(this, selectedRowData[0]);

                // }catch (response){this.props.MapaKseg.toastError("");}
              }
            }else 
            if(e.itemData.text === ' Excel Exportar'){  
                this.exportDataGridToxlsx(" Excel");     

            }else if(e.itemData.text === ' PDF Exportar'){  
                this.exportGridPDF(" PDF");        
            }else if(e.itemData.text === ' Abrir Comprovante'){  
              var selectedRowData = this.dataGrid.getSelectedRowsData(); 
              var objeto = {entrega_id:0};
              if(selectedRowData[0].is_coleta === false){
                 objeto.entrega_id = selectedRowData[0].id;
                 objeto.canhoto = selectedRowData[0].canhoto;
                 objeto.canhoto64 = selectedRowData[0].canhoto64;
              }

              this.props.Modals.showComprovanteModal(this, "Comprovante", objeto, "visualizar");  
          }else if(e.itemData.text === ' Pago Atualizar'){    
            let selectedRowData = this.dataGrid.getSelectedRowsData();               
            this.props.Modals.showPagoAtualizarModal(this, "Pago Atualizar", selectedRowData[0], "visualizar");       
        }
        }
    }
    //------------------------------------------
      get dataGrid() {
        return this.dataGridRefColeta.current.instance;
      }
    //-------------------------------------------------------------------------------
    exportGridPDF=(e)=> {
        this.setState({icone_visible: !this.state.icone_visible
                      ,observacao_visible: false
                      ,cte_visible:false}); 
        const doc = new jsPDF();

        let today = new Date();
        let time = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
        let date = today.getDate() + "-" + (today.getMonth() + 1) + '-' + today.getFullYear() + '  '+time;

        let dataGrid = this.dataGridRefColeta.current.instance;
        let nomeArquivo = "CaixaColetasEntregas "+date+".pdf";
    
        exportDataGridToPdf({
        jsPDFDocument: doc,
        component: dataGrid
        }).then(() => {
        doc.save(nomeArquivo);
        this.setState({icone_visible: !this.state.icone_visible
                      ,observacao_visible: true
                      ,cte_visible:true});
        });    
    }
    //------------------------------------------------------------------
    exportDataGridToxlsx = (e) =>  {
      this.setState({
        visible_datetime: false,
        visible_datetime_text: true,
        icone_visible: false}); 
  
      let today = new Date();
      let time = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
      let date = today.getDate() + "-" + (today.getMonth() + 1) + '-' + today.getFullYear() + '  '+time;
  
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
  
      let compo = this.dataGridRefColeta.current.instance;
      let nomeArquivo = "CaixaColetasEntregas "+date+".xlsx";
  
      exportDataGrid({
          // component: e.component,
          component: compo,
          worksheet: worksheet
      }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), nomeArquivo);
          this.setState({
            visible_datetime: true,
            visible_datetime_text: false,
            icone_visible: true});
          });
      });
  
    }
    //---------------------------------------------------------------------------------------
    onExportingToxlsx = (e) => {
        this.setState({
          visible_datetime: false,
          visible_datetime_text: true,
          icone_visible: false});
  
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");
        
        let list = this.state.list;
  
        let today = new Date();
        let time = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
        let date = today.getDate() + "-" + (today.getMonth() + 1) + '-' + today.getFullYear() + '  '+time;
  
  
        let compo = this.dataGridRefColeta.current.instance;
        let nomeArquivo = "CaixaColetasEntregas "+date+".xlsx";
    
        exportDataGrid({
          component: compo,
          worksheet: worksheet,
          autoFilterEnabled: true,
          topLeftCell: { row: 2, column: 2 },
          customizeCell: ({ gridCell, excelCell }) => {
            if (
              gridCell.column.dataField === "cliente" &&
              gridCell.rowType === "data"
            ) {
              this.masterRows.push({
                rowIndex: excelCell.fullAddress.row + 1,
                data: gridCell.data
              });
            }
          }
        })
          .then((cellRange) => {
            const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
            let offset = 0;
    
            const insertRow = (index, offset, outlineLevel) => {
              const currentIndex = index + offset;
              // debugger;
              let row = worksheet.insertRow(currentIndex, [], "n");
    
              for (var j = worksheet.rowCount + 1; j > currentIndex; j--) {
                worksheet.getRow(j).outlineLevel = worksheet.getRow(
                  j - 1
                ).outlineLevel;
              }
    
              row.outlineLevel = outlineLevel;
    
              return row;
            };
    
            console.log("this.masterRows: "+String(this.masterRows));
            console.log("this.masterRows[0].data: "+String(this.masterRows[0].data));
            // if(this.masterRows != null){
            //   console.log("Não é null!");
            
            for (var i = 0; i < this.masterRows.length; i++) {
              let row = insertRow(this.masterRows[i].rowIndex + i, offset++, 2);
              let columnIndex = cellRange.from.column + 1;
              row.height = 40;
    
              let employeeData = list.find(
                (item) => item.coleta_id === this.masterRows[i].data.coleta_id
              );
              console.log("employeeData: ");
              console.log(employeeData);
              Object.assign(row.getCell(columnIndex), {
                value: getEmployeeCaption(employeeData),
                fill: {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "BEDFE6" }
                }
              });
              worksheet.mergeCells(row.number, columnIndex, row.number, 7);
    
              const columns = [
                "cliente",
                "rota",
                "municipio",
                "tipo_pagamento",
                "valor",
                "data_previsao"
              ];
    
              row = insertRow(this.masterRows[i].rowIndex + i, offset++, 2);
              columns.forEach((columnName, currentColumnIndex) => {
                Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                  value: columnName,
                  fill: {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "BEDFE6" }
                  },
                  font: { bold: true },
                  border: {
                    bottom: borderStyle,
                    left: borderStyle,
                    right: borderStyle,
                    top: borderStyle
                  }
                });
              });
    
              employeeData.entregas.forEach((task, index) => {
                row = insertRow(this.masterRows[i].rowIndex + i, offset++, 2);
    
                columns.forEach((columnName, currentColumnIndex) => {
                  Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                    value: task[columnName],
                    fill: {
                      type: "pattern",
                      pattern: "solid",
                      fgColor: { argb: "BEDFE6" }
                    },
                    border: {
                      bottom: borderStyle,
                      left: borderStyle,
                      right: borderStyle,
                      top: borderStyle
                    }
                  });
                });
              });
              offset--;
            }
          
          })
          .then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
              FileSaver.saveAs(
                new Blob([buffer], { type: "application/octet-stream" }),
                nomeArquivo
              );
  
              this.setState({
                visible_datetime: true,
                visible_datetime_text: false,
                icone_visible: true});
  
            });
          });
      };
    //------------------------------------------------------------------
      onClickPesquisar = (e) => {
        const age=this.diffInDay;
        
        if(this.state.data_inicial===null || String(this.state.data_inicial).includes("undefined"))
            ToastStore.error("Data inicial está vazia!");
        else if(this.state.data_final===null || String(this.state.data_final).includes("undefined"))
            ToastStore.error("Data final está vazia!");
        // else if(age > 31)
        //     ToastStore.error("Período maior que 31 dias!");
        // else if(this.state.veiculo_id===null)
        // ToastStore.error("Selecine um veículo!");    
        else
            this.loadData();
      }
      //------------------------------------------------------------------
      loadData = (e) => {
        this.setState({list:[]});
        if(this.state.btnPesquisar===true){
            this.setState({btnPesquisar:false});
            this.setState({loading:true});
        }
        this.setState({btnPesquisar:true, list: []});
        this.get();
      }
      //------------------------------------------------------------------
      get diffInDay() {
        return `${Math.floor(Math.abs((this.state.value_data_final - this.state.value_data_inicial) / (24 * 60 * 60 * 1000))) } `;
      }
      //------------------------------------------------------------------
      onValueChangedDataInicial = (e) => {
        this.setState({
          data_inicial: this.formatarDateTime(e.value),
          value_data_inicial: e.value
        });
        // ToastStore.success( String(this.state.data_inicial));
      }
      //------------------------------------------------------------------
      onValueChangedDataFinal = (e) => {
        this.setState({
          data_final: this.formatarDateTime(e.value),
          value_data_final: e.value
        });
        // ToastStore.success( String(this.state.data_final));
      }
      //------------------------------------------------------------------
      formatarMes(mes){    
        if(mes==="Jan")
            return 1;
        else if (mes==="Feb")
            return 2;
        else if (mes==="Mar")
            return 3;     
        else if (mes==="Apr")
            return 4;     
        else if (mes==="May")
            return 5;     
        else if (mes==="Jun")
            return 6; 
        else if (mes==="Jul")
            return 7; 
        else if (mes==="Aug")
            return 8; 
        else if (mes==="Sep")
            return 9; 
        else if (mes==="Oct")
            return 10;    
        else if (mes==="Nov")
            return 11;
        else if (mes==="Dec")
            return 12;             
      }
      //------------------------------------------------------------------
      formatarDateTime(dtTime){    
        const y = String(dtTime).substring(11,15);
        const mm = this.formatarMes(String(dtTime).substring(4,7));
        const dd = String(dtTime).substring(8,10);
        const hm = String(dtTime).substring(16,21);

        // console.log("  *** dtTime: "+String(dtTime));
        // console.log("  *** y: "+y);
        // console.log("  *** mm: "+mm);
        // console.log("  *** mm: "+String(this.formatarMes(mm)));
        // console.log("  *** dd: "+dd);
        // console.log("  *** hm: "+hm);  
        const datetime = y+"-"+mm+"-"+dd+" "+hm;  // "Y-MM-DD H:M"
        return datetime;                  
      }
     //------------------------------------------------------------------
     formatarDateTimeGrid(dtTime){
         //  sex 21:47:10   03-07-2020  
         if(dtTime===null)
            return "";

        const diaSema = String(dtTime).substring(0,3);
        const y = String(dtTime).substring(23,25);
        const mm = String(dtTime).substring(18,20);
        const dd = String(dtTime).substring(12,17);
        const hm = String(dtTime).substring(4,12);    
        const datetime = diaSema+", "+dd+"/"+mm+"/"+y+"   "+hm;  // "Y-MM-DD H:M"
        return datetime;  
     }
    //--------------------------------------------------------------
    somarFreteTDA =(_frete, _tda) =>{
      _frete = parseFloat(_frete.replace(".", "").replace(",", "."));

      if (_tda !== "") _tda = parseFloat(_tda.replace(".", "").replace(",", "."));
      else _tda = 0;

      let total = _frete + _tda;
      total = total.toLocaleString('pt-br', {minimumFractionDigits: 2});
      return total;        
    }
    //------------------------------------------------------------------
    get = async () => {
        try {
            let body = new FormData()
            body.append('pessoa_id', this.props.MapaKseg.state.pessoa_id)
            body.append('data_inicial', this.state.data_inicial)
            body.append('data_final', this.state.data_final)
            body.append('token', localStorage.getItem('token'))
            body.append('tipos_pagamento', this.state.tipos_pagamento);

            var filiais_list = this.state.filiais_list;
            var filiais_id_str="";
            for (var i = 1; i < filiais_list.length; i++) {  
                if (i==1) filiais_id_str+=filiais_list[i].value;
                else filiais_id_str+=","+filiais_list[i].value;
            }
            
            if(filiais_id_str === "") filiais_id_str = "0"

            body.append('filiais_id_str', filiais_id_str);            

            if(this.state.filial_id !== 0)
                body.append('filial_id', this.state.filial_id);
            
            if(this.state.rota_id !== 0)
                body.append('rota_id', this.state.rota_id);    

            if(this.state.usuario_id !== 0)
              body.append('usuario_id', this.state.usuario_id);        

            var url = "/relatorio_api/getCaixaColetaEntrega";
            // var url = "/teste_api/getCaixaColetaEntrega";

            const response = await api.post(url, body);


            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
        
                let list = []; 
                let objeto ={};
                let soma=0;
                let soma_total_frete=0;

                // ToastStore.success("resposta.length: "+ String(resposta.length));
                  
                for (var i = 0; i < resposta.length; i++) {
                    objeto = {    
                        index: i+1,
                        is_coleta: resposta[i].is_coleta,
                        is_coleta_str: '',
                        id: resposta[i].id,
                        cliente_id: resposta[i].cliente_id,
                        cliente: resposta[i].cliente,
                        rota_id: resposta[i].rota_id,
                        rota: resposta[i].rota,
                        data_previsao: resposta[i].data_previsao,
                        data_previsao2: resposta[i].data_previsao2,
                        data_coleta_entrega: resposta[i].data_coleta_entrega,
                        tipo_pagamento_id: resposta[i].tipo_pagamento_id,
                        tipo_pagamento: resposta[i].tipo_pagamento,
                        valor: resposta[i].valor,
                        valor2: parseFloat(resposta[i].valor.replace(".", "").replace(",", ".")),
                        tda: resposta[i].tda,
                        nota_fiscal: resposta[i].nota_fiscal,
                        total_frete_tda: 0, 
                        total_frete_tda2: 0, 
                        local_id: resposta[i].local_id,
                        local: resposta[i].local,
                        usuario: resposta[i].usuario,
                        municipio: resposta[i].municipio,                        
                        observacao: resposta[i].observacao,
                        cte: resposta[i].cte,                  
                        filial: resposta[i].filial,

                        pago: resposta[i].pago,
                        pago_str: '',                    
                        canhoto: resposta[i].canhoto,
                        canhoto64: "",

                        status:2,
                        selecionada: false,
                        index_scroll: (i+2)*25,
                    };   
                    
                    if(objeto.pago === true)
                      objeto.pago_str = 'Foi Pago'
                    else if(objeto.pago === false)    
                      objeto.pago_str = 'Não Pago'
                    
                    // if(objeto.canhoto == true) objeto.canhoto = 'Sim';
                    // else if(objeto.canhoto == false) objeto.canhoto = '';

                    if(objeto.is_coleta == true) objeto.is_coleta_str = 'Coleta';
                    else if(objeto.is_coleta == false) objeto.is_coleta_str = 'Entrega';

                    // coleta.entregas_count_icon = this.setEntregasCount(coleta.entregas.length);

                    soma = soma + objeto.valor2;
                    
                    // if(i==0) soma = soma + coleta.valor2; 
                    // else if(coleta.coleta_id !== resposta[i-1].coleta_id) soma = soma + coleta.valor2;
                    // else if(coleta.coleta_id === resposta[i-1].coleta_id){
                    //   if(coleta.tipo_pagamento_id !== 1 && coleta.tipo_pagamento_id !== 3 && coleta.tipo_pagamento_id !== 5){  // CIF, AR CIF, PIX CIF
                    //     soma = soma + coleta.valor2;
                    //   }
                    // }

                    objeto.total_frete_tda = this.somarFreteTDA(objeto.valor, objeto.tda);
                    objeto.total_frete_tda2 = parseFloat(objeto.total_frete_tda.replace(".", "").replace(",", "."));
                    soma_total_frete = soma_total_frete + ( parseFloat(objeto.total_frete_tda.replace(".", "").replace(",", ".")))
                
                    list.push(objeto);                    
                }                        
                soma = soma.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'});
                soma_total_frete = soma_total_frete.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'});
                this.setState({list: list, soma: soma, soma_total_frete: soma_total_frete});                
            }

        } finally {
            this.setState({loading: false});
            }
    };
    //------------------------------------------------------------------
    getClientes = async () => {
        try {
            let body = new FormData()
            body.append('pessoa_id', this.props.MapaKseg.state.pessoa_id)
            body.append('token', localStorage.getItem('token'))  

            var url = "/relatorio_api/getClientes";

            const response = await api.post(url, body);
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);    
                let clientes_list = []; 
                  
                clientes_list.push({ value: 0, label: "Todos" });

                for (var i = 0; i < resposta.length; i++) {             
                    clientes_list.push({ value: resposta[i].cliente_id, label: resposta[i].cliente });
                    this.setState({clientes_list: clientes_list});
                }                                        
            }

        } finally { this.setState({loading_clientes: false}); }
    };
    //------------------------------------------------------------------
    getFiliais = async () => {
        try {
            let body = new FormData()
            body.append('pessoa_id', this.props.MapaKseg.state.pessoa_id);
            body.append('usuario_id', this.props.MapaKseg.state.usuario_id);
            body.append('token', localStorage.getItem('token'));

            var url = "/relatorio_api/getFiliais";
            // var url = "/teste_api/getFiliais";

            const response = await api.post(url, body);
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);    
                let filiais_list = []; 

                filiais_list.push({ value: 0, label: "Todos" });

                for (var i = 0; i < resposta.length; i++) {             
                    filiais_list.push({ value: resposta[i].filial_id, label: resposta[i].filial });
                    this.setState({filiais_list: filiais_list});
                }                                        
            }

        } finally { this.setState({loading_filiais: false}); }
    };
    //------------------------------------------------------------------
    getRotas = async () => {
      try {
          let body = new FormData()
          body.append('pessoa_id', this.props.MapaKseg.state.pessoa_id);
          body.append('usuario_id', this.props.MapaKseg.state.usuario_id);
          body.append('token', localStorage.getItem('token'));

          var url = "/relatorio_api/getRotas";
          // var url = "/teste_api/getRotas";

          const response = await api.post(url, body);
          var responseJson = null

          if (response.data !== null  &&  response.status !== 403){
              responseJson = JSON.stringify(response.data)
              var req = { json: responseJson };
              var resposta = JSON.parse(req.json);    
              let rotas_list = []; 

              rotas_list.push({ value: 0, label: "Todos" });

              for (var i = 0; i < resposta.length; i++) {             
                rotas_list.push({ value: resposta[i].rota_id, label: resposta[i].descricao });
                  this.setState({rotas_list: rotas_list});
              }                                        
          }

      } finally { this.setState({loading_rotas: false}); }
  };
      //------------------------------------------------------------------
      getUsuarios = async () => {
        try {
            let body = new FormData()
            body.append('pessoa_id', this.props.MapaKseg.state.pessoa_id)
            body.append('token', localStorage.getItem('token'))  

            var url = "/relatorio_api/getUsuarios";

            const response = await api.post(url, body);
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);    
                let usuarios_list = []; 

                usuarios_list.push({ value: 0, label: "Todos" });

                for (var i = 0; i < resposta.length; i++) {             
                  usuarios_list.push({ value: resposta[i].usuario_id, label: resposta[i].usuario });
                    this.setState({usuarios_list: usuarios_list});
                }                                        
            }

        } finally { this.setState({loading_usuarios: false}); }
    };
    //-----------------------------------------------------------------------------------------
    setCliente = (id) =>{
        this.setState({cliente_id: id});
    };
    //-----------------------------------------------------------------------------------------
    setFilial = (id) =>{
        this.setState({filial_id: id});                                           
    };
    //-----------------------------------------------------------------------------------------
    setUsuario = (id) =>{
      this.setState({usuario_id: id});                                           
    };
    //-----------------------------------------------------------------------------------------
    setRota = (id) =>{
      this.setState({rota_id: id});                                           
    };
    //-----------------------------------------------------------------------------------------
    setEntregasCount = (count) =>{
        if(count===1) return  require("../img/circle1.png");
        else if(count===2) return require("../img/circle2.png")
        else if(count===3) return require("../img/circle3.png")
        else if(count===4) return require("../img/circle4.png")
        else if(count===5) return require("../img/circle5.png")
        else if(count>5) return require("../img/circle5plus.png")
        else if(count>9) return require("../img/circle9plus.png")                                            
    };
    //------------------------------------------------------------------
    getDataInicial(){
        var data_ontem = new Date();
        data_ontem.setHours(data_ontem.getHours() - 1);
        return data_ontem;  
     }
     //------------------------------------------------------------------
     minimizarModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
      
        this.props.Modals.closeRelCaixaModal();
        this.props.MapaKseg.setState({sidenav_visible:true});

        if(this.props.Modals.state.GridColetaEntrega != null)
          this.props.Modals.state.GridColetaEntrega.visibleFilterrow();
    };
    //------------------------------------------------------------------
    maximizarModal = () =>{
        var grid_h = String(window.innerHeight - 150)+"px";
        this.setState({width: window.innerWidth
                        ,height: window.innerHeight
                        ,drag: "drag0"
                        ,grid_height: grid_h});
    };
    //------------------------------------------------------------------
    restoreModal = () =>{
        var grid_h = String(450)+"px";
        this.setState({width: 1100
                        ,height: 600
                        ,drag: "drag3"
                        ,grid_height: grid_h});                     
    };
    //------------------------------------------------------------------
    fecharModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "zoom"});
        this.setState({minimizar:false});

        this.props.Modals.closeRelCaixaModal();
        this.props.MapaKseg.setState({sidenav_visible:true});    

        if(this.props.Modals.state.GridColetaEntrega != null)
          this.props.Modals.state.GridColetaEntrega.visibleFilterrow();
    };
    //------------------------------------------------------------------
    desativarModal = () =>{    
        if(this.props.show === false){           
            if(this.state.minimizar)
                this.props.Modals.closeRelCaixaModal();
            else this.props.Modals.desativarRelCaixaModal();            
        }     
    };
    //----------------------------------------------------
    dateDiff=(data_inicio, data_final)=>{
        // data_inicio: sex 21:47:10   03-07-2020 
        if(String(data_inicio).length> 19){
            var ano_anterior = data_inicio.substring(21,25);
            var mes_anterior = data_inicio.substring(18,20);
            var dia_anterior = data_inicio.substring(15,17);
            var hora_anterior = data_inicio.substring(4,12);
            var a = moment(ano_anterior+"-"+mes_anterior+"-"+dia_anterior+"T"+hora_anterior+"-03:00");  //2020-03-17T17:39:31-03:00
    
            var ano = data_final.substring(21,25);
            var mes = data_final.substring(18,20);
            var dia = data_final.substring(15,17);
            var hora = data_final.substring(4,12);
            var b = moment(ano+"-"+mes+"-"+dia+"T"+hora+"-03:00");  //2020-03-17T17:39:31-03:00
            const precise_diff =  moment.preciseDiff(a, b, true); 
        
            const diff = b.diff(a);
            const diffDuration = moment.duration(diff);
            const diff_minutes = parseInt(diffDuration.asMinutes())
            // const diff_days = parseInt(diffDuration.asDays())
        
            // // alert(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes");
            // alert(diff_minutes + " minutes");
        
            // console.log("****  diff_minutes: "+String(diff_minutes));
        
            // return String(ano_anterior+"-"+mes_anterior+"-"+dia_anterior+"T"+hora_anterior+"-03:00");
            // return "("+String(diff_minutes)+"min)";
        
            if(precise_diff.minutes===0)
                if(precise_diff.seconds===0)
                    return "0";
                else return precise_diff.seconds+"seg";
            else if(precise_diff.hours === 0)
                return precise_diff.minutes+"min";
            else if(precise_diff.days === 0){
                if(precise_diff.hours===1)
                return String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
                else return String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
            }else if(precise_diff.months === 0){
                if(precise_diff.days===1)
                return String(precise_diff.days)+"dia "+String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
                else return String(precise_diff.days)+"dias "+String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
            }else if(precise_diff.years === 0){
                if(precise_diff.months===1)
                return String(precise_diff.months)+"mes "+String(precise_diff.hours)+"dia "+String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
                else return String(precise_diff.months)+"meses "+String(precise_diff.hours)+"dia "+String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
            }
        }
    }
  //---------------------------------------------------------------
  showGeralList= (e) => {   
    // this.props.MapaKseg.callToast(e.target.getAttribute('value') );
    // if(e.target.getAttribute('value') == CLIENTES)
        this.props.Modals.showGeralList(this,"Clientes", CLIENTES, true);
    // else if(e.target.getAttribute('value') == COLETA_CLIENTE_LOCAL_TIPO)
    //     this.props.Modals.showGeralList(this,"Local da Coleta", COLETA_CLIENTE_LOCAL_TIPO);    
    // else if(e.target.getAttribute('value') == COLETA_ROTA_TIPO)
    //     this.props.Modals.showGeralList(this,"Rotas Coleta", COLETA_ROTA_TIPO);      
  }; 
  //-----------------------------------------------------------------------------------------
  getList= (geralListModal, tipo) => { 
    this.setState({GeralListModal: geralListModal});  

    if(tipo == CLIENTES){             
        var instance = new GetClientePessoa(null, this, this.props.MapaKseg, CLIENTES);
        instance.get(); 
    }
    // else if(tipo == COLETA_CLIENTE_LOCAL_TIPO){ 
    //     var instance = new GetClientePessoa(null, this, this.props.MapaKseg, COLETA_CLIENTE_TIPO);
    //     instance.get(); 
    // }else if(tipo == COLETA_ROTA_TIPO){ 
    //     var instance = new GetRotaPessoa(null, this, this.props.MapaKseg, COLETA_ROTA_TIPO);
    //     instance.get();   
    // }
  }; 
  //--------------------------------------------------------------
  setListClientes= (_list, tipo) => {  
    let array =[];
    let descr = "";

    for (var i = 0; i < _list.length; i++) { 

        if(_list[i].cnpj_cpf == null) descr = _list[i].nome + " ("+_list[i].local+")"
        else descr = _list[i].nome +" ("+_list[i].cnpj_cpf +") ("+_list[i].local+")"

        array.push({
            index: _list[i].index
              ,id: _list[i].cliente_id
            , descricao: descr
            , descricao2: _list[i].nome
            , local_id: _list[i].local_id
            , local: _list[i].local
            , selecionada: _list[i].selecionada
            , index_scroll: _list[i].index_scroll});
    }
    this.state.GeralListModal.setList(array, tipo);
  }; 
  //-----------------------------------------------------------------------
  setObjeto= (objeto, tipo) => { 

    if(tipo == CLIENTES){
        let cliente_id = this.state.cliente_id;
        let cliente = this.state.cliente;

        cliente_id = objeto.id;
        cliente = objeto.descricao2;              

        this.setState({cliente_id: cliente_id, cliente: cliente});   
    }
    // else if(tipo == COLETA_CLIENTE_LOCAL_TIPO){
    //     let coleta = this.state.coleta;
    //     coleta.local_id = objeto.local_id;
    //     coleta.local = objeto.local;
    //     this.setState({coleta: coleta});

    // }
    // else if(tipo == COLETA_ROTA_TIPO){
    //     let coleta = this.state.coleta;
    //     coleta.rota_id = objeto.id;
    //     coleta.rota = objeto.descricao2;
    //     this.setState({coleta: coleta});
        
    // }
  }
   //------------------------------------------------------------------
   visibleFilterrow = ()=>{
    // this.setState({visible_filterrow:true});
  }
  //------------------------------------------------------------------
  refreshDataGrid = ()=>{
    this.dataGrid.refresh(); 
  }
  //---------------------------------------------------------------------------------------
  renderDetail= (props) => {
    return (
      <React.Fragment>
        <b>Entregas</b>

        <DataGrid
          dataSource={props.data.entregas}
          showBorders={true}
          columnAutoWidth={true}
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          // onExporting={this.onExportingToxlsx}
        >

          <Selection mode="single" />
          <Scrolling mode='virtual'></Scrolling>
          
          <Column dataField="index" caption= "" width={40}/>
          <Column dataField="coleta_id" caption="coleta_id" width={60}  />
          <Column dataField="entrega_id" caption="Id" width={60}  />
          <Column dataField="cliente" width={150} />
          <Column dataField="rota" width={100} />
          <Column dataField="municipio" caption= "Município" width={100} />
          <Column dataField="tipo_pagamento" caption= "pagamento" width={100} />
          <Column dataField="valor" width={70} />          
          {/* <Column dataField="data_previsao" caption={"Data Previsão"} format="EE  dd/MM/yyyy HH:mm" dataType="datetime" /> */}
          <Column dataField="data_previsao" caption={"Data Previsão"} dataType="text" />
          <Column dataField="data_entrega" caption={"Data Entrega"}  dataType="text" />
          <Column dataField="local" visible={false} width={250}/> 
        </DataGrid>
      </React.Fragment>
    );
  }
  //-------------------------------------------------
  customizeSum= () => {
    return this.state.soma + '';
  }
  //-------------------------------------------------
  customizeSomaTotalFrete= () => {
    return this.state.soma_total_frete + '';
  }
  //-------------------------------------------------
  customizeSomaTotalFrete2= (data) => {
    let soma_total_frete = data.value.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'});
    return `${soma_total_frete}`;
  }
  //--------------------------------------------------
  customizeCount(data) {
    return `${data.value}`;
  }
  //-----------------------------------------------
  setPagamentoFiltro(event) {
    this.setState({ tipos_pagamento :event.target.value});
   }
  //------------------------------------------------------------------
    render(){
        var data_hoje = new Date();
        var data_ontem = new Date();
        data_ontem.setDate(data_ontem.getDate() - 1);
        // data_ontem.setHours(data_ontem.getHours() - 1);

        const renderTooltipCliente = (props) => (
          <Tooltip id="button-tooltip" {...props}>Cliente</Tooltip>
        );

        const onContextMenuPreparing = (e) =>{
          try{
            e.component.selectRows(e.row.key, false)
            // var selectedRowData = this.dataGrid.getSelectedRowsData();
            // this.props.MapaKseg.ajustarZoom(selectedRowData[0]);
  
            // console.log(" e.row.key: ");
            // console.log(e.row.key);
            this.setState({objeto_selecionado: e.row.key});
            // alert(e.row.key);
          }catch (response){}
        }

        return(
            <div className={this.state.drag} >
                <Draggable   handle="strong"  >                
                    <div >                    
                        <Rodal 
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={this.state.width}
                            height={this.state.height}
                            onAnimationEnd={()=>this.desativarModal()}
                            >  
                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={true} maximizar={true} titulo={this.props.titulo} Modal={this}/></strong>

                                    <Form > 
                                      <div className="options">
                                        <Row>
                                            <Col xs={4} >
                                                <Form.Group as={Row} controlId="formMotorista">                                                                                                 
                                                  <Col sm={10}>
                                                    {this.state.loading_usuarios ? <Spinner size="sm" animation="border" /> 
                                                          : <FilterSelect list={this.state.usuarios_list} tipo={5} RelColetaEntregaModal={this} placeholder={"Usuário"} /> }
                                                  </Col>                                                  
                                              </Form.Group> 
                                            </Col>
                                            <Col>
                                                {this.state.loading_filiais ? <Spinner size="sm" animation="border" /> 
                                                : <FilterSelect list={this.state.filiais_list} tipo={4} RelColetaEntregaModal={this} placeholder={"Filial"} /> }
                                            </Col>      
                                            <Col>
                                                {this.state.loading_rotas ? <Spinner size="sm" animation="border" /> 
                                                : <FilterSelect list={this.state.rotas_list} tipo={6} RelColetaEntregaModal={this} placeholder={"Rota"} /> }
                                            </Col>                                          
                                            <Col>
                                                <Button variant="primary" onClick={()=>this.onClickPesquisar()}>
                                                    <img src={"img/lupa.png"} alt="" Width="20" />
                                                </Button>
                                            </Col>
                                        </Row>
                                       
                                        <Row>                 
                                          <div className="option">                                                                  
                                            <span>Previsão:</span>    
                                          </div>
                                          <DateBox displayFormat="dd/MM/yyyy HH:mm" placeholder="Dada inicial" 
                                              className="headerDateTime"  type="datetime" 
                                              onValueChanged={this.onValueChangedDataInicial}
                                              defaultValue={data_ontem} /> 

                                          <DateBox displayFormat="dd/MM/yyyy HH:mm" placeholder="Data final" 
                                              className="headerDateTime"  type="datetime" 
                                              onValueChanged={this.onValueChangedDataFinal}
                                              defaultValue={data_hoje}/>        

                                          <Col xs={3} >
                                            <Form.Control as="select" aria-describedby="basic-addon1" 
                                                custom onChange={this.setPagamentoFiltro.bind(this)} >
                                                  {this.state.tipo_pagamentos.map(item =>
                                                      <option value={item.id}>
                                                          {item.descricao}
                                                      </option>
                                                  )}
                                            </Form.Control>       
                                          </Col>


                                        </Row>                                                                          
                                      </div> 
                                    </Form>


                                    {this.state.btnPesquisar ?
                                         this.state.loading ? 
                                            <div className="centro">
                                                <div className="espaco_topo2">
                                                    <Spinner animation="border" />
                                               </div>
                                            </div>                    
                                            :
                                            <div style={{height: this.state.grid_height, overflow: 'hidden'}}>       

                                                <ContextMenu
                                                    dataSource={this.state.items_coleta}
                                                    width={180}
                                                    target="#listGridRel"
                                                    itemRender={this.ItemTemplate}
                                                    onItemClick={this.itemClick} />          

                                                <DataGrid
                                                    id="listGridRel"
                                                    dataSource={this.state.list}
                                                    // defaultColumns={columns_coleta}
                                                    showBorders={true}
                                                    // onRowPrepared={this.onRowPrepared}
                                                    columnAutoWidth={true}
                                                    // onRowUpdated={this.onRowUpdated}
                                                    height={430}
                                                    allowColumnResizing={true}
                                                    rowAlternationEnabled={true}
                                                    onContextMenuPreparing={onContextMenuPreparing}
                                                    ref={this.dataGridRefColeta}
                                                    allowColumnReordering={true}
                                                    repaintChangesOnly={true}
                                                    > 
                                                    <Selection mode="single" />
                                                    <Scrolling mode="virtual" />
                                                    <FilterRow visible={this.state.visibleFilterRow} />
                                                    <ColumnChooser enabled={true} mode="select" />

                                                    {/* <Column dataField="entregas_count_icon"
                                                        caption=""
                                                        width={50}
                                                        allowSorting={false}
                                                        cellRender={cellRender}
                                                        visible={this.state.icone_visible}
                                                        >          
                                                    </Column> */}
                                                    <Column dataField="index" visible={false} width={50} />
                                                    <Column dataField="is_coleta_str" caption= "Coleta/Entrega" cellRender={ColetaEntregaCell} visible={true}  width={110}  /> 
                                                    <Column dataField="id" caption= "Id" visible={true} /> 
                                                    <Column dataField="usuario" caption= "Usuário" width={150} />
                                                    <Column dataField="data_previsao" caption={"Previsão Coleta/Entrega"} format="EE  dd/MM/yyyy HH:mm" dataType="datetime" width={165}  />
                                                    <Column dataField="data_coleta_entrega" caption={"Data Coleta/Entrega"} format="EE  dd/MM/yyyy HH:mm" dataType="datetime" />
                                                    <Column dataField="tipo_pagamento" caption= "Pagamento" width={90}/>
                                                    <Column dataField="valor" caption= "Frete"/>
                                                    <Column dataField="tda" caption= "TDA"/>
                                                    <Column dataField="total_frete_tda" caption= "Total Frete"/>
                                                    <Column dataField="total_frete_tda2" caption= "Total Frete2" visible={false}/>
                                                    <Column dataField="pago_str" caption= "Pago" cellRender={PagoCell}/>
                                                    <Column dataField="rota" width={100} />
                                                    <Column dataField="cliente" caption= "Cliente" width={100} />
                                                    <Column dataField="filial" caption= "Filial" width={100} />                                                    
                                                    <Column dataField="municipio" caption= "Município" width={100} />                                                                                                        
                                                    <Column dataField="cte" caption= "CTE" visible={this.state.cte_visible}  />
                                                    <Column dataField="observacao" caption= "Observação" width={100} visible={this.state.observacao_visible} />
                                                    <Column dataField="nota_fiscal" caption= "NF"  />
                                                    <Column dataField="canhoto" caption= "Tem Comprovante?" alignment='center'/>                               

                                                    {/* <MasterDetail enabled={true} render={this.renderDetail} /> */}

                                                    <Summary>
                                                        <TotalItem
                                                            column="is_coleta_str"
                                                            summaryType="count"
                                                            customizeText = {this.customizeCount} />                       

                                                        {/* <TotalItem
                                                            column="valor"
                                                            customizeText = {this.customizeSum}
                                                            /> */}
                                                        {/* <TotalItem
                                                            column="total_frete_tda"
                                                            customizeText = {this.customizeSomaTotalFrete}
                                                            />     */}

                                                        <TotalItem
                                                            column="total_frete_tda2"
                                                            summaryType="sum"
                                                            showInColumn="total_frete_tda"
                                                            customizeText = {this.customizeSomaTotalFrete2}
                                                         />  
                                                    </Summary>

                                                </DataGrid>
                                            </div>                                                
                                    :<div className={"espaco_bottom2"}></div>}                         
                                        
                                </div>
                        
            
                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>
            
        );
    }
}    

function cellRender(data) {
    if(data.value !== null)
      return <img src={data.value} width="20"  height="20"/>;
    else return <img src={require("../img/transparent.png")} width="20"  height="20"/>;
  }

//------------------------
function PagoCell(cell) {
  return (
    <div className={cell.data.pago === true ? 'text_green' : 'text_red' }>
      <span>{cell.text}</span>
    </div>
  );
}  
//------------------------
function ColetaEntregaCell(cell) {
  return (
    <div className={cell.data.is_coleta === true ? 'text_blue' : 'text_orange' }>
      <span>{cell.text}</span>
    </div>
  );
}  