import React, {Component} from 'react';
import {ListGroup } from 'react-bootstrap';
import './estilo.css';
import { ToastStore} from 'react-toasts';
import {CabecalhoModal} from './CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';

export class TemasMapaModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            animation: "zoom",
            scrollOffset: 0,

            temas: [
                {id:1, nome:"Retrô", selecionado:false, img: require('./img/mapa_kseg.png'), index_scroll:0}
                ,{id:2, nome:"Noite", selecionado:false, img: require('./img/mapa_noite.png'), index_scroll:0}
                ,{id:3, nome:"Dark", selecionado:false, img: require('./img/mapa_dark.png'), index_scroll:0}
                ,{id:4, nome:"Aubergine", selecionado:false, img: require('./img/mapa_aubergine.png'), index_scroll:100}
                ,{id:5, nome:"Tático", selecionado:false, img: require('./img/mapa_tatico.png'), index_scroll:200}
            ],
        };
      }
    //-------------------
    minimizarModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "slideLeft"});
        this.props.FloatActionButton.closeTemasMapaModal();
    };
    //---------close----------
    fecharModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "zoom"});
        this.props.FloatActionButton.closeTemasMapaModal();
    };
    //------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.FloatActionButton.temasMapaModalDesativar();
    };
    //------------------------------------------
    setStateTema = (id) =>{
        // Step 1: Find the element
        const elementsIndex = this.state.temas.findIndex(element => element.id == id );
        // Step 2: Create a copy of the state array
        let newArray = [...this.state.temas];
        // Step 3: Update the one value
        newArray[elementsIndex] = {...newArray[elementsIndex], selecionado: !newArray[elementsIndex].selecionado};
        // Step 4: SetState
        this.setState({temas: newArray,});
        // this.setState({temas: newArray, scrollOffset: newArray[elementsIndex].index_scroll, });
    };
    //------------------------------------------
    disableEnableTema = (id_disable, id_enable) =>{
        // Step 1: Find the element
        const index_disable = this.state.temas.findIndex(element => element.id == id_disable );
        const index_enable = this.state.temas.findIndex(element => element.id == id_enable );
        // Step 2: Create a copy of the state array
        let newArray = [...this.state.temas];
        // Step 3: Update the one value
        newArray[index_disable] = {...newArray[index_disable], selecionado: !newArray[index_disable].selecionado};
        newArray[index_enable] = {...newArray[index_enable], selecionado: !newArray[index_enable].selecionado};
        // Step 4: SetState
        this.setState({temas: newArray,});
    };
    //------------------------------------------
    setTemaMapa = (tema_mapa) =>{
        if(tema_mapa !== this.props.MapaKseg.state.tema_mapa){
            this.disableEnableTema(this.props.MapaKseg.state.tema_mapa, tema_mapa);
            this.props.MapaKseg.setTemaMapa(tema_mapa)
        }
    };  
    //------------------------
    async componentDidMount() {
        this.setStateTema(this.props.MapaKseg.state.tema_mapa);
    }
    //-------------------------
    async componentDidUpdate() {
        // this.scrollElement();
        // ToastStore.success("shiitt");
        // if(this.state.scrollOffset===0)
        //     this.setState({scrollOffset: 800, });
    }
    //-------------------------
    render(){
        //--------------------
        return(
            <div className="drag1" >
                <Draggable   handle="strong"  >                
                    <div >                    
                        <Rodal 
                            visible={this.props.show}
                            animation={this.state.animation}
                            showCloseButton={false}
                            duration={600}
                            width={400}
                            height={580}
                            onAnimationEnd={()=>this.desativarModal()}
                            >  
                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false} titulo={"Temas de mapa"} Modal={this}/></strong>
                                <div className="padding_modal">
                                    <div style={{height: '500px', overflow: 'hidden'}}>                                    
                                        <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                                            <ListGroup>{
                                                this.state.temas.map((tema) =>  
                                                    tema.selecionado?
                                                        <ListGroup.Item action variant="dark" onClick={()=>this.setTemaMapa(tema.id)}>
                                                        <div >
                                                            <input type="radio" className="espaco_right" checked />
                                                            <img className="margin_cantos5" src={tema.img}/>
                                                            {tema.nome+'  '}    
                                                            { this.state.scrollOffset===0 ? 
                                                                tema.index_scroll > 70?
                                                                    this.setState({scrollOffset: tema.index_scroll}) 
                                                                : ""
                                                            : ""}                                        
                                                        </div>
                                                    </ListGroup.Item>
                                                    : 
                                                        <ListGroup.Item action variant="" onClick={()=>this.setTemaMapa(tema.id)}>
                                                            <div>
                                                                <input type="radio" className="espaco_right" />
                                                                <img className="margin_cantos5" src={tema.img}/>
                                                                {tema.nome+'  '}                                           
                                                            </div>
                                                        </ListGroup.Item>
                                                    )}
                                            </ListGroup>   
                                        </CustomScroll>                                        
                                    </div>                             
                                </div>                                
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>
        
        );
    }
}