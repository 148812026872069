import React, {Component} from 'react';
import {InputGroup, Spinner, Button,OverlayTrigger, Tooltip, Form, Row, Col, Image} from 'react-bootstrap'; 
import DataGrid, {  Scrolling, Selection, Column, FilterRow, ColumnChooser, Summary, TotalItem } from 'devextreme-react/data-grid';
import ContextMenu from 'devextreme-react/context-menu';
import '../estilo.css';
import { ToastStore} from 'react-toasts';
import api from '../api';
import FilterSelect from '../filterSelect'
import { DateBox } from 'devextreme-react';
import {CabecalhoModal} from '../CabecalhoModal';
import GetClientePessoa from '../services/get_cliente_pessoa';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';

import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';

// ---------Export detail -----------
import FileSaver from "file-saver";
// import service from "./data.js";


const getEmployeeCaption = ({ cliente, municipio }) =>
  'Entregas:';

const CLIENTES=1;

var moment = require('moment');
require('moment-precise-range-plugin');


export class RelEntregaModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true,
            entregas:[],
            loading:true,
            filter_list: [],
            data_inicial: null,
            data_final: null,
            value_data_inicial: null,
            value_data_final: null,
            btnPesquisar:false,
            placa:null,
            MapaKseg: props.MapaKseg,
            animation: "zoom",
            minimizar:false,
            drag:"drag3",
            width:1000,
            height:600,
            grid_height:'450px',
            entrega:{},
            filial_id:0,
            cliente_id:0,
            usuario_id:0,
            clientes_list:[],
            loading_clientes:true,
            filiais_list:[],
            loading_filiais:true,
            items_entrega:[],
            usuarios_list:[],
            loading_usuarios:true,  
            visible_datetime_text:false,
            observacao_visible: true,
            cte_visible:true,
            soma: 0,
            soma_total_frete:0,
            objeto_selecionado: null,
        };
        this.now = new Date();
        this.dataGridRefEntrega = React.createRef();
      }    
    //------------------------------------------------------------------
    async componentDidMount() {
        // this.props.MapaKseg.callToast("Width: "+String(window.innerWidth)+"   Height: "+String(window.innerHeight));
        var data_hoje= new Date();
        var data_ontem = new Date();
        data_ontem.setDate(data_ontem.getDate() - 1);
        // this.props.MapaKseg.callToast("data_ontem: "+String(data_ontem));

        // this.getClientes();
        this.getFiliais();
        this.getUsuarios();

        this.setState({
            data_inicial: this.formatarDateTime(String(data_ontem))
            ,value_data_inicial: String(data_ontem)
            ,data_final: this.formatarDateTime(String(data_hoje))
            ,value_data_final: String(data_hoje)
        });


        
        this.setState({drag: "drag3",width: 1000 });
        // this.setState({drag: "drag5",width: 1199 });

        this.carregarItemsEntregaContextMenu();
    };      
    //------------------------------------------
    get dataGrid() {
        return this.dataGridRefEntrega.current.instance;
    }
    //------------------------------------------
    carregarItemsEntregaContextMenu() {
        var items_entrega = this.state.items_entrega;

        items_entrega.push({ text: ' Excel Exportar', icon: "dx-icon-exportxlsx"  });
        items_entrega.push({ text: ' PDF Exportar', icon: "dx-icon-exportpdf"  });
        items_entrega.push({ text: ' Abrir Comprovante', icon: "dx-icon-file"  });

        this.setState({items_entrega: items_entrega}); 
    }
    //-------------------------------------------------------------------
    ItemTemplate (e) {
        return (
        <React.Fragment>
            <span className={ e.icon } />
            { e.items_entrega ? <span className="dx-icon-spinright" /> : null }
            { e.text }
        </React.Fragment>
        );
    }
    //---------------------------------------------------------------
    itemClick = (e) => {    
        if (!e.itemData.items_entrega) {      
            if(e.itemData.text === ' Excel Exportar'){     
                this.exportDataGridToxlsx("Entregas Excel");
            }else if(e.itemData.text === ' PDF Exportar'){  
                this.exportGridPDF("Entregas PDF");        
            }else if(e.itemData.text === ' Abrir Comprovante'){  
                var selectedRowData = this.dataGrid.getSelectedRowsData(); 
                this.props.Modals.showComprovanteModal(this, "Comprovante", selectedRowData[0], "visualizar");  
            }
        }
    }
      //-------------------------------------------------------------------------------
    exportGridPDF=(e)=> {
        this.setState({icone_visible: !this.state.icone_visible
                        ,observacao_visible: false
                        ,cte_visible:false}); 
        const doc = new jsPDF();

        let today = new Date();
        let time = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
        let date = today.getDate() + "-" + (today.getMonth() + 1) + '-' + today.getFullYear() + '  '+time;

        let dataGrid = this.dataGridRefEntrega.current.instance;
        let nomeArquivo = "Entregas "+date+".pdf";

        if(e == "Entregas PDF"){
        dataGrid = this.dataGridRefEntrega.current.instance;
        nomeArquivo = "Entregas "+date+".pdf";
        }
    
        exportDataGridToPdf({
        jsPDFDocument: doc,
        component: dataGrid
        }).then(() => {
            doc.save(nomeArquivo);
            this.setState({icone_visible: !this.state.icone_visible
                            , observacao_visible: true
                            ,cte_visible:true});
        });    
    }
    //------------------------------------------------------------------
    exportDataGridToxlsx = (e) =>  {
        this.setState({
          visible_datetime: false,
          visible_datetime_text: true,
          icone_visible: false}); 
    
        let today = new Date();
        let time = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
        let date = today.getDate() + "-" + (today.getMonth() + 1) + '-' + today.getFullYear() + '  '+time;
    
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
    
        let compo = this.dataGridRefEntrega.current.instance;
        let nomeArquivo = "Coletas "+date+".xlsx";
        
        if(e == "Entregas Excel"){
          compo = this.dataGridRefEntrega.current.instance;
          nomeArquivo = "Entregas "+date+".xlsx";
        }
    
        exportDataGrid({
            // component: e.component,
            component: compo,
            worksheet: worksheet
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), nomeArquivo);
            this.setState({
              visible_datetime: true,
              visible_datetime_text: false,
              icone_visible: true});
            });
        });
    
      }
    //------------------------------------------------------------------
      onClickPesquisar = (e) => {
        const age=this.diffInDay;
        
        if(this.state.data_inicial===null || String(this.state.data_inicial).includes("undefined"))
            ToastStore.error("Data inicial está vazia!");
        else if(this.state.data_final===null || String(this.state.data_final).includes("undefined"))
            ToastStore.error("Data final está vazia!");
        // else if(age > 31)
        //     ToastStore.error("Período maior que 31 dias!");
        // else if(this.state.veiculo_id===null)
        // ToastStore.error("Selecine um veículo!");    
        else
            this.loadData();
      }
      //------------------------------------------------------------------
      loadData = (e) => {
        this.setState({list:[]});
        if(this.state.btnPesquisar===true){
            this.setState({btnPesquisar:false});
            this.setState({loading:true});
        }
        this.setState({btnPesquisar:true, entregas: []});
        this.get();
      }
      //------------------------------------------------------------------
      get diffInDay() {
        return `${Math.floor(Math.abs((this.state.value_data_final - this.state.value_data_inicial) / (24 * 60 * 60 * 1000))) } `;
      }
      //------------------------------------------------------------------
      onValueChangedDataInicial = (e) => {
        this.setState({
          data_inicial: this.formatarDateTime(e.value),
          value_data_inicial: e.value
        });
        // ToastStore.success( String(this.state.data_inicial));
      }
      //------------------------------------------------------------------
      onValueChangedDataFinal = (e) => {
        this.setState({
          data_final: this.formatarDateTime(e.value),
          value_data_final: e.value
        });
        // ToastStore.success( String(this.state.data_final));
      }
      //------------------------------------------------------------------
      formatarMes(mes){    
        if(mes==="Jan")
            return 1;
        else if (mes==="Feb")
            return 2;
        else if (mes==="Mar")
            return 3;     
        else if (mes==="Apr")
            return 4;     
        else if (mes==="May")
            return 5;     
        else if (mes==="Jun")
            return 6; 
        else if (mes==="Jul")
            return 7; 
        else if (mes==="Aug")
            return 8; 
        else if (mes==="Sep")
            return 9; 
        else if (mes==="Oct")
            return 10;    
        else if (mes==="Nov")
            return 11;
        else if (mes==="Dec")
            return 12;             
      }
      //------------------------------------------------------------------
      formatarDateTime(dtTime){    
        const y = String(dtTime).substring(11,15);
        const mm = this.formatarMes(String(dtTime).substring(4,7));
        const dd = String(dtTime).substring(8,10);
        const hm = String(dtTime).substring(16,21);

        // console.log("  *** dtTime: "+String(dtTime));
        // console.log("  *** y: "+y);
        // console.log("  *** mm: "+mm);
        // console.log("  *** mm: "+String(this.formatarMes(mm)));
        // console.log("  *** dd: "+dd);
        // console.log("  *** hm: "+hm);  
        const datetime = y+"-"+mm+"-"+dd+" "+hm;  // "Y-MM-DD H:M"
        return datetime;                  
      }
     //------------------------------------------------------------------
     formatarDateTimeGrid(dtTime){
         //  sex 21:47:10   03-07-2020  
         if(dtTime===null)
            return "";

        const diaSema = String(dtTime).substring(0,3);
        const y = String(dtTime).substring(23,25);
        const mm = String(dtTime).substring(18,20);
        const dd = String(dtTime).substring(12,17);
        const hm = String(dtTime).substring(4,12);    
        const datetime = diaSema+", "+dd+"/"+mm+"/"+y+"   "+hm;  // "Y-MM-DD H:M"
        return datetime;  
     }
     //--------------------------------
     formatNumber = (q) => {
        return q.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        })
       } 
    //--------------------------------------------------------------
    somarFreteTDA =(_frete, _tda) =>{
        _frete = parseFloat(_frete.replace(".", "").replace(",", "."));
  
        if (_tda !== "") _tda = parseFloat(_tda.replace(".", "").replace(",", "."));
        else _tda = 0;
  
        let total = _frete + _tda;
        total = total.toLocaleString('pt-br', {minimumFractionDigits: 2});
        return total;        
      }       
    //------------------------------------------------------------------
    get = async () => {
        try {
            let body = new FormData()
            body.append('pessoa_id', this.props.MapaKseg.state.pessoa_id)
            body.append('data_inicial', this.state.data_inicial)
            body.append('data_final', this.state.data_final)
            body.append('token', localStorage.getItem('token'))

            var filiais_list = this.state.filiais_list;
            var filiais_id_str="";
            for (var i = 1; i < filiais_list.length; i++) {  
                if (i==1) filiais_id_str+=filiais_list[i].value;
                else filiais_id_str+=","+filiais_list[i].value;
            }
            
            if(filiais_id_str === "") filiais_id_str = "0"

            body.append('filiais_id_str', filiais_id_str);

            if(this.state.filial_id !== 0)
                body.append('filial_id', this.state.filial_id);
            
            if(this.state.cliente_id !== 0)
                body.append('cliente_id', this.state.cliente_id);  
            
            if(this.state.usuario_id !== 0)
              body.append('usuario_id', this.state.usuario_id); 

            var url = "/relatorio_api/getEntregas";
            // var url = "/teste_api/getEntregas";

            const response = await api.post(url, body);


            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
        
                let entregas = []; 
                let entrega = {};
                let soma=0;
                let soma_total_frete=0;

                // ToastStore.success("resposta.length: "+ String(resposta.length));
                  
                for (var i = 0; i < resposta.length; i++) {
                // for (var i = 0; i < 5; i++) {
                    entrega = {    
                        index: i+1,
                        entrega_id: resposta[i].entrega_id,
                        cliente: resposta[i].cliente,
                        rota: resposta[i].rota,
                        data_previsao: resposta[i].data_previsao,
                        data_previsao2: resposta[i].data_previsao2,
                        data_entrega: resposta[i].data_entrega,
                        data_entrega2: resposta[i].data_entrega2,
                        tipo_pagamento: resposta[i].tipo_pagamento,
                        
                        valor: resposta[i].valor,
                        // valor: Number(resposta[i].valor.replace(",", "")),
                        // valor: i==0? parseFloat('1.342,52') : parseFloat(resposta[i].valor),
                        valor2: resposta[i].valor !== "" ? parseFloat(resposta[i].valor.replace(".", "").replace(",", ".")) : 0,
                        // valor: i==0? parseFloat('1.342,52'.replace(".", "").replace(",", ".")) : parseFloat(resposta[i].valor.replace(",", ".")),
                        // valor3:  resposta[i].valor.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}),
                        // valor3: i==0? parseFloat('1.342,52') : resposta[i].valor.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'})
                        
                        tda_entrega: resposta[i].tda_entrega,
                        total_frete_tda: 0,

                        local: resposta[i].local,     
                        usuario: resposta[i].usuario,           
                        municipio: resposta[i].municipio,    
                        coleta_id: resposta[i].coleta_id,    
                        cte_entrega: resposta[i].cte_entrega,
                        observacao_entrega: resposta[i].observacao_entrega,    

                        cliente_coleta: resposta[i].cliente_coleta,
                        data_previsao_coleta: resposta[i].data_previsao_coleta,
                        data_coleta: resposta[i].data_coleta,
                        valor_coleta: resposta[i].valor_coleta,
                        municipio_coleta: resposta[i].municipio_coleta,
                        cte_coleta: resposta[i].cte_entrega,
                        observacao_coleta: resposta[i].observacao_entrega,
                        pago_coleta: resposta[i].pago_coleta,
                        pago_coleta_str: '',
                        pago_entrega: resposta[i].pago_entrega,
                        pago_entrega_str: '',
                        filial_coleta: resposta[i].filial_coleta,
                        filial_entrega: resposta[i].filial_entrega,
                        nota_fiscal_entrega: resposta[i].nota_fiscal_entrega,
                        canhoto: resposta[i].canhoto,
                        canhoto64: "",

                        status:2,
                        selecionada: false,
                        index_scroll: (i+2)*25,
                    };

                    if(String(entrega.data_entrega).trim() !== "")
                        entrega.status = 1 // 1 - concluido


                    if(entrega.pago_coleta === true)
                        entrega.pago_coleta_str = 'Foi Pago'
                    else if(entrega.pago_coleta === false)    
                        entrega.pago_coleta_str = 'Não Pago'

                    if(entrega.pago_entrega === true)
                        entrega.pago_entrega_str = 'Foi Pago'
                    else if(entrega.pago_entrega === false)    
                        entrega.pago_entrega_str = 'Não Pago'    

                    // if(entrega.canhoto == true) entrega.canhoto = 'Sim';
                    // else if(entrega.canhoto == false) entrega.canhoto = '';

                    // entrega.valor3 = entrega.valor2.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'});
                    soma = soma + entrega.valor2;
                    
                    entrega.total_frete_tda = this.somarFreteTDA(entrega.valor, entrega.tda_entrega);
                    soma_total_frete = soma_total_frete + ( parseFloat(entrega.total_frete_tda.replace(".", "").replace(",", ".")))

                    entregas.push(entrega);                    
                }                          
                soma = soma.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'});
                soma_total_frete = soma_total_frete.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'});
                this.setState({entregas: entregas, soma: soma, soma_total_frete:soma_total_frete});              
            }

        } finally {
            this.setState({loading: false});
            }
    };
    //------------------------------------------------------------------
    getClientes = async () => {
        try {
            let body = new FormData()
            body.append('pessoa_id', this.props.MapaKseg.state.pessoa_id)
            body.append('token', localStorage.getItem('token'))  

            var url = "/relatorio_api/getClientes";

            const response = await api.post(url, body);
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);    
                let clientes_list = []; 
                  
                clientes_list.push({ value: 0, label: "Todos" });

                for (var i = 0; i < resposta.length; i++) {             
                    clientes_list.push({ value: resposta[i].cliente_id, label: resposta[i].cliente });
                    this.setState({clientes_list: clientes_list});
                }                                        
            }

        } finally { this.setState({loading_clientes: false}); }
    };
    //------------------------------------------------------------------
    getFiliais = async () => {
        try {
            let body = new FormData()
            body.append('pessoa_id', this.props.MapaKseg.state.pessoa_id);
            body.append('usuario_id', this.props.MapaKseg.state.usuario_id);
            body.append('token', localStorage.getItem('token'));  

            var url = "/relatorio_api/getFiliais";
            // var url = "/teste_api/getFiliais";

            const response = await api.post(url, body);
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);    
                let filiais_list = []; 

                filiais_list.push({ value: 0, label: "Todos" });

                for (var i = 0; i < resposta.length; i++) {             
                    filiais_list.push({ value: resposta[i].filial_id, label: resposta[i].filial });
                    this.setState({filiais_list: filiais_list});
                }                                        
            }

        } finally { this.setState({loading_filiais: false}); }
    };
    //------------------------------------------------------------------
    getUsuarios = async () => {
    try {
        let body = new FormData()
        body.append('pessoa_id', this.props.MapaKseg.state.pessoa_id)
        body.append('token', localStorage.getItem('token'))  

        var url = "/relatorio_api/getUsuarios";
        // var url = "/teste_api/getUsuarios";

        const response = await api.post(url, body);
        var responseJson = null

        if (response.data !== null  &&  response.status !== 403){
            responseJson = JSON.stringify(response.data)
            var req = { json: responseJson };
            var resposta = JSON.parse(req.json);    
            let usuarios_list = []; 

            usuarios_list.push({ value: 0, label: "Todos" });

            for (var i = 0; i < resposta.length; i++) {             
                usuarios_list.push({ value: resposta[i].usuario_id, label: resposta[i].usuario });
                this.setState({usuarios_list: usuarios_list});
            }                                        
        }

    } finally { this.setState({loading_usuarios: false}); }
}; 
    //-----------------------------------------------------------------------------------------
    setCliente = (cliente_id) =>{
        this.setState({cliente_id: cliente_id});
    };
    //-----------------------------------------------------------------------------------------
    setFilial = (filial_id) =>{
        this.setState({filial_id: filial_id});                                           
    };
    //-----------------------------------------------------------------------------------------
    setUsuario = (id) =>{
        this.setState({usuario_id: id});                                           
    };
    //-----------------------------------------------------------------------------------------
    setEntregasCount = (count) =>{
        if(count===1) return  require("../img/circle1.png");
        else if(count===2) return require("../img/circle2.png")
        else if(count===3) return require("../img/circle3.png")
        else if(count===4) return require("../img/circle4.png")
        else if(count===5) return require("../img/circle5.png")
        else if(count>5) return require("../img/circle5plus.png")
        else if(count>9) return require("../img/circle9plus.png")                                            
    };
    //------------------------------------------------------------------
    getDataInicial(){
        var data_ontem = new Date();
        data_ontem.setHours(data_ontem.getHours() - 1);
        return data_ontem;  
     }
     //------------------------------------------------------------------
     minimizarModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
      
        this.props.Modals.closeRelEntregaModal();
        this.props.MapaKseg.setState({sidenav_visible:true});

        if(this.props.Modals.state.GridColetaEntrega != null)
            this.props.Modals.state.GridColetaEntrega.visibleFilterrow();
    };
    //------------------------------------------------------------------
    maximizarModal = () =>{
        var grid_h = String(window.innerHeight - 150)+"px";
        this.setState({width: window.innerWidth
                        ,height: window.innerHeight
                        ,drag: "drag0"
                        ,grid_height: grid_h});
    };
    //------------------------------------------------------------------
    restoreModal = () =>{
        var grid_h = String(400)+"px";
        this.setState({width: 1000
                        ,height: 520
                        ,drag: "drag3"
                        ,grid_height: grid_h});                     
    };
    //------------------------------------------------------------------
    fecharModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "zoom"});
        this.setState({minimizar:false});

        this.props.Modals.closeRelEntregaModal();
        this.props.MapaKseg.setState({sidenav_visible:true});    

        if(this.props.Modals.state.GridColetaEntrega != null)
            this.props.Modals.state.GridColetaEntrega.visibleFilterrow();
    };
    //------------------------------------------------------------------
    desativarModal = () =>{    
        if(this.props.show === false){           
            if(this.state.minimizar)
                this.props.Modals.closeRelEntregaModal();
            else this.props.Modals.desativarRelEntregaModal();            
        }     
    };
    //----------------------------------------------------
    dateDiff=(data_inicio, data_final)=>{
        // data_inicio: sex 21:47:10   03-07-2020 
        if(String(data_inicio).length> 19){
            var ano_anterior = data_inicio.substring(21,25);
            var mes_anterior = data_inicio.substring(18,20);
            var dia_anterior = data_inicio.substring(15,17);
            var hora_anterior = data_inicio.substring(4,12);
            var a = moment(ano_anterior+"-"+mes_anterior+"-"+dia_anterior+"T"+hora_anterior+"-03:00");  //2020-03-17T17:39:31-03:00
    
            var ano = data_final.substring(21,25);
            var mes = data_final.substring(18,20);
            var dia = data_final.substring(15,17);
            var hora = data_final.substring(4,12);
            var b = moment(ano+"-"+mes+"-"+dia+"T"+hora+"-03:00");  //2020-03-17T17:39:31-03:00
            const precise_diff =  moment.preciseDiff(a, b, true); 
        
            const diff = b.diff(a);
            const diffDuration = moment.duration(diff);
            const diff_minutes = parseInt(diffDuration.asMinutes())
            // const diff_days = parseInt(diffDuration.asDays())
        
            // // alert(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes");
            // alert(diff_minutes + " minutes");
        
            // console.log("****  diff_minutes: "+String(diff_minutes));
        
            // return String(ano_anterior+"-"+mes_anterior+"-"+dia_anterior+"T"+hora_anterior+"-03:00");
            // return "("+String(diff_minutes)+"min)";
        
            if(precise_diff.minutes===0)
                if(precise_diff.seconds===0)
                    return "0";
                else return precise_diff.seconds+"seg";
            else if(precise_diff.hours === 0)
                return precise_diff.minutes+"min";
            else if(precise_diff.days === 0){
                if(precise_diff.hours===1)
                return String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
                else return String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
            }else if(precise_diff.months === 0){
                if(precise_diff.days===1)
                return String(precise_diff.days)+"dia "+String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
                else return String(precise_diff.days)+"dias "+String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
            }else if(precise_diff.years === 0){
                if(precise_diff.months===1)
                return String(precise_diff.months)+"mes "+String(precise_diff.hours)+"dia "+String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
                else return String(precise_diff.months)+"meses "+String(precise_diff.hours)+"dia "+String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
            }
        }
    }
    //---------------------------------------------------------------
    showGeralList= (e) => {   
        // this.props.MapaKseg.callToast(e.target.getAttribute('value') );
        // if(e.target.getAttribute('value') == CLIENTES)
            this.props.Modals.showGeralList(this,"Clientes", CLIENTES, true);
        // else if(e.target.getAttribute('value') == COLETA_CLIENTE_LOCAL_TIPO)
        //     this.props.Modals.showGeralList(this,"Local da Coleta", COLETA_CLIENTE_LOCAL_TIPO);    
        // else if(e.target.getAttribute('value') == COLETA_ROTA_TIPO)
        //     this.props.Modals.showGeralList(this,"Rotas Coleta", COLETA_ROTA_TIPO);      
    }; 
    //-----------------------------------------------------------------------------------------
    getList= (geralListModal, tipo) => { 
        this.setState({GeralListModal: geralListModal});  

        if(tipo == CLIENTES){             
            var instance = new GetClientePessoa(null, this, this.props.MapaKseg, CLIENTES);
            instance.get(); 
        }
        // else if(tipo == COLETA_CLIENTE_LOCAL_TIPO){ 
        //     var instance = new GetClientePessoa(null, this, this.props.MapaKseg, COLETA_CLIENTE_TIPO);
        //     instance.get(); 
        // }else if(tipo == COLETA_ROTA_TIPO){ 
        //     var instance = new GetRotaPessoa(null, this, this.props.MapaKseg, COLETA_ROTA_TIPO);
        //     instance.get();   
        // }
    }; 
    //--------------------------------------------------------------
    setListClientes= (_list, tipo) => {  
        let array =[];
        let descr = "";

        for (var i = 0; i < _list.length; i++) { 

            if(_list[i].cnpj_cpf == null) descr = _list[i].nome + " ("+_list[i].local+")"
            else descr = _list[i].nome +" ("+_list[i].cnpj_cpf +") ("+_list[i].local+")"

            array.push({
                index: _list[i].index
                ,id: _list[i].cliente_id
                , descricao: descr
                , descricao2: _list[i].nome
                , local_id: _list[i].local_id
                , local: _list[i].local
                , selecionada: _list[i].selecionada
                , index_scroll: _list[i].index_scroll});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //-----------------------------------------------------------------------
    setObjeto= (objeto, tipo) => { 

        if(tipo == CLIENTES){
            let cliente_id = this.state.cliente_id;
            let cliente = this.state.cliente;

            cliente_id = objeto.id;
            cliente = objeto.descricao2;              

            this.setState({cliente_id: cliente_id, cliente: cliente});   
        }
        // else if(tipo == COLETA_CLIENTE_LOCAL_TIPO){
        //     let coleta = this.state.coleta;
        //     coleta.local_id = objeto.local_id;
        //     coleta.local = objeto.local;
        //     this.setState({coleta: coleta});

        // }
        // else if(tipo == COLETA_ROTA_TIPO){
        //     let coleta = this.state.coleta;
        //     coleta.rota_id = objeto.id;
        //     coleta.rota = objeto.descricao2;
        //     this.setState({coleta: coleta});
            
        // }
    }
    //-------------------------------------------------
    customizeSum= () => {
        return this.state.soma + '';
      }
    //-------------------------------------------------
    customizeSomaTotalFrete= () => {
        return this.state.soma_total_frete + '';
    }      
    //--------------------------------------------------
    customizeCount(data) {
        return `${data.value}`;
        }
    //------------------------------------------------------------------
    render(){
        var data_hoje = new Date();
        var data_ontem = new Date();
        data_ontem.setDate(data_ontem.getDate() - 1);
        // data_ontem.setHours(data_ontem.getHours() - 1);

        const renderTooltipCliente = (props) => (
            <Tooltip id="button-tooltip" {...props}>Cliente</Tooltip>
          );

        const onContextMenuPreparing = (e) =>{
        try{
            e.component.selectRows(e.row.key, false)
            // var selectedRowData = this.dataGrid.getSelectedRowsData();
            // this.props.MapaKseg.ajustarZoom(selectedRowData[0]);

            // console.log(" e.row.key: ");
            // console.log(e.row.key);
            this.setState({objeto_selecionado: e.row.key});
            // alert(e.row.key);
        }catch (response){}
        }  

        return(
            <div className={this.state.drag} >
                <Draggable   handle="strong"  >                
                    <div >                    
                        <Rodal 
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={this.state.width}
                            height={this.state.height}
                            onAnimationEnd={()=>this.desativarModal()}
                            >  
                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={true} maximizar={true} titulo={this.props.titulo} Modal={this}/></strong>
                                    <Form > 
                                        <div className="options">
                                            <Row>
                                                <Col xs={4}>
                                                    <Form.Group as={Row} controlId="formClienteColeta">                                                                                                 
                                                        <Col sm={10}>
                                                            <InputGroup className="mb-1" >
                                                                <InputGroup.Prepend >
                                                                    <OverlayTrigger overlay={renderTooltipCliente} placement="right" delay={{ show: 250, hide: 400 }} >  
                                                                    <Button id="formClienteBt" value={CLIENTES} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                        <Image value={CLIENTES} src={require('../img/text_box_search48.png')} width='20'/>
                                                                    </Button>
                                                                    </OverlayTrigger>  
                                                                </InputGroup.Prepend>

                                                                <Form.Control  aria-describedby="basic-addon1" value={this.state.cliente} placeholder={"Cliente"}  >                                                                         

                                                                </Form.Control>
                                                            </InputGroup>
                                                        </Col>                                                  
                                                    </Form.Group> 
                                                </Col>
                                                <Col>
                                                    {this.state.loading_filiais ? <Spinner size="sm" animation="border" /> 
                                                    : <FilterSelect list={this.state.filiais_list} tipo={4} RelColetaEntregaModal={this} placeholder={"Filial"} /> }
                                                </Col>
                                                <Col>
                                                    {this.state.loading_usuarios ? <Spinner size="sm" animation="border" /> 
                                                    : <FilterSelect list={this.state.usuarios_list} tipo={5} RelColetaEntregaModal={this} placeholder={"Usuário Entrega"} /> }
                                                </Col>
                                                <Col>
                                                    <Button variant="primary" onClick={()=>this.onClickPesquisar()}>
                                                        <img src={"img/lupa.png"} alt="" Width="20" />
                                                    </Button>
                                                </Col>
                                            </Row>

                                            <Row>  
                                                <div className="option">                                                                  
                                                    <span>Previsão Entrega:</span>    
                                                </div>                                          
                                                <DateBox displayFormat="dd/MM/yyyy HH:mm" placeholder="Dada inicial" 
                                                    className="headerDateTime"  type="datetime" 
                                                    onValueChanged={this.onValueChangedDataInicial}
                                                    defaultValue={data_ontem} /> 
                                            
                                                <DateBox displayFormat="dd/MM/yyyy HH:mm" placeholder="Data final" 
                                                    className="headerDateTime"  type="datetime" 
                                                    onValueChanged={this.onValueChangedDataFinal}
                                                    defaultValue={data_hoje}/>                                                                                                                                                                                                                                                                                                               
                                            </Row>
                                        </div> 
                                    </Form>


                                    {this.state.btnPesquisar ?
                                         this.state.loading ? 
                                            <div className="centro">
                                                <div className="espaco_topo2">
                                                    <Spinner animation="border" />
                                               </div>
                                            </div>                    
                                            :
                                            <div style={{height: this.state.grid_height, overflow: 'hidden'}}>       

                                                <ContextMenu
                                                    dataSource={this.state.items_entrega}
                                                    width={180}
                                                    target="#entregasGridRel"
                                                    itemRender={this.ItemTemplate}
                                                    onItemClick={this.itemClick} />          

                                                <DataGrid
                                                    id="entregasGridRel"
                                                    
                                                    repaintChangesOnly={true}
                                                    dataSource={this.state.entregas}
                                                    // defaultColumns={columns_entrega}
                                                    showBorders={true}
                                                    // onRowPrepared={this.onRowPrepared}
                                                    columnAutoWidth={true}
                                                    rowAlternationEnabled={true}
                                                    onContextMenuPreparing={onContextMenuPreparing}
                                                    height={430}
                                                    allowColumnResizing={true}
                                                    ref={this.dataGridRefEntrega}
                                                    allowColumnReordering={true}
                                                    > 
                                                    <Selection mode="single" />
                                                    <Scrolling mode="virtual" />
                                                    <FilterRow visible={true} />
                                                    <ColumnChooser enabled={true} mode="select" />
                                                    
                                                    {/* <HeaderFilter visible={true} /> */}
                                                    {/* <FilterRow visible={this.state.visible_filterrow} /> */}

                                                    <Column dataField="index" visible={true}/>
                                                    <Column dataField="entrega_id" caption= "Id" visible={true} />
                                                    <Column dataField="cliente" caption= "Cliente Entrega" width={100} />
                                                    {/* <Column caption= "Frete" dataField="valor" /> */}
                                                    {/* <Column caption= "Frete2" dataField="valor2" /> */}
                                                    {/* <Column caption= "Frete3" dataField="valor3" /> */}

                                                    <Column dataField="filial_entrega" caption= "Filial Entrega" width={100} />
                                                    <Column dataField="municipio" caption= "Município" width={100} />
                                                    <Column dataField="rota" width={100} />

                                                    <Column dataField="data_previsao" caption={"Previsão Entrega"} format="EE  dd/MM/yyyy HH:mm" dataType="datetime" 
                                                            visible={this.state.visible_datetime} />
                                                    <Column dataField="data_previsao2" caption={"Previsão Entrega"} dataType="text"
                                                            visible={this.state.visible_datetime_text} />

                                                    {/* <Column caption= "Data Entrega" dataField="data_entrega"  /> */}
                                                    <Column dataField="data_entrega" caption={"Data Entrega"} format="EE  dd/MM/yyyy HH:mm" dataType="datetime"
                                                            visible={this.state.visible_datetime} />
                                                    <Column dataField="data_entrega2" caption={"Data Entrega"} dataType="text" 
                                                            visible={this.state.visible_datetime_text}/>

                                                    <Column dataField="usuario" caption= "Usuário"/>
                                                    <Column dataField="canhoto" caption= "Comprovante" alignment='center'/> 
                                                    <Column dataField="tipo_pagamento" caption= "Pagamento"/>
                                                    <Column dataField="valor" caption= "Frete"  />
                                                    <Column dataField="tda_entrega" caption= "TDA" />
                                                    <Column dataField="total_frete_tda" caption= "Total Frete"/>
                                                    <Column dataField="pago_entrega_str" caption= "Pago Entrega"/>
                                                    <Column dataField="local" visible={false} width={250}/> 
                                                    <Column dataField="cte_entrega" caption= "CTE Entrega" visible={this.state.cte_visible} />
                                                    <Column dataField="observacao_entrega" caption= "Observação Entrega" width={100}  visible={this.state.observacao_visible} />
                                                    <Column dataField="nota_fiscal_entrega" caption= "NF Entrega"   />

                                                    <Column dataField="cliente_coleta" caption={"Cliente Coleta"} width={100} />
                                                    <Column dataField="filial_coleta" caption= "Filial Coleta" width={100} />
                                                    <Column dataField="data_previsao_coleta" caption={"Previsão Coleta"} format="EE  dd/MM/yyyy HH:mm" dataType="datetime" />
                                                    <Column dataField="data_coleta" caption={"Data Coleta"} format="EE  dd/MM/yyyy HH:mm" dataType="datetime" />
                                                    <Column dataField="valor_coleta" caption= "Frete Coleta" visible={false}/>
                                                    <Column dataField="pago_coleta_str" caption= "Pago Coleta"/>
                                                    <Column dataField="municipio_coleta" caption= "Município Coleta" width={100} />
                                                    <Column dataField="cte_coleta" caption= "CTE Coleta" visible={this.state.cte_visible} />
                                                    <Column dataField="observacao_coleta" caption= "Observação Coleta" width={100} visible={this.state.observacao_visible} />


                                                    <Summary>
                                                        <TotalItem
                                                            column="index"
                                                            summaryType="count"
                                                            customizeText = {this.customizeCount} 
                                                             />
                                                       
                                                        {/* <TotalItem
                                                        column="valor"
                                                        summaryType="sum"
                                                        // valueFormat= "R$ #,##0,00" 
                                                        valueFormat= "R$ #.##0,00" 
                                                        /> */}

                                                        <TotalItem
                                                            column="valor"
                                                            customizeText = {this.customizeSum}
                                                            />
                                                        <TotalItem
                                                            column="total_frete_tda"
                                                            customizeText = {this.customizeSomaTotalFrete}
                                                            />                                                             
                                                    </Summary>
                                                </DataGrid>
                                            </div>                                                
                                    :<div className={"espaco_bottom2"}></div>}                                                                 
                                </div>                                                
                        </Rodal>
                    </div>
                </Draggable>        
            </div>
            
        );
    }
}    

function cellRender(data) {
    if(data.value !== null)
      return <img src={data.value} width="20"  height="20"/>;
    else return <img src={require("../img/transparent.png")} width="20"  height="20"/>;
  }