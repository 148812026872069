import React, {Component} from 'react';
import {ListGroup } from 'react-bootstrap';
import './estilo.css';
import {CabecalhoModal} from './CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import api from './api';

export class PoligonoOpcoesModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            animation: "zoom",
            scrollOffset: 0,

            temas: [
                {id:1, nome:"Editar", selecionado:false, img: require('./img/edit_48dp.png'), index_scroll:0}
                ,{id:2, nome:"Excluir", selecionado:false, img: require('./img/delete_48dp.png'), index_scroll:0}
                ,{id:3, nome:"Notificação", selecionado:false, img: require('./img/plus.png'), index_scroll:0}
            ],
        };
      }
    //-------------------
    minimizarModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "slideLeft"});
        this.props.Modals.closePoligonoOpcoesModal();
    };
    //---------close----------
    fecharModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "zoom"});
        this.props.Modals.closePoligonoOpcoesModal();
    };
    //------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.poligonoOpcoesModalDesativar();
    };
    //------------------------------------------
    setStateTema = (id) =>{
        // Step 1: Find the element
        const elementsIndex = this.state.temas.findIndex(element => element.id == id );
        // Step 2: Create a copy of the state array
        let newArray = [...this.state.temas];
        // Step 3: Update the one value
        newArray[elementsIndex] = {...newArray[elementsIndex], selecionado: !newArray[elementsIndex].selecionado};
        // Step 4: SetState
        this.setState({temas: newArray,});
        // this.setState({temas: newArray, scrollOffset: newArray[elementsIndex].index_scroll, });
    };
    //------------------------------------------
    disableEnableTema = (id_disable, id_enable) =>{
        // Step 1: Find the element
        const index_disable = this.state.temas.findIndex(element => element.id == id_disable );
        const index_enable = this.state.temas.findIndex(element => element.id == id_enable );
        // Step 2: Create a copy of the state array
        let newArray = [...this.state.temas];
        // Step 3: Update the one value
        newArray[index_disable] = {...newArray[index_disable], selecionado: !newArray[index_disable].selecionado};
        newArray[index_enable] = {...newArray[index_enable], selecionado: !newArray[index_enable].selecionado};
        // Step 4: SetState
        this.setState({temas: newArray,});
    };
    //--------------------
    postDeletePoligono = async () => {
        try {
            let body = new FormData()
            var poligono_id = this.props.MapaKseg.state.selectedPolygon.poligono_id;
            body.append('poligono_id', poligono_id);
            body.append('token', localStorage.getItem('token'));
 

            const response = await api.post('/poligono_api/deletePoligono', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);

                var poligonos_list = this.props.MapaKseg.state.poligonos_list;

                poligonos_list = poligonos_list.filter((item,index) =>{  
                    return item.poligono_id !== poligono_id;
                })

                this.props.MapaKseg.setState({poligonos_list: poligonos_list});

                this.props.MapaKseg.callToast(String(resposta));
            }
                

        } finally {
            this.setState({loading: false});     
            this.setState({salvando:false});
            }
    };    
    //------------------------------------------------
    mensagemConfirmacao = () => {
        var poligono = this.props.MapaKseg.state.selectedPolygon
        var mensagem_excluir_area = 2;
        this.props.Modals.showMensagemModal(mensagem_excluir_area, this, poligono.descricao, true);
    }
    //------------------------------------------
    onItemClick = (item_id) =>{
        // this.props.MapaKseg.callToast("onItemClickk "+String(item_id));
        var poligono = this.props.MapaKseg.state.selectedPolygon;
        if(item_id=== 1){ // editar
            var poligono_novo_latlngs = this.props.MapaKseg.state.poligono_novo_latlngs;            
            // poligono_novo_latlngs.push(poligono);
            poligono_novo_latlngs = poligono;

            var poligono_novo_list=[];
            for (var i = 0; i < poligono_novo_latlngs.lat_lng_list.length; i++) { 
                var poligono = {
                    id:i+1
                    ,poligono_id : poligono_novo_latlngs.poligono_id
                    ,descricao : poligono.descricao
                    ,tipo_poligono_id: poligono_novo_latlngs.tipo_poligono_id
                    ,flag: poligono_novo_latlngs.flag
                    ,cor: poligono_novo_latlngs.cor
                    ,poligono_coordenada_id: poligono_novo_latlngs.poligono_coordenada_id_list[i]
                    ,latitude: poligono_novo_latlngs.lat_lng_list[i].lat
                    ,longitude: poligono_novo_latlngs.lat_lng_list[i].lng
                    ,lat_lng: String(poligono_novo_latlngs.lat_lng_list[i].lat+", "+poligono_novo_latlngs.lat_lng_list[i].lng)
                    ,selecionado: true,};
                    
                poligono_novo_list.push(poligono);
            }

            this.props.MapaKseg.setState({poligono_novo_list: poligono_novo_list, poligono_novo_latlngs: poligono_novo_latlngs});
            this.props.MapaKseg.setState({carregarPolygonNovo: true});
            console.log("2 poligono editar:");
            console.log(poligono_novo_list);

            var poligonos_list = this.props.MapaKseg.state.poligonos_list.filter((item,index) =>{
                return item.poligono_id !== poligono.poligono_id;
            })
            this.props.MapaKseg.setState({poligonos_list: poligonos_list});

        }else if(item_id===3){  //Notificação
            this.props.Modals.showAddNotificacaoVeiModal();

        }else if(item_id===2){  //Excluir
            this.mensagemConfirmacao();
        }
        var editar_poligono=2;
        this.props.Modals.state.PoligonosModal.setState({modo_poligono: editar_poligono
                                                        // , activeIndexCarousel:1
                                                        , descricao: poligono.descricao});
        this.fecharModal();
    };  
    //-------------------------
    render(){
        //--------------------
        return(
            <div className="drag1" >
                <Draggable   handle="strong"  >                
                    <div >                    
                        <Rodal 
                            visible={this.props.show}
                            animation={this.state.animation}
                            showCloseButton={false}
                            duration={600}
                            width={400}
                            height={250}
                            onAnimationEnd={()=>this.desativarModal()}
                            >  
                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false} titulo={this.props.polygon.dados[0].descricao} Modal={this}/></strong>
                                <div className="padding_modal">
                                    <div style={{height: '500px', overflow: 'hidden'}}>                                    
                                        <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                                            <ListGroup>{
                                                this.state.temas.map((tema) =>                                                    
                                                        <ListGroup.Item action variant="" onClick={()=>this.onItemClick(tema.id)}>
                                                            <div>
                                                                <img className="margin_cantos5" src={tema.img} width="30"/>
                                                                {tema.nome+'  '}                                           
                                                            </div>
                                                        </ListGroup.Item>
                                                    )}
                                            </ListGroup>   
                                        </CustomScroll>                                        
                                    </div>                             
                                </div>                                
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>
        
        );
    }
}