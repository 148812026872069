import  {Component} from 'react';
import api from '../api';

export default class PostPessoaCad extends Component{
    constructor(props, objeto, MapaKseg ){
        super(props);
        this.state = {
             objeto: objeto,
             MapaKseg: MapaKseg
       }
    }
    //------------------------------------------------
    post = async () => {
        let pessoa_idd= 0;
        try {        
            let editados = this.state.objeto.state.editados;
            let pessoa = this.state.objeto.state.pessoa;
            pessoa_idd = pessoa.pessoa_id;

            let response = null;
            let responseJson = null;
            let body =null;            

            if(editados.nome_social || editados.nome_fantasia || editados.pessoa_juridica || editados.cnpj || editados.cpf){

                body = new FormData();
                body.append('token', localStorage.getItem('token'));
                body.append('pessoa_id', pessoa.pessoa_id);

                if(editados.nome_social) 
                    body.append('nome_social', pessoa.nome_social);
                if(editados.nome_fantasia) 
                    body.append('nome_fantasia', pessoa.nome_fantasia);
                if(editados.pessoa_juridica) 
                    body.append('pessoa_juridica', pessoa.pessoa_juridica);
                if(editados.cnpj) 
                    body.append('cnpj', pessoa.cnpj);
                if(editados.cpf) 
                    body.append('cpf', pessoa.cpf);

                response = await api.post('/cadastro_api/postPessoa', body);

                if (response.data !== null  &&  response.status !== 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json); 
                    pessoa_idd = resposta.pessoa_id; 
                }

            }
             

        } finally {      
            let pessoa = this.state.objeto.state.pessoa;
            pessoa.pessoa_id = pessoa_idd;
            this.state.objeto.setState({pessoa: pessoa});
            this.state.objeto.concluido();
            }
    };
}