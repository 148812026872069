export default [
  {
    id: 10248,
    region: 'North America',
    country: 'United States of America',
    city: 'New York',
    amount: 1740,
    date: new Date('2013-01-06'),
  },
  {
    id: 10249,
    region: 'North America',
    country: 'United States of America',
    city: 'Los Angeles',
    amount: 850,
    date: new Date('2013-01-13'),
  },
  {
    id: 10422,
    region: 'Australia',
    country: 'Australia',
    city: 'Sydney',
    amount: 2220,
    date: new Date('2013-08-16'),
  },
  {
    id: 10423,
    region: 'Australia',
    country: 'Australia',
    city: 'Melbourne',
    amount: 1575,
    date: new Date('2013-08-23'),
  },
  {
    id: 10424,
    region: 'Africa',
    country: 'South Africa',
    city: 'Pretoria',
    amount: 1880,
    date: new Date('2013-08-12'),
  },
];