import React, {Component} from 'react';
import '../estilo.css';
import 'rodal/lib/rodal.css';
import GetGruposTipo from '../services/get_grupos_tipo';
import DataGrid, {  Scrolling, Selection } from 'devextreme-react/data-grid';
import ContextMenu from 'devextreme-react/context-menu';
import GetGrupoVeiculos from '../services/get_grupo_veiculos';

const INSERT =1;
const DELETE =2;
const UPDATE =3;

export class GrupoVeiculosGrid extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            GeralListModal:null,
            height_grid:0,
            items:[],
            load:true,
        };
         // -------------------------------
        this.dataGridRef = React.createRef();
    
        this.selectedRowsData = [];

        this.getSelectedData = () => {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData();
            this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            });
        }
      }
    //---------------------------------------------------------------
    get dataGrid() {
        return this.dataGridRef.current.instance;
    }
    //-------------------------------------------------------------------
    async componentDidMount() {
        this.carregarItems()
    }; 
    //-----------------------------------------------------------------------
    carregarItems() {  
        let items = this.state.items;
        items.push({ text: ' Add', icon: "dx-icon-add"  });
        items.push({ text: ' Remover', icon: "dx-icon-remove"  });

        this.setState({ items: items  }, 
            () => { this.setState({load:false})});
    };
    //-------------------------------------------------------------------
    ItemTemplate (e) {
        return (
        <React.Fragment>
            <span className={ e.icon } />
            { e.items ? <span className="dx-icon-spinright" /> : null }
            { e.text }
        </React.Fragment>
        );
    }
    //---------------------------------------------------------------
    itemClick = (e) => {
        if (!e.itemData.items) {
            let selectedRowData = this.dataGrid.getSelectedRowsData(); 
            // alert(e.itemData.text+"  "+selectedRowData[0]);
            console.log("selectedRowData[0]");
            console.log(selectedRowData[0]);            

            if(e.itemData.text === this.state.items[0].text){
                this.props.Modals.showGeralList(this,"Veículos", 0);    
            }else 
                if(e.itemData.text === this.state.items[1].text){
                    this.remover(selectedRowData[0]);            
                }
        }
    }
    //-------------------------------------------------------------------
    procurarOutroObj (list) {     
        let obj = null;
        for (var i = 0; i < list.length; i++) { 
            if(list[i].operacao != DELETE){
                obj = list[i];
                break;
            }            
        }
        return obj;
    }
    //-------------------------------------------------------------------
    remover(obj) {
        let veiculos = this.props.objeto.state.grupo.veiculos;
   
        if(obj.grupo_veiculo_id === 0 ){
            veiculos = veiculos.filter((g,index) =>{ return g.veiculo_id !== obj.veiculo_id; });
        }else{
            for (var j = 0; j < veiculos.length; j++) { 
                if(veiculos[j].veiculo_id === obj.veiculo_id){                                                             
                    veiculos[j].operacao = DELETE;
                }
            }            
        }
        
        let editados = this.props.objeto.state.editados;
        editados.veiculos =  true;

        let grupo = this.props.objeto.state.grupo;
        grupo.veiculos = veiculos;
        grupo.veiculos = grupo.veiculos.concat(); // para não precisar dar reload no grid

        this.props.objeto.setState({ 
            grupo: grupo
            , changed: true
            , editados: editados});

        // this.setState({load: true}, () => { this.setState({load:false})});
    }
    //------------------------------------------------------------------------    
    onRowPrepared(e) {      
        if(e.data !== undefined){
            if (e.data.operacao === 0 ){
                if(e.data.index %2 === 0)
                    e.rowElement.style.backgroundColor = "#F5F5F5";
                else e.rowElement.style.backgroundColor = "#FFFFFF";                

            }else if (e.data.operacao === 1 || e.data.operacao === 3)
                    e.rowElement.style.backgroundColor = "#92d1b0";
            else if(e.data.operacao===2)
                e.rowElement.style.backgroundColor = "#f593a0";     
        }      
    } 
    //------------------------------------------------------------------
    getList= (geralListModal, tipo) => { 
        this.setState({GeralListModal: geralListModal});  
        var instance = new GetGrupoVeiculos(null, this, this.props.MapaKseg, 0);
        instance.get(); 
    };
    //-------------------------------------------------------------
    setListVeiculos =  (_list) => {  
        let array =[]
        let adicionar=true;
        let veiculos = this.props.objeto.state.grupo.veiculos

        for (var i = 0; i < _list.length; i++) { 
            for (var j = 0; j < veiculos.length; j++) { 
                if(veiculos[j].veiculo_id == _list[i].veiculo_id){
                    adicionar=false;
                    break;
                }else adicionar=true;
            }

            if(adicionar==true){
                array.push({
                    id: _list[i].veiculo_id
                    , descricao: _list[i].placa
                    , selecionada: false
                    , index_scroll: 0});
            }
        }
        this.state.GeralListModal.setList(array, 0);
    }; 
    //-------------------------------------------------------------------------
    setObjeto= (objeto) => { 
        let veiculo ={
            grupo_veiculo_id: 0
            , veiculo_id: objeto.id
            , placa: objeto.descricao
            , operacao: INSERT};

        let veiculos = this.props.objeto.state.grupo.veiculos
        // grupos = grupos.concat(grupo);
        let lista = [];
        lista.push(veiculo);
        lista = lista.concat(veiculos);

        let grupo = this.props.objeto.state.grupo;
        grupo.veiculos = lista;

        this.props.objeto.setState({grupo: grupo});

        let editados = this.props.objeto.state.editados;
        editados.veiculos =  true;

        this.props.objeto.setState({
                editados: editados 
                ,changed:true});
        
    };
    //--------------------------------------------------------------------------
    onRowInserted(e) {
        alert("onRowInserted");
        e.component.navigateToRow(e.key);
    }
    //------------------------------------------------------------------------------
    render(){
        const columns = [ 'veiculo_id','placa'];
        
        const onContextMenuPreparing = (e) =>{  // precisa desse para selecionar a linha com o botao direito
            try{
              e.component.selectRows(e.row.key, false)
              var selectedRowData = this.dataGrid.getSelectedRowsData();
              this.props.MapaKseg.ajustarZoom(selectedRowData[0]);
      
            //   console.log(e.row.key);
              // alert(e.row.key);
            }catch (response){}
          }

        return(
            this.state.load? ""
            :<div >                
                 <ContextMenu
                    dataSource={this.state.items}
                    width={180}
                    target="#veiculogrid"
                    itemRender={this.ItemTemplate}
                    onItemClick={this.itemClick} />
                
                    <DataGrid
                        id="veiculogrid"
                        key="index"
                        ref={this.dataGridRef}
                        dataSource={this.props.objeto.state.grupo.veiculos}
                        showBorders={true}
                        // rowAlternationEnabled={true}
                        defaultColumns={columns}
                        onRowPrepared={this.onRowPrepared}
                        columnAutoWidth={false}
                        onContextMenuPreparing={onContextMenuPreparing}
                        height={this.props.height_grid}
                        > 
                        <Selection mode="single" />        
                        <Scrolling mode="virtual" />
                    </DataGrid>                       
            </div>        
        );
    }
}