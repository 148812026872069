import  {Component} from 'react';
import api from '../api';

export default class GetTokensUsuario extends Component{
    constructor(props, objeto, MapaKseg){
        super(props);
        this.state = {
             list: [],
             objeto: objeto,
             MapaKseg: MapaKseg
       }
    }
    //------------------------------------------------
    get = async () => {
        try {
            let body = new FormData()
            body.append('usuario_id', this.state.objeto.state.usuario.usuario_id);
            body.append('token', localStorage.getItem('token'));

            const response = await api.post('/cadastro_api/getUsuarioTokens', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let obj = null;
                let list = this.state.list;                
                
                for (var i = 0; i < resposta.length; i++) {                        
                    obj = {    
                        index: i+1,
                        token_firebase_id: resposta[i].token_firebase_id,                        
                        device_name: resposta[i].device_name,
                        data_criacao: resposta[i].data_criacao,
                        ativo: resposta[i].ativo,                        
                        version_kseg_app: resposta[i].version_kseg_app,
                        falhas: resposta[i].falhas,
                        token_firebase: resposta[i].token_firebase.substring(0,15),
                        operacao: 0
                    };                   

                    list.push(obj);
                    this.setState({list: list});
                }                
            }
        } finally {      
            let usu = this.state.objeto.state.usuario;
            usu.tokens = this.state.list;
            this.state.objeto.setState({usuario: usu});                  
            }
    };
}