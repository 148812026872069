import React from 'react';

import 'devextreme/data/odata/store';
import DataGrid, { Column, Scrolling, Selection, MasterDetail } from 'devextreme-react/data-grid';
import './estilo.css';
import ContextMenu from 'devextreme-react/context-menu';
// import notify from 'devextreme/ui/notify';
import api from './api';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';


export class GridVeiNotif extends React.Component {
  constructor(props) {
    super(props);
    // this.onRowPrepared = this.logEvent.bind(this, 'RowInserting');
    this.onRowPrepared = this.onRowPrepared.bind(this); 

    this.state = {
      countNotificacao: 0,
      props: props,
      GridVeiculoDetail: null,
      items:[],
      load:true,
      grid_list:[],
      veiculo_list:[],
      usuario_list:[],
    }

  // -------------------------------
    this.dataGridRef = React.createRef();
 
    this.selectedRowsData = [];

    this.getSelectedData = () => {
        this.selectedRowsData = this.dataGrid.getSelectedRowsData();

        // ===== or when deferred selection is used =====
        this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            // Your code goes here
        });
    }
    //-----
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onRowExpanding = this.onRowExpanding.bind(this);

    // this.dataSource = this.getTasks(props.data.key);

  }
//------------------------------------------
  get dataGrid() {
    return this.dataGridRef.current.instance;
  }
//------------------------------------------
  onRowPrepared(e) {  
     
  } 
  //---------------------------------------------------------------
  addMenuItems(e) {
    if (e.target == 'header') {
        // e.items can be undefined
        if (!e.items) e.items = [];

        // Add a custom menu item
        e.items.push({
            text: 'Log Column Caption',
            onItemClick: () => {
                // alert(e.column.caption);
                // console.log(e.column.caption);
            }
        });
    }
  }
  //-------------------------------------------------------------------
  ItemTemplate (e) {
    return (
      <React.Fragment>
        <span className={ e.icon } />
        { e.items ? <span className="dx-icon-spinright" /> : null }
        { e.text }
      </React.Fragment>
    );
  }
  //---------------------------------------------------------------
  itemClick = (e) => {
    if (!e.itemData.items) {
      // console.log(e);
      // alert(e);
      // notify(`The "${ e.itemData.text }" item was clicked`+" shitt", 'success', 1500);     
      // alert(e.itemData.text);   
      var selectedRowData = this.dataGrid.getSelectedRowsData(); 
      // console.log(selectedRowData);
      // alert(selectedRowData);   
    //   this.props.MapaKseg.setState({veiculoPosicao_selecionado: selectedRowData[0]});

      if(e.itemData.text === this.state.items[0].text){
        this.props.MapaKseg.state.Modals.showCadCliente(selectedRowData[0]);
        // var instance = new GetComandosVeiculo(selectedRowData[0], this.props.MapaKseg); 
        // instance.getComandosVei();   

      }else if(e.itemData.text === this.state.items[1].text){
        this.props.MapaKseg.state.Modals.showCadCliente(selectedRowData[0]);
      }

    }
  }
  //--------------------------------------------------------------
  onSelectionChanged({ selectedRowsData }) {

  }
  //-------------------------------------------------------------
  onRowExpanding= (e) => {
    e.component.selectRows(e.key, false);
    // this.props.MapaKseg.ajustarZoom(selectedRowsData[0]);
  }
  //-----------------------------------------
  componentDidMount() {
    // var instance = new GridVeiculoDetail(this.props, this.props.MapaKseg);
    // this.setState({GridVeiculoDetail: instance});

    // this.props.MapaKseg.toast(" Iniciado GridClientes");

    var items = this.state.items;

    if(this.props.MapaKseg.state.pessoa_id===1)
      items.push({ text: ' Add Veículo', icon: "dx-icon-add"  });

    // var instance = new GetPoligonos(this.props.MapaKseg, 5, "", 5);
    // instance.getPoligonos();

    this.setState({items: items});
    // this.setState({items: items, load:false});
    this.getVeiculosPoligonoNotificacao();
  };
  //---------------------------------------------------------------
  getVeiculosPoligonoNotificacao = async () => {
    try {
        var poligono = this.props.MapaKseg.state.selectedPolygon;

        var veiculos_list = this.props.MapaKseg.state.list;
        var veiculo_id_list=[];
        for(var i=0; i<veiculos_list.length; i++ ){                
            // var json = JSON.stringify({veiculo_id: veiculos_list[i].veiculo_id});
            veiculo_id_list.push(veiculos_list[i].veiculo_id);                            
        }

        let body = new FormData()
        body.append('veiculo_id_list', veiculo_id_list);
        body.append('poligono_id', poligono.poligono_id);
        body.append('usuario_id', this.props.MapaKseg.state.usuario_id);
        body.append('pessoa_id', this.props.MapaKseg.state.pessoa_id);
        body.append('token', localStorage.getItem('token'));


        // console.log("  ***datetime: "+datetime)

        const response = await api.post('/poligono_api/getVeiculosPoligonoNotificacao2', body);
        
        var responseJson = null

        if (response.data !== null  &&  response.status !== 403){
            responseJson = JSON.stringify(response.data)
            var req = { json: responseJson };
            var resposta = JSON.parse(req.json);

            
            let veiculo = {};
            let grid_list = this.state.grid_list;  
            var list_veiculos = this.props.MapaKseg.state.list;

            
            for (var i = 0; i < resposta.length; i++) {    
                // ---- antes ver se o veiculo já esta na lista, pra nao repetir por causa das flags entrada e saída.       
                //------------------------
          

                    veiculo = {  
                        poligono_acao_id_entrada : 0,
                        poligono_acao_id_saida : 0,
                        veiculo_id : resposta[i].veiculo_id,
                        placa : resposta[i].placa,
                        tipo_acao_id : resposta[i].tipo_acao_id,
                        entrada: false,
                        saida: false,
                        status:2,
                        editado:false,
                        usuario_id : resposta[i].usuario_id,
                        login : resposta[i].login,
                    };

                    if(veiculo.tipo_acao_id === 1){
                        veiculo.entrada = true;
                        veiculo.poligono_acao_id_entrada = resposta[i].poligono_acao_id;
                    }else if(veiculo.tipo_acao_id === 2){
                        veiculo.saida = true;
                        veiculo.poligono_acao_id_saida = resposta[i].poligono_acao_id;
                    }

                    grid_list.push(veiculo);   
             
                
            //------filtrar os veiculos do primeiro grid-------------------
                list_veiculos = list_veiculos.filter((item,index) =>{  
                    return item.veiculo_id !== veiculo.veiculo_id;
                })
            }
            // this.setState({grid_list: grid_list});     
            
            //--------------Adicionar no grid final-------------
            // for (var i = 0; i < list_veiculos.length; i++) {                        
            //     veiculo = {  
            //         poligono_acao_id_entrada : 0,
            //         poligono_acao_id_saida : 0,
            //         veiculo_id : list_veiculos[i].veiculo_id,
            //         placa : list_veiculos[i].placa,
            //         tipo_acao_id : 66,
            //         entrada: false,
            //         saida: false,
            //         status:1,
            //         editado:false
            //     };

            //     grid_list.push(veiculo);                                       
            // }       
            this.setState({grid_list: grid_list});  
            
        }else{
        }

    } finally {
        this.setState({load: false});     
        this.props.MapaKseg.toast(this.state.grid_list.length);

        }
  };
  //-------------------------------------------------------------------
  getTasks=(key)=> {
    var dataSource = new DataSource({
      store: new ArrayStore({
        data: this.state.grid_list,
        key: 'veiculo_id'
      }),
      filter: ['veiculo_id', '=', key]
    });

    return dataSource;
  }
  //-------------------------------------------------------------------
  renderDetail=(props)=> {
    // let dataSource = this.getTasks(props.data.key);
    console.log(props.data);
    console.log(props.data.veiculo_id);
    let dataSource = this.getTasks(props.data.veiculo_id);
    console.log(dataSource);

    // if (props.data.eventos_count===0){
    //   return(<div></div>);
    // }

    return (
      <div >
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          columnAutoWidth={true}
          showRowLines={false}
          rowAlternationEnabled={true}
        >
          <Column dataField="login"
              caption="Usuário"
              />

          <Column
          dataField="entrada"
          dataType="boolean"
        />

        <Column
          caption="Saída"
          dataField="saida"
          dataType="boolean"    
        />    
        </DataGrid>
      </div>
    );
  }
  //---------------------------------------------------------------
  render() {
    const onContextMenuPreparing = (e) =>{
        try{
          e.component.selectRows(e.row.key, false)
        //   var selectedRowData = this.dataGrid.getSelectedRowsData();
        //   this.props.MapaKseg.ajustarZoom(selectedRowData[0]);

          // console.log(e.row.key);
          // alert(e.row.key);
        }catch (response){}

      // if (e.target !== "content") 
      //     return;
      // // e.items = [];
      // if(e.row){
      //   e.component.selectRows(e.row.key, false)
      //   var selectedRowData = this.dataGrid.getSelectedRowsData();
      //   this.props.MapaKseg.ajustarZoom(selectedRowData[0]);

      //   console.log(e.row.key);
      //   alert(e.row.key);
      // }
    }
    
    return (      
        this.state.load ? "":
          <div>          

            <ContextMenu
                dataSource={this.state.items}
                width={180}
                target="#dtGridVeiNotif"
                itemRender={this.ItemTemplate}
                onItemClick={this.itemClick} />
            
            <DataGrid
              id="dtGridVeiNotif"
              dataSource={this.state.grid_list}
              showBorders={true}
              onRowPrepared={this.onRowPrepared}
              onContextMenuPreparing={onContextMenuPreparing}
              ref={this.dataGridRef}
              onSelectionChanged={this.onSelectionChanged}  
              keyExpr="veiculo_id"  
            //   onRowExpanding={this.onRowExpanding}    
              >

                <MasterDetail showBorders={false} enabled={true} render={this.renderDetail} />

                <Selection mode="single" />

                <Column
                  dataField="placa"
                  dataType="string"
                  allowEditing={false}
                />
                <Column
                  dataField="status"
                  dataType="number"
                  visible={false}
                />
                
              
            </DataGrid>
          </div>     
    );
  }
}
