import React, {Component} from 'react';
import {Modal, Image, Spinner, Button, Form, Row,Col,Card,Nav,InputGroup,FormControl, Tab, Tabs} from 'react-bootstrap';
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import api from '../api';
import GetClientePessoa from '../services/get_cliente_pessoa';
import GetRotaPessoa from '../services/get_rota_pessoa';
import { DateBox } from 'devextreme-react';
import GetColeta from '../services/get_coleta';
import PostEntrega from '../services/post_entrega';
import { dinheiroMask } from '../dinheiro_mask'

const ENTREGA_CLIENTE_TIPO=4;
const ENTREGA_CLIENTE_LOCAL_TIPO=5;
const ENTREGA_ROTA_TIPO=6;


export class ColetaEntregaModal extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            loading:true,
            minimizar:false,
            changed:false,
            GeralListModal:null,
            // tipo_pagamentos: [{id: 2, descricao:"FOB"},{id: 4, descricao:"AR FOB"}],

            tipo_pagamentos: [{tipo_pagamento_id: 1, descricao:"CIF"},{tipo_pagamento_id: 2, descricao:"FOB"}
            , {tipo_pagamento_id: 3, descricao:"AR CIF"}, {tipo_pagamento_id: 4, descricao:"AR FOB"}
            , {tipo_pagamento_id: 5, descricao:"PIX CIF"}, {tipo_pagamento_id: 6, descricao:"PIX FOB"} ],

            tipo_pagamentos2: [{tipo_pagamento_id: 2, descricao:"FOB"}, {tipo_pagamento_id: 4, descricao:"AR FOB"}
                                , {tipo_pagamento_id: 6, descricao:"PIX FOB"} ],


            pagamento: {id: 0, descricao:""},
            data_previsao_coleta: null,
            valor: null,
            disable_pagamento:false,
            disable_valor:false,

            entrega: {entrega_id:0, cliente_id:0, cliente:"", local_id:0, local:"", rota_id:0, rota:""
            , tipo_pagamento_id:0, pagamento:"", valor:"0", data_previsao:"", coleta_id: 0, observacao:"", cte:"", pago:""},

            coleta: {coleta_id:0, cliente_id:0, cliente:"", local_id:0, local:"", rota_id:0, rota:""
            , tipo_pagamento_id:0, tipo_pagamento:"", valor:0, data_previsao:"", entregas: [], observacao:"", cte:"", pago:""},

            coleta2:{},

            activeKey:"Coleta",
            nova_entrega:{},
            salvando:false,
            height_modal:555,
            items_entrega:[],
            entregas:[],
            editados:[]
        };
        this.showGeralList = this.showGeralList.bind(this);
        // this.setInputFocus = this.setInputFocus.bind(this);
        this.myInputRef = React.createRef();

        // -------------------------------
    }

    //--------------------------
    async componentDidMount() {
        try{            
            this.setState({GridColetaEntrega: this.props.objeto});
            this.setState({minDateValue:  new Date((new Date()).getTime() - 1000 * 60 * 24 * 3)});

            let editados = {
                cliente:false
                , local:false
                , rota:false
                , pagamento:false
                , valor: false
                , previsao:false
                , observacao:false
                , cte:false
                , pago:false};

            let entrega = this.state.entrega;
            entrega.tipo_pagamento_id = this.props.obj_selected.tipo_pagamento_id;
            entrega.tipo_pagamento = this.props.obj_selected.tipo_pagamento;                
            entrega.valor=0;

            if(this.props.obj_selected.tipo_pagamento_id===1 
                || this.props.obj_selected.tipo_pagamento_id===3
                || this.props.obj_selected.tipo_pagamento_id===5){                
                    this.setState({ disable_pagamento: true, disable_valor: true}); 
            }else{
                this.setState({tipo_pagamentos: this.state.tipo_pagamentos2});
            }    

            if(this.props.obj_selected !== null){
                this.setState({entrega: this.props.obj_selected
                                , editados: editados},
                () => {            
                    this.getColeta(); }
                )      
            }else this.setState({loading: false});    
        
        }catch (response){
            this.props.MapaKseg.toastError("");
            this.fecharModal();}
         
    };
    //--------------------------------------------------------------------
    getColeta = () => {
        var instance = new GetColeta(null, this, this.props.MapaKseg, this.state.entrega.coleta_id);
        instance.get(); 
    } 
    //-------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closeEntregaModal();  
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeEntregaModal();
        this.state.GridColetaEntrega.visibleFilterrow();        
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarEntregaModal();
    };   
    //-----------------------------------------------
    setPagamento(event) {
        var list_aux = this.state.tipo_pagamentos.filter((item,index) =>{  return String(item.descricao) === String(event.target.value);});
        let entrega = this.state.entrega;
        entrega.tipo_pagamento_id = list_aux[0].tipo_pagamento_id;
        entrega.tipo_pagamento = list_aux[0].descricao;

        let editados = this.state.editados;
        editados.pagamento = true;
        this.setState({entrega: entrega, editados: editados, changed: true});

        if(event.target.value==3 || event.target.value==5)
            this.setState({disable_valor: true, valor:"0"});  
        else this.setState({disable_valor: false});
    }
    //--------------------------------------------
    setDataPrevisaoEntrega = (e) => {
        let entrega = this.state.entrega;
        entrega.data_previsao = this.formatarDateTime(e.value);
        let editados = this.state.editados;
        editados.previsao = true;
        this.setState({entrega: entrega, editados: editados, changed: true});
    }      
    //----------
    formatarDateTime(dtTime){    
        const y = String(dtTime).substring(11,15);
        const mm = this.formatarMes(String(dtTime).substring(4,7));
        const dd = String(dtTime).substring(8,10);
        const hm = String(dtTime).substring(16,21);

        // console.log("  *** dtTime: "+String(dtTime));
        // console.log("  *** y: "+y);
        // console.log("  *** mm: "+mm);
        // console.log("  *** mm: "+String(this.formatarMes(mm)));
        // console.log("  *** dd: "+dd);
        // console.log("  *** hm: "+hm);  
        const datetime = y+"-"+mm+"-"+dd+" "+hm;  // "Y-MM-DD H:M"
        return datetime;                  
      }
      //-------------------------
      formatarMes(mes){    
        if(mes==="Jan")
            return 1;
        else if (mes==="Feb")
            return 2;
        else if (mes==="Mar")
            return 3;     
        else if (mes==="Apr")
            return 4;     
        else if (mes==="May")
            return 5;     
        else if (mes==="Jun")
            return 6; 
        else if (mes==="Jul")
            return 7; 
        else if (mes==="Aug")
            return 8; 
        else if (mes==="Sep")
            return 9; 
        else if (mes==="Oct")
            return 10;    
        else if (mes==="Nov")
            return 11;
        else if (mes==="Dec")
            return 12;             
      }  
    //-------------------------
    setValorPagamento(event) {             
        let entrega = this.state.entrega;
        entrega.valor =  dinheiroMask(event.target.value);         

        let editados = this.state.editados;
        editados.valor = true;
        this.setState({entrega: entrega, editados: editados, changed: true});
    }
    //--------------------
    showGeralList= (e) => {   
        if(e.target.getAttribute('value') == ENTREGA_CLIENTE_TIPO){
            // this.props.Modals.showGeralList(this,"Clientes Entrega", ENTREGA_CLIENTE_TIPO, true);
            this.props.Modals.showPesquisarModal(this,"Clientes Entrega", true, 'Clientes Entrega');
        }else if(e.target.getAttribute('value') == ENTREGA_CLIENTE_LOCAL_TIPO)
            this.props.Modals.showGeralList(this,"Local da Entrega", ENTREGA_CLIENTE_LOCAL_TIPO);
        else if(e.target.getAttribute('value') == ENTREGA_ROTA_TIPO)
            this.props.Modals.showGeralList(this,"Rotas Entrega", ENTREGA_ROTA_TIPO);             
    }; 
    //-----------------------------------------------------
    getList= (geralListModal, tipo, filtro='') => { 
        this.setState({GeralListModal: geralListModal});  

        if(tipo == ENTREGA_CLIENTE_TIPO){ 
            var instance = new GetClientePessoa(null, this, this.props.MapaKseg, ENTREGA_CLIENTE_TIPO, filtro);
            instance.get(); 
        }else if(tipo == ENTREGA_CLIENTE_LOCAL_TIPO){ 
            var instance = new GetClientePessoa(null, this, this.props.MapaKseg, ENTREGA_CLIENTE_TIPO, filtro);
            instance.get(); 
        }else if(tipo == ENTREGA_ROTA_TIPO){ 
            var instance = new GetRotaPessoa(null, this, this.props.MapaKseg, ENTREGA_ROTA_TIPO, 0);
            instance.get(); 
        }
    }; 
    //--------------------------------------------------------------
    setListClientes= (_list, tipo) => {  
        let array =[];
        let descr = "";

        for (var i = 0; i < _list.length; i++) { 

            if(_list[i].cnpj_cpf == null) descr = _list[i].nome + " ("+_list[i].local+")"
            else descr = _list[i].nome +" ("+_list[i].cnpj_cpf +") ("+_list[i].local+")"

            array.push({
                index: _list[i].index
                  ,id: _list[i].cliente_id
                , descricao: descr
                , descricao2: _list[i].nome
                , local_id: _list[i].local_id
                , local: _list[i].local
                , municipio_id : _list[i].municipio_id
                , municipio : _list[i].municipio
                , selecionada: _list[i].selecionada
                , index_scroll: _list[i].index_scroll});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //--------------------
    setObjeto= (objeto, tipo) => { 
        let editados = this.state.editados;

        if(tipo == ENTREGA_CLIENTE_TIPO){
            let entrega = this.state.entrega;

            entrega.cliente_id = objeto.id;
            entrega.cliente = objeto.descricao2;
            entrega.local_id = objeto.local_id;
            entrega.local = objeto.local;
            
            this.setState({entrega: entrega});

            editados.cliente = true;
            editados.local = true;

            var instance = new GetRotaPessoa(null, this, this.props.MapaKseg, ENTREGA_ROTA_TIPO, objeto.municipio_id);
            instance.get(); 

        }
        else if(tipo == ENTREGA_CLIENTE_LOCAL_TIPO){
            let entrega = this.state.entrega;
            entrega.local_id = objeto.local_id;
            entrega.local = objeto.local;
            this.setState({entrega: entrega});
            editados.local = true;
        }
        else if(tipo == ENTREGA_ROTA_TIPO){
            let entrega = this.state.entrega;
            entrega.rota_id = objeto.id;
            entrega.rota = objeto.descricao2;
            this.setState({entrega: entrega});
            editados.rota = true;
        }

        this.setState({editados: editados, changed: true});
    }
    //---------------------------------------------------
    setRota= (objeto) => { 
        let editados = this.state.editados;
        let entrega = this.state.entrega;
        entrega.rota_id = objeto.rota_id;
        entrega.rota = objeto.descricao;
        this.setState({entrega: entrega});
        
        editados.rota = true;   
        this.setState({editados: editados, changed: true});
    }
     //--------------------
     setListRotas= (_list, tipo) => {  
        let array =[]
        for (var i = 0; i < _list.length; i++) { 
            array.push({
                index: _list[i].index
                , id: _list[i].rota_id
                , descricao: _list[i].descricao + " ("+_list[i].municipios+")"
                , descricao2: _list[i].descricao
                , selecionada: _list[i].selecionada
                , index_scroll: _list[i].index_scroll});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //---------------------------------------------
    validar = () => {              
        let valido=true;
   
        if(this.state.entrega.cliente_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Cliente da Entrega!");
            this.handleSelect("Entrega");
        }
        if(this.state.entrega.rota_id==0 && valido){
            valido=false;
            this.props.MapaKseg.toastError("Informe o Rota da Entrega!");
        }

        if(this.state.entrega.data_previsao==null && valido){
            valido=false;
            this.props.MapaKseg.toastError("Informe a Data de Previsão da Entrega!");
        }

        if(valido){
            this.setState({salvando:true});         
            // console.log("nova_entrega");
            // console.log(nova_entrega);

            var instance = new PostEntrega(null, this, this.props.MapaKseg);
            instance.post();

            // this.addEntrega(nova_entrega);
        }
    }; 
    //--------------------------------------
    concluido=(nova_entrega)=> {
        this.setState({salvando:false});
        this.setState({entrega: nova_entrega});
        // this.state.GridColetaEntrega.pushGridEntrega(nova_entrega);
        this.state.GridColetaEntrega.reloadGrid(nova_entrega);
        this.fecharModal();
    }
    //---------------------------------------------------------------
    setCliente = (cliente) => {
        cliente.id = cliente.cliente_id;
        cliente.descricao2 = cliente.nome;
        this.setObjeto(cliente, ENTREGA_CLIENTE_TIPO);
    }
    //---------------------------------------------------------------
    setColeta = (coleta) => {
        this.setState({coleta: coleta},
            () => { this.setState({loading: false}); }
            ) 
    }
    //---------------------------------------------------------------
    showObservacao= (e) => {   
        this.props.Modals.showObservacaoModal(this, "Observação da Entrega", this.state.entrega.observacao, true, 'observacao');             
    }; 
    //---------------------------------------------------------------
    showCTE= (e) => {   
        this.props.Modals.showObservacaoModal(this, "CTE da Entrega", this.state.entrega.cte, true, 'cte');             
    }; 
    //---------------------------------------------------------------
    confirmarObservacao(texto){
        let entrega = this.state.entrega;
        entrega.observacao = texto;

        let editados = this.state.editados;
        editados.observacao = true;

        this.setState({entrega: entrega, editados: editados, changed: true});        
    };
    //---------------------------------------------------------------
    confirmarCte(texto){
        let entrega = this.state.entrega;
        entrega.cte = texto;

        let editados = this.state.editados;
        editados.cte = true;

        this.setState({entrega: entrega, editados: editados, changed: true});        
    };
    //---------------------------------------------------------------
    handleSelect=(selectedKey)=> {
        this.setState({activeKey: selectedKey});
    }    
    //---------------------------------------------
    render(){
        return(
            <div className="drag1_1">
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            showCloseButton={false}
                            duration={600}
                            width={580}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.loading ?
                                        <Spinner className="centro3" animation="border" role="status"/>
                                    :
                                        <div style={{height: this.state.height_modal, overflow:'hidden'}}>
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                                                {/*------------------------------------ */}
                                                <Form>                    
                                                    {/* ----------------Tab---------------------------------------------------- */}
                                                    <Tabs
                                                        id="controlled-tab-example"
                                                        activeKey={this.state.activeKey}
                                                        onSelect={this.handleSelect} 
                                                        className="mb-3"
                                                        style={{background:"#E9ECEF"}}
                                                        >
                                                            <Tab eventKey="Coleta" title="Coleta">
                                                                <Form.Group as={Row} controlId="formClienteColeta">
                                                                    <Form.Label column sm={3}>
                                                                        Cliente Coleta
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formClienteBt" variant="outline-secondary" >                                                                                   
                                                                                    <Image src={require('../img/text_box_search48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control aria-describedby="basic-addon1" value={this.state.coleta.cliente}  >                                                                         

                                                                            </Form.Control>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formLocalColeta">
                                                                    <Form.Label column sm={3}>
                                                                        Local Coleta
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button disabled id="formLocalColetaBt"   variant="outline-secondary" >                                                                                   
                                                                                    <Image src={require('../img/text_box_search48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control disabled aria-describedby="basic-addon1" value={this.state.coleta.local}   />                                                                         

                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formRotaColeta">
                                                                    <Form.Label column sm={3}>
                                                                        Rota Coleta
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button disabled id="formRotaColetaBt"   variant="outline-secondary" >
                                                                                    <Image  src={require('../img/text_box_search48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control disabled aria-describedby="basic-addon1" value={this.state.coleta.rota} >                                                                                     

                                                                            </Form.Control>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>                                                                 

                                                                <Form.Group as={Row} controlId="formPagamento">
                                                                    <Form.Label column sm={3}>
                                                                        Pagamento
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >                                                        
                                                                            <Form.Control disabled aria-describedby="basic-addon1" value={this.state.coleta.tipo_pagamento} />                         
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>                                                                 

                                                                <Form.Group as={Row} controlId="formValor">
                                                                    <Form.Label column sm={3}>
                                                                        Valor R$
                                                                    </Form.Label>
                                                                    <Col sm={3}>
                                                                        <InputGroup className="mb-1" >
                                                                            <FormControl disabled type="text"  aria-describedby="basic-addon1" value={this.state.coleta.valor}  />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>

                                                                <Form.Group as={Row} controlId="formObservacao">
                                                                    <Form.Label column sm={3}>
                                                                        Observação
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button disabled id="formObservacaoBt" onClick={this.showObservacao} variant="outline-secondary" >                                                                                   
                                                                                    <Image  src={require('../img/pencil_48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control disabled aria-describedby="basic-addon1" value={this.state.coleta.observacao}  >                                                                         

                                                                            </Form.Control>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formCTE">
                                                                    <Form.Label column sm={3}>
                                                                        CTE
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button disabled id="formCTEBt" onClick={this.showCTE} variant="outline-secondary" >                                                                                   
                                                                                    <Image  src={require('../img/pencil_48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control  aria-describedby="basic-addon1" disabled value={this.state.coleta.cte} />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formPrevisaoColeta">
                                                                    <Form.Label column sm={3}>
                                                                        Previsão
                                                                    </Form.Label>
                                                                    <Col sm={8}>                                                                    
                                                                        <InputGroup className="mb-1" >
                                                                            <DateBox disabled displayFormat="dd/MM/yyyy HH:mm"  width={200}
                                                                                className="headerDateTime"  type="datetime" 
                                                                                placeholder={this.state.coleta.data_previsao}
                                                                                /> 
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                        </Tab>
                                                    {/* ------------------------------------------------------------------------------------------------------ */}
                                                    
                                                        <Tab eventKey="Entrega" title="Entrega">

                                                            <Form.Group as={Row} controlId="formClienteEntrega">                                                                
                                                                <Form.Label column sm={3}>
                                                                        Cliente Entrega
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button  value={ENTREGA_CLIENTE_TIPO} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                                    <Image value={ENTREGA_CLIENTE_TIPO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control  aria-describedby="basic-addon1" value={this.state.entrega.cliente} / >                                                                         
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formLocalEntrega">
                                                                    <Form.Label column sm={3}>
                                                                        Local Entrega
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button disabled value={ENTREGA_CLIENTE_LOCAL_TIPO} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                                    <Image value={ENTREGA_CLIENTE_LOCAL_TIPO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control disabled aria-describedby="basic-addon1" value={this.state.entrega.local}   />                                                                         

                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formRotaEntrega">
                                                                    <Form.Label column sm={3}>
                                                                        Rota Entrega
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button  value={ENTREGA_ROTA_TIPO} onClick={this.showGeralList} variant="outline-secondary" >
                                                                                    <Image value={ENTREGA_ROTA_TIPO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control  aria-describedby="basic-addon1" value={this.state.entrega.rota} >                                                                                     

                                                                            </Form.Control>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formPagamento">
                                                                    <Form.Label column sm={3}>
                                                                        Pagamento
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >                                                        
                                                                            <Form.Control as="select" aria-describedby="basic-addon1"  
                                                                            defaultValue={this.state.entrega.tipo_pagamento} 
                                                                            disabled={this.state.disable_pagamento} 
                                                                            custom onChange={this.setPagamento.bind(this)}>
                                                                                        <option value={null}>
                                                                                                    {}
                                                                                                </option>

                                                                                            {this.state.tipo_pagamentos.map(paga =>
                                                                                                <option value={paga.id} >
                                                                                                    {paga.descricao}
                                                                                                </option>
                                                                                            )}
                                                                            </Form.Control>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>                                                                 

                                                                <Form.Group as={Row} controlId="formValor">
                                                                    <Form.Label column sm={3}>
                                                                        Valor R$
                                                                    </Form.Label>
                                                                    <Col sm={3}>
                                                                        <InputGroup className="mb-1" >
                                                                            <FormControl type="text" disabled={this.state.disable_valor}  aria-describedby="basic-addon1" value={this.state.entrega.valor}  onChange={this.setValorPagamento.bind(this)}/>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>


                                                                <Form.Group as={Row} controlId="formObservacao">
                                                                    <Form.Label column sm={3}>
                                                                        Observação
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formObservacaoBt" onClick={this.showObservacao} variant="outline-secondary" >                                                                                   
                                                                                    <Image  src={require('../img/pencil_48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control  aria-describedby="basic-addon1" disabled value={this.state.entrega.observacao} /> 
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formCTE">
                                                                    <Form.Label column sm={3}>
                                                                        CTE
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formCTEBt" onClick={this.showCTE} variant="outline-secondary" >                                                                                   
                                                                                    <Image  src={require('../img/pencil_48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control  aria-describedby="basic-addon1" disabled value={this.state.entrega.cte} />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formPrevisaoEntrega">
                                                                    <Form.Label column sm={3}>
                                                                        Previsão
                                                                    </Form.Label>
                                                                    <Col sm={8}>                                                                    
                                                                        <InputGroup className="mb-1" >
                                                                            <DateBox displayFormat="dd/MM/yyyy HH:mm"  width={200}
                                                                                className="headerDateTime"  type="datetime" 
                                                                                onValueChanged={this.setDataPrevisaoEntrega}
                                                                                // min={this.state.minDateValue}
                                                                                placeholder={this.state.entrega.data_previsao}
                                                                                /> 
                                                                        </InputGroup>
                                                                    </Col>
                                                            </Form.Group>                                                                                    
                                                        </Tab>   
                                                    </Tabs>                                                                                                                                
                                                </Form>
                                                {/* ----------------------------------- */}
                                            </CustomScroll>


                                            <Modal.Footer >
                                                        {/* <div className="align_bottom_left">
                                                            <Button onClick={this.onVoltarClick}><img src={require('./img/arrow_left24.png')} title={"Voltar"}/></Button>
                                                        </div> */}
                                                            <div className="align_bottom_right_top">                                                                
                                                                {/* <Button disabled  onClick={this.validar}> */}
                                                                <Button disabled={!this.state.changed} onClick={this.validar}>
                                                                    {this.state.salvando
                                                                        ? <Spinner as="span" animation="grow" role="status" aria-hidden="true"/>                                                                
                                                                    :
                                                                    <div>
                                                                        <img src={require('../img/checkbold24.png')}/> 
                                                                    </div>
                                                                    }                                                                
                                                                </Button>
                                                            </div> 
    

                                            </Modal.Footer>

                                        </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}