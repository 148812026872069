import  {Component} from 'react';
import api from '../api';

export default class PostClienteCad extends Component{
    constructor(props, objeto, MapaKseg ){
        super(props);
        this.state = {
             objeto: objeto,
             MapaKseg: MapaKseg
       }
    }
    //------------------------------------------------
    post = async () => {
        let cliente_idd =0;
        try {                    
            let editados = this.state.objeto.state.editados;
            let cliente = this.state.objeto.state.cliente;
            cliente_idd = cliente.cliente_id;

            let response = null;
            let responseJson = null;
            let body =null;            

            if(editados.nome || editados.local || editados.pessoa || editados.municipio || editados.imagem 
                || editados.tipo_pessoa_cliente || editados.telefone || editados.cnpj_cpf 
                || editados.tipo_pagamento || editados.tda){

                body = new FormData();
                body.append('token', localStorage.getItem('token'));
                body.append('cliente_id', cliente.cliente_id);

                if(editados.nome) 
                    body.append('nome', cliente.nome);
                if(editados.local) 
                    body.append('local_id', cliente.local_id);     
                if(editados.pessoa) 
                    body.append('pessoa_id', cliente.pessoa_id);  
                if(editados.municipio) 
                    body.append('municipio_id', cliente.municipio_id);                                       
                if(editados.imagem)
                    body.append('imagem', cliente.imagem);      
                if(editados.juridica)
                    body.append('juridica', cliente.juridica);          
                if(editados.telefone)
                    body.append('telefone', cliente.telefone);      
                if(editados.cnpj_cpf)
                    body.append('cnpj_cpf', cliente.cnpj_cpf);      
                if(editados.tipo_pagamento)
                    body.append('tipo_pagamento_id', cliente.tipo_pagamento_id);                                  
                if(editados.tda)
                    body.append('tda', cliente.tda);     
            

                response = await api.post('/cadastro_api/postCliente', body);

                if (response.data !== null  &&  response.status !== 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json); 
                    cliente_idd = resposta.cliente_id; 
                }

            }
             

        } finally {      
            let cliente = this.state.objeto.state.cliente;
            cliente.cliente_id = cliente_idd;
            this.state.objeto.setState({ cliente: cliente   },
               () => { this.state.objeto.concluido()  });  

            }
    };
}