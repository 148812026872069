import  {Component} from 'react';
import api from '../api';

export default class GetUsuarios extends Component{
    constructor(props, objeto, MapaKseg, usuario_id){
        super(props);
        this.state = {
             list: [],
             objeto: objeto,
             MapaKseg: MapaKseg,
             usuario_id: usuario_id
       }
    }
    //------------------------------------------------
    get = async () => {
        try {
            let body = new FormData()
            if(this.state.usuario_id !== 0) 
                body.append('usuario_id', this.state.usuario_id)

            body.append('token', localStorage.getItem('token'))

            let response = null;

            response = await api.post('/cadastro_api/getUsuario', body);    
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let obj = null;
                let list = this.state.list;                

                // ToastStore.success("resposta.length: "+ String(resposta.length));
                for (var i = 0; i < resposta.length; i++) {                        
                    obj = {    
                        index: i+1,
                        usuario_id: resposta[i].usuario_id,
                        nome: resposta[i].nome,
                        login: resposta[i].login,
                        senha: resposta[i].senha,
                        pessoa_id: resposta[i].pessoa_id,
                        nome_social: resposta[i].nome_social,
                        ativo: resposta[i].ativo,
                        tipo_usuario: resposta[i].tipo_usuario,    
                        tipo_usuario_id: resposta[i].tipo_usuario_id,    
                        operacao:0,                    
                    };                   

                    list.push(obj);
                    this.setState({list: list});
                }                
            }
        } finally {   
            this.state.objeto.setList(this.state.list);
            }
    };
}