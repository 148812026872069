import  {Component} from 'react';
import api from '../api';
import {ToastStore} from 'react-toasts';
import Moment from 'moment';

// import {Polyline} from 'google-maps-react';

export default class GetColeta extends Component{
    constructor(props, objeto, MapaKseg, coleta_id){
        super(props);
        this.state = {
             rota_filial: {},
             list: [],
             loading:true,
             objeto: objeto,
             coleta_id: coleta_id,
             coleta:null,
             MapaKseg: MapaKseg,
       }
    }
    //-----------------------------------------------------------------------------------------
    setEntregasCount = (count) =>{
        if(count===1) return  require("../img/circle1.png");
        else if(count===2) return require("../img/circle2.png")
        else if(count===3) return require("../img/circle3.png")
        else if(count===4) return require("../img/circle4.png")
        else if(count===5) return require("../img/circle5.png")
        else if(count>5) return require("../img/circle5plus.png")
        else if(count>9) return require("../img/circle9plus.png")                                            

    };
    //------------------------------------------------
    get = async () => {
        let coleta =null;
        try {
            let body = new FormData();
            body.append('coleta_id', this.state.coleta_id);
            body.append('pessoa_id', this.state.MapaKseg.state.pessoa_id);
            body.append('token', localStorage.getItem('token'));

            const response = await api.post('/coletaweb_api/getColeta', body);
            
            var responseJson = {};

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let list = this.state.list;       
                // console.log(" resposta: ");
                // console.log(resposta.coleta.cliente);
                coleta = resposta.coleta;
                
                if(coleta.valor === "0") coleta.valor = "";                
                if(coleta.tda === "None") coleta.tda = "";
                if(coleta.nota_fiscal === "None") coleta.nota_fiscal = "";


                // ToastStore.success("resposta.length: "+ String(resposta.length));
                // for (var i = 0; i < resposta.length; i++) {                        
                //     coleta = {    
                //         index: i+1,
                //         coleta_id: resposta[i].coleta_id,
                //         cliente_id: resposta[i].cliente_id,
                //         cliente: resposta[i].cliente,
                //         rota_id: resposta[i].rota_id,
                //         rota: resposta[i].rota,
                //         data_previsao: resposta[i].data_previsao,
                //         data_coleta: resposta[i].data_coleta,
                //         tipo_pagamento_id: resposta[i].tipo_pagamento_id,
                //         tipo_pagamento: resposta[i].tipo_pagamento,
                //         valor: resposta[i].valor,
                //         local_id: resposta[i].local_id,
                //         local: resposta[i].local,
                //         entrega_cliente: resposta[i].entrega_cliente,
                //         usuario: resposta[i].usuario,
                //         entregas: resposta[i].entregas,                        
                //         municipio: resposta[i].municipio,

                //         status:2,
                //         selecionada: false,
                //         index_scroll: (i+2)*25,
                //         entregas_count_icon: null,
                //     };                    

                //     if(String(coleta.data_coleta).trim() !== "")
                //         coleta.status = 1 // 1 - concluido

                //     coleta.entregas_count_icon = this.setEntregasCount(coleta.entregas.length);

                //     list.push(coleta);
                //     this.setState({list: list});
                // }

                this.setState({loading: false});
                
            }
        } finally {      

            if(coleta.coleta_id === 0){
                this.state.MapaKseg.toastError("Coleta Id não encontrada!");
                this.state.objeto.fecharModal();
            }else{
                this.state.objeto.setColeta(coleta);              
                }
            }
    };
    //--------------------------
}


// coleta = {    
//     coleta_id: resposta[i].rota_id,
//     descricao: resposta[i].descricao,
//     data_previsao: resposta[i].data_previsao,
//     data_coleta: resposta[i].data_coleta,
//     data_cadastro: resposta[i].data_cadastro,
//     valor: resposta[i].valor,
//     usuario_id: resposta[i].usuario_id,
//     rota_id: resposta[i].rota_id,
//     cliente_id: resposta[i].cliente_id,
//     entrega_cliente_id: resposta[i].entrega_cliente_id,
//     entrega_data_previsao: resposta[i].entrega_data_previsao,
//     entrega_rota_id: resposta[i].entrega_rota_id,
//     entrega_local_id: resposta[i].entrega_local_id,
//     tipo_pagamento_id: resposta[i].tipo_pagamento_id,
//     urgente: resposta[i].urgente,
//     usuario_cadastro_id: resposta[i].usuario_cadastro_id,
//     status:1,


//     selecionada: false,
//     index_scroll: (i+2)*25,
//     Status:1,
// };