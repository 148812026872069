import React, {Component} from 'react';
import {Modal, ListGroup, Spinner, Button, Navbar, Nav, Form, FormControl} from 'react-bootstrap'; 
import './estilo.css';
import { ToastStore} from 'react-toasts';
import api from './api';
import DtGrid from './dataGrid';
import {DataGridTemperatura} from './DataGridTemperatura';
import {DataGridEventos} from './DataGridEventos';
import FilterSelect from './filterSelect'
import { DateBox } from 'devextreme-react';
import {CabecalhoModal} from './CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import {GetEventos} from './services/GetEventos';
// import { CSVLink, CSVDownload } from "react-csv";
import axios from 'axios';
import {
    setKey,
    setDefaults,
    setLanguage,
    setRegion,
    fromAddress,
    fromLatLng,
    fromPlaceId,
    setLocationType,
    geocode,
    RequestType,
  } from "react-geocode";

// import { mkConfig, generateCsv, asString } from "export-to-csv";

const TIPO_CHAMADA_POSICOES =1;
const TIPO_CHAMADA_EVENTOS =2;


const csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"]
  ];

//--------------------------------  
var moment = require('moment');
require('moment-precise-range-plugin');
 //--------------------------------  


export class RelPosicoesVeiModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true,
            posicao:{},
            list:[],
            loading:false,
            filter_list: [],
            data_inicial: null,
            data_final: null,
            value_data_inicial: null,
            value_data_final: null,
            btnPesquisar:false,
            veiculo_id:null,
            placa:null,
            veiculo_temperaturas_tab:false,
            MapaKseg: props.MapaKseg,
            animation: "zoom",
            minimizar:false,
            drag:"drag3",
            width:1000,
            height:520,
            grid_height:'400px',
            trecho_evento_list:[],

            listOfUsers: [],
        };
        this.now = new Date();
      }    
      //----------
      setVeiculoId = (veiculo_id, placa) => {
        this.setState({veiculo_id: veiculo_id,
                      placa: placa});

        var temperaturas_tab =false;
        for (var i=0; i < this.props.list.length; i++) {
            if(parseInt(this.props.list[i].veiculo_id) === parseInt(veiculo_id)){
                temperaturas_tab = this.props.list[i].temperaturas_tab;
                // ToastStore.success( "ACHOU temperaturas_tab: "+String(temperaturas_tab));
                break;
            }
        }
        this.setState({veiculo_temperaturas_tab: temperaturas_tab});
      }
      //----------
      onClickPesquisar = (e) => {
        const age=this.diffInDay;
        if(this.state.veiculo_id===null)
            ToastStore.error("Selecine um veículo!");
        else if(this.state.data_inicial===null || String(this.state.data_inicial).includes("undefined"))
            ToastStore.error("Data inicial está vazia!");
        else if(this.state.data_final===null || String(this.state.data_final).includes("undefined"))
            ToastStore.error("Data final está vazia!");
        else if(age > 31)
            ToastStore.error("Período maior que 31 dias!");
        else{
            this.setState({loading:true});
            this.loadData();
        }
      }
      //----------
      loadData = (e) => {
        this.setState({list:[]});
        if(this.state.btnPesquisar===true){
            this.setState({btnPesquisar:false});
            this.setState({loading:true});
        }
        this.setState({btnPesquisar:true});
        this.getPosicoes();
      }
      //----------
      get diffInDay() {
        return `${Math.floor(Math.abs((this.state.value_data_final - this.state.value_data_inicial) / (24 * 60 * 60 * 1000))) } `;
      }
      //----------
      renderData = () => {    
        return(
            <div>
                { this.state.loading ? 
                    <div className="centro">
                        <div className="espaco_topo2">
                            <Spinner animation="border" />
                       </div>
                    </div>

                    :
                    <div style={{height: this.state.grid_height, overflow: 'hidden'}}>                                    
                        <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                            {this.props.tipo_chamada===TIPO_CHAMADA_POSICOES ?  
                                this.state.veiculo_temperaturas_tab ?
                                    <DataGridTemperatura RelPosicoesVeiModal={this} MapaKseg={this.props.MapaKseg} veiculo_id={this.state.veiculo_id} placa={this.state.placa} orders={this.state.list} />
                                :<DtGrid veiculo_id={this.state.veiculo_id} placa={this.state.placa} orders={this.state.list} MapaKseg={this.props.MapaKseg} RelPosicoesVeiModal={this} trecho_evento_list={this.state.trecho_evento_list}/>     
                            : <DataGridEventos veiculo_id={this.state.veiculo_id}  placa={this.state.placa} orders={this.state.list} MapaKseg={this.props.MapaKseg} RelPosicoesVeiModal={this}/>     
                            }
                        </CustomScroll>
                    </div>        
                }
            </div>
        );
      }
      //----------
      onValueChangedDataInicial = (e) => {
        this.setState({
          data_inicial: this.formatarDateTime(e.value),
          value_data_inicial: e.value
        });
        // ToastStore.success( String(this.state.data_inicial));
      }
      //----------
      onValueChangedDataFinal = (e) => {
        this.setState({
          data_final: this.formatarDateTime(e.value),
          value_data_final: e.value
        });
        // ToastStore.success( String(this.state.data_final));
      }
      //----------
      formatarMes(mes){    
        if(mes==="Jan")
            return 1;
        else if (mes==="Feb")
            return 2;
        else if (mes==="Mar")
            return 3;     
        else if (mes==="Apr")
            return 4;     
        else if (mes==="May")
            return 5;     
        else if (mes==="Jun")
            return 6; 
        else if (mes==="Jul")
            return 7; 
        else if (mes==="Aug")
            return 8; 
        else if (mes==="Sep")
            return 9; 
        else if (mes==="Oct")
            return 10;    
        else if (mes==="Nov")
            return 11;
        else if (mes==="Dec")
            return 12;             
      }
      //----------
      formatarDateTime(dtTime){    
        const y = String(dtTime).substring(11,15);
        const mm = this.formatarMes(String(dtTime).substring(4,7));
        const dd = String(dtTime).substring(8,10);
        const hm = String(dtTime).substring(16,21);

        // console.log("  *** dtTime: "+String(dtTime));
        // console.log("  *** y: "+y);
        // console.log("  *** mm: "+mm);
        // console.log("  *** mm: "+String(this.formatarMes(mm)));
        // console.log("  *** dd: "+dd);
        // console.log("  *** hm: "+hm);  
        const datetime = y+"-"+mm+"-"+dd+" "+hm;  // "Y-MM-DD H:M"
        return datetime;                  
      }
     //--------------------
     formatarDateTimeGrid(dtTime){
         //  sex 21:47:10   03-07-2020  
         if(dtTime===null)
            return "";

        const diaSema = String(dtTime).substring(0,3);
        const y = String(dtTime).substring(23,25);
        const mm = String(dtTime).substring(18,20);
        const dd = String(dtTime).substring(12,17);
        const hm = String(dtTime).substring(4,12);    
        const datetime = diaSema+", "+dd+"/"+mm+"/"+y+"   "+hm;  // "Y-MM-DD H:M"
        return datetime;  
     }
    //-----------------
    getPosicoes = async () => {
        // console.log("  ***this.props.data_receb: "+this.props.data_receb)
        try {
            let body = new FormData()
            body.append('grupo_id', localStorage.getItem('grupo_id'))
            body.append('veiculo_id', this.state.veiculo_id)
            body.append('data_inicial', this.state.data_inicial)
            body.append('data_final', this.state.data_final)
            body.append('token', localStorage.getItem('token'))
            body.append('temperaturas_tab', this.state.veiculo_temperaturas_tab)

            var url = "/relatorio_api/posicoesVeiculoData";
            if(this.props.tipo_chamada=== TIPO_CHAMADA_EVENTOS)
                url="/relatorio_api/eventosVeiculoData";

            let response = await api.post(url, body);

            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
            
                let posicao = this.state.posicao;
                let list = this.state.list;
                let sensores ="";    
                var inserir=true;            

                // ToastStore.success("resposta.length: "+ String(resposta.length));

                if(this.props.tipo_chamada=== TIPO_CHAMADA_POSICOES){
                    if(this.state.veiculo_temperaturas_tab){
                        for (var i = 0; i < resposta.length; i++) {
                            posicao = {
                                id:i+1,
                                recebimento_id: resposta[i].recebimento_id,
                                veiculo_id: resposta[i].veiculo_id,
                                placa: this.state.placa,
                                municipio: resposta[i].municipio,
                                uf: resposta[i].uf,
                                estado: resposta[i].estado,
                                pais: resposta[i].pais,
                                velocidade: resposta[i].velocidade,
                                tipo_recebimento_id: resposta[i].tipo_recebimento_id,
                                tecnologia_id: resposta[i].tecnologia_id,
                                data_receb: resposta[i].data_receb_temperatura,
                                data_format: this.formatarDateTimeGrid(resposta[i].data_receb_temperatura),
                                latitude: resposta[i].latitude,
                                longitude: resposta[i].longitude,
                                latlng: resposta[i].latitude+", "+resposta[i].longitude,
                                backup: resposta[i].backup,
                                sensor1: resposta[i].sensor1,
                                sensor2: resposta[i].sensor2,
                                sensor3: resposta[i].sensor3,
                                sensores: "",
                                ignicao: resposta[i].ignicao,
                                mensagem_externo_id: resposta[i].mensagem_externo_id,
                                direcao: resposta[i].direcao,                                
                                };
                                console.log("  ignicao "+posicao.ignicao)
                                if(resposta[i].data_receb_temperatura=== null){
                                    posicao.data_receb = resposta[i].data_cadastro;
                                    posicao.data_format = this.formatarDateTimeGrid(resposta[i].data_cadastro);
                                }
                                
                                // if(resposta[i].velocidade===0)
                                //     posicao.velocidade="Parado"

                            // console.log("  *** backup: "+resposta[i].backup)
                            // console.log("  *** placa: "+posicao.placa+"   data_receb: "+posicao.data_receb)

                            sensores ="";
                            // if (String(posicao.sensor1)[0]==="-")
                            //     sensores = "s1:"+String(resposta[i].sensor1).substring(0,3)
                            // else sensores = "s1:"+String(resposta[i].sensor1).substring(0,2)    

                            // if (String(posicao.sensor1)[0]==="-")
                            //     sensores = "s1:"+String((resposta[i].sensor1).toFixed(0))
                            // else sensores = "s1:"+String((resposta[i].sensor1).toFixed(0))  sentence.split(" ");

                            if(resposta[i].sensor1 !== null){
                                if (String(posicao.sensor1)[0]==="-")
                                    sensores = "s1:"+String((resposta[i].sensor1)).split(".")[0]
                                else sensores = "s1:"+String((resposta[i].sensor1)).split(".")[0]

                                if (String(posicao.sensor2)[0]==="-")
                                    sensores = sensores+" s2:"+String((resposta[i].sensor2)).split(".")[0]
                                else sensores = sensores+" s2:"+String((resposta[i].sensor2)).split(".")[0] 

                                if (String(posicao.sensor3)[0]==="-")
                                    sensores = sensores+" s3:"+String((resposta[i].sensor3)).split(".")[0]
                                else sensores = sensores+" s3:"+String((resposta[i].sensor3)).split(".")[0]
                            }else sensores ="";

                            posicao.sensores = sensores;
                            list.push(posicao);
                            this.setState({list: list});
                        }
                    }else{
                        for (var i = 0; i < resposta.length; i++) {
                            posicao = {
                                id:i+1,
                                recebimento_id: resposta[i].recebimento_id,
                                veiculo_id: resposta[i].veiculo_id,
                                placa: this.state.placa,
                                municipio: resposta[i].municipio,
                                uf: resposta[i].uf,
                                estado: resposta[i].estado,
                                pais: resposta[i].pais,
                                velocidade: resposta[i].velocidade,
                                tipo_recebimento_id: resposta[i].tipo_recebimento_id,
                                tecnologia_id: resposta[i].tecnologia_id,
                                data_receb: resposta[i].data_cadastro,
                                data_format: this.formatarDateTimeGrid(resposta[i].data_cadastro),
                                latitude: resposta[i].latitude,
                                longitude: resposta[i].longitude,               
                                latlng: resposta[i].latitude+", "+resposta[i].longitude,      
                                latlng2: resposta[i].latitude+","+resposta[i].longitude,
                                backup: resposta[i].backup,
                                ignicao: resposta[i].ignicao,
                                mensagem_externo_id: resposta[i].mensagem_externo_id,
                                direcao: resposta[i].direcao,
                                eventos_count:0,
                                eventos_count_icon: null,
                                // endereco: resposta[i].endereco,
                                endereco: ""
                                };
                        
                                // console.log("  ignicao "+posicao.ignicao)
                            // console.log("  *** backup: "+resposta[i].backup)
                            // console.log("  *** placa: "+posicao.placa+"   data_receb: "+posicao.data_receb)

                            if( i == 0){
                                await geocode(RequestType.LATLNG, posicao.latlng2)
                                .then(({ results }) => {
                                    const address = results[0].formatted_address;
                                    // console.log(address);
                                    posicao.endereco = address;
                                })
                                .catch(console.error);
                            }else
                            if( posicao.latitude != resposta[i-1].latitude
                                && posicao.longitude != resposta[i-1].longitude){
                                    // console.log('');
                                await geocode(RequestType.LATLNG, posicao.latlng2)
                                .then(({ results }) => {
                                    const address = results[0].formatted_address;
                                    // console.log(address);
                                    posicao.endereco = address;
                                })
                                .catch(console.error);

                            }else posicao.endereco = list[i-1].endereco;
                            
                            // else posicao.endereco = "Iguais";




                            list.push(posicao);
                            this.setState({list: list});
                        }
                    }

                    

                }else if(this.props.tipo_chamada=== TIPO_CHAMADA_EVENTOS){
                    for (var i = 0; i < resposta.length; i++) {
                        posicao = {
                            id:i+1,
                            recebimento_id: resposta[i].recebimento_id,
                            veiculo_id: resposta[i].veiculo_id,
                            placa: this.state.placa,
                            municipio: resposta[i].municipio,
                            uf: resposta[i].uf,
                            estado: resposta[i].estado,
                            pais: resposta[i].pais,
                            velocidade: resposta[i].velocidade,
                            tipo_recebimento_id: resposta[i].tipo_recebimento_id,
                            tecnologia_id: resposta[i].tecnologia_id,
                            data_receb: resposta[i].data_cadastro,
                            data_format: this.formatarDateTimeGrid(resposta[i].data_cadastro),
                            latitude: resposta[i].latitude,
                            longitude: resposta[i].longitude,               
                            latlng: resposta[i].latitude+", "+resposta[i].longitude,      
                            backup: resposta[i].backup,
                            ignicao: resposta[i].ignicao,
                            tipo_evento_id: resposta[i].tipo_evento_id,
                            icone_marker: this.setIcone(resposta[i].tipo_evento_id),
                            direcao: resposta[i].direcao,
                            evento: resposta[i].evento,
                            data_inicial_format: this.formatarDateTimeGrid(resposta[i].data_inicio),
                            data_final_format: this.formatarDateTimeGrid(resposta[i].data_final),
                            dateDiff: "",
                            mensagem_externo_id: resposta[i].mensagem_externo_id,
                            valor: resposta[i].valor
                            };
                        // console.log("  posicao.data_final "+resposta[i].data_final);
                        if(resposta[i].data_final!==null)
                            posicao.dateDiff = this.dateDiff(resposta[i].data_inicio, resposta[i].data_final)

                            // console.log("  ignicao "+posicao.ignicao);
                        // console.log("  *** backup: "+resposta[i].backup)
                        // console.log("  *** placa: "+posicao.placa+"   data_receb: "+posicao.data_receb)
                        inserir=true;

                        // if(posicao.tipo_evento_id===68){ // 68 porta traseira aberta
                        //     if(posicao.velocidade>0)
                        //         inserir=false;
                        // }

                        if(inserir)
                            list.push(posicao);

                        this.setState({list: list, listOfUsers: list});
                    }
                }

                if(this.state.list.length>0){
                    var tipo_chamada=1;  //  TIPO_CHAMADA_TRECHO_POSICOES
                    var instance = new GetEventos(this.state.list[this.state.list.length-1]
                                                    , this.props.MapaKseg
                                                    , this.state.list
                                                    , tipo_chamada
                                                    , this.state.veiculo_id
                                                    ,this
                                                    ,null);
                    instance.getEventos();
                }

                this.setState({loading: false});
                
            }else{
                // console.log("response.status");
                // console.log(response.status);
                // alert(response.status);
            }
        } catch (e) {
            // console.log("eeee!");
            // console.log(e);
            // alert(e);

        } finally {
            this.setState({loading: false});
                // ToastStore.success(" list.length: "+ String(this.state.list.length));
                // ToastStore.success("PPlaca: "+ String(lastPosition.data_receb));
                // this.state.objeto.setCoords(coords, lastPosition, markerEvento_list);

            }
    };
    //--------------------------------
    getUsers1 = async (event, done) => {
        if(!this.state.loading) {
            this.setState({
            loading: true
            });


            try {
                let body = new FormData()
                body.append('grupo_id', localStorage.getItem('grupo_id'))
                body.append('veiculo_id', this.state.veiculo_id)
                body.append('data_inicial', this.state.data_inicial)
                body.append('data_final', this.state.data_final)
                body.append('token', localStorage.getItem('token'))
                body.append('temperaturas_tab', this.state.veiculo_temperaturas_tab)
    
                var url = "/relatorio_api/posicoesVeiculoData";
                if(this.props.tipo_chamada=== TIPO_CHAMADA_EVENTOS)
                    url="/relatorio_api/eventosVeiculoData";
    
                const response = await api.post(url, body);
    
    
                var responseJson = null
    
                if (response.data !== null  &&  response.status !== 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json);
    
                
                    let posicao = this.state.posicao;
                    let list = this.state.list;
                    let sensores ="";    
                    var inserir=true;            
    
                    // ToastStore.success("resposta.length: "+ String(resposta.length));
    
                    if(this.props.tipo_chamada=== TIPO_CHAMADA_POSICOES){
                        if(this.state.veiculo_temperaturas_tab){
                            for (var i = 0; i < resposta.length; i++) {
                                posicao = {
                                    id:i+1,
                                    recebimento_id: resposta[i].recebimento_id,
                                    veiculo_id: resposta[i].veiculo_id,
                                    placa: this.state.placa,
                                    municipio: resposta[i].municipio,
                                    uf: resposta[i].uf,
                                    estado: resposta[i].estado,
                                    pais: resposta[i].pais,
                                    velocidade: resposta[i].velocidade,
                                    tipo_recebimento_id: resposta[i].tipo_recebimento_id,
                                    tecnologia_id: resposta[i].tecnologia_id,
                                    data_receb: resposta[i].data_receb_temperatura,
                                    data_format: this.formatarDateTimeGrid(resposta[i].data_receb_temperatura),
                                    latitude: resposta[i].latitude,
                                    longitude: resposta[i].longitude,
                                    latlng: resposta[i].latitude+", "+resposta[i].longitude,
                                    backup: resposta[i].backup,
                                    sensor1: resposta[i].sensor1,
                                    sensor2: resposta[i].sensor2,
                                    sensor3: resposta[i].sensor3,
                                    sensores: "",
                                    ignicao: resposta[i].ignicao,
                                    mensagem_externo_id: resposta[i].mensagem_externo_id,
                                    direcao: resposta[i].direcao,
                                    };
                                    console.log("  ignicao "+posicao.ignicao)
                                    if(resposta[i].data_receb_temperatura=== null){
                                        posicao.data_receb = resposta[i].data_cadastro;
                                        posicao.data_format = this.formatarDateTimeGrid(resposta[i].data_cadastro);
                                    }
                                    
                                    // if(resposta[i].velocidade===0)
                                    //     posicao.velocidade="Parado"
    
                                // console.log("  *** backup: "+resposta[i].backup)
                                // console.log("  *** placa: "+posicao.placa+"   data_receb: "+posicao.data_receb)
    
                                sensores ="";
                                // if (String(posicao.sensor1)[0]==="-")
                                //     sensores = "s1:"+String(resposta[i].sensor1).substring(0,3)
                                // else sensores = "s1:"+String(resposta[i].sensor1).substring(0,2)    
    
                                // if (String(posicao.sensor1)[0]==="-")
                                //     sensores = "s1:"+String((resposta[i].sensor1).toFixed(0))
                                // else sensores = "s1:"+String((resposta[i].sensor1).toFixed(0))  sentence.split(" ");
    
                                if(resposta[i].sensor1 !== null){
                                    if (String(posicao.sensor1)[0]==="-")
                                        sensores = "s1:"+String((resposta[i].sensor1)).split(".")[0]
                                    else sensores = "s1:"+String((resposta[i].sensor1)).split(".")[0]
    
                                    if (String(posicao.sensor2)[0]==="-")
                                        sensores = sensores+" s2:"+String((resposta[i].sensor2)).split(".")[0]
                                    else sensores = sensores+" s2:"+String((resposta[i].sensor2)).split(".")[0] 
    
                                    if (String(posicao.sensor3)[0]==="-")
                                        sensores = sensores+" s3:"+String((resposta[i].sensor3)).split(".")[0]
                                    else sensores = sensores+" s3:"+String((resposta[i].sensor3)).split(".")[0]
                                }else sensores ="";
    
                                posicao.sensores = sensores;
                                list.push(posicao);
                                this.setState({list: list});
                            }
                        }else{
                            for (var i = 0; i < resposta.length; i++) {
                                posicao = {
                                    id:i+1,
                                    recebimento_id: resposta[i].recebimento_id,
                                    veiculo_id: resposta[i].veiculo_id,
                                    placa: this.state.placa,
                                    municipio: resposta[i].municipio,
                                    uf: resposta[i].uf,
                                    estado: resposta[i].estado,
                                    pais: resposta[i].pais,
                                    velocidade: resposta[i].velocidade,
                                    tipo_recebimento_id: resposta[i].tipo_recebimento_id,
                                    tecnologia_id: resposta[i].tecnologia_id,
                                    data_receb: resposta[i].data_cadastro,
                                    data_format: this.formatarDateTimeGrid(resposta[i].data_cadastro),
                                    latitude: resposta[i].latitude,
                                    longitude: resposta[i].longitude,               
                                    latlng: resposta[i].latitude+", "+resposta[i].longitude,      
                                    backup: resposta[i].backup,
                                    ignicao: resposta[i].ignicao,
                                    mensagem_externo_id: resposta[i].mensagem_externo_id,
                                    direcao: resposta[i].direcao,
                                    eventos_count:0,
                                    eventos_count_icon: null,
                                    };
                            
                                    // console.log("  ignicao "+posicao.ignicao)
                                // console.log("  *** backup: "+resposta[i].backup)
                                // console.log("  *** placa: "+posicao.placa+"   data_receb: "+posicao.data_receb)
                                list.push(posicao);
                                this.setState({list: list});
                            }
                        }
    
                        
    
                    }else if(this.props.tipo_chamada=== TIPO_CHAMADA_EVENTOS){
                        for (var i = 0; i < resposta.length; i++) {
                            posicao = {
                                id:i+1,
                                recebimento_id: resposta[i].recebimento_id,
                                veiculo_id: resposta[i].veiculo_id,
                                placa: this.state.placa,
                                municipio: resposta[i].municipio,
                                uf: resposta[i].uf,
                                estado: resposta[i].estado,
                                pais: resposta[i].pais,
                                velocidade: resposta[i].velocidade,
                                tipo_recebimento_id: resposta[i].tipo_recebimento_id,
                                tecnologia_id: resposta[i].tecnologia_id,
                                data_receb: resposta[i].data_cadastro,
                                data_format: this.formatarDateTimeGrid(resposta[i].data_cadastro),
                                latitude: resposta[i].latitude,
                                longitude: resposta[i].longitude,               
                                latlng: resposta[i].latitude+", "+resposta[i].longitude,      
                                backup: resposta[i].backup,
                                ignicao: resposta[i].ignicao,
                                tipo_evento_id: resposta[i].tipo_evento_id,
                                icone_marker: this.setIcone(resposta[i].tipo_evento_id),
                                direcao: resposta[i].direcao,
                                evento: resposta[i].evento,
                                data_inicial_format: this.formatarDateTimeGrid(resposta[i].data_inicio),
                                data_final_format: this.formatarDateTimeGrid(resposta[i].data_final),
                                dateDiff: "",
                                mensagem_externo_id: resposta[i].mensagem_externo_id,
                                valor: resposta[i].valor
                                };
                            // console.log("  posicao.data_final "+resposta[i].data_final);
                            if(resposta[i].data_final!==null)
                                posicao.dateDiff = this.dateDiff(resposta[i].data_inicio, resposta[i].data_final)
    
                                // console.log("  ignicao "+posicao.ignicao);
                            // console.log("  *** backup: "+resposta[i].backup)
                            // console.log("  *** placa: "+posicao.placa+"   data_receb: "+posicao.data_receb)
                            inserir=true;
    
                            // if(posicao.tipo_evento_id===68){ // 68 porta traseira aberta
                            //     if(posicao.velocidade>0)
                            //         inserir=false;
                            // }
    
                            if(inserir)
                                list.push(posicao);
    
                            this.setState({list: list});
                        }
                    }
    
                    if(this.state.list.length>0){
                        var tipo_chamada=1;  //  TIPO_CHAMADA_TRECHO_POSICOES
                        var instance = new GetEventos(this.state.list[this.state.list.length-1]
                                                        , this.props.MapaKseg
                                                        , this.state.list
                                                        , tipo_chamada
                                                        , this.state.veiculo_id
                                                        ,this
                                                        ,null);
                        instance.getEventos();
                    }
    
                    this.setState({listOfUsers: list});
                    this.setState({loading: false});
                    
                }else{
                }
    
            } finally {
                    // ToastStore.success(" list.length: "+ String(this.state.list.length));
                    // ToastStore.success("PPlaca: "+ String(lastPosition.data_receb));
                    // this.state.objeto.setCoords(coords, lastPosition, markerEvento_list);
    
                }


        }
        }
        //--------------------------------
        getUsers3 = (event, done) => {
            // if(!this.state.loading) {
              this.setState({
                loading: true
              });
    
    
              axios.post('https://kseg.net.br/relatorio_api/posicoesVeiculoData', {
                grupo_id: localStorage.getItem('grupo_id'),
                veiculo_id: this.state.veiculo_id,
                data_inicial: this.state.data_inicial,
                data_final: this.state.data_final,
                token: localStorage.getItem('token'),
                temperaturas_tab: this.state.veiculo_temperaturas_tab
              }).then((userListJson) => {
                alert(userListJson);
                this.setState({
                  listOfUsers: userListJson,
                  loading: false
                });
                done(true); // Proceed and get data from dataFromListOfUsersState function
              }).catch(() => {
                alert("catch");
                this.setState({
                  loading: false
                });
                done(false);
              });
    
    
            // }
          }
        //--------------------------------
        getUsers4 = async (event, done) => {
            // if(!this.state.loading) {
              this.setState({
                loading: true
              });
    

            //   const {data} = await axios.post('https://kseg.net.br/relatorio_api/posicoesVeiculoData', {
            //     grupo_id: localStorage.getItem('grupo_id'),
            //     veiculo_id: this.state.veiculo_id,
            //     data_inicial: this.state.data_inicial,
            //     data_final: this.state.data_final,
            //     token: localStorage.getItem('token'),
            //     temperaturas_tab: this.state.veiculo_temperaturas_tab
            // }, {
            //     headers: {
            //         "Content-Type": "application/x-www-form-urlencoded",
            //         "Accept": "application/json"
            //     }
            // }
            // )
    
            await axios.post('https://kseg.net.br/relatorio_api/posicoesVeiculoData', {
                grupo_id: localStorage.getItem('grupo_id'),
                veiculo_id: this.state.veiculo_id,
                data_inicial: this.state.data_inicial,
                data_final: this.state.data_final,
                token: localStorage.getItem('token'),
                temperaturas_tab: this.state.veiculo_temperaturas_tab
              }, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json"
                }
            }).then((userListJson) => {
                alert(userListJson);
                this.setState({
                  listOfUsers: userListJson,
                  loading: false
                });
                done(true); // Proceed and get data from dataFromListOfUsersState function
              }).catch(() => {
                alert("catch");
                this.setState({
                  loading: false
                });
                done(false);
              });
    
    
            // }
          }          
    //--------------------------------
    getUsers2 = (event, done) => {
        if(!this.state.loading) {
          this.setState({
            loading: true
          });


          axios.get("/api/users").then((userListJson) => {
            this.setState({
              listOfUsers: userListJson,
              loading: false
            });
            done(true); // Proceed and get data from dataFromListOfUsersState function
          }).catch(() => {
            this.setState({
              loading: false
            });
            done(false);
          });


        }
      }
      //------------------------------------
    //   onClickPesquisar = (e) => {
      dataFromListOfUsersState = () => {
        return this.state.listOfUsers;
      }
    //--------------------------
    async componentDidMount() {
        // this.props.MapaKseg.callToast("Width: "+String(window.innerWidth)+"   Height: "+String(window.innerHeight));
        var data_hoje= new Date();
        var data_ontem = new Date();
        data_ontem.setDate(data_ontem.getDate() - 1);

        this.setState({
            data_inicial: this.formatarDateTime(String(data_ontem))
            ,value_data_inicial: String(data_ontem)
            ,data_final: this.formatarDateTime(String(data_hoje))
            ,value_data_final: String(data_hoje)
          });


        if(this.props.tipo_chamada===TIPO_CHAMADA_POSICOES)
            this.setState({drag: "drag3",width: 1000 });
        else if(this.props.tipo_chamada===TIPO_CHAMADA_EVENTOS)
            this.setState({drag: "drag5",width: 1199 });

        // -----------Geocode sets-------------------
        setDefaults({
            key: "AIzaSyADcVq6g9Vr3jpGIVhBK8v4ThVnzxJU0Mw", // Your API key here.
            language: "pt", // Default language for responses.
            region: "br", // Default region for responses.
            });

        // ToastStore.success("getPosicoesVeiculo componentDidMount()");
        // this.getPosicoes();
    };
    //--------------------
    setEventosCount = (eventos_count) =>{
        let list = this.state.list;
        for(var i=0; i<eventos_count.length; i++) {
            for(var j = 0; j<list.length; j++) {
                if(eventos_count[i].recebimento_id === list[j].recebimento_id ){
                    list[j].eventos_count = eventos_count[i].count;
                    if(eventos_count[i].count===1) list[j].eventos_count_icon = require("./img/circle1.png")
                    else if(eventos_count[i].count===2) list[j].eventos_count_icon = require("./img/circle2.png")
                    else if(eventos_count[i].count===3) list[j].eventos_count_icon = require("./img/circle3.png")
                    else if(eventos_count[i].count===4) list[j].eventos_count_icon = require("./img/circle4.png")
                    else if(eventos_count[i].count===5) list[j].eventos_count_icon = require("./img/circle5.png")
                    else if(eventos_count[i].count>5) list[j].eventos_count_icon = require("./img/circle5plus.png")
                    else if(eventos_count[i].count>9) list[j].eventos_count_icon = require("./img/circle9plus.png")
                    
                    break;
                }
            }
        }
        this.setState({list:list});
    };
    //--------------------
    getDataInicial(){
        var data_ontem = new Date();
        data_ontem.setHours(data_ontem.getHours() - 1);
        return data_ontem;  
     }
     //--------------------
     minimizarModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        if(this.props.tipo_chamada=== TIPO_CHAMADA_POSICOES)
            this.props.SideNavDrawer.relPosicoesVeiModalClose();
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_EVENTOS)
            this.props.SideNavDrawer.relEventosVeiModalClose();
        this.props.MapaKseg.setState({sidenav_visible:true});

        if(this.props.Modals.state.GridColetaEntrega != null)
            this.props.Modals.state.GridColetaEntrega.visibleFilterrow();
    };
    //--------------------
    maximizarModal = () =>{
        var grid_h = String(window.innerHeight - 150)+"px";
        this.setState({width: window.innerWidth
                        ,height: window.innerHeight
                        ,drag: "drag0"
                        ,grid_height: grid_h});
    };
    //--------------------
    restoreModal = () =>{
        var grid_h = String(400)+"px";
        this.setState({width: 1000
                        ,height: 520
                        ,drag: "drag3"
                        ,grid_height: grid_h});                     
    };
    //---------close----------
    fecharModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "zoom"});
        this.setState({minimizar:false});
        if(this.props.tipo_chamada=== TIPO_CHAMADA_POSICOES)
            this.props.SideNavDrawer.relPosicoesVeiModalClose();
        else if(this.props.tipo_chamada=== TIPO_CHAMADA_EVENTOS)
            this.props.SideNavDrawer.relEventosVeiModalClose();
        this.props.MapaKseg.setState({sidenav_visible:true});    

        if(this.props.Modals.state.GridColetaEntrega != null)
            this.props.Modals.state.GridColetaEntrega.visibleFilterrow();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false){
            if(this.props.tipo_chamada=== TIPO_CHAMADA_POSICOES){
                if(this.state.minimizar)
                    this.props.SideNavDrawer.relPosicoesVeiModalClose();
                else this.props.SideNavDrawer.relPosicoesVeiModalDesativar();    
            }else if(this.props.tipo_chamada=== TIPO_CHAMADA_EVENTOS){
                if(this.state.minimizar)
                    this.props.SideNavDrawer.relEventosVeiModalClose();
                else this.props.SideNavDrawer.relEventosVeiModalDesativar(); 
            }
        }     
    };
    //---------------------
    setIcone = (tipo_evento_id) =>{    
        switch(tipo_evento_id) {
            case 1:
            return require("./img/location_marker48.png"); 
            case 3:
                return require("./img/ic_marker_bloqueio.png");                                           
            case 42:
                return require("./img/ic_antenna_black_48.png"); 
            case 66:
                return require("./img/key_off.png");                              
            case 67:
                return require("./img/card_green48.png"); 
            case 68:
                return require("./img/marker_porta_aberta36.png");  
            case 69:
                return require("./img/marker_porta_lateral_aberta2.png");  
            case 70:
                return require("./img/alert_octagon24x24.png");                     
            case 71:
                return require("./img/ic_antenna_black_48.png");
            case 74:
                return require("./img/marker_truck_open.png");                
            default:
            return require("./img/alert_circle48.png");  
        }
    };
    //------------------------
    //----------------------------------------------------
    dateDiff=(data_inicio, data_final)=>{
        // data_inicio: sex 21:47:10   03-07-2020 
        if(String(data_inicio).length> 19){
            var ano_anterior = data_inicio.substring(21,25);
            var mes_anterior = data_inicio.substring(18,20);
            var dia_anterior = data_inicio.substring(15,17);
            var hora_anterior = data_inicio.substring(4,12);
            var a = moment(ano_anterior+"-"+mes_anterior+"-"+dia_anterior+"T"+hora_anterior+"-03:00");  //2020-03-17T17:39:31-03:00
    
            var ano = data_final.substring(21,25);
            var mes = data_final.substring(18,20);
            var dia = data_final.substring(15,17);
            var hora = data_final.substring(4,12);
            var b = moment(ano+"-"+mes+"-"+dia+"T"+hora+"-03:00");  //2020-03-17T17:39:31-03:00
            const precise_diff =  moment.preciseDiff(a, b, true); 
        
            const diff = b.diff(a);
            const diffDuration = moment.duration(diff);
            const diff_minutes = parseInt(diffDuration.asMinutes())
            // const diff_days = parseInt(diffDuration.asDays())
        
            // // alert(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes");
            // alert(diff_minutes + " minutes");
        
            // console.log("****  diff_minutes: "+String(diff_minutes));
        
            // return String(ano_anterior+"-"+mes_anterior+"-"+dia_anterior+"T"+hora_anterior+"-03:00");
            // return "("+String(diff_minutes)+"min)";
        
            if(precise_diff.minutes===0)
                if(precise_diff.seconds===0)
                    return "0";
                else return precise_diff.seconds+"seg";
            else if(precise_diff.hours === 0)
                return precise_diff.minutes+"min";
            else if(precise_diff.days === 0){
                if(precise_diff.hours===1)
                return String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
                else return String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
            }else if(precise_diff.months === 0){
                if(precise_diff.days===1)
                return String(precise_diff.days)+"dia "+String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
                else return String(precise_diff.days)+"dias "+String(precise_diff.hours)+"hr "+precise_diff.minutes+"min";
            }else if(precise_diff.years === 0){
                if(precise_diff.months===1)
                return String(precise_diff.months)+"mes "+String(precise_diff.hours)+"dia "+String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
                else return String(precise_diff.months)+"meses "+String(precise_diff.hours)+"dia "+String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min";
            }
        }
    }
    //------------------------
    render(){
        var data_hoje = new Date();
        var data_ontem = new Date();
        data_ontem.setDate(data_ontem.getDate() - 1);
        // data_ontem.setHours(data_ontem.getHours() - 1);

        const orders = [{
            'ID': 1,
            'recebimento_id': 35703,
            'data_receb': '2017/04/13 9:00',
            'latitude': 11800,
            'longitude': 21800,
            'backup': false
          }, {
            'ID': 4,
            'recebimento_id': 35711,
            'data_receb': '2017/01/13 9:00',
            'latitude': 16050,
            'longitude': 12300,
            'backup': false
          }];

        //   const options = { 
        //     fieldSeparator: ',',
        //     quoteStrings: '"',
        //     decimalSeparator: '.',
        //     showLabels: true, 
        //     showTitle: true,
        //     title: 'Stations',
        //     useTextFile: false,
        //     useBom: true,
        //     useKeysAsHeaders: true,
        //     // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        //   };


        //   const [transactionData, setTransactionData] = useState([]);
        //   const csvLink = useRef(); // setup the ref that we'll use for the hidden CsvLink click once we've updated the data


        //   const getTransactionData = async () => {
        //     // 'https://kseg.net.br/relatorio_api/posicoesVeiculoData'
        //     // 'api' just wraps axios with some setting specific to our app. the important thing here is that we use .then to capture the table response data, update the state, and then once we exit that operation we're going to click on the csv download link using the ref
        //     await api.post('/relatorio_api/posicoesVeiculoData', { grupo_id: localStorage.getItem('grupo_id'),
        //     veiculo_id: this.state.veiculo_id,
        //     data_inicial: this.state.data_inicial,
        //     data_final: this.state.data_final,
        //     token: localStorage.getItem('token'),
        //     temperaturas_tab: this.state.veiculo_temperaturas_tab})
        //       .then((r) => setTransactionData(r.data))
        //       .catch((e) => console.log(e))
        //     csvLink.current.link.click()
        //   }

        // const dataTocsv = async () => {
        //     const csvExporter = new ExportToCsv(options);
        //     csvExporter.generateCsv(orders);
        // }

        //----

        // const csvConfig = mkConfig({ useKeysAsHeaders: true });

        // const addNewLine = (s: string): string => s + "\n";

        // const mockData = [
        // {
        //     name: "Rouky",
        //     date: "2023-09-01",
        //     percentage: 0.4,
        //     quoted: '"Pickles"',
        // },
        // {
        //     name: "Keiko",
        //     date: "2023-09-01",
        //     percentage: 0.9,
        //     quoted: '"Cactus"',
        // },
        // ];

        // Converts your Array<Object> to a CsvOutput string based on the configs
        // const csvOutput = generateCsv(csvConfig)(mockData);

        return(
            <div className={this.state.drag} >
                <Draggable   handle="strong"  >                
                    <div >                    
                        <Rodal 
                            // visible={this.props.SideNavDrawer.state.relPosicoesVeiModalShow}
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={this.state.width}
                            height={this.state.height}
                            onAnimationEnd={()=>this.desativarModal()}
                            >  
                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={true} maximizar={true} titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                            {/* {<DtGrid veiculo_id={666} orders={orders} />} */}

                                            {/* <FilterSelect list={this.props.filter_list} tipo={2} /> */}

                                                


                                                {/* --------- */}
                                                {/* <Navbar bg="light" variant="light">
                                                    
                                                    <Nav className="mr-auto">
                                                        <div className="form-group mb-2">
                                                            <FilterSelect className="headerDateTime" list={this.props.filter_list} tipo={2} />
                                                        </div>

                                                        
                                                    </Nav>
                                                    <Form inline>
                                                    

                                                

                                                    Data inicial: 
                                                    <DateBox className="headerDateTime" defaultValue={this.now} type="datetime" />    
                                                    Data final: 
                                                    <DateBox className="headerDateTime" defaultValue={this.now} type="datetime" onValueChanged={this.onValueChanged}/> 
                                                    <Button variant="outline-primary">Pesquisar</Button>
                                                    </Form>
                                                </Navbar> */}


                                                {/* <FormControl type="text" placeholder="Search" className="mr-sm-2" /> */}


                                                <Form inline className="espaco_bottom"> 
                                                    <div className="headerSearch"> 
                                                        <FilterSelect list={this.props.filter_list} tipo={2} RelPosicoesVeiModal={this} />
                                                    </div>
                                                
                                                    <DateBox displayFormat="dd/MM/yyyy HH:mm" placeholder="Dada inicial" 
                                                        className="headerDateTime"  type="datetime" 
                                                        onValueChanged={this.onValueChangedDataInicial}
                                                        defaultValue={data_ontem} />    
                                                    
                                                    <DateBox displayFormat="dd/MM/yyyy HH:mm" placeholder="Data final" 
                                                        className="headerDateTime"  type="datetime" 
                                                        onValueChanged={this.onValueChangedDataFinal}
                                                        defaultValue={data_hoje}/> 

                                                    <div className="espaco_esq">

                                                        {/* <div>
                                                            <Button onClick={getTransactionData}>Download transactions to csv</Button>
                                                            <CSVLink
                                                                data={transactionData}
                                                                filename='transactions.csv'
                                                                className='hidden'
                                                                ref={csvLink}
                                                                target='_blank'
                                                            />
                                                        </div> */}
                                                        

                                                        {/* <Button variant="primary" onClick={csvOutput}> */}
                                                        {/* <Button variant="primary" onClick={dataTocsv}> */}
                                                            {/* <img src={"img/lupa.png"} alt="" Width="20" /> */}
                                                        {/* </Button> */}


                                                        {/* <CSVLink
                                                            data={this.dataFromListOfUsersState()}
                                                            asyncOnClick={true}
                                                            // onClick={()=>this.getUsers1}
                                                            onClick={()=>this.getUsers4}
                                                            >
                                                            {this.state.loading ? 'Loading csv...' : 'Download me'}
                                                        </CSVLink> */}

                                                        <Button variant="primary" onClick={()=>this.onClickPesquisar()}>
                                                            <img src={"img/lupa.png"} alt="" Width="20" />
                                                        </Button>

                                                        
                                                    </div>

                                                </Form>


                                                {/* <div className="dx-field"> */}
                                                    {/* <div className="dx-field-label">Date and time</div> */}
                                                    {/* <div className="dx-field-value"> */}
                                                    {/* <DateBox defaultValue={this.now} type="datetime" /> */}
                                                    {/* </div> */}

                                                {/* --------- */}


                                                {this.state.btnPesquisar ?
                                                    this.renderData()
                                                :<div className={"espaco_bottom2"}></div>}

                                                {/* { this.state.loading ? 
                                                        <div className="centro">
                                                            <Spinner animation="border" />
                                                        </div>

                                                        : <DtGrid veiculo_id={666} orders={this.state.list} />
                                                    } */}


                                                {/* { this.state.loading ? 
                                                    <div className="centro">
                                                        <Spinner animation="border" />
                                                    </div>

                                                    : this.state.list.map((posicao) =>
                                                            <div>
                                                            <ListGroup>
                                                                <ListGroup.Item action variant="">
                                                                    <div>
                                                                        {String(posicao.backup)}
                                                                    </div>
                                                                </ListGroup.Item>
                                                            </ListGroup>
                                                            </div> 
                                                        )
                                                } */}

                                        
                                </div>


                        
                            </div>

                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>

            
        );
    }
}    