import  {Component} from 'react';
import api from './api';
import {ToastStore} from 'react-toasts';
import Moment from 'moment';

// import {Polyline} from 'google-maps-react';

export default class GetGruposPoligono extends Component{
    constructor(props, objeto){
        super(props);
        this.state = {
             grupo: {},
             list: [],
             loading:true,
             objeto:objeto,
             usuario_id: props,
       }
    }
    //---------------------
    toastStore = (texto) => {
        ToastStore.success("teste: "+texto);
    };
    //--------------------
    getGruposPoligono = async () => {
        try {
            // ToastStore.success("usuario_id: "+String(this.state.usuario_id));
            let body = new FormData()
            body.append('usuario_id', this.state.usuario_id)
            body.append('token', localStorage.getItem('token'))

            // console.log("  ***datetime: "+datetime)

            const response = await api.post('/poligono_api/getGrupoPoligonoUsuario', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);

                
                let grupo = this.state.grupo;
                let list = this.state.list;                

                // ToastStore.success("resposta.length: "+ String(resposta.length));
                for (var i = 0; i < resposta.length; i++) {                        
                    grupo = {    
                        grupo_id: resposta[i].grupo_id,
                        nome: resposta[i].nome,
                        tipo_grupo_id: resposta[i].tipo_grupo_id,
                        selecionada: false,
                        index_scroll: (i+2)*25,
                    };

                    if(localStorage.getItem('grupo_id') === String(grupo.grupo_id) )
                        grupo.selecionada=true;

                    list.push(grupo);
                    this.setState({list: list});
                }

                this.setState({loading: false});
                
            }else{
            }

        } finally {
                // ToastStore.success("GetPo coords.length: "+ String(coords.length));
                // ToastStore.success("PPlaca: "+ String(lastPosition.data_receb));
                // this.state.objeto.setCoords(coords, lastPosition, markerEvento_list);
                this.state.objeto.setState({gruposPoligono_list: this.state.list});

                let height_modal = 120;
                // if(this.state.list.length === 2)
                //     height_modal = 160;
                // if(this.state.list.length > 2)
                //     height_modal = 55 * this.state.list.length;
                if(this.state.list.length < 6 && this.state.list.length > 1)
                    height_modal = 80 * this.state.list.length;
                else if(this.state.list.length >= 6)
                    height_modal = 35 * this.state.list.length;

                // this.state.objeto.props.MapaKseg.toast(this.state.list[0].nome);

                let grupos_list =[];
                for (var i = 0; i < this.state.list.length; i++)
                    grupos_list.push(this.state.list[i].nome);

                this.state.objeto.setState({height_modal: height_modal}); 
                if(this.state.list.length > 0){
                    this.state.objeto.setState({loading: false, filterStatus: this.state.list[0].nome, grupo_id: this.state.list[0].grupo_id, grupos_list: grupos_list});
                    this.state.objeto.getPoligonos();
                }
                // this.state.objeto.props.MapaKseg.toast(this.state.objeto.state.grupo_id+"  "+this.state.objeto.state.filterStatus);

            }
    };
    //--------------------------
    async componentDidMount() {
        // ToastStore.success("getGruposVeiculo componentDidMount()");
        this.getGruposPoligono();
    };
}