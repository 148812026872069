import React, {Component} from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import {ComandoModal} from './ComandoModal';
import GetComandoVeiculo from './GetComandoVeiculo';
 
export class RenderComandos extends Component{
    constructor(props){
        super(props);
        this.state = {
            comando: {},
            comandoConfiguracao_list: [],
            comandosVei_list: [],
            list: [],
            loading:true,
            comandos_list: [],
            comandoModalShow:false,
       }
    }
    //--------------------
    showComandoModal = () => {
        this.setState({comandoModalShow:true});
    };
    //-------------------
    comandoModalClose = () =>{
        this.setState({comandoModalShow:false});
    };  
    //---------------------
    renderComandoModal = () => {
        return(
            <div>
                <ComandoModal  
                    show={this.state.comandoModalShow}
                    onHide={this.comandoModalClose}
                    comando={this.state.comando}
                    comandoConfiguracao_list = {this.state.comandoConfiguracao_list}
                    comandosVei_list = {this.state.comandosVei_list}
                    MapaKseg = {this.props.MapaKseg}
                />
            </div>);
    };
    //---------------------
    comandoClick = (comando) => {
        // this.props.MapaKseg.callToast(comando.tipo_comando_id+" - "+comando.descricao);
        this.setState({comando: comando});
        // this.showComandoModal();

        var instance = new GetComandoVeiculo(comando, this); 
        instance.getComandoVei();
    };
    //---------------------
    render(){
        return (

            <Container>

                {this.state.comandoModalShow ? this.renderComandoModal() : ""}

                <Row>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >0 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[0])}> <img src={this.props.MapaKseg.state.comandos_list[0].icone} /> <p>{this.props.MapaKseg.state.comandos_list[0].descricao} </p> </Button> : "" }</Col>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >1 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[1])}> <img src={this.props.MapaKseg.state.comandos_list[1].icone} /> <p>{this.props.MapaKseg.state.comandos_list[1].descricao} </p> </Button>  : "" }</Col>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >2 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[2])}> <img src={this.props.MapaKseg.state.comandos_list[2].icone} /> <p>{this.props.MapaKseg.state.comandos_list[2].descricao} </p> </Button>  : "" }</Col>
                </Row>
                <Row>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >3 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[3])}> <img src={this.props.MapaKseg.state.comandos_list[3].icone} /> <p>{this.props.MapaKseg.state.comandos_list[3].descricao} </p> </Button> : "" }</Col>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >4 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[4])}> <img src={this.props.MapaKseg.state.comandos_list[4].icone} /> <p>{this.props.MapaKseg.state.comandos_list[4].descricao} </p> </Button>  : "" }</Col>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >5 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[5])}> <img src={this.props.MapaKseg.state.comandos_list[5].icone} /> <p>{this.props.MapaKseg.state.comandos_list[5].descricao} </p> </Button>  : "" }</Col>
                </Row>
                <Row>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >6 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[6])}> <img src={this.props.MapaKseg.state.comandos_list[6].icone} /> <p>{this.props.MapaKseg.state.comandos_list[6].descricao} </p> </Button> : "" }</Col>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >7 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[7])}> <img src={this.props.MapaKseg.state.comandos_list[7].icone} /> <p>{this.props.MapaKseg.state.comandos_list[7].descricao} </p> </Button>  : "" }</Col>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >8 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[8])}> <img src={this.props.MapaKseg.state.comandos_list[8].icone} /> <p>{this.props.MapaKseg.state.comandos_list[8].descricao} </p> </Button>  : "" }</Col>
                </Row>
                <Row>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >9 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[9])}> <img src={this.props.MapaKseg.state.comandos_list[9].icone} /> <p>{this.props.MapaKseg.state.comandos_list[9].descricao} </p> </Button> : "" }</Col>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >10 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[10])}> <img src={this.props.MapaKseg.state.comandos_list[10].icone} /> <p>{this.props.MapaKseg.state.comandos_list[10].descricao} </p> </Button>  : "" }</Col>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >11 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[11])}> <img src={this.props.MapaKseg.state.comandos_list[11].icone} /> <p>{this.props.MapaKseg.state.comandos_list[11].descricao} </p> </Button>  : "" }</Col>
                </Row>
                <Row>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >12 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[12])}> <img src={this.props.MapaKseg.state.comandos_list[12].icone} /> <p>{this.props.MapaKseg.state.comandos_list[12].descricao} </p> </Button> : "" }</Col>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >13 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[13])}> <img src={this.props.MapaKseg.state.comandos_list[13].icone} /> <p>{this.props.MapaKseg.state.comandos_list[13].descricao} </p> </Button>  : "" }</Col>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >14 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[14])}> <img src={this.props.MapaKseg.state.comandos_list[14].icone} /> <p>{this.props.MapaKseg.state.comandos_list[14].descricao} </p> </Button>  : "" }</Col>
                </Row>
                <Row>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >15 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[15])}> <img src={this.props.MapaKseg.state.comandos_list[15].icone} /> <p>{this.props.MapaKseg.state.comandos_list[15].descricao} </p> </Button> : "" }</Col>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >16 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[16])}> <img src={this.props.MapaKseg.state.comandos_list[16].icone} /> <p>{this.props.MapaKseg.state.comandos_list[16].descricao} </p> </Button>  : "" }</Col>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >17 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[17])}> <img src={this.props.MapaKseg.state.comandos_list[17].icone} /> <p>{this.props.MapaKseg.state.comandos_list[17].descricao} </p> </Button>  : "" }</Col>
                </Row>
                <Row>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >18 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[18])}> <img src={this.props.MapaKseg.state.comandos_list[18].icone} /> <p>{this.props.MapaKseg.state.comandos_list[18].descricao} </p> </Button> : "" }</Col>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >19 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[19])}> <img src={this.props.MapaKseg.state.comandos_list[19].icone} /> <p>{this.props.MapaKseg.state.comandos_list[19].descricao} </p> </Button>  : "" }</Col>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >20 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[20])}> <img src={this.props.MapaKseg.state.comandos_list[20].icone} /> <p>{this.props.MapaKseg.state.comandos_list[20].descricao} </p> </Button>  : "" }</Col>
                </Row>
                <Row>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >21 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[21])}> <img src={this.props.MapaKseg.state.comandos_list[21].icone} /> <p>{this.props.MapaKseg.state.comandos_list[21].descricao} </p> </Button> : "" }</Col>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >22 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[22])}> <img src={this.props.MapaKseg.state.comandos_list[22].icone} /> <p>{this.props.MapaKseg.state.comandos_list[22].descricao} </p> </Button>  : "" }</Col>
                    <Col>{this.props.MapaKseg.state.comandos_list.length >23 ? <Button variant="light" onClick={() => this.comandoClick(this.props.MapaKseg.state.comandos_list[23])}> <img src={this.props.MapaKseg.state.comandos_list[23].icone} /> <p>{this.props.MapaKseg.state.comandos_list[23].descricao} </p> </Button>  : "" }</Col>
                </Row>
            </Container>
        );
    }
}