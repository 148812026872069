import React, {Component} from 'react';
import {Modal, Form, Button as Button_bootstrap, Row, Col, Spinner, Carousel, Navbar, Container, Image} from 'react-bootstrap';
import '../estilo.css';
import api from '../api';
import CustomScroll from 'react-customscroll';
import { Switch } from 'devextreme-react/switch';
// import { parseString} from "xml2js";
// import service from './data.js';


export  class PagoAtualizarModal extends Component{
    constructor(props) {
        super(props);
        this.state = { 
            salvando: false,
            item: {id: 0, is_coleta: false, pago: false, cliente:'', local:'', data_previsao2:'', tipo_pagamento:'', valor:''},
            activeIndexCarousel:0,
            height_modal:300,
            enable_salvar:false,
            post_concluido:false,
        };
      }   
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closePagoAtualizarModal();
    };
    //------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarPagoAtualizarModal();
    };
    //--------------------------
    async componentDidMount() {
        // this.props.MapaKseg.toastError(this.props.obj_selected.canhoto+" - "+this.props.obj_selected.canhoto64);
        // console.log(this.props.obj_selected);
        let _item = this.state.item;
        _item.is_coleta = this.props.obj_selected.is_coleta;
        _item.id = this.props.obj_selected.id;
        _item.pago = this.props.obj_selected.pago;
        _item.coleta_id = this.props.obj_selected.coleta_id;
        _item.cliente = this.props.obj_selected.cliente;
        _item.local = this.props.obj_selected.local;
        _item.data_previsao2 = this.props.obj_selected.data_previsao2;
        _item.tipo_pagamento = this.props.obj_selected.tipo_pagamento;
        _item.valor = this.props.obj_selected.valor;

        this.setState({item: _item});

        if(this.props.tipo === "visualizar"){
                this.setState({activeIndexCarousel:0});
        }else this.setState({activeIndexCarousel:1});

        // this.setState({texto: this.props.texto, tipo: this.props.tipo});
        // document.getElementById("ControlTextArea1").focus();
        
    };

    //---------------------------------------------------------------
    postSalvar = async () => {
        try {       
            let body = new FormData()
            body.append('is_coleta', this.state.item.is_coleta);
            body.append('id', this.state.item.id);
            body.append('pago', this.state.item.pago);
            body.append('token', localStorage.getItem('token'));

            let response = await api.post('/coletaweb_api/postPago', body);
            // let response = await api.post('/teste_api/getEntregas', body);
            
            var responseJson = null;

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);                                                
                this.setState({post_concluido: true});
            }
        } finally {      
            this.setState({salvando: false});
            this.updateList();
        }
    };
    //------------------------
    salvar = async()=>{
        this.setState({salvando:true, enable_salvar:false}); 
        this.postSalvar(); 
    };
    //--------------------------------
    updateList= () => {
        let _list = this.props.objeto.state.list;

        if(this.state.post_concluido === true){
            for(var i=0; i < _list.length;i++){
                if((_list[i].is_coleta === this.state.item.is_coleta) && (_list[i].id === this.state.item.id)){
                    _list[i].pago = this.state.item.pago;
                    if(this.state.item.pago === true) _list[i].pago_str = "Foi Pago";
                    else _list[i].pago_str = "Não Pago";

                    break;
                }
            }
        }else alert("Não foi possível concluir!");

        this.props.objeto.setState({list: _list},
            () => { this.props.objeto.refreshDataGrid();
                    this.fecharModal();  });
    }
    //---------------------------------
    setPago= (e) => {
        let item = this.state.item;
        item.pago = e.value;

        this.setState({item: item, enable_salvar:true});
    }
    //------------------------- 
    render(){
        return(
            <div className="splashscreen-modal1">
                <Modal  centered
                    {...this.props}
                    backdrop="static"
                    
                    aria-labelledby="example-custom-modal-styling-title"
                    scrollable="True"
                    dialogClassName="temperatura-modal2"    
                    >
                    <div >
                        <strong className="cursor">
                            <div className="cabecalho"> 
                            <label className="titulo2" > {this.props.titulo} </label>
                                {/* <label className="titulo2" > Aviso! </label> */}
                            </div>
                        </strong>

                        <div style={{height: this.state.height_modal, overflow:'hidden'}}>
                            <CustomScroll  scrollWidth="15px" scrollBarRadius="0">                                         
                                <Form className="espaco3">                     
                                    <Navbar  variant="light">
                                        <Container>
                                        <Navbar.Brand href="#"></Navbar.Brand>
                                        </Container>
                                    </Navbar>                                          

                                    <Form.Group as={Row} className="mb-1" controlId="formLocalColeta">
                                        <Form.Label column sm={1}>
                                            <b>Cliente</b> 
                                        </Form.Label> 
                                        <Col sm={10}>
                                            <Form.Label column >
                                                {this.state.item.cliente}
                                            </Form.Label> 
                                        </Col>                                                       
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-1" controlId="formLocalColeta">
                                        <Form.Label column sm={1}>
                                            <b>Local</b> 
                                        </Form.Label> 
                                        <Col sm={10}>
                                            <Form.Label column >
                                                {this.state.item.local}
                                            </Form.Label> 
                                        </Col>                                                       
                                    </Form.Group>       

                                    <Form.Group as={Row} className="mb-1" controlId="formPrevisaoColeta">
                                        <Form.Label column sm={2}>
                                            <b>Previsão</b>
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Label column >
                                                {this.state.item.data_previsao2}
                                            </Form.Label> 
                                        </Col>                                                        
                                    </Form.Group>                                                                                   

                                    <Form.Group as={Row} className="mb-1" controlId="formPagamento">
                                        <Form.Label column sm={2}>
                                            <b>Pagamento</b>
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label column >
                                                {this.state.item.tipo_pagamento}
                                            </Form.Label> 
                                        </Col>                                                          
                                    </Form.Group>                                                                 

                                    <Form.Group as={Row} className="mb-1" controlId="formValor">
                                        <Form.Label column sm={2}>
                                            <b>Valor_R$</b>
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Form.Label column >
                                                {this.state.item.valor}
                                            </Form.Label> 
                                        </Col>                                                         
                                    </Form.Group> 

                                    <Form.Group as={Row} className="mb-1" controlId="formValor">
                                        <Form.Label column sm={2}>
                                            <b>Pago</b>
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Switch className="padding_top" defaultValue={this.state.item.pago} value={this.state.item.pago}  switchedOnText={"Sim"} switchedOffText={"Não"} onValueChanged={this.setPago}/>                                                             
                                        </Col>                                                         
                                    </Form.Group>                                                                                                
                                </Form>                                                                                           
                            </CustomScroll>
                        </div>                                                                       
                    </div>


                    <Modal.Footer>                               
                        <Button_bootstrap disabled={!this.state.enable_salvar} onClick={this.salvar}>
                            {this.state.salvando
                                ? <Spinner as="span" animation="grow" role="status" aria-hidden="true" size="sm"/>                                                                
                                : <div> <img src={require('../img/checkbold24.png')}/> Salvar </div>
                            }                                                                
                        </Button_bootstrap>

                        <Button_bootstrap variant="outline-secondary" onClick={this.fecharModal}> Fechar</Button_bootstrap>                
                    </Modal.Footer>

                </Modal>

            </div>
        );
    }
}