import  {Component} from 'react';
import api from '../api';

export default class PostUsuarioCad extends Component{
    constructor(props, objeto, MapaKseg ){
        super(props);
        this.state = {
             objeto: objeto,
             MapaKseg: MapaKseg
       }
    }
    //------------------------------------------------
    post = async () => {
        let usuario_idd= 0;
        try {        
            let editados = this.state.objeto.state.editados;
            let usuario = this.state.objeto.state.usuario;
            usuario_idd = usuario.usuario_id;

            let response = null;
            let responseJson = null;
            let body =null;            

            if(editados.nome || editados.login || editados.senha || editados.pessoa || editados.ativo || editados.tipo_usuario){

                body = new FormData();
                body.append('token', localStorage.getItem('token'));
                body.append('usuario_id', usuario.usuario_id);
                if(usuario.grupos.length > 0) body.append('grupo_id_atual', usuario.grupos[0].grupo_id);
                else body.append('grupo_id_atual', "3");  // 3 - Vazio

                if(editados.nome) body.append('nome', usuario.nome);
                if(editados.login) body.append('login', usuario.login);
                if(editados.senha) body.append('senha', usuario.senha);
                if(editados.pessoa) body.append('pessoa_id', usuario.pessoa_id);
                if(editados.ativo) body.append('ativo', usuario.ativo);
                if(editados.tipo_usuario) body.append('tipo_usuario_id', usuario.tipo_usuario_id);

                response = await api.post('/cadastro_api/postUsuarioPessoa', body);

                if (response.data !== null  &&  response.status !== 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json); 
                    usuario_idd = resposta.usuario_id; 
                }

            }
             
            if(usuario_idd != 0){
                if(editados.grupos==true || editados.areas==true){
                    let grupos = usuario.grupos.filter((grupo,index) =>{ return grupo.operacao !== 0;});
                    
                    if(editados.areas) grupos = usuario.areas.filter((grupo,index) =>{ return grupo.operacao !== 0;});

                    if(grupos.length >0){
                        grupos.sort((a,b) => a.operacao - b.operacao);
                        let list_json =[];

                        for (var i = 0; i < grupos.length; i++) {   
                            list_json.push(JSON.stringify({
                                grupo_id: grupos[i].grupo_id
                                , usuario_id: usuario_idd
                                , operacao: grupos[i].operacao }));
                        }

                        body = new FormData();
                        body.append('token', localStorage.getItem('token'));
                        body.append('list_json', list_json);

                        response = await api.post('/cadastro_api/postUsuarioGrupos', body);

                        if (response.data !== null  &&  response.status !== 403){
                        responseJson = JSON.stringify(response.data)
                        var req = { json: responseJson };
                        var resposta = JSON.parse(req.json); 
                            // console.log(resposta.poligono_id);
                            // var coleta_id = resposta.coleta_id;    
                            // nova_coleta.coleta_id = resposta.resposta; 
                        // this.state.MapaKseg.toast(" "+String(resposta.resposta ));

                        }
                    }
                }

                if(editados.tabs){                    
                    let tabs = usuario.tabs.filter((tab,index) =>{ return tab.operacao !== 0;});

                    if(tabs.length >0){
                        tabs.sort((a,b) => a.operacao - b.operacao);
                        let list_json =[];
                        for (var i = 0; i < tabs.length; i++) {   
                            list_json.push(JSON.stringify({
                                tab_id: tabs[i].tab_id
                                , usuario_id: usuario_idd
                                , principal: String(tabs[i].principal)
                                , operacao: tabs[i].operacao }));
                        }

                        body = new FormData();
                        body.append('token', localStorage.getItem('token'));
                        body.append('list_json', list_json);

                        response = await api.post('/cadastro_api/postUsuarioTabs', body);

                        // if (response.data !== null  &&  response.status !== 403){
                        // responseJson = JSON.stringify(response.data)
                        // var req = { json: responseJson };
                        // var resposta = JSON.parse(req.json); 
                        //     // console.log(resposta.poligono_id);
                        //     // var coleta_id = resposta.coleta_id;    
                        //     // nova_coleta.coleta_id = resposta.resposta; 
                        // // this.state.MapaKseg.toast(" "+String(resposta.resposta ));

                        // }
                    }
                }   

                if(editados.rotas){                    
                    let rotas = usuario.rotas.filter((rota,index) =>{ return rota.operacao !== 0;});

                    if(rotas.length >0){
                        rotas.sort((a,b) => a.operacao - b.operacao);
                        let list_json =[];
                        for (var i = 0; i < rotas.length; i++) {   
                            list_json.push(JSON.stringify({
                                rota_id: rotas[i].rota_id
                                , usuario_id: usuario_idd
                                , operacao: rotas[i].operacao }));
                        }

                        body = new FormData();
                        body.append('token', localStorage.getItem('token'));
                        body.append('list_json', list_json);

                        response = await api.post('/cadastro_api/postUsuarioRotas', body);
                    }
                }  

                if(editados.filiais){                    
                    let filiais = usuario.filiais.filter((filial,index) =>{ return filial.operacao !== 0;});

                    if(filiais.length >0){
                        filiais.sort((a,b) => a.operacao - b.operacao);
                        let list_json =[];
                        for (var i = 0; i < filiais.length; i++) {   
                            list_json.push(JSON.stringify({
                                filial_id: filiais[i].filial_id
                                , usuario_id: usuario_idd
                                , operacao: filiais[i].operacao }));
                        }

                        body = new FormData();
                        body.append('token', localStorage.getItem('token'));
                        body.append('list_json', list_json);

                        response = await api.post('/cadastro_api/postUsuarioFiliais', body);
                    }
                }  
            }

        } finally {      
            let usuario = this.state.objeto.state.usuario;
            usuario.usuario_id = usuario_idd;
            this.state.objeto.setState({usuario: usuario});
            this.state.objeto.concluido();
            }
    };
}