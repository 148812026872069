import  {Component} from 'react';
import api from './api';
import {GetEventos} from './services/GetEventos';

var moment = require('moment');
var lastPosition=null;
var coords=[];
var markerEvento_list=[];
var body =null;
var polyline_list=[];


const TIPO_CHAMADA_PLUSCLICK = 0;
const TIPO_CHAMADA_MARKERCLICK_VEICULO = 1;
const TIPO_CHAMADA_RECARREGARAUTOMATICO_VEI_SELECIONADO = 2;
const TIPO_CHAMADA_RECARREGAR_CLICK = 3;
// import {Polyline} from 'google-maps-react';


export class GetPosicoes extends Component{
    constructor(props, MapaKseg, api_url, body, tipo_chamada, GetPosicoesVeiculo, GridVeiculoDetail){
        super(props);
        this.state = {
            veiculo: props,
            posicao: {},
            list: [],
            loading:true,
            grupo_id: MapaKseg.state.grupo_id,
            MapaKseg:MapaKseg,
            markerEvento_list: [],
            tipo_chamada: tipo_chamada,
            api_url: api_url,
            body: body,
            GetPosicoesVeiculo: GetPosicoesVeiculo,
            GridVeiculoDetail: GridVeiculoDetail,
            eventos_count: [],
       }
    }
    //--------------------
    formatarDateTimeGrid(dtTime){
        //  sex 21:47:10   03-07-2020  
        if(dtTime===null)
           return "";

       const diaSema = String(dtTime).substring(0,3);
       const y = String(dtTime).substring(23,25);
       const mm = String(dtTime).substring(18,20);
       const dd = String(dtTime).substring(12,17);
       const hm = String(dtTime).substring(4,12);    
       const datetime = diaSema+", "+dd+"/"+mm+"/"+y+"   "+hm;  // "Y-MM-DD H:M"
       return datetime;  
    }
   //-----------------
    //--------------------
    getPosicoes = async () => {
        // console.log("  ***this.veiculo.data_receb: "+this.state.veiculo.data_receb)
        try {
            // const date = Date(this.veiculo.data_receb);
            // sex 14:09:34 08-11-2019
            let y = this.state.veiculo.data_receb.substring(21,25);
            let mm = this.state.veiculo.data_receb.substring(18,20);
            let dd = this.state.veiculo.data_receb.substring(15,17);
            let hm = this.state.veiculo.data_receb.substring(3,9);
            
            let datetime = moment(y+"-"+mm+"-"+dd+" "+hm+"-03:00").format('Y-MM-DD HH:mm');
            // console.log(" joined dateTime 2: "+String(datetime));

            let body = new FormData();
            body.append('grupo_id', this.state.grupo_id);
            body.append('veiculo_id', this.state.veiculo.veiculo_id);
            body.append('data_final', String(datetime));
            body.append('token', localStorage.getItem('token'));

            // console.log("  ***datetime: "+datetime)

            // const response = await api.post('/posicoesVeiculoData', body);
            let response = await api.post(this.state.api_url, this.state.body);
            // coords = [];
            // polyline_list = this.state.MapaKseg.state.polyline_list;
            coords.push({lat: this.state.veiculo.latitude, lng: this.state.veiculo.longitude});
            // var markerEvento_list = []; // = this.state.markerEvento_list;
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);

            
                let posicao = this.state.posicao;
                let list = this.state.list;             
                // list.push(this.state.veiculo);   
                // this.setState({list: list});
                // ToastStore.success("resposta.length: "+ String(resposta.length));
                // for (var i = resposta.length-1; i >= 0; i--) {
                for (var i = 0; i < resposta.length; i++) {
                    posicao = {
                        id:i+1,
                        recebimento_id: resposta[i].recebimento_id,
                        veiculo_id: resposta[i].veiculo_id,
                        placa: this.state.placa,
                        municipio: resposta[i].municipio,
                        uf: resposta[i].uf,
                        estado: resposta[i].estado,
                        pais: resposta[i].pais,
                        velocidade: resposta[i].velocidade,
                        velocidade2:null,
                        tipo_recebimento_id: resposta[i].tipo_recebimento_id,
                        tecnologia_id: resposta[i].tecnologia_id,
                        data_receb: resposta[i].data_cadastro,
                        data_receb2: resposta[i].data_receb,
                        data_format: this.formatarDateTimeGrid(resposta[i].data_cadastro),
                        latitude: resposta[i].latitude,
                        longitude: resposta[i].longitude,               
                        latlng: resposta[i].latitude+", "+resposta[i].longitude,      
                        backup: "",
                        ignicao: resposta[i].ignicao,
                        mensagem_externo_id: resposta[i].mensagem_externo_id,
                        direcao: resposta[i].direcao,
                        eventos_count:0,
                        eventos_count_icon: null,
                        };

                    // ToastStore.success("direcao: "+ String(posicao.direcao));
                    // console.log(" posicao.data_receb: "+posicao.data_receb +  "   lat: "+posicao.latitude+"   recebimento_id: "+posicao.recebimento_id);
                    

                    y = posicao.data_receb.substring(21,25);
                    mm = posicao.data_receb.substring(18,20);
                    dd = posicao.data_receb.substring(15,17);
                    hm = posicao.data_receb.substring(3,9);
                    posicao.dateTime = new Date(y+"-"+mm+"-"+dd+" "+hm).getTime();   //"2016-01-04 10:34:23"

                    if(posicao.velocidade > 0 ) posicao.velocidade2 = posicao.velocidade;
                      else posicao.velocidade2 = "Parado";

                    // if(i===resposta.length-1)
                        // ToastStore.success(" "+ String(posicao.data_receb));
                    // coords.push({lat: resposta[i].latitude, lng: resposta[i].longitude});
                    coords.push({lat: posicao.latitude, lng: posicao.longitude});

                    // if(resposta[i].tipo_recebimento_id === 6  || resposta[i].tipo_recebimento_id === 2){  // 6- evento    2-alerta
                    if( resposta[i].tipo_recebimento_id === 2   ||  resposta[i].tipo_recebimento_id === 6){  // 6- evento    2-alerta
                        
                        posicao.tipo_evento_id = 1;

                        markerEvento_list.push({
                            recebimento_id: resposta[i].recebimento_id,
                            data_receb: resposta[i].data_cadastro, 
                            latitude: resposta[i].latitude,
                            longitude: resposta[i].longitude,
                            maark: null,
                        });
                    }

                    if(resposta[i].backup)
                        posicao.backup= "Backup";
                    else posicao.backup= "Online";    

                    // if( resposta[i].tipo_recebimento_id === 2){}

                    list.push(posicao);            
                    this.setState({list: list});                    
                }

                this.setState({loading: false});
                if(this.state.list.length > 0){
                    // lastPosition = list[0];
                    lastPosition = list[list.length-1];


                    // this.setState({ lastPosition: "shit" }, () => {
                    //     alert("lastPosition");
                    //     console.log("lastPosition:");
                    //     console.log(this.state.lastPosition);
                    // });
                }
                
            }else{
            }

        } finally {

            if(this.state.list.length>0){
                var tipo_chamada=2;  //  TIPO_CHAMADA_POSICOES_EVENTOS_GRID
                var instance = new GetEventos(this.state.list[this.state.list.length-1]
                                                , this.state.MapaKseg
                                                , this.state.list
                                                , tipo_chamada
                                                , this.state.veiculo.veiculo_id
                                                ,null
                                                ,this);
                instance.getEventos();
            }
        }
    };
    //--------------------------------------------------------------------------------
    getPosicoesData = async (startDate, endDate, posicoes_list) => {
        // console.log("  ***this.props.data_receb: "+this.props.data_receb)
        try {
            let respostaVazia=true;
            let count_loop=0;
            let startDate_edit = startDate;
            

            while(respostaVazia){
                count_loop+=1;
                // console.log(startDate_edit);
                body = new FormData()
                body.append('grupo_id', localStorage.getItem('grupo_id'))
                body.append('veiculo_id', this.state.veiculo.veiculo_id)
                body.append('data_inicial', startDate_edit)
                body.append('data_final', endDate)
                body.append('token', localStorage.getItem('token'))
                body.append('temperaturas_tab', this.state.veiculo.veiculo_temperaturas_tab)
                // alert(startDate_edit + "\n"+ endDate);
        
                var url = "/relatorio_api/posicoesVeiculoData";
        
                const response = await api.post(url, body);
        
        
                var responseJson = null
        
                if (response.data !== null  &&  response.status !== 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json);
                    // this.state.veiculo.MapaKseg.callToast("resposta.length: "+ String(resposta.length));

                    if(resposta.length>0){
                        count_loop=0;
                        respostaVazia=false;
                
                        let posicao = this.state.posicao;
                        //   let posicoes_list = this.state.posicoes_list;
                        let sensores ="";           
            
                        if(this.state.veiculo.veiculo_temperaturas_tab){
                            for (var i = 0; i < resposta.length; i++) {
                                posicao = {
                                    id: i + (posicoes_list.length+1),
                                    recebimento_id: resposta[i].recebimento_id,
                                    veiculo_id: resposta[i].veiculo_id,
                                    placa: this.state.placa,
                                    municipio: resposta[i].municipio,
                                    uf: resposta[i].uf,
                                    estado: resposta[i].estado,
                                    pais: resposta[i].pais,
                                    velocidade: resposta[i].velocidade,
                                    velocidade2:null,
                                    tipo_recebimento_id: resposta[i].tipo_recebimento_id,
                                    tecnologia_id: resposta[i].tecnologia_id,
                                    data_receb: resposta[i].data_receb_temperatura,
                                    data_format: this.formatarDateTimeGrid(resposta[i].data_receb_temperatura),
                                    latitude: resposta[i].latitude,
                                    longitude: resposta[i].longitude,
                                    latlng: resposta[i].latitude+", "+resposta[i].longitude,
                                    backup: resposta[i].backup,
                                    sensor1: resposta[i].sensor1,
                                    sensor2: resposta[i].sensor2,
                                    sensor3: resposta[i].sensor3,
                                    sensores: "",
                                    ignicao: resposta[i].ignicao,
                                    mensagem_externo_id: resposta[i].mensagem_externo_id,
                                    direcao: resposta[i].direcao,
                                    };
                                    //   console.log("  ignicao "+posicao.ignicao)
                                    if(resposta[i].data_receb_temperatura=== null){
                                        posicao.data_receb = resposta[i].data_cadastro;
                                        posicao.data_format = this.formatarDateTimeGrid(resposta[i].data_cadastro);
                                    }
                                    
                                    if(posicao.velocidade > 0 ) posicao.velocidade2 = posicao.velocidade;
                                    else posicao.velocidade2 = "Parado";

                                    coords.push({lat: posicao.latitude, lng: posicao.longitude});   

                                    if( resposta[i].tipo_recebimento_id === 2   ||  resposta[i].tipo_recebimento_id === 6){  // 6- evento    2-alerta                    
                                        posicao.tipo_evento_id = 1;
                
                                        markerEvento_list.push({
                                            recebimento_id: resposta[i].recebimento_id,
                                            data_receb: resposta[i].data_cadastro, 
                                            latitude: resposta[i].latitude,
                                            longitude: resposta[i].longitude,
                                            maark: null,
                                        });
                                    }  

            
                                    sensores ="";
            
                                    if(resposta[i].sensor1 !== null){
                                        if (String(posicao.sensor1)[0]==="-")
                                            sensores = "s1:"+String((resposta[i].sensor1)).split(".")[0]
                                        else sensores = "s1:"+String((resposta[i].sensor1)).split(".")[0]
                
                                        if (String(posicao.sensor2)[0]==="-")
                                            sensores = sensores+" s2:"+String((resposta[i].sensor2)).split(".")[0]
                                        else sensores = sensores+" s2:"+String((resposta[i].sensor2)).split(".")[0] 
                
                                        if (String(posicao.sensor3)[0]==="-")
                                            sensores = sensores+" s3:"+String((resposta[i].sensor3)).split(".")[0]
                                        else sensores = sensores+" s3:"+String((resposta[i].sensor3)).split(".")[0]
                                    }else sensores ="";
                                        

                                    posicao.sensores = sensores;

                                    if(resposta[i].backup) posicao.backup= "Backup";
                                    else posicao.backup= "Online";  

                                    posicoes_list.push(posicao);
                                    this.setState({posicoes_list: posicoes_list});

                                    if(posicoes_list.length > 0){
                                        lastPosition = posicoes_list[posicoes_list.length-1];

                                        let polyline_obj = {esperando_renderizar:true, polyline: null};
                                        polyline_obj = this.state.veiculo.MapaKseg.setPolyline(polyline_obj, coords);
                                        polyline_list.push(polyline_obj);  
                                    }
                            }
                        }else{                            
                            for (var i = 0; i < resposta.length; i++) {
                                posicao = {
                                    id:  i + (posicoes_list.length+1),
                                    recebimento_id: resposta[i].recebimento_id,
                                    veiculo_id: resposta[i].veiculo_id,
                                    placa: this.state.placa,
                                    municipio: resposta[i].municipio,
                                    uf: resposta[i].uf,
                                    estado: resposta[i].estado,
                                    pais: resposta[i].pais,
                                    velocidade: resposta[i].velocidade,
                                    velocidade2: null,
                                    tipo_recebimento_id: resposta[i].tipo_recebimento_id,
                                    tecnologia_id: resposta[i].tecnologia_id,
                                    data_receb: resposta[i].data_cadastro,
                                    data_receb2: resposta[i].data_receb,
                                    data_format: this.formatarDateTimeGrid(resposta[i].data_cadastro),
                                    latitude: resposta[i].latitude,
                                    longitude: resposta[i].longitude,               
                                    latlng: resposta[i].latitude+", "+resposta[i].longitude,      
                                    backup: resposta[i].backup,
                                    ignicao: resposta[i].ignicao,
                                    mensagem_externo_id: resposta[i].mensagem_externo_id,
                                    direcao: resposta[i].direcao,
                                    eventos_count:0,
                                    eventos_count_icon: null,
                                    };
                            

                                    console.log("  data_receb2 "+posicao.data_receb2)
                                // console.log("  *** backup: "+resposta[i].backup)
                                // console.log("  *** placa: "+posicao.placa+"   data_receb: "+posicao.data_receb)
                                if(resposta[i].backup) posicao.backup= "Backup";
                                else posicao.backup= "Online";  

                                if(posicao.velocidade > 0 ) posicao.velocidade2 = posicao.velocidade;
                                else posicao.velocidade2 = "Parado";

                                coords.push({lat: posicao.latitude, lng: posicao.longitude});
                                

                                if( resposta[i].tipo_recebimento_id === 2   ||  resposta[i].tipo_recebimento_id === 6){  // 6- evento    2-alerta                    
                                    posicao.tipo_evento_id = 1;

                                    markerEvento_list.push({
                                        recebimento_id: resposta[i].recebimento_id,
                                        data_receb: resposta[i].data_cadastro, 
                                        latitude: resposta[i].latitude,
                                        longitude: resposta[i].longitude,
                                        maark: null,
                                    });
                                }  
                                
                                posicoes_list.push(posicao);
                                this.setState({posicoes_list: posicoes_list});             

                                if(posicoes_list.length > 0){
                                    lastPosition = posicoes_list[posicoes_list.length-1];                                
                            
                                }                                
                            }
                        }
        
        
                        // if(this.state.posicoes_list.length>0){
                        //     this.getEventos();
                        // }
        
                        
                    }   
                }

                startDate_edit = moment(startDate_edit).subtract(1, 'days').format('Y-MM-DD HH:mm');

                if(count_loop ===10) break;
            }
  
        } finally {
            // this.state.MapaKseg.callToast("finally len: "+this.state.list.length);
            if(this.state.list.length>0){
                // alert("getEventos");
                var tipo_chamada=2;  //  TIPO_CHAMADA_POSICOES_EVENTOS_GRID
                var instance = new GetEventos(this.state.list[this.state.list.length-1]
                                                , this.state.MapaKseg
                                                , this.state.list
                                                , tipo_chamada
                                                , this.state.veiculo.veiculo_id
                                                ,null
                                                ,this);
                instance.getEventos();
            }  
        }
      };
    //-----------------------------------------------------------------------------------------
    setEventosCount = (eventos_count) =>{
        let list = this.state.list;
        for(var i=0; i<eventos_count.length; i++) {
            for(var j = 0; j<list.length; j++) {
                if(eventos_count[i].recebimento_id === list[j].recebimento_id ){
                    list[j].eventos_count = eventos_count[i].count;
                    if(eventos_count[i].count===1) list[j].eventos_count_icon = require("./img/circle1.png")
                    else if(eventos_count[i].count===2) list[j].eventos_count_icon = require("./img/circle2.png")
                    else if(eventos_count[i].count===3) list[j].eventos_count_icon = require("./img/circle3.png")
                    else if(eventos_count[i].count===4) list[j].eventos_count_icon = require("./img/circle4.png")
                    else if(eventos_count[i].count===5) list[j].eventos_count_icon = require("./img/circle5.png")
                    else if(eventos_count[i].count>5) list[j].eventos_count_icon = require("./img/circle5plus.png")
                    else if(eventos_count[i].count>9) list[j].eventos_count_icon = require("./img/circle9plus.png")
                    
                    break;
                }
            }
        }
        this.setState({list:list
                        ,eventos_count:eventos_count});
    };
    //-----------------------------------------------------------------------------------------
    async changeHandler(lastPosition) {
        await this.setState({ lastPosition: lastPosition});
        // console.log(this.state.lastPosition);
    }
    //--------------------------------------------------------
    retornarList = async (eventos_list, GetPosicoes) =>{
        // alert(eventos_list.length + "");
        // console.log(eventos_list);
        if(this.state.GridVeiculoDetail !== null){
            this.state.GridVeiculoDetail.setState({posicoes_list: this.state.list
                                                        ,eventos_list: eventos_list
                                                        ,loading: false
                                                        ,eventos_count: this.state.eventos_count});                                                        
        }else{
            // this.state.MapaKseg.setState({posicoesVeiculo: this.state.list
            //                                 ,eventos_list: eventos_list
            //                                 ,loading: false}); 
        }
        // alert("retornarList");
        this.state.MapaKseg.setCoords(
                this.state.list
                , coords
                , lastPosition
                , markerEvento_list);

    }
    //-----------------------------------------------------------------------------------------
}