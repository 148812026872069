import  {Component} from 'react';
import api from './api';
import {ToastStore} from 'react-toasts';
import Moment from 'moment';

// import {Polyline} from 'google-maps-react';

export default class GetGridTemperatura extends Component{
    constructor(props, objeto){
        super(props);
        this.state = {
             grupo: {},
             list: [],
             loading:true,
             objeto:objeto,
             usuario_id: props,
             verde: "#44c87e",
             vermelho: "#ee3f43",
             hrefcolor: "#111111",
       }
    }
    //---------------------
    toastStore = (texto) => {
        ToastStore.success("teste: "+texto);
    };
    //-----------------
    formatarSensores=(resposta, sensores_obj)=>{
        sensores_obj = {
            sensor1_cor: this.state.verde,
            sensor2_cor: this.state.verde,
            sensor3_cor: this.state.verde,
            sensores: ""
        }
        // sensores="111";
        // ToastStore.success(" "+resposta.placa);

        if(!resposta.faixa_fracionada){
            if(resposta.faixa_temperatura_id===1){  // 1-Nenhuma Faixa
                sensores_obj.sensor1_cor= this.state.verde;
                sensores_obj.sensor2_cor= this.state.verde;
                sensores_obj.sensor3_cor= this.state.verde;
                sensores_obj.sensores="111";
            }else{
                if((resposta.sensor1 < resposta.faixa_min) || (resposta.sensor1 > resposta.faixa_max)){
                    sensores_obj.sensor1_cor= this.state.vermelho;
                    sensores_obj.sensores = '0';
                }else sensores_obj.sensores ='1';

                if((resposta.sensor2 < resposta.faixa_min) || (resposta.sensor2 > resposta.faixa_max)){
                    sensores_obj.sensor2_cor= this.state.vermelho;    
                    sensores_obj.sensores = sensores_obj.sensores+'0';
                }else sensores_obj.sensores = sensores_obj.sensores+'1';

                if((resposta.sensor3 < resposta.faixa_min) || (resposta.sensor3 > resposta.faixa_max)){
                    sensores_obj.sensor3_cor= this.state.vermelho;
                    sensores_obj.sensores= sensores_obj.sensores+'0';
                }else sensores_obj.sensores = sensores_obj.sensores+'1';
            }
        }else{

            if(resposta.faixa1_id===1){  // 1-Nenhuma Faixa
                sensores_obj.sensor1_cor= this.state.verde;
                sensores_obj.sensores="1";
            }else{
                if((resposta.sensor1 < resposta.faixa1_min) || (resposta.sensor1 > resposta.faixa1_max)){
                    sensores_obj.sensor1_cor= this.state.vermelho;
                    sensores_obj.sensores = '0';
                }else sensores_obj.sensores ='1';

            }

            if(resposta.faixa2_id===1){  // 1-Nenhuma Faixa
                sensores_obj.sensor2_cor= this.state.verde;
                sensores_obj.sensores = sensores_obj.sensores+'1';
            }else{
                if((resposta.sensor2 < resposta.faixa2_min) || (resposta.sensor2 > resposta.faixa2_max)){
                    sensores_obj.sensor2_cor= this.state.vermelho;    
                    sensores_obj.sensores = sensores_obj.sensores+'0';
                }else sensores_obj.sensores = sensores_obj.sensores+'1';
            }

            if(resposta.faixa3_id===1){  // 1-Nenhuma Faixa
                sensores_obj.sensor3_cor= this.state.verde;
                sensores_obj.sensores = sensores_obj.sensores+'1';
            }else{
                if((resposta.sensor3 < resposta.faixa3_min) || (resposta.sensor3 > resposta.faixa3_max)){
                    sensores_obj.sensor3_cor= this.state.vermelho;
                    sensores_obj.sensores= sensores_obj.sensores+'0';
                }else sensores_obj.sensores = sensores_obj.sensores+'1';
            }

        }

        return sensores_obj;

    }    
    //--------------------
    getGridTemperatura = async () => {
        try {
            // ToastStore.success("usuario_id: "+String(this.state.usuario_id));
            let body = new FormData()
            body.append('grupo_id', localStorage.getItem('grupo_id'))
            body.append('token', localStorage.getItem('token'))

            // console.log("  ***datetime: "+datetime)

            const response = await api.post('/temperatura_api/gridTemperatura', body);
            
            var responseJson = null
                if (response.data != null  &&  response.status != 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json);

                
                    let temperatura = this.state.temperatura;
                    let list = this.state.list;
                    // let list = [];
                    let sensores_obj = {};

                    // ToastStore.success("resposta.length: "+ String(resposta.length));
                    for (var i=0; i < resposta.length; i++) {
                        
                        sensores_obj = this.formatarSensores(resposta[i], sensores_obj);
                        
                        temperatura = {
                            veiculo_id: resposta[i].veiculo_id,
                            placa: resposta[i].placa,
                            tecnologia_id: resposta[i].tecnologia_id,
                            sensor1: parseInt(resposta[i].sensor1, 10),
                            sensor2: parseInt(resposta[i].sensor2, 10),
                            sensor3: parseInt(resposta[i].sensor3, 10),
                            data_receb: resposta[i].data_receb,
                            faixa_temperatura_id: resposta[i].faixa_temperatura_id,
                            descricao: resposta[i].descricao,
                            faixa_min: resposta[i].faixa_min,
                            faixa_max: resposta[i].faixa_max,
                            faixa_min_max: resposta[i].faixa_min+'      a    ' +resposta[i].faixa_max,
                            sensor1_cor: sensores_obj.sensor1_cor,
                            sensor2_cor: sensores_obj.sensor2_cor,
                            sensor3_cor: sensores_obj.sensor3_cor,
                            sensores: sensores_obj.sensores,
                            faixa_fracionada: resposta[i].faixa_fracionada,
                            faixa1_min: resposta[i].faixa1_min,
                            faixa1_max: resposta[i].faixa1_max,
                            faixa1_min_max: resposta[i].faixa1_min+'      a    ' +resposta[i].faixa1_max,
                            faixa2_min: resposta[i].faixa2_min,
                            faixa2_max: resposta[i].faixa2_max,
                            faixa2_min_max: resposta[i].faixa2_min+'      a    ' +resposta[i].faixa2_max,
                            faixa3_min: resposta[i].faixa3_min,
                            faixa3_max: resposta[i].faixa3_max,
                            faixa3_min_max: resposta[i].faixa3_min+'      a    ' +resposta[i].faixa3_max,
                            descricao1 : resposta[i].descricao1,
                            descricao2 : resposta[i].descricao2,
                            descricao3 : resposta[i].descricao3,
                            faixa1_id: resposta[i].faixa1_id,
                            faixa2_id: resposta[i].faixa2_id,
                            faixa3_id: resposta[i].faixa3_id,
                            municipio: resposta[i].municipio
                            };

                        list.push(temperatura);
                        this.setState({list: list});
                    }

                    this.setState({loading: false});
            }else{
            }

        } finally {
                // ToastStore.success("GetPo coords.length: "+ String(coords.length));
                // ToastStore.success("PPlaca: "+ String(lastPosition.data_receb));
                // this.state.objeto.setCoords(coords, lastPosition, markerEvento_list);
                this.state.objeto.setState({list: this.state.list});
                this.state.objeto.setState({loading: false});

            }
    };
    //--------------------------
    async componentDidMount() {
        ToastStore.success("GetGridTemperatura componentDidMount()");
        this.getGridTemperatura();
    };
}