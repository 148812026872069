export const dados_jornada = [
  { jornada_motorista_id: 1985, motorista: "Telê Santana", status_jornada_motorista_id: 1, status_jornada: "Andamento", evento_jornada_motorista: "Reinício Jornada", veiculo: "JAK5I39", data_atual_veiculo: "qua 12/04/2024 13:06", data_inicial: "qua 12/04/2024 07:14", data_final: "", municipio: "Seara - SC" },
	{ jornada_motorista_id: 1986, motorista: "Rivaldo Ferreira", status_jornada_motorista_id: 1, status_jornada: "Andamento", evento_jornada_motorista: "Reinício Jornada", veiculo: "ISM-0568", data_atual_veiculo: "qua 12/04/2024 12:14", data_inicial: "qua 12/04/2024 07:16", data_final: "", municipio: "Bom Jesus - SC" },
	{ jornada_motorista_id: 1995, motorista: "Michel Machado", status_jornada_motorista_id: 2, status_jornada: "Parada", evento_jornada_motorista: "Espera", veiculo: "ISM-0568", data_atual_veiculo: "qua 12/04/2024 12:14", data_inicial: "qua 12/04/2024 07:16", data_final: "", municipio: "Bom Jesus - SC" },
	{ jornada_motorista_id: 1987, motorista: "Ricardo dos Santos", status_jornada_motorista_id: 3, status_jornada: "Fim Jornada", evento_jornada_motorista: "Fim Jornada", veiculo: "3IZM9A91", data_atual_veiculo: "qua 12/04/2024 01:18", data_inicial: "ter 11/04/2024 08:01", data_final: "qua 12/04/2024 01:20", municipio: "Capitão Leônidas Marques - PR" },
	{ jornada_motorista_id: 1988, motorista: "Ronaldo Nazário", status_jornada_motorista_id: 2, status_jornada: "Parada", evento_jornada_motorista: "Repouso", veiculo: "OEL5F52", data_atual_veiculo: "qua 12/04/2024 13:20", data_inicial: "qua 12/04/2024 08:14", data_final: "", municipio: "Pinhalzinho - SC" },
	{ jornada_motorista_id: 1989, motorista: "Leonardo Fagundes", status_jornada_motorista_id: 3, status_jornada: "Fim Jornada", evento_jornada_motorista: "Fim Jornada", veiculo: "AJI-6892", data_atual_veiculo: "qua 11/04/2024 19:17", data_inicial: "ter 11/04/2024 08:04", data_final: "qua 11/04/2024 19:17", municipio: "Chapecó - SC" },
	{ jornada_motorista_id: 1990, motorista: "Dorival Silvestre", status_jornada_motorista_id: 2, status_jornada: "Parada", evento_jornada_motorista: "Espera", veiculo: "MFX-6997", data_atual_veiculo: "qua 12/04/2024 12:30", data_inicial: "qua 12/04/2024 08:07", data_final: "", municipio: "Joaçaba - SC" },
	{ jornada_motorista_id: 1991, motorista: "André Luiz", status_jornada_motorista_id: 2, status_jornada: "Parada", evento_jornada_motorista: "Refeição", veiculo: "Saveiro", data_atual_veiculo: "qua 12/04/2024 12:10", data_inicial: "qua 12/04/2024 08:00", data_final: "", municipio: "Catanduvas - SC" },
	{ jornada_motorista_id: 1992, motorista: "Rogério Ceni", status_jornada_motorista_id: 2, status_jornada: "Parada", evento_jornada_motorista: "Parada Indevida", veiculo: "IRQ-9534", data_atual_veiculo: "qua 12/04/2024 11:00", data_inicial: "qua 12/04/2024 08:20", data_final: "", municipio: "Xanxerê - SC" },
	{ jornada_motorista_id: 1993, motorista: "Diego Lugano", status_jornada_motorista_id: 1, status_jornada: "Andamento", evento_jornada_motorista: "Início Jornada", veiculo: "JBW-6B86", data_atual_veiculo: "qua 12/04/2024 08:14", data_inicial: "qua 12/04/2024 08:14", data_final: "", municipio: "Itá - SC" },
	{ jornada_motorista_id: 1994, motorista: "Raí de Oliveira", status_jornada_motorista_id: 2, status_jornada: "Parada", evento_jornada_motorista: "Refeição", veiculo: "IZV9F88", data_atual_veiculo: "qua 12/04/2024 12:00", data_inicial: "qua 12/04/2024 08:03", data_final: "", municipio: "Ipumirim - SC" }
];

export const eventos_jornada = [
	{ evento_jornada_motorista_id: 685, jornada_motorista_id: 1, evento_jornada_motorista: "Início Jornada", data_inicial: "qua 12/04/2024 07:14", data_final: "qua 12/04/2024 11:58", municipio: "Capinzal - SC" },
	  { evento_jornada_motorista_id: 686, jornada_motorista_id: 1, evento_jornada_motorista: "Refeição", data_inicial: "qua 12/04/2024 11:58", data_final: "qua 12/04/2024 13:06", municipio: "Concórdia - SC" },
	  { evento_jornada_motorista_id: 687, jornada_motorista_id: 1, evento_jornada_motorista: "Reinício Jornada", data_inicial: "qua 12/04/2024 13:06", data_final: "", municipio: "Seara - SC" },

	  { evento_jornada_motorista_id: 688, jornada_motorista_id: 2, evento_jornada_motorista: "Início Jornada", data_inicial: "qua 12/04/2024 07:16", data_final: "qua 12/04/2024 10:16", municipio: "Tangará - SC" },	  
	  { evento_jornada_motorista_id: 690, jornada_motorista_id: 2, evento_jornada_motorista: "Espera", data_inicial: "qua 12/04/2024 10:16", data_final: "qua 12/04/2024 11:10", municipio: "Luzerna - SC" },
	  { evento_jornada_motorista_id: 691, jornada_motorista_id: 2, evento_jornada_motorista: "Refeição", data_inicial: "qua 12/04/2024 11:10", data_final: "qua 12/04/2024 12:14", municipio: "Pte Serrada - SC" },
	  { evento_jornada_motorista_id: 692, jornada_motorista_id: 2, evento_jornada_motorista: "Reinício Jornada", data_inicial: "qua 12/04/2024 12:14", data_final: "", municipio: "Bom Jesus - SC" },

	  { evento_jornada_motorista_id: 693, jornada_motorista_id: 3, evento_jornada_motorista: "Início Jornada", data_inicial: "ter 11/04/2024 08:01", data_final: "ter 11/04/2024 11:31", municipio: "Xanxerê - SC" },
	  { evento_jornada_motorista_id: 694, jornada_motorista_id: 3, evento_jornada_motorista: "Refeição", data_inicial: "ter 11/04/2024 11:31", data_final: "ter 11/04/2024 12:47", municipio: "Maravilha - SC" },
	  { evento_jornada_motorista_id: 695, jornada_motorista_id: 3, evento_jornada_motorista: "Reinício Jornada", data_inicial: "ter 11/04/2024 12:47", data_final: "ter 11/04/2024 18:00", municipio: "Anchieta - SC" },
	  { evento_jornada_motorista_id: 696, jornada_motorista_id: 3, evento_jornada_motorista: "Refeição", data_inicial: "ter 11/04/2024 18:00", data_final: "ter 11/04/2024 19:03", municipio: "Salgado Filho - PR" },
	  { evento_jornada_motorista_id: 697, jornada_motorista_id: 3, evento_jornada_motorista: "Reinício Jornada", data_inicial: "ter 11/04/2024 19:03", data_final: "qua 12/04/2024 01:18", municipio: "Realeza - PR" },
	  { evento_jornada_motorista_id: 698, jornada_motorista_id: 3, evento_jornada_motorista: "Fim Jornada", data_inicial: "qua 12/04/2024 01:18", data_final: "qua 12/04/2024 01:20", municipio: "Capitão Leônidas Marques - PR" },

	  { evento_jornada_motorista_id: 699, jornada_motorista_id: 4, evento_jornada_motorista: "Início Jornada", data_inicial: "qua 12/04/2024 08:14", data_final: "qua 12/04/2024 13:20", municipio: "Vargeão - SC" },
	  { evento_jornada_motorista_id: 700, jornada_motorista_id: 4, evento_jornada_motorista: "Refeição", data_inicial: "qua 12/04/2024 13:20", data_final: "", municipio: "Pinhalzinho - SC" },

	  { evento_jornada_motorista_id: 701, jornada_motorista_id: 5, evento_jornada_motorista: "Início Jornada", data_inicial: "ter 11/04/2024 08:04", data_final: "ter 11/04/2024 11:45", municipio: "São José - SC" },
	  { evento_jornada_motorista_id: 702, jornada_motorista_id: 5, evento_jornada_motorista: "Refeição", data_inicial: "ter 11/04/2024 11:45", data_final: "ter 11/04/2024 12:45", municipio: "Lages - SC" },
	  { evento_jornada_motorista_id: 703, jornada_motorista_id: 5, evento_jornada_motorista: "Reinício Jornada", data_inicial: "ter 11/04/2024 12:45", data_final: "qua 11/04/2024 19:17", municipio: "Joaçaba - SC" },
	  { evento_jornada_motorista_id: 704, jornada_motorista_id: 5, evento_jornada_motorista: "Fim Jornada", data_inicial: "qua 11/04/2024 19:17", data_final: "qua 11/04/2024 19:18", municipio: "Chapecó - SC" },	  	  

	  { evento_jornada_motorista_id: 707, jornada_motorista_id: 6, evento_jornada_motorista: "Início Jornada", data_inicial: "qua 12/04/2024 08:07", data_final: "qua 12/04/2024 12:30", municipio: "Lages - SC" },
	  { evento_jornada_motorista_id: 708, jornada_motorista_id: 6, evento_jornada_motorista: "Espera", data_inicial: "qua 12/04/2024 12:30", data_final: "", municipio: "Joaçaba - SC" },

	  { evento_jornada_motorista_id: 709, jornada_motorista_id: 7, evento_jornada_motorista: "Início Jornada", data_inicial: "qua 12/04/2024 08:00", data_final: "qua 12/04/2024 12:10", municipio: "Saudades - SC" },
	  { evento_jornada_motorista_id: 710, jornada_motorista_id: 7, evento_jornada_motorista: "Refeição", data_inicial: "qua 12/04/2024 12:10", data_final: "", municipio: "Catanduvas - SC" },

	  { evento_jornada_motorista_id: 711, jornada_motorista_id: 8, evento_jornada_motorista: "Início Jornada", data_inicial: "qua 12/04/2024 08:20", data_final: "qua 12/04/2024 11:00", municipio: "Mondaí - SC" },
	  { evento_jornada_motorista_id: 712, jornada_motorista_id: 8, evento_jornada_motorista: "Parada Indevida", data_inicial: "qua 12/04/2024 11:00", data_final: "", municipio: "Xanxerê - SC" },

	  { evento_jornada_motorista_id: 705, jornada_motorista_id: 9, evento_jornada_motorista: "Início Jornada", data_inicial: "qua 12/04/2024 08:14", data_final: "", municipio: "Itá - SC" },

	  { evento_jornada_motorista_id: 713, jornada_motorista_id: 10, evento_jornada_motorista: "Início Jornada", data_inicial: "qua 12/04/2024 08:03", data_final: "qua 12/04/2024 12:00", municipio: "Ibicaré - SC" },
	  { evento_jornada_motorista_id: 714, jornada_motorista_id: 10, evento_jornada_motorista: "Refeição", data_inicial: "qua 12/04/2024 12:00", data_final: "", municipio: "Ipumirim - SC" },

	  { evento_jornada_motorista_id: 715, jornada_motorista_id: 11, evento_jornada_motorista: "Início Jornada", data_inicial: "qua 12/04/2024 07:16", data_final: "qua 12/04/2024 07:20", municipio: "Bom Jesus - SC" },
	  { evento_jornada_motorista_id: 716, jornada_motorista_id: 11, evento_jornada_motorista: "Espera", data_inicial: "qua 12/04/2024 07:20", data_final: "", municipio: "Bom Jesus - SC" },
  ];

