import React, {Component} from 'react';
import {Modal, Image, Spinner, Button, Form, Row,Col,Card,Nav,InputGroup,FormControl} from 'react-bootstrap';
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import GetPessoa from '../services/get_pessoa';
import GetLocais from '../services/get_locais';
import PostLocalCad from '../services/post_local_cad';

const PESSOA =1;

export class LocalCadModal extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            load:true,
            minimizar:false,
            changed:false,
            local:null,

            GeralListModal:null,            
            LocalGrid:null,

            height_modal:330,
            height_grid:0,
            items:[],
            editados: {},

            salvando:false,            
        };

         // -------------------------------
        this.dataGridRef = React.createRef();
    
        this.selectedRowsData = [];

        this.getSelectedData = () => {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData();
            this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            });
        }
      }
    //---------------------------------------------------------------
    get dataGrid() {
        return this.dataGridRef.current.instance;
    }
    //-------------------------------------------------------------------
    async componentDidMount() {
        // INICIALIZAR VARIÁVEIS

        this.setState({LocalGrid: this.props.objeto});

        let editados = {
              descricao:false
            , latitude:false
            , longitude:false
            , pessoa: false
             };

        let local = this.props.obj_selected;

        this.setState({
            local: local       
                ,editados: editados        
                , height_grid: this.state.height_modal-420},
            () => { 
                if(local.local_id != 0){
                    // this.getUsuariosGrupo()
                    // this.getTokensUsuario()
                }
                this.setState({load:false}) }
          )
    }; 
    //----------------------------------------------------------------
    getLocal = () =>{
        var instance = new GetLocais(null, this, this.props.MapaKseg, 0, this.state.local.local_id );
        instance.get();   
    }; 
    //----------------------------------------------------------------
    getPessoa = () =>{
        var instance = new GetPessoa(null, this, this.props.MapaKseg,0, this.state.local.local_id );
        instance.get();   
    };  
    //--------------------------------------------------------------------
    setListLocal = (_list) => {
        let local = this.state.local;
        let obj = _list[0];

        local.local_id = obj.local_id;
        local.descricao = obj.descricao;
        local.latitude = obj.latitude;
        local.longitude = obj.longitude;
        local.pessoa_id = obj.pessoa_id;
        local.nome_social = obj.nome_social;

        this.setState({local: local});
    } 
    //------------------------------------------------------------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closeLocalCadModal();  
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeLocalCadModal();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarLocalCadModal();
    };    
    //------------------------------------------------------
    updateInputValue(event) {
        var editados = this.state.editados;
        var local = this.state.local;

        if(event.target.id==="formDescricao"){
            local.descricao = event.target.value;
            editados.descricao = true;
        }else 
        if(event.target.id==="formLatitude"){
            local.latitude = String(event.target.value).trim();
            editados.latitude = true;
        }else 
        if(event.target.id==="formLongitude"){
            local.longitude = String(event.target.value).trim();
            editados.longitude = true;
        }else 
        if(event.target.id==="formPessoa"){
            local.pessoa_id = event.target.value;
            editados.pessoa = true;
        }

        this.setState({local: local});
        this.setState({editados: editados, changed: true});
     }
         //-------------------------------------------------------------------
    showGeralList= (e) => {   
        if(e.target.getAttribute('value') == PESSOA)
            this.props.Modals.showGeralList(this,"Pessoa", PESSOA);            
    };
    //-----------------------------------------------------
    getList= (geralListModal, tipo) => { 
        this.setState({GeralListModal: geralListModal});  

        if(tipo == PESSOA){             
            var instance = new GetPessoa(null, this, this.props.MapaKseg, PESSOA, 0);
            instance.get(); 
        }
    };
    //-------------------------------------------------------------
    setListPessoa =  (_list, tipo) => {  
        let array =[]
        let sel=false;
        for (var i = 0; i < _list.length; i++) { 
            if(this.state.local.pessoa_id == _list[i].pessoa_id) sel=true;
            else sel=false;

            array.push({
                index: _list[i].index
                , id: _list[i].pessoa_id
                , descricao: _list[i].nome_social
                , selecionada: sel
                , index_scroll: 0});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //---------------------------------------------------
    setObjeto= (objeto, tipo) => { 
        if(tipo == PESSOA){
            let local = this.state.local;
            local.pessoa_id = objeto.id;
            local.nome_social = objeto.descricao;            
            this.setState({local: local});            

            let editados =this.state.editados;
            editados.pessoa =  true;
            this.setState({editados: editados });
        }
        this.setState({changed:true});
    };
    //--------------------------------------
    concluido=()=> {
        this.getLocal();
        this.setState({salvando:false, changed:false });     
        this.state.LocalGrid.setLocal(this.state.local);  
    }
    //--------------------------------------
    validar=()=> {
        let valido = true;

        if(String(this.state.local.descricao).trim()==="" && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Local!");
        }

        if(String(this.state.local.latitude).trim().length < 6 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe Latitude válida!");
        }  
        
        if(String(this.state.local.longitude).trim().length < 6 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe Longitude válida!");
        }    

        if(this.state.local.pessoa_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe a Pessoa!");
        }    
    
        if(valido==true){
            this.setState({salvando:true, changed:true});
            var instance = new PostLocalCad(null, this, this.props.MapaKseg);
            instance.post(); 
        }
    }
    //-------------------------
    render(){
        return(
            <div className="drag1">
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={600}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.load ?
                                        <Spinner className="centro3" animation="border" role="status">
                                            <span className="sr-only"></span>
                                        </Spinner>
                                    :
                                        <div style={{height: this.state.height_modal, overflow:'hidden'}}>
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                                                {/*------------------------------------ */}
                                                <Form>                                                       
                                                    <Form.Group className="mb-1" as={Row} controlId="formDescricao">
                                                        <Form.Label column sm={3}>
                                                            Local
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>{this.state.local.local_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.local.descricao}  onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>        

                                                     <Form.Group className="mb-1" as={Row} controlId="formLatitude">
                                                        <Form.Label column sm={3}>
                                                            Latitude
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >                                                   
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.local.latitude} onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>    

                                                    <Form.Group className="mb-1" as={Row} controlId="formLongitude">
                                                        <Form.Label column sm={3}>
                                                            Longitude
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >                                                    
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.local.longitude} onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>                            
                            
                                                    <Form.Group className="mb-1" as={Row} controlId="formPessoa">
                                                        <Form.Label column sm={3}>
                                                        Pessoa
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formPessoaBt" value={PESSOA} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                        <Image value={PESSOA} src={require('../img/text_box_search48.png')} width='20'/>
                                                                    </Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text onChange={this.updateInputValue.bind(this)} >{this.state.local.pessoa_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control disabled aria-describedby="basic-addon1" value={this.state.local.nome_social}  />
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>                                                                                                                                                                

                                                </Form>
                                                {/* ----------------------------------- */}
                                            </CustomScroll>


                                            <Modal.Footer >
                                                <div className="align_bottom_right">
                                                    <Button disabled={!this.state.changed} onClick={this.validar}>
                                                        {this.state.salvando
                                                            ? <Spinner size="sm"  as="span" animation="grow" role="status" aria-hidden="true"/>                                                                
                                                        :
                                                        <div>
                                                            <img src={require('../img/checkbold24.png')}/> Salvar 
                                                        </div>
                                                        }                                                                
                                                    </Button>
                                                </div> 
                                            </Modal.Footer>

                                        </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}