const maleAgeData = [
	{
	  jornada_id:1985,
	  state: 'Linha \n Tempo',
	  evento1: 29.956,
	  evento2: 90.354,
	  evento3: 14.472,
	  evento4: 28.597,
	  evento5: 91.827,
	  evento6: 20.362,
	}
  ];
  export default {
	getMaleAgeData() {
	  return maleAgeData;
	},
  };
  