import  {Component} from 'react';
import api from '../api';
import {ToastStore} from 'react-toasts';
import Moment from 'moment';

// import {Polyline} from 'google-maps-react';

export default class GetVeiculos extends Component{
    constructor(props, objeto, MapaKseg, rotas){
        super(props);
        this.state = {
             list: [],
             loading:true,
             objeto: objeto,
             MapaKseg: MapaKseg,
       }
    }
    //------------------------------------------------
    get = async () => {
        try {
            // rotas_id_str+=")";

            let body = new FormData()
            body.append('placa', "")
            body.append('token', localStorage.getItem('token'))

            const response = await api.post('/cadastro_api/getVeiculos', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let veiculo = null;
                let list = this.state.list;                

                // ToastStore.success("resposta.length: "+ String(resposta.length));
                for (var i = 0; i < resposta.length; i++) {                        
                    veiculo = {    
                        index: i+1,
                        veiculo_id: resposta[i].veiculo_id,
                        placa: resposta[i].placa,
                        veiculo_externo: String(resposta[i].veiculo_externo),
                        pessoa_id: resposta[i].pessoa_id,
                        nome_social: resposta[i].nome_social.substring(0,15),                        
                        rastreador: resposta[i].rastreador,
                        chip: resposta[i].chip,
                        ultima_posicao: resposta[i].ultima_posicao,
                        veiculo_entrada_saida_id: resposta[i].veiculo_entrada_saida_id,
                        tipo_entrada_1_id: resposta[i].tipo_entrada_1_id,
                        entrada1: resposta[i].entrada1,
                        tipo_entrada_2_id: resposta[i].tipo_entrada_2_id,
                        entrada2: resposta[i].entrada2,
                        tipo_saida_1_id: resposta[i].tipo_saida_1_id,
                        saida1: resposta[i].saida1,
                        tipo_saida_2_id: resposta[i].tipo_saida_2_id,
                        saida2: resposta[i].saida2,                        
                    };                   

                    list.push(veiculo);
                    this.setState({list: list});
                }

                this.setState({loading: false});
                
            }
        } finally {      
            this.state.objeto.setState({veiculos: this.state.list});
            this.state.objeto.setState({bloqueado_consultar:false, load: false});
            // this.state.MapaKseg.toast(this.state.list.length);
            }
    };
}