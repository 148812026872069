import React, {Component} from 'react';
import {Modal, Form, Button as Button_bootstrap, Row, Col} from 'react-bootstrap';
import '../estilo.css';


export  class ObservacaoModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true, 
            showSheet:true,
            texto: "",
        };
      }   
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeObservacaoModal();
    };
    //------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarObservacaoModal();
    };
    //--------------------------
    async componentDidMount() {
        this.setState({texto: this.props.texto, tipo: this.props.tipo});
        document.getElementById("ControlTextArea1").focus();
    };
    //------------------------
    okClick = ()=>{
        if(this.state.tipo === 'observacao'){
            if(String(this.state.texto).length > 200 ) this.props.MapaKseg.toastError("Passou de 200 Caracteres!");
            else{
                this.props.objeto.confirmarObservacao(this.state.texto);
                this.fecharModal();
            }
        }else if(this.state.tipo === 'observacao_entrega'){
            if(String(this.state.texto).length > 200 ) this.props.MapaKseg.toastError("Passou de 200 Caracteres!");
            else{
                this.props.objeto.confirmarObservacaoEntrega(this.state.texto);
                this.fecharModal();
            }
        }else if(this.state.tipo === 'cte'){
            if(String(this.state.texto).length > 60 ) this.props.MapaKseg.toastError("Passou de 60 Caracteres!");
            else{
                this.props.objeto.confirmarCte(this.state.texto);
                this.fecharModal();
            }
        }else if(this.state.tipo === 'nota_fiscal'){
            if(String(this.state.texto).length > 50 ) this.props.MapaKseg.toastError("Passou de 50 Caracteres!");
            else{
                this.props.objeto.confirmarNotaFiscal(this.state.texto);
                this.fecharModal();
            }
        }
    };
    //-------------------------
    setTexto(event) {
        if(this.state.tipo === 'observacao'){
            if(String(event.target.value).length > 200 ) this.props.MapaKseg.toastError("Passou de 200 Caracteres!");
        }else if(this.state.tipo === 'cte'){
            if(String(event.target.value).length > 60 ) this.props.MapaKseg.toastError("Passou de 60 Caracteres!");
        }else if(this.state.tipo === 'nota_fiscal'){
            if(String(event.target.value).length > 50 ) this.props.MapaKseg.toastError("Passou de 50 Caracteres!");
        }

        this.setState({texto: event.target.value});
    }
    //-------------------------  <img src={this.props.comando.icone} /> {this.props.comando.descricao}
    render(){
        return(
            <div className="splashscreen-modal1">
                <Modal  centered
                    {...this.props}
                    
                    aria-labelledby="example-custom-modal-styling-title"
                    scrollable="True"
                    dialogClassName="temperatura-modal2"    
                    >
                    <div >
                        <strong className="cursor">
                            <div className="cabecalho"> 
                            <label className="titulo2" > {this.props.titulo} </label>
                                {/* <label className="titulo2" > Aviso! </label> */}
                            </div>
                        </strong>
                            
                        <Modal.Body>           
                            <Form>
                                <Form.Group className="mb-3" controlId="ControlTextArea1">
                                    {/* <Form.Label>Observação</Form.Label> */}
                                    <Form.Control as="textarea" defaultValue={this.props.texto} rows={3} onChange={this.setTexto.bind(this)} />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        
                    </div>


                    <Modal.Footer>
                        {this.props.cancelar ?
                            <Button_bootstrap className="left" onClick={this.props.onHide}>Cancelar</Button_bootstrap> : ""
                        }
                        <Button_bootstrap onClick={this.okClick}> Confirmar</Button_bootstrap>                
                    </Modal.Footer>

                </Modal>

            </div>
        );
    }
}