import React, {Component} from 'react';
import {Modal, Image, Spinner, Button, Form, Row,Col,Card,Nav,InputGroup,FormControl, Tab, Tabs, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { Switch } from 'devextreme-react/switch';
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import api from '../api';
import GetClientePessoa from '../services/get_cliente_pessoa';
import GetRotaPessoa from '../services/get_rota_pessoa';
import { DateBox } from 'devextreme-react';
import GetColeta from '../services/get_coleta';
import PostEntrega from '../services/post_entrega';
import { dinheiroMask } from '../dinheiro_mask'
import ContentSelectAll from 'material-ui/svg-icons/content/select-all';

const ENTREGA_CLIENTE_TIPO=4;
const ENTREGA_CLIENTE_LOCAL_TIPO=5;
const ENTREGA_ROTA_TIPO=6;


export class EntregaModal extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            loading:true,
            minimizar:false,
            changed:false,
            GeralListModal:null,
            // tipo_pagamentos: [{id: 2, descricao:"FOB"},{id: 4, descricao:"AR FOB"}],

            tipo_pagamentos: [{tipo_pagamento_id: 1, descricao:"CIF"},{tipo_pagamento_id: 2, descricao:"FOB"}
            , {tipo_pagamento_id: 3, descricao:"AR CIF"}, {tipo_pagamento_id: 4, descricao:"AR FOB"}
            , {tipo_pagamento_id: 5, descricao:"PIX CIF"}, {tipo_pagamento_id: 6, descricao:"PIX FOB"} ],

            tipo_pagamentos2: [{tipo_pagamento_id: 2, descricao:"FOB"}, {tipo_pagamento_id: 4, descricao:"AR FOB"}
                                , {tipo_pagamento_id: 6, descricao:"PIX FOB"} ],


            pagamento: {id: 0, descricao:""},
            data_previsao_coleta: null,
            valor: null,
            disable_pagamento:false,
            disable_valor:false,
            disable_pago:false,

            entrega: {entrega_id:0, cliente_id:0, cliente:"", local_id:0, local:"", rota_id:0, rota:""
            , tipo_pagamento_id:0, tipo_pagamento:"", valor:"", data_previsao:"", coleta_id: 0, observacao:""
            , cte:"", pago:false, rastreio_codigo: "", tda:"", nota_fiscal:"", cliente_tda:"", municipio_tda:""
            , municipio_frete_minimo:"", cliente_frete_minimo:""},

            coleta: {coleta_id:0, cliente_id:0, cliente:"", local_id:0, local:"", rota_id:0, rota:""
            , tipo_pagamento_id:0, tipo_pagamento:"", valor:"", data_previsao:"", entregas: [], observacao:""
            , cte:"", pago:false, tda:"", nota_fiscal:""},

            coleta2:{},

            activeKey:"Entrega",
            nova_entrega:{},
            salvando:false,
            height_modal:555,
            items_entrega:[],
            entregas:[],
            editados:[],
            hint_pago: "Não pago",
        };
        this.showGeralList = this.showGeralList.bind(this);
        // this.setInputFocus = this.setInputFocus.bind(this);
        this.myInputRef = React.createRef();

        // -------------------------------
    }

    //--------------------------
    async componentDidMount() {
        try{            
            this.setState({GridColetaEntrega: this.props.objeto});
            this.setState({minDateValue:  new Date((new Date()).getTime() - 1000 * 60 * 24 * 3)});

            let editados = {
                cliente:false
                , local:false
                , rota:false
                , pagamento:false
                , valor: false
                , previsao:false
                , observacao:false
                , cte:false
                , pago:false
                , tda:false
                , nota_fiscal: false };

            if(this.props.obj_selected !== null){
                this.setState({entrega: this.props.obj_selected
                                , editados: editados},
                () => {            
                    this.getColeta(); }
                );
            }else this.setState({loading: false});    
            
        
        }catch (response){
            this.props.MapaKseg.toastError("");
            this.fecharModal();}
         
    };
    //--------------------------------------------------------------------
    getColeta = () => {
        var instance = new GetColeta(null, this, this.props.MapaKseg, this.state.entrega.coleta_id);
        instance.get(); 
    } 
    //-------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closeEntregaModal();  
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeEntregaModal();
        this.state.GridColetaEntrega.visibleFilterrow();        
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarEntregaModal();
    };   
    //-----------------------------------------------
    setPagamento(event) {
        var list_aux = this.state.tipo_pagamentos.filter((item,index) =>{  return String(item.tipo_pagamento_id) === String(event.target.value);});
        let entrega = this.state.entrega;
        entrega.tipo_pagamento_id = list_aux[0].tipo_pagamento_id;
        entrega.tipo_pagamento = list_aux[0].descricao;

        let editados = this.state.editados;
        editados.pagamento = true;        

        if(event.target.value==3 || event.target.value==5)
            this.setState({disable_valor: true, valor:"0", disable_pago: true});  
        else this.setState({disable_valor: false, disable_pago: false});

        if(event.target.value==3 || event.target.value==4){
            entrega.pago = true;
            editados.pago = true;
        }else{
            entrega.pago = false;
            editados.pago = true;
        }

        this.setState({entrega: entrega, editados: editados, changed: true});
    }
    //--------------------------------------------
    setDataPrevisaoEntrega = (e) => {
        let entrega = this.state.entrega;
        entrega.data_previsao = this.formatarDateTime(e.value);
        let editados = this.state.editados;
        editados.previsao = true;
        this.setState({entrega: entrega, editados: editados, changed: true});
    }      
    //----------
    formatarDateTime(dtTime){    
        const y = String(dtTime).substring(11,15);
        const mm = this.formatarMes(String(dtTime).substring(4,7));
        const dd = String(dtTime).substring(8,10);
        const hm = String(dtTime).substring(16,21);

        // console.log("  *** dtTime: "+String(dtTime));
        // console.log("  *** y: "+y);
        // console.log("  *** mm: "+mm);
        // console.log("  *** mm: "+String(this.formatarMes(mm)));
        // console.log("  *** dd: "+dd);
        // console.log("  *** hm: "+hm);  
        const datetime = y+"-"+mm+"-"+dd+" "+hm;  // "Y-MM-DD H:M"
        return datetime;                  
      }
      //-------------------------
      formatarMes(mes){    
        if(mes==="Jan")
            return 1;
        else if (mes==="Feb")
            return 2;
        else if (mes==="Mar")
            return 3;     
        else if (mes==="Apr")
            return 4;     
        else if (mes==="May")
            return 5;     
        else if (mes==="Jun")
            return 6; 
        else if (mes==="Jul")
            return 7; 
        else if (mes==="Aug")
            return 8; 
        else if (mes==="Sep")
            return 9; 
        else if (mes==="Oct")
            return 10;    
        else if (mes==="Nov")
            return 11;
        else if (mes==="Dec")
            return 12;             
      }  
    //-------------------------
    setValorPagamento(event) {             
        let entrega = this.state.entrega;
        entrega.valor =  dinheiroMask(event.target.value);         

        let editados = this.state.editados;
        editados.valor = true;
        this.setState({entrega: entrega, editados: editados, changed: true});
    }
    //-------------------------
    setTDAEntrega(event) {             
        let entrega = this.state.entrega;
        entrega.tda =  dinheiroMask(event.target.value);         

        let editados = this.state.editados;
        editados.tda = true;
        this.setState({entrega: entrega, editados: editados, changed: true});
    }
    //-------------------------
    somarFreteTDA =(_frete, _tda) =>{
        _frete = parseFloat(String(_frete).replace(".", "").replace(",", "."));

        if (String(_tda) === "undefined")  _tda = 0;

        if (_tda !== "") _tda = parseFloat(String(_tda).replace(".", "").replace(",", "."));
        else _tda = 0;

        let total = '';

        if (String(_frete) === 'NaN') _frete = 0;
        if (String(_tda) === 'NaN') _tda = 0;

        total = _frete + _tda;
        total = total.toLocaleString('pt-br', {minimumFractionDigits: 2});
        
        return total;        
    }    
    //--------------------
    showGeralList= (e) => {   
        if(e.target.getAttribute('value') == ENTREGA_CLIENTE_TIPO){
            // this.props.Modals.showGeralList(this,"Clientes Entrega", ENTREGA_CLIENTE_TIPO, true);
            this.props.Modals.showPesquisarModal(this,"Clientes Entrega", true, 'Clientes Entrega');
        }else if(e.target.getAttribute('value') == ENTREGA_CLIENTE_LOCAL_TIPO)
            this.props.Modals.showGeralList(this,"Local da Entrega", ENTREGA_CLIENTE_LOCAL_TIPO);
        else if(e.target.getAttribute('value') == ENTREGA_ROTA_TIPO)
            this.props.Modals.showGeralList(this,"Rotas Entrega", ENTREGA_ROTA_TIPO);             
    }; 
    //-----------------------------------------------------
    getList= (geralListModal, tipo, filtro='') => { 
        this.setState({GeralListModal: geralListModal});  

        if(tipo == ENTREGA_CLIENTE_TIPO){ 
            var instance = new GetClientePessoa(null, this, this.props.MapaKseg, ENTREGA_CLIENTE_TIPO, filtro);
            instance.get(); 
        }else if(tipo == ENTREGA_CLIENTE_LOCAL_TIPO){ 
            var instance = new GetClientePessoa(null, this, this.props.MapaKseg, ENTREGA_CLIENTE_TIPO, filtro);
            instance.get(); 
        }else if(tipo == ENTREGA_ROTA_TIPO){ 
            var instance = new GetRotaPessoa(null, this, this.props.MapaKseg, ENTREGA_ROTA_TIPO, 0, null);
            instance.get(); 
        }
    }; 
    //--------------------------------------------------------------
    setListClientes= (_list, tipo) => {  
        let array =[];
        let descr = "";

        for (var i = 0; i < _list.length; i++) { 

            if(_list[i].cnpj_cpf == null) descr = _list[i].nome + " ("+_list[i].local+")"
            else descr = _list[i].nome +" ("+_list[i].cnpj_cpf +") ("+_list[i].local+")"

            array.push({
                index: _list[i].index
                  ,id: _list[i].cliente_id
                , descricao: descr
                , descricao2: _list[i].nome
                , cliente_tda : _list[i].cliente_tda
                , local_id: _list[i].local_id
                , local: _list[i].local
                , municipio_id : _list[i].municipio_id
                , municipio : _list[i].municipio
                , municipio_tda : _list[i].municipio_tda
                , rota_id: _list[i].rota_id
                , rota: _list[i].rota
                , cliente_frete_minimo: _list[i].cliente_frete_minimo
                , municipio_frete_minimo: _list[i].municipio_frete_minimo
                , selecionada: _list[i].selecionada
                , index_scroll: _list[i].index_scroll});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //--------------------
    setObjeto= (objeto, tipo) => { 
        let editados = this.state.editados;

        if(tipo == ENTREGA_CLIENTE_TIPO){
            let entrega = this.state.entrega;

            entrega.cliente_id = objeto.id;
            entrega.cliente = objeto.descricao2;
            entrega.local_id = objeto.local_id;
            entrega.local = objeto.local;
            entrega.rota_id = objeto.rota_id;
            entrega.rota = objeto.rota;
            entrega.municipio_tda = objeto.municipio_tda;
            entrega.cliente_tda = objeto.cliente_tda;
            entrega.municipio_frete_minimo = objeto.municipio_frete_minimo;
            entrega.cliente_frete_minimo = objeto.cliente_frete_minimo;

            if(this.state.disable_valor===false){
                if(objeto.municipio_frete_minimo === '0') entrega.valor = '';
                else entrega.valor = objeto.municipio_frete_minimo;
    
                if(objeto.cliente_frete_minimo !== '0') entrega.valor = objeto.cliente_frete_minimo;

                editados.valor = true;
                //--------
                entrega.tda = objeto.municipio_tda;
                if(objeto.cliente_tda !== '') entrega.tda = objeto.cliente_tda;
            }                    
            
            this.setState({entrega: entrega});

            editados.cliente = true;
            editados.local = true;
            editados.rota = true;            

            if(entrega.rota_id == 0){
                var instance = new GetRotaPessoa(null, this, this.props.MapaKseg, ENTREGA_ROTA_TIPO, objeto.municipio_id, entrega.cliente_id);
                instance.get(); 
            }

        }
        else if(tipo == ENTREGA_CLIENTE_LOCAL_TIPO){
            let entrega = this.state.entrega;
            entrega.local_id = objeto.local_id;
            entrega.local = objeto.local;
            this.setState({entrega: entrega});
            editados.local = true;
        }
        else if(tipo == ENTREGA_ROTA_TIPO){
            let entrega = this.state.entrega;
            entrega.rota_id = objeto.id;
            entrega.rota = objeto.descricao2;
            this.setState({entrega: entrega});
            editados.rota = true;
        }

        this.setState({editados: editados, changed: true});
    }
    //---------------------------------------------------
    setRota= (objeto) => { 
        let editados = this.state.editados;
        let entrega = this.state.entrega;
        entrega.rota_id = objeto.rota_id;
        entrega.rota = objeto.descricao;
        this.setState({entrega: entrega});
        
        editados.rota = true;   
        this.setState({editados: editados, changed: true});
    }
    //---------------------------------------------
    setPago= (e) => {
        let entrega = this.state.entrega;
        entrega.pago = e.value;

        let hint_pago = "Pago";
        if(String(e.value) === "true") hint_pago = "Pago";
        else if (String(e.value) == "false") hint_pago = "Não Pago";


        let editados = this.state.editados;
        editados.pago = true;

        this.setState({entrega: entrega, editados: editados, changed:true, hint_pago: hint_pago});
    }
     //--------------------
     setListRotas= (_list, tipo) => {  
        let array =[]
        for (var i = 0; i < _list.length; i++) { 
            array.push({
                index: _list[i].index
                , id: _list[i].rota_id
                , descricao: _list[i].descricao + " ("+_list[i].municipios+")"
                , descricao2: _list[i].descricao
                , selecionada: _list[i].selecionada
                , index_scroll: _list[i].index_scroll});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //---------------------------------------------
    validarFrete =(_valor, _frete_minimo) =>{
        let resultado = false;

        _valor = parseFloat(String(_valor).replace(".", "").replace(",", "."));
        _frete_minimo = parseFloat(String(_frete_minimo).replace(".", "").replace(",", "."));

        if(_valor < _frete_minimo){
            _frete_minimo = _frete_minimo.toLocaleString('pt-br', {minimumFractionDigits: 2});
            this.props.MapaKseg.toastError("O Frete Mínimo é R$ "+String(_frete_minimo));
        }else resultado = true;

        return resultado;        
    }    
    //---------------------------------------------
    validar = () => {              
        let valido=true;
   
        if(this.state.entrega.cliente_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Cliente da Entrega!");
            this.handleSelect("Entrega");
        }
        if(this.state.entrega.rota_id==0 && valido){
            valido=false;
            this.props.MapaKseg.toastError("Informe o Rota da Entrega!");
        }

        if(this.state.disable_valor===false){
            if(this.state.entrega.municipio_frete_minimo !=='0' || this.state.entrega.cliente_frete_minimo !=='0'){
                if(valido){
                    let frete_minimo = this.state.entrega.municipio_frete_minimo;
            
                    if(this.state.entrega.cliente_frete_minimo !== '0') frete_minimo = this.state.entrega.cliente_frete_minimo;
                    

                    valido = this.validarFrete(this.state.entrega.valor, frete_minimo);  
                }
            }
        }

        if(this.state.entrega.data_previsao==null && valido){
            valido=false;
            this.props.MapaKseg.toastError("Informe a Data de Previsão da Entrega!");
        }

        if(this.state.entrega.tipo_pagamento_id == 2 || this.state.entrega.tipo_pagamento_id == 4 || this.state.entrega.tipo_pagamento_id == 6)  // FOB
            if(this.state.disable_valor==false && valido){
                if(this.state.entrega.valor=="" || this.state.entrega.valor=="0" || this.state.entrega.valor==0 || this.state.entrega.valor==null ){
                    valido=false;
                    this.props.MapaKseg.toastError("Informe o Frete R$ !");
                }
            }
        
        if(this.state.entrega.tipo_pagamento_id === undefined){
            valido=false;
            this.props.MapaKseg.toastError("Informe o Tipo de Pagamento novamente!");
        } 
           
        if(this.state.entrega.rota_id === undefined){
            valido=false;
            this.props.MapaKseg.toastError("Informe a Rota Novamente!");
        }  

        // if(this.state.entrega.tda === '') this.state.entrega.tda = 0

        if(valido){ 
            // console.log("nova_entrega");
            // console.log(nova_entrega);
            this.setState({salvando:true},
            () => {            
                var instance = new PostEntrega(null, this, this.props.MapaKseg);
                instance.post();
             }
            );            

            // this.addEntrega(nova_entrega);
        }
    }; 
    //--------------------------------------
    concluido=(nova_entrega)=> {
        this.setState({salvando:false});
        this.setState({entrega: nova_entrega});
        // this.state.GridColetaEntrega.pushGridEntrega(nova_entrega);
        this.state.GridColetaEntrega.reloadGrid(nova_entrega);
        this.fecharModal();
    }
    //---------------------------------------------------------------
    setCliente = (cliente) => {
        cliente.id = cliente.cliente_id;
        cliente.descricao2 = cliente.nome;
        this.setObjeto(cliente, ENTREGA_CLIENTE_TIPO);
    }
    //---------------------------------------------------------------
    setColeta = (coleta) => {
        let entrega = this.state.entrega;

        // this.props.MapaKseg.toast(coleta.tipo_pagamento_id);
        
        let tipo_pagamentos_aux = this.state.tipo_pagamentos.filter((pagamento,index) =>{
            return pagamento.tipo_pagamento_id !== coleta.tipo_pagamento_id;
          });

        let tipo_pagamentos = [{tipo_pagamento_id: coleta.tipo_pagamento_id, descricao: coleta.tipo_pagamento}];
        tipo_pagamentos = tipo_pagamentos.concat(tipo_pagamentos_aux);

        if(coleta.tipo_pagamento_id===1 
            || coleta.tipo_pagamento_id===3
            || coleta.tipo_pagamento_id===5){                

            entrega.valor = coleta.valor;
            entrega.tda = coleta.tda;
            entrega.tipo_pagamento_id = coleta.tipo_pagamento_id;
            entrega.tipo_pagamento = coleta.tipo_pagamento;
            this.setState({ disable_pagamento: true, disable_valor: true, disable_pago: true}); 
        }else{
            tipo_pagamentos = this.state.tipo_pagamentos2;
        }    

        if(coleta.pago===true ){      
            entrega.pago = true;
        }else if(coleta.tipo_pagamento_id===3 || coleta.tipo_pagamento_id===4 ){
            entrega.pago = true;
        }
        this.setState({coleta: coleta, entrega: entrega, tipo_pagamentos: tipo_pagamentos},
            () => { this.setState({loading: false}); }
            ) 
    }
    //---------------------------------------------------------------
    showObservacao= (e) => {   
        this.props.Modals.showObservacaoModal(this, "Observação da Entrega", this.state.entrega.observacao, true, 'observacao');             
    }; 
    //---------------------------------------------------------------
    showComprovante= (e) => {   
        this.props.Modals.showComprovanteModal(this, "Comprovante", this.state.entrega);             
    };     
    //---------------------------------------------------------------
    showCTE= (e) => {   
        this.props.Modals.showObservacaoModal(this, "CTE da Entrega", this.state.entrega.cte, true, 'cte');             
    }; 
    //--------------------
    showNotaFiscal= (e) => {   
        this.props.Modals.showObservacaoModal(this, "Nota Fiscal da Entrega", this.state.entrega.nota_fiscal, true, 'nota_fiscal');             
    };
    //---------------------------------------------------------------
    confirmarObservacao(texto){
        let entrega = this.state.entrega;
        entrega.observacao = texto;

        let editados = this.state.editados;
        editados.observacao = true;

        this.setState({entrega: entrega, editados: editados, changed: true});        
    };
    //---------------------------------------------------------------
    confirmarCte(texto){
        let entrega = this.state.entrega;
        entrega.cte = texto;

        let editados = this.state.editados;
        editados.cte = true;

        this.setState({entrega: entrega, editados: editados, changed: true});        
    };
    //----------------------------------
    confirmarNotaFiscal(texto){
        let entrega = this.state.entrega;
        entrega.nota_fiscal = texto;

        let editados = this.state.editados;
        editados.nota_fiscal = true;

        this.setState({entrega: entrega, editados: editados, changed: true});        
    };        
    //---------------------------------------------------------------
    postGerarRastreioCodigo = async () => {
        try {       
            let body = new FormData()
            body.append('entrega_id', this.state.entrega.entrega_id);
            body.append('cte', this.state.entrega.cte);
            body.append('data_previsao', this.state.entrega.data_previsao);
            body.append('remetente_cliente_id', this.state.coleta.cliente_id);
            body.append('destinatario_cliente_id', this.state.entrega.cliente_id);
            body.append('token', localStorage.getItem('token'));

            let response = await api.post('/coletaweb_api/postGerarRastreioCodigo', body);
            // let response = await api.post('/teste_api/getEntregas', body);
            
            var responseJson = null;

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                // alert(resposta.cod_rastreio);
                
                let entrega = this.state.entrega;
                entrega.rastreio_codigo = resposta.cod_rastreio;

                this.setState({entrega: entrega, loading_codigo_rastreio: false});
                
            }
        } finally {      
            this.setState({loading_codigo_rastreio: false});
        }
    };
    //---------------------------------------------------------------
    handleSelect=(selectedKey)=> {
        this.setState({activeKey: selectedKey});
    }    
    //---------------------------------------------
    render(){
        return(
            <div className="drag1_1">
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            showCloseButton={false}
                            duration={600}
                            width={680}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.loading ?
                                        <Spinner className="centro3" animation="border" role="status"/>
                                    :
                                        <div style={{height: this.state.height_modal, overflow:'hidden'}}>
                                            
                                                {/*------------------------------------ */}
                                                <Form  >                    
                                                    {/* ----------------Tab---------------------------------------------------- */}
                                                    
                                                    <Tabs
                                                        id="controlled-tab-example"
                                                        activeKey={this.state.activeKey}
                                                        onSelect={this.handleSelect} 
                                                        className="mb-3"
                                                        style={{background:"#E9ECEF"}}
                                                        >
                                                            
                                                        <Tab eventKey="Coleta" title="Coleta" style={{height:"400px"}} >
                                                            <CustomScroll  flex="1">
                                                                <Form.Group as={Row} controlId="formColetaID">
                                                                    <Form.Label column sm={3}>
                                                                        Coleta ID
                                                                    </Form.Label>
                                                                    <Col sm={3}>
                                                                        <InputGroup className="mb-1" >
                                                                            <Form.Control disabled aria-describedby="basic-addon1" value={this.state.coleta.coleta_id}   />                                                                         
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formClienteColeta">
                                                                    <Form.Label column sm={3}>
                                                                        Cliente Coleta
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button disabled id="formClienteBt" variant="outline-secondary" >                                                                                   
                                                                                    <Image src={require('../img/text_box_search48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control disabled aria-describedby="basic-addon1" value={this.state.coleta.cliente}  >                                                                         

                                                                            </Form.Control>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formLocalColeta">
                                                                    <Form.Label column sm={3}>
                                                                        Local Coleta
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button disabled id="formLocalColetaBt"   variant="outline-secondary" >                                                                                   
                                                                                    <Image src={require('../img/text_box_search48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control disabled aria-describedby="basic-addon1" value={this.state.coleta.local}   />                                                                         

                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formRotaColeta">
                                                                    <Form.Label column sm={3}>
                                                                        Rota Coleta
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button disabled id="formRotaColetaBt"   variant="outline-secondary" >
                                                                                    <Image  src={require('../img/text_box_search48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control disabled aria-describedby="basic-addon1" value={this.state.coleta.rota} >                                                                                     

                                                                            </Form.Control>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>                                                                 

                                                                <Form.Group as={Row} controlId="formPagamento">
                                                                    <Form.Label column sm={3}>
                                                                        Pagamento
                                                                    </Form.Label>
                                                                    <Col sm={6}>
                                                                        <InputGroup className="mb-1">
                                                                            <Form.Control disabled as="select" aria-describedby="basic-addon1"  
                                                                                value={this.state.coleta.tipo_pagamento_id} 
                                                                                custom onChange={this.setPagamento.bind(this)}>
                                                                                    <option value={null}>
                                                                                        {}
                                                                                    </option>

                                                                                    {this.state.tipo_pagamentos.map(paga =>
                                                                                        <option value={paga.tipo_pagamento_id}>
                                                                                            {paga.descricao}
                                                                                        </option>
                                                                                    )}
                                                                            </Form.Control>

                                                                            <Form.Label column sm={4} className="padding_left" > Pago </Form.Label>
                                                                            <Switch disabled className="padding_top" defaultValue={this.state.coleta.pago} value={this.state.coleta.pago}  switchedOnText={"Sim"} switchedOffText={"Não"} onValueChanged={this.setPago}/>

                                                                        </InputGroup>

                                                                    </Col>
                                                                </Form.Group>                                                                

                                                                <Form.Group as={Row} controlId="formValor">
                                                                    <Form.Label column sm={3}>
                                                                        Frete R$
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1">
                                                                            {/* <FormControl type="number" disabled={this.state.disable_valor}  aria-describedby="basic-addon1" value={this.state.coleta.valor} defaultValue={0.0} onChange={this.setValorPagamento.bind(this)}/> */}
                                                                            <FormControl type="text" disabled  aria-describedby="basic-addon1" value={this.state.coleta.valor} defaultValue={0} onChange={this.setValorPagamento.bind(this)} />

                                                                            <Form.Label column sm={1.1} className="padding_left_right" > TDA </Form.Label>
                                                                            <FormControl type="text" disabled aria-describedby="basic-addon1" value={this.state.coleta.tda} defaultValue={0} />
                                                                        
                                                                            <Form.Label column sm={1.1} className="padding_left_right" >Total </Form.Label>
                                                                            <FormControl type="text" disabled  aria-describedby="basic-addon1"                                                                        
                                                                                value={this.somarFreteTDA(this.state.coleta.valor, this.state.coleta.tda) }  
                                                                                defaultValue={0}  />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>   

                                                                <Form.Group as={Row} controlId="formObservacao">
                                                                    <Form.Label column sm={3}>
                                                                        Observação
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button disabled id="formObservacaoBt" onClick={this.showObservacao} variant="outline-secondary" >                                                                                   
                                                                                    <Image  src={require('../img/pencil_48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control disabled aria-describedby="basic-addon1" value={this.state.coleta.observacao}  >                                                                         

                                                                            </Form.Control>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formCTE">
                                                                    <Form.Label column sm={3}>
                                                                        CTE
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button disabled id="formCTEBt" onClick={this.showCTE} variant="outline-secondary" >                                                                                   
                                                                                    <Image  src={require('../img/pencil_48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control  aria-describedby="basic-addon1" disabled value={this.state.coleta.cte} />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formNotaFiscal">
                                                                    <Form.Label column sm={3}>
                                                                        Nota Fiscal
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button disabled id="formNotaFiscalBt" onClick={this.showNotaFiscal} variant="outline-secondary" >                                                                                   
                                                                                    <Image  src={require('../img/pencil_48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control  aria-describedby="basic-addon1" disabled value={this.state.coleta.nota_fiscal} />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formPrevisaoColeta">
                                                                    <Form.Label column sm={3}>
                                                                        Previsão
                                                                    </Form.Label>
                                                                    <Col sm={8}>                                                                    
                                                                        <InputGroup className="mb-1" >
                                                                            <DateBox disabled displayFormat="dd/MM/yyyy HH:mm"  width={200}
                                                                                className="headerDateTime"  type="datetime" 
                                                                                placeholder={this.state.coleta.data_previsao2}
                                                                                /> 
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formDataColeta">
                                                                    <Form.Label column sm={3}>
                                                                        Data Coleta
                                                                    </Form.Label>
                                                                    <Col sm={8}>                                                                    
                                                                        <InputGroup className="mb-1" >
                                                                            <DateBox disabled displayFormat="dd/MM/yyyy HH:mm"  width={200}
                                                                                className="headerDateTime"  type="datetime" 
                                                                                placeholder={this.state.coleta.data_coleta2}
                                                                                /> 
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                            </CustomScroll> 
                                                        </Tab>
                                                    {/* ------------------------------------------------------------------------------------------------------ */}
                                                    
                                                        <Tab eventKey="Entrega" title="Entrega" style={{height:"400px"}} >                                                    
                                                            
                                                            <CustomScroll  flex="1">
                                                                <Form.Group as={Row} controlId="formEntregaID">
                                                                    <Form.Label column sm={3}>
                                                                        Entrega ID
                                                                    </Form.Label>
                                                                    <Col sm={3}>
                                                                        <InputGroup className="mb-1" >
                                                                            <Form.Control disabled aria-describedby="basic-addon1" value={this.state.entrega.entrega_id}   />                                                                         
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 
                                                                
                                                                <Form.Group as={Row} controlId="formClienteEntrega">                                                                
                                                                    <Form.Label column sm={3}>
                                                                            Cliente Entrega
                                                                        </Form.Label>
                                                                        <Col sm={8}>
                                                                            <InputGroup className="mb-1" >
                                                                                <InputGroup.Prepend >
                                                                                    <Button  value={ENTREGA_CLIENTE_TIPO} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                                        <Image value={ENTREGA_CLIENTE_TIPO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                                    </Button>
                                                                                </InputGroup.Prepend>

                                                                                <Form.Control  aria-describedby="basic-addon1" value={this.state.entrega.cliente} / >                                                                         
                                                                            </InputGroup>
                                                                        </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formLocalEntrega">
                                                                    <Form.Label column sm={3}>
                                                                        Local Entrega
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button disabled value={ENTREGA_CLIENTE_LOCAL_TIPO} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                                    <Image value={ENTREGA_CLIENTE_LOCAL_TIPO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control disabled aria-describedby="basic-addon1" value={this.state.entrega.local}   />                                                                         

                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formRotaEntrega">
                                                                    <Form.Label column sm={3}>
                                                                        Rota Entrega
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button  value={ENTREGA_ROTA_TIPO} onClick={this.showGeralList} variant="outline-secondary" >
                                                                                    <Image value={ENTREGA_ROTA_TIPO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control  aria-describedby="basic-addon1" value={this.state.entrega.rota} >                                                                                     

                                                                            </Form.Control>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formPagamento">
                                                                    <Form.Label column sm={3}>
                                                                        Pagamento
                                                                    </Form.Label>
                                                                    <Col sm={6}>
                                                                        <InputGroup className="mb-1" >
                                                                            <Form.Control as="select" aria-describedby="basic-addon1"  
                                                                                disabled={this.state.disable_pagamento}
                                                                                // defaultValue={this.state.tipo_pagamentos[1]} 
                                                                                value={this.state.entrega.tipo_pagamento_id} 
                                                                                custom onChange={this.setPagamento.bind(this)}>
                                                                                                <option value={null}>
                                                                                                    {}
                                                                                                </option>                                                                       
                                                                                                {this.state.tipo_pagamentos.map(paga =>
                                                                                                    <option value={paga.tipo_pagamento_id}>
                                                                                                        {paga.descricao}
                                                                                                    </option>
                                                                                                )}
                                                                                </Form.Control>
                                                                        
                                                                            <Form.Label column sm={4} className="padding_left" > Pago </Form.Label>
                                                                            <Switch className="padding_top" disabled={this.state.disable_pago} defaultValue={this.state.entrega.pago} value={this.state.entrega.pago}  switchedOnText={"Sim"} switchedOffText={"Não"} onValueChanged={this.setPago}/>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formValor">
                                                                    <Form.Label column sm={3}>
                                                                        Frete R$
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <FormControl type="text" disabled={this.state.disable_valor}  aria-describedby="basic-addon1" value={this.state.entrega.valor}  onChange={this.setValorPagamento.bind(this)} />
                                                                        
                                                                            <Form.Label column sm={1.1} className="padding_left_right" > TDA </Form.Label>
                                                                            <FormControl type="text" disabled={this.state.disable_valor}  aria-describedby="basic-addon1" value={this.state.entrega.tda}  onChange={this.setTDAEntrega.bind(this)}/>
                                                                            
                                                                            <Form.Label column sm={1.1} className="padding_left_right" >Total </Form.Label>
                                                                            <FormControl type="text" disabled  aria-describedby="basic-addon1" 
                                                                                value={this.somarFreteTDA(this.state.entrega.valor, this.state.entrega.tda) }  
                                                                                defaultValue={0}  />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>

                                                                    {/* <Form.Group as={Row} controlId="formPagamento">
                                                                        <Form.Label column sm={3}>
                                                                            Pagamento
                                                                        </Form.Label>
                                                                        <Col sm={3}>
                                                                            <InputGroup className="mb-1" >                                                        
                                                                                <Form.Control as="select" aria-describedby="basic-addon1"  
                                                                                    disabled={this.state.disable_pagamento}
                                                                                    custom onChange={this.setPagamento.bind(this)}>
                                                                                            
                                                                                    {this.state.tipo_pagamentos.map(paga =>
                                                                                        <option value={paga.id} >
                                                                                            {paga.descricao}
                                                                                        </option>
                                                                                    )}
                                                                                </Form.Control>
                                                                            </InputGroup>
                                                                        </Col>

                                                                        <Col sm={3}>
                                                                            <InputGroup className="mb-1" >
                                                                                <FormControl type="text" placeholder="Valor" disabled={this.state.disable_valor}  aria-describedby="basic-addon1" value={this.state.entrega.valor}  onChange={this.setValorPagamento.bind(this)}/>
                                                                            </InputGroup>
                                                                        </Col>
                                                                        <Col sm={1}>
                                                                            <InputGroup className="mb-1" >
                                                                                <Switch className="padding_top" hint={this.state.hint_pago} disabled={this.state.disable_pago} defaultValue={this.state.entrega.pago} value={this.state.entrega.pago} switchedOnText={"Pago"} switchedOffText={"Não"} onValueChanged={this.setPago}/>
                                                                            </InputGroup>
                                                                        </Col>
                                                                    </Form.Group>      */}
                                                                                                                                

                                                                    {/* <Form.Group as={Row} controlId="formPagamento">
                                                                        <Form.Label column sm={3}>
                                                                            Pagamento
                                                                        </Form.Label>
                                                                        <Col sm={8}>
                                                                            <InputGroup className="mb-1" >                                                        
                                                                                <Form.Control as="select" aria-describedby="basic-addon1"  
                                                                                disabled={this.state.disable_pagamento}
                                                                                custom onChange={this.setPagamento.bind(this)}>
                                                                                            
                                                                                    {this.state.tipo_pagamentos.map(paga =>
                                                                                        <option value={paga.id} >
                                                                                            {paga.descricao}
                                                                                        </option>
                                                                                    )}
                                                                                </Form.Control>
                                                                            </InputGroup>
                                                                        </Col>
                                                                    </Form.Group>                                                                 

                                                                    <Form.Group as={Row} controlId="formValor">
                                                                        <Form.Label column sm={3}>
                                                                            Valor R$
                                                                        </Form.Label>
                                                                        <Col sm={6}>
                                                                            <InputGroup className="mb-1" >
                                                                                <FormControl type="text" disabled={this.state.disable_valor}  aria-describedby="basic-addon1" value={this.state.entrega.valor}  onChange={this.setValorPagamento.bind(this)}/>
                                                                                <Form.Label column sm={4} className="padding_left" > Pago </Form.Label>
                                                                                <Switch className="padding_top" disabled={this.state.disable_pago} defaultValue={this.state.entrega.pago} value={this.state.entrega.pago} switchedOnText={"Sim"} switchedOffText={"Não"} onValueChanged={this.setPago}/>
                                                                            </InputGroup>
                                                                        </Col>
                                                                    </Form.Group>                                                                 */}

                                                                <Form.Group as={Row} controlId="formObservacao">
                                                                    <Form.Label column sm={3}>
                                                                        Observação
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formObservacaoBt" onClick={this.showObservacao} variant="outline-secondary" >                                                                                   
                                                                                    <Image  src={require('../img/pencil_48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control  aria-describedby="basic-addon1" disabled value={this.state.entrega.observacao} /> 
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formCTE">
                                                                    <Form.Label column sm={3}>
                                                                        CTE
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formCTEBt" onClick={this.showCTE} variant="outline-secondary" >                                                                                   
                                                                                    <Image  src={require('../img/pencil_48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control  aria-describedby="basic-addon1" disabled value={this.state.entrega.cte} />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formNotaFiscalEntrega">
                                                                    <Form.Label column sm={3}>
                                                                        Nota Fiscal
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formNotaFiscalBtEntrega" onClick={this.showNotaFiscal} variant="outline-secondary" >                                                                                   
                                                                                    <Image  src={require('../img/pencil_48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control  aria-describedby="basic-addon1" disabled value={this.state.entrega.nota_fiscal} />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formCTE">
                                                                    <Form.Label column sm={3}>
                                                                        Cód. Rastreio
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <OverlayTrigger
                                                                                    placement="right"
                                                                                    delay={{ show: 250, hide: 400 }}
                                                                                    overlay={<Tooltip id="button-tooltip">Gerar código de Rastreio </Tooltip>}>
                                                                                    <Button id="formCTEBt" onClick={this.postGerarRastreioCodigo} variant="outline-secondary"  >                                                                                   
                                                                                        <Image  src={require('../img/autorenew48.png')} width='20' />                                                                                    
                                                                                    </Button>
                                                                                </OverlayTrigger>                                                                                                                                                                
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control  aria-describedby="basic-addon1" placeholder="Código de Rastreio" disabled value={this.state.entrega.rastreio_codigo} />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formPrevisaoEntrega">
                                                                    <Form.Label column sm={3}>
                                                                        Previsão
                                                                    </Form.Label>
                                                                    <Col sm={8}>                                                                    
                                                                        <InputGroup className="mb-1" >
                                                                            <DateBox displayFormat="dd/MM/yyyy HH:mm"  width={200}
                                                                                className="headerDateTime"  type="datetime" 
                                                                                onValueChanged={this.setDataPrevisaoEntrega}
                                                                                // min={this.state.minDateValue}
                                                                                placeholder={this.state.entrega.data_previsao2}
                                                                                /> 
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>   

                                                                <Form.Group as={Row} controlId="formComprovante">
                                                                    <Form.Label column sm={3}>
                                                                        Comprovante
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formComprovanteBt" onClick={this.showComprovante} variant="outline-secondary" >
                                                                                    <Image  src={require('../img/file.svg')} width='20' style={{ tintColor: '#272727'}}/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>                                                                            
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>
                                                            </CustomScroll>  
                                                        </Tab>                                                                                                                                                                                     
                                                    </Tabs>                                                     
                                                </Form>
                                                {/* ----------------------------------- */}
                                            


                                            <Modal.Footer >
                                                        {/* <div className="align_bottom_left">
                                                            <Button onClick={this.onVoltarClick}><img src={require('./img/arrow_left24.png')} title={"Voltar"}/></Button>
                                                        </div> */}
                                                            <div className="align_bottom_right_top">                                                                
                                                                {/* <Button disabled  onClick={this.validar}> */}
                                                                <Button disabled={!this.state.changed} onClick={this.validar}>
                                                                    {this.state.salvando
                                                                        ? <Spinner as="span" animation="grow" role="status" aria-hidden="true"/>                                                                
                                                                    :
                                                                    <div>
                                                                        <img src={require('../img/checkbold24.png')}/> 
                                                                    </div>
                                                                    }                                                                
                                                                </Button>
                                                            </div> 
    

                                            </Modal.Footer>

                                        </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}