import React, {Component} from 'react';
import SideNav, {  NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import {Modal, ListGroup, Spinner, Button, Carousel, Pagination, Navbar, Nav, Form, FormControl} from 'react-bootstrap';

// import {ToastContainer, ToastStore} from 'react-toasts';

import './estilo.css';


export class paginationSwipe extends Component{
    constructor(props){
        super(props);
        this.state = {
            divWidth: {
               width: 1900
            },
            divHeight: {
                height:500
             },

             tempGridModalShow:false,
             gruposVeiculoModalShow:false,
             relatoriosModalShow:false,
       }
    }

    render(){
        return(
            <div>
                <nav className={"navbar navbar-expand-md navbar-dark "+this.state.backgroundBottomSheet2}>     
                    <div id="outer">
                        <Button variant={this.state.tabHistoricoSel? this.state.light : this.state.outlineLight}
                                onClick={() => this.buttonHistoricoClick()} >HISTÓRICO</Button>
                        <text>    </text>
                        <Button variant={this.state.tabComandoSel? this.state.light : this.state.outlineLight}
                                onClick={() => this.buttonComandoClick()} >COMANDO</Button>
                    </div>
              
                </nav>
            </div>
        );
    }
}