import React from 'react';
import Chart, {
  ArgumentAxis,
  Legend,
  CommonSeriesSettings,
  SeriesTemplate,
  ValueAxis,
  Label,
  Export,
  Tick,
} from 'devextreme-react/chart';

// import dados from '../dados/veiculos_kmpercorrido_data.js';

// const customizeText = (e) => `Day ${e.value}`;
const KmcustomizeText = (e) => `${e.value} km`;

export class ColetasMotoristasChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  //-----------------
  render() {
    return (
      <Chart
        title="Coletas por Motoristas"
        // dataSource = {dados.getDados()}
        dataSource = {this.props.list}
        rotated={true}
        id="chart"
      >
        <ArgumentAxis>
          {/* <Label customizeText={customizeText} /> */}
          <Label />
        </ArgumentAxis>

        <ValueAxis>
          <Tick visible={false} />
          <Label visible={false} />
        </ValueAxis>

        <CommonSeriesSettings
        valueField="quantidade_coletas"
        argumentField="motorista"
        type="bar"
        ignoreEmptyPoints={true}
        >
          <Label
              visible={true}
            />
        </CommonSeriesSettings>  

        <SeriesTemplate nameField="motorista" />

        <Legend visible={false} />

        <Export enabled={true} />
      </Chart>

    );
  }
}
