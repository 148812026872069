import  {Component} from 'react';
import api from '../api';

export default class GetCliente extends Component{
    constructor(props, objeto, MapaKseg, tipo, cliente_id){
        super(props);
        this.state = {
             list: [],
             objeto: objeto,
             MapaKseg: MapaKseg,
             tipo: tipo,
             cliente_id: cliente_id
       }
    }
    //------------------------------------------------
    get = async () => {
        try {
            let body = new FormData()
            if(this.state.cliente_id !== 0)
                body.append('cliente_id', this.state.cliente_id);

            body.append('pessoa_id', this.state.MapaKseg.state.pessoa_id);    
            body.append('token', localStorage.getItem('token'))

            const response = await api.post('/cadastro_api/getClientes', body);
            // const response = await api.post('/cadastro_api/getClientesLimit', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let obj = null;
                let list = this.state.list;                

                for (var i = 0; i < resposta.length; i++) {                        
                    obj = {    
                        index: i+1,
                        cliente_id: resposta[i].cliente_id,
                        nome: resposta[i].nome,
                        descricao: resposta[i].descricao,
                        local_id: resposta[i].local_id,
                        local: resposta[i].local,    
                        municipio_id: resposta[i].municipio_id,
                        municipio: resposta[i].municipio,                              
                        pessoa_id: resposta[i].pessoa_id,
                        nome_social: resposta[i].nome_social,           
                        imagem: "data:image/png;base64,"+resposta[i].imagem, 
                        imagem_dir: resposta[i].imagem_dir,
                        telefone: resposta[i].telefone,
                        cnpj_cpf: resposta[i].cnpj_cpf,
                        juridica: resposta[i].juridica,
                        tipo_pagamento_id: resposta[i].tipo_pagamento_id,
                        tipo_pagamento: resposta[i].tipo_pagamento,
                        email: resposta[i].email,
                        tda: resposta[i].tda,
                        operacao:0       
                    };                   

                    list.push(obj);
                    this.setState({list: list});
                }                
            }
        } finally {      
            this.state.objeto.setListCliente(this.state.list, this.state.tipo);
            }
    };
}