import React, {Component} from 'react';
import {Modal, Image, Spinner, Button, Form, Row,Col,Card,Nav,InputGroup,FormControl} from 'react-bootstrap';
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import GetPessoa from '../services/get_pessoa';
import PostPessoaCad from '../services/post_pessoa_cad';
import { CheckBox } from 'devextreme-react/check-box';

const PESSOA_TIPO =1;
const TIPO_GRUPO =2;

export class PessoaCadModal extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            load:true,
            minimizar:false,
            changed:false,
            pessoa:null,

            GeralListModal:null,            
            PessoaGrid:null,

            disable_nome_social:true,
            disable_nome_fantasia: true,
            disable_cnpj:true,
            disable_cpf: true,

            height_modal:410,
            height_grid:0,
            items:[],
            editados: {},

            salvando:false,            
        };

         // -------------------------------
        this.dataGridRef = React.createRef();
    
        this.selectedRowsData = [];

        this.getSelectedData = () => {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData();
            this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            });
        }

        this.onValueChangedCheck = this.onValueChangedCheck.bind(this);

      }
    //---------------------------------------------------------------
    get dataGrid() {
        return this.dataGridRef.current.instance;
    }
    //-------------------------------------------------------------------
    async componentDidMount() {
        // INICIALIZAR VARIÁVEIS

        this.setState({PessoaGrid: this.props.objeto});

        let editados = {
              nome_social:false
            , nome_fantasia:false
            , pessoa_juridica:false
            , cnpj: false
            , cpf: false
             };

        let pessoa = this.props.obj_selected;

        if(pessoa.pessoa_id==0){
            this.setState({
                disable_nome_social: false,
                disable_nome_fantasia: false,
                disable_cnpj:false,
                disable_cpf: false,
            });

            editados.pessoa_juridica = true;
        }

        this.setState({
            pessoa: pessoa       
                ,editados: editados        
                , height_grid: this.state.height_modal-420},
            () => { 
                if(pessoa.pessoa_id != 0){
                    // this.getUsuariosGrupo()
                    // this.getTokensUsuario()
                }
                this.setState({load:false}) }
          )
    }; 
    //----------------------------------------------------------------
    getPessoa = () =>{
        var instance = new GetPessoa(null, this, this.props.MapaKseg,0, this.state.pessoa.pessoa_id );
        instance.get();   
    };  
    //--------------------------------------------------------------------
    setListPessoa = (_list) => {
        let pessoa = this.state.pessoa;
        let obj = _list[0];

        pessoa.pessoa_id = obj.pessoa_id;
        pessoa.nome_social = obj.nome_social;
        pessoa.nome_fantasia = obj.nome_fantasia;
        pessoa.pessoa_juridica = obj.pessoa_juridica;
        pessoa.cnpj = obj.cnpj;
        pessoa.cpf = obj.cpf;

        this.setState({pessoa: pessoa});
        this.state.PessoaGrid.setPessoa(this.state.pessoa);
    }  
    //------------------------------------------------------------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closePessoaCadModal();  
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closePessoaCadModal();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarPessoaCadModal();
    };
    //-------------------------------------------------------------
    ativarEditCampo(event) {       
        if(event.target.id==="formNomeSocial"){
            this.setState({disable_nome_social: false}); 
        }else
        if(event.target.id==="formNomeFantasia"){
            this.setState({disable_nome_fantasia: false}); 
        }else
        if(event.target.id==="formCNPJ"){
            this.setState({disable_cnpj: false}); 
        }else
        if(event.target.id==="formCPF"){
            this.setState({disable_cpf: false}); 
        }
    }      
    //------------------------------------------------------
    updateInputValue(event) {
        var editados = this.state.editados;
        var pessoa = this.state.pessoa;

        if(event.target.id==="formNomeSocial"){
            pessoa.nome_social = event.target.value;
            editados.nome_social = true;
        }else 
        if(event.target.id==="formNomeFantasia"){
            pessoa.nome_fantasia = event.target.value;
            editados.nome_fantasia = true;
        }else 
        if(event.target.id==="formCNPJ"){
            pessoa.cnpj = String(event.target.value).trim();
            editados.cnpj = true;
        }else 
        if(event.target.id==="formCPF"){
            pessoa.cpf = String(event.target.value).trim();
            editados.cpf = true;
        }

        this.setState({pessoa: pessoa});
        this.setState({editados: editados, changed: true});
     }
     //---------------------------------------------------------------
     onValueChangedCheck(args) {
        var editados = this.state.editados;
        var pessoa = this.state.pessoa;

        pessoa.pessoa_juridica = args.value;                     
        editados.pessoa_juridica = true;

        this.setState({pessoa: pessoa});
        this.setState({editados: editados, changed: true});
      }
    //--------------------------------------
    concluido=()=> {
        this.getPessoa();
        this.setState({salvando:false, changed:false });                    
    }
    //--------------------------------------
    validar=()=> {
        let valido = true;

        if(this.state.pessoa.nome_social.trim()==="" && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Nome Social!");
        }

        if(this.state.pessoa.nome_fantasia.trim()==="" && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Nome Fantasia!");
        }       

        if(valido==true){
            this.setState({salvando:true, changed:true});
            var instance = new PostPessoaCad(null, this, this.props.MapaKseg);
            instance.post(); 
        }
    }
    //-------------------------
    render(){
        return(
            <div className="drag1">
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={600}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.load ?
                                        <Spinner className="centro3" animation="border" role="status">
                                            <span className="sr-only"></span>
                                        </Spinner>
                                    :
                                        <div style={{height: this.state.height_modal, overflow:'hidden'}}>
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                                                {/*------------------------------------ */}
                                                <Form>                                                       
                                                    <Form.Group className="mb-1" as={Row} controlId="formNomeSocial">
                                                        <Form.Label column sm={3}>
                                                            Pessoa
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formNomeSocial" variant="outline-secondary" onClick={this.ativarEditCampo.bind(this)}><Image id="formNomeSocial" src={require('../img/pencil_48.png')} width='20'/></Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>{this.state.pessoa.pessoa_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.pessoa.nome_social} disabled={this.state.disable_nome_social} onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>    

                                                    <Form.Group className="mb-1" as={Row} controlId="formNomeFantasia">
                                                        <Form.Label column sm={3}>
                                                            Nome Fantasia
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formNomeFantasia" variant="outline-secondary" onClick={this.ativarEditCampo.bind(this)}><Image id="formNomeFantasia" src={require('../img/pencil_48.png')} width='20'/></Button>
                                                                </InputGroup.Prepend>                                                     
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.pessoa.nome_fantasia} disabled={this.state.disable_nome_fantasia} onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>            

                                                     <Form.Group className="mb-1" as={Row} controlId="formCNPJ">
                                                        <Form.Label column sm={3}>
                                                            CNPJ
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formCNPJ" variant="outline-secondary" onClick={this.ativarEditCampo.bind(this)}><Image id="formCNPJ" src={require('../img/pencil_48.png')} width='20'/></Button>
                                                                </InputGroup.Prepend>                                                     
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.pessoa.cnpj} disabled={this.state.disable_cnpj} onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>    

                                                    <Form.Group className="mb-1" as={Row} controlId="formCPF">
                                                        <Form.Label column sm={3}>
                                                            CPF
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formCPF" variant="outline-secondary" onClick={this.ativarEditCampo.bind(this)}><Image id="formCPF" src={require('../img/pencil_48.png')} width='20'/></Button>
                                                                </InputGroup.Prepend>                                                     
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.pessoa.cpf} disabled={this.state.disable_cpf} onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>                            
                            
                                                    <Form.Group className="mb-1" as={Row} controlId="formPessoaJuridica">
                                                        <Form.Label column sm={3}>
                                                        Pessoa Jurídica
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <CheckBox value={this.state.pessoa.pessoa_juridica} onValueChanged={this.onValueChangedCheck}  />
                                                        </Col>
                                                    </Form.Group>                                          
                                        

                                                                                                                                                          

                                                </Form>
                                                {/* ----------------------------------- */}
                                            </CustomScroll>


                                            <Modal.Footer >
                                                <div className="align_bottom_right">
                                                    <Button disabled={!this.state.changed} onClick={this.validar}>
                                                        {this.state.salvando
                                                            ? <Spinner size="sm"  as="span" animation="grow" role="status" aria-hidden="true"/>                                                                
                                                        :
                                                        <div>
                                                            <img src={require('../img/checkbold24.png')}/> Salvar 
                                                        </div>
                                                        }                                                                
                                                    </Button>
                                                </div> 
                                            </Modal.Footer>

                                        </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}