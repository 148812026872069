import React, {Component} from 'react';
import {Modal, ListGroup, Spinner, Button} from 'react-bootstrap';
import './estilo.css';
import { ToastStore} from 'react-toasts';
import {CabecalhoModal} from './CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';

export class ListaModal extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            loading:false,
            minimizar:false,
        };
      }
    //--------------------------
    async componentDidMount() {
        // ToastStore.success("componentDidMount!");

        if(this.props.Modals.state.GridColetaEntrega != null)
            this.props.Modals.state.GridColetaEntrega.visibleFilterrowFalse();

        var list=[];
        if (this.props.tipoChamadaLista===1){
            list = [{id:1, nome:"Temperatura"}];
        }else if(this.props.tipoChamadaLista===2){
            list = [{id:1, nome:"Posições do Veículo"}
                    ,{id:2, nome:"Eventos Gerados"}
                    // ,{id:3, nome:"Coletas"}
                    ,{id:4, nome:"Entregas"}
                    ,{id:5, nome:"Geral Coletas/Entregas"}];
            
            if(this.props.MapaKseg.state.gerente === true) 
                list.push({id:6, nome:"Relatório de Caixas"});

            list.push({id:7, nome:"Jornada Motorista"});

        }else if(this.props.tipoChamadaLista===3){
            list = [{id:1, nome:"Quilometragem percorrida"}
                    ,{id:2, nome:"Velocidade média"}
                    ,{id:3, nome:"Quantidade de posições"}
                    ,{id:4, nome:"Quantidade de Eventos gerados"}
                    // ,{id:5, nome:"Coletas e Entregas"}
                ];

            if (this.props.MapaKseg.state.pessoa_id == 1){
                 list.push({id:5, nome:"Coletas e Entregas"});
                 list.push({id:6, nome:"Coletas por Clientes"});
                 list.push({id:7, nome:"Entregas por Clientes"});
                 list.push({id:8, nome:"Coletas por Motoristas"});
                 list.push({id:9, nome:"Entregas por Motoristas"});
            }
        }
        this.setState({list:list});
    };
    //-------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});

        if (this.props.tipoChamadaLista===1){
            this.props.Modals.closeMonitoresModal();
        }else if(this.props.tipoChamadaLista===2){
            this.props.Modals.closeRelatoriosModal();
        }
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        if (this.props.tipoChamadaLista===1){
            this.props.Modals.closeMonitoresModal();
        }else if(this.props.tipoChamadaLista===2){
            this.props.Modals.closeRelatoriosModal();
        }else if(this.props.tipoChamadaLista===3){
            this.props.Modals.closeGraficosListaModal();
        }

        if(this.props.Modals.state.GridColetaEntrega != null)
            this.props.Modals.state.GridColetaEntrega.visibleFilterrow();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false){
            if (this.props.tipoChamadaLista===1){
                this.props.Modals.desativarMonitoresModal();
            }else if(this.props.tipoChamadaLista===2){
                this.props.Modals.desativarRelatoriosModal();
            }else if(this.props.tipoChamadaLista===3){
                this.props.Modals.desativarGraficosListaModal();
            }
        }
    };
    //----------------------------------------------RelPosicoesVeiModal--------------------------------------------------------------------
    showItemModal = (item) => {
        if (this.props.tipoChamadaLista===1){
            if(item===1)
                this.props.Modals.showTempGridModal();
        }else if(this.props.tipoChamadaLista===2){
            if(item===1)
                this.props.Modals.showRelPosicoesVeiModal();
            else if(item===2)
                this.props.Modals.showRelEventosVeiModal();    
            else if(item===3)
                this.props.Modals.showRelColetaModal();     
            else if(item===4)
                this.props.Modals.showRelEntregaModal();      
            else if(item===5)
                this.props.Modals.showRelColetaEntregaModal();     
            else if(item===6)
                this.props.Modals.showRelCaixaModal();       
            else if(item===7)
                this.props.Modals.showRelJornadaMotoristaModal();
                
        }else if(this.props.tipoChamadaLista===3){
            if(item===1) this.props.Modals.showGraficosKmPercorridaModal();
            else if(item===2) this.props.Modals.showGraficosVelocidadeMediaModal();    
            else if(item===3) this.props.Modals.showGraficosQuantidadePosicoesModal();    
            else if(item===4) this.props.Modals.showGraficosQuantidadeEventosModal();
            else if(item===5) this.props.Modals.showGraficosColetasEntregasModal();    
            else if(item===6) this.props.Modals.showGraficosColetasClientesModal(); 
            else if(item===7) this.props.Modals.showGraficosEntregasClientesModal(); 
            else if(item===8) this.props.Modals.showGraficosColetasMotoristasModal(); 
            else if(item===9) this.props.Modals.showGraficosEntregasMotoristasModal(); 
        }

        this.minimizarModal();
    }; 
    //----------------------------
    sequenciaUpdate = () => {
        this.props.Modals.setModalSelecionado("ListaModal");
    };
    render(){
        //--------------------
        return(
            <div className="drag4" onClick={()=>this.sequenciaUpdate()}>
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={500}
                            height={350}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.loading ?
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    :
                                        <div style={{height: '300px', overflow: 'hidden'}}>
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                                                <ListGroup>{
                                                    this.state.list.map((item) =>
                                                            <ListGroup.Item action variant="" onClick={()=>this.showItemModal(item.id)}>
                                                                <div>
                                                                    {item.nome+'  '}
                                                                </div>
                                                            </ListGroup.Item>                                                                                                                                                                        
                                                    )}
                                                    </ListGroup>
                                                </CustomScroll>
                                            </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}