import React, {Component} from 'react';
import {Spinner, Image, Navbar, Button, Form,InputGroup,FormControl} from 'react-bootstrap';
// import api from './api';
import {ToastContainer, ToastStore} from 'react-toasts';
import api from './api';
import { browserName, osName} from "react-device-detect";


class Login extends Component{
    constructor(props){
        super(props);

        this.state = {
            list: [1, 2, 3],
            email:null,
            senha:null,

            loading:true,
            tema_mapa:1,
            bloqueado_entrar: false,
            logando:false,

            mostrarSenha:false,
            type_senha: "password",
        };
    }
    //---------------------------------
    validarCampos = async () => {
        if (this.state.email==null){
            ToastStore.error("O campo Email é obrigatório!");
        }else if(this.state.senha==null){
            ToastStore.error("O campo Senha é obrigatório!");
        }else{
            this.setState({bloqueado_entrar:true, logando:true});
            this.login();
        }
    }
    //---------------------------------
    login2 = async () => {
        let body = new FormData()

        body.append('email', this.state.email)
        body.append('senha', this.state.pass)

        // localStorage.setItem('login', this.props.login)

        const response = await api.post('/login_api/login', body);
        const {pessoa_id, token, usuario_id,nome, grupo_id, tema_mapa, nome_social, grupo_id_atual} = response.data;
        this.setState({pessoa_id: pessoa_id});
        this.setState({token: token});
        localStorage.setItem('token', token);
        localStorage.setItem('nome_social', nome_social);
        this.setState({usuario_id: usuario_id});
        this.setState({nome: nome});
        this.setState({grupo_id: grupo_id});
        this.setState({tema_mapa: tema_mapa});
        this.setState({nome_social: nome_social});
    }
    //-----------------------------------
    login = async () => {
        // console.log(" ******login()");
        // ToastStore.success("login()");    
        try{
            let body = new FormData()
                body.append('email', this.state.email)
                body.append('senha', this.state.senha)
                // ToastStore.success("fuck");
                const response = await api.post('/login_api/login', body);
                var responseJson = null;
        
                if (response.status !== 403){
        
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json);                    
                    // ToastStore.success(resposta.token);
                    console.log("token: "+resposta.token);
                    
                    // ToastStore.success(resposta.sucess);

                    this.setState({pessoa_id: resposta.pessoa_id});
                    this.setState({token: resposta.token});
                    localStorage.setItem('token', resposta.token)
                    localStorage.setItem('pessoa_id', resposta.pessoa_id)
                    localStorage.setItem('usuario_id', resposta.usuario_id)
                    this.setState({usuario_id: resposta.usuario_id});
                    this.setState({nome: resposta.nome});
                    this.setState({tema_mapa: resposta.tema_mapa});
                    this.setState({nome_social: resposta.nome_social});
                    localStorage.setItem('nome_social', resposta.nome_social);
                    this.setState({grupo_id: resposta.grupo_id_atual});
                    localStorage.setItem('grupo_id', resposta.grupo_id_atual);
                    this.setState({gerente: resposta.gerente});
                    
                    // if (grupo_id !== null)
                    //     this.setState({grupo_id: localStorage.getItem('grupo_id')});
                    // else{
                    //     this.setState({grupo_id: resposta.grupo_id});
                    //     localStorage.setItem('grupo_id', resposta.grupo_id)
                    // }
                   

                    if(resposta.grupo_id)
                        this.props.onAuthloadChange(true, resposta.pessoa_id, resposta.usuario_id, resposta.nome, resposta.grupo_id_atual, this.state.email
                            , this.state.senha, resposta.tema_mapa, resposta.nome_social, resposta.mapa_ativo, resposta.modo_mapa, resposta.gerente)
                    else ToastStore.error("Email ou senha incorretos!");
                    // if (resposta.sucess===0){
                    //     ToastStore.error("Login ou Senha incorreto!");    
                    // }else if(resposta.sucess===1){
                    //     this.navigateToPage(resposta.token);
                    // }

                }
            }catch (response){
                this.setState({errorMessage: response.data});
                this.setState({bloqueado_entrar:false, logando:false});
            }finally{
                this.setState({bloqueado_entrar:false, logando:false});
            }

    };
    //---------------------------------
    change = e =>{
        this.setState({
            [e.target.name]: e.target.value
        });
        // console.log(e.target.name+": "+e.target.value);
        // ToastStore.success(e.target.value);
    };
    //--------------------------------------
    clickHandler=()=> {
        this.setState(
            {mostrarSenha: !this.state.mostrarSenha},
            () => { this.state.mostrarSenha ? this.setState({type_senha:"text"}) : this.setState({type_senha:"password"})}
          )
    }
    //------------------------------------
    render(){
        return(
            <div >
                <Navbar className="bg_roxo">
                    <Navbar.Brand className="loginLogo">
                    
                    <img
                        src="img/kseg_logo_escrita7.png"
                        width="230"
                        height="40"
                        className="d-inline-block align-top"
                    />
                    {'    '}                           
                    </Navbar.Brand>
                </Navbar>
                
                <div className="back_login" >
                    <br/>
                    <div className="loginsenha">

                            <div className="container_login">
                                <label for="uname"><b>Email</b></label>
                                <Form.Control type="email" placeholder="Informe Email" name="email" required  onChange={e => this.change(e)}/>
                                
                                <br></br>
                                <label for="psw"><b>Senha</b></label>
                                {/* <Form.Control type="password" placeholder="Informe a senha" name="senha" required  onChange={e => this.change(e)}/>     */}

                                <InputGroup className="mb-3" >
                                    <FormControl type={this.state.type_senha}  aria-describedby="basic-addon1" placeholder="Informe a senha" name="senha" required   onChange={e => this.change(e)}/>
                                    <InputGroup.Append>
                                        <Button id="formMostrarSenha" variant="outline-secondary" onClick={this.clickHandler}> {this.state.mostrarSenha ? <Image id="formSenha" src={require('./img/eye48.png')} width='20' /> : <Image id="formSenha" src={require('./img/eye_off48.png')} width='20' /> } </Button>
                                    </InputGroup.Append>
                                </InputGroup>                                             
                                <br></br>

                                <Button autoFocus disabled={this.state.bloqueado_entrar} onClick={this.validarCampos}>
                                    {this.state.logando
                                        ? <Spinner size="sm" as="span" animation="grow" role="status" aria-hidden="true" />                                                                
                                        :<div> Entrar </div>
                                    }                                                                
                                </Button>
                            </div>
                    </div>
                </div>
                <div> <ToastContainer position={ToastContainer.POSITION.BOTTOM_CENTER} store={ToastStore}/> </div>
        
            </div>
        )
    }
}

export default Login;