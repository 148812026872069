import React, {Component} from 'react';
import './estilo.css';
import GridVeiculo from './GridVeiculo.js';
import GridVeiculoNotificacao from './GridVeiculoNotificacao.js';
import api from './api';
import { Spinner } from 'react-bootstrap';


export class NotificacaoAdd extends Component{
    constructor(props) {
        super(props);
        this.state = {
            scrollOffset: 0,
            list:[],
            loading:true,
            grid_list:[],
            poligono:{},
            list_post:[],
        };
      }
    //------------------------------------------
    onItemClick = (item_id) =>{
        this.props.MapaKseg.callToast("onItemClick "+String(item_id));
    };  
    //------------------------
    async componentDidMount() {
        this.setState({poligono: this.props.MapaKseg.state.selectedPolygon});

        var status_list =[];
        for(var i=0; i< this.props.MapaKseg.state.list.length; i++){
            status_list.push({status: 1});
            // status_list.push(1);
        }
        this.setState({status_list: status_list});

        this.getVeiculosPoligonoNotificacao();
    }
    //-------------------------
    async componentDidUpdate() {
        // this.scrollElement();
        // ToastStore.success("shiitt");
        // if(this.state.scrollOffset===0)
        //     this.setState({scrollOffset: 800, });
    }
     //--------------------
     getVeiculosPoligonoNotificacao = async () => {
        try {
            var poligono = this.props.MapaKseg.state.selectedPolygon;

            var veiculos_list = this.props.MapaKseg.state.list;
            var veiculo_id_list=[];
            for(var i=0; i<veiculos_list.length; i++ ){                
                // var json = JSON.stringify({veiculo_id: veiculos_list[i].veiculo_id});
                veiculo_id_list.push(veiculos_list[i].veiculo_id);                            
            }

            let body = new FormData()
            body.append('veiculo_id_list', veiculo_id_list);
            body.append('poligono_id', poligono.poligono_id);
            body.append('usuario_id', this.props.MapaKseg.state.usuario_id);
            body.append('token', localStorage.getItem('token'));
 

            // console.log("  ***datetime: "+datetime)

            const response = await api.post('/poligono_api/getVeiculosPoligonoNotificacao', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);

                
                let veiculo = {};
                let grid_list = this.state.list;  
                var list_veiculos = this.props.MapaKseg.state.list;


                for (var i = 0; i < resposta.length; i++) {    
                    // ---- antes ver se o veiculo já esta na lista, pra nao repetir por causa das flags entrada e saída.
                    var list_aux = grid_list.filter((item,index) =>{  
                        return item.veiculo_id === resposta[i].veiculo_id;
                    })
                    //------------------------
                    if(list_aux.length===0){

                        veiculo = {  
                            poligono_acao_id_entrada : 0,
                            poligono_acao_id_saida : 0,
                            veiculo_id : resposta[i].veiculo_id,
                            placa : resposta[i].placa,
                            tipo_acao_id : resposta[i].tipo_acao_id,
                            entrada: false,
                            saida: false,
                            status:2,
                            editado:false
                        };

                        if(veiculo.tipo_acao_id === 1){
                            veiculo.entrada = true;
                            veiculo.poligono_acao_id_entrada = resposta[i].poligono_acao_id;
                        }else if(veiculo.tipo_acao_id === 2){
                            veiculo.saida = true;
                            veiculo.poligono_acao_id_saida = resposta[i].poligono_acao_id;
                        }

                        grid_list.push(veiculo);   
                    }
                    else{  // senao só vai alterar a flag do veículo
                        for (var j=0;j<grid_list.length;j++) {
                            if(grid_list[j].veiculo_id===list_aux[0].veiculo_id){
                                if(resposta[i].tipo_acao_id === 1){
                                    grid_list[j].entrada = true;
                                    grid_list[j].poligono_acao_id_entrada = resposta[i].poligono_acao_id;
                                }else if(resposta[i].tipo_acao_id === 2){
                                    grid_list[j].saida = true;
                                    grid_list[j].poligono_acao_id_saida = resposta[i].poligono_acao_id;
                                }
                                break;
                            }
                        }
                    }
                    
                //------filtrar os veiculos do primeiro grid-------------------
                    list_veiculos = list_veiculos.filter((item,index) =>{  
                        return item.veiculo_id !== veiculo.veiculo_id;
                    })
                }
                // this.setState({grid_list: grid_list});     
                
                //--------------Adicionar no grid final-------------
                for (var i = 0; i < list_veiculos.length; i++) {                        
                    veiculo = {  
                        poligono_acao_id_entrada : 0,
                        poligono_acao_id_saida : 0,
                        veiculo_id : list_veiculos[i].veiculo_id,
                        placa : list_veiculos[i].placa,
                        tipo_acao_id : 66,
                        entrada: false,
                        saida: false,
                        status:1,
                        editado:false
                    };

                    grid_list.push(veiculo);                                       
                }       
                this.setState({grid_list: grid_list});  
                
            }else{
            }

        } finally {
            this.setState({loading: false});     

            }
    };
    //---------------------------------------
    validarAcaoPoligono = () =>{
        var grid_list = this.state.grid_list;
        var result=true;
        for (var i = 0; i < grid_list.length; i++) {  
            if(grid_list[i].status===2){
                if(grid_list[i].entrada===false && grid_list[i].saida===false){
                    result=false;
                    this.props.MapaKseg.callToastError("Informe qual Notificação quer para o "+grid_list[i].placa+".");
                    this.setState({salvando:false});
                    break;
                }            
            }                                     
        } 
        return result;
    };
    //-------------------------
    formatarListaPost = () =>{
        var grid_list = this.state.grid_list;
        var poligono_acao_id = 0;
        var list_post = this.state.list_post;
        var veiculo ={};

        console.log("grid_list:");
        console.log(grid_list);

        for (var i = 0; i<grid_list.length; i++) {  
            poligono_acao_id = 0;
            if(grid_list[i].editado){

                if(grid_list[i].status===1){
                    if(grid_list[i].poligono_acao_id_entrada!==0){
                        veiculo = {poligono_acao_id: grid_list[i].poligono_acao_id_entrada, veiculo_id: grid_list[i].veiculo_id, poligono_id:999, tipo_acao_id:1, ativo:true };
                        list_post.push(veiculo);
                    }
                    if(grid_list[i].poligono_acao_id_saida!==0){
                        veiculo = {poligono_acao_id: grid_list[i].poligono_acao_id_saida, veiculo_id: grid_list[i].veiculo_id, poligono_id:999, tipo_acao_id:1, ativo:true };
                        list_post.push(veiculo);
                    }
                    
                }else if(grid_list[i].status===2){
                    //---Notificação para entrada:-------
                    if(grid_list[i].entrada){
                        if( grid_list[i].poligono_acao_id_entrada===0){
                            veiculo = {poligono_acao_id: grid_list[i].poligono_acao_id_entrada, veiculo_id: grid_list[i].veiculo_id, poligono_id: this.state.poligono.poligono_id, tipo_acao_id:1, ativo:true};
                            list_post.push(veiculo);
                        }
                    }else{
                        if( grid_list[i].poligono_acao_id_entrada!==0){
                            veiculo = {poligono_acao_id: grid_list[i].poligono_acao_id_entrada, veiculo_id: grid_list[i].veiculo_id, poligono_id: this.state.poligono.poligono_id, tipo_acao_id:1, ativo:true};
                            list_post.push(veiculo);
                        }
                    }
                    //---Notificação para saída:-------
                    if(grid_list[i].saida){
                        if( grid_list[i].poligono_acao_id_saida===0){
                            veiculo = {poligono_acao_id: grid_list[i].poligono_acao_id_saida, veiculo_id: grid_list[i].veiculo_id, poligono_id: this.state.poligono.poligono_id, tipo_acao_id:2, ativo:true};
                            list_post.push(veiculo);
                        }
                    }else{
                        if( grid_list[i].poligono_acao_id_saida!==0){
                            veiculo = {poligono_acao_id: grid_list[i].poligono_acao_id_saida, veiculo_id: grid_list[i].veiculo_id, poligono_id: this.state.poligono.poligono_id, tipo_acao_id:2, ativo:true};
                            list_post.push(veiculo);
                        }
                    }
                }

            }

                                      
        } 
        return list_post;
    };
    //-------------------------
    salvarNovoAcaoPoligono = () =>{
        this.setState({salvando:true});

        if(!this.state.salvando){
            if(this.validarAcaoPoligono()){
                
                var list_post = this.formatarListaPost();
                if(list_post.length>0){
                    this.postNovoAcaoPoligono(list_post);
                }else{
                    this.props.MapaKseg.callToastError("Sem alterações para enviar!");
                    this.setState({salvando:false});
                }
            }

        } else this.setState({salvando:false});

    
    };
    //--------------------
    postNovoAcaoPoligono = async (list_post) => {
        try {
            let body = new FormData()
            body.append('list_post', JSON.stringify(list_post));
    
            body.append('usuario_id', this.props.MapaKseg.state.usuario_id);
            body.append('token', localStorage.getItem('token'));
 

            const response = await api.post('/poligono_api/salvarPoligonoAcao', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                this.props.MapaKseg.callToast(String(resposta));
                console.log(String(resposta));
            }
                

        } finally {
            this.setState({loading: false});     
            this.setState({salvando:false});
            }
    };
    //------------------------
    render(){
        //--------------------
        return(                      
            <div className="padding_modal">

                {this.state.loading ?
                    <div className="centro">
                        <div className="espaco_topo2">
                            <Spinner animation="border" />
                    </div>
                    </div>
                    :
                    <div> 
                        
                        <div className="tables">
                            <div className="column">
                                <h6>Veículos:</h6>
                                <GridVeiculo tasksStore={this.state.grid_list} status={1} AddNotificacaoVeiModal={this} />
                            </div>
        

                            <div className="column">
                                {/* <h6>Enviar Notificação Entrada/saída da área:</h6> */}
                                <h6>Enviar Notificação:</h6>
                                <GridVeiculoNotificacao tasksStore={this.state.grid_list} status={2} AddNotificacaoVeiModal={this} />
                            </div>
                        </div>
                    </div>    
                }


            </div>                                
       
        
        );
    }
}