import React, {Component} from 'react';
import {Modal, Image, Spinner, Button, Form, Row,Col,Card,Nav,InputGroup,FormControl, Tab, Tabs} from 'react-bootstrap';
import DataGrid, { Column, Scrolling, Selection, MasterDetail, FilterRow, HeaderFilter, Paging } from 'devextreme-react/data-grid';
import { Switch } from 'devextreme-react/switch';
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import api from '../api';
import GetClientePessoa from '../services/get_cliente_pessoa';
import GetRotaPessoa from '../services/get_rota_pessoa';
import { DateBox } from 'devextreme-react';
import NumberBox from 'devextreme-react/number-box';
import ContextMenu from 'devextreme-react/context-menu';
import { dinheiroMask } from '../dinheiro_mask'
import PostColeta from '../services/post_coleta';
import PostEntregaCancelar from '../services/post_entrega_cancelar';


const COLETA_CLIENTE_TIPO=1;
const COLETA_CLIENTE_LOCAL_TIPO=2;
const COLETA_ROTA_TIPO=3;


export class CadColetaModal extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            loading:true,
            minimizar:false,
            changed:false,
            GeralListModal:null,
            tipo_pagamentos: [{tipo_pagamento_id: 0, descricao:""}, {tipo_pagamento_id: 1, descricao:"CIF"},{tipo_pagamento_id: 2, descricao:"FOB"}
                , {tipo_pagamento_id: 3, descricao:"AR CIF"}, {tipo_pagamento_id: 4, descricao:"AR FOB"}
                , {tipo_pagamento_id: 5, descricao:"PIX CIF"}, {tipo_pagamento_id: 6, descricao:"PIX FOB"} ],

            coleta_cliente: {id: 0, descricao:"", local_id:0, local:""},
            coleta_local: {id: 0, descricao:""},
            rota_coleta: {id: 0, descricao:""},
            pagamento_coleta: {id: 0, descricao:""},
            data_previsao_coleta: null,
            valor: null,
            disable_valor:false,
            disable_pago:false,

            coleta: {coleta_id:0, cliente_id:0, cliente:"", local_id:0, local:"", rota_id:0, rota:""
            , tipo_pagamento_id:0, tipo_pagamento:"", valor:"", data_previsao:"", entregas: []
            , observacao:"", cte:"", pago:false, tda:"", nota_fiscal:"", cliente_tda:"", municipio_tda:""
            , municipio_frete_minimo:"", cliente_frete_minimo:""},


            activeKey:"Coleta",
            nova_coleta:{},
            salvando:false,
            height_modal:605,
            items_entrega:[],
            editados:[],
        };
        this.showGeralList = this.showGeralList.bind(this);
        // this.setInputFocus = this.setInputFocus.bind(this);
        this.setPago = this.setPago.bind(this);

        this.myInputRef = React.createRef();

        // -------------------------------
        this.dataGridRef = React.createRef();
    
        this.selectedRowsData = [];

        this.getSelectedData = () => {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData();

            // ===== or when deferred selection is used =====
            this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
                // Your code goes here
            });
        }
        //----
    }
    //------------------------------------------
    get dataGrid() {
        return this.dataGridRef.current.instance;
    }  
    //--------------------------
    async componentDidMount() {
        this.setState({GridColetaEntrega: this.props.objeto});
        this.setState({minDateValue:  new Date((new Date()).getTime() - 1000 * 60 * 24 * 3)});

        this.carregarItemsMenu();

        //----
        let editados = {
            cliente:false
            , local:false
            , rota:false
            , pagamento:false
            , valor: false
            , previsao:false
            , entregas: false
            , observacao: false
            , cte: false
            , pago: false
            , tda: false
            ,nota_fiscal: false};
        
        let coleta = this.state.coleta;

        if(this.props.obj_selected !== null){
            coleta = this.props.obj_selected;        

            if(coleta.tipo_pagamento_id===2 
                || coleta.tipo_pagamento_id===4
                || coleta.tipo_pagamento_id===6) {     // 2-FOB, AR FOB, PIX FOB         
                    this.setState({ disable_valor: true, disable_pago: true}); 
            }            
        }else{

            let tipo_pagamento = {tipo_pagamento_id: 3, descricao:"AR CIF"};
            coleta.tipo_pagamento_id =  tipo_pagamento.tipo_pagamento_id;         
            coleta.tipo_pagamento=  tipo_pagamento.descricao;                 
            coleta.pago = true;

            editados.pagamento = true;
            editados.pago = true;
        }

        if(coleta.coleta_id == 0) editados.observacao = true;

        this.setState({
                coleta: coleta       
                ,editados: editados        
                // , height_grid: this.state.height_modal-470
            },
            () => {         
                this.setState({loading:false});
                // this.props.MapaKseg.callToast(this.state.coleta.entregas.length );
            }
          )       
    };
    //-------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closeCadColeta();  
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeCadColeta();
        // this.props.Modals.desativarCadColeta();
        this.state.GridColetaEntrega.visibleFilterrow();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarCadColeta();
    };
    //------------------------------------------
  carregarItemsMenu() {
    var items_entrega = this.state.items_entrega;
    items_entrega.push({ text: ' Add Entrega', icon: "dx-icon-add"  });
    items_entrega.push({ text: ' Cancelar Entrega', icon: "dx-icon-close"  });
    // items_entrega.push({ text: ' Nova Coleta', icon: "dx-icon-add"  });

    // if(this.props.MapaKseg.state.pessoa_id===1)
    //   items.push({ text: ' Nova Coleta', icon: "dx-icon-activefolder"  });

    this.setState({items_entrega: items_entrega}); 
    }
    //------------------------------------------
    onRowPrepared(e) {  
        if(e.data !== undefined){
            if (e.data.operacao === 0 ){
                if(e.data.index %2 === 0)
                    e.rowElement.style.backgroundColor = "#F5F5F5";
                else e.rowElement.style.backgroundColor = "#FFFFFF";                

            }else if (e.data.operacao === 1 || e.data.operacao === 3)
                    e.rowElement.style.backgroundColor = "#92d1b0";
            else if(e.data.operacao===2)
                e.rowElement.style.backgroundColor = "#f593a0";     
        }       
    } 
    //-------------------------------------------------------------------
    ItemTemplate (e) {
        return (
        <React.Fragment>
            <span className={ e.icon } />
            { e.items_entrega ? <span className="dx-icon-spinright" /> : null }
            { e.text }
        </React.Fragment>
        );
    }
    //---------------------------------------------------------------
    itemClick = (e) => {
        if (!e.itemData.items_entrega) {
            if(e.itemData.text === this.state.items_entrega[0].text){
                if(this.state.coleta.tipo_pagamento_id==0 ){
                    this.props.MapaKseg.toastError("Informe o Pagamento na Coleta!");
                } else this.props.MapaKseg.state.Modals.showCadEntregaModal(this, null);
            }else if(e.itemData.text === ""){
                var selectedRowData = this.dataGrid.getSelectedRowsData(); 
                if(this.state.coleta.tipo_pagamento_id==0 ){
                    this.props.MapaKseg.toastError("Informe o Pagamento na Coleta!");
                } else this.props.MapaKseg.state.Modals.showCadEntregaModal(this, selectedRowData[0]);
            }else if(e.itemData.text === ' Cancelar Entrega'){
                var selectedRowData = this.dataGrid.getSelectedRowsData(); 
                if(selectedRowData.length > 0)
                    this.remover(selectedRowData[0]);
            }
        }
    }    
    //-------------------------------------------------------------------
    remover (obj) {
        if(this.state.coleta.entregas.length > 1){
            if(obj.entrega_id === 0){
                let entregas = this.state.coleta.entregas;
                entregas = entregas.filter((g,index) =>{ return g.index !== obj.index; });        
                let coleta = this.state.coleta;
                coleta.entregas = entregas;
                coleta.entregas = coleta.entregas.concat(); // para não precisar dar reload no grid
                this.setState({ coleta: coleta});
            }else{             
                // let texto = "Esta Entrega não pode ser removida, pois já está salva no sistema!";
                // this.props.Modals.showMensagemModal(this, "Aviso!", texto, false, "");
                
                    let texto = "Deseja Cancelar a Entrega "
                    +String(obj.entrega_id)+" - "
                    +String(obj.cliente) 
                    +" ?";
                    let imagem = require('../img/close_100dp.png');
                    this.props.MapaKseg.state.Modals.showMensagemModal(this, "Cancelar Entrega", texto, true, imagem);                
            }
        }else{
            let texto = "Esta Entrega não pode ser Cancelada, pois a Coleta precisa ter ao menos uma Entrega!";
            this.props.Modals.showMensagemModal(this, "Aviso!", texto, false, "");
        }
    }
    //------------------------------------------------
    confirmarMensagem = (texto) => {
        if(texto ==="Cancelar Entrega"){
            var selectedRowData = this.dataGrid.getSelectedRowsData(); 
            var instance = new PostEntregaCancelar(null, this, this.props.MapaKseg, selectedRowData[0]);
            instance.post(); 
        }
    }
    //-------------------------------------------------------------
    reloadGrid= (obj) => {
        let entregas = this.state.coleta.entregas;
        entregas = entregas.filter((g,index) =>{ return g.index !== obj.index; });        
        let coleta = this.state.coleta;
        coleta.entregas = entregas;
        coleta.entregas = coleta.entregas.concat(); // para não precisar dar reload no grid
        this.setState({ coleta: coleta});
    }
    //----------------------------------
    // confirmarMensagem(texto){};
    //-----------------------------------------------
    setPagamento(event) {        
        let list_aux = this.state.tipo_pagamentos.filter((item,index) =>{ return item.tipo_pagamento_id === parseInt(event.target.value)});
        if(list_aux.length > 0){
            let coleta = this.state.coleta;
            coleta.tipo_pagamento_id =  list_aux[0].tipo_pagamento_id;         
            coleta.tipo_pagamento=  list_aux[0].descricao;                     

            this.setState({coleta: coleta});

            let editados = this.state.editados;
            editados.pagamento = true;            

            if(event.target.value==2 || event.target.value==4 || event.target.value==6){ // 2-FOB, AR FOB, PIX FOB
                coleta.valor = 0;
                coleta.tda = 0;
                this.setState({disable_valor: true, coleta:coleta, disable_pago: true});  
            }else this.setState({disable_valor: false, disable_pago: false});

            // {tipo_pagamento_id: 3, descricao:"AR CIF"}, {tipo_pagamento_id: 4, descricao:"AR FOB"}

            if(event.target.value==3 ){  //  3 - AR CIF    if(event.target.value==3 || event.target.value==4){
                coleta.pago = true;
                editados.pago = true;
                this.setState({coleta:coleta});  
            }else{
                coleta.pago = false;
                editados.pago = true;
                this.setState({coleta:coleta});
            }

            if(this.state.disable_valor===true){
                if(coleta.municipio_frete_minimo === '0') coleta.valor = '';
                else coleta.valor = coleta.municipio_frete_minimo;
    
                if(coleta.cliente_frete_minimo !== '0') coleta.valor = coleta.cliente_frete_minimo;
                // ------
                coleta.tda = coleta.municipio_tda;
                if(coleta.cliente_tda !== '') coleta.tda = coleta.cliente_tda;
            }

            this.setState({editados: editados, changed: true});
        }        
    }
    //--------------------------------------------
    setDataPrevisaoColeta = (e) => {
        let coleta = this.state.coleta;
        coleta.data_previsao =  this.formatarDateTime(e.value);            
        this.setState({coleta: coleta});
    
        let editados = this.state.editados;
        editados.previsao = true;
        this.setState({editados: editados, changed: true});

        // this.handleSelect("Entrega");
    }
    //----------
    formatarDateTime(dtTime){    
        const y = String(dtTime).substring(11,15);
        const mm = this.formatarMes(String(dtTime).substring(4,7));
        const dd = String(dtTime).substring(8,10);
        const hm = String(dtTime).substring(16,21);

        // console.log("  *** dtTime: "+String(dtTime));
        // console.log("  *** y: "+y);
        // console.log("  *** mm: "+mm);
        // console.log("  *** mm: "+String(this.formatarMes(mm)));
        // console.log("  *** dd: "+dd);
        // console.log("  *** hm: "+hm);  
        const datetime = y+"-"+mm+"-"+dd+" "+hm;  // "Y-MM-DD H:M"
        return datetime;                  
      }
      //-------------------------
      formatarMes(mes){    
        if(mes==="Jan")
            return 1;
        else if (mes==="Feb")
            return 2;
        else if (mes==="Mar")
            return 3;     
        else if (mes==="Apr")
            return 4;     
        else if (mes==="May")
            return 5;     
        else if (mes==="Jun")
            return 6; 
        else if (mes==="Jul")
            return 7; 
        else if (mes==="Aug")
            return 8; 
        else if (mes==="Sep")
            return 9; 
        else if (mes==="Oct")
            return 10;    
        else if (mes==="Nov")
            return 11;
        else if (mes==="Dec")
            return 12;             
      }  
    //-------------------------
    setValorPagamento(event) {
        let coleta = this.state.coleta;
        coleta.valor =  dinheiroMask(event.target.value);            
        this.setState({coleta: coleta});

        let editados = this.state.editados;
        editados.valor = true;
        this.setState({editados: editados, changed: true});
    }
    //-------------------------
    setTDA(event) {
        let coleta = this.state.coleta;
        coleta.tda =  dinheiroMask(event.target.value);            
        this.setState({coleta: coleta});

        let editados = this.state.editados;
        editados.tda = true;
        this.setState({editados: editados, changed: true});
    }
    //-------------------------
    somarFreteTDA =(_frete, _tda) =>{
        _frete = parseFloat(String(_frete).replace(".", "").replace(",", "."));

        if (_tda !== "") _tda = parseFloat(String(_tda).replace(".", "").replace(",", "."));
        else _tda = 0;

        let total = '';

        if (String(_frete) === 'NaN') _frete = 0;
        if (String(_tda) === 'NaN') _tda = 0;

        total = _frete + _tda;
        total = total.toLocaleString('pt-br', {minimumFractionDigits: 2});

        return total;        
    }
    //------------------------
    showGeralList= (e) => {   
        // this.props.MapaKseg.callToast(e.target.getAttribute('value') );
        if(e.target.getAttribute('value') == COLETA_CLIENTE_TIPO){
            // this.props.Modals.showGeralList(this,"Clientes Coleta", COLETA_CLIENTE_TIPO, true);
            this.props.Modals.showPesquisarModal(this,"Clientes Coleta", true, 'Clientes Coleta');
        }else if(e.target.getAttribute('value') == COLETA_CLIENTE_LOCAL_TIPO)
            this.props.Modals.showGeralList(this,"Local da Coleta", COLETA_CLIENTE_LOCAL_TIPO);    
        else if(e.target.getAttribute('value') == COLETA_ROTA_TIPO)
            this.props.Modals.showGeralList(this,"Rotas Coleta", COLETA_ROTA_TIPO);      
    }; 
    //--------------------
    showObservacao= (e) => {   
        this.props.Modals.showObservacaoModal(this, "Observação da Coleta", this.state.coleta.observacao, true, 'observacao');             
    }; 
    //--------------------
    showCTE= (e) => {   
        this.props.Modals.showObservacaoModal(this, "CTE da Coleta", this.state.coleta.cte, true, 'cte');             
    }; 
    //--------------------
    showNotaFiscal= (e) => {   
        this.props.Modals.showObservacaoModal(this, "Nota Fiscal da Coleta", this.state.coleta.nota_fiscal, true, 'nota_fiscal');             
    };
    //----------------------------------
    confirmarObservacao(texto){
        let coleta = this.state.coleta;
        coleta.observacao = texto;

        let editados = this.state.editados;
        editados.observacao = true;

        this.setState({coleta: coleta, editados: editados, changed: true});        
    };
    //----------------------------------
    confirmarCte(texto){
        let coleta = this.state.coleta;
        coleta.cte = texto;

        let editados = this.state.editados;
        editados.cte = true;

        this.setState({coleta: coleta, editados: editados, changed: true});        
    };
    //----------------------------------
    confirmarNotaFiscal(texto){
        let coleta = this.state.coleta;
        coleta.nota_fiscal = texto;

        let editados = this.state.editados;
        editados.nota_fiscal = true;

        this.setState({coleta: coleta, editados: editados, changed: true});        
    };    
    //-----------------------------------------------------
    getList= (geralListModal, tipo, filtro='') => { 
        this.setState({GeralListModal: geralListModal});  

        if(tipo == COLETA_CLIENTE_TIPO){             
            var instance = new GetClientePessoa(null, this, this.props.MapaKseg, COLETA_CLIENTE_TIPO, filtro);
            instance.get(); 
        }else if(tipo == COLETA_CLIENTE_LOCAL_TIPO){ 
            var instance = new GetClientePessoa(null, this, this.props.MapaKseg, COLETA_CLIENTE_TIPO, filtro);
            instance.get(); 
        }else if(tipo == COLETA_ROTA_TIPO){ 
            var instance = new GetRotaPessoa(null, this, this.props.MapaKseg, COLETA_ROTA_TIPO, 0, null);
            instance.get();   
        }
    }; 
    //--------------------------------------------------------------
    setListClientes= (_list, tipo) => {  
        let array =[];
        let descr = "";

        for (var i = 0; i < _list.length; i++) { 
            if(_list[i].cnpj_cpf == null) descr = _list[i].nome + " ("+_list[i].local+")"
            else descr = _list[i].nome +" ("+_list[i].cnpj_cpf +") ("+_list[i].local+")"

            array.push({
                index: _list[i].index
                  ,id: _list[i].cliente_id
                , descricao: descr
                , descricao2: _list[i].nome
                , cliente_tda: _list[i].cliente_tda
                , local_id: _list[i].local_id
                , local: _list[i].local
                , municipio_id: _list[i].municipio_id
                , municipio: _list[i].municipio
                , municipio_tda: _list[i].municipio_tda
                , tipo_pagamento_id: _list[i].tipo_pagamento_id
                , tipo_pagamento: _list[i].tipo_pagamento
                , rota_id: _list[i].rota_id
                , rota: _list[i].rota
                , cliente_frete_minimo: _list[i].cliente_frete_minimo
                , municipio_frete_minimo: _list[i].municipio_frete_minimo
                , selecionada: _list[i].selecionada
                , index_scroll: _list[i].index_scroll});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //--------------------
    setObjeto= (objeto, tipo) => { 
        let editados = this.state.editados;

        if(tipo == COLETA_CLIENTE_TIPO){
            let coleta = this.state.coleta;                    

            coleta.cliente_id = objeto.id;
            coleta.cliente = objeto.descricao2;
            coleta.local_id = objeto.local_id;
            coleta.local = objeto.local
            coleta.tda = objeto.municipio_tda;
            coleta.rota_id = objeto.rota_id;
            coleta.rota = objeto.rota;
            coleta.municipio_tda = objeto.municipio_tda;
            coleta.cliente_tda = objeto.cliente_tda;
            coleta.municipio_frete_minimo = objeto.municipio_frete_minimo;
            coleta.cliente_frete_minimo = objeto.cliente_frete_minimo;

            if(objeto.municipio_frete_minimo === '0') coleta.valor = '';
            else coleta.valor = objeto.municipio_frete_minimo;

            if(objeto.cliente_frete_minimo !== '0') coleta.valor = objeto.cliente_frete_minimo;
            
            if(coleta.coleta_id === 0){
                coleta.tipo_pagamento_id = objeto.tipo_pagamento_id;
                coleta.tipo_pagamento = objeto.tipo_pagamento;
                editados.pagamento = true;
            }                    

            if(objeto.cliente_tda !== '') coleta.tda = objeto.cliente_tda;
            

            this.setState({coleta: coleta});   

            editados.cliente = true;
            editados.local = true;
            editados.rota = true; 
            
            // if(coleta.rota == null) this.props.MapaKseg.toastError(coleta.rota_id);
            
            if(coleta.rota_id == 0){
                var instance = new GetRotaPessoa(null, this, this.props.MapaKseg, COLETA_ROTA_TIPO, objeto.municipio_id, coleta.cliente_id);
                instance.get();
            }

        }
        else if(tipo == COLETA_CLIENTE_LOCAL_TIPO){
            let coleta = this.state.coleta;
            coleta.local_id = objeto.local_id;
            coleta.local = objeto.local;
            this.setState({coleta: coleta});

            editados.local = true;
        }
        else if(tipo == COLETA_ROTA_TIPO){
            let coleta = this.state.coleta;
            coleta.rota_id = objeto.id;
            coleta.rota = objeto.descricao2;
            this.setState({coleta: coleta});
            
            editados.rota = true;            
        }

        editados.valor = true;
        this.setState({editados: editados, changed: true});
    }
    //---------------------------------------------------
    setRota= (objeto) => { 
        let editados = this.state.editados;
        let coleta = this.state.coleta;
        coleta.rota_id = objeto.rota_id;
        coleta.rota = objeto.descricao;
        this.setState({coleta: coleta});
        
        editados.rota = true;   
        this.setState({editados: editados, changed: true});
    }
    //----------------------------------------------------
     setListRotas= (_list, tipo) => {  
        let array =[]
        for (var i = 0; i < _list.length; i++) { 
            array.push({
                index: _list[i].index
                , id: _list[i].rota_id
                , descricao: _list[i].descricao + " ("+_list[i].municipios+")"
                , descricao2: _list[i].descricao
                , selecionada: _list[i].selecionada
                , index_scroll: _list[i].index_scroll});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //---------------------------------------------
    validarFrete =(_valor, _frete_minimo) =>{
        let resultado = false;

        _valor = parseFloat(String(_valor).replace(".", "").replace(",", "."));
        _frete_minimo = parseFloat(String(_frete_minimo).replace(".", "").replace(",", "."));

        if(_valor < _frete_minimo){
            _frete_minimo = _frete_minimo.toLocaleString('pt-br', {minimumFractionDigits: 2});
            this.props.MapaKseg.toastError("O Frete Mínimo é R$ "+String(_frete_minimo));
        }else resultado = true;

        return resultado;        
    }
    //---------------------------------------------
    validar = () => {
        let valido=true;
        if(this.state.coleta.cliente_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Cliente da Coleta!");
        }
        if(this.state.coleta.rota_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe a Rota da Coleta!");
        }

        if(this.state.disable_valor===false){
            if(this.state.coleta.municipio_frete_minimo !=='0' || this.state.coleta.cliente_frete_minimo !=='0'){
                if(valido){
                    let frete_minimo = this.state.coleta.municipio_frete_minimo;
            
                    if(this.state.coleta.cliente_frete_minimo !== '0') frete_minimo = this.state.coleta.cliente_frete_minimo;
                    

                    valido = this.validarFrete(this.state.coleta.valor, frete_minimo);  
                }
            }
        }
      
        if(this.state.coleta.tipo_pagamento_id==0 && valido){
            valido=false;
            this.props.MapaKseg.toastError("Informe o Pagamento!");
        }        
        if(this.state.coleta.tipo_pagamento_id == 1 || this.state.coleta.tipo_pagamento_id == 3 || this.state.coleta.tipo_pagamento_id == 5)
            if(this.state.disable_valor==false && valido){
                if(this.state.coleta.valor=="" || this.state.coleta.valor=="0" || this.state.coleta.valor==0 || this.state.coleta.valor==null ){
                    valido=false;
                    this.props.MapaKseg.toastError("Informe o Frete R$ !");
                }
            }
        
        if(this.state.coleta.entregas.length <= 0 && valido){
            valido=false;
            this.props.MapaKseg.toastError("Adicione ao menos uma Entrega!");
        }

        if(this.state.coleta.data_previsao=="" && valido){
            valido=false;
            this.props.MapaKseg.toastError("Informe a Data de Previsão da Coleta!");
        }

        if(this.state.coleta.tipo_pagamento_id === undefined){
            valido=false;
            this.props.MapaKseg.toastError("Informe o Tipo de Pagamento novamente!");
        }

        if(this.state.coleta.rota_id === undefined){
            valido=false;
            this.props.MapaKseg.toastError("Informe a Rota Novamente!");
        }

        if(valido){
            // this.props.MapaKseg.toast("Válido!");
            this.setState({salvando:true, changed:false});
            let coleta = this.state.coleta;
            coleta.usuario_cadastro_id = this.props.MapaKseg.state.usuario_id;
            coleta.status = 2;    

            this.setState({coleta: coleta},
            () => {            
                var instance = new PostColeta(null, this, this.props.MapaKseg);
                instance.post();
             }
            );                       

            // console.log("nova_coleta");
            // console.log(nova_coleta);

            // console.log("coleta:");
            // console.log(coleta);
            
        }
    }; 
    //--------------------------------------
    concluido=(coleta)=> {
        this.setState({salvando:false, changed:true});
        this.state.GridColetaEntrega.reloadGrid(coleta);
        this.fecharModal();
    }
    //---------------------------------------------------------------
    setCliente = (cliente) => {
        cliente.id = cliente.cliente_id;
        cliente.descricao2 = cliente.nome;
        this.setObjeto(cliente, COLETA_CLIENTE_TIPO);
    }
    //--------------------------------------
    handleSelect=(selectedKey)=> {
        this.setState({activeKey: selectedKey});
      }
    //--------------------------------------
    onClickTabEvento() {
        this.props.MapaKseg.toast("onClickTabEvento!");
        this.setState({carregarTabEventos: true});
      }
    //---------------------------------------------------------------
    pushGridEntrega=(nova_entrega)=> {        
        // let entregas = this.state.entregas;
        // let list_aux = [];
        // list_aux.push(nova_entrega);
        // list_aux = list_aux.concat(entregas);
        // this.setState({entregas: list_aux});        

        let coleta = this.state.coleta;
        let list_aux = [];
        list_aux.push(nova_entrega);        
        list_aux = list_aux.concat(coleta.entregas);

        list_aux = list_aux.filter(function( entrega ) {
            return entrega !== undefined;
         });

        coleta.entregas = list_aux;
        this.setState({coleta: coleta});
        let editados = this.state.editados;
        editados.entregas = true;
        this.setState({editados: editados, changed:true});
    };
    //---------------------------------------------
    setPago= (e) => {
        let coleta = this.state.coleta;
        coleta.pago = e.value;

        let editados = this.state.editados;
        editados.pago = true;    

        this.setState({coleta: coleta, editados: editados, changed:true});
    }
    //---------------------------------------------
    render(){
        const onContextMenuPreparing = (e) =>{
            try{
              e.component.selectRows(e.row.key, false)
              var selectedRowData = this.dataGrid.getSelectedRowsData();
              // this.props.MapaKseg.ajustarZoom(selectedRowData[0]);
    
            //   console.log(e.row.key);
            }catch (response){}
        }                
        //--------------------
        return(
            <div className="drag1">
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            showCloseButton={false}
                            duration={600}
                            width={600}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.loading ?
                                        <Spinner className="centro3" animation="border" role="status"/>
                                    :
                                        <div style={{height: this.state.height_modal, overflow:'hidden'}}>
                                            {/* <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0"> */}
                                                <Form>                                                                                                                                                                                                           

                                                    {/* ----------------Tab---------------------------------------------------- */}
                                                    <Tabs
                                                        id="controlled-tab-example"
                                                        activeKey={this.state.activeKey}
                                                        onSelect={this.handleSelect} 
                                                        className="mb-3"
                                                        style={{background:"#E9ECEF"}}
                                                        >
                                                        <Tab eventKey="Coleta" title="Coleta" style={{height:"450px"}} >
                                                            <CustomScroll  flex="1">

                                                                <Form.Group as={Row} controlId="formColetaID">
                                                                    <Form.Label column sm={3}>
                                                                        Coleta ID
                                                                    </Form.Label>
                                                                    <Col sm={3}>
                                                                        <InputGroup className="mb-1" >
                                                                            <Form.Control disabled aria-describedby="basic-addon1" value={this.state.coleta.coleta_id}   />                                                                         
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formClienteColeta">
                                                                    <Form.Label column sm={3}>
                                                                        Cliente Coleta
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formClienteBt" value={COLETA_CLIENTE_TIPO} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                                    <Image value={COLETA_CLIENTE_TIPO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control  aria-describedby="basic-addon1" value={this.state.coleta.cliente}  />                                                                         
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formLocalColeta">
                                                                    <Form.Label column sm={3}>
                                                                        Local Coleta
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button disabled id="formLocalColetaBt" value={COLETA_CLIENTE_LOCAL_TIPO} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                                    <Image value={COLETA_CLIENTE_LOCAL_TIPO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control disabled aria-describedby="basic-addon1" value={this.state.coleta.local}   />                                                                         
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formRotaColeta">
                                                                    <Form.Label column sm={3}>
                                                                        Rota Coleta
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formRotaColetaBt" value={COLETA_ROTA_TIPO} onClick={this.showGeralList} variant="outline-secondary" >
                                                                                    <Image value={COLETA_ROTA_TIPO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>

                                                                            <Form.Control  aria-describedby="basic-addon1" value={this.state.coleta.rota} >                                                                                     

                                                                            </Form.Control>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>                                                                 

                                                                {/* <Form.Group as={Row} controlId="formPagamento">
                                                                    <Form.Label column sm={3}>
                                                                        Pagamento
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >                                                        
                                                                            <Form.Control as="select" aria-describedby="basic-addon1"  
                                                                                value={this.state.coleta.tipo_pagamento_id} 
                                                                                custom onChange={this.setPagamento.bind(this)}>
                                                                                    <option value={null}>
                                                                                        {}
                                                                                    </option>

                                                                                    {this.state.tipo_pagamentos.map(paga =>
                                                                                        <option value={paga.tipo_pagamento_id}>
                                                                                            {paga.descricao}
                                                                                        </option>
                                                                                    )}
                                                                            </Form.Control>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>   */}

                                                                <Form.Group as={Row} controlId="formPagamento">
                                                                    <Form.Label column sm={3}>
                                                                        Pagamento
                                                                    </Form.Label>
                                                                    <Col sm={6}>
                                                                        <InputGroup className="mb-1">
                                                                            <Form.Control as="select" aria-describedby="basic-addon1"  
                                                                                value={this.state.coleta.tipo_pagamento_id} 
                                                                                custom onChange={this.setPagamento.bind(this)}>
                                                                                    <option value={null}>
                                                                                        {}
                                                                                    </option>

                                                                                    {this.state.tipo_pagamentos.map(paga =>
                                                                                        <option value={paga.tipo_pagamento_id}>
                                                                                            {paga.descricao}
                                                                                        </option>
                                                                                    )}
                                                                            </Form.Control>

                                                                            <Form.Label column sm={4} className="padding_left" > Pago </Form.Label>
                                                                            <Switch disabled={this.state.disable_pago} className="padding_top" defaultValue={this.state.coleta.pago} value={this.state.coleta.pago}  switchedOnText={"Sim"} switchedOffText={"Não"} onValueChanged={this.setPago}/>

                                                                        </InputGroup>

                                                                    </Col>
                                                                </Form.Group>                                                                

                                                                <Form.Group as={Row} controlId="formValor">
                                                                    <Form.Label column sm={3}>
                                                                        Frete R$
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1">
                                                                            {/* <FormControl type="number" disabled={this.state.disable_valor}  aria-describedby="basic-addon1" value={this.state.coleta.valor} defaultValue={0.0} onChange={this.setValorPagamento.bind(this)}/> */}
                                                                            <FormControl type="text" disabled={this.state.disable_valor}  aria-describedby="basic-addon1" value={this.state.coleta.valor} defaultValue={0} onChange={this.setValorPagamento.bind(this)} />

                                                                            <Form.Label column sm={1.1} className="padding_left_right" > TDA </Form.Label>
                                                                            <FormControl type="text" disabled={this.state.disable_valor}  aria-describedby="basic-addon1" value={this.state.coleta.tda} defaultValue={0} onChange={this.setTDA.bind(this)} />

                                                                            <Form.Label column sm={1.1} className="padding_left_right" >Total </Form.Label>
                                                                            <FormControl type="text" disabled  aria-describedby="basic-addon1" 
                                                                                // value={this.state.coleta.valor} 
                                                                                // value={parseFloat(this.state.coleta.valor.replace(",", ".")) }                                                                             
                                                                                value={this.somarFreteTDA(this.state.coleta.valor, this.state.coleta.tda) }  
                                                                                defaultValue={0} onChange={this.setTDA.bind(this)} />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>                                               

                                                                <Form.Group as={Row} controlId="formObservacao">
                                                                    <Form.Label column sm={3}>
                                                                        Observação
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formObservacaoBt" onClick={this.showObservacao} variant="outline-secondary" >                                                                                   
                                                                                    <Image  src={require('../img/pencil_48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control  aria-describedby="basic-addon1" disabled value={this.state.coleta.observacao} /> 
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formCTE">
                                                                    <Form.Label column sm={3}>
                                                                        CTE
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formCTEBt" onClick={this.showCTE} variant="outline-secondary" >                                                                                   
                                                                                    <Image  src={require('../img/pencil_48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control  aria-describedby="basic-addon1" disabled value={this.state.coleta.cte} />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formNotaFiscal">
                                                                    <Form.Label column sm={3}>
                                                                        Nota Fiscal
                                                                    </Form.Label>
                                                                    <Col sm={8}>
                                                                        <InputGroup className="mb-1" >
                                                                            <InputGroup.Prepend >
                                                                                <Button id="formNotaFiscalBt" onClick={this.showNotaFiscal} variant="outline-secondary" >                                                                                   
                                                                                    <Image  src={require('../img/pencil_48.png')} width='20'/>
                                                                                </Button>
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control  aria-describedby="basic-addon1" disabled value={this.state.coleta.nota_fiscal} />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 

                                                                <Form.Group as={Row} controlId="formPrevisaoColeta">
                                                                    <Form.Label column sm={3}>
                                                                        Previsão
                                                                    </Form.Label>
                                                                    <Col sm={8}>                                                                    
                                                                        <InputGroup className="mb-1" >
                                                                            <DateBox displayFormat="dd/MM/yyyy HH:mm"  width={200}
                                                                                className="headerDateTime"  type="datetime" 
                                                                                onValueChanged={this.setDataPrevisaoColeta}
                                                                                placeholder={this.state.coleta.data_previsao2}
                                                                                // min={this.state.minDateValue}
                                                                                /> 
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group> 
                                                            </CustomScroll>
                                                        </Tab>
                                                    {/* ---------------------------------- */}
                                                        <Tab eventKey="Entrega" title="Entregas" >
                                                            {/* <Form.Group as={Row} controlId="formClienteEntrega"> */}
                                                                <div>
                                                                    <ContextMenu
                                                                        dataSource={this.state.items_entrega}
                                                                        width={180}
                                                                        target="#entregas_add_grid"
                                                                        itemRender={this.ItemTemplate}
                                                                        onItemClick={this.itemClick} />

                                                                    <DataGrid
                                                                        id="entregas_add_grid"      
                                                                        ref={this.dataGridRef}                                                              
                                                                        repaintChangesOnly={true}
                                                                        dataSource={this.state.coleta.entregas}
                                                                        // defaultColumns={columns_entrega}
                                                                        showBorders={true}
                                                                        onRowPrepared={this.onRowPrepared}
                                                                        onRowUpdated={this.onRowUpdated}
                                                                        columnAutoWidth={true}
                                                                        onContextMenuPreparing={onContextMenuPreparing}
                                                                        height={330}
                                                                        allowColumnResizing={true}    
                                                                        // remoteOperations={true}
                                                                        // wordWrapEnabled={true}                                                                
                                                                        > 
                                                                        <Selection mode="single" />
                                                                        <Scrolling mode="virtual" />
                                                                        {/* <Scrolling mode="virtual" rowRenderingMode="virtual"/> */}
                                                                        <Paging defaultPageSize={100} />
                                                                        {/* <HeaderFilter visible={true} /> */}
                                                                        {/* <FilterRow visible={true} /> */}

                                                                        <Column dataField="index" caption= "" width={40}/>
                                                                        <Column dataField="entrega_id" caption= "Id" visible={true} width={60}/>
                                                                        <Column dataField="cliente" width={150} />
                                                                        <Column dataField="municipio" caption= "Município" width={100} />
                                                                        <Column dataField="rota" width={80} />
                                                                        <Column dataField="tipo_pagamento" caption= "pagamento" width={100} />
                                                                        <Column dataField="valor" caption="Frete" width={70} />
                                                                        <Column dataField="tda" caption= "TDA" width={70} />
                                                                        {/* <Column caption= "Data Previsão" dataField="data_previsao" dataType="datetime" format="d/M/yyyy  HH:mm" width={120} /> */}
                                                                        {/* <Column caption= "Data Previsão" dataField="data_previsao" dataType="datetime" format="EE  dd/MM/yyyy HH:mm" width={180} /> */}
                                                                        <Column dataField="data_previsao" caption={"Data Previsão"} dataType="text" width={170} />
                                                                        <Column dataField="local" visible={false} width={250}/> 
                                                                    </DataGrid>
                                                                </div>        
                                                                                                     
                                                        </Tab>
                                                    
                                                    </Tabs>                                                                                                                                                          

                                                </Form>
                                                {/* ----------------------------------- */}
                                            {/* </CustomScroll> */}


                                            <Modal.Footer >
                                                        {/* <div className="align_bottom_left">
                                                            <Button onClick={this.onVoltarClick}><img src={require('./img/arrow_left24.png')} title={"Voltar"}/></Button>
                                                        </div> */}
                                                            <div className="align_bottom_right_top">
                                                                <Button disabled={!this.state.changed} onClick={this.validar}>
                                                                    {this.state.salvando
                                                                        ? <Spinner as="span" animation="grow" role="status" aria-hidden="true"/>                                                                
                                                                    :
                                                                    <div>
                                                                        <img src={require('../img/checkbold24.png')}/> Salvar 
                                                                    </div>
                                                                    }                                                                
                                                                </Button>
                                                            </div> 
    

                                            </Modal.Footer>

                                        </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}