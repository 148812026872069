import React, {Component} from 'react';
import {Modal, Image, Spinner, Button, Form, Row,Col,Card,Nav,InputGroup,FormControl, Dropdown, DropdownButton} from 'react-bootstrap';
import './estilo.css';
import {CabecalhoModal} from './CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import api from './api';
import {NotificacaoAdd} from './NotificacaoAdd';
import {GridVeiNotif} from './GridVeiNotif';

export class CadCliente extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            list_pessoa:[],
            loading:true,
            minimizar:false,
            editado:false,
            poligono: {},
            disable_chip:true,
            disable_placa:true,
            disable_pessoa:true,            
            colorPoligon:"#3f89bc"
        };
      }
    //--------------------------
    async componentDidMount() {
        // this.getVeiculoInformacao();
        if(this.props.poligono_selecionado !== {}){
            console.log("poligono_selecionado CadCLiente: ");
            console.log(this.props.poligono_selecionado);
            this.setState({poligono: this.props.poligono_selecionado});
        }
    };
    //----------------------------------------
    componentDidUpdate(prevProps) {
        // this.props.MapaKseg.toast("componentDidUpdate");
        if(this.state.loading)
            this.setState({ loading:false});
    }
    //-------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closeCadCliente();  
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeCadCliente();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarCadCliente();
    };
    //----------------------------------------------RelPosicoesVeiModal--------------------------------------------------------------------
    showItemModal = (item) => {
        if (this.props.tipoChamadaLista===1){
            if(item===1)
                this.props.SideNavDrawer.showTempGridModal();
        }else if(this.props.tipoChamadaLista===2){
            if(item===1)
                this.props.SideNavDrawer.showRelPosicoesVeiModal();
            else if(item===2)
                this.props.SideNavDrawer.showRelEventosVeiModal();    
        }

        this.minimizarModal();
    }; 
    //----------------------------
    sequenciaUpdate = () => {
        this.props.SideNavDrawer.setModalSelecionado("ListaModal");
    };
    //---------------------------------------------------------------------
    getVeiculoInformacao = async () => {
        try {
            let body = new FormData()
            body.append('veiculo_id', this.props.MapaKseg.state.veiculoPosicao_selecionado.veiculo_id);
            body.append('token', localStorage.getItem('token'));

            const response = await api.post('/comando_api/getVeiculoInformacao', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                var list = this.state.list;  
                var cliente = {                              
                    poligono_id : resposta[0].poligono_id,          
                    descricao : resposta[0].descricao,
                    cor : resposta[0].cor                      
                };
                

                // ------------------------
                var res = resposta[1];
                var tipo_entrada_saida ={};
                for (var i = 0; i < res.length; i++) {    
                        tipo_entrada_saida = {                              
                            tipo_entrada_saida_id : res[i].tipo_entrada_saida_id,          
                            descricao : res[i].descricao,                  
                            entrada : res[i].entrada,       
                        };
                        list.push(tipo_entrada_saida);   
                        this.setState({list: list});  
                    }    
                // ------------------------
                res = resposta[2];
                list=[];
                var pessoa ={};
                for (var i = 0; i < res.length; i++) {    
                    pessoa = {                              
                        pessoa_id : res[i].pessoa_id,          
                        nome_social : res[i].nome_social,                  
                        };
                    list.push(pessoa);   
                    this.setState({list_pessoa: list});  
                    }                    
                }       
            

        } finally {
            this.setState({loading: false});     
            }
    };    
    //---------------------------------------------------------------------
    onSalvar = async() => {
       

    };
    //-------------------------
    onChangeSelect(event) {
        // console.log("event.target:");
        // console.log(event.target);
        // this.props.MapaKseg.callToast(event.target.id);
   
      }
    //-------------------------
    onEditCampo(event) {
        // this.props.MapaKseg.callToast(event.target.id);

    }      
    //-------------------------
    updateInputValue(event) {
      
        // this.props.MapaKseg.callToast(event.target.id);
     }
     //--------------------------
     setColorPoligon = (color) =>{
         let pol = this.state.poligono;
         pol.cor = color;
         this.setState({poligono: pol});
     }
    //-------------------------
    render(){
        //--------------------
        return(
            <div className="drag1">
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={650}
                            height={600}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.loading ?
                                        <Spinner className="centro3" animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    :
                                        <div style={{height:'600px', overflow:'hidden'}}>
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                                                {/*------------------------------------ */}
                                                <Form>   
                                                    
                                                    <Form.Group as={Row} controlId="formDescricao">
                                                        <Form.Label column sm={3}>
                                                            Cliente
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formDescricao" variant="outline-secondary" onClick={this.onEditCampo.bind(this)}><Image id="formDescricao" src={require('./img/pencil_48.png')} width='20'/></Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>{this.state.poligono.poligono_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.poligono.descricao} disabled={this.state.disable_placa} onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>                                         
                                                    
                                                    <Form.Group as={Row} controlId="formCor">
                                                        <Form.Label column sm={3}>
                                                            Cor
                                                        </Form.Label>
                                                        <Col sm={8}>

                                                            <DropdownButton  variant="outline-secondary"
                                                                    title={
                                                                            <img className="thumbnail-image" 
                                                                                src={require('./img/poligono/'+this.state.poligono.cor+'.png')}
                                                                                width="20" height="20"
                                                                                alt="user pic"
                                                                            />                                                
                                                                    } >                                                            
                                                                <Dropdown.Item onClick={()=>this.setColorPoligon("#0277bd")} > <img src={require('./img/poligono/#0277bd.png')} width="20" height="20" /> </Dropdown.Item>
                                                                <Dropdown.Item onClick={()=>this.setColorPoligon("#3546e6")}> <img src={require('./img/poligono/#3546e6.png')} width="20" height="20" /> </Dropdown.Item>
                                                                <Dropdown.Item onClick={()=>this.setColorPoligon("#ED4145")}> <img src={require('./img/poligono/#ED4145.png')} width="20" height="20" /></Dropdown.Item>
                                                                <Dropdown.Item onClick={()=>this.setColorPoligon("#db1d0f")}> <img src={require('./img/poligono/#db1d0f.png')} width="20" height="20" /> </Dropdown.Item>
                                                                <Dropdown.Item onClick={()=>this.setColorPoligon("#FFFF00")}> <img src={require('./img/poligono/#FFFF00.png')} width="20" height="20" /> </Dropdown.Item>
                                                                <Dropdown.Item onClick={()=>this.setColorPoligon("#FFA500")}> <img src={require('./img/poligono/#FFA500.png')} width="20" height="20" /> </Dropdown.Item>
                                                                <Dropdown.Item onClick={()=>this.setColorPoligon("#008000")}> <img src={require('./img/poligono/#008000.png')} width="20" height="20" /> </Dropdown.Item>
                                                                <Dropdown.Item onClick={()=>this.setColorPoligon("#12db1c")}> <img src={require('./img/poligono/#12db1c.png')} width="20" height="20" /> </Dropdown.Item>
                                                            </DropdownButton>


                                                            {/* <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formCor" variant="outline-secondary" onClick={this.onEditCampo.bind(this)}><Image id="formCor" src={require('./img/pencil_48.png')} width='20'/></Button>
                                                                </InputGroup.Prepend>
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.poligono.cor} disabled={this.state.disable_chip} onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup> */}
                                                        </Col>
                                                    </Form.Group>
                                                

                                                    {/* ----------------Tab---------------------------------------------------- */}
                                                    <Card >
                                                        <Card.Header>
                                                            <Nav variant="tabs" defaultActiveKey="#Veiculos">
                                                                <Nav.Item >
                                                                    <Nav.Link  data-toggle="tab" href="#Veiculos">Notificação</Nav.Link>                                                                    
                                                                </Nav.Item>                                                                                     
                                                            </Nav>
                                                        </Card.Header>

                                                        <div class="tab-content">
                                                            <Card.Body id="Veiculos" className="tab-pane active">
                                                                {/* <NotificacaoAdd MapaKseg={this.props.MapaKseg} /> */}
                                                                {/* <NotificacaoAdd MapaKseg={this.props.MapaKseg} /> */}
                                                                <GridVeiNotif MapaKseg={this.props.MapaKseg} />
                                                            </Card.Body>

                                                            {/* ------------------------------------ */}
                                                                                                                
                                                        </div>
                                                    </Card>                                                                                                           

                                                </Form>
                                                {/* ----------------------------------- */}
                                            </CustomScroll>


                                            <Modal.Footer >
                                                        {/* <div className="align_bottom_left">
                                                            <Button onClick={this.onVoltarClick}><img src={require('./img/arrow_left24.png')} title={"Voltar"}/></Button>
                                                        </div> */}
                                                        {this.state.editado?
                                                            <div className="align_bottom_right">
                                                                <Button onClick={this.onSalvar}><img src={require('./img/checkbold24.png')}/>Salvar</Button>
                                                            </div> 
                                                        :
                                                            <div className="align_bottom_right">
                                                                <Button disabled><img src={require('./img/checkbold24.png')}/>Salvar</Button>
                                                            </div>    
                                                        }
                                            </Modal.Footer>

                                        </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}