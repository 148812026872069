import React, {Component, useState} from 'react';
import {Form, Modal, Button } from 'react-bootstrap';
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import api from '../api';
import Resizer from 'react-image-file-resizer';

import ImageCropper from "../imagecropper";

function blobToFile(theBlob, fileName){
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}

export class ImageCropperModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            animation: "zoom",
            scrollOffset: 0,
            activeIndexCarousel:0,
            ImageCropper:null,
            render_image: false,
            file:null,
            Modal:null,
            aspectRatio:4,
        };
        this.handleChange = this.handleChange.bind(this);
      }
      
    //-------------------
    minimizarModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "slideLeft"});
        this.props.Modals.closeImageCropperModal();
    };
    //---------close----------
    fecharModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "zoom"});
        // this.props.Modals.closeImageCropperModal();
        this.props.Modals.desativarImageCropperModal();
    };
    //------------------------
    desativarModal = () =>{    
        if(this.props.Modals.imageCropperShow === false)
            this.props.Modals.desativarImageCropperModal();
    };  
    //------------------------
    async componentDidMount() {
        this.setState({Modal: this.props.objeto});    
    }
    //-------------------------
    async componentDidUpdate() {
        // this.scrollElement();
        // ToastStore.success("shiitt");
        // if(this.state.scrollOffset===0)
        //     this.setState({scrollOffset: 800, });
    }
    //------------------------
    postImgUsuario = async(img)=>{
        try {
            var pieces = img.split(",");
            img=pieces[1];
            let body = new FormData()
            body.append('usuario_id', this.props.usuario_id)
            body.append('data_str', img)
            body.append('token', localStorage.getItem('token'));
            const response = await api.post('/login_api/postImgUsuario', body);        
            console.log(response);

        }catch (response){}
    }
    //-------------------------
    resFile = async(file)=>{
        const resizeFile = (file) => new Promise(resolve => {
            Resizer.imageFileResizer(file, 600, 600, 'JPEG', 100, 0,
            uri => {
                resolve(uri);
                var myFile = blobToFile(uri, "my_image.png");
                this.setState({file: URL.createObjectURL(myFile)
                                    , render_image:true});
                // console.log(uri);
            },
            'blob'
            );
        });

        const image = await resizeFile(file);
        return image;
    }
    //-------------------------
    handleChange (event) {
        if(event.target.files){
            const image = this.resFile(event.target.files[0]);
            // this.setState({
            //     render_image:true
            //     // ,file: URL.createObjectURL(event.target.files[0])
            //     // ,file: URL.createObjectURL(this.state.file2)
            //     // ,file2: event.target.files[0]
            //     });  
        }
    }
    //-------------------------
    onInputClick = ()=>{
        this.setState({file:null, render_image:false});
    }
    //-------------------------
    onRadioClick = ()=>{
        this.setState({render_image:false},
            () => { this.setState({render_image:true }) })
    }
    //-------------------------
    onSalvarClick = ()=>{
        if(this.state.file){
            var img = this.state.ImageCropper.state.imageDestination;    
            console.log(img);
            let cliente = this.state.Modal.state.cliente;
            cliente.imagem = img;
            let editados = this.state.Modal.state.editados;
            editados.imagem = true;
            this.state.Modal.setState({cliente: cliente, editados: editados, changed: true});
            this.props.Modals.desativarImageCropperModal();
        }else 
            this.props.MapaKseg.callToast("Escolha o arquivo!");
    }
    //-------------------------
    render(){
        //--------------------
        return(
            <div className="drag9" >
                <Draggable   handle="strong"  >                
                    <div >                    
                        <Rodal 
                            visible={this.props.show}
                            animation={this.state.animation}
                            showCloseButton={false}
                            duration={600}
                            width={400}
                            height={410}
                            onAnimationEnd={()=>this.desativarModal()}
                            >  
                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false} titulo={"Logo"} Modal={this}/></strong>
                                <div className="padding_modal">                                                                                                                                                                              
                                    <input id="fileItem" type="file" accept="image/*" onChange={this.handleChange} onClick={()=> this.onInputClick()}/>
                                    <Form>                                                                                                
                                        <Form.Check inline defaultChecked  label="Logo" name="group1" type={'radio'} id={'radio2'} onClick={ (e) => { this.setState({aspectRatio:4}); this.onRadioClick() } } />
                                        <Form.Check inline label="Foto" name="group1" type={'radio'} id={'radio1'} onClick={ (e) => { this.setState({aspectRatio:1}); this.onRadioClick() } } />                                                                                                
                                    </Form>
                                    {this.state.render_image ?
                                        <div>                                                    
                                            <ImageCropper aspectRatio={this.state.aspectRatio} UsuarioModal={this} src={this.state.file}/>
                                        </div>
                                    :""}
                                     
                                                                                                                                                                                                                                  
                                    <Modal.Footer >                                     
                                        <div className="align_bottom_right">
                                            <Button onClick={this.onSalvarClick}><img src={require('../img/checkbold24.png')}/></Button>
                                        </div> 
                                    </Modal.Footer>                                    
                                </div>
                                    
                                                           
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>
        
        );
    }

    
}