import  {Component} from 'react';
import api from './api';
import {ToastStore} from 'react-toasts';

const NOVO_POLIGONO =1;
const EDITAR_POLIGONO =2;

export class PostPoligono extends Component{
    constructor(props, MapaKseg){
        super(props);
        this.state = {
            loading:true,
            MapaKseg:MapaKseg,
       }
    }
    //---------------------
    toastStore = (texto) => {
        ToastStore.success("teste: "+texto);
    };   
    //--------------------------------  
    postPoligono = async (modo, PoligonoModal) => {  
        try {
            var poligono_novo_list = this.state.MapaKseg.state.poligono_novo_list;
            var latLngs_list=[];
            if (modo === NOVO_POLIGONO){
                for(var i=0; i<poligono_novo_list.length; i++ ){
                    // var json = JSON.stringify({"latitude":poligono_novo_list[i].latitude, "longitude":poligono_novo_list[i].longitude});
                    var json = JSON.stringify({latitude:poligono_novo_list[i].latitude, longitude:poligono_novo_list[i].longitude});
                    latLngs_list.push(json);                            
                }
            }else if(modo === EDITAR_POLIGONO){                
                for(var i=0; i<poligono_novo_list.length; i++ ){
                    console.log("poligono_coordenada_id: "+String(poligono_novo_list[i].poligono_coordenada_id));
                    var json = JSON.stringify({poligono_coordenada_id: poligono_novo_list[i].poligono_coordenada_id, latitude:poligono_novo_list[i].latitude, longitude:poligono_novo_list[i].longitude});
                    latLngs_list.push(json);                            
                }
            }

            var latLngs_list2 = "" //JSON.stringify(latLngs_list);
            // console.log(JSON.parse(latLngs_list));
            
            var jsonObject = JSON.stringify({tipo_poligono_id: this.props.tipo_poligono_id  
                                            ,descricao: this.props.descricao                
                                            ,pessoa_id: this.state.MapaKseg.state.pessoa_id
                                            ,usuario_id: this.state.MapaKseg.state.usuario_id
                                            ,grupo_id:   this.props.grupo_id
                                            ,latLngs: latLngs_list2});
                                            // ,latLngs: JSON.parse(latLngs_list2)});
            let body = new FormData();
            
            body.append('json', jsonObject);
            body.append('latLngs', latLngs_list);
            // body.append('latLngs', JSON.parse(latLngs_list));
            body.append('token', localStorage.getItem('token'));
            console.log("modo: "+String(modo));

            var url = "";
             if (modo === NOVO_POLIGONO)
                url ='/poligono_api/salvarPoligono';
             else if  (modo === EDITAR_POLIGONO)        
                url ='/poligono_api/editarPoligono';

                const response = await api.post(url, body);
                var responseJson = null
                if (response.data !== null  &&  response.status !== 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json); 
                    // console.log(resposta.poligono_id);
                    var poligono_id = resposta.poligono_id;     
                    
                    ToastStore.success("Poligono recebido no servidor!");
                    //---------------
                    poligono_novo_list[0].descricao = this.props.descricao;
                    var novo_poligono = {dados: poligono_novo_list
                                        ,poligono_id: poligono_id
                                        ,descricao: this.props.descricao
                                        ,lat_lng_list: this.state.MapaKseg.state.poligono_novo_latlngs.lat_lng_list
                                        ,cor: poligono_novo_list[0].cor
                                        ,flag: poligono_novo_list[0].flag
                                        ,nome: this.props.descricao};

                    var poligonos_list = this.state.MapaKseg.state.poligonos_list;
                    poligonos_list.push(novo_poligono);
                    this.state.MapaKseg.setState({poligonos_list: poligonos_list, carregarPolygon:true
                                            ,carregarPolygonNovo:false ,poligono_novo_list:[], poligono_novo_latlngs:[]}); //, carregarPolygon:true

                    PoligonoModal.setState({salvando:false, activeIndexCarousel:0
                                    , scrollOffset: PoligonoModal.state.scrollOffset + 1000000});

                }
            
            
                console.log("latLngs_list:");
                console.log(latLngs_list);
            

        } finally {
                // this.state.MapaKseg.setState({eventos_list: this.state.list});

            }
    };
    //--------------------------
    async componentDidMount() {
    };
}