import React, {Component} from 'react';
import {Modal, Form, Button as Button_bootstrap} from 'react-bootstrap';
import './estilo.css';
import { ToastStore} from 'react-toasts';
import Spinner from 'react-bootstrap/Spinner';
import ReactCodeInput from 'react-verification-code-input';
import { NumberBox, RadioGroup } from 'devextreme-react';
import {PostComando} from './PostComando';
import {CabecalhoModal} from './CabecalhoModal';


export  class ComandoModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true, 
            showSheet:true,
            tempGridModalShow:false,
            gruposVei_list:"",
            loading:true,
            codeInput: "",
            numberBox: "",
            numberBox2: "",
            comando_id: props.comandosVei_list[0].comando_id,
            comando: props.comandosVei_list[0].comando,
        };
      }
    //------------------------
    async componentDidMount() {
        // ToastStore.success("criado GruposVeiculoModal");
        // ToastStore.success("usuario_id: "+String(this.props.usuario_id));
      }     
    //--------------------
    toastStore2 = () => {
        ToastStore.success("teste2222");
    };
  //-------------------------
    onComplete = () => {
        this.setState({clockVisibility:"visible"});
    };
    //-------------------------
     expand = () => {
         this.toastStore2();
        // this.setState({dialogClassNn:"temperatura-modal2"});
    };
    //------------------------
    onChangeCheckBox(e){  
        // ToastStore.success(String(e.target.id));
        this.setState({comando_id: e.target.id});
        for(var i=0; i<this.props.comandosVei_list.length; i++ ){
            if(parseInt(this.props.comandosVei_list[i].comando_id)===parseInt(e.target.id))
                this.setState({comando: this.props.comandosVei_list[i].comando});
        }
    }
    //------------------------
    onChangeCodeInput = (rCodeInput)=>{
        this.setState({codeInput: rCodeInput});
    }
    //------------------------
    onChangeNumberBox = (numberBox)=>{
        this.setState({numberBox: numberBox.value});
        // ToastStore.success(String(numberBox.value));
    }
    //------------------------
    onChangeNumberBox2 = (numberBox)=>{
        this.setState({numberBox2: numberBox.value});
        // ToastStore.success(String(numberBox.value));
    }
    //------------------------
    onEnviarComando = ()=>{
        // ToastStore.success("onEnviarComando");
        // ToastStore.success(String(this.state.codeInput));
        if(parseInt(this.state.codeInput)===1010){
            let comando = { comando_id: this.state.comando_id
                           ,comando: this.state.comando
                           ,versao_equipamento_id: this.props.comando.versao_equipamento_id
                           ,tecnologia_id: 77
                           ,valor1: this.state.numberBox
                           ,valor2: this.state.numberBox2
                        };
            var instance = new PostComando(comando, this.props.MapaKseg); 
            instance.postComando();
            this.props.onHide();
        }else{
            ToastStore.error("PIN Incorreto!");
        }
    }
    //------------------------
    gerarZeros = (valor_maximo)=>{
        var zeros="";
        for(var i=0; i<String(valor_maximo).length; i++ ){
            zeros = zeros+"0";
        }
        return zeros;
    }
    //---------close----------
    fecharModal = () =>{
        this.props.onHide();
    };
    //-------------------------  <img src={this.props.comando.icone} /> {this.props.comando.descricao}
    render(){
        let radioItems = [];
        //--------------------
        return(
            <div className="splashscreen-modal1">
                <Modal  centered
                    {...this.props}
                    
                    aria-labelledby="example-custom-modal-styling-title"
                    scrollable="True"
                    dialogClassName="temperatura-modal2"    
                    >
                    <div >
                    <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.comando.descricao} Modal={this}/><img src={this.props.comando.icone} /></strong>
                        
                    <Modal.Body>                                                
                        {this.props.comando.configuravel ? 
                            
                            <div>                                                            
                                {this.props.comandoConfiguracao_list.length >0 ? 
                                    <div>
                                        <text>{this.props.comandoConfiguracao_list[0].descricao}:</text>
                                        {/* <br /> */}
                                        {/* <text>{String(this.props.comandoConfiguracao_list[0].valor_maximo)}</text> */}
                                        <NumberBox 
                                        // format={this.gerarZeros(this.props.comandoConfiguracao_list[0].valor_maximo)}
                                            format="#" width="100" onValueChanged={this.onChangeNumberBox}/>
                                        <br />
                                    </div>
                                :  ""} 
        
                                {this.props.comandoConfiguracao_list.length >1 ? 
                                    <div>
                                        <text>{this.props.comandoConfiguracao_list[1].descricao}:</text>
                                        <NumberBox 
                                            format="#" width="100" onValueChanged={this.onChangeNumberBox2}/>
                                        <br />
                                    </div>
                                :  ""} 
                            </div>
                        : 
                            this.props.comandosVei_list[0].tipo_comando_envio_id === 1 ?
                                
                                <div>
                                    {/* <RadioGroup 
                                        items={[this.props.comandosVei_list[0].descricao, this.props.comandosVei_list[1].descricao]}
                                        onValueChanged={this.onChangeCheckBox}  /> */}



                                    <Form.Check                                        
                                        type="radio"
                                        label={this.props.comandosVei_list[0].descricao}
                                        name="formHorizontalRadios"
                                        id={this.props.comandosVei_list[0].comando_id}
                                        onChange={this.onChangeCheckBox.bind(this)}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label={this.props.comandosVei_list[1].descricao}
                                        name="formHorizontalRadios"
                                        id={this.props.comandosVei_list[1].comando_id}
                                        onChange={this.onChangeCheckBox.bind(this)}
                                    /> 

                                </div>
                            : ""
                                
                        }
                       

                        
                        <br />
                        <ReactCodeInput fields={4} title={"Confirme seu PIN:"} onChange={this.onChangeCodeInput}/>

                    </Modal.Body>
                    </div>
                    {/* {this.state.relPosicoesVeiModalShow ? this.renderRelPosicoesVeiModal(): ""} */}


                    <Modal.Footer>                        
                        <Button_bootstrap className="left" onClick={this.props.onHide}>Cancelar</Button_bootstrap>
                        <Button_bootstrap onClick={this.onEnviarComando}> Enviar</Button_bootstrap>                
                    </Modal.Footer>

                </Modal>

            </div>
        );
    }
}