import React from 'react';

import 'devextreme/data/odata/store';
import DataGrid, { Column, Summary, TotalItem, Selection, MasterDetail } from 'devextreme-react/data-grid';
import '../estilo.css';
import ContextMenu from 'devextreme-react/context-menu';
// import notify from 'devextreme/ui/notify';
// import {GetComandosVeiculo} from '../GetComandosVeiculo';
import GetPoligonos from '../GetPoligonos';
// import GetGruposPoligono from '../GetGruposPoligono';
// import ScrollView from 'devextreme-react/scroll-view';
// import SelectBox from 'devextreme-react/select-box';
// import {dados_jornada} from '../dados/jornada_data.js';
import {eventos_jornada} from '../dados/jornada_data.js';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
// import JornadaDetailGrid2 from './JornadaDetailGrid2.js';
import JornadaEventosChartGrid from './JornadaEventosChartGrid.js';
import CustomScroll from 'react-customscroll';
import api from '../api';
import JornadaLinhaTempo from './JornadaLinhaTempo.js';
var moment = require('moment');

const statuses = ['All', 'Not Started', 'In Progress', 'Need Assistance', 'Deferred', 'Completed'];

const getEventos2 = (key) =>
  new DataSource({
    store: new ArrayStore({
      data: eventos_jornada,
      key: 'evento_jornada_motorista_id',
    }),
    filter: ['jornada_motorista_id', '=', key],
  });

export class JornadaGrid extends React.Component {
  constructor(props) {
    super(props);
    // this.onRowPrepared = this.logEvent.bind(this, 'RowInserting');
    this.onRowPrepared = this.onRowPrepared.bind(this); 

    this.state = {
      countNotificacao: 0,
      props: props,
      GridVeiculoDetail: null,
      items:[],
      loading:false,
      filterStatus: 'tesste',
      grupo_id:0,
      gruposPoligono_list: [],
      grupos_list: [],
      list_jornada:[],
      list_eventos:[]
    }

  // -------------------------------
    this.dataGridRef = React.createRef();
 
    this.selectedRowsData = [];

    this.getSelectedData = () => {
        this.selectedRowsData = this.dataGrid.getSelectedRowsData();

        // ===== or when deferred selection is used =====
        this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            // Your code goes here
        });
    }
    //-----
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onRowExpanding = this.onRowExpanding.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);

  }
//------------------------------------------
  get dataGrid() {
    return this.dataGridRef.current.instance;
  }
  //-------------------------------------
  getEventos = (key) =>
  new DataSource({
    store: new ArrayStore({
      // data: eventos_jornada,
      data: this.state.list_eventos,
      key: 'evento_jornada_motorista_id',
    }),
    filter: ['jornada_motorista_id', '=', key],
  });
//------------------------------------------
  onRowPrepared(e) {  
    // if (e.rowType !== "data")  
    //     return  
    // alert(JSONString);
    
    if(e.data !== undefined){
      if (e.data.tipo_evento_jornada_motorista_id === 1)
        e.rowElement.style.backgroundColor = "#98d4b4";
      else if(e.data.tipo_evento_jornada_motorista_id===2)
        e.rowElement.style.backgroundColor = "#F095A1";
      else if(e.data.tipo_evento_jornada_motorista_id===3)
        e.rowElement.style.backgroundColor = "#98d4b4";   
      else if(e.data.tipo_evento_jornada_motorista_id===4)
        e.rowElement.style.backgroundColor = "#e8d979";  
      else if(e.data.tipo_evento_jornada_motorista_id===5)
        e.rowElement.style.backgroundColor = "#e8d979"; 
      else if(e.data.tipo_evento_jornada_motorista_id===6)
        e.rowElement.style.backgroundColor = "#e8d979";                        
    }  
  

    // if (e.rowType === "data")  
    //   e.data.selectRows([5, 10, 12], true);
     
  } 
  //---------------------------------------------------------------
  addMenuItems(e) {
    if (e.target == 'header') {
        // e.items can be undefined
        if (!e.items) e.items = [];

        // Add a custom menu item
        e.items.push({
            text: 'Log Column Caption',
            onItemClick: () => {
                // alert(e.column.caption);
                // console.log(e.column.caption);
            }
        });
    }
  }
  //-------------------------------------------------------------------
  ItemTemplate (e) {
    return (
      <React.Fragment>
        <span className={ e.icon } />
        { e.items ? <span className="dx-icon-spinright" /> : null }
        { e.text }
      </React.Fragment>
    );
  }
  //---------------------------------------------------------------
  itemClick = (e) => {
    if (!e.itemData.items) {
      // console.log(e);
      // alert(e);
      // notify(`The "${ e.itemData.text }" item was clicked`+" shitt", 'success', 1500);     
      // alert(e.itemData.text);   
      var selectedRowData = this.dataGrid.getSelectedRowsData(); 
      // console.log(selectedRowData);
      // alert(selectedRowData);   
    //   this.props.MapaKseg.setState({veiculoPosicao_selecionado: selectedRowData[0]});

      // if(e.itemData.text === this.state.items[0].text){
      //   this.props.MapaKseg.state.Modals.showCadCliente(selectedRowData[0]);
      //   // var instance = new GetComandosVeiculo(selectedRowData[0], this.props.MapaKseg); 
      //   // instance.getComandosVei();   

      // }else if(e.itemData.text === this.state.items[1].text){
      //   this.props.MapaKseg.state.Modals.showCadCliente(selectedRowData[0]);
      // }




      // console.log(selectedRowData);
      // alert(selectedRowData[0].recebimento_id);
    //   this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
    //     // Your code goes here
    //     alert("shitt yeah");
    //   });

    }
  }
  //--------------------------------------------------------------
  onSelectionChanged({ selectedRowsData }) {
    // console.log("selectedRowsData: "+ selectedRowsData.length);
    // console.log(selectedRowsData);
    
    // if(selectedRowsData.length>0){
    //   let obj = selectedRowsData[0].dados[0];
    //   this.props.MapaKseg.ajustarZoom(obj);
    //   this.props.MapaKseg.setState({selectedPolygon: obj});
    // }
  }
  //-------------------------------------------------------------
  onRowExpanding= (e) => {
    e.component.selectRows(e.key, false);
    // this.props.MapaKseg.ajustarZoom(selectedRowsData[0]);
  }
  //-----------------------------------------
  getPoligonos = () => {
    var instance = new GetPoligonos(this.props.MapaKseg, this.state.grupo_id, "", null);
    instance.getPoligonos();
  } 
  //---------------------------------------------------------------
  componentDidMount() {
    this.props.PainelGrid.setState({JornadaGrid: this});
    this.props.MapaKseg.state.Modals.setState({JornadaGrid: this});

    var items = this.state.items;

    if(this.props.MapaKseg.state.pessoa_id===1)
      items.push({ text: ' Abrir Jornada', icon: "dx-icon-activefolder"  });
      // items.push({ text: ' Nova Jornada', icon: "dx-icon-add"  });

    // var gruposPoligono = new GetGruposPoligono(this.props.MapaKseg.state.usuario_id, this);
    // gruposPoligono.getGruposPoligono();

    this.setState({items: items});

    this.getDadosJornadaMotorista();    
  };
  //-------------------------------------------------------------------------------------
  getDadosJornadaMotorista = async () => {
    // console.log("  ***this.props.data_receb: "+this.props.data_receb)
    let list_jornada = [];
    try {
        this.setState({loading: true});
        let body = new FormData()
        body.append('pessoa_id', this.props.MapaKseg.state.pessoa_id)
        // body.append('data_ini', this.state.data_inicial+':00')
        // body.append('data_final', this.state.data_final+':00')
        body.append('token', localStorage.getItem('token'))
        // body.append('temperaturas_tab', this.state.veiculo_temperaturas_tab)

        var url = "/jornada_motorista_api/getJornadasMotoristas";
        const response = await api.post(url, body);
        var responseJson = null

        if (response.data !== null  &&  response.status !== 403){
            responseJson = JSON.stringify(response.data)
            var req = { json: responseJson };
            var resposta = JSON.parse(req.json);
            // console.log(resposta);              

            // ToastStore.success("resposta.length: "+ String(resposta.length))                 
                for (var i = 0; i < resposta.length; i++) { 
                    // console.log(resposta[i]);
                    // list.push(resposta[i]);
                    list_jornada.push({
                      jornada_motorista_id: resposta[i].jornada_motorista_id, 
                      data_inicial: resposta[i].data_inicial,
                      data_final: resposta[i].data_final,
                      motorista_nome: resposta[i].motorista_nome,
                      motorista_cartao_codigo: resposta[i].motorista_cartao_codigo,
                      placa: resposta[i].placa,
                      tipo_evento_jornada_motorista_id: resposta[i].tipo_evento_jornada_motorista_id,
                      tipo_evento_jornada_motorista: resposta[i].tipo_evento_jornada_motorista,
                      data_atual_veiculo: resposta[i].data_atual_veiculo,
                      municipio_inicial: resposta[i].municipio_inicial,
                      municipio_final: resposta[i].municipio_final,
                    });
                    this.setState({list_jornada: list_jornada});
                }


            this.setState({loading: false});
            
        }

    } finally {
      this.getDadosEventoJornadaMotorista(list_jornada);
    }
  };   
  //-------------------------------------------------------------------------------------
  getDadosEventoJornadaMotorista = async (list_jornada) => {
    // console.log("  ***this.props.data_receb: "+this.props.data_receb)
    try {
        // this.setState({loading: true});

        var jornadas_id_str="";
        for (var i = 0; i < list_jornada.length; i++) {  
            if (i==0) jornadas_id_str += list_jornada[i].jornada_motorista_id;
            else jornadas_id_str+=","+ list_jornada[i].jornada_motorista_id;
        }

        if(jornadas_id_str === "") jornadas_id_str = "0"

        let body = new FormData()
        body.append('jornadas_id_str', jornadas_id_str);
        // body.append('data_ini', this.state.data_inicial+':00')
        // body.append('data_final', this.state.data_final+':00')
        body.append('token', localStorage.getItem('token'))
        // body.append('temperaturas_tab', this.state.veiculo_temperaturas_tab)

        var url = "/jornada_motorista_api/getEventosJornadasMotoristas";
        const response = await api.post(url, body);
        var responseJson = null

        if (response.data !== null  &&  response.status !== 403){
            responseJson = JSON.stringify(response.data)
            var req = { json: responseJson };
            var resposta = JSON.parse(req.json);
            // console.log(resposta);
            let list_eventos = [];          
      
            // alert(resposta.length);
                for (var i = 0; i < resposta.length; i++) { 
                  let data_inicial;
                  let data_final;
                  let tempo = null;
                    // console.log(resposta[i]);
                    // list.push(resposta[i]);
                    list_eventos.push({
                      evento_jornada_motorista_id: resposta[i].evento_jornada_motorista_id, 
                      jornada_motorista_id: resposta[i].jornada_motorista_id,
                      tipo_evento_jornada_motorista_id: resposta[i].tipo_evento_jornada_motorista_id,
                      tipo_evento_jornada_motorista: resposta[i].tipo_evento_jornada_motorista,
                      data_inicial: resposta[i].data_inicial,
                      data_final: resposta[i].data_final,
                      data_final_now: "",
                      tempo_evento: 0,
                      tempo_evento_diff_minutes: 0,
                      tempo_evento_diff_str: '',
                      recebimento_id_inicial: resposta[i].recebimento_id_inicial,
                      recebimento_id_final: resposta[i].recebimento_id_final,
                      municipio_inicial: resposta[i].municipio_inicial,
                      municipio_final: resposta[i].municipio_final,
                    });

                    data_final = list_eventos[i].data_final;

                    if (resposta[i].data_final == null || resposta[i].data_final == ""){
                      list_eventos[i].data_final = "";                       

                      data_final = moment().format('ddd DD/MM/YYYY  HH:mm:ss');
                      tempo = this.dateDiff(list_eventos[i].data_inicial+":00", data_final );
                      list_eventos[i].tempo_evento = tempo;
                      list_eventos[i].tempo_evento_diff_minutes = parseInt(tempo.diff_minutes);
                      list_eventos[i].tempo_evento_diff_str = tempo.diff_str;

                    }else{
                      tempo = this.dateDiff(list_eventos[i].data_inicial+":00", list_eventos[i].data_final+":00" );
                      list_eventos[i].tempo_evento = tempo;
                      list_eventos[i].tempo_evento_diff_minutes = parseInt(tempo.diff_minutes);
                      list_eventos[i].tempo_evento_diff_str = tempo.diff_str;
                    }



                    this.setState({list_eventos: list_eventos});
                }


            // this.setState({loading: false});
            
        }

    } finally {}
  };     
  //----------------------------------------------------
  dateDiff=(data_inicio, data_final)=>{
    // data_inicio: sex 21:47:10   03-07-2020 
    // qua 03/04/2024 11:58
    if(String(data_inicio).length> 19){
        // var ano_inicio = data_inicio.substring(21,25);
        // var mes_inicio = data_inicio.substring(18,20);
        // var dia_inicio = data_inicio.substring(15,17);
        // var hora_inicio = data_inicio.substring(4,12);

        var ano_inicio = data_inicio.substring(10,14);
        var mes_inicio = data_inicio.substring(7,9);
        var dia_inicio = data_inicio.substring(4,6);
        var hora_inicio = data_inicio.substring(16,21);
        console.log("ano_inicio: "+String(ano_inicio));
        console.log("mes_inicio: "+String(mes_inicio));
        console.log("dia_inicio: "+String(dia_inicio));
        console.log("hora_inicio: "+String(hora_inicio));
        console.log(ano_inicio+"-"+mes_inicio+"-"+dia_inicio+"T"+hora_inicio+"-03:00");

        var a = moment(ano_inicio+"-"+mes_inicio+"-"+dia_inicio+"T"+hora_inicio+"-03:00");  //2020-03-17T17:39:31-03:00
        console.log("a: "+String(a));

        var ano = data_final.substring(10,14);
        var mes = data_final.substring(7,9);
        var dia = data_final.substring(4,6);
        var hora = data_final.substring(16,21);
        console.log("ano_fim: "+String(ano));
        console.log("mes_fim: "+String(mes));
        console.log("dia_fim: "+String(dia));
        console.log("hora_fim: "+String(hora));

        console.log(ano+"-"+mes+"-"+dia+"T"+hora+"-03:00");
        var b = moment(ano+"-"+mes+"-"+dia+"T"+hora+"-03:00");  //2020-03-17T17:39:31-03:00        
        console.log("b: "+String(b));
        const precise_diff =  moment.preciseDiff(a, b, true); 
        console.log("precise_diff: "+String(precise_diff));
    
        const diff = b.diff(a);
        const diffDuration = moment.duration(diff);
        let diff_minutes = parseInt(diffDuration.asMinutes())
        // return diff_minutes
        // const diff_days = parseInt(diffDuration.asDays())
    
        // // alert(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes");
        // alert(diff_minutes + " minutes");
    
        // console.log("****  diff_minutes: "+String(diff_minutes));
    
        // return String(ano_anterior+"-"+mes_anterior+"-"+dia_anterior+"T"+hora_anterior+"-03:00");
        // return "("+String(diff_minutes)+"min)";
    
        let diff_str = this.transformarHrsDiasMeses(precise_diff);        


        // return diff_minutes, diff_str

        return {
          diff_minutes: diff_minutes, 
          diff_str: diff_str
      };
    }
  }  
    //----------------------------------------------------
    transformarHrsDiasMeses=(_moment)=>{
      let diff_str = "";

        if(_moment.minutes===0)
            if(_moment.seconds===0)
                diff_str = "0";
            else diff_str = _moment.seconds+"seg";
        else if(_moment.hours === 0)
          diff_str = _moment.minutes+"min";
        else if(_moment.days === 0){
            if(_moment.hours===1)
              diff_str = String(_moment.hours)+"hr "+_moment.minutes+"min";
            else diff_str = String(_moment.hours)+"hrs "+_moment.minutes+"min";
        }else if(_moment.months === 0){
            if(_moment.days===1)
              diff_str = String(_moment.days)+"dia "+String(_moment.hours)+"hr "+_moment.minutes+"min";
            else diff_str = String(_moment.days)+"dias "+String(_moment.hours)+"hr "+_moment.minutes+"min";
        }else if(_moment.years === 0){
            if(_moment.months===1)
              diff_str = String(_moment.months)+"mes "+String(_moment.hours)+"dia "+String(_moment.hours)+"hrs "+_moment.minutes+"min";
            else diff_str = String(_moment.months)+"meses "+String(_moment.hours)+"dia "+String(_moment.hours)+"hrs "+_moment.minutes+"min";
        }

        return diff_str;
    }
    //----------------------------------------------------
    transformarHrsDiasMeses2=(_moment)=>{
      // console.log("****  transformarHrsDiasMeses2   ***** ");
      let diff_str = "";

        if(_moment.minutes()===0)
            if(_moment.seconds()===0)
                diff_str = "0";
            else diff_str = _moment.seconds()+"seg";
        else if(_moment.hours() === 0)
          diff_str = _moment.minutes()+"min";
        else if(_moment.days() === 0){
            if(_moment.hours()===1)
              diff_str = String(_moment.hours())+"hr "+_moment.minutes()+"min";
            else diff_str = String(_moment.hours())+"hrs "+_moment.minutes()+"min";
        }else if(_moment.months() === 0){
            if(_moment.days()===1)
              diff_str = String(_moment.days())+"dia "+String(_moment.hours())+"hr "+_moment.minutes()+"min";
            else diff_str = String(_moment.days())+"dias "+String(_moment.hours())+"hr "+_moment.minutes()+"min";
        }else if(_moment.years() === 0){
            if(_moment.months()===1)
              diff_str = String(_moment.months())+"mes "+String(_moment.hours())+"dia "+String(_moment.hours())+"hrs "+_moment.minutes()+"min";
            else diff_str = String(_moment.months())+"meses "+String(_moment.hours())+"dia "+String(_moment.hours())+"hrs "+_moment.minutes()+"min";
        }

        // console.log("  diff_str: "+String(diff_str));

        return diff_str;
    }
  //--------------------------------------------------------------
  customizeSomaTotal= (data) => {
    // let soma_total = moment.utc().startOf('day').add(data.value, 'minutes');
    // let soma_total = moment(data.value, "hmm").format("HH:mm:ss");
    let _moment = moment.utc().startOf('day').add(data.value, 'minutes').format();
    // const precise_diff =  moment.preciseDiff(_moment, 0, true);
    // let soma_total = precise_diff.minutes;

    // let date = moment(_moment);

    let soma_total = moment().minutes(data.value);
    // soma_total = soma_total.minutes();
    // soma_total = soma_total.hours();
    // soma_total = this.transformarHrsDiasMeses2(soma_total);
    // let date = moment("2001-01-21");
    // var a = moment().minutes(20); 
    // Printing the updated minutes 
    // console.log(" ----  Printing the updated minutes -----"); 
    // console.log(a);  
    // console.log("a.minutes");  
    // console.log(a.minutes);  
    // let soma_total = moment().minutes(data.value);
    // soma_total = this.transformarHrsDiasMeses(soma_total);
    // soma_total = soma_total.minutes;
    // let soma_total = moment.utc().startOf('day').add(data.value, 'minutes').format('hh:mm A')
    // let soma_total = data.value
    // let soma_total = data.value.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'});


    // var s = "2013-01-01T00:00:00-13:00";
    // moment(s).utcOffset(s);
    // soma_total = moment(s).minutes;
    // soma_total = moment(s).utc().minutes();
    // soma_total =  moment(s).duration().minutes();
    // soma_total = moment.duration(data.value, 'minutes');
    soma_total = moment.duration(data.value);
    soma_total = soma_total.minutes();

    let duration = moment.duration(data.value, 'minutes');
    soma_total = this.transformarHrsDiasMeses2(duration);
    // soma_total = duration.months()+' - '+duration.days()+' - '+duration.hours()+':'+duration.minutes() + ':' +duration.seconds();

    return `${soma_total}`;
  }
  //--------------------------------------------------------------
  onValueChanged({ value }) {
    console.log("value:");
    console.log(value);
    console.log(value.trim());
    var gruposPoligono_list = this.state.gruposPoligono_list;
    var list_aux = gruposPoligono_list.filter((item,index) =>{  
      return item.nome.trim() === value.trim();
    })

    console.log("list_aux:");
    console.log(list_aux);

    // if (value === 'All') {
    //   dataGrid.clearFilter();
    // } else {
    //   dataGrid.filter(['nome', '=', value]);
    // }

    // this.setState({filterStatus: value, grupo_id: list_aux[0].grupo_id});
    // this.props.MapaKseg.setState({selectedPolygon: {}});
    // this.getPoligonos();
  }
    //---------------------------------------------------------------------------------------
    renderDetail= (props) => {
      const dataSource = this.getEventos(props.data.jornada_motorista_id);
      // tempo = this.dateDiff(props.data.data_inicial+":00", props.data.data_final+":00" );
      // const dataSource = getEventos2(props.data.jornada_motorista_id);
      // alert(props.data.jornada_motorista_id);
      return (
        <React.Fragment>
          <JornadaLinhaTempo data_source={dataSource}  />
  
          <DataGrid
            dataSource = {dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            rowAlternationEnabled={true}
          >

            <Column dataField="evento_jornada_motorista_id" caption={"evento_id"}/>
            <Column dataField="tipo_evento_jornada_motorista" caption={"evento"}/>
            <Column dataField="data_inicial" caption={"Data Inicial Evento"}/>
            <Column dataField="data_final" caption={"Data Final Evento"}/>
            <Column dataField="tempo_evento_diff_str" caption={"Tempo evento"}/>
            {/* <Column dataField="tempo_evento_diff_minutes" caption={"Tempo evento"}/> */}
            <Column dataField="municipio_inicial"  caption={"Município Inicial"} />
            <Column dataField="municipio_final"  caption={"Município Final"} />
  
            <Selection mode="single" />
            {/* <Scrolling mode='virtual'></Scrolling> */}
            
            <Summary>
                <TotalItem
                    column="tempo_evento_diff_minutes"
                    summaryType="sum"
                    showInColumn="tempo_evento_diff_str"
                    customizeText = {this.customizeSomaTotal}
                  />  
            </Summary>
          </DataGrid>
        </React.Fragment>
      );
    }
    //---------------------------------------------------------------------------------------
    renderDetail2= (props) => {
      const dataSource = this.getEventos(props.data.key);
      // alert(props.data.jornada_motorista_id);
      return (
        <JornadaEventosChartGrid/>
      );
    }    
  //---------------------------------------------------------------
  render() {
    const onContextMenuPreparing = (e) =>{
        try{
          e.component.selectRows(e.row.key, false)
        //   var selectedRowData = this.dataGrid.getSelectedRowsData();
        //   this.props.MapaKseg.ajustarZoom(selectedRowData[0]);
        }catch (response){}

    }
    
    return (      
        this.state.loading ? "":
          <div>            
            {/* <div className="left-side">
              <SelectBox
                items={this.state.grupos_list}
                value={this.state.filterStatus}
                onValueChanged={this.onValueChanged} 
                />
            </div> */}
            <div className="right-sidee">
                  <div className="logo">
                    &nbsp;
                  </div>
            </div>

            <ContextMenu
                dataSource={this.state.items}
                width={180}
                target="#dtGridJornada"
                itemRender={this.ItemTemplate}
                onItemClick={this.itemClick} />
            
            <div  style={{height:"400px"}} >
            <CustomScroll  flex="1">
              <DataGrid
                id="dtGridJornada"
                keyExpr="jornada_motorista_id"
                // dataSource={this.props.MapaKseg.state.poligonos_list}
                // dataSource = {dados_jornada}
                dataSource = {this.state.list_jornada}
                showBorders={true}
                onRowPrepared={this.onRowPrepared}
                onContextMenuPreparing={onContextMenuPreparing}
                ref={this.dataGridRef}
                onSelectionChanged={this.onSelectionChanged} 
                columnAutoWidth={true}
                allowColumnResizing={true}
                // height={480}                 
              //   onRowExpanding={this.onRowExpanding}    
                >

                  <Selection mode="single" />

                  <Column dataField="jornada_motorista_id" caption={"id"} />
                  <Column dataField="motorista_nome" caption={"motorista"} />
                  <Column dataField="placa" caption={"veículo"} />
                  <Column dataField="data_atual_veiculo" />
                  <Column dataField="motorista_cartao_codigo" caption={"cartão"} />
                  {/* <Column dataField="status_jornada" /> */}
                  {/* <Column dataField="evento" /> */}
                  {/* <Column dataField="tipo_evento_jornada_motorista_id" caption={"evento_id"} /> */}
                  <Column dataField="tipo_evento_jornada_motorista" caption={"evento"}/>
                  <Column dataField="data_inicial" caption={"Data Inicial Jornada"}/>
                  <Column dataField="data_final" caption={"Data Final Jornada"}/>                  
                  <Column dataField="municipio_inicial"  caption={"Município Inicial"} />
                  <Column dataField="municipio_final"  caption={"Município Final"} />
                  
                  {/* <MasterDetail enabled={true} render={JornadaDetailGrid2} /> */}
                  <MasterDetail enabled={true} render={this.renderDetail} />

                  {/* <MasterDetail enabled={true} render={JornadaLinhaTempo} /> */}
                  {/* <MasterDetail enabled={true} render={JornadaDetailGrid2} /> */}
                  {/* <MasterDetail enabled={true} render={JornadaEventosChartGrid} />    */}
                  {/* <MasterDetail enabled={true} render={JornadaDetailGrid3} /> */}
                  {/* <MasterDetail enabled={true} render={this.renderDetail2} /> */}
                  {/* <MasterDetail enabled={true} render={JornadaLinhaTempo2} /> */}
                  {/* <MasterDetail enabled={true} render={JornadaDetailGrid4} /> */}
                  {/* <MasterDetail enabled={true} render={JornadaDetailGrid} /> */}
              </DataGrid>        
            </CustomScroll> 
            </div>   
          </div>     
    );
  }
}
