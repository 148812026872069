import React, {Component} from 'react';
import '../estilo.css';
import 'rodal/lib/rodal.css';
import DataGrid, {  Scrolling, Selection, Paging } from 'devextreme-react/data-grid';
import ContextMenu from 'devextreme-react/context-menu';
import GetMunicipio from '../services/get_municipio';

const INSERT =1;
const DELETE =2;
const UPDATE =3;

export class RotaMunicipioGrid extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            GeralListModal:null,
            height_grid:0,
            items:[],
            load:true,
        };
         // -------------------------------
        this.dataGridRefMunicipioGrid = React.createRef();
    
        this.selectedRowsData = [];

        this.getSelectedData = () => {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData();
            this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            });
        }
      }
    //---------------------------------------------------------------
    get dataGrid() {
        return this.dataGridRefMunicipioGrid.current.instance;
    }
    //-------------------------------------------------------------------
    async componentDidMount() {
        this.carregarItems()
    }; 
    //-----------------------------------------------------------------------
    carregarItems() {  
        let items = this.state.items;
        items.push({ text: ' Add', icon: "dx-icon-add"  });
        items.push({ text: ' Remover', icon: "dx-icon-remove"  });

        this.setState({ items: items  }, 
            () => { this.setState({load:false})});
    };
    //-------------------------------------------------------------------
    ItemTemplate (e) {
        return (
        <React.Fragment>
            <span className={ e.icon } />
            { e.items ? <span className="dx-icon-spinright" /> : null }
            { e.text }
        </React.Fragment>
        );
    }
    //---------------------------------------------------------------
    itemClick = (e) => {
        if (!e.itemData.items) {
            let selectedRowData = this.dataGrid.getSelectedRowsData(); 
            // alert(e.itemData.text+"  "+selectedRowData[0]);          

            if(e.itemData.text === this.state.items[0].text){
                this.props.Modals.showGeralList(this,"Municípios", 0);    
            }else 
                if(e.itemData.text === this.state.items[1].text){
                    this.remover(selectedRowData[0]);            
                }
        }
    }
    //-------------------------------------------------------------------
    procurarOutroObj (list) {     
        let obj = null;
        for (var i = 0; i < list.length; i++) { 
            if(list[i].operacao != DELETE){
                obj = list[i];
                break;
            }            
        }
        return obj;
    }
    //-------------------------------------------------------------------
    remover(obj) {
        let municipios = this.props.objeto.state.rota.municipios;

        if(obj.rota_municipio_id === 0 ){
            municipios = municipios.filter((g,index) =>{ return g.municipio_id !== obj.municipio_id; });
        }else{
            for (var j = 0; j < municipios.length; j++) { 
                if(municipios[j].municipio_id === obj.municipio_id){                                                             
                    municipios[j].operacao = DELETE;
                }
            }            
        }
        
        let editados = this.props.objeto.state.editados;
        editados.municipios =  true;

        let rota = this.props.objeto.state.rota;
        rota.municipios = municipios;
        rota.municipios = rota.municipios.concat(); // para não precisar dar reload no grid

        this.props.objeto.setState({ 
            rota: rota
            , changed: true
            , editados: editados});

        // this.setState({load: true}, () => { this.setState({load:false})});
    }
    //------------------------------------------------------------------------    
    onRowPrepared(e) {      
        if(e.data !== undefined){
            if (e.data.operacao === 0 ){
                if(e.data.index %2 === 0)
                    e.rowElement.style.backgroundColor = "#F5F5F5";
                else e.rowElement.style.backgroundColor = "#FFFFFF";                

            }else if (e.data.operacao === 1 || e.data.operacao === 3)
                    e.rowElement.style.backgroundColor = "#92d1b0";
            else if(e.data.operacao===2)
                e.rowElement.style.backgroundColor = "#f593a0";     
        }      
    } 
    //------------------------------------------------------------------
    getList= (geralListModal, tipo) => { 
        this.setState({GeralListModal: geralListModal});  
        var instance = new GetMunicipio(null, this, this.props.MapaKseg, 0, 0);
        instance.get(); 
    };
    //-------------------------------------------------------------
    setListMunicipio =  (_list) => {  
        let array =[]
        let adicionar=true;
        let municipios = this.props.objeto.state.rota.municipios

        for (var i = 0; i < _list.length; i++) { 
            for (var j = 0; j < municipios.length; j++) { 
                if(municipios[j].municipio_id == _list[i].municipio_id){
                    adicionar=false;
                    break;
                }else adicionar=true;
            }

            if(adicionar==true){
                array.push({
                    index: _list[i].index
                     ,id: _list[i].municipio_id
                    , descricao: _list[i].descricao
                    , selecionada: false
                    , index_scroll: 0});
            }
        }
        this.state.GeralListModal.setList(array, 0);
    }; 
    //-------------------------------------------------------------------------
    setObjeto= (objeto) => { 
        let municipio ={
            rota_municipio_id: 0
            , municipio_id: objeto.id
            , municipio: objeto.descricao
            , operacao: INSERT};

        let municipios = this.props.objeto.state.rota.municipios
        let lista = [];
        lista.push(municipio);
        lista = lista.concat(municipios);

        let rota = this.props.objeto.state.rota;
        rota.municipios = lista;

        this.props.objeto.setState({rota: rota});

        let editados = this.props.objeto.state.editados;
        editados.municipios =  true;

        this.props.objeto.setState({
                editados: editados 
                ,changed:true});
        
    };
    //--------------------------------------------------------------------------
    onRowInserted(e) {
        alert("onRowInserted");
        e.component.navigateToRow(e.key);
    }
    //------------------------------------------------------------------------------
    render(){                
        const onContextMenuPreparing = (e) =>{  // precisa desse para selecionar a linha com o botao direito
            try{
              e.component.selectRows(e.row.key, false)
              var selectedRowData = this.dataGrid.getSelectedRowsData();
            //   this.props.MapaKseg.ajustarZoom(selectedRowData[0]);
      
            //   console.log(e.row.key);
              // alert(e.row.key);
            }catch (response){}
          }
        const columns = [ 'municipio_id','municipio'];
        return(
            this.state.load? ""
            :<div >                
                 <ContextMenu
                    dataSource={this.state.items}
                    width={180}
                    target="#municipiogrid"
                    itemRender={this.ItemTemplate}
                    onItemClick={this.itemClick} />
                
                    <DataGrid
                        id="municipiogrid"
                        key="municipio_id"
                        ref={this.dataGridRefMunicipioGrid}
                        dataSource={this.props.objeto.state.rota.municipios}
                        showBorders={true}
                        // rowAlternationEnabled={true}
                        defaultColumns={columns}
                        onRowPrepared={this.onRowPrepared}
                        columnAutoWidth={true}
                        onContextMenuPreparing={onContextMenuPreparing}
                        height={this.props.height_grid}
                        > 
                        <Selection mode="single" />        
                        <Scrolling mode="virtual" />
                        <Paging defaultPageSize={100} />
                    </DataGrid>                       
            </div>        
        );
    }
}