import  {Component} from 'react';
import api from '../api';
import {ToastStore} from 'react-toasts';

// import {Polyline} from 'google-maps-react';

export default class GetUsuarioRotas extends Component{
    constructor(props, objeto, MapaKseg, usuario_id){
        super(props);
        this.state = {
             list: [],
             loading:true,
             objeto: objeto,
             MapaKseg: MapaKseg,
             usuario_id: usuario_id
       }
    }
    //------------------------------------------------
    get = async () => {
        let principal="";
        try {
            let body = new FormData()
            body.append('usuario_id', this.state.usuario_id);
            body.append('pessoa_id', this.state.MapaKseg.state.pessoa_id);
            body.append('token', localStorage.getItem('token'));

            const response = await api.post('/cadastro_api/getUsuarioRotas', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let obj = {};
                let list = this.state.list; 

                // ToastStore.success("resposta.length: "+ String(resposta.length));
                for (var i = 0; i < resposta.length; i++) {                        
                    obj = {    
                        index: i+1,
                        rota_id: resposta[i].rota_id,
                        rota: resposta[i].rota,
                        filial_id: resposta[i].filial_id,
                        filial: resposta[i].filial,
                        operacao: 0
                    };

                    // if(tab.principal==true ){
                    //     tab.enable=true;
                    //     principal = tab.descricao;
                    // }

                    // tab.renderTab = this.state.objeto.setTab(tab.tab_id);    

                    list.push(obj);
                    this.setState({list: list});
                }

                this.setState({loading: false});
                
            }
        } finally {    
            if(this.state.list.length>0){                     
                let usuario = this.state.objeto.state.usuario;
                usuario.rotas = this.state.list;
                this.state.objeto.setState({usuario: usuario}); 

                // this.state.objeto.setState({tabs: this.state.list, tabs2: tabb2, activeKey: principal});
                // this.state.objeto.setState({tab_load: false});              
            }
        }
    };
    //--------------------------
}