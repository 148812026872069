import React, {Component} from 'react';
import '../estilo.css';
import DataGrid, { Column, Scrolling, Selection, MasterDetail, HeaderFilter, FilterPanel, FilterRow  } from 'devextreme-react/data-grid';
import {Spinner, ListGroup, Form, Card, FormControl, Modal, Button} from 'react-bootstrap';
// import ReactDOM from 'react-dom';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';

// import 'devextreme/dist/css/dx.light.css';
// import './focus.css';


export class GeralListModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true,
            showSheet:true,
            tempGridModalShow:false,
            clockVisibility:"not-visible",
            list:[],
            loading:true,
            animation: "zoom",
            height_modal:0,
            height_grid:450,
            scrollOffset:0,
            foundItems: [],
            // dialogClassNn:"temperatura-modal",
        };

        this.dataGridRef = React.createRef();
        this.onSelectionChanged = this.onSelectionChanged.bind(this);

      }
    //------------------------
    async componentDidMount() {
        this.props.objeto.getList(this, this.props.tipo, this.props.filtro);       
    }   
    //------------------------------------------
    get dataGrid() {
        return this.dataGridRef.current.instance;
    }
    //------------------------------------------
    onRowPrepared(e) {          
        if(e.data !== undefined){
            // if(e.data.index === 0)
            //     e.rowElement.focus();

            if(e.data.index %2 === 0)
                e.rowElement.style.backgroundColor = "#F5F5F5";
            else e.rowElement.style.backgroundColor = "#FFFFFF";                
        }       
    } 
    //------------------------------------------
    onCellPrepared(e) {          
        console.log("e.rowType:");
        console.log(e.rowType);

        if(e.rowType === 'filter'  && e.columnIndex === 0 ){ 
            // e.cellElement.foc      
            //  e.cellElement.classList.add("filter-row-focus");
            // e.cellElement.value = "RR";
            // e.cellElement.querySelectorAll(".dx-texteditor-input");
            // e.component.focus();  
            // e.component.focus(e.cellElement);  
            // (".filter-row-focus .dx-textbox").dxTextBox('instance').focus();
            // e.cellElement.classList.add('filter-row-focus');
            // e.cellElement.classList.add('filter-row-focus');
            // e.cellElement.Focus();
            // e.focus();
            // e.cellElement.focus();
            // e.cellElement.addClass('filter-row-focus');
        }       
    } 
    //---------------------------------------
    onSelectionChanged({ selectedRowsData }) {
        const data = selectedRowsData[0];

        this.props.objeto.setObjeto(data, this.state.tipo);
        this.fecharModal();
    }
    //------------------------------------------
    setList = async (_list, tipo)=>{            
        this.setState({
             list: _list  
            , loading:false    
            , tipo: tipo       
            , foundItems: _list },
            () => { });                        
    }        
    //-----------------
    sleep (milliseconds) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    }
    //-------------------
    minimizarModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "slideLeft"});
        this.props.Modals.closeGeralList();
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeGeralList();
    };
    //---------close----------
    desativarModal = () =>{
        if(this.props.show === false)
            this.props.Modals.desativarGeralList();  
    };
    //---------------------------------
    onFocus(event) {   
        this.setState({value: "k"});
    }
    //---------------------------------
    onChangeSearch(event) {        
        console.log(event.target.value);
        let keyword = event.target.value;
        this.setState({value: keyword});

        if (keyword !== '') {
            const results = this.state.list.filter((item) => {
              return item.descricao.toLowerCase().startsWith(keyword.toLowerCase());
              // Use the toLowerCase() method to make it case-insensitive
            });
            this.setState({foundItems: results});
            // setFoundUsers(results);
        }else
            this.setState({foundItems: this.state.list});
      }
    //---------------------------------
    onCadastroClick = ()=>{
        if(this.props.titulo === "Clientes Coleta" || this.props.titulo === "Clientes Entrega"){
            let cliente = {cliente_id:0, nome:"", local_id:0, local:"", municipio_id:0, municipio:"", pessoa_id:0, nome_social:"", imagem:""
          , telefone:"", cnpj_cpf:"", juridica:""}; // Novo
            this.props.Modals.showClienteCadModal(this.props.objeto, cliente);
        }

        this.fecharModal();
    }  
    //-------------------------
    render(){
        const onContextMenuPreparing = (e) =>{  // precisa desse para selecionar a linha com o botao direito
            try{
              e.component.selectRows(e.row.key, false)
              var selectedRowData = this.dataGrid.getSelectedRowsData();
      
              // console.log(e.row.key);
              // alert(e.row.key);
            }catch (response){}
          }

        const columns = ['id','descricao'];
        return(
            <div className="drag7"  >
                <Draggable  handle="strong"  >
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={480}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            autoFocus={false}
                            >
                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false} titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal" style={{background: 'white'}}>
                                    <Card>
                                        {this.state.loading ?
                                            <Form  style={{height: '390px', overflow: 'hidden'}}>
                                                <div className="centro">
                                                    <div className="espaco_topo3">
                                                        <Spinner animation="border" />
                                                    </div>
                                                </div>                                        
                                            </Form>
                                        :
                                        <div >                                                                
                                            <DataGrid
                                                id="geralgrid"
                                                ref={this.dataGridRef}
                                                dataSource={this.state.list}
                                                showBorders={true}
                                                defaultColumns={columns}
                                                onRowPrepared={this.onRowPrepared}
                                                columnAutoWidth={true}
                                                onContextMenuPreparing={onContextMenuPreparing}
                                                height={this.state.height_grid}
                                                showColumnHeaders={false}
                                                onSelectionChanged={this.onSelectionChanged}
                                                // onCellPrepared={this.onCellPrepared}
                                            > 
                                                <Selection mode="single" />        
                                                <Scrolling mode="virtual" />
                                                <FilterRow visible={true} />
                                            </DataGrid>
                                
                                        </div>
                                        }

                                        <Modal.Footer> 
                                            {this.props.botao_cadastrar ?
                                                <Button onClick={this.onCadastroClick}><img src={require('../img/plus_white24.png')}/></Button>
                                            :""}                                            
                                        </Modal.Footer>

                                    </Card>
                                </div>
                            </div>
                        </Rodal>
                    </div>
                </Draggable>
            </div>
        );
    }
}
