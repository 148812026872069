import React, {Component} from 'react';
import './estilo.css';
import { ToastStore} from 'react-toasts';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
// import ReactDOM from 'react-dom';
import {CabecalhoModal} from './CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';

import NotificacaoCard from './NotificacaoCard';
import api from './api';

const CARREGAR_PRIMEIRAS =1;
const CARREGAR_MAIS_ANTIGAS =2;


export class NotificacaoModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            animation: "zoom",
            height_modal:0,
            scrollOffset:0,
            list:[],
            notificacao_envio_id_list_str:"",
            carregandoMaisNotificacoes:false,
            ultimo_notificacao_envio_id:0,
            nenhuma_notificacao:false,
            count_nao_visualizado:0,
            // dialogClassNn:"temperatura-modal", %a %H:%M:%S   %d-%m-%Y
            notificacoes: [
                {notificacao_envio_id:1, veiculo_id:666, tipo_acao_id:1, enviado: true, data_envio:"seg 01:00:00  01-01-2021", mensagem:"Aggressive Perfector", index_scroll:0}
                ,{notificacao_envio_id:2, veiculo_id:667, tipo_acao_id:2, enviado: true, data_envio:"qui 13:56:00  09-01-2021", mensagem:"Guided by Evil", index_scroll:0}
                ,{notificacao_envio_id:3, veiculo_id:668, tipo_acao_id:2, enviado: true, data_envio:"ter 14:30:00  12-01-2021", mensagem:"Perpetual Chaos", index_scroll:0}
                ,{notificacao_envio_id:4, veiculo_id:669, tipo_acao_id:1, enviado: true, data_envio:"qua 09:21:00  16-01-2021", mensagem:"Piece by Piece", index_scroll:100}
                ,{notificacao_envio_id:5, veiculo_id:670, tipo_acao_id:2, enviado: true, data_envio:"sex 10:45:00  19-01-2021", mensagem:"Necrophobic", index_scroll:200}
            ],
        };
        this.anchors = {};
      }
    //------------------------
    async componentDidMount() {
        // ToastStore.success("criado GruposVeiculoModal");
        // ToastStore.success("usuario_id: "+String(this.props.usuario_id));

        // var instance = new GetGruposPoligono(this.props.MapaKseg.state.usuario_id, this);
        // instance.getGruposPoligono();
    

        if(this.state.list.length===0)
            this.getNotificacoes();

        this.props.Modals.setState({NotificacaoModal: this});

        if(this.props.Modals.state.GridColetaEntrega != null)
          this.props.Modals.state.GridColetaEntrega.visibleFilterrowFalse();

        // this.postNotificacaoVisualizada();
      }
    //--------------------
    toastStore2 = () => {
        ToastStore.success("teste2222");
    };
  //-------------------------
    onComplete = () => {
        this.setState({clockVisibility:"visible"});
    };
    //-------------------------
    expand = () => {
         this.toastStore2();
        // this.setState({dialogClassNn:"temperatura-modal2"});
    };
    //------------------------------------------
    setGrupoId = (grupo)=>{
        let listaVazia = [];
        // localStorage.setItem('grupo_id', grupo_id)
        // this.props.MapaKseg.recarregarPosicoesClick();
        // this.props.MapaKseg.setState({ coords: [] });
        // this.props.MapaKseg.setState({ markerPlus: {} });
        // this.props.MapaKseg.setState({markerPosicao_list: listaVazia});
        // this.props.MapaKseg.setState({markerEvento_list: listaVazia});

        // var instance = new GetPoligonos(this.props.MapaKseg, grupo.grupo_id, grupo.nome);
        // instance.getPoligonos();

        // this.props.MapaKseg.closeBottomSheet();
        this.fecharModal();
    }
    //-------------------
    minimizarModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "slideLeft"});
        this.props.Modals.closeNotificacaoModal();

        if(this.props.Modals.state.GridColetaEntrega != null)
          this.props.Modals.state.GridColetaEntrega.visibleFilterrow();
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeNotificacaoModal();

        if(this.props.Modals.state.GridColetaEntrega != null)
            this.props.Modals.state.GridColetaEntrega.visibleFilterrow();
    };
    //---------close----------
    desativarModal = () =>{
        if(this.props.show === false)
            this.props.Modals.desativarNotificacaoModal();
    };
    //-------------------------
    sequenciaUpdate = () => {
        this.props.Modals.setModalSelecionado("GruposPoligonoModal");
    };
    //-------------------------
    getNotificacoes = async () => {
        try {
            let body = new FormData()
            body.append('usuario_id', this.props.MapaKseg.state.usuario_id);
            body.append('notificacao_envio_id', this.state.ultimo_notificacao_envio_id);
            body.append('token', localStorage.getItem('token'));

            const response = await api.post('/mensagem_api/getNotificacoes', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                var list = this.state.list;  
                var notificacao={};
                var notificacao_envio_id_list_str="";
                // console.log("resposta:");
                // console.log(resposta);
                for (var i = 0; i < resposta.length; i++) {    
                        notificacao = {  
                            notificacao_envio_id : resposta[i].notificacao_envio_id,
                            veiculo_id : resposta[i].veiculo_id,                            
                            tipo_notificacao_id : resposta[i].tipo_notificacao_id,                        
                            enviado: resposta[i].enviado,
                            data_envio : resposta[i].data_envio,
                            mensagem : resposta[i].mensagem,
                            visualizado : resposta[i].visualizado,
                            index_scroll:0
                        };

                        // console.log(notificacao.notificacao_envio_id);
                                               

                        if(notificacao.visualizado === false){
                            this.setState({count_nao_visualizado: this.state.count_nao_visualizado+1});

                            notificacao_envio_id_list_str += notificacao.notificacao_envio_id +",";
                        }

                        list.push(notificacao);   
                        this.setState({list: list});  
                }  
            
                // console.log(notificacao_envio_id_list_str);

                if(notificacao_envio_id_list_str.length > 0)
                    notificacao_envio_id_list_str = notificacao_envio_id_list_str.substring(0,notificacao_envio_id_list_str.length-1);;

                if(resposta.length>0)    
                    this.setState({ultimo_notificacao_envio_id: resposta[resposta.length-1].notificacao_envio_id});
                else this.setState({nenhuma_notificacao:true});
                }       
            

        }catch (response){

            // alert("Shitt!!");
        }
         finally {
            this.setState({loading: false, carregandoMaisNotificacoes:false, notificacao_envio_id_list_str: notificacao_envio_id_list_str});     
            this.props.MapaKseg.setState({count_nao_visualizado: this.state.count_nao_visualizado});
            // alert(notificacao_envio_id_list_str);

            if(this.props.MapaKseg.state.count_nao_visualizado > 0)
                this.postNotificacaoVisualizada();

        }
    };
    //-------------------------
    postNotificacaoVisualizada = async () => {
        try {
            this.setState({count_nao_visualizado: 0});
            this.props.MapaKseg.setState({count_nao_visualizado: 0});


            let body = new FormData()
            body.append('usuario_id', this.props.MapaKseg.state.usuario_id);
            body.append('notificacao_envio_id_list_str', this.state.notificacao_envio_id_list_str);
            body.append('token', localStorage.getItem('token'));

            const response = await api.post('/mensagem_api/postNotificacaoVisualizada', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                var list = this.state.list;  
                var notificacao={};
                // console.log("resposta:");
                // console.log(resposta);  
                // alert(resposta);
                if(resposta === "ok"){
                    this.props.MapaKseg.setState({count_nao_visualizado: 0});
                    this.setState({count_nao_visualizado: 0});
                }

                }       
            

        } finally {

            }
    };    
    //-------------------------
    render(){
        //--------------------
        return(
            <div className="drag1" >
                <Draggable   handle="strong"  >
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={480}
                            height={500}
                            onAnimationEnd={()=>this.desativarModal()}
                            >
                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false} titulo={"Notificações"} Modal={this}/></strong>
                                <div className="padding_modal">
                                    {this.state.loading ?
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    :
                                        <div style={{height: '390px', overflow: 'hidden'}}>
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0" >
                                                <ListGroup>{
                                                    this.state.list.map((notificacao) =>
                                                        <NotificacaoCard value={notificacao} MapaKseg={this.props.MapaKseg} showMapIcon={false}/>
                                                    )}
                                                    </ListGroup>

                                                    {this.state.nenhuma_notificacao?
                                                        <div className="texto_centro">
                                                            <text>Nenhuma Notificação!</text>
                                                        </div>
                                                        :
                                                        <div className="texto_centro">
                                                            <Button variant="light" onClick={() => this.getNotificacoes()}>
                                                                {this.state.carregandoMaisNotificacoes ?
                                                                    <div><Spinner animation="border" />CARREGANDO...</div>
                                                                : <div>CARREGAR MAIS</div>}
                                                            </Button>
                                                        </div>
                                                    }
                                            </CustomScroll>

                                            

                                        </div>

                                    }
                                </div>
                            </div>
                        </Rodal>
                    </div>
                </Draggable>
            </div>
        );
    }
}
