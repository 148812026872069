import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {Image} from 'react-bootstrap';
import {ToastStore} from 'react-toasts';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: 'flex',
    marginTop: 5,
    marginBottom: 5,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  details_gray: {
    background: "#bdbebd",
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    margin: 8,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  backIconRoxo: {
    background: "#7441E7",
    width: 100,
  },
  backIconVerde: {
    background: "#229558",
    width: 100,
  },
  backIconVermelho: {
    background: "#ED4145",
    width: 100,
  },
  backIconVermelhoClaro: {
    background: "#f56e71",
    width: 100,
  },
  backIconCinza: {
    background: "#5e5c5c",
    width: 100,
  },
  backIconAmarelo: {
    background: "#FDA95F",
    width: 100,
  },
  backIconAmarelo2: {
    background: "#D8C03F",
    width: 100,
  },
  backIconAmareloAlaranjado: {
    background: "#D28E7B",
    width: 100,
  },
  backIconLaranja: {
    background: "#ff9000",
    width: 100,
  },
  backIconVerdeMed: {
    background: "#00A99F",
    width: 100,
  },  
  backIconAzulClaro: {
    background: "#5D7EA4",
    width: 100,
  },
  textColorCinza: {
    color: "#6d6b6b"
  },
  textBold: {
    color: "#000000",
    fontWeight: "bold"
  },
  pos_right: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
}));
//--------------------------------  
var moment = require('moment');
require('moment-precise-range-plugin');
 //--------------------------------  
 function setDiaSemana(dia){
  switch(dia) {
      case 'Mon':
        return 'segunda-feira';
      case 'Tue':
        return 'terça-feira';
      case 'Wed':
          return 'quarta-feira';  
      case 'Thu':
          return 'quinta-feira';    
      case 'Fri':
        return 'sexta-feira';
      case 'Sat':
          return 'sábado';  
      case 'Sun':
          return 'domingo';    
      default:
          return ''  ;
  }
};
//---------------------
function setDiaMes (data){
  var dia_mes = data.split("-");

  switch(parseInt(dia_mes[1])) {
      case 1:
        return dia_mes[0]+' de janeiro';
      case 2:
        return dia_mes[0]+' de fevereiro';
      case 3:
          return dia_mes[0]+' de março';  
      case 4:
          return dia_mes[0]+' de abril';    
      case 5:
        return dia_mes[0]+' de maio';
      case 6:
          return dia_mes[0]+' de junho';  
      case 7:
          return dia_mes[0]+' de julho';    
      case 8:
          return dia_mes[0]+' de agosto';
      case 9:
          return dia_mes[0]+' de setembro';                                    
      case 10:
          return dia_mes[0]+' de outubro';                
      case 11:
          return dia_mes[0]+' de novembro';                
      case 12:
          return dia_mes[0]+' de dezembro';                
      default:
          return parseInt(dia_mes[1])  ;
  }
};
//---------------------
function setDiaMesHora(data){
  var dia_mes = data.split("-");

  if(dia_mes[2]===null)
      return null;

  return this.setDiaMes(data) + " "+dia_mes[2];
};
//-------------------------------------
function setBackDetails (visualizado, classes) { 
  if(visualizado){
    return classes.details;
  }else   return classes.details_gray;
};
//-------------------------------------
function setBackIcon (tipo_notificacao_id, classes) {       

  switch(tipo_notificacao_id) {
      case 1:
          return classes.backIconRoxo; 
      case 2:
          return classes.backIconVermelhoClaro;           
      case 3:
        return classes.backIconLaranja; 
      case 4:
        return classes.backIconVerdeMed;    
      case 5:
          return classes.backIconRoxo;         
      case 42:
        return classes.backIconVermelho;      
      case 66:
          return classes.backIconVermelhoClaro;                     
      case 67:
          return classes.backIconVerde; 
      case 68:
          return classes.backIconRoxo; 
      case 69:
          return classes.backIconAmarelo;               
      case 70:
          return classes.backIconVermelhoClaro;
      case 71:
          return classes.backIconVermelhoClaro;    
      default:
        return classes.backIconCinza; 
    }
};
//---------------------
function setIcone  (tipo_notificacao_id){       
  switch(tipo_notificacao_id) {
      case 1:
        return require("./img/kseg_logo_preto.png");     
      case 2:
        return require("./img/ic_bloqueio_preto.png");         
      case 3:
        return require("./img/vector_square7.png"); 
      case 4:
          return require("./img/vector_square6.png");    
      case 5:
          return require("./img/user1.png");                                                    
      case 42:
          return require("./img/ic_antenna_black_48.png"); 
      case 66:
          return require("./img/key_off48.png");                              
      case 67:
          return require("./img/account_card_details48.png"); 
      case 68:
          return require("./img/porta_bau_aberta48.png");  
      case 69:
          return require("./img/porta_bau_lateral_aberta6.png"); 
      case 70:
            return require("./img/alert_octagon36x36.png");                               
      case 71:
          return require("./img/ic_antenna_black_48.png");
      default:
        return require("./img/alert_circle48.png");  
    }
};
//---------------------
function toastStore (){
        ToastStore.success(" Ver no mapa");
    };   
//---------------------
function zoomMapaKseg (MapaKseg, evento){
  MapaKseg.ajustarZoom(evento);
  MapaKseg.toggleBottomSheet();


  let eventoClicado_list = MapaKseg.state.eventoClicado_list;
  eventoClicado_list.push(evento);
  MapaKseg.setState({eventoClicado_list: eventoClicado_list});
  let marrrk = MapaKseg.state.marker_aux;
  // marrrk.position = {lat: evento.latitude, lng: evento.longitude};

  MapaKseg.setState({
    selectedPlace: evento.recebimento_id,
    // activeMarker: evento.maark, 
    activeMarker: MapaKseg.state.marker_aux,
    marker_aux: marrrk ,
    infoWindowLocation: {lat: evento.latitude, lng: evento.longitude},
    showingInfoWindow: true
  });

};   
//----------------------------------------------------------------
function dateDiff2(data_inicio, data_final){  //  2020-07-08 19:17:53
  var str = "mes: "+data_inicio.substring(5,7)
          +"\n horario: "+data_inicio.substring(12,20);
  return str;
}
//----------------------------------------------------
function dateDiff(data_inicio, data_final){
  
  if(String(data_inicio).length> 19){
    var ano_anterior = data_inicio.substring(0,4);
    var mes_anterior = data_inicio.substring(5,7);
    var dia_anterior = data_inicio.substring(8,10);
    var hora_anterior = data_inicio.substring(12,20);
    var a = moment(ano_anterior+"-"+mes_anterior+"-"+dia_anterior+"T"+hora_anterior+"-03:00");  //2020-03-17T17:39:31-03:00

  //   sex 21:47:10   03-07-2020  

    var ano = data_final.substring(0,4);
    var mes = data_final.substring(5,7);
    var dia = data_final.substring(8,10);
    var hora = data_final.substring(12,20);
    var b = moment(ano+"-"+mes+"-"+dia+"T"+hora+"-03:00");  //2020-03-17T17:39:31-03:00

    const precise_diff =  moment.preciseDiff(a, b, true); 

    const diff = b.diff(a);
    const diffDuration = moment.duration(diff);
    const diff_minutes = parseInt(diffDuration.asMinutes())
    // const diff_days = parseInt(diffDuration.asDays())

    // // alert(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes");
    // alert(diff_minutes + " minutes");


    // console.log("****  "+hora_anterior+"  "+posicaoAnterior.data_receb+"  -   "+posicao.data_receb+"   "+String(diff_minutes) + " minutes  "+String(inserir))

    // return String(ano_anterior+"-"+mes_anterior+"-"+dia_anterior+"T"+hora_anterior+"-03:00");
    // return "("+String(diff_minutes)+"min)";

    if(precise_diff.minutes===0)
      if(precise_diff.seconds===0)
        return "(0)";
      else return "("+precise_diff.seconds+"seg)";
    else if(precise_diff.hours === 0)
      return "("+precise_diff.minutes+"min)";
    else if(precise_diff.days === 0){
      if(precise_diff.hours===1)
        return "("+String(precise_diff.hours)+"hr "+precise_diff.minutes+"min)";
      else return "("+String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min)";
    }else if(precise_diff.months === 0){
      if(precise_diff.days===1)
        return "("+String(precise_diff.days)+"dia "+String(precise_diff.hours)+"hr "+precise_diff.minutes+"min)";
      else return "("+String(precise_diff.days)+"dias "+String(precise_diff.hours)+"hr "+precise_diff.minutes+"min)";
    }else if(precise_diff.years === 0){
      if(precise_diff.months===1)
      return "("+String(precise_diff.months)+"mes "+String(precise_diff.hours)+"dia "+String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min)";    
      else return "("+String(precise_diff.months)+"meses "+String(precise_diff.hours)+"dia "+String(precise_diff.hours)+"hrs "+precise_diff.minutes+"min)";    
    }
  }
}
//---------------------
function cardNotificacao (props){
  const classes = useStyles();
  // const classes = null;
  //const [root, details,content, cover, controls, backIconRoxo, backIconVerde, backIconVermelho, backIconVermelhoClaro,backIconCinza, backIconAmarelo,textColorCinza,pos_right ] = useState(0);
  const { value, MapaKseg, showMapIcon } = props;
  var backIcon = classes.backIconVerde;
  var backIcon = setBackIcon(value.tipo_notificacao_id, classes);
  var icon = setIcone(value.tipo_notificacao_id);
  var dia_semana = setDiaSemana(value.data_envio.substring(0,3));
  var dia_mes = setDiaMes(value.data_envio.substring(13,26));
  var hora = value.data_envio.substring(4,9); 
  var backDetails = setBackDetails(value.visualizado, classes);

  // ToastStore.success(String(value.backup));  
  
  return(
    <Card className={classes.root} >
      
      <div className={backIcon}>

      <div  className="text-center">
        <Image src={icon} className={classes.cover} style={{width: '50%'}}/>
        <br/>
        <Typography component="h8" variant="h8">
            {dia_semana}
        </Typography>
        <br/>
        <Typography component="h8" variant="h8">
            {dia_mes}
        </Typography>
        <br/>
        <Typography component="h8" variant="h8">
            {hora}
        </Typography>

      </div>

      </div>

      <div className={backDetails}>
        <CardContent className={classes.content}>
          <Typography component="h8" variant="h8">
            {value.mensagem}
            <br/>
             {/* <text className={classes.textColorCinza} > Enviou: </text> <text> {String(value.enviado)}</text>               */}
             <br/>
          </Typography>
        </CardContent>       
      </div>

      {showMapIcon ? 
        <a className={classes.pos_right}  style={{width: '5%', height: 'auto'}}  href="#" >
          <Image src={require("./img/map_search48.png")} style={{width: '100%', height: 'auto'}} onClick={()=>zoomMapaKseg(MapaKseg, value)}/>
        </a>
      : ""
      }

    </Card>
  );
};
//--------------------
export default function NotificacaoCard(props) {  
  const {value} = props;
  return (  
    <div>
      {cardNotificacao(props)}
    </div>
  );
}

//   SA200STT;155948;1016D;20200417;12:20:12;6da19;-28.408439;-050.860684;000.022;000.00;11;1;13349861;25.36;100000;2;0006;016959;4.0;1
//   SA200UEX;155948;1016D;20200417;12:20:17;6da19;-28.408443;-050.860684;000.022;000.00;11;1;13349863;24.80;100000;24;GTSL|6|1|0|12583014|1|;99;016960;4.0;1