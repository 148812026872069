import  {Component} from 'react';
import api from '../api';

export default class PostGrupoCad extends Component{
    constructor(props, objeto, MapaKseg ){
        super(props);
        this.state = {
             objeto: objeto,
             MapaKseg: MapaKseg
       }
    }
    //------------------------------------------------
    post = async () => {
        let grupo_idd= 0;
        try {        
            let editados = this.state.objeto.state.editados;
            let grupo = this.state.objeto.state.grupo;
            grupo_idd = grupo.grupo_id;

            let response = null;
            let responseJson = null;
            let body =null;            

            if(editados.nome || editados.tipo_grupo || editados.pessoa || editados.ativo){

                body = new FormData();
                body.append('token', localStorage.getItem('token'));
                body.append('grupo_id', grupo.grupo_id);
                if(grupo.usuarios.length > 0) body.append('grupo_id_atual', grupo.usuarios[0].grupo_id);

                if(editados.nome) body.append('nome', grupo.nome);
                if(editados.tipo_grupo) body.append('tipo_grupo_id', grupo.tipo_grupo_id);
                if(editados.pessoa) body.append('pessoa_id', grupo.pessoa_id);
                if(editados.ativo) body.append('ativo', grupo.ativo);

                response = await api.post('/cadastro_api/postGrupo', body);

                if (response.data !== null  &&  response.status !== 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json); 
                    grupo_idd = resposta.grupo_id; 
                }

            }
             
            if(grupo_idd != 0){
                if(editados.usuarios==true ){
                    let usuarios = grupo.usuarios.filter((grupo,index) =>{ return grupo.operacao !== 0;});            

                    if(usuarios.length >0){
                        usuarios.sort((a,b) => a.operacao - b.operacao);
                        let list_json =[];

                        for (var i = 0; i < usuarios.length; i++) {   
                            list_json.push(JSON.stringify({
                                usuario_id: usuarios[i].usuario_id
                                , grupo_id: grupo_idd
                                , operacao: usuarios[i].operacao }));
                        }

                        body = new FormData();
                        body.append('token', localStorage.getItem('token'));
                        body.append('list_json', list_json);

                        response = await api.post('/cadastro_api/postUsuarioGrupos', body);

                        if (response.data !== null  &&  response.status !== 403){
                        responseJson = JSON.stringify(response.data)
                        var req = { json: responseJson };
                        var resposta = JSON.parse(req.json); 
                            // console.log(resposta.poligono_id);
                            // var coleta_id = resposta.coleta_id;    
                            // nova_coleta.coleta_id = resposta.resposta; 
                        // this.state.MapaKseg.toast(" "+String(resposta.resposta ));

                        }
                    }
                } 

                if(editados.veiculos==true ){
                    let veiculos = grupo.veiculos.filter((grupo,index) =>{ return grupo.operacao !== 0;});            

                    if(veiculos.length >0){
                        veiculos.sort((a,b) => a.operacao - b.operacao);
                        let list_json =[];

                        for (var i = 0; i < veiculos.length; i++) {   
                            list_json.push(JSON.stringify({
                                veiculo_id: veiculos[i].veiculo_id
                                , grupo_id: grupo_idd
                                , operacao: veiculos[i].operacao }));
                        }

                        body = new FormData();
                        body.append('token', localStorage.getItem('token'));
                        body.append('list_json', list_json);

                        response = await api.post('/cadastro_api/postGrupoVeiculos', body);

                        if (response.data !== null  &&  response.status !== 403){
                        responseJson = JSON.stringify(response.data)
                        var req = { json: responseJson };
                        var resposta = JSON.parse(req.json); 
                            // console.log(resposta.poligono_id);
                            // var coleta_id = resposta.coleta_id;    
                            // nova_coleta.coleta_id = resposta.resposta; 
                        // this.state.MapaKseg.toast(" "+String(resposta.resposta ));

                        }
                    }
                } 
            }

        } finally {      
            let grupo = this.state.objeto.state.grupo;
            grupo.grupo_id = grupo_idd;
            this.state.objeto.setState({grupo: grupo});
            this.state.objeto.concluido();
            }
    };
}