import  {Component} from 'react';
import api from '../api';

export default class GetGrupos extends Component{
    constructor(props, objeto, MapaKseg, grupo_id){
        super(props);
        this.state = {
             list: [],
             list2:[],
             objeto: objeto,
             MapaKseg: MapaKseg,
             grupo_id: grupo_id
       }
    }
    //------------------------------------------------
    get = async () => {
        try {
            let body = new FormData()
            if(this.state.grupo_id !== 0) body.append('grupo_id', this.state.grupo_id)
            body.append('token', localStorage.getItem('token'))

            const response = await api.post('/cadastro_api/getGrupos', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let obj = null;
                let list = this.state.list; 
                let list2 = this.state.list2;                 
                
                for (var i = 0; i < resposta.length; i++) {                        
                    obj = {    
                        index: i+1,             
                        grupo_id: resposta[i].grupo_id,                        
                        nome: resposta[i].nome,
                        tipo_grupo_id: resposta[i].tipo_grupo_id,
                        tipo_grupo: resposta[i].tipo_grupo,
                        pessoa_id: resposta[i].pessoa_id,
                        nome_social: resposta[i].nome_social,   
                        ativo: resposta[i].ativo,                           
                        operacao:0                     
                    };                   

                    list.push(obj);
                    this.setState({list: list});
                }                
            }
        } finally {        
            this.state.objeto.setList(this.state.list);               
            }
    };
}