import React, {Component} from 'react';
import {Modal, Form, Button as Button_bootstrap, Row, Col} from 'react-bootstrap';
import '../estilo.css';


export  class PesquisarModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true, 
            showSheet:true,
            texto: "",
        };
      }   
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closePesquisarModal();
    };
    //------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarPesquisarModal();
    };
    //--------------------------
    async componentDidMount() {
        this.setState({texto: this.props.texto, tipo: this.props.tipo});
        document.getElementById("ControlTextArea").focus()
    };
    //------------------------
    okClick = ()=>{
        if(this.state.tipo === 'Clientes Coleta' ){
            let COLETA_CLIENTE_TIPO=1;
            this.props.Modals.showGeralList(this.props.objeto, this.state.tipo, COLETA_CLIENTE_TIPO, true, this.state.texto);
            this.fecharModal();    
        }else if(this.state.tipo === 'Clientes Entrega'){
            let ENTREGA_CLIENTE_TIPO=4;
            this.props.Modals.showGeralList(this.props.objeto, this.state.tipo, ENTREGA_CLIENTE_TIPO, true, this.state.texto);
            this.fecharModal();    
        }
    };
    //------------------------
    onKeyUp = (event) => {
        if (event.key === "Enter") {
          this.okClick();
        }
    };
    //-------------------------
    setTexto(event) {
        this.setState({texto: event.target.value});
    }
    //-------------------------  <img src={this.props.comando.icone} /> {this.props.comando.descricao}
    render(){
        return(
            <div >
                <Modal  centered
                    {...this.props}
                                        
                    scrollable="True"
                    dialogClassName="pesquisar-campo-modal"    
                    >
                    <div >
                        <strong className="cursor">
                            <div className="cabecalho"> 
                            <label className="titulo2" > {this.props.titulo} </label>
                                {/* <label className="titulo2" > Aviso! </label> */}
                            </div>
                        </strong>
                            
                        <Modal.Body>           
                            <Form>
                                <Form.Group className="mb-3" controlId="ControlTextArea">
                                    {/* <Form.Label>Observação</Form.Label> */}
                                    <Form.Control placeholder="Nome" as="textarea" style={{ height: 50 }}  
                                     onKeyPress={this.onKeyUp} defaultValue={this.props.texto} rows={3} onChange={this.setTexto.bind(this)} />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        
                    </div>


                    <Modal.Footer>
                        {this.props.cancelar ?
                            <Button_bootstrap className="left" onClick={this.props.onHide}>Cancelar</Button_bootstrap> : ""
                        }
                        <Button_bootstrap onClick={this.okClick}> <img src={"img/lupa.png"} alt="" Width="20" /> </Button_bootstrap>                
                    </Modal.Footer>

                </Modal>

            </div>
        );
    }
}