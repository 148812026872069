import React from 'react';

import 'devextreme/data/odata/store';
import DataGrid, { Column, Scrolling, Selection, MasterDetail } from 'devextreme-react/data-grid';
import './estilo.css';
import ContextMenu from 'devextreme-react/context-menu';
// import notify from 'devextreme/ui/notify';
import {GetComandosVeiculo} from './GetComandosVeiculo';
import GetPoligonos from './GetPoligonos';

export class GridClientes extends React.Component {
  constructor(props) {
    super(props);
    // this.onRowPrepared = this.logEvent.bind(this, 'RowInserting');
    this.onRowPrepared = this.onRowPrepared.bind(this); 

    this.state = {
      countNotificacao: 0,
      props: props,
      GridVeiculoDetail: null,
      items:[],
      load:true,
    }

  // -------------------------------
    this.dataGridRef = React.createRef();
 
    this.selectedRowsData = [];

    this.getSelectedData = () => {
        this.selectedRowsData = this.dataGrid.getSelectedRowsData();

        // ===== or when deferred selection is used =====
        this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            // Your code goes here
        });
    }
    //-----
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onRowExpanding = this.onRowExpanding.bind(this);

  }
//------------------------------------------
  get dataGrid() {
    return this.dataGridRef.current.instance;
  }
//------------------------------------------
  onRowPrepared(e) {  
    // if (e.rowType !== "data")  
    //     return  
    // alert(JSONString);

    if(e.data !== undefined){
      e.rowElement.style.backgroundColor = e.data.cor;
      // if (e.data.velocidade === 0) {  
      //     e.rowElement.style.backgroundColor = "#f26b7d";
      // }else e.rowElement.style.backgroundColor = "#5ed194";

      // if(e.data.diff_minutes>=60)
      //   e.rowElement.style.backgroundColor = "#b3afaf";
    }  

    // if (e.rowType === "data")  
    //   e.data.selectRows([5, 10, 12], true);
     
  } 
  //---------------------------------------------------------------
  addMenuItems(e) {
    if (e.target == 'header') {
        // e.items can be undefined
        if (!e.items) e.items = [];

        // Add a custom menu item
        e.items.push({
            text: 'Log Column Caption',
            onItemClick: () => {
                // alert(e.column.caption);
                // console.log(e.column.caption);
            }
        });
    }
  }
  //-------------------------------------------------------------------
  ItemTemplate (e) {
    return (
      <React.Fragment>
        <span className={ e.icon } />
        { e.items ? <span className="dx-icon-spinright" /> : null }
        { e.text }
      </React.Fragment>
    );
  }
  //---------------------------------------------------------------
  itemClick = (e) => {
    if (!e.itemData.items) {
      // console.log(e);
      // alert(e);
      // notify(`The "${ e.itemData.text }" item was clicked`+" shitt", 'success', 1500);     
      // alert(e.itemData.text);   
      var selectedRowData = this.dataGrid.getSelectedRowsData(); 
      // console.log(selectedRowData);
      // alert(selectedRowData);   
    //   this.props.MapaKseg.setState({veiculoPosicao_selecionado: selectedRowData[0]});

      if(e.itemData.text === this.state.items[0].text){
        this.props.MapaKseg.state.Modals.showCadCliente(selectedRowData[0]);
        // var instance = new GetComandosVeiculo(selectedRowData[0], this.props.MapaKseg); 
        // instance.getComandosVei();   

      }else if(e.itemData.text === this.state.items[1].text){
        this.props.MapaKseg.state.Modals.showCadCliente(selectedRowData[0]);
      }




      // console.log(selectedRowData);
      // alert(selectedRowData[0].recebimento_id);
    //   this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
    //     // Your code goes here
    //     alert("shitt yeah");
    //   });

    }
  }
  //--------------------------------------------------------------
  onSelectionChanged({ selectedRowsData }) {
    let obj = selectedRowsData[0].dados[0];
    // console.log(selectedRowsData);
    this.props.MapaKseg.ajustarZoom(obj);
    this.props.MapaKseg.setState({selectedPolygon: obj});
  }
  //-------------------------------------------------------------
  onRowExpanding= (e) => {
    e.component.selectRows(e.key, false);
    // this.props.MapaKseg.ajustarZoom(selectedRowsData[0]);
  }
  //-----------------------------------------
  getPoligonos = () => {
    var instance = new GetPoligonos(this.props.MapaKseg, 5, "", 5);
    instance.getPoligonos();
  }
  //---------------------------------------------------------------
  componentDidMount() {
    // var instance = new GridVeiculoDetail(this.props, this.props.MapaKseg);
    // this.setState({GridVeiculoDetail: instance});

    // this.props.MapaKseg.toast(" Iniciado GridClientes");

    var items = this.state.items;

    if(this.props.MapaKseg.state.pessoa_id===1)
      items.push({ text: ' Cadastro Cliente', icon: "dx-icon-activefolder"  });
      items.push({ text: ' Novo Cliente', icon: "dx-icon-add"  });

    var instance = new GetPoligonos(this.props.MapaKseg, 5, "", 5);
    instance.getPoligonos();

    this.setState({items: items, load:false});
  };
  //---------------------------------------------------------------
  render() {
    const onContextMenuPreparing = (e) =>{
        try{
          e.component.selectRows(e.row.key, false)
        //   var selectedRowData = this.dataGrid.getSelectedRowsData();
        //   this.props.MapaKseg.ajustarZoom(selectedRowData[0]);

          // console.log(e.row.key);
          // alert(e.row.key);
        }catch (response){}

      // if (e.target !== "content") 
      //     return;
      // // e.items = [];
      // if(e.row){
      //   e.component.selectRows(e.row.key, false)
      //   var selectedRowData = this.dataGrid.getSelectedRowsData();
      //   this.props.MapaKseg.ajustarZoom(selectedRowData[0]);

      //   console.log(e.row.key);
      //   alert(e.row.key);
      // }
    }
    
    return (      
        this.state.load ? "":
          <div>
            <ContextMenu
                dataSource={this.state.items}
                width={180}
                target="#dtGridClientes"
                itemRender={this.ItemTemplate}
                onItemClick={this.itemClick} />
            
            <DataGrid
              id="dtGridClientes"
              height={480}
              dataSource={this.props.MapaKseg.state.poligonos_cliente_list}
              showBorders={true}
              onRowPrepared={this.onRowPrepared}
              onContextMenuPreparing={onContextMenuPreparing}
              ref={this.dataGridRef}
              onSelectionChanged={this.onSelectionChanged}    
            //   onRowExpanding={this.onRowExpanding}    
              >

                <Selection mode="single" />

                
              
            </DataGrid>
          </div>     
    );
  }
}
