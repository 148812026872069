import React, {Component} from 'react';
import {Modal, Image, Spinner, Button, Form, Row,Col,Card,Nav,InputGroup,FormControl} from 'react-bootstrap';
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import GetPessoa from '../services/get_pessoa';
import GetGrupoUsuario from '../services/get_grupo_usuario';
import GetTabUsuario from '../services/get_tab_usuario';
import GetTokensUsuario from '../services/get_tokens_usuario';
import GetUsuarioFiliais from '../services/get_usuario_filiais';
import GetUsuarioRotas from '../services/get_usuario_rotas';
import PostUsuarioCad from '../services/post_usuario_cad';
import {UsuarioTabsGrid} from '../datagrid/usuario_tabs_grid';
import {UsuarioGrupoGrid} from '../datagrid/usuario_grupo_grid';
import {UsuarioAreaGrid} from '../datagrid/usuario_area_grid';
import {UsuarioTokenGrid} from '../datagrid/usuario_token_grid';
import {UsuarioFiliaisGrid} from '../datagrid/usuario_filiais_grid';
import {UsuarioRotasGrid} from '../datagrid/usuario_rotas_grid';
import GetUsuarios from '../services/get_usuarios';
import GetTipoUsuario from '../services/get_tipo_usuario';
import { Switch } from 'devextreme-react/switch';

const PESSOA_TIPO =1;
const TIPO_USUARIO =2;

export class UsuarioCadModal extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            load:true,
            minimizar:false,
            changed:false,
            veiculo:null,
            usuario:null,

            GeralListModal:null,            

            mostrarSenha:false,
            type_senha: "password",

            height_modal:650,
            height_grid:0,
            items:[],
            editados: {},

            salvando:false,
            UsuariosGrid:null
        };

         // -------------------------------
        this.dataGridRef = React.createRef();
    
        this.selectedRowsData = [];

        this.getSelectedData = () => {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData();
            this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            });
        }

        this.onValueChangedCheck = this.onValueChangedCheck.bind(this);

      }
    //---------------------------------------------------------------
    get dataGrid() {
        return this.dataGridRef.current.instance;
    }
    //-------------------------------------------------------------------
    async componentDidMount() {
        // INICIALIZAR VARIÁVEIS

        this.setState({UsuariosGrid: this.props.objeto});

        let editados = {
            nome:false
            , login:false
            , senha:false
            , pessoa:false
            , tipo_usuario: false
            , ativo:false
            , grupos_veiculo: false
            , areas: false 
            , tabs: false
            , filiais:false
            , rotas:false };

        let usuario = this.props.obj_selected;
        usuario.grupos = [];
        usuario.areas = [];
        usuario.tabs = [];
        usuario.tokens = [];
        usuario.filiais = [];
        usuario.rotas = [];

        if(usuario.usuario_id==0){
            this.setState({
                mostrarSenha:true,
                type_senha: "text",
            });

            editados.ativo = true;
        }

        this.setState({
                usuario: usuario       
                ,editados: editados        
                , height_grid: this.state.height_modal-470},
            () => { 
                if(usuario.usuario_id != 0){
                    this.getGrupoUsuario();
                    this.getTabUsuario();
                    this.getTokensUsuario();
                    this.getUsuarioFiliais();
                    this.getUsuarioRotas();
                }
                this.setState({load:false}) }
          )
    }; 
    //----------------------------------------------------------------
    getGrupoUsuario = () =>{
        let tipo_grupo_id = 1;
        var instance = new GetGrupoUsuario(null, this, this.props.MapaKseg
                    , this.state.usuario.usuario_id
                    ,tipo_grupo_id );
        instance.get();   
    }; 
    //-------------------------------------------------------------------
    getTabUsuario = () =>{
        var instance = new GetTabUsuario(null, this, this.props.MapaKseg, this.state.usuario.usuario_id);
        instance.get();   
    }; 
    //-------------------------------------------------------------------
    getUsuarioFiliais = () =>{
        var instance = new GetUsuarioFiliais(null, this, this.props.MapaKseg, this.state.usuario.usuario_id);
        instance.get();   
    };
    //-------------------------------------------------------------------
    getUsuarioRotas = () =>{
        var instance = new GetUsuarioRotas(null, this, this.props.MapaKseg, this.state.usuario.usuario_id);
        instance.get();   
    };
    //--------------------------------------------------------------------
    getUsuario = () => {
        var instance = new GetUsuarios(null, this, this.props.MapaKseg, this.state.usuario.usuario_id );
        instance.get(); 
    } 
    //--------------------------------------------------------------------
    getTokensUsuario = () => {
        var instance = new GetTokensUsuario(null, this, this.props.MapaKseg );
        instance.get(); 
    } 
    //--------------------------------------------------------------------
    setList = (_list) => {
        let usuario = this.state.usuario;
        let usu = _list[0];

        usuario.usuario_id = usu.usuario_id;
        usuario.nome = usu.nome;
        usuario.login = usu.login;
        usuario.senha = usu.senha;
        usuario.pessoa_id = usu.pessoa_id;
        usuario.nome_social = usu.nome_social;
        usuario.ativo = usu.ativo;

        this.setState({usuario: usuario});
    }  
    //------------------------------------------------------------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closeUsuarioCadModal();  
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeUsuarioCadModal();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarUsuarioCadModal();
    };
    //-------------------------------------------------------------
    ativarEditCampo(event) {       
         if(event.target.id==="formSenha"){
            this.setState({disable_senha: false, type_senha:"text" }); 
        }        
    }      
    //------------------------------------------------------
    updateInputValue(event) {
        var editados = this.state.editados;
        var usuario = this.state.usuario;

        if(event.target.id==="formNome"){
            usuario.nome = event.target.value;
            editados.nome = true;
        }else 
         if(event.target.id==="formLogin"){
            usuario.login = String(event.target.value).trim();
            editados.login = true;
         }else 
         if(event.target.id==="formSenha"){
            usuario.senha = String(event.target.value).trim();  
            editados.senha = true;
         }else 
         if(event.target.id==="formPessoa"){
             usuario.pessoa_id = event.target.value;                     
             editados.pessoa = true;
         }else 
         if(event.target.id==="formTipoUsuario"){
             usuario.tipo_usuario_id = event.target.value;                     
             editados.tipo_usuario = true;
         }
        this.setState({usuario: usuario});
        this.setState({editados: editados, changed: true});
     }
     //---------------------------------------------------------------
     onValueChangedCheck(args) {
        var editados = this.state.editados;
        var usuario = this.state.usuario;

        usuario.ativo = args.value;                     
        editados.ativo = true;

        this.setState({usuario: usuario});
        this.setState({editados: editados, changed: true});
      }
    //-------------------------------------------------------------------
    showGeralList= (e) => {   
        if(e.target.getAttribute('value') == PESSOA_TIPO)
            this.props.Modals.showGeralList(this,"Pessoa", PESSOA_TIPO);
        else 
        if(e.target.getAttribute('value') == TIPO_USUARIO)
            this.props.Modals.showGeralList(this,"Tipo Usuário", TIPO_USUARIO);    
    };
    //-----------------------------------------------------
    getList= (geralListModal, tipo) => { 
        this.setState({GeralListModal: geralListModal});  

        if(tipo == PESSOA_TIPO){             
            var instance = new GetPessoa(null, this, this.props.MapaKseg, PESSOA_TIPO, 0);
            instance.get(); 
        }else
        if(tipo == TIPO_USUARIO){             
            var instance = new GetTipoUsuario(null, this, this.props.MapaKseg, TIPO_USUARIO, 0);
            instance.get(); 
        }
    };
    //-------------------------------------------------------------
    setListPessoa =  (_list, tipo) => {  
        let array =[]
        let sel=false;
        for (var i = 0; i < _list.length; i++) { 
            if(this.state.usuario.pessoa_id == _list[i].pessoa_id) sel=true;
            else sel=false;

            array.push({
                 index: _list[i].index
                , id: _list[i].pessoa_id
                , descricao: _list[i].nome_social
                , selecionada: sel
                , index_scroll: 0});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //-------------------------------------------------------------
    setListTipoUsuario =  (_list, tipo) => {  
        let array =[]
        let sel=false;
        for (var i = 0; i < _list.length; i++) { 
            if(this.state.usuario.tipo_usuario_id == _list[i].tipo_usuario_id) sel=true;
            else sel=false;

            array.push({
                 index: _list[i].index
                , id: _list[i].tipo_usuario_id
                , descricao: _list[i].descricao
                , selecionada: sel
                , index_scroll: 0});
        }
        this.state.GeralListModal.setList(array, tipo);
    };     
    //---------------------------------------------------
    setObjeto= (objeto, tipo) => { 
        if(tipo == PESSOA_TIPO){
            let usuario = this.state.usuario;
            usuario.pessoa_id = objeto.id;
            usuario.nome_social = objeto.descricao;            
            this.setState({usuario: usuario});            

            let editados =this.state.editados;
            editados.pessoa =  true;
            this.setState({editados: editados });

        }else
        if(tipo == TIPO_USUARIO){
            let usuario = this.state.usuario;
            usuario.tipo_usuario_id = objeto.id;
            usuario.tipo_usuario = objeto.descricao;            
            this.setState({usuario: usuario});            

            let editados =this.state.editados;
            editados.tipo_usuario =  true;
            this.setState({editados: editados });

        }

        this.setState({changed:true});
    };
    //--------------------------------------
    clickHandler=()=> {
        this.setState(
            {mostrarSenha: !this.state.mostrarSenha},
            () => { this.state.mostrarSenha ? this.setState({type_senha:"text"}) : this.setState({type_senha:"password"})}
          )
    }
    //--------------------------------------
    concluido=()=> {
        this.getUsuario();
        this.getGrupoUsuario();
        this.getTabUsuario();
        this.setState({salvando:false, changed:false });            
        this.state.UsuariosGrid.setUsuario(this.state.usuario);
    }
    //--------------------------------------
    validar=()=> {
        let valido = true;

        if(this.state.usuario.nome.trim()==="" && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Nome!");
        }
       
        if(this.state.usuario.login.trim()==="" && valido){
            valido=false;
            this.props.MapaKseg.toastError("Informe o Login!");
        }

        if(this.state.usuario.senha.trim()==="" && valido){
            valido=false;
            this.props.MapaKseg.toastError("Informe a Senha!");
        }

        if(this.state.usuario.pessoa_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe a Pessoa!");
        }
  
        if(this.state.usuario.tipo_usuario_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Tipo de Usuário!");
        }          
     

        if(valido==true){
            this.setState({salvando:true, changed:true});
            var instance = new PostUsuarioCad(null, this, this.props.MapaKseg);
            instance.post(); 
        }
    }
    //-------------------------
    render(){
        return(
            <div className="drag1">
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={600}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.load ?
                                        <Spinner className="centro3" animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    :
                                        <div style={{height: this.state.height_modal, overflow:'hidden'}}>
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                                                {/*------------------------------------ */}
                                                <Form>                                                       
                                                    <Form.Group className="mb-1" as={Row} controlId="formNome">
                                                        <Form.Label column sm={3}>
                                                            Usuário
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-1" >                                                
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>{this.state.usuario.usuario_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.usuario.nome}  onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>     
                                                    
                                                    <Form.Group className="mb-1" as={Row} controlId="formLogin">
                                                        <Form.Label column sm={3}>
                                                            Login
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-1" >                          
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.usuario.login} onChange={this.updateInputValue.bind(this)}/>                                                                
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group className="mb-1" as={Row} controlId="formSenha">
                                                        <Form.Label column sm={3}>
                                                            senha
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-1" >                                                     
                                                                <FormControl type={this.state.type_senha}  aria-describedby="basic-addon1" value={this.state.usuario.senha} onChange={this.updateInputValue.bind(this)} />                   
                                                                <InputGroup.Append>
                                                                    <Button id="formMostrarSenha" variant="outline-secondary" onClick={this.clickHandler}> {this.state.mostrarSenha ? <Image id="formSenha" src={require('../img/eye48.png')} width='20' /> : <Image id="formSenha" src={require('../img/eye_off48.png')} width='20' /> } </Button>                                                      
                                                                </InputGroup.Append>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group className="mb-1" as={Row} controlId="formPessoa">
                                                        <Form.Label column sm={3}>
                                                            Pessoa
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-1" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formPessoaBt" value={PESSOA_TIPO} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                        <Image value={PESSOA_TIPO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                    </Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text onChange={this.updateInputValue.bind(this)} >{this.state.usuario.pessoa_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control disabled aria-describedby="basic-addon1" value={this.state.usuario.nome_social}  />
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group> 

                                                    <Form.Group className="mb-1" as={Row} controlId="formTipoUsuario">
                                                        <Form.Label column sm={3}>
                                                            Tipo Usuário
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-1" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formTipoUsuarioBt" value={TIPO_USUARIO} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                        <Image value={TIPO_USUARIO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                    </Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text onChange={this.updateInputValue.bind(this)} >{this.state.usuario.tipo_usuario_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control disabled aria-describedby="basic-addon1" value={this.state.usuario.tipo_usuario}  />
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group> 

                                                    <Form.Group className="mb-1" as={Row} controlId="formAtivo">
                                                        <Form.Label column sm={3}>
                                                            Ativo
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <Switch value={this.state.usuario.ativo} onValueChanged={this.onValueChangedCheck} />
                                                            {/* <CheckBox value={this.state.usuario.ativo} onValueChanged={this.onValueChangedCheck}  /> */}
                                                        </Col>
                                                    </Form.Group>
                                        

                                                    {/* ----------------Tab---------------------------------------------------- */}
                                                    <Card>
                                                        <Card.Header>
                                                            <Nav variant="tabs" defaultActiveKey="#Grupos">
                                                                <Nav.Item >
                                                                    <Nav.Link  data-toggle="tab" href="#Grupos">Grupos</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link data-toggle="tab" href="#Filiais">Filiais</Nav.Link>
                                                                </Nav.Item>  
                                                                <Nav.Item>
                                                                    <Nav.Link data-toggle="tab" href="#Rotas">Rotas</Nav.Link>
                                                                </Nav.Item> 
                                                                <Nav.Item>
                                                                    <Nav.Link data-toggle="tab" href="#Tabs">Tabs</Nav.Link>
                                                                </Nav.Item>      
                                                                <Nav.Item>
                                                                    <Nav.Link data-toggle="tab" href="#Tokens">Tokens</Nav.Link>
                                                                </Nav.Item>       
                                                                <Nav.Item>
                                                                    <Nav.Link data-toggle="tab" href="#Areas">Áreas</Nav.Link>
                                                                </Nav.Item>                                                                                                              
                                                            </Nav>
                                                        </Card.Header>

                                                        <div class="tab-content">
                                                            <Card.Body id="Grupos" className="tab-pane active">
                                                                <UsuarioGrupoGrid  
                                                                    objeto={this}
                                                                    height_grid={this.state.height_grid} 
                                                                    MapaKseg={this.props.MapaKseg} 
                                                                    Modals={this.props.Modals}/>                                                                                                                                   
                                                            </Card.Body>

                                                            {/* ------------------------------------ */}
                                                            
                                                            <Card.Body id="Filiais" className="tab-pane fade">
                                                                <UsuarioFiliaisGrid  
                                                                    objeto={this}
                                                                    height_grid={this.state.height_grid} 
                                                                    MapaKseg={this.props.MapaKseg} 
                                                                    Modals={this.props.Modals}/>                                                     
                                                            </Card.Body> 

                                                            {/* ------------------------------------ */}
                                                            
                                                            <Card.Body id="Rotas" className="tab-pane fade">
                                                                <UsuarioRotasGrid  
                                                                    objeto={this}
                                                                    height_grid={this.state.height_grid} 
                                                                    MapaKseg={this.props.MapaKseg} 
                                                                    Modals={this.props.Modals}/>                                                     
                                                            </Card.Body>
                                                            {/* ------------------------------------ */}
                                                                                                                        
                                                            <Card.Body id="Tabs" className="tab-pane fade">
                                                                <UsuarioTabsGrid  
                                                                    objeto={this}
                                                                    height_grid={this.state.height_grid} 
                                                                    MapaKseg={this.props.MapaKseg} 
                                                                    Modals={this.props.Modals}/>                                                     
                                                            </Card.Body>  

                                                            {/* ------------------------------------ */}
                                                            
                                                            <Card.Body id="Tokens" className="tab-pane fade">
                                                                <UsuarioTokenGrid  
                                                                    objeto={this}
                                                                    height_grid={this.state.height_grid} 
                                                                    MapaKseg={this.props.MapaKseg} 
                                                                    Modals={this.props.Modals}/>                                                     
                                                            </Card.Body>  

                                                            {/* ------------------------------------ */}
                                                            
                                                            <Card.Body id="Areas" className="tab-pane fade">
                                                                <UsuarioAreaGrid  
                                                                    objeto={this}
                                                                    height_grid={this.state.height_grid} 
                                                                    MapaKseg={this.props.MapaKseg} 
                                                                    Modals={this.props.Modals}/>                                                               
                                                            </Card.Body>   
                                                                                                                                                                                     

                                                        </div>
                                                    </Card>                                                                                                           

                                                </Form>
                                                {/* ----------------------------------- */}
                                            </CustomScroll>


                                            <Modal.Footer >
                                                <div className="align_bottom_right">
                                                    <Button disabled={!this.state.changed} onClick={this.validar}>
                                                        {this.state.salvando
                                                            ? <Spinner size="sm"  as="span" animation="grow" role="status" aria-hidden="true"/>                                                                
                                                        :
                                                        <div>
                                                            <img src={require('../img/checkbold24.png')}/> Salvar 
                                                        </div>
                                                        }                                                                
                                                    </Button>
                                                </div> 
                                            </Modal.Footer>

                                        </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}