import  {Component} from 'react';
import api from '../api';

export default class PostRotaCad extends Component{
    constructor(props, objeto, MapaKseg ){
        super(props);
        this.state = {
             objeto: objeto,
             MapaKseg: MapaKseg
       }
    }
    //------------------------------------------------
    post = async () => {
        let rota_idd =0;
        try {                    
            let editados = this.state.objeto.state.editados;
            let rota = this.state.objeto.state.rota;
            rota_idd = rota.rota_id;
            let grupo = this.state.objeto.state.grupo;

            let response = null;
            let responseJson = null;
            let body =null;            


            if(editados.descricao || editados.filial ){

                body = new FormData();
                body.append('token', localStorage.getItem('token'));
                body.append('rota_id', rota.rota_id);

                if(editados.descricao) 
                    body.append('descricao', rota.descricao);
                if(editados.filial) 
                    body.append('filial_id', rota.filial_id);     

                response = await api.post('/cadastro_api/postRota', body);

                if (response.data !== null  &&  response.status !== 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json); 
                    rota_idd = resposta.rota_id; 
                }

            }

            if(rota_idd != 0){
                if(editados.usuarios==true ){
                    let usuarios = grupo.usuarios.filter((grupo,index) =>{ return grupo.operacao !== 0;});            

                    if(usuarios.length >0){
                        usuarios.sort((a,b) => a.operacao - b.operacao);
                        let list_json =[];

                        for (var i = 0; i < usuarios.length; i++) {   
                            list_json.push(JSON.stringify({
                                usuario_id: usuarios[i].usuario_id
                                , rota_id: rota_idd
                                , operacao: usuarios[i].operacao }));
                        }

                        body = new FormData();
                        body.append('token', localStorage.getItem('token'));
                        body.append('list_json', list_json);

                        response = await api.post('/cadastro_api/postRotaUsuarios', body);

                        if (response.data !== null  &&  response.status !== 403){
                        responseJson = JSON.stringify(response.data)
                        var req = { json: responseJson };
                        var resposta = JSON.parse(req.json); 
                            // console.log(resposta.poligono_id);
                            // var coleta_id = resposta.coleta_id;    
                            // nova_coleta.coleta_id = resposta.resposta; 
                        // this.state.MapaKseg.toast(" "+String(resposta.resposta ));

                        }
                    }
                } 

                if(editados.municipios==true ){
                    let municipios = rota.municipios.filter((municipio,index) =>{ return municipio.operacao !== 0;});                

                    if(municipios.length >0){
                        municipios.sort((a,b) => a.operacao - b.operacao);
                        let list_json =[];

                        for (var i = 0; i < municipios.length; i++) {   
                            list_json.push(JSON.stringify({
                                municipio_id: municipios[i].municipio_id
                                , rota_id: rota_idd
                                , operacao: municipios[i].operacao }));
                        }

                        body = new FormData();
                        body.append('token', localStorage.getItem('token'));
                        body.append('list_json', list_json);

                        response = await api.post('/cadastro_api/postRotaMunicipios', body);

                        if (response.data !== null  &&  response.status !== 403){
                        responseJson = JSON.stringify(response.data)
                        var req = { json: responseJson };
                        var resposta = JSON.parse(req.json); 
                            // console.log(resposta.poligono_id);
                            // var coleta_id = resposta.coleta_id;    
                            // nova_coleta.coleta_id = resposta.resposta; 
                        // this.state.MapaKseg.toast(" "+String(resposta.resposta ));

                        }
                    }
                }
            }
             

        } finally {      
            let rota = this.state.objeto.state.rota;
            rota.rota_id = rota_idd;
            this.state.objeto.setState({ rota: rota   },
               () => { this.state.objeto.concluido()  });  

            }
    };
}