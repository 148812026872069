import React from "react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import "./imagecropper.css";

class ImageCropper extends React.Component {

    constructor() {
        super();
        this.state = {
            imageDestination: ""
        };
        this.imageElement = React.createRef();
    }

    componentDidMount() {
        this.props.UsuarioModal.setState({ImageCropper:this});
        
        const cropper = new Cropper(this.imageElement.current, {
            zoomable: true,
            aspectRatio: this.props.aspectRatio,     
            // responsive: true,
            // minCropBoxWidth: 100,
            // minCropBoxHeight: 100,
            // viewMode: 2,
            // background: false,
            crop: () => {
                const canvas = cropper.getCroppedCanvas({});
                this.setState({ imageDestination: canvas.toDataURL("image/png") });
            }
        })
     }

    render() {
        return (
            <div class="img-container">
                <img  ref={this.imageElement} src={this.props.src} alt="Source" crossorigin />
            </div>
            /* <img src={this.state.imageDestination} class="img-preview" alt="Destination" /> */
                
        );
    }

}

export default ImageCropper;