import api from './api';

export const isAuthenticated = async () => {
    try {
        let body = new FormData()
        body.append('hash_kseg_web', localStorage.getItem('hash_kseg_web'))
        // console.log("fuuu2")
        const response = await api.post('/login_api/ksegwebConsulta', body);
        var responseJson = null
        if (response.data !== null  &&  response.status !== 403){
            // console.log("fuuu2  response.data != null")
            responseJson = JSON.stringify(response.data)            
            var req = { posicoes_json: responseJson };
            var event = JSON.parse(req.posicoes_json);
            // console.log("Autenticado: "+event.autenticado)
            localStorage.setItem('autenticado', event.autenticado)
            if(event.autenticado===1){
                localStorage.setItem('l', event.login)
                localStorage.setItem('p', event.pass) 
                localStorage.setItem('grupo_id', event.grupo_id)
            }else{
                localStorage.setItem('l', '')
                localStorage.setItem('p', '')
                localStorage.setItem('grupo_id', '')
            }
        }else{
        // this.getPosicoesList();
        }

    } finally {
       
    }
};

// export const isAuthenticated = async () => false;