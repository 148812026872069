import  {Component} from 'react';
import api from '../api';

export default class PostColetaReabrir extends Component{
    constructor(props, objeto, MapaKseg, coleta ){
        super(props);
        this.state = {
             loading:true,
             objeto: objeto,
             coleta: coleta,
             MapaKseg: MapaKseg
       }
    }
    //------------------------------------------------
    post = async () => {
        let coleta_idd= 0;
        try {        
            let coleta = this.state.coleta;
            coleta_idd = coleta.coleta_id;

            let responseJson = null;
            let formData = new FormData()      
            
            formData.append('coleta_id', coleta.coleta_id); 
            formData.append('usuario_cadastro_id', this.state.MapaKseg.state.usuario_id);
            formData.append('token', localStorage.getItem('token'));

            let response = await api.post('/coletaweb_api/postColetaReabrir', formData);
            // let response = await api.post('/teste_api/postColetaReabrir', formData);

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);   
                coleta_idd = resposta.coleta_id;     
            }
        

        } finally {      
            this.state.objeto.reloadGrid(null);
            }
    };
    //--------------------------
}