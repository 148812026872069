import React from 'react';
import DataGrid, { Column, FilterRow, HeaderFilter, SearchPanel, Export, ColumnChooser, MasterDetail } from 'devextreme-react/data-grid';
import { SelectBox, CheckBox, Button } from 'devextreme-react';
import service from './data_datagrid.js';
import { ToastStore} from 'react-toasts';
// import service2 from './data.js';
import { Container, Row,Col } from 'react-bootstrap';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
const dataGridRef = React.createRef();


const saleAmountEditorOptions = { format: 'currency', showClearButton: true };

export default class DtGrid extends React.Component {
  constructor(props) {
    super(props);
    // this.orders = service.getOrders();
    // this.tasks = service2.getTasks();
    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately'
    }, {
      key: 'onClick',
      name: 'On Button Click'
    }];
    this.saleAmountHeaderFilter = [{
      text: 'Less than $3000',
      value: ['SaleAmount', '<', 3000]
    }, {
      text: '$3000 - $5000',
      value: [
        ['SaleAmount', '>=', 3000],
        ['SaleAmount', '<', 5000]
      ]
    }, {
      text: '$5000 - $10000',
      value: [
        ['SaleAmount', '>=', 5000],
        ['SaleAmount', '<', 10000]
      ]
    }, {
      text: '$10000 - $20000',
      value: [
        ['SaleAmount', '>=', 10000],
        ['SaleAmount', '<', 20000]
      ]
    }, {
      text: 'Greater than $20000',
      value: ['SaleAmount', '>=', 20000]
    }];
    this.state = {
      showFilterRow: true,
      showHeaderFilter: true,
      currentFilter: this.applyFilterTypes[0].key,
      focusedRowKey: 1,
      backup:true,
      icone_visible:true,
    };
    this.dataGrid = null;
    this.orderHeaderFilter = this.orderHeaderFilter.bind(this);
    this.onShowFilterRowChanged = this.onShowFilterRowChanged.bind(this);
    this.onShowHeaderFilterChanged = this.onShowHeaderFilterChanged.bind(this);
    this.onCurrentFilterChanged = this.onCurrentFilterChanged.bind(this);
    this.onTaskIdChanged = this.onTaskIdChanged.bind(this);
    this.cloneIconClick = this.cloneIconClick.bind(this);
  }
  //-----------------
  onTaskIdChanged(e) {
    if(e.event && e.value > 0) {
      this.setState({ focusedRowKey: e.value });
    }
  }
  //------------------
  cloneIconClick(e) {
    // var employees = this.state.employees.slice(),
    //   clonedItem = Object.assign({}, e.row.data, { ID: service.getMaxID() });

    // employees.splice(e.row.rowIndex, 0, clonedItem);
    // this.setState({ employees: employees });
    // e.event.preventDefault();


    this.props.RelPosicoesVeiModal.minimizarModal();
    var veiculoPosicao = {latitude: e.row.data.latitude, longitude: e.row.data.longitude
            ,tipo_recebimento_id:1
            ,data_receb: e.row.data.data_receb
            ,velocidade: e.row.data.velocidade};
    this.props.MapaKseg.setInfoWindow(veiculoPosicao);
    this.props.MapaKseg.ajustarZoom(veiculoPosicao);
  }
  //-----------------
  exportGrid=()=> {
    this.setState({icone_visible: !this.state.icone_visible});
    const doc = new jsPDF();
    const dataGrid = dataGridRef.current.instance;
  
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: dataGrid
    }).then(() => {
      doc.save(this.props.placa+'.pdf');
      this.setState({icone_visible: !this.state.icone_visible});
    });    
  }
    //-----------------------
    get gridContainer() {
      return dataGridRef.current.instance;
    }
    //----------------
    exportDataGridToxlsx = () =>  {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
      const context = this;
  
  
      exportDataGrid({
        // component: e.component,
        component: context.gridContainer,
        worksheet: worksheet
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), this.props.placa+'.xlsx');
        });
      });
      // e.cancel = true;
    }
    //-----------------
  verTrechoMapa=()=> {
    var coords = [];
    for (var i = 0; i < this.props.orders.length; i++) {
      coords.push({lat: this.props.orders[i].latitude, lng: this.props.orders[i].longitude});
    }

    // var tipo_chamada=1;  //  TIPO_CHAMADA_TRECHO_POSICOES
    // var instance = new GetEventos(this.props.orders[this.props.orders.length-1]
    //                             , this.props.MapaKseg
    //                             , this.props.orders
    //                             , tipo_chamada
    //                             , this.props.orders[0].veiculo_id);
    // instance.getEventos();

    this.props.MapaKseg.pushTrechoPosicao(coords, this.props.orders);
    this.props.MapaKseg.setState({trecho_evento_list: this.props.trecho_evento_list});
        

    var veiculoPosicao = {latitude: this.props.orders[0].latitude, longitude: this.props.orders[0].longitude
      ,tipo_recebimento_id: this.props.orders[0].tipo_recebimento_id
      ,data_receb: this.props.orders[0].data_receb
      ,velocidade: this.props.orders[0].velocidade};

    this.props.MapaKseg.ajustarZoom(veiculoPosicao);
    this.props.RelPosicoesVeiModal.minimizarModal();
    this.props.MapaKseg.setState({ veiculoPosicao_selecionado: this.props.orders[0]
                      ,bsOverflowHeight: 55});
    
    this.props.MapaKseg.setBottomSheet(this.props.orders[0]);

  }
    //-------------------
    // renderDetail=()=> {
    //   // let { Picture, Notes } = props.data;
    //   let { recebimento_id, Subject } = this.tasks;
    //   return (
    //     <div >
    //       <p >{recebimento_id}</p>
    //       <p >{Subject}</p>
    //     </div>
    //   );
    // }
  //-------------------
  getTasks=(key)=> {
    var dataSource = new DataSource({
      store: new ArrayStore({
        data: this.props.trecho_evento_list,
        key: 'recebimento_id'
      }),
      filter: ['recebimento_id', '=', key]
    });

    return dataSource;
  }
  //-----------------------
  renderDetail=(props)=> {
    // let dataSource = this.getTasks(props.data.key);
    let dataSource = this.getTasks(props.data.recebimento_id);

    if (props.data.eventos_count===0){
      return(<div></div>);
    }

    return (
      <div >
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          columnAutoWidth={true}
          showRowLines={true}
          rowAlternationEnabled={true}
        >
          <Column dataField="icone"
            caption="Ícone"
            width={50}
            allowSorting={false}
            cellRender={cellRender}
            visible={true}
            >          
          </Column>

          <Column dataField="descricao"
            caption="Evento"
            >
            <HeaderFilter allowSearch={true} />
          </Column>  

          <Column dataField="nome"
            caption="Nome"
            />

          <Column dataField="data_inicio"
                alignment="left"
                dataType="date"
                // format="M/d/yyyy, HH:mm"
                caption="Data inicial"
                width={160}
                format= 'MM/dd/yyyy HH:mm'
            />

            <Column dataField="data_final"
                alignment="left"
                dataType="date"
                // format="M/d/yyyy, HH:mm"
                caption="Data final"
                width={160}
                format= 'MM/dd/yyyy HH:mm'
            />

          <Column dataField="dateDiff"
            caption="Duração Evento">
            <HeaderFilter allowSearch={true} />
          </Column> 
        </DataGrid>
      </div>
    );
  }
  //-----------------
  render() {
    return (
      <div className="wrap">       
        <DataGrid id="gridContainer"
          ref={dataGridRef}
          // ref={(ref) => this.dataGrid = ref}
        //   dataSource={this.orders}
          dataSource={this.props.orders}
          showBorders={true}
          focusedRowEnabled={false}
          showRowLines={true}
          rowAlternationEnabled={true}
          keyExpr="recebimento_id"
          
          hoverStateEnabled={true}
          allowColumnResizing={true}
          columnResizingMode={"nextColumn"}
          // focusedRowKey={2737879}
          // focusedRowKey={this.state.focusedRowKey}
          >
            
          <MasterDetail showBorders={false} enabled={true} render={this.renderDetail} />

          <FilterRow visible={this.state.showFilterRow}
            applyFilter={this.state.currentFilter} />

          <HeaderFilter visible={this.state.showHeaderFilter} />

          <SearchPanel visible={false}
            width={240}
            placeholder="Pesquisar..." />

          <ColumnChooser enabled={true} title="Escolha a Coluna"/>

          <Column dataField="eventos_count_icon"
            caption="Eventos"
            width={50}
            allowSorting={false}
            cellRender={cellRender}
            visible={this.state.icone_visible}
            >          
          </Column>

          <Column dataField="eventos_count"            
            caption="Eventos count"
            alignment="left"
            visible={false}>
            <HeaderFilter groupInterval={100} />
          </Column>
          
          <Column dataField="id" caption="ID" alignment="left" width={50} />

          <Column dataField="recebimento_id"            
            caption="Posição id"
            alignment="left"
            visible={false}>
            <HeaderFilter groupInterval={10000} />
          </Column>

          <Column dataField="placa"
            caption={this.getAmount}
            width={80}
            >
            <HeaderFilter allowSearch={true} />
          </Column>

          <Column dataField="municipio"
            caption="Município"
            width={80}
            >
            <HeaderFilter allowSearch={true} />
          </Column>

          <Column dataField="estado"
            caption="Estado"
            alignment="left"
            width={80}
            >
            <HeaderFilter allowSearch={true} />
          </Column>

          <Column dataField="pais"
            caption="País"
            alignment="left"
            width={70}
            >
            <HeaderFilter allowSearch={true} />
          </Column>

          <Column dataField="velocidade"
            caption="Km/h"
            alignment="left"
            format="###"
            width={50}
            >
            <HeaderFilter groupInterval={300} />
          </Column>

          {/* <Column dataField="ignicao" 
            caption="Ignição"
            width={80}>
            <HeaderFilter allowSearch={true} />
          </Column> */}
 
          <Column dataField="data_format"
            alignment="right"
            // dataType="datetime"
            // format="M/d/yyyy, HH:mm"
            caption="Data"
            width={160} />

          <Column dataField="endereco"
            alignment="left"
            caption="Endereço"
            // width={160} 
            />  

          <Column dataField="data_receb"
            alignment="right"
            // dataType="datetime"
            // format="M/d/yyyy, HH:mm"
            caption="data2"
            width={160}
            visible={false} />



          <Column dataField="latlng"
            alignment="right"
            caption="lat, lng"  
            editorOptions={saleAmountEditorOptions}
            visible={false} >
            <HeaderFilter dataSource={this.saleAmountHeaderFilter} />
          </Column>

          <Column type="buttons" width={50}
            caption="Mapa" 
            buttons={['edit', 'delete', {
              hint: 'ver',
              icon: require("./img/location_marker20.png"),
              visible: this.isCloneIconVisible,
              onClick: this.cloneIconClick
            }]} />

          <Column dataField="backup" caption="Backup" width={50}>
            <HeaderFilter allowSearch={true} />
          </Column>
          

        </DataGrid>


        {/* -----------Row---------------- */}
        <Container className="button-container">
          <Row >
            <Col md={2} className={"espaco_right_row_button"}>
              <Button
                id='exportButton'
                icon='exportpdf'
                text='PDF'
                onClick={this.exportGrid} />
            </Col>                        
            {this.props.MapaKseg.state.pessoa_id===1 ?
              <Col md={2} className={"espaco_right_row_button"}>
                <Button 
                  id='exportButton2'
                  icon='exportxlsx'
                  text='xlsx'
                  onClick={this.exportDataGridToxlsx}
                  />
              </Col>  
            :""}
            <Col md={2}  className={"espaco_right_row_button"}>
              <Button 
                id='vertrechomapa'
                icon={require("./img/road3.png")}
                text='Mapa'
                onClick={this.verTrechoMapa} />
            </Col> 

          </Row>          
        </Container>
        
      
      </div>
    );
  }
  calculateFilterExpression(value, selectedFilterOperations, target) {
    let column = this;
    if (target === 'headerFilter' && value === 'weekends') {
      return [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]];
    }
    return column.defaultCalculateFilterExpression.apply(this, arguments);
  }
  orderHeaderFilter(data) {
    data.dataSource.postProcess = (results) => {
      results.push({
        text: 'Weekends',
        value: 'weekends'
      });
      return results;
    };
  }
  onShowFilterRowChanged(e) {
    this.setState({
      showFilterRow: e.value
    });
    this.clearFilter();
  }
  onShowHeaderFilterChanged(e) {
    this.setState({
      showHeaderFilter: e.value
    });
    this.clearFilter();
  }
  onCurrentFilterChanged(e) {
    this.setState({
      currentFilter: e.value
    });
  }
  clearFilter() {
    this.dataGrid.instance.clearFilter();
  }
}

function getOrderDay(rowData) {
  return (new Date(rowData.OrderDate)).getDay();
}


function renderDetail2(props) {
  let { recebimento_id, estado } = props.data;
  return (
    <div >
      <p >{recebimento_id}</p>
      <p >{estado}</p>
    </div>
  );
}

function cellRender(data) {
  if(data.value !== null)
    return <img src={data.value} width="20"  height="20"/>;
  else return <img src={require("./img/transparent.png")} width="20"  height="20"/>;
}


// export default DtGrid;