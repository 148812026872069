import React, {Component, useState} from 'react';
import {Carousel, Modal, Button } from 'react-bootstrap';
import './estilo.css';
import {CabecalhoModal} from './CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import api from './api';
import Resizer from 'react-image-file-resizer';
import ReactRoundedImage from "react-rounded-image";
import CustomScroll from 'react-customscroll';

import ImageCropper from "./imagecropper";

function blobToFile(theBlob, fileName){
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}

export class UsuarioModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            animation: "zoom",
            scrollOffset: 0,
            activeIndexCarousel:0,
            ImageCropper:null,
            render_image: false,
            file:null,
        };
        this.handleChange = this.handleChange.bind(this);
      }
      
    //-------------------
    minimizarModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "slideLeft"});
        this.props.Modals.closeUsuarioModal();

        if(this.props.Modals.state.GridColetaEntrega != null)
          this.props.Modals.state.GridColetaEntrega.visibleFilterrow();
    };
    //---------close----------
    fecharModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "zoom", activeIndexCarousel:0});
        this.props.Modals.closeUsuarioModal();

        if(this.props.Modals.state.GridColetaEntrega != null)
          this.props.Modals.state.GridColetaEntrega.visibleFilterrow();
    };
    //------------------------
    desativarModal = () =>{    
        if(this.props.Modals.usuarioModalShow === false)
            this.props.Modals.usuarioModalDesativar();
    };
    //------------------------------------------
    setCarouselItem = (item) =>{
        this.setState({activeIndexCarousel:item});
    };  
    //------------------------
    async componentDidMount() {
        if(this.props.Modals.state.GridColetaEntrega != null)
          this.props.Modals.state.GridColetaEntrega.visibleFilterrowFalse();
    }
    //-------------------------
    async componentDidUpdate() {
        // this.scrollElement();
        // ToastStore.success("shiitt");
        // if(this.state.scrollOffset===0)
        //     this.setState({scrollOffset: 800, });
    }
    //------------------------
    postImgUsuario = async(img)=>{
        try {
            var pieces = img.split(",");
            img=pieces[1];
            let body = new FormData()
            body.append('usuario_id', this.props.usuario_id)
            body.append('data_str', img)
            body.append('token', localStorage.getItem('token'));
            console.log("Hora da api");
            const response = await api.post('/login_api/postImgUsuario', body);        
            console.log(response);

        }catch (response){}
    }
    //-------------------------
    resFile = async(file)=>{
        const resizeFile = (file) => new Promise(resolve => {
            Resizer.imageFileResizer(file, 600, 600, 'JPEG', 100, 0,
            uri => {
                resolve(uri);
                var myFile = blobToFile(uri, "my_image.png");
                this.setState({file: URL.createObjectURL(myFile)
                                    , render_image:true});
                console.log(uri);
            },
            'blob'
            );
        });

        const image = await resizeFile(file);
        return image;
    }
    //-------------------------
    handleChange (event) {
        if(event.target.files){
            const image = this.resFile(event.target.files[0]);
            // this.setState({
            //     render_image:true
            //     // ,file: URL.createObjectURL(event.target.files[0])
            //     // ,file: URL.createObjectURL(this.state.file2)
            //     // ,file2: event.target.files[0]
            //     });  
        }
    }
    //-------------------------
    onInputClick = ()=>{
        this.setState({file:null, render_image:false});
    }
    //-------------------------
    onSalvarClick = ()=>{
        if(this.state.file){
            var img = this.state.ImageCropper.state.imageDestination;
            this.props.MapaKseg.setState({foto_usuario: img});
            this.setState({activeIndexCarousel:0});
            this.postImgUsuario(img);
            this.state.ImageCropper.setState({imageDestination:""});
            this.setState({file:null, render_image:false});
        }else this.props.MapaKseg.callToast("Escolha o arquivo!");
    }
    //-------------------------
    onVoltarClick = ()=>{
        if(this.state.file){
            this.state.ImageCropper.setState({imageDestination:""});
            this.setState({file:null, render_image:false});
        }
        this.setState({activeIndexCarousel:0});
    }
    //-------------------------
    render(){
        //--------------------
        return(
            <div className="drag1" >
                <Draggable   handle="strong"  >                
                    <div >                    
                        <Rodal 
                            visible={this.props.show}
                            animation={this.state.animation}
                            showCloseButton={false}
                            duration={600}
                            width={540}
                            height={500}
                            onAnimationEnd={()=>this.desativarModal()}
                            >  
                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false} titulo={"Usuário"} Modal={this}/></strong>
                                <div className="padding_modal">
                                                                                                   

                                    <div id="outer2">
                                        <div style={{height: 400, overflow:'hidden'}}>
                                            <CustomScroll  scrollWidth="15px" scrollBarRadius="0">
                                                <Carousel  interval={null}  controls={false} indicators={false} activeIndex={this.state.activeIndexCarousel}>
                                                    <Carousel.Item >                                 
                                                        <button alt="First slide" className="button2"  onClick={()=> this.setCarouselItem(1)} title={"Alterar foto"}>
                                                            <ReactRoundedImage
                                                                    image={this.props.MapaKseg.state.foto_usuario}
                                                                    roundedColor="#8b60db" 
                                                                    imageWidth="150"
                                                                    imageHeight="150"
                                                                    roundedSize="0"
                                                                />
                                                        </button>            
                                                        <br></br>
                                                        <div > 
                                                            <br></br>                                               
                                                            <text className="center6">{this.props.MapaKseg.state.nome}</text>
                                                            <br></br>
                                                            <text className="center6">{localStorage.getItem('login')}</text> 
                                                            <br></br>
                                                            <text className="center6">{localStorage.getItem('nome_social')}</text>
                                                        </div>
                                                    </Carousel.Item>          

                                                    <Carousel.Item>                 
                                                            <input id="fileItem" type="file" accept="image/*" onChange={this.handleChange} onClick={()=> this.onInputClick()}></input>
                                                            {this.state.render_image ?
                                                                <ImageCropper aspectRatio={0} UsuarioModal={this} src={this.state.file}></ImageCropper>
                                                            :""}                      
                                                    </Carousel.Item>   

                                            
                                                </Carousel>         
                                            </CustomScroll>
                                        </div>                                                                               
                                    </div>                                                                                                    
                             

                                    {this.state.activeIndexCarousel===1?
                                        
                                            <Modal.Footer >
                                                <div className="align_bottom_left">
                                                    <Button onClick={this.onVoltarClick}><img src={require('./img/arrow_left24.png')} title={"Voltar"}/></Button>
                                                </div>
                                                <div className="align_bottom_right">
                                                    <Button onClick={this.onSalvarClick}><img src={require('./img/checkbold24.png')}/>Salvar</Button>
                                                </div> 
                                            </Modal.Footer>
                                        
                                    :""}
                                </div>
                                    
                                                           
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>
        
        );
    }

    
}