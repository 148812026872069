import React, {Component} from 'react';
import {Modal, Image, Spinner, Button, Form, Row,Col,Card,Nav,InputGroup,FormControl} from 'react-bootstrap';
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import GetMunicipio from '../services/get_municipio';
import GetLocais from '../services/get_locais';
import GetEstados from '../services/get_estados';
import PostMunicipioCad from '../services/post_municipio_cad';
import { dinheiroMask } from '../dinheiro_mask'


const ESTADO =1;
const LOCAL =2;

export class MunicipioCadModal extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            load:true,
            minimizar:false,
            changed:false,
            municipio:null,

            GeralListModal:null,            
            MunicipioGrid:null,

            height_modal:330,
            height_grid:0,
            items:[],
            editados: {},

            salvando:false,            
        };

         // -------------------------------
        this.dataGridRef = React.createRef();
    
        this.selectedRowsData = [];

        this.getSelectedData = () => {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData();
            this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            });
        }
      }
    //---------------------------------------------------------------
    get dataGrid() {
        return this.dataGridRef.current.instance;
    }
    //-------------------------------------------------------------------
    async componentDidMount() {
        // INICIALIZAR VARIÁVEIS

        this.setState({MunicipioGrid: this.props.objeto});


        let editados = {
              descricao:false
            , codigo:false
            , estado:false
            , local: false
            , tda: false
             };

        let municipio = this.props.obj_selected;

        this.setState({
            municipio: municipio       
                ,editados: editados        
                , height_grid: this.state.height_modal-420},
            () => { 
                if(municipio.municipio_id != 0){
                    // this.getUsuariosGrupo()
                    // this.getTokensUsuario()
                }
                this.setState({load:false}) }
          )
    }; 
    //----------------------------------------------------------------
    getMunicipio = () =>{
        var instance = new GetMunicipio(null, this, this.props.MapaKseg, 0, this.state.municipio.municipio_id );
        instance.get();   
    }; 
    //----------------------------------------------------------------
    getEstado = () =>{
        // var instance = new GetPessoa(null, this, this.props.MapaKseg,0, this.state.municipio.municipio_id );
        // instance.get();   
    };  
    //----------------------------------------------------------------
    getLocal = () =>{
        var instance = new GetLocais(null, this, this.props.MapaKseg, 0, this.state.local.local_id );
        instance.get();   
    }; 
    //--------------------------------------------------------------------
    setListMunicipio = (_list) => {
        let municipio = this.state.municipio;
        let obj = _list[0];

        municipio.municipio_id = obj.municipio_id;
        municipio.descricao = obj.descricao;
        municipio.codigo = obj.codigo;
        municipio.estado_id = obj.estado_id;
        municipio.estado = obj.estado;
        municipio.local_id = obj.local_id;
        municipio.local = obj.local;

        this.setState({municipio: municipio});
    } 
    //------------------------------------------------------------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closeMunicipioCadModal();  
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeMunicipioCadModal();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarMunicipioCadModal();
    };   
    //------------------------------------------------------
    updateInputValue(event) {
        var editados = this.state.editados;
        var municipio = this.state.municipio;

        if(event.target.id==="formDescricao"){
            municipio.descricao = event.target.value;
            editados.descricao = true;
        }else 
        if(event.target.id==="formCodigo"){
            municipio.codigo = String(event.target.value).trim();
            editados.codigo = true;
        }else 
        if(event.target.id==="formEstado"){
            municipio.estado_id = event.target.value;
            editados.estado = true;
        }else 
        if(event.target.id==="formLocal"){
            municipio.local_id = event.target.value;
            editados.local = true;
        }

        this.setState({municipio: municipio});
        this.setState({editados: editados, changed: true});
     }
         //-------------------------------------------------------------------
    showGeralList= (e) => {   
        if(e.target.getAttribute('value') == ESTADO)
            this.props.Modals.showGeralList(this,"Estado", ESTADO);   
        else
         if(e.target.getAttribute('value') == LOCAL)
            this.props.Modals.showGeralList(this,"Local", LOCAL);        
    };
    //-----------------------------------------------------
    getList= (geralListModal, tipo) => { 
        this.setState({GeralListModal: geralListModal});  

        if(tipo == ESTADO){             
            var instance = new GetEstados(null, this, this.props.MapaKseg, ESTADO, 0);
            instance.get(); 
        }else
        if(tipo == LOCAL){             
            var instance = new GetLocais(null, this, this.props.MapaKseg, LOCAL, 0);
            instance.get(); 
        }
    };
    //-------------------------------------------------------------
    setListEstado =  (_list, tipo) => {  
        let array =[]
        let sel=false;
        for (var i = 0; i < _list.length; i++) { 
            if(this.state.municipio.estado_id == _list[i].estado_id) sel=true;
            else sel=false;

            array.push({
                 index: _list[i].index
                , id: _list[i].estado_id
                , descricao: _list[i].descricao
                , selecionada: sel
                , index_scroll: 0});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //-------------------------------------------------------------
    setListLocal =  (_list, tipo) => {  
        let array =[]
        let sel=false;
        for (var i = 0; i < _list.length; i++) { 
            if(this.state.municipio.local_id == _list[i].local_id) sel=true;
            else sel=false;

            array.push({
                index: _list[i].index
                ,id: _list[i].local_id
                , descricao: _list[i].descricao
                , selecionada: sel
                , index_scroll: 0});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //---------------------------------------------------
    setObjeto= (objeto, tipo) => { 
        if(tipo == ESTADO){
            let municipio = this.state.municipio;
            municipio.estado_id = objeto.id;
            municipio.estado = objeto.descricao;            
            this.setState({municipio: municipio});            

            let editados =this.state.editados;
            editados.estado =  true;
            this.setState({editados: editados });
        }else
        if(tipo == LOCAL){
            let municipio = this.state.municipio;
            municipio.local_id = objeto.id;
            municipio.local = objeto.descricao;            
            this.setState({municipio: municipio});            

            let editados =this.state.editados;
            editados.local =  true;
            this.setState({editados: editados });
        }

        this.setState({changed:true});
    };
    //---------------------------------------------
    setTDA(event) {
        let municipio = this.state.municipio;
        municipio.tda =  dinheiroMask(event.target.value);            
        this.setState({municipio: municipio});

        let editados = this.state.editados;
        editados.tda = true;
        this.setState({editados: editados, changed: true});
    }
    //--------------------------------------
    concluido=()=> {
        this.getMunicipio();
        this.setState({salvando:false, changed:false });     
        this.state.MunicipioGrid.setMunicipio(this.state.municipio);  
    }
    //--------------------------------------
    validar=()=> {
        let valido = true;

        if(String(this.state.municipio.descricao).trim()==="" && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Município!");
        }

        if(String(this.state.municipio.codigo).trim()==="" && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Código!");
        }  
        
        if(this.state.municipio.estado_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Estado!");
        }   
        
        if(this.state.municipio.local_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Local!");
        } 

        if(this.state.municipio.pessoa_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe a Pessoa!");
        }    
    
        if(valido==true){
            this.setState({salvando:true, changed:true});
            var instance = new PostMunicipioCad(null, this, this.props.MapaKseg);
            instance.post(); 
        }
    }
    //-------------------------
    render(){
        return(
            <div className="drag1">
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={600}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.load ?
                                        <Spinner className="centro3" animation="border" role="status">
                                            <span className="sr-only"></span>
                                        </Spinner>
                                    :
                                        <div style={{height: this.state.height_modal, overflow:'hidden'}}>
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                                                {/*------------------------------------ */}
                                                <Form>                                                       
                                                    <Form.Group className="mb-1" as={Row} controlId="formDescricao">
                                                        <Form.Label column sm={3}>
                                                            Município
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >            
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>{this.state.municipio.municipio_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.municipio.descricao}  onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>        

                                                     <Form.Group className="mb-1" as={Row} controlId="formCodigo">
                                                        <Form.Label column sm={3}>
                                                            Código
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >                                                                                                        
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.municipio.codigo}  onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>         

                                                    <Form.Group className="mb-1" as={Row} controlId="formEstado">
                                                        <Form.Label column sm={3}>
                                                        Estado
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formEstadoBt" value={ESTADO} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                        <Image value={ESTADO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                    </Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text onChange={this.updateInputValue.bind(this)} >{this.state.municipio.estado_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control disabled aria-describedby="basic-addon1" value={this.state.municipio.estado}  />
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>   

                                                    <Form.Group className="mb-1" as={Row} controlId="formLocal">
                                                        <Form.Label column sm={3}>
                                                        Local
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formLocalBt" value={LOCAL} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                        <Image value={LOCAL} src={require('../img/text_box_search48.png')} width='20'/>
                                                                    </Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text onChange={this.updateInputValue.bind(this)} >{this.state.municipio.local_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control disabled aria-describedby="basic-addon1" value={this.state.municipio.local}  />
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>    

                                                    <Form.Group className="mb-1" as={Row} controlId="formTDA">
                                                        <Form.Label column sm={3}>
                                                            TDA
                                                        </Form.Label>
                                                        <Col sm={3}>
                                                            <InputGroup className="mb-3" >                                                                                                        
                                                            <FormControl type="text" disabled={this.state.disable_valor}  aria-describedby="basic-addon1" value={this.state.municipio.tda} defaultValue={0} onChange={this.setTDA.bind(this)} />
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>                                                                                                                                                        
                                                                                                                                                    

                                                </Form>
                                                {/* ----------------------------------- */}
                                            </CustomScroll>


                                            <Modal.Footer >
                                                <div className="align_bottom_right">
                                                    <Button disabled={!this.state.changed} onClick={this.validar}>
                                                        {this.state.salvando
                                                            ? <Spinner size="sm"  as="span" animation="grow" role="status" aria-hidden="true"/>                                                                
                                                        :
                                                        <div>
                                                            <img src={require('../img/checkbold24.png')}/> Salvar 
                                                        </div>
                                                        }                                                                
                                                    </Button>
                                                </div> 
                                            </Modal.Footer>

                                        </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}