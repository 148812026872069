import React, {Component} from 'react';
import {Modal, Image, Spinner, Button, Form, Row,Col,Card,Nav,InputGroup,FormControl} from 'react-bootstrap';
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import GetPessoa from '../services/get_pessoa';
import GetUsuariosGrupo from '../services/get_usuarios_grupo';
import GetGrupoVeiculos from '../services/get_grupo_veiculos';
import GetTipoGrupo from '../services/get_tipo_grupo';
import GetGrupos from '../services/get_grupos';
import PostGrupoCad from '../services/post_grupo_cad';
import {UsuariosGrupoGrid} from '../datagrid/usuarios_grupo_grid';
import { Switch } from 'devextreme-react/switch';
import {GrupoVeiculosGrid} from '../datagrid/grupo_veiculos_grid';

const PESSOA_TIPO =1;
const TIPO_GRUPO =2;

export class GrupoCadModal extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            load:true,
            minimizar:false,
            changed:false,
            veiculo:null,
            grupo:null,

            GeralListModal:null,            
            GruposGrid:null,

            disable_nome: true,
            disable_login:true,

            height_modal:610,
            height_grid:0,
            items:[],
            editados: {},

            salvando:false,            
        };

         // -------------------------------
        this.dataGridRef = React.createRef();
    
        this.selectedRowsData = [];

        this.getSelectedData = () => {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData();
            this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            });
        }

        this.onValueChangedCheck = this.onValueChangedCheck.bind(this);

      }
    //---------------------------------------------------------------
    get dataGrid() {
        return this.dataGridRef.current.instance;
    }
    //-------------------------------------------------------------------
    async componentDidMount() {
        // INICIALIZAR VARIÁVEIS

        this.setState({GruposGrid: this.props.objeto});

        let editados = {
              nome:false
            , tipo_grupo:false
            , pessoa:false
            , ativo:false
            , usuarios: false
            , veiculos: false };

        let grupo = this.props.obj_selected;
        grupo.usuarios = [];
        grupo.veiculos = [];


        if(grupo.grupo_id==0){
            this.setState({
                disable_nome: false,
            });

            editados.ativo = true;
        }

        this.setState({
                grupo: grupo       
                ,editados: editados        
                , height_grid: this.state.height_modal-420},
            () => { 
                if(grupo.grupo_id != 0){
                    this.getUsuariosGrupo()
                    this.getGrupoVeiculos()
                    // this.getTokensUsuario()
                }
                this.setState({load:false}) }
          )
    }; 
    //----------------------------------------------------------------
    getUsuariosGrupo = () =>{
        var instance = new GetUsuariosGrupo(null, this, this.props.MapaKseg, this.state.grupo.grupo_id );
        instance.get();   
    }; 
    //----------------------------------------------------------------
    getGrupoVeiculos = () =>{
        var instance = new GetGrupoVeiculos(null, this, this.props.MapaKseg, this.state.grupo.grupo_id );
        instance.get();   
    }; 
    //--------------------------------------------------------------------
    getGrupo = () => {
        var instance = new GetGrupos(null, this, this.props.MapaKseg, this.state.grupo.grupo_id);
        instance.get(); 
    }  
    //--------------------------------------------------------------------
    setGrupos = (_list) => {
        let grupo = this.state.grupo;
        let usu = _list[0];

        grupo.grupo_id = usu.grupo_id;
        grupo.nome = usu.nome;
        grupo.login = usu.login;
        grupo.pessoa_id = usu.pessoa_id;
        grupo.nome_social = usu.nome_social;
        grupo.ativo = usu.ativo;

        this.setState({grupo: grupo});
    }  
    //------------------------------------------------------------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closeGrupoCadModal();  
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeGrupoCadModal();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarGrupoCadModal();
    };
    //-------------------------------------------------------------
    ativarEditCampo(event) {       
        if(event.target.id==="formNome"){
            this.setState({disable_nome: false}); 
        } 
    }      
    //------------------------------------------------------
    updateInputValue(event) {
        var editados = this.state.editados;
        var grupo = this.state.grupo;

        if(event.target.id==="formNome"){
            grupo.nome = event.target.value;
            editados.nome = true;
        }else 
         if(event.target.id==="formTipoGrupo"){
            grupo.tipo_grupo_id = event.target.value;
            editados.tipo_grupo = true;
         }else 
         if(event.target.id==="formPessoa"){
            grupo.pessoa_id = event.target.value;                     
             editados.pessoa = true;
         }else 
         if(event.target.id==="formAtivo"){
            grupo.ativo = event.target.value;                     
             editados.ativo = true;
         }

        this.setState({grupo: grupo});
        this.setState({editados: editados, changed: true});
     }
     //---------------------------------------------------------------
     onValueChangedCheck(args) {
        var editados = this.state.editados;
        var grupo = this.state.grupo;

        grupo.ativo = args.value;                     
        editados.ativo = true;

        this.setState({grupo: grupo});
        this.setState({editados: editados, changed: true});
      }
    //-------------------------------------------------------------------
    showGeralList= (e) => {   
        if(e.target.getAttribute('value') == PESSOA_TIPO)
            this.props.Modals.showGeralList(this,"Pessoa", PESSOA_TIPO);
        else
        if(e.target.getAttribute('value') == TIPO_GRUPO)
            this.props.Modals.showGeralList(this,"Tipo Grupo", TIPO_GRUPO);    
    };
    //-----------------------------------------------------
    getList= (geralListModal, tipo) => { 
        this.setState({GeralListModal: geralListModal});  

        if(tipo == PESSOA_TIPO){             
            var instance = new GetPessoa(null, this, this.props.MapaKseg, PESSOA_TIPO, 0);
            instance.get(); 
        }else
        if(tipo == TIPO_GRUPO){             
            var instance = new GetTipoGrupo(null, this, this.props.MapaKseg, TIPO_GRUPO);
            instance.get(); 
        }
    };
    //-------------------------------------------------------------
    setListPessoa =  (_list, tipo) => {  
        let array =[]
        let sel=false;
        for (var i = 0; i < _list.length; i++) { 
            if(this.state.grupo.pessoa_id == _list[i].pessoa_id) sel=true;
            else sel=false;

            array.push({
                index: _list[i].index
                , id: _list[i].pessoa_id
                , descricao: _list[i].nome_social
                , selecionada: sel
                , index_scroll: 0});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //-------------------------------------------------------------
    setListTipoGrupo =  (_list, tipo) => {  
        let array =[]
        let sel=false;
        for (var i = 0; i < _list.length; i++) { 
            if(this.state.grupo.tipo_grupo_id == _list[i].tipo_grupo_id) sel=true;
            else sel=false;

            array.push({
                index: _list[i].index
                , id: _list[i].tipo_grupo_id
                , descricao: _list[i].descricao
                , selecionada: sel
                , index_scroll: 0});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //---------------------------------------------------
    setObjeto= (objeto, tipo) => { 
        if(tipo == PESSOA_TIPO){
            let grupo = this.state.grupo;
            grupo.pessoa_id = objeto.id;
            grupo.nome_social = objeto.descricao;            
            this.setState({grupo: grupo});            

            let editados =this.state.editados;
            editados.pessoa =  true;
            this.setState({editados: editados });

        }else
         if(tipo == TIPO_GRUPO){
            let grupo = this.state.grupo;
            grupo.tipo_grupo_id = objeto.id;
            grupo.tipo_grupo = objeto.descricao;            
            this.setState({grupo: grupo});            

            let editados =this.state.editados;
            editados.tipo_grupo = true;
            this.setState({editados: editados });

        }

        this.setState({changed:true});
    };
    //-------------------------------------------------------------
    setListUsuarios =  (_list) => {  
        let grupo = this.state.grupo;
        grupo.usuarios = _list;
        this.setState({grupo:grupo});
    }; 
    //-------------------------------------------------------------
    setListVeiculos =  (_list) => {  
        let grupo = this.state.grupo;
        grupo.veiculos = _list;
        this.setState({grupo:grupo});
    }; 
    //--------------------------------------------------------------------
    setList = (_list) => {
        let grupo = this.state.grupo;
        let obj = _list[0];

        grupo.grupo_id = obj.grupo_id;
        grupo.nome = obj.nome;
        grupo.tipo_grupo_id = obj.tipo_grupo_id;
        grupo.pessoa_id = obj.pessoa_id;
        grupo.ativo = obj.ativo;

        this.setState({grupo: grupo});
    } 
    //--------------------------------------
    concluido=()=> {
        this.getGrupo();
        this.getGrupoVeiculos();
        this.getUsuariosGrupo();        
        this.setState({salvando:false, changed:false });            
        this.state.GruposGrid.setGrupo(this.state.grupo);
    }
    //--------------------------------------
    validar=()=> {
        let valido = true;

        if(this.state.grupo.nome.trim()==="" && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Nome!");
        }

        if(this.state.grupo.tipo_grupo_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Tipo Grupo!");
        }

        if(this.state.grupo.pessoa_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe a Pessoa!");
        }           

        if(valido==true){
            this.setState({salvando:true, changed:true});
            var instance = new PostGrupoCad(null, this, this.props.MapaKseg);
            instance.post(); 
        }
    }
    //-------------------------
    render(){
        return(
            <div className="drag1">
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={600}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.load ?
                                        <Spinner className="centro3" animation="border" role="status">
                                            <span className="sr-only"></span>
                                        </Spinner>
                                    :
                                        <div style={{height: this.state.height_modal, overflow:'hidden'}}>
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                                                {/*------------------------------------ */}
                                                <Form>                                                       
                                                    <Form.Group className="mb-1" as={Row} controlId="formNome">
                                                        <Form.Label column sm={3}>
                                                            Grupo
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formNome" variant="outline-secondary" onClick={this.ativarEditCampo.bind(this)}><Image id="formNome" src={require('../img/pencil_48.png')} width='20'/></Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>{this.state.grupo.grupo_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.grupo.nome} disabled={this.state.disable_nome} onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>                                 

                                                    <Form.Group className="mb-1" as={Row} controlId="formTipoGrupo">
                                                        <Form.Label column sm={3}>
                                                        Tipo Grupo
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formTipoGrupo" value={TIPO_GRUPO} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                        <Image value={TIPO_GRUPO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                    </Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text onChange={this.updateInputValue.bind(this)} >{this.state.grupo.tipo_grupo_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control disabled aria-describedby="basic-addon1" value={this.state.grupo.tipo_grupo}  />
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group> 

                                                    <Form.Group className="mb-1" as={Row} controlId="formPessoa">
                                                        <Form.Label column sm={3}>
                                                        Pessoa
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formPessoaBt" value={PESSOA_TIPO} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                        <Image value={PESSOA_TIPO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                    </Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text onChange={this.updateInputValue.bind(this)} >{this.state.grupo.pessoa_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control disabled aria-describedby="basic-addon1" value={this.state.grupo.nome_social}  />
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group> 

                                                    <Form.Group className="mb-1" as={Row} controlId="formAtivo">
                                                        <Form.Label column sm={3}>
                                                            Ativo
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <Switch value={this.state.grupo.ativo} onValueChanged={this.onValueChangedCheck} />
                                                            {/* <CheckBox value={this.state.grupo.ativo} onValueChanged={this.onValueChangedCheck}  /> */}
                                                        </Col>
                                                    </Form.Group>
                                        

                                                    {/* ----------------Tab---------------------------------------------------- */}
                                                    <Card>
                                                        <Card.Header>
                                                            <Nav variant="tabs" defaultActiveKey="#Veiculos">
                                                                <Nav.Item >
                                                                    <Nav.Link  data-toggle="tab" href="#Veiculos">Veículos</Nav.Link>
                                                                </Nav.Item>   
                                                                <Nav.Item >
                                                                    <Nav.Link  data-toggle="tab" href="#Usuarios">Usuários</Nav.Link>
                                                                </Nav.Item>                                                                                                                                            
                                                            </Nav>
                                                        </Card.Header>

                                                        <div class="tab-content">
                                                            <Card.Body id="Veiculos" className="tab-pane active">
                                                                <GrupoVeiculosGrid  
                                                                    objeto={this}
                                                                    height_grid={this.state.height_grid} 
                                                                    MapaKseg={this.props.MapaKseg} 
                                                                    Modals={this.props.Modals}/>                                                                                                                                   
                                                            </Card.Body>
                                                            {/* ------------------------------------ */}
                                                            <Card.Body id="Usuarios" className="tab-pane fade">
                                                                <UsuariosGrupoGrid  
                                                                    objeto={this}
                                                                    height_grid={this.state.height_grid} 
                                                                    MapaKseg={this.props.MapaKseg} 
                                                                    Modals={this.props.Modals}/>                                                                                                                                   
                                                            </Card.Body>                                                                                                                                                                          
                                                        </div>
                                                    </Card>                                                                                                           

                                                </Form>
                                                {/* ----------------------------------- */}
                                            </CustomScroll>


                                            <Modal.Footer >
                                                <div className="align_bottom_right">
                                                    <Button disabled={!this.state.changed} onClick={this.validar}>
                                                        {this.state.salvando
                                                            ? <Spinner size="sm"  as="span" animation="grow" role="status" aria-hidden="true"/>                                                                
                                                        :
                                                        <div>
                                                            <img src={require('../img/checkbold24.png')}/> Salvar 
                                                        </div>
                                                        }                                                                
                                                    </Button>
                                                </div> 
                                            </Modal.Footer>

                                        </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}