import  {Component} from 'react';
import api from '../api';

export default class PostLocalCad extends Component{
    constructor(props, objeto, MapaKseg ){
        super(props);
        this.state = {
             objeto: objeto,
             MapaKseg: MapaKseg
       }
    }
    //------------------------------------------------
    post = async () => {
        let local_idd =0;
        try {                    
            let editados = this.state.objeto.state.editados;
            let local = this.state.objeto.state.local;
            local_idd = local.local_id;

            let response = null;
            let responseJson = null;
            let body =null;            


            if(editados.descricao || editados.latitude || editados.longitude || editados.pessoa){

                body = new FormData();
                body.append('token', localStorage.getItem('token'));
                body.append('local_id', local.local_id);

                if(editados.descricao) 
                    body.append('descricao', local.descricao);
                if(editados.latitude) 
                    body.append('latitude', local.latitude);
                if(editados.longitude) 
                    body.append('longitude', local.longitude);
                if(editados.pessoa) 
                    body.append('pessoa_id', local.pessoa_id);         

                response = await api.post('/cadastro_api/postLocal', body);

                if (response.data !== null  &&  response.status !== 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json); 
                    local_idd = resposta.local_id; 
                }

            }
             

        } finally {      
            let local = this.state.objeto.state.local;
            local.local_id = local_idd;
            this.state.objeto.setState({ local: local   },
               () => { this.state.objeto.concluido()  });  

            }
    };
}