import React from 'react';

import 'devextreme/data/odata/store';
import DataGrid, { Column, Scrolling, Selection, MasterDetail, HeaderFilter, FilterPanel, FilterRow  } from 'devextreme-react/data-grid';
import {Nav, Navbar, Form,Card, Spinner, NavDropdown, FormControl, Button, Container} from 'react-bootstrap';
import '../estilo.css';
import ContextMenu from 'devextreme-react/context-menu';
import SelectBox from 'devextreme-react/select-box';
import GetUsuarios from '../services/get_usuarios';

import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

export class UsuariosGrid extends React.Component {
  constructor(props) {
    super(props);
    // this.onRowPrepared = this.logEvent.bind(this, 'RowInserting');
    this.onRowPrepared = this.onRowPrepared.bind(this); 

    this.state = {
      countNotificacao: 0,
      props: props,
      list:[],
      // height_grid:1500,

      bloqueado_consultar:false,
      load:true,
      items:[]
    }

  // -------------------------------
    this.dataGridRef = React.createRef();
 
    this.selectedRowsData = [];

    this.getSelectedData = () => {
        this.selectedRowsData = this.dataGrid.getSelectedRowsData();
        this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
        });
    }
    //-----
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onRowExpanding = this.onRowExpanding.bind(this);

  }
//------------------------------------------
  get dataGrid() {
    return this.dataGridRef.current.instance;
  }
//------------------------------------------
  onRowPrepared(e) {  
    if(e.data !== undefined){
      if (e.data.operacao === 0 ){
          if(e.data.index %2 === 0)
              e.rowElement.style.backgroundColor = "#F5F5F5";
          else e.rowElement.style.backgroundColor = "#FFFFFF";                

      }else if (e.data.operacao === 1 || e.data.operacao === 3)
              e.rowElement.style.backgroundColor = "#92d1b0";
      else if(e.data.operacao===2)
          e.rowElement.style.backgroundColor = "#f593a0";     
   }       
  } 
  //-------------------------------------------------------------------
  ItemTemplate (e) {
    return (
      <React.Fragment>
        <span className={ e.icon } />
        { e.items ? <span className="dx-icon-spinright" /> : null }
        { e.text }
      </React.Fragment>
    );
  }
  //---------------------------------------------------------------
  itemClick = (e) => {
    if (!e.itemData.items) {
      let selectedRowData = this.dataGrid.getSelectedRowsData(); 
      // alert(e.itemData.text+"  "+selectedRowData[0].nome);

      if(e.itemData.text === this.state.items[0].text){
        let usuario = {usuario_id:0, nome:'', login:'', senha:'', pessoa_id:0, ativo:true, tipo_usuario_id:0, tipo_usuario:""}; // Novo usuário
        this.props.MapaKseg.state.Modals.showUsuarioCadModal(this, usuario);
      }else if(e.itemData.text === this.state.items[1].text){
        if(this.state.list.length > 0)
          this.props.MapaKseg.state.Modals.showUsuarioCadModal(this, selectedRowData[0]);
      }else if(e.itemData.text === ' Excel Exportar'){  
        this.exportDataGridToxlsx("Usuários");        
      }
    }
  }
  //------------------------------------------------------------------
  exportDataGridToxlsx = (nome) =>  {
    let today = new Date();
    let time = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
    let date = today.getDate() + "-" + (today.getMonth() + 1) + '-' + today.getFullYear() + '  '+time;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    let compo = this.dataGridRef.current.instance;
    let nomeArquivo = nome+date+".xlsx";

    exportDataGrid({
        // component: e.component,
        component: compo,
        worksheet: worksheet
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), nomeArquivo);
         });
    });
  
  }  
  //---------------------------------------------------------------
  setUsuario = (usuario) => {
    let list = this.state.list;
    let encontrou =false;

    for (var i = 0; i < list.length; i++) {    
      if(list[i].usuario_id == usuario.usuario_id){
        encontrou=true;
        list[i].usuario_id = usuario.usuario_id;
        list[i].nome = usuario.nome;
        list[i].login = usuario.login;
        list[i].senha = usuario.senha;
        list[i].pessoa_id = usuario.pessoa_id;
        list[i].nome_social = usuario.nome_social;
        list[i].ativo = usuario.ativo;
        list[i].operacao = 1;
        break;
      }
    }

    if(encontrou == false){
      let list_aux = [];
      usuario.operacao = 1;
      list_aux.push(usuario);
      list_aux = list_aux.concat(list);
      this.setState({list: list_aux});
    }else{
      list = list.concat(); // para não precisar dar reload no grid
      this.setState({list: list});
    }

  }
  //--------------------------------------------------------------
  onSelectionChanged({ selectedRowsData }) {
    // this.props.MapaKseg.ajustarZoom(selectedRowsData[0]);
  }
  //-------------------------------------------------------------
  onRowExpanding= (e) => {
    e.component.selectRows(e.key, false);
  }
  //-----------------------------------------
  componentDidMount() {  
    let items = this.state.items;
    items.push({ text: ' Novo', icon: "dx-icon-add"  });
    items.push({ text: ' Cadastro', icon: "dx-icon-activefolder"  });
    items.push({ text: ' Excel Exportar', icon: "dx-icon-exportxlsx"  });
    this.setState({items: items, load:false});
  };
  //--------------------------------------------------------------------
  getApi = () => {
    this.setState({bloqueado_consultar: true, load:true});
    var instance = new GetUsuarios(null, this, this.props.MapaKseg, 0);
    instance.get(); 
  }  
  //--------------------------------------------------------------------
  setList = (_list) => {
    this.setState({list: _list, bloqueado_consultar: false, load: false});
  }  
  //---------------------------------------------------------------
  render() {    
    const onContextMenuPreparing = (e) =>{  // precisa desse para selecionar a linha com o botao direito
      try{
        e.component.selectRows(e.row.key, false)
        var selectedRowData = this.dataGrid.getSelectedRowsData();

        // console.log(e.row.key);
      }catch (response){}
    }
          
    const columns = ['index', 'usuario_id','nome','login', 'pessoa_id', 'nome_social', 'ativo','tipo_usuario'];

    return (      
      <div>
        <Navbar bg="light" expand="lg">
              <Container>
                  <Navbar.Brand href="#home">{this.props.titulo}</Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />

                  <Form className="d-flex">
                      {/* <FormControl type="search" placeholder="Nome" className="me-2" aria-label="Search" /> */}
                      <Button variant="outline-primary" 
                          onClick={()=>this.getApi()} disabled={this.state.bloqueado_consultar} >
                          <div>Consultar</div>
                      </Button>
                  </Form>
              </Container>
          </Navbar>

        {this.state.load?
          <Form  style={{height: '390px', overflow: 'hidden'}}>
            <div className="centro">
                <div className="espaco_topo3">
                    <Spinner animation="border" />
                </div>
            </div>                                        
          </Form> 
        :       
          <div >                                    
            <ContextMenu
              dataSource={this.state.items}
              width={180}
              target="#usuariosGrid"
              itemRender={this.ItemTemplate}
              onItemClick={this.itemClick} />

              <DataGrid
                id="usuariosGrid"
                ref={this.dataGridRef}
                dataSource={this.state.list}
                showBorders={true}
                defaultColumns={columns}
                onRowPrepared={this.onRowPrepared}
                columnAutoWidth={true}
                onContextMenuPreparing={onContextMenuPreparing}
                height={this.props.height_grid}
              > 
                <Selection mode="single" />        
                <Scrolling mode="virtual" />
                <HeaderFilter visible={true} />
                  <FilterRow visible={true} />
              </DataGrid>
    
          </div>
        }       
      </div>
    );
  }
}
