import  {Component} from 'react';
import api from '../api';

export default class PostEntregaReabrir extends Component{
    constructor(props, objeto, MapaKseg, entrega ){
        super(props);
        this.state = {
             loading:true,
             objeto: objeto,
             entrega: entrega,
             MapaKseg: MapaKseg
       }
    }
    //------------------------------------------------
    post = async () => {
        let entrega_idd= 0;
        try {        
            let entrega = this.state.entrega;
            entrega_idd = entrega.entrega;

            let responseJson = null;
            let formData = new FormData()      
            
            formData.append('entrega_id', entrega.entrega_id); 
            formData.append('usuario_cadastro_id', this.state.MapaKseg.state.usuario_id);
            formData.append('token', localStorage.getItem('token'));

            let response = await api.post('/coletaweb_api/postEntregaReabrir', formData);
            // let response = await api.post('/teste_api/postEntregaReabrir', formData);

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);      
                entrega_idd = resposta.entrega_id;     
            }
        
  
        } finally {      
            this.state.objeto.reloadGrid(this.state.entrega);
            }
    };
}