import React, {Component} from 'react';
import api from './api';
import {Modal, Button as Button_bootstrap, Table} from 'react-bootstrap';
import './estilo.css';
import {ToastStore} from 'react-toasts';

// import ReactCountdownClock from 'react-countdown-clock';
import Spinner from 'react-spinner-material';
import GetGridTemperatura from './GetGridTemperatura';

import {CabecalhoModal} from './CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';

export class TempGridModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            divWidth: {
               width: 900
            },
            divHeight: {
                height:500
             },

             temperaturaModalShow:false,            
             list: [],
             temperatura: {},
             dado:true,
             loading:true,
             placa_selecionada: null,
             veiculo_id_selecionada: null,
             segundos:300,
             terminou_segundos:false,
             completions: 0,
             popupVisible: false,
             sensores: '',
             sensor1:null,
             sensor2:null,
             sensor3:null,
             tipoChamada:0,
             faixaSelId:0,
             verde: "#44c87e",
             vermelho: "#ee3f43",
             hrefcolor: "#111111",
             loadingTemperaturaModal: true,
             temperaturaList: [],

             animation: "zoom",      
             minimizar:false,       

             width:1050,
             height:400,
             drag:"drag3"
       }
        this.showInfo = this.showInfo.bind(this);
        this.hideInfo = this.hideInfo.bind(this);
    }
    
    //--------------------
    toastStore = (texto) => {
        ToastStore.success(texto);
    };
    
    //--------------------
    onClearList = () => {
        this.setState({ list: [] });
      };
    //--------------------
    onComplete = () => {
        this.onClearList();  // isso ja faz recarregar a lista das temperaturas(o grid todo)
        var instance = new GetGridTemperatura(this.props.usuario_id, this); 
        instance.getGridTemperatura();
    };
    //--------------------
    showAssociarFaixa = (veic_temperatura, faixa_index, faixa_id) => {
        // ToastStore.success("foda "+String(faixa_index));
        this.setState({tipoChamada:1, cadFaixasModalShow:true, faixaSelId: faixa_id
            , placa_selecionada: veic_temperatura.placa
            , veiculo_id_selecionada: veic_temperatura.veiculo_id
            , faixa_index: faixa_index });
        
        this.showCadFaixas(1, faixa_id);
    };
    //-----------------------
    showCadFaixas = (tipoChamada, faixaSelId) =>{
        this.setState({tipoChamada: tipoChamada, faixaSelId: faixaSelId});
        this.props.SideNavDrawer.showCadastroFaixasModal(this);
    }
    //-------------------
    cadastroFaixasClose = () =>{
        this.props.SideNavDrawer.cadastroFaixasClose();
    };
    //-------------------------
    cadastroFaixasDesativar = () =>{
        this.props.SideNavDrawer.cadastroFaixasDesativar();
    };
    //----------------------------------------TemperaturaModa--------------------------------------------
    showTemperaturaModal = (data) => {
        var temperatura_obj = {
            placa: data.placa
            ,veiculo_id: data.veiculo_id
            ,sensores: data.sensores
            ,sensor1: data.sensor1
            ,sensor2: data.sensor2
            ,sensor3: data.sensor3
            ,nomeFaixa: data.descricao
            ,minLine: data.faixa_min
            ,maxLine: data.faixa_max
            ,getTemperaturas:true
            ,renderLoading:true  // usei para renderizar quando clicar no historico do outro veiculo, e reenderizar o Linechart
        };
        this.props.SideNavDrawer.showTemperaturaModal(temperatura_obj);
    };
    //----------------------------------------------------------------------------------------------
    showInfo() {
        this.setState({
          popupVisible: true
        });
      }
    
      hideInfo() {
        this.setState({
          popupVisible: false
        });
      }
    //--------------------------
    async componentDidMount() {
        // this.getGridTemperatura();
        this.setState({list: []})
        var instance = new GetGridTemperatura(this.props.usuario_id, this); 
        instance.getGridTemperatura();
    };
    //------------
    atualizarDescFaixa =(parametros)=>{
        // let temperatura = this.state.temperatura;
        let list = this.state.list;
        let sensores_obj={};

        for (var i=0; i < list.length; i++) {

            if(list[i].veiculo_id===parametros.veiculo_id){

                if(parametros.faixa_index===0){
                    list[i].faixa_min_max=parametros.faixa_min_max;
                    list[i].descricao=parametros.descricao;
                    list[i].faixa_min = parametros.faixa_min;
                    list[i].faixa_max = parametros.faixa_max;
                    list[i].faixa_temperatura_id = parametros.faixa_temperatura_id;
                }else if(parametros.faixa_index===1){
                    list[i].faixa1_min_max = parametros.faixa_min_max;
                    list[i].descricao1 = parametros.descricao;
                    list[i].faixa1_min = parametros.faixa_min;
                    list[i].faixa1_max = parametros.faixa_max;
                    list[i].faixa1_id = parametros.faixa_temperatura_id;
                }else if(parametros.faixa_index===2){
                    list[i].faixa2_min_max = parametros.faixa_min_max;
                    list[i].descricao2 = parametros.descricao;
                    list[i].faixa2_min = parametros.faixa_min;
                    list[i].faixa2_max = parametros.faixa_max;
                    list[i].faixa2_id = parametros.faixa_temperatura_id;
                }else if(parametros.faixa_index===3){
                    list[i].faixa3_min_max = parametros.faixa_min_max;
                    list[i].descricao3 = parametros.descricao;
                    list[i].faixa3_min = parametros.faixa_min;
                    list[i].faixa3_max = parametros.faixa_max;
                    list[i].faixa3_id = parametros.faixa_temperatura_id;
                }

                sensores_obj = this.formatarSensores(list[i], sensores_obj);
                list[i].sensor1_cor = sensores_obj.sensor1_cor;
                list[i].sensor2_cor = sensores_obj.sensor2_cor;
                list[i].sensor3_cor = sensores_obj.sensor3_cor;
                list[i].sensores = sensores_obj.sensores;

            }
        }
        this.setState({list: list});
    }
    //-----------------------
    loadFaixasUnicas=(veic_temperatura)=>{
        return(
            <div>
            <td style={{backgroundColor: veic_temperatura.sensor1_cor}}>{veic_temperatura.sensor1}</td>
            <td style={{backgroundColor: veic_temperatura.sensor2_cor}}>{veic_temperatura.sensor2}</td>
            <td style={{backgroundColor: veic_temperatura.sensor3_cor}}>{veic_temperatura.sensor3}</td>
            </div>                           
        );
    }
    //---------------------------------------------------------------------------
    minimizarModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.SideNavDrawer.tempGridModalClose();
    };
    //--------------------
    maximizarModal = () =>{
        var grid_h = String(window.innerHeight - 150)+"px";
        this.setState({width: window.innerWidth
                        ,height: window.innerHeight
                        ,drag: "drag0"
                        ,grid_height: grid_h});
    };
    //--------------------
    restoreModal = () =>{
        var grid_h = String(400)+"px";
        this.setState({width: 1050
                        ,height: 400
                        ,drag: "drag3"
                        ,grid_height: grid_h});
    };
    //---------close----------
    fecharModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "zoom"});
        this.setState({minimizar:false});
        this.props.SideNavDrawer.tempGridModalClose();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false){
            if(this.state.minimizar)
                this.props.SideNavDrawer.tempGridModalClose();
            else this.props.SideNavDrawer.tempGridModalDesativar();    
        }
    };
    //-------------------------
    nenhumVeiculo = () =>{    
        this.toastStore("Nenhum Veículo com Temperatura!");
        this.props.SideNavDrawer.tempGridModalClose();
        this.props.SideNavDrawer.tempGridModalDesativar(); 
    };
    //----------------------------
    sequenciaUpdate = () => {
        this.props.SideNavDrawer.setModalSelecionado("TempGridModal");
    };
    //-------------------------
    renderGrid = data => {        
        const percentage = 16;
        var ReactCountdownClock = require('react-countdown-clock');
            return (
                <div className={this.state.drag} onClick={()=>this.sequenciaUpdate()}>
                <Draggable   handle="strong"  >                
                    <div >                    
                        <Rodal 
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            // width={1050}
                            width={this.state.width}
                            height={this.state.height}
                            // height={400}
                            onAnimationEnd={()=>this.desativarModal()}
                            >  

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={true} maximizar={true}  titulo={"Monitor Temperatura"} Modal={this}/></strong>
                                <div className="texto_canto_dir2">
                                    <ReactCountdownClock 
                                        key={this.state.completions}
                                        seconds={this.state.segundos}
                                        showMilliseconds={false}
                                        color="#6342d7"
                                        size={50}
                                        onComplete={()=>this.onComplete()} />
                                </div>
                                <div >                                                                   
                                    <div style={{height: '500px', overflow: 'hidden'}}>                                    
                                        <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0" >
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                    <th>Placa</th>
                                                    <th>Sensor 1</th>
                                                    <th>Sensor 2</th>
                                                    <th>Sensor 3</th>    
                                                    <th>Faixa</th>
                                                    <th>Nome faixa</th>
                                                    <th>Data</th>
                                                    <th>Município</th>
                                                    <th>Histórico</th>
                                                    </tr>
                                                </thead>

                                                {this.state.list.map(veic_temperatura =>
                                                    <tbody id={'data-grid-demo'}>
                                                        <tr  >
                                                            <td>{veic_temperatura.placa}</td>

                                                            {veic_temperatura.faixa_fracionada ?                                           
                                                                <td style={{backgroundColor: veic_temperatura.sensor1_cor}}>
                                                                    {veic_temperatura.sensor1}
                                                                    &nbsp;
                                                                    <a  href="#" onClick={()=>this.showAssociarFaixa(veic_temperatura, 1, veic_temperatura.faixa1_id)} style={{color: this.state.hrefcolor}} >
                                                                        {/* ({veic_temperatura.faixa1_min_max })
                                                                        <img src={require('./img/thermometer_plus24b.png')}/>
                                                                        <br/> */}
                                                                        <img src={require('./img/thermometer_plus24b.png')}/>
                                                                        <br/> 
                                                                        <u>{veic_temperatura.descricao1}</u>
                                                                    </a>
                                                                </td>
                                                                :
                                                                <td style={{backgroundColor: veic_temperatura.sensor1_cor}}>{veic_temperatura.sensor1}</td>
                                                            }

                                                            {veic_temperatura.faixa_fracionada ?                                           
                                                                <td style={{backgroundColor: veic_temperatura.sensor2_cor}}>
                                                                    {veic_temperatura.sensor2}
                                                                    &nbsp;
                                                                    <a  href="#" onClick={()=>this.showAssociarFaixa(veic_temperatura, 2, veic_temperatura.faixa2_id)} style={{color: this.state.hrefcolor}} >
                                                                        {/* ({veic_temperatura.faixa2_min_max })
                                                                        <img src={require('./img/thermometer_plus24b.png')}/>
                                                                        <br/> */}
                                                                        <img src={require('./img/thermometer_plus24b.png')}/>
                                                                        <br/>
                                                                        <u>{veic_temperatura.descricao2}</u>
                                                                    </a>
                                                                </td>
                                                                
                                                                :
                                                                <td style={{backgroundColor: veic_temperatura.sensor2_cor}}>{veic_temperatura.sensor2}</td>
                                                            }

                                                            {veic_temperatura.faixa_fracionada ?                                                                                     
                                                                    <td style={{backgroundColor: veic_temperatura.sensor3_cor}} >
                                                                        {veic_temperatura.sensor3}
                                                                        &nbsp;
                                                                        <a  href="#" onClick={()=>this.showAssociarFaixa(veic_temperatura, 3, veic_temperatura.faixa3_id)} style={{color: this.state.hrefcolor}} >
                                                                            {/* ({veic_temperatura.faixa3_min_max })
                                                                            <img src={require('./img/thermometer_plus24b.png')}/> 
                                                                            <br/> */}
                                                                            <img src={require('./img/thermometer_plus24b.png')}/>
                                                                            <br/>
                                                                            <u>{veic_temperatura.descricao3}</u>
                                                                        </a>
                                                                    </td>
                                                                :
                                                                    <td style={{backgroundColor: veic_temperatura.sensor3_cor}}>{veic_temperatura.sensor3}</td>
                                                                
                                                            }

                                                            {/* {veic_temperatura.faixa_unica ?                                           
                                                                <td style={{backgroundColor: veic_temperatura.sensor1_cor}}>{veic_temperatura.sensor1}</td>
                                                                :
                                                                <td >{veic_temperatura.sensor1}</td>
                                                            }

                                                            {veic_temperatura.faixa_unica ?                                           
                                                                <td style={{backgroundColor: veic_temperatura.sensor2_cor}}>{veic_temperatura.sensor2}</td>
                                                                :
                                                                <td >{veic_temperatura.sensor2}</td>
                                                            }

                                                            {veic_temperatura.faixa_unica ?                                           
                                                                <td style={{backgroundColor: veic_temperatura.sensor3_cor}}>{veic_temperatura.sensor3}</td>
                                                                :
                                                                <td >
                                                                    {veic_temperatura.sensor3}
                                                                    <span class="texto_canto_dir">
                                                                        <a href="#">
                                                                            <img onClick={()=>this.showAssociarFaixa(veic_temperatura)} src={require('./img/editoutline.png')}/>
                                                                        </a>
                                                                    </span>   
                                                                </td>
                                                            } */}


                                                                
                                                                {/* <td style={{backgroundColor: veic_temperatura.sensor1_cor}}>{veic_temperatura.sensor1}</td>
                                                                <td style={{backgroundColor: veic_temperatura.sensor2_cor}}>{veic_temperatura.sensor2}</td>
                                                                <td style={{backgroundColor: veic_temperatura.sensor3_cor}}>{veic_temperatura.sensor3}</td>
                                                                */}

                                                            <td>

                                                                {veic_temperatura.faixa_fracionada ?     
                                                                    <div>
                                                                        Faixa fracionada
                                                                    </div>
                                                                :
                                                                    veic_temperatura.faixa_min===null ?
                                                                    <a  href="#" onClick={()=>this.showAssociarFaixa(veic_temperatura, 0, veic_temperatura.faixa_temperatura_id)} >
                                                                        <img src={require('./img/thermometer_plus24b.png')}/>
                                                                    </a>
                                                                :
                                                                    <a  href="#" onClick={()=>this.showAssociarFaixa(veic_temperatura, 0, veic_temperatura.faixa_temperatura_id)} style={{color: this.state.hrefcolor}} >
                                                                        <img src={require('./img/thermometer_plus24b.png')}/>
                                                                        <u>{veic_temperatura.faixa_min_max }</u>
                                                                    </a>
                                                                }



                                                                {/* {veic_temperatura.faixa_unica ?     
                                                                    veic_temperatura.faixa_min===null ?
                                                                        <span class="texto_canto_dir">
                                                                            <a href="#">
                                                                                <img onClick={()=>this.showAssociarFaixa(veic_temperatura)} src={require('./img/editoutline.png')}/>
                                                                            </a>
                                                                        </span> 
                                                                    :
                                                                        <a  href="#" onClick={()=>this.showAssociarFaixa(veic_temperatura)} >
                                                                            {veic_temperatura.faixa_min_max }
                                                                        </a>
                                                                :
                                                                    <div>
                                                                        <a  href="#" onClick={()=>this.showAssociarFaixa(veic_temperatura)} >
                                                                            1({veic_temperatura.faixa1_min_max })
                                                                        </a>
                                                                        <br/>
                                                                        <a  href="#" onClick={()=>this.showAssociarFaixa(veic_temperatura)} >
                                                                            2({veic_temperatura.faixa2_min_max })
                                                                        </a>
                                                                        <br/>
                                                                        <a  href="#" onClick={()=>this.showAssociarFaixa(veic_temperatura)} >
                                                                            3({veic_temperatura.faixa3_min_max })
                                                                        </a>
                                                                    </div>
                                                                    } */}



                                                                {/* <span class="texto_canto_dir">
                                                                    <a href="#">
                                                                        <img onClick={()=>this.showAssociarFaixa(veic_temperatura)} src={require('./img/editoutline.png')}/>
                                                                    </a>
                                                                </span>    */}

                                                            </td>
                                                                
                                                            <td>
                                                                {veic_temperatura.faixa_fracionada ?     
                                                                        veic_temperatura.descricao="Faixa fracionada"
                                                                : veic_temperatura.descricao}
                                                            </td>
                                                            <td>{veic_temperatura.data_receb.substring(0,3)+', '+veic_temperatura.data_receb.substring(4,9)
                                                                            +'   '+veic_temperatura.data_receb.substring(12,17)
                                                                            +'/'+veic_temperatura.data_receb.substring(18,20)
                                                                            +'/'+veic_temperatura.data_receb.substring(21,25) }
                                                            </td>
                                                            <td>
                                                                {veic_temperatura.municipio}
                                                            </td>
                                                            <td>
                                                                <a  href="#" onClick={()=>this.showTemperaturaModal(veic_temperatura)} >
                                                                    <img src={require('./img/chart_line24b.png')}/>
                                                                </a>     
                                                                {/* <Button_bootstrap  onClick={()=> this.showTemperaturaModal(veic_temperatura)}>
                                                                    <img  src={require('./img/chart_line24.png')}/>
                                                                </Button_bootstrap> */}
                                                            </td>       


                                                        </tr>
                                                    </tbody>
                                                ) }
                                                                            
                                            </Table>
                                        </CustomScroll>                                        
                                    </div>


                            <div className="align_bottom">                        
                                <Button_bootstrap  onClick={()=>this.showCadFaixas(0,0)}><img src={require('./img/thermometer_plus24.png')}/> Faixa</Button_bootstrap>
                            </div>

                    </div>
                </div>                            
            </Rodal>
            </div>
        </Draggable>        
        </div>                
        );
    };  
    //------------

    render(){
        return(
            <div>
                {/* <Spinner size={120} spinnerColor={"#333"} spinnerWidth={2} visible={true} /> */}
                {this.state.loading ? "": 
                    this.state.list.length>0 ?
                        this.renderGrid(this.state.list)
                    : this.nenhumVeiculo()}

                {/* {this.state.loading ? "": this.toastStore(this.state.list.length)} */}

                {/* condição ? true : false. */}
                {/* {this.showGrid()}           */}
            </div>
        );
    }
}