import React from 'react';
import PieChart, { Series, Label, Legend, Export } from 'devextreme-react/pie-chart';
// import { countries, waterLandRatio } from '../dados/coletasEntregas_data.js';
import '../estilo.css';

// const pieCharts = [
//   {
//     title: 'Coletas',
//     palette: 'Soft',
//     dataSource: countries,
//   },
//   {
//     title: 'Entregas',
//     palette: 'Soft Pastel',
//     dataSource: waterLandRatio,
//   },
// ];

// import dados from '../dados/veiculos_kmpercorrido_data.js';


export class ColetasEntregasChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pieCharts : [
        {
          title: 'Coletas '+ String(this.props.titulo_coleta),
          palette: 'Soft',
          dataSource: this.props.list[0],
        },
        {
          title: 'Entregas '+String(this.props.titulo_entrega),
          palette: 'Soft Pastel',
          dataSource: this.props.list[1],
        },
      ]
    };
  }
  //-----------------
  render() {
    // const pies = pieCharts.map((options, i) => (
      const pies = this.state.pieCharts.map((options, i) => (      
        <PieChart
          className="pie"
          key={i}
          title={options.title}
          palette={options.palette}
          sizeGroup="piesGroup"
          dataSource={options.dataSource}
        >
          <Series
            argumentField="nome"
            valueField="porcentagem"
          >
            <Label
              visible={true}
              format="percent"
            />
          </Series>
          <Legend
            verticalAlignment="bottom"
            horizontalAlignment="center"
            itemTextPosition="right"
            rowCount={1}
          />
          <Export enabled={true} />
        </PieChart>
      ));
      return <div className="pies-container">{pies}</div>;
  }
}
