import React, {Component} from 'react';
import {Modal, Form, Button as Button_bootstrap, FormControl, Col, Row, InputGroup} from 'react-bootstrap';
import '../estilo.css';


export  class FormModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true, 
            showSheet:true,
            valor1:""
        };
      }   
    //---------close---------- 
    //--------------------------
    async componentDidMount() {
        document.getElementById("formValor1").focus();
    };
    //----------------------------------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeFormModal();
    };
    //------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarFormModal();
    };
    //------------------------
    okClick = ()=>{
        this.props.objeto.confirmarFormModal(this.state.valor1);

        this.fecharModal();
    };
    //------------------------
    onKeyUp = (event) => {
        if (event.key === "Enter") {
          this.okClick();
        }
    };
    //------------------------------------------------------
    updateInputValue(event) {
        var valor1 = this.state.valor1;
        if(event.target.id==="formValor1"){
            valor1 = event.target.value;                     
        }

        this.setState({valor1: valor1});
        // this.setState({editados: editados, changed: true});
        }
    //-------------------------  <img src={this.props.comando.icone} /> {this.props.comando.descricao}
    render(){
        return(
            <div className="splashscreen-modal1">
                <Modal  centered
                    {...this.props}
                    
                    aria-labelledby="example-custom-modal-styling-title"
                    scrollable="True"
                    dialogClassName="temperatura-modal2"    
                    >
                    <div >
                    <strong className="cursor">
                        <div className="cabecalho"> 
                            <label className="titulo2" > {this.props.titulo} </label>                      
                        </div>
                    </strong>
                        
                    <Modal.Body>                                                
                        
                        <div className="horizontal">
                            <p className="recuo">{this.props.texto}</p>
                        </div>

                        {/* <Form.Group as={Row} controlId="formValor1">              

                                <InputGroup className="mb-1" >                                                        
                                    <Form.Control aria-describedby="basic-addon1" value={this.state.valor1} onChange={this.updateInputValue.bind(this)} />                         
                                </InputGroup>

                        </Form.Group>   */}

                        <Form.Group as={Row} controlId="formValor1">
                            <Form.Label column sm={3}>            
                                {this.props.campos[0]}
                            </Form.Label>
                            <Col sm={5}>
                                <InputGroup className="mb-1" >                                                        
                                    <Form.Control type="number" aria-describedby="basic-addon1" onKeyPress={this.onKeyUp}
                                    value={this.state.valor1} onChange={this.updateInputValue.bind(this)} />                         
                                </InputGroup>
                            </Col>
                        </Form.Group> 
                        </Modal.Body>
                    </div>


                    <Modal.Footer>
                        <Button_bootstrap className="left" onClick={this.props.onHide}>Cancelar</Button_bootstrap>
                        <Button_bootstrap onClick={this.okClick}> Ok</Button_bootstrap>                
                    </Modal.Footer>

                </Modal>

            </div>
        );
    }
}