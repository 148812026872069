import React, {Component} from 'react';
import { Container, Button as ButtonFab, Link, lightColors } from 'react-floating-action-button'
import {TemasMapaModal} from './TemasMapaModal';
import {GruposPoligonoModal} from './GruposPoligonoModal';
import {ToastContainer, ToastStore} from 'react-toasts';

export class FloatActionButton extends Component{
    constructor(props){
        super(props);
        this.state = {
             temasMapaModalShow:false,
             temasMapaModalAtivo:false,

             gruposPoligonoModalShow:false,
             gruposPoligonoModalAtivo:false,
       }
    }
    //-------------------------------------------------------TemasMapaModal----------------------------------------------------------------
    renderTemasMapaModal = () => {
        return(
            <div>
                <TemasMapaModal  
                    show={this.state.temasMapaModalShow}
                    onHide={this.closeTemasMapaModal}
                    FloatActionButton={this}
                    MapaKseg={this.props.MapaKseg}
                />    
            </div>);
    };
    //--------------------
    showTemasMapaModal = () => {
        this.setState({temasMapaModalShow:true});
        this.setState({temasMapaModalAtivo:true});
    };
    //-------------------
    closeTemasMapaModal = () =>{
        this.setState({temasMapaModalShow:false});
    };   
    //-------------------
    temasMapaModalDesativar = () =>{
        this.setState({temasMapaModalAtivo:false});
        // this.setState({abrirModal:false});
       };
     //-------------------------------------------------------GruposPoligonoModal----------------------------------------------------------------
     renderGruposPoligonoModal = () => {
        return(
            <div>
                <GruposPoligonoModal  
                    show={this.state.gruposPoligonoModalShow}
                    onHide={this.closeGruposPoligonoModal}
                    FloatActionButton={this}
                    MapaKseg={this.props.MapaKseg}
                />    
            </div>);
    };
    //--------------------
    showGruposPoligonoModal = () => {
        this.setState({gruposPoligonoModalShow:true});
        this.setState({gruposPoligonoModalAtivo:true});
    };
    //-------------------
    closeGruposPoligonoModal = () =>{
        this.setState({gruposPoligonoModalShow:false});
    };   
    //-------------------
    gruposPoligonoModalDesativar = () =>{
        this.setState({gruposPoligonoModalAtivo:false});
        // this.setState({abrirModal:false});
       };      
    zoomMais = () => {
        this.props.MapaKseg.zoomMais();
    };
    //--------------------
    zoomMenos = () => {
        this.props.MapaKseg.zoomMenos();
     };
    //------------------------
    async componentDidMount() {
        this.props.MapaKseg.setState({FloatActionButton:this});
      }
    //-------------------
    render() {
        return (
            <div>        
                <Container styles={{paddingBottom:50}}>          

                    <div >
                     <Link href="#"
                        styles={{backgroundColor: lightColors.white, color: lightColors.white}}
                        tooltip="Notificação"
                        icon="far fa-sticky-note" >
                        <img src={require('./img/bell_ring48.png')} width="25" onClick={() => this.props.MapaKseg.state.Modals.showNotificacaoModal()}/>
                    </Link>  
                    <Link href="#"
                        styles={{backgroundColor: lightColors.white, color: lightColors.white}}
                        tooltip="Área"
                        icon="fas fa-user-plus" 
                        className="fab-item btn btn-link btn-lg text-white">
                        <img src={require('./img/vector_square.png')} onClick={() => this.showGruposPoligonoModal()}/>
                    </Link>
                    <Link href={null} 
                        styles={{backgroundColor: lightColors.white, color: lightColors.white}}
                        tooltip="Temas de mapa"                    
                        >
                        <img src={require('./img/paint.png')} onClick={() => this.showTemasMapaModal()} />
                    </Link>         
                    {/* <Link href={null}
                        styles={{backgroundColor: lightColors.white, color: lightColors.white}}
                        tooltip="Onde estou"
                        icon="fas fa-user-plus" 
                        className="fab-item btn btn-link btn-lg text-white">
                        <img src={require('./img/gps.png')}/>
                    </Link> */}


                    <ButtonFab
                        styles={{backgroundColor: lightColors.white, color: lightColors.white}}                  
                        rotate={true}
                        // onClick={() => alert('FAB Rocks!')} 
                        >
                            <img src={require('./img/plus.png')}/>
                    </ButtonFab>    


                    </div>                
                </Container>

            
            {/* ------------------------------zoom---------------------------------- */}
                <Container styles={{width:53, height:60}}>                
                    <div>
                        <ButtonFab 
                            styles={{backgroundColor: "white", color: lightColors.white, width:30, height:30}}
                            onClick={()=>this.zoomMais()}
                            >
                            <div>
                            <img src={require("./img/plus48.png")} width="35"/>
                            </div>                      
                        </ButtonFab>            
                    </div>

                    <div>
                        <ButtonFab 
                            styles={{backgroundColor: "white", color: lightColors.white, width:30, height:30, marginTop: 5}}
                            onClick={()=>this.zoomMenos()}
                            >
                            <div>
                            <img src={require("./img/minus48.png")} width="35"/>
                            </div>                      
                        </ButtonFab>                        
                    </div>
                </Container> 
            



                {this.state.temasMapaModalAtivo ? this.renderTemasMapaModal() : ""} 
                {this.state.gruposPoligonoModalAtivo ? this.renderGruposPoligonoModal() : ""}            

                <div>
                    <ToastContainer position={ToastContainer.POSITION.BOTTOM_CENTER} store={ToastStore}/>
                </div>

       

            </div>   
        )
    }
};