import  {Component} from 'react';
import api from '../api';

export default class PostEntrega extends Component{
    constructor(props, objeto, MapaKseg ){
        super(props);
        this.state = {
             list: [],
             loading:true,
             objeto: objeto,
             entrega_id:null,
             MapaKseg: MapaKseg
       }
    }
    //------------------------------------------------
    post = async () => {
        let entrega_idd= 0;
        try {        
            let editados = this.state.objeto.state.editados;
            let entrega = this.state.objeto.state.entrega;
            entrega_idd = entrega.entrega_id;

            let response = null;
            let responseJson = null;
            let formData =null;    

            if(editados.cliente || editados.local || editados.rota || editados.pagamento || editados.valor 
                || editados.previsao || editados.observacao || editados.cte || editados.pago || editados.tda
                || editados.nota_fiscal ){
                formData = new FormData()                      

                if(editados.cliente) formData.append('cliente_id', entrega.cliente_id);
                if(editados.local) formData.append('local_id', entrega.local_id);
                if(editados.rota) formData.append('rota_id', entrega.rota_id);
                if(editados.pagamento) formData.append('tipo_pagamento_id', entrega.tipo_pagamento_id);
                if(editados.valor) formData.append('valor', entrega.valor);
                if(editados.previsao) formData.append('data_previsao', entrega.data_previsao);
                if(editados.observacao) formData.append('observacao', entrega.observacao);
                if(editados.cte) formData.append('cte', entrega.cte);
                if(editados.pago) formData.append('pago', entrega.pago);
                if(editados.tda) formData.append('tda', entrega.tda);
                if(editados.nota_fiscal) formData.append('nota_fiscal', entrega.nota_fiscal);

                // console.log("Editados: ");
                // console.log(" tipo_pagamento_id: "+String(entrega.tipo_pagamento_id));
                // console.log(" pago: "+String(entrega.pago));
                
                formData.append('coleta_id', entrega.coleta_id); 
                formData.append('entrega_id', entrega.entrega_id);
                formData.append('usuario_cadastro_id', this.state.MapaKseg.state.usuario_id);
                formData.append('pessoa_id', this.state.MapaKseg.state.pessoa_id);
                formData.append('token', localStorage.getItem('token'));

                // console.log(" formData: ");
                // for (var value of formData.values()) {
                //     console.log(value);
                //  }

                response = await api.post('/coletaweb_api/postEntrega', formData);
                // response = await api.post('/teste_api/postEntrega', formData);


                if (response.data !== null  &&  response.status !== 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json); 
                    // console.log(resposta.poligono_id);                     
                    entrega_idd = resposta.entrega_id;     
                }
            }

  
        } finally {      
            let entrega = this.state.objeto.state.entrega;
            entrega.entrega_id = entrega_idd;
            this.state.objeto.setState({entrega: entrega});
            // this.state.objeto.concluido();
            this.state.objeto.concluido(entrega);
            }
    };
    //--------------------------
}