import React, {Component} from 'react';
import './estilo.css';
import api from './api';
import { ToastStore} from 'react-toasts';

const temperaturaSourcesList = [
    { value: 'sensor1', name: 'Sensor 1' },
    { value: 'sensor2', name: 'Sensor 2' },
    { value: 'sensor3', name: 'Sensor 3' },
];
// const types = ['line', 'stackedline', 'fullstackedline'];

var moment = require('moment');

export class GetTemperaturasVei extends Component{
    constructor(props, RenderTemperatura, TemperaturaModal) {
        super(props);
        this.state = {
            bottomSheet:true, 
            showSheet:true,
            tempGridModalShow:false,
            type: 'line',
            maxLine: 0,
            minLine: 0,
            clockVisibility:"not-visible",
            // loading: props.loading,
            loading: true,
            nomeFaixa: "",

            list: [],
            temperatura: {},

            temperaturaData: {},
            temperaturaDataList: [],
            RenderTemperatura: RenderTemperatura,
            TemperaturaModal: TemperaturaModal,
            veiculo_id: props.veiculo_id,
        };
      }
      //--------------------
  dateDiff = (posicaoAnterior, posicao) => {

    // var today = new Date();
    // var Christmas = new Date("2020-07-03");
    // var diffMs = (Christmas - today); // milliseconds between now & Christmas
    // var diffDays = Math.floor(diffMs / 86400000); // days
    // var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    // var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    // alert(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes until Christmas 2009 =)");
    var inserir = true;
    var ano_anterior = posicaoAnterior.data_receb.substring(21,25);
    var mes_anterior = posicaoAnterior.data_receb.substring(18,20);
    var dia_anterior = posicaoAnterior.data_receb.substring(15,17);
    var hora_anterior = posicaoAnterior.data_receb.substring(4,12);
    var a = moment(ano_anterior+"-"+mes_anterior+"-"+dia_anterior+"T"+hora_anterior+"-03:00");  //2020-03-17T17:39:31-03:00

//   sex 21:47:10   03-07-2020  

    var ano = posicao.data_receb.substring(21,25);
    var mes = posicao.data_receb.substring(18,20);
    var dia = posicao.data_receb.substring(15,17);
    var hora = posicao.data_receb.substring(4,12);
    var b = moment(ano+"-"+mes+"-"+dia+"T"+hora+"-03:00");  //2020-03-17T17:39:31-03:00

    const diff = b.diff(a);
    const diffDuration = moment.duration(diff);
    const diff_minutes = parseInt(diffDuration.asMinutes())

    // // alert(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes");
    // alert(diff_minutes + " minutes");


    if(diff_minutes>2)
        inserir = true;
    else inserir = false;

    console.log("****  "+hora_anterior+"  "+posicaoAnterior.data_receb+"  -   "+posicao.data_receb+"   "+String(diff_minutes) + " minutes  "+String(inserir))

    return inserir;
  }
  //--------------------
    getTemperaturas = async (veiculo_id) => {
            try {
            this.setState({loading:true, list: []})
            
            if(this.state.RenderTemperatura !== null)
                this.state.RenderTemperatura.setState({renderLoading:true});

            let body = new FormData()
            body.append('grupo_id', 1)
            body.append('token', localStorage.getItem('token'))
            // body.append('veiculo_id',this.props.veiculo_id)
            // body.append('veiculo_id',this.RenderTemperatura.props.veiculo_id)
            body.append('veiculo_id', veiculo_id)
            body.append('pessoa_id',1)

            const response = await api.post('/temperatura_api/relatorioTemperaturasVeiculo2', body);
            // ToastStore.success("response.status: "+ String(response.status));
            var responseJson = null
            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data[0])
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);

                var responseJsonFaixa = null
                responseJsonFaixa = response.data[1];                
                // ToastStore.success("max: "+ String(responseJsonFaixa.faixa_max));

                this.setState({minLine: responseJsonFaixa.faixa_min});
                this.setState({maxLine: responseJsonFaixa.faixa_max});

                if(responseJsonFaixa.faixa_min === null)
                    this.setState({nomeFaixa: "Faixa fracionada"});
                else this.setState({nomeFaixa: responseJsonFaixa.descricao});

                let temperatura = this.state.temperatura;
                let list = this.state.list;
                let verde = 1;
                let vermelho = 0;
                let sensor1_cor = null;
                let sensor2_cor = null;
                let sensor3_cor = null;

                let temperaturaData = this.state.temperaturaData;
                let temperaturaDataList = this.state.temperaturaDataList;
                let temperatura_anterior =null;
                let inserir = true;

                // ToastStore.success("temperatura resposta.length: "+ String(resposta.length));
                for (var i = resposta.length-1; i >=0; i--) {
                    sensor1_cor=verde;
                    sensor2_cor=verde;
                    sensor3_cor=verde;

                    if((resposta[i].sensor1 < resposta[i].faixa_min) || (resposta[i].sensor1 > resposta[i].faixa_max))
                        sensor1_cor=vermelho;

                    if((resposta[i].sensor2 < resposta[i].faixa_min) || (resposta[i].sensor2 > resposta[i].faixa_max))
                        sensor2_cor=vermelho;    

                    if((resposta[i].sensor3 < resposta[i].faixa_min) || (resposta[i].sensor3 > resposta[i].faixa_max))
                        sensor3_cor=vermelho;

                    temperatura = {
                        veiculo_id: resposta[i].veiculo_id,
                        placa: resposta[i].placa,
                        tecnologia_id: resposta[i].tecnologia_id,
                        sensor1: resposta[i].sensor1,
                        sensor2: resposta[i].sensor2,
                        sensor3: resposta[i].sensor3,
                        data_receb: resposta[i].data_receb,
                        data_receb2: "   "+resposta[i].data_receb.substring(0,9)+"  "+resposta[i].data_receb.substring(12,25),
                        faixa_temperatura_id: resposta[i].faixa_temperatura_id,
                        descricao: resposta[i].descricao,
                        faixa_min: resposta[i].faixa_min,
                        faixa_max: resposta[i].faixa_max,
                        sensor1_cor: sensor1_cor,
                        sensor2_cor: sensor2_cor,
                        sensor3_cor: sensor3_cor,
                        
                        faixa_fracionada: resposta[i].faixa_fracionada,
                        faixa1_min: resposta[i].faixa1_min,
                        faixa1_max: resposta[i].faixa1_max,
                        faixa1_min_max: resposta[i].faixa1_min+'      a    ' +resposta[i].faixa1_max,
                        faixa2_min: resposta[i].faixa2_min,
                        faixa2_max: resposta[i].faixa2_max,
                        faixa2_min_max: resposta[i].faixa2_min+'      a    ' +resposta[i].faixa2_max,
                        faixa3_min: resposta[i].faixa3_min,
                        faixa3_max: resposta[i].faixa3_max,
                        faixa3_min_max: resposta[i].faixa3_min+'      a    ' +resposta[i].faixa3_max,
                        descricao1 : resposta[i].descricao1,
                        descricao2 : resposta[i].descricao2,
                        descricao3 : resposta[i].descricao3,
                        faixa1_id: resposta[i].faixa1_id,
                        faixa2_id: resposta[i].faixa2_id,
                        faixa3_id: resposta[i].faixa3_id
                        };


                    list.push(temperatura);
                    this.setState({list: list});                                        
                    // ------------------

                    temperaturaData = {
                        horario: temperatura.data_receb2,
                        data_receb: temperatura.data_receb,
                        sensor1: resposta[i].sensor1,
                        sensor2: resposta[i].sensor2,
                        sensor3: resposta[i].sensor3
                    };

                    // if(temperaturaDataList.length >0){
                    //     temperatura_anterior = temperaturaDataList[temperaturaDataList.length-1];
                    //     inserir = this.dateDiff(temperatura_anterior, temperatura);
                    // }

                    if(inserir)
                        temperaturaDataList.push(temperaturaData);

                    this.setState({temperaturaDataList: temperaturaDataList});                     
                }
                this.setState({loading: false}); 
                
            }else{
            // this.getToken();
            // this.getPosicoesList();
            }

            } finally {
                this.setState(
                    {completions: this.state.completions + 1},
                    () => console.log('completions', this.state.completions)
                  )

                  if(this.state.RenderTemperatura !== null){
                    this.state.RenderTemperatura.setState({temperaturaList: this.state.temperaturaDataList});
                    this.state.RenderTemperatura.setState({list:this.state.list});
                    // this.state.RenderTemperatura.setFaixaData(this.state.nomeFaixa, this.state.minLine, this.state.maxLine);
                    this.state.RenderTemperatura.setState({nomeFaixa:this.state.nomeFaixa, minLine: this.state.minLine, maxLine: this.state.maxLine});
                    this.state.RenderTemperatura.setState({renderLoading:false});
                  }else if(this.state.TemperaturaModal !== null){
                    this.state.TemperaturaModal.setData(this.state.temperaturaDataList);
                  }                
            }    
    };
}