import  {Component} from 'react';
import api from '../api';
import {ToastStore} from 'react-toasts';
import { PersonPinCircleOutlined } from '@material-ui/icons';

// import {Polyline} from 'google-maps-react';

export default class GetTabUsuario extends Component{
    constructor(props, objeto, MapaKseg){
        super(props);
        this.state = {
             list: [],
             loading:true,
             objeto: objeto,
             MapaKseg: MapaKseg,
       }
    }
    //---------------------
    toastStore = (texto) => {
        ToastStore.success("teste: "+texto);
    };
    //------------------------------------------------
    get = async () => {
        let principal="";
        try {
            let body = new FormData()
            body.append('usuario_id', this.state.MapaKseg.state.usuario_id)
            body.append('token', localStorage.getItem('token'))

            const response = await api.post('/login_api/getTabsUsuario', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let tab = this.state.tab;
                let list = this.state.list; 


                // ToastStore.success("resposta.length: "+ String(resposta.length));
                for (var i = 0; i < resposta.length; i++) {                        
                    tab = {    
                        index: i,
                        tab_id: resposta[i].tab_id,
                        descricao: resposta[i].descricao,
                        principal: resposta[i].principal,
                        enable: false,
                        index_scroll: (i+2)*25,
                        renderTab: null,
                    };

                    if(tab.principal==true ){
                        tab.enable=true;
                        principal = tab.descricao;
                    }

                    // tab.renderTab = this.state.objeto.setTab(tab.tab_id);    

                    list.push(tab);
                    this.setState({list: list});
                    console.log(tab);
                }
                
            }
        } finally {    
            if(this.state.list.length>0){
                let tabb2 = [];
                tabb2.push(this.state.list[0]);       

                if(principal === "") principal = this.state.list[0].descricao;
                
                this.state.objeto.setState({tabs: this.state.list, tabs2: tabb2, activeKey: principal});
                this.state.objeto.setState({tab_load: false});              
            }
        }
    };
    //--------------------------
}