import  {Component} from 'react';
import api from './api';
import {ToastStore} from 'react-toasts';
var moment = require('moment');

const TIPO_CHAMADA_PLUSCLICK = 0;
const TIPO_CHAMADA_MARKERCLICK_VEICULO = 1;
const TIPO_CHAMADA_RECARREGARAUTOMATICO_VEI_SELECIONADO = 2;
// import {Polyline} from 'google-maps-react';

export class GetPosicoesVeiculo extends Component{
    constructor(props, grupo_id, mapaKseg, tipo_chamada){
        super(props);
        this.state = {
             veiculo: props,
             posicao: {},
             list: [],
             loading:true,
             grupo_id: grupo_id,
             mapaKseg:mapaKseg,
             markerEvento_list: [],
             tipo_chamada: tipo_chamada
       }
    }
    //---------------------
    toastStore = (texto) => {
        ToastStore.success("teste: "+texto);
    };
    //--------------------
    getPosicoes = async () => {
        // console.log("  ***this.veiculo.data_receb: "+this.state.veiculo.data_receb)
        try {
            // const date = Date(this.veiculo.data_receb);
            // sex 14:09:34 08-11-2019
            let y = this.state.veiculo.data_receb.substring(21,25);
            let mm = this.state.veiculo.data_receb.substring(18,20);
            let dd = this.state.veiculo.data_receb.substring(15,17);
            let hm = this.state.veiculo.data_receb.substring(3,9);
            
            let datetime = moment(y+"-"+mm+"-"+dd+" "+hm+"-03:00").format('Y-MM-DD HH:mm');
            // console.log(" joined dateTime 2: "+String(datetime));

            let body = new FormData();
            body.append('grupo_id', this.state.grupo_id);
            body.append('veiculo_id', this.state.veiculo.veiculo_id);
            body.append('data_final', String(datetime));
            body.append('token', localStorage.getItem('token'));

            // console.log("  ***datetime: "+datetime)

            // const response = await api.post('/posicoesVeiculoData', body);
            let response;
            
            if(this.state.tipo_chamada === TIPO_CHAMADA_RECARREGARAUTOMATICO_VEI_SELECIONADO){
                // console.log(" this.state.tipo_chamada == TIPO_CHAMADA_RECARREGARAUTOMATICO_VEI_SELECIONADO ");
                datetime = moment(y+"-"+mm+"-"+dd+" "+hm+"-03:00").add(1, 'minutes').format('Y-MM-DD HH:mm');
                body.append('data_final', String(datetime))
                let lastPosicoesVeiculo = this.state.mapaKseg.state.veiculoPosicao_selecionado;
                // console.log(" lastPosicoesVeiculo: "+lastPosicoesVeiculo.data_receb);
                if(String(lastPosicoesVeiculo.data_receb).length > 20){
                    y = lastPosicoesVeiculo.data_receb.substring(21,25);
                    mm = lastPosicoesVeiculo.data_receb.substring(18,20);
                    dd = lastPosicoesVeiculo.data_receb.substring(15,17);
                    hm = lastPosicoesVeiculo.data_receb.substring(3,9);
                    datetime = y+"-"+mm+"-"+dd+" "+hm;  // "Y-MM-DD H:M"
                }
                body.append('data_ini', datetime)

                response = await api.post('/posicoesVeiculoPeriodo', body);
            }else response = await api.post('/posicoesVeiculoParada', body);
                        
            var coords = [];
            coords.push({lat: this.state.veiculo.latitude, lng: this.state.veiculo.longitude});
            var lastPosition;
            var markerEvento_list = []; // = this.state.markerEvento_list;
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);

            
                let posicao = this.state.posicao;
                let list = this.state.list;             
                // list.push(this.state.veiculo);   
                // this.setState({list: list});
                // ToastStore.success("resposta.length: "+ String(resposta.length));
                // for (var i = resposta.length-1; i >= 0; i--) {
                for (var i = 0; i < resposta.length; i++) {
                    posicao = {
                        recebimento_id: resposta[i].recebimento_id,
                        veiculo_id: resposta[i].veiculo_id,
                        tipo_recebimento_id: resposta[i].tipo_recebimento_id,
                        // tecnologia_id: resposta[i].tecnologia_id,
                        data_receb: resposta[i].data_cadastro,   // ter 14:13:00   28-07-2020 
                        data_receb2: resposta[i].data_receb,
                        dateTime: resposta[i].data_cadastro,
                        latitude: resposta[i].latitude,
                        longitude: resposta[i].longitude, 
                        direcao: resposta[i].direcao,                 
                        backup: resposta[i].backup,   
                        velocidade: resposta[i].velocidade,
                        tipo_evento_id: 0,
                        };

                    // ToastStore.success("direcao: "+ String(posicao.direcao));
                    // console.log(" posicao.data_receb: "+posicao.data_receb +  "   lat: "+posicao.latitude+"   recebimento_id: "+posicao.recebimento_id);
                    list.push(posicao);
                    this.setState({list: list});

                    y = posicao.data_receb.substring(21,25);
                    mm = posicao.data_receb.substring(18,20);
                    dd = posicao.data_receb.substring(15,17);
                    hm = posicao.data_receb.substring(3,9);
                    posicao.dateTime = new Date(y+"-"+mm+"-"+dd+" "+hm).getTime();   //"2016-01-04 10:34:23"

                    // if(i===resposta.length-1)
                        // ToastStore.success(" "+ String(posicao.data_receb));
                    // coords.push({lat: resposta[i].latitude, lng: resposta[i].longitude});
                    coords.push({lat: posicao.latitude, lng: posicao.longitude});

                    // if(resposta[i].tipo_recebimento_id === 6  || resposta[i].tipo_recebimento_id === 2){  // 6- evento    2-alerta
                    if( resposta[i].tipo_recebimento_id === 2   ||  resposta[i].tipo_recebimento_id === 6){  // 6- evento    2-alerta
                        
                        posicao.tipo_evento_id = 1;

                        markerEvento_list.push({
                            recebimento_id: resposta[i].recebimento_id,
                            data_receb: resposta[i].data_cadastro, 
                            latitude: resposta[i].latitude,
                            longitude: resposta[i].longitude,
                            maark: null,
                        });
                    }

                    if( resposta[i].tipo_recebimento_id === 2){}

                    
                }

                this.setState({loading: false});
                if(this.state.list.length > 0){
                    // lastPosition = list[0];
                    lastPosition = list[list.length-1];
                    // let polyline_obj = {esperando_renderizar:true, polyline: null};
                    // polyline_obj = this.state.mapaKseg.setPolyline(polyline_obj, coords);
                    // polyline_list.push(polyline_obj);                    
                    
                }
                
            }else{
            }

        } finally {
                // ToastStore.success("PPlaca: "+ String(lastPosition.data_receb));
                // ToastStore.success("this.state.list.length: "+ String(this.state.list.length));
                // ToastStore.success('markerEvento_list.len: '+String(markerEvento_list.length));

                // console.log("markerEvento_list.len: "+String(markerEvento_list.length));
                
                if(this.state.list.length > 0){
                    if(this.state.tipo_chamada === TIPO_CHAMADA_MARKERCLICK_VEICULO ||this.state.tipo_chamada === TIPO_CHAMADA_PLUSCLICK)
                        this.state.mapaKseg.setCoords(this.state.list, coords, lastPosition, markerEvento_list);
                    else if(this.state.tipo_chamada === TIPO_CHAMADA_RECARREGARAUTOMATICO_VEI_SELECIONADO)    
                        this.state.mapaKseg.pushCoords(this.state.list, coords, markerEvento_list, this.state.veiculo);
                    
                    // console.log(" ***PRIMEIRA POSICAO getPOsicoesVeiculo: ");
                    // console.log(this.state.list[0]);
                    this.state.mapaKseg.setPosicoesVeiculo(this.state.list);
                    // this.state.mapaKseg.setState({polyline_list: polyline_list});                    
                }
            }
    };
    //--------------------------------------------------------------------------------
    getPosicoesData = async (startDate, endDate, posicoes_list) => {
        try {
            let respostaVazia=true;
            let count_loop=0;
            let startDate_edit = startDate;
            var coords = [];
            // polyline_list=[];
            var lastPosition;
            var markerEvento_list = []; // = this.state.markerEvento_list;
            var body;
            var y, mm,dd,hm;

            while(respostaVazia){
                count_loop+=1;
                // console.log(startDate_edit);
                body = new FormData()
                body.append('grupo_id', localStorage.getItem('grupo_id'))
                body.append('veiculo_id', this.state.veiculo.veiculo_id)
                body.append('data_inicial', startDate_edit)
                body.append('data_final', endDate)
                body.append('token', localStorage.getItem('token'))
                body.append('temperaturas_tab', this.state.veiculo.veiculo_temperaturas_tab)
                // alert(startDate_edit + "\n"+ endDate);
        
                var url = "/relatorio_api/posicoesVeiculoData";
        
                const response = await api.post(url, body);
        
        
                var responseJson = null
        
                if (response.data !== null  &&  response.status !== 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json);

                
                    let posicao = this.state.posicao;
                    let list = this.state.list;             
                    // list.push(this.state.veiculo);   
                    // this.setState({list: list});
                    // ToastStore.success("resposta.length: "+ String(resposta.length));

                    if(resposta.length>0){
                        count_loop=0;
                        respostaVazia=false;
                        // for (var i = resposta.length-1; i >= 0; i--) {
                        for (var i = 0; i < resposta.length; i++) {
                            posicao = {
                                recebimento_id: resposta[i].recebimento_id,
                                veiculo_id: resposta[i].veiculo_id,
                                tipo_recebimento_id: resposta[i].tipo_recebimento_id,
                                // tecnologia_id: resposta[i].tecnologia_id,
                                data_receb: resposta[i].data_cadastro,   // ter 14:13:00   28-07-2020 
                                data_receb2: resposta[i].data_receb,
                                dateTime: resposta[i].data_cadastro,
                                latitude: resposta[i].latitude,
                                longitude: resposta[i].longitude, 
                                direcao: resposta[i].direcao,                 
                                backup: resposta[i].backup,   
                                velocidade: resposta[i].velocidade,
                                tipo_evento_id: 0,
                                };

                            // ToastStore.success("direcao: "+ String(posicao.direcao));
                            // console.log(" posicao.data_receb: "+posicao.data_receb +  "   lat: "+posicao.latitude+"   recebimento_id: "+posicao.recebimento_id);
                            list.push(posicao);
                            this.setState({list: list});

                            y = posicao.data_receb.substring(21,25);
                            mm = posicao.data_receb.substring(18,20);
                            dd = posicao.data_receb.substring(15,17);
                            hm = posicao.data_receb.substring(3,9);
                            posicao.dateTime = new Date(y+"-"+mm+"-"+dd+" "+hm).getTime();   //"2016-01-04 10:34:23"

                            // if(i===resposta.length-1)
                                // ToastStore.success(" "+ String(posicao.data_receb));
                            // coords.push({lat: resposta[i].latitude, lng: resposta[i].longitude});
                            coords.push({lat: posicao.latitude, lng: posicao.longitude});

                            // if(resposta[i].tipo_recebimento_id === 6  || resposta[i].tipo_recebimento_id === 2){  // 6- evento    2-alerta
                            if( resposta[i].tipo_recebimento_id === 2   ||  resposta[i].tipo_recebimento_id === 6){  // 6- evento    2-alerta
                                
                                posicao.tipo_evento_id = 1;

                                markerEvento_list.push({
                                    recebimento_id: resposta[i].recebimento_id,
                                    data_receb: resposta[i].data_cadastro, 
                                    latitude: resposta[i].latitude,
                                    longitude: resposta[i].longitude,
                                    maark: null,
                                });
                            }

                            // if( resposta[i].tipo_recebimento_id === 2){}

                            
                        }

                        this.setState({loading: false});
                        if(this.state.list.length > 0){
                            // lastPosition = list[0];
                            lastPosition = list[list.length-1];

                            // let polyline_obj = {esperando_renderizar:true, polyline: null};
                            // polyline_obj = this.state.mapaKseg.setPolyline(polyline_obj, coords);
                            // polyline_list.push(polyline_obj);   
                        }
            
                            
                    }
                }

                startDate_edit = moment(startDate_edit).subtract(1, 'days').format('Y-MM-DD HH:mm');

                if(count_loop ===10) break;
            }
  
        } finally {
            // this.state.MapaKseg.callToast("finally len: "+this.state.list.length);
            // if(this.state.list.length>0){
            //     alert("getEventos");
            //     var tipo_chamada=2;  //  TIPO_CHAMADA_POSICOES_EVENTOS_GRID
            //     var instance = new GetEventos(this.state.list[this.state.list.length-1]
            //                                     , this.state.MapaKseg
            //                                     , this.state.list
            //                                     , tipo_chamada
            //                                     , this.state.veiculo.veiculo_id
            //                                     ,null
            //                                     ,this);
            //     instance.getEventos();
            // }  

            if(this.state.list.length > 0){
                if(this.state.tipo_chamada === TIPO_CHAMADA_MARKERCLICK_VEICULO ||this.state.tipo_chamada === TIPO_CHAMADA_PLUSCLICK)
                    this.state.mapaKseg.setCoords(this.state.list, coords, lastPosition, markerEvento_list);
                else if(this.state.tipo_chamada === TIPO_CHAMADA_RECARREGARAUTOMATICO_VEI_SELECIONADO)    
                    this.state.mapaKseg.pushCoords(this.state.list, coords, markerEvento_list, this.state.veiculo);
                
                // console.log(" ***PRIMEIRA POSICAO getPOsicoesVeiculo: ");
                // console.log(this.state.list[0]);
                this.state.mapaKseg.setPosicoesVeiculo(this.state.list);
                // this.state.mapaKseg.setState({polyline_list: polyline_list}); 
            }
        }
    };
    //--------------------------
    async componentDidMount() {
        // ToastStore.success("getPosicoesVeiculo componentDidMount()");
        this.getPosicoes();
    };

}