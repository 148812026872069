import  {Component} from 'react';
import api from '../api';

export default class GetRotaMunicipio extends Component{
    constructor(props, objeto, MapaKseg,tipo, rota_id){
        super(props);
        this.state = {
             list: [],
             objeto: objeto,
             MapaKseg: MapaKseg,
             tipo:tipo,
             rota_id: rota_id,
       }
    }
    //------------------------------------------------
    get = async () => {
        try {
            let body = new FormData()
            if(this.state.municipio_id !==0) 
                body.append('rota_id', this.state.rota_id);
            body.append('token', localStorage.getItem('token'))

            const response = await api.post('/cadastro_api/getRotaMunicipio', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let obj = null;
                let list = this.state.list;                
                
                for (var i = 0; i < resposta.length; i++) {                        
                    obj = {    
                        index: i+1,
                        rota_municipio_id: resposta[i].rota_municipio_id,   
                        rota_id: resposta[i].rota_id,   
                        municipio_id: resposta[i].municipio_id,                        
                        municipio: resposta[i].municipio,
                        operacao:0,
                    };                   

                    list.push(obj);
                    this.setState({list: list});
                }                
            }
        } finally {      
            this.state.objeto.setListRotaMunicipio(this.state.list, this.state.tipo);
            }
    };
}