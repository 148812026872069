import React from 'react';
import Select from 'react-select';
import {ToastContainer, ToastStore} from 'react-toasts';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];



class FilterSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      list: props.list,
      placeholder: "Pesquisar...",
      menuPlacement: "bottom"  // top
    };
  }
  //-------------------
  onLoadList = () => {

  };
  async componentDidMount() {
    // this.setState({list: this.props.list})
    // this.setState({list: this.props.MapaKseg.state.filter_list});

    // ToastStore.success("length: "+ String(this.props.list.length));

    // ToastStore.success("FilterSelect ");

    if(this.props.tipo ===3 || this.props.tipo ===4 || this.props.tipo ===5 || this.props.tipo ===6){
      this.setState({placeholder: this.props.placeholder, menuPlacement: this.props.menuPlacement});
    }
  }     
  //-------------------
  handleChange = selectedOption => {
    this.setState(
      { selectedOption },
      () => console.log('Option selected:', this.state.selectedOption)
    );

    // ToastStore.success(selectedOption.value);
    
    let item = {name: selectedOption.value};
    // ToastStore.success(" veiculo.temperaturas_tab: "+veiculo.name);
    if(this.props.tipo ===1)
      this.props.MapaKseg.onMarkerClick(item);
    else if(this.props.tipo ===2){
      this.props.RelPosicoesVeiModal.setVeiculoId(item.name, selectedOption.label);
    }
    else if(this.props.tipo ===3){
      this.props.RelColetaEntregaModal.setCliente(item.name);
    }
    else if(this.props.tipo ===4){
      this.props.RelColetaEntregaModal.setFilial(item.name);
    }
    else if(this.props.tipo ===5){
      this.props.RelColetaEntregaModal.setUsuario(item.name);
    }else if(this.props.tipo ===6){
      this.props.RelColetaEntregaModal.setRota(item.name);
    }
  };
  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.state.list}
        placeholder={this.state.placeholder}
        color='#229558'
        menuPlacement={this.state.menuPlacement}
      />
    );
  }
}
  
export default FilterSelect;


// SERVER,0,191.252.64.8,9092,0#