import React from 'react';
import Chart, {
  ArgumentAxis,
  Legend,
  Series,
  ValueAxis,
  Label,
  Export,
  Tick,
} from 'devextreme-react/chart';

// import dados from '../dados/veiculos_kmpercorrido_data.js';

// const customizeText = (e) => `Day ${e.value}`;
const KmcustomizeText = (e) => `${e.value} km`;

export class QuantidadeEventosGrafico extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  //-----------------
  render() {
    return (
      <Chart
        title="Quantidade de Eventos gerados"
        // dataSource = {dados.getDados()}
        dataSource = {this.props.list}
        rotated={true}
        id="chart"
      >
        <ArgumentAxis>
          {/* <Label customizeText={customizeText} /> */}
          <Label />
        </ArgumentAxis>

        <ValueAxis>
          <Tick visible={false} />
          <Label visible={false} />
        </ValueAxis>

        <Series
          valueField="quantidade_eventos"
          argumentField="placa"
          type="bar"
          // color="#79cac4"
          color="#C334FD"
        >
          <Label
            visible={true}
            // customizeText={KmcustomizeText}
            // backgroundColor="#c18e92"
            backgroundColor="#b789c9"
          />
        </Series>

        <Legend visible={false} />

        <Export enabled={true} />
      </Chart>

    );
  }
}
