import React, {Component} from 'react';
import RangeSelector, { Margin, Scale, Label, Behavior, Format } from 'devextreme-react/range-selector';
import DataGrid from 'devextreme-react/data-grid';
import {Form, Row, Col} from 'react-bootstrap';
import './estilo.css';
import { ToastStore} from 'react-toasts';
import api from './api';

const employees = [
    {temperatura: -30},
    {temperatura: -29},
    {temperatura: -28},
    {temperatura: -27},
    {temperatura: -26},
    {temperatura: -25},
    {temperatura: -24},
    {temperatura: -23},
    {temperatura: -22},
    {temperatura: -21},
    {temperatura: -20},
    {temperatura: -19},
    {temperatura: -18},
    {temperatura: -17},
    {temperatura: -16},
    {temperatura: -15},
    {temperatura: -14},
    {temperatura: -13},
    {temperatura: -12},
    {temperatura: -11},
    {temperatura: -10},
    {temperatura: -9},
    {temperatura: -8},
    {temperatura: -7},
    {temperatura: -6},
    {temperatura: -5},
    {temperatura: -4},
    {temperatura: -3},
    {temperatura: -2},
    {temperatura: -1},
    {temperatura: 0},
    {temperatura: 1},
    {temperatura: 2},
    {temperatura: 3},
    {temperatura: 4},
    {temperatura: 5},
    {temperatura: 6},
    {temperatura: 7},
    {temperatura: 8},
    {temperatura: 9},
    {temperatura: 10},
    {temperatura: 11},
    {temperatura: 12},
    {temperatura: 13},
    {temperatura: 14},
    {temperatura: 15},
    {temperatura: 16},
    {temperatura: 17},
    {temperatura: 18},
    {temperatura: 19},
    {temperatura: 20},

  ];

  
export class CadFaixaForm extends Component{
    constructor(props) {
        super(props);
        this.state = {
            faixa: {},
            list:[],
            loading:true,
            nome:null,
            faixa_min:-10,
            faixa_max: 10
        };
        this.filterEmployees = this.filterEmployees.bind(this);
        this.handleChange = this.handleChange.bind(this);
      }
    //------------------ 
    filterEmployees({ value }) {
        this.setState({
            faixa_min: value[0],
            faixa_max: value[1]
            // faixa_min: employees.filter(employee => employee.temperatura >= value[0] && employee.temperatura <= value[1] || !value.length)
        });
        // this.toastFaixa();

        this.props.cadastroFaixas.setFaixa(value[0], value[1]);
      }
    //----------------
    toastFaixa = () =>{
        ToastStore.success("faixa_min: "+ String(this.state.faixa_min)+"  faixa_max: "+String(this.state.faixa_max));
    }  
    //------------------
    handleChange(event) {
        // this.setState({value: event.target.value});
        this.props.cadastroFaixas.setNome(event.target.value);
      }
    //------------------
      render(){
          return(
              <div className="espaco_topo">
                <Form  className="espaco_esq">

                    <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                        Nome:
                        </Form.Label>
                        <Col sm="5">
                        <Form.Control type="name" placeholder="Nome da faixa" onChange={this.handleChange} />
                        </Col>
                    </Form.Group>

                    <React.Fragment >
                        <RangeSelector
                            id="range-selector"
                            title="Faixa"
                            dataSource={employees}
                            dataSourceField="temperatura"
                            onValueChanged={this.filterEmployees}
                        >
                            <Margin top={20} />
                            <Scale tickInterval={1} minorTickInterval={1}>
                                <Label>
                                    <Format type="decimal" />
                                </Label>
                            </Scale>
                            <Behavior callValueChanged="onMoving" />
                        </RangeSelector>
                        
                    </React.Fragment>

                </Form>
              </div>
          );
      }

}    