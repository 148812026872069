import React, { useEffect, useRef } from 'react';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import Chart, {
  AdaptiveLayout, Size,   Series,
  CommonSeriesSettings,
  Legend,
  ValueAxis,
  Title,
  Export,
  Tooltip,
} from 'devextreme-react/chart';
import PivotGrid, { FieldChooser } from 'devextreme-react/pivot-grid';
import sales from '../dados/sales_data.js';

import service from '../dados/jornada_linha_tempo_data.js';

const dataSourceMale = service.getMaleAgeData();
function customizeItems(items) {
  const sortedItems = [];
  items.forEach((item) => {
    const startIndex = item.series.stack === 'male' ? 0 : 3;
    sortedItems.splice(startIndex, 0, item);
  });
  return sortedItems;
}
// ----------------------------

const customizeTooltip = (args) => {
  const valueText = args.seriesName.indexOf('Total') !== -1
    ? new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(
      args.originalValue,
    )
    : args.originalValue;
  return {
    html: `${args.seriesName}<div class='currency'>${valueText}</div>`,
  };
};
const JornadaEventosChartGrid = () => {
  const chartRef = useRef(null);
  const pivotGridRef = useRef(null);

  useEffect(() => {
    pivotGridRef.current.instance.bindChart(chartRef.current.instance, {
      dataFieldsDisplayMode: 'splitPanes',
      alternateDataFields: false,
    });
  }, []);



  return (
    <React.Fragment>
      <Chart
      ref={chartRef}
      id="chart"
      dataSource={dataSourceMale}
      rotated={true}      
    //   height={50}
      // width={100}
      // height ="100"
    >
        <Size height={320} />
      <CommonSeriesSettings
        argumentField="state"
        type="stackedbar"
      />
      <Series
        valueField="maleyoung"
        name="Male: 0-14"
        stack="male"
        color="#98d4b4"
      />
      <Series
        valueField="malemiddle"
        name="Male: 15-64"
        stack="male"
        color="#e8d979"
      />
      <Series
        valueField="maleolder"
        name="Male: 65 and older"
        stack="male"
        color="#98d4b4"
      />

     <Series
        valueField="femaleyoung"
        name="Male: 15-164"
        stack="male"
        color="#e8d979"
      />
      <Series
        valueField="femalemiddle"
        name="Male: 15-164"
        stack="male"
        color="#98d4b4"
      />
{/* 
      <Legend
        position="inside"
        columnCount={2}
        customizeItems={customizeItems}
        horizontalAlignment="right"
      >
        <Border visible={true} />
      </Legend> */}
      <Export enabled={false} />
      {/* <Tooltip enabled={true} /> */}
      <Legend visible={false} />
      
        <CommonSeriesSettings type="bar" />
        <AdaptiveLayout width={450} />
      </Chart>

      <PivotGrid
        id="pivotgrid"
        dataSource={dataSource}
        allowSortingBySummary={true}
        allowFiltering={true}
        showBorders={true}
        showColumnTotals={false}
        showColumnGrandTotals={false}
        showRowTotals={false}
        showRowGrandTotals={false}
        ref={pivotGridRef}
      >
        <FieldChooser
          enabled={true}
          height={400}
        />
      </PivotGrid>
    </React.Fragment>
  );
};
const dataSource = new PivotGridDataSource({
  fields: [
    {
      caption: 'Region',
      width: 120,
      dataField: 'region',
      area: 'row',
      sortBySummaryField: 'Total',
    },
    {
      caption: 'City',
      dataField: 'city',
      width: 150,
      area: 'row',
    },
    {
      dataField: 'date',
      dataType: 'date',
      area: 'column',
    },
    {
      groupName: 'date',
      groupInterval: 'month',
      visible: false,
    },
    {
      caption: 'Total',
      dataField: 'amount',
      dataType: 'number',
      summaryType: 'sum',
      format: 'currency',
      area: 'data',
    },
    {
      summaryType: 'count',
      area: 'data',
    },
  ],
  store: sales,
});
export default JornadaEventosChartGrid;
