import  {Component} from 'react';
import api from '../api';

export default class GetGruposTipo extends Component{
    constructor(props, objeto, tipo_grupo_id){
        super(props);
        this.state = {
             list: [],
             objeto: objeto,
             tipo_grupo_id: tipo_grupo_id
       }
    }
    //------------------------------------------------
    get = async () => {
        try {
            let body = new FormData()
            body.append('tipo_grupo_id', this.state.tipo_grupo_id)
            body.append('token', localStorage.getItem('token'))

            const response = await api.post('/cadastro_api/getGruposTipo', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let obj = {};
                let list = this.state.list; 

                for (var i = 0; i < resposta.length; i++) {                        
                    obj = {    
                        index: i+1,
                        grupo_id: resposta[i].grupo_id,
                        nome: resposta[i].nome,              
                    };

                    list.push(obj);
                    this.setState({list: list});
                }                                
            }
        } finally {    
            this.state.objeto.setList(this.state.list);  
        }
    };
}