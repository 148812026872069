import React, {Component} from 'react';
import {Modal, Image, Spinner, Button, Form, Row,Col,Card,Nav,InputGroup,FormControl} from 'react-bootstrap';
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import GetLocais from '../services/get_locais';
import GetCliente from '../services/get_cliente';
import GetMunicipio from '../services/get_municipio';
import GetPessoa from '../services/get_pessoa';
import PostClienteCad from '../services/post_cliente_cad';
import { Switch } from 'devextreme-react/switch';
import { dinheiroMask } from '../dinheiro_mask'

const PESSOA =1;
const LOCAL =2;
const MUNICIPIO =3;
const IMAGEM =4;
 

export class ClienteCadModal extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            load:true,
            minimizar:false,
            changed:false,
            cliente:null,

            GeralListModal:null,            
            Grid:null,

            height_modal:560,
            height_grid:0,
            items:[],
            editados: {},

            salvando:false,            

            tipo_pessoa_cliente_list:[{descricao:"Jurídica", selecionada:false},{descricao:"Física", selecionada:false}],
            // tipo_pessoa_cliente: "Jurídica"

            tipo_pessoa_cliente: "",
            _list_municipio: [],

            tipo_pagamentos: [{tipo_pagamento_id: 1, descricao:"CIF"},{tipo_pagamento_id: 2, descricao:"FOB"}
                , {tipo_pagamento_id: 3, descricao:"AR CIF"}, {tipo_pagamento_id: 4, descricao:"AR FOB"}
                , {tipo_pagamento_id: 5, descricao:"PIX CIF"}, {tipo_pagamento_id: 6, descricao:"PIX FOB"} ],
            
            hint_tipo_pessoa: "Tipo Pessoa",
        };

         // -------------------------------
        this.dataGridRef = React.createRef();
    
        this.selectedRowsData = [];

        this.getSelectedData = () => {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData();
            this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            });
        }
      }
    //---------------------------------------------------------------
    get dataGrid() {
        return this.dataGridRef.current.instance;
    }
    //-------------------------------------------------------------------
    async componentDidMount() {
        // INICIALIZAR VARIÁVEIS

        this.setState({Grid: this.props.objeto});

        let editados = {
              nome:false
            , local:false
            , pessoa:false
            , municipio:false
            , imagem:true
            , telefone:false
            , juridica: false
            , cnpj_cpf:false
            , email:false
            , tipo_pagamento: false
            , tda:false
             };

        let cliente = this.props.obj_selected;

        let tipo_pessoa_cliente_list = this.state.tipo_pessoa_cliente_list;
        let tipo_pessoa_cliente = this.state.tipo_pessoa_cliente;

        if(cliente.cliente_id===0){
            cliente.juridica = "true";
            editados.juridica = true;
        }

        if(cliente.juridica)
            tipo_pessoa_cliente_list[0].selecionada =true;
        else if(!cliente.juridica)
            tipo_pessoa_cliente_list[1].selecionada =true;
        

        this.setState({
            cliente: cliente       
                ,editados: editados        
                , height_grid: this.state.height_modal-420},
            () => { 
                this.setState({load:false}) }
          )
    }; 
    //----------------------------------------------------------------
    getCliente = () =>{
        var instance = new GetCliente(null, this, this.props.MapaKseg,0, this.state.cliente.cliente_id );
        instance.get();   
    };    
    //--------------------------------------------------------------------
    setListCliente = (_list) => {
        let cliente = this.state.cliente;
        let obj = _list[0];

        cliente.cliente_id = obj.cliente_id;
        cliente.nome = obj.nome;
        cliente.local_id = obj.local_id;
        cliente.local = obj.local;
        cliente.pessoa_id = obj.pessoa_id;
        cliente.nome_social = obj.nome_social;
        cliente.municipio_id = obj.municipio_id;
        cliente.municipio = obj.municipio;
        cliente.tda = obj.tda;

        this.setState({cliente: cliente});
    } 
    //------------------------------------------------------------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closeClienteCadModal();  
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeClienteCadModal();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarClienteCadModal();
    };    
    //------------------------------------------------------
    updateInputValue(event) {
        var editados = this.state.editados;
        var cliente = this.state.cliente;

        if(event.target.id==="formNome"){
            cliente.nome = event.target.value;
            editados.nome = true;
        }else 
        if(event.target.id==="formLocal"){
            cliente.local_id = event.target.value;
            editados.local = true;
        }else 
        if(event.target.id==="formPessoa"){
            cliente.pessoa_id = event.target.value;                     
            editados.pessoa = true;
        }else 
        if(event.target.id==="formMunicipio"){
            cliente.municipio_id = event.target.value;                     
            editados.municipio = true;
        }else 
        if(event.target.id==="formTelefone"){
            if(String(event.target.value).length <= 11){
                cliente.telefone = event.target.value;                     
                editados.telefone = true;
            }
        }else 
        if(event.target.id==="formCnpjCpf"){
            if(String(event.target.value).length <= 14){
                cliente.cnpj_cpf = event.target.value;                     
                editados.cnpj_cpf = true;
            }
        }else 
        if(event.target.id==="formEmail"){
            cliente.email = event.target.value;                     
            editados.email = true;            
        }else 
        if(event.target.id==="formTDA"){
            cliente.tda = event.target.value;                     
            editados.tda = true;            
        }

        this.setState({cliente: cliente});
        this.setState({editados: editados, changed: true});
     }
    //-------------------------------------------------------------------
    showGeralList= (e) => {   
        if(e.target.getAttribute('value') == LOCAL)
            this.props.Modals.showGeralList(this,"Local", LOCAL); 
        else
        if(e.target.getAttribute('value') == PESSOA)
            this.props.Modals.showGeralList(this,"Pessoa", PESSOA);     
        else
        if(e.target.getAttribute('value') == MUNICIPIO)
            this.props.Modals.showGeralList(this,"Município", MUNICIPIO);     
        else
        if(e.target.getAttribute('value') == IMAGEM)
            this.props.Modals.showImageCropperModal(this,"Imagem", IMAGEM);              
    };
    //-----------------------------------------------------
    getList= (geralListModal, tipo) => { 
        this.setState({GeralListModal: geralListModal});  

        if(tipo == LOCAL){             
            var instance = new GetLocais(null, this, this.props.MapaKseg, LOCAL, 0);
            instance.get(); 
        }else
        if(tipo == PESSOA){             
            var instance = new GetPessoa(null, this, this.props.MapaKseg, PESSOA, 0);
            instance.get(); 
        }else
        if(tipo == MUNICIPIO){             
            var instance = new GetMunicipio(null, this, this.props.MapaKseg, MUNICIPIO, 0);
            instance.get(); 
        }
    };
    //-------------------------------------------------------------
    setListLocal =  (_list, tipo) => {  
        let array =[]
        let sel=false;
        for (var i = 0; i < _list.length; i++) { 
            if(this.state.cliente.local_id == _list[i].local_id) sel=true;
            else sel=false;

            array.push({
                index: _list[i].index
                , id: _list[i].local_id
                , descricao: _list[i].descricao
                , selecionada: sel
                , index_scroll: 0});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //-------------------------------------------------------------
    setListPessoa =  (_list, tipo) => {  
        let array =[]
        let sel=false;
        for (var i = 0; i < _list.length; i++) { 
            if(this.state.cliente.pessoa_id == _list[i].pessoa_id) sel=true;
            else sel=false;

            array.push({
                index: _list[i].index
                , id: _list[i].pessoa_id
                , descricao: _list[i].nome_social
                , selecionada: sel
                , index_scroll: 0});
        }
        this.state.GeralListModal.setList(array, tipo);
    };  
    //-------------------------------------------------------------
    setListMunicipio =  (_list, tipo) => {  
        this.setState({_list_municipio: _list})
        let array =[]
        let sel=false;
        for (var i = 0; i < _list.length; i++) { 
            if(this.state.cliente.municipio_id == _list[i].municipio_id) sel=true;
            else sel=false;

            array.push({
                index: _list[i].index
                , id: _list[i].municipio_id
                , descricao: _list[i].descricao
                , selecionada: sel
                , index_scroll: 0});
        }
        this.state.GeralListModal.setList(array, tipo);
    };   
    //---------------------------------------------------
    setObjeto= (objeto, tipo) => { 
        if(tipo == LOCAL){
            let cliente = this.state.cliente;
            cliente.local_id = objeto.id;
            cliente.local = objeto.descricao;            
            this.setState({cliente: cliente});            

            let editados =this.state.editados;
            editados.local =  true;
            this.setState({editados: editados });
        }else
        if(tipo == PESSOA){
            let cliente = this.state.cliente;
            cliente.pessoa_id = objeto.id;
            cliente.nome_social = objeto.descricao;            
            this.setState({cliente: cliente});            

            let editados =this.state.editados;
            editados.pessoa =  true;
            this.setState({editados: editados });

        }else
        if(tipo == MUNICIPIO){
            let cliente = this.state.cliente;
            cliente.municipio_id = objeto.id;
            cliente.municipio = objeto.descricao;    
            
            // -- Já setar por default a localizao do municipio no cadastro da coleta
            let _list_municipio = this.state._list_municipio;
            let l = _list_municipio.filter((item,index) =>{ return parseInt(item.municipio_id) === parseInt(cliente.municipio_id)}); 
            cliente.local_id = l[0].local_id;
            cliente.local = l[0].local;   

            this.setState({cliente: cliente});            

            let editados =this.state.editados;
            editados.municipio =  true;
            editados.local =  true;
            this.setState({editados: editados });

        }

        this.setState({changed:true});
    };
    //---------------------------------------------
    setTDA(event) {
        let cliente = this.state.cliente;
        cliente.tda =  dinheiroMask(event.target.value);            
        this.setState({cliente: cliente});

        let editados = this.state.editados;
        editados.tda = true;
        this.setState({editados: editados, changed: true});
    }
    //--------------------------------------
    concluido=()=> {
        this.getCliente();
        this.setState({salvando:false, changed:false });     
        this.state.Grid.setCliente(this.state.cliente);  
        this.fecharModal();
    }
    //--------------------------------------
    validar=()=> {
        let valido = true;

        if(String(this.state.cliente.nome).trim()==="" && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Cliente!");
        }

        if(this.state.cliente.local_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Local!");
        }         

        if(this.state.cliente.pessoa_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe a Pessoa!");
        } 

        if(this.state.cliente.municipio_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Município!");
        } 
    
        // if(this.state.cliente.tipo_pagamento_id==0 && valido){
        //     valido=false;            
        //     this.props.MapaKseg.toastError("Informe o Pagamento!");
        // } 

        if(valido==true){
            this.setState({salvando:true, changed:true});
            var instance = new PostClienteCad(null, this, this.props.MapaKseg);
            instance.post();            
        }
    }
      //--------------------------  
      onValuePessoaClienteChanged= (e) => {
          let cliente = this.state.cliente;
        if(e.target.value === "Jurídica")
            cliente.juridica = "true";            
        else if(e.target.value === "Física")
            cliente.juridica = "false";

        let editados = this.state.editados;
        editados.juridica = true;
        this.setState({cliente: cliente, editados: editados, changed: true});
    }   
    //-----------------------------------------------
    setPagamento(event) {        
        let list_aux = this.state.tipo_pagamentos.filter((item,index) =>{ return item.tipo_pagamento_id === parseInt(event.target.value)});
        if(list_aux.length > 0){
            let cliente = this.state.cliente;
            cliente.tipo_pagamento_id =  list_aux[0].tipo_pagamento_id;         
            cliente.tipo_pagamento=  list_aux[0].descricao;         
            

            this.setState({cliente: cliente});

            let editados = this.state.editados;
            editados.tipo_pagamento = true;
            this.setState({editados: editados, changed: true});
        }
    }    
    //-------------------------
    render(){
        const radioPessoaClienteList = 
            <Card style={{ height: '2.3rem' }} body>
                <Form className="top_negativo" >
                {this.state.tipo_pessoa_cliente_list.map((pessoa) => (
                    <Form.Check               
                        inline              
                        label={pessoa.descricao}
                        name="group1"
                        // name={pessoa.descricao}
                        type='radio'
                        id={pessoa.descricao}
                        // {...pessoa.descricao === "Jurídica"? checked: ""}
                        // checked
                        
                        defaultChecked={pessoa.selecionada}
                        // onClick={ (e) => { this.onCheckClick() } }

                        onChange={(e) => {
                        this.onValuePessoaClienteChanged({
                            target: {
                            name: e.target.name,
                            value: e.target.id,
                            },
                        });
                        }}
                    />
                ))}
                </Form>
            </Card>;

        return(
            <div className="drag1">
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={600}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.load ?
                                        <Spinner className="centro3" animation="border" role="status">
                                            <span className="sr-only"></span>
                                        </Spinner>
                                    :
                                        <div style={{height:"450px"}}>   
                                            <CustomScroll  flex="1">
                                                <Form>     
                                                    <Form.Group className="mb-1" as={Row} controlId="formLogo">
                                                        <Form.Label column sm={3}>Logo</Form.Label>                                                        
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >                                                         
                                                                <Button id="formLogoBt" value={IMAGEM} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                    <Image value={IMAGEM} src={this.state.cliente.imagem} width='80'/>
                                                                </Button>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group> 

                                                    <Form.Group className="mb-1" as={Row} controlId="formNome">
                                                        <Form.Label column sm={3}>
                                                            Cliente
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>{this.state.cliente.cliente_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.cliente.nome}  onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>        

                                                    <Form.Group className="mb-1" as={Row} controlId="formPessoa">
                                                        <Form.Label column sm={3}>
                                                        Pessoa
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formPessoaBt" value={PESSOA} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                        <Image value={PESSOA} src={require('../img/text_box_search48.png')} width='20'/>
                                                                    </Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text onChange={this.updateInputValue.bind(this)} >{this.state.cliente.pessoa_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control disabled aria-describedby="basic-addon1" value={this.state.cliente.nome_social}  />
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group> 

                                                    
                                                    <Form.Group className="mb-1" as={Row} controlId="formMunicipio">
                                                        <Form.Label column sm={3}>
                                                        Município
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formMunicipioBt" value={MUNICIPIO} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                        <Image value={MUNICIPIO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                    </Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text onChange={this.updateInputValue.bind(this)} >{this.state.cliente.municipio_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control disabled aria-describedby="basic-addon1" value={this.state.cliente.municipio}  />
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>   


                                                    <Form.Group className="mb-1" as={Row} controlId="formLocal">
                                                        <Form.Label column sm={3}>
                                                        Local
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formLocalBt" value={LOCAL} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                        <Image value={LOCAL} src={require('../img/text_box_search48.png')} width='20'/>
                                                                    </Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text onChange={this.updateInputValue.bind(this)} >{this.state.cliente.local_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control disabled aria-describedby="basic-addon1" value={this.state.cliente.local}  />
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>     

                                                    <Form.Group as={Row} controlId="formPagamento">
                                                        <Form.Label column sm={3}>
                                                            Pagamento
                                                        </Form.Label>
                                                        <Col sm={3}>
                                                            <InputGroup className="mb-1" >                                                        
                                                                <Form.Control as="select" aria-describedby="basic-addon1"  
                                                                    disabled={this.state.disable_pagamento}
                                                                    custom onChange={this.setPagamento.bind(this)}>
                                                                            
                                                                    {this.state.tipo_pagamentos.map(paga =>
                                                                        <option value={paga.id} >
                                                                            {paga.descricao}
                                                                        </option>
                                                                    )}
                                                                </Form.Control>
                                                            </InputGroup>
                                                        </Col>
                                              
                                                        {/* <Col sm={2}>
                                                            <InputGroup className="mb-1" >
                                                                <Switch className="padding_top" hint={this.state.hint_tipo_pessoa}  defaultValue={this.state.cliente.tipo_pessoa} value={this.state.cliente.tipo_pessoa} switchedOnText={"Jurídica"} switchedOffText={"Física"} onValueChanged={this.setPago}/>
                                                            </InputGroup>
                                                        </Col> */}

                                                        <Col sm={5}>                                                                                                                           
                                                            {radioPessoaClienteList}                                                                                                                           
                                                        </Col>
                                                        
                                                    </Form.Group>                                                                                                                 

                                                    {/* <Form.Group as={Row} controlId="formPessoaCliente">
                                                        <Form.Label column sm={3}>
                                                            Tipo Pessoa
                                                        </Form.Label>
                                                        <Col sm={5}>
                                                            {radioPessoaClienteList}
                                                        </Col>
                                                    </Form.Group>  */}

                                                    <Form.Group as={Row} controlId="formCnpjCpf">
                                                        <Form.Label column sm={3}>
                                                            CNPJ/CPF
                                                        </Form.Label>
                                                        <Col sm={5}>
                                                            <InputGroup className="mb-1" >                                                        
                                                                <Form.Control aria-describedby="basic-addon1" value={this.state.cliente.cnpj_cpf} onChange={this.updateInputValue.bind(this)} />                         
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>            

                                                    <Form.Group as={Row} controlId="formTelefone">
                                                        <Form.Label column sm={3}>
                                                            Telefone
                                                        </Form.Label>
                                                        <Col sm={5}>
                                                            <InputGroup className="mb-1" >                                                        
                                                                <Form.Control aria-describedby="basic-addon1" value={this.state.cliente.telefone} onChange={this.updateInputValue.bind(this)} />                         
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>   

                                                    <Form.Group as={Row} controlId="formEmail">
                                                        <Form.Label column sm={3}>
                                                            E-mail
                                                        </Form.Label>
                                                        <Col sm={5}>
                                                            <InputGroup className="mb-1" >                                                        
                                                                <Form.Control aria-describedby="basic-addon1" value={this.state.cliente.email} onChange={this.updateInputValue.bind(this)} />                         
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>    

                                                    <Form.Group className="mb-1" as={Row} controlId="formTDA">
                                                        <Form.Label column sm={3}>
                                                            TDA
                                                        </Form.Label>
                                                        <Col sm={3}>
                                                            <InputGroup className="mb-3" >                                                                                                        
                                                            <FormControl type="text" disabled={this.state.disable_valor}  aria-describedby="basic-addon1" value={this.state.cliente.tda} defaultValue={0} onChange={this.setTDA.bind(this)} />
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>                                       

                                                    {/* <Form.Group className="mb-1" as={Row} controlId="formLogoDIr">
                                                        <Form.Label column sm={3}>
                                                            Logo Diretório
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.cliente.imagem_dir}  onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>                                                                                                                                                                                                                                                                          */}

                                                </Form>                                                

                                            </CustomScroll>                                         

                                            <Modal.Footer >
                                                <div className="align_bottom_right">
                                                    <Button disabled={!this.state.changed} onClick={this.validar}>
                                                        {this.state.salvando
                                                            ? <Spinner size="sm"  as="span" animation="grow" role="status" aria-hidden="true"/>                                                                
                                                        :
                                                        <div>
                                                            <img src={require('../img/checkbold24.png')}/> Salvar 
                                                        </div>
                                                        }                                                                
                                                    </Button>
                                                </div> 
                                            </Modal.Footer>

                                        </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}