import React from 'react';

import 'devextreme/data/odata/store';
import DataGrid, { Column, Scrolling, Selection, MasterDetail } from 'devextreme-react/data-grid';
import './estilo.css';
import ContextMenu from 'devextreme-react/context-menu';
// import notify from 'devextreme/ui/notify';
import {GetComandosVeiculo} from './GetComandosVeiculo';
import GetPoligonos from './GetPoligonos';
import GetGruposPoligono from './GetGruposPoligono';
import ScrollView from 'devextreme-react/scroll-view';
import SelectBox from 'devextreme-react/select-box';

const statuses = ['All', 'Not Started', 'In Progress', 'Need Assistance', 'Deferred', 'Completed'];


export class GridAreas extends React.Component {
  constructor(props) {
    super(props);
    // this.onRowPrepared = this.logEvent.bind(this, 'RowInserting');
    this.onRowPrepared = this.onRowPrepared.bind(this); 

    this.state = {
      countNotificacao: 0,
      props: props,
      GridVeiculoDetail: null,
      items:[],
      loading:true,
      filterStatus: 'tesste',
      grupo_id:0,
      gruposPoligono_list: [],
      grupos_list: [],
    }

  // -------------------------------
    this.dataGridRef = React.createRef();
 
    this.selectedRowsData = [];

    this.getSelectedData = () => {
        this.selectedRowsData = this.dataGrid.getSelectedRowsData();

        // ===== or when deferred selection is used =====
        this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            // Your code goes here
        });
    }
    //-----
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onRowExpanding = this.onRowExpanding.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);

  }
//------------------------------------------
  get dataGrid() {
    return this.dataGridRef.current.instance;
  }
//------------------------------------------
  onRowPrepared(e) {  
    // if (e.rowType !== "data")  
    //     return  
    // alert(JSONString);

    if(e.data !== undefined){
      e.rowElement.style.backgroundColor = e.data.cor;
      // if (e.data.velocidade === 0) {  
      //     e.rowElement.style.backgroundColor = "#f26b7d";
      // }else e.rowElement.style.backgroundColor = "#5ed194";

      // if(e.data.diff_minutes>=60)
      //   e.rowElement.style.backgroundColor = "#b3afaf";
    }  

    // if (e.rowType === "data")  
    //   e.data.selectRows([5, 10, 12], true);
     
  } 
  //---------------------------------------------------------------
  addMenuItems(e) {
    if (e.target == 'header') {
        // e.items can be undefined
        if (!e.items) e.items = [];

        // Add a custom menu item
        e.items.push({
            text: 'Log Column Caption',
            onItemClick: () => {
                // alert(e.column.caption);
                // console.log(e.column.caption);
            }
        });
    }
  }
  //-------------------------------------------------------------------
  ItemTemplate (e) {
    return (
      <React.Fragment>
        <span className={ e.icon } />
        { e.items ? <span className="dx-icon-spinright" /> : null }
        { e.text }
      </React.Fragment>
    );
  }
  //---------------------------------------------------------------
  itemClick = (e) => {
    if (!e.itemData.items) {
      // console.log(e);
      // alert(e);
      // notify(`The "${ e.itemData.text }" item was clicked`+" shitt", 'success', 1500);     
      // alert(e.itemData.text);   
      var selectedRowData = this.dataGrid.getSelectedRowsData(); 
      // console.log(selectedRowData);
      // alert(selectedRowData);   
    //   this.props.MapaKseg.setState({veiculoPosicao_selecionado: selectedRowData[0]});

      if(e.itemData.text === this.state.items[0].text){
        this.props.MapaKseg.state.Modals.showCadCliente(selectedRowData[0]);
        // var instance = new GetComandosVeiculo(selectedRowData[0], this.props.MapaKseg); 
        // instance.getComandosVei();   

      }else if(e.itemData.text === this.state.items[1].text){
        this.props.MapaKseg.state.Modals.showCadCliente(selectedRowData[0]);
      }




      // console.log(selectedRowData);
      // alert(selectedRowData[0].recebimento_id);
    //   this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
    //     // Your code goes here
    //     alert("shitt yeah");
    //   });

    }
  }
  //--------------------------------------------------------------
  onSelectionChanged({ selectedRowsData }) {
    // console.log("selectedRowsData: "+ selectedRowsData.length);
    // console.log(selectedRowsData);
    
    if(selectedRowsData.length>0){
      let obj = selectedRowsData[0].dados[0];
      this.props.MapaKseg.ajustarZoom(obj);
      this.props.MapaKseg.setState({selectedPolygon: obj});
    }
  }
  //-------------------------------------------------------------
  onRowExpanding= (e) => {
    e.component.selectRows(e.key, false);
    // this.props.MapaKseg.ajustarZoom(selectedRowsData[0]);
  }
  //-----------------------------------------
  getPoligonos = () => {
    var instance = new GetPoligonos(this.props.MapaKseg, this.state.grupo_id, "", null);
    instance.getPoligonos();
  }
  //---------------------------------------------------------------
  componentDidMount() {
    // var instance = new GridVeiculoDetail(this.props, this.props.MapaKseg);
    // this.setState({GridVeiculoDetail: instance});

    // this.props.MapaKseg.toast(" Iniciado GridClientes");

    var items = this.state.items;

    if(this.props.MapaKseg.state.pessoa_id===1)
      items.push({ text: ' Cadastro Área', icon: "dx-icon-activefolder"  });
      items.push({ text: ' Nova Área', icon: "dx-icon-add"  });

    var gruposPoligono = new GetGruposPoligono(this.props.MapaKseg.state.usuario_id, this);
    gruposPoligono.getGruposPoligono();

    this.setState({items: items});
  };
  //--------------------------------------------------------------
  onValueChanged({ value }) {
    console.log("value:");
    console.log(value);
    console.log(value.trim());
    var gruposPoligono_list = this.state.gruposPoligono_list;
    var list_aux = gruposPoligono_list.filter((item,index) =>{  
      return item.nome.trim() === value.trim();
    })

    console.log("list_aux:");
    console.log(list_aux);

    // if (value === 'All') {
    //   dataGrid.clearFilter();
    // } else {
    //   dataGrid.filter(['nome', '=', value]);
    // }

    this.setState({filterStatus: value, grupo_id: list_aux[0].grupo_id});
    this.props.MapaKseg.setState({selectedPolygon: {}});
    this.getPoligonos();
  }
  //---------------------------------------------------------------
  render() {
    const onContextMenuPreparing = (e) =>{
        try{
          e.component.selectRows(e.row.key, false)
        //   var selectedRowData = this.dataGrid.getSelectedRowsData();
        //   this.props.MapaKseg.ajustarZoom(selectedRowData[0]);

          // console.log(e.row.key);
          // alert(e.row.key);
        }catch (response){}

      // if (e.target !== "content") 
      //     return;
      // // e.items = [];
      // if(e.row){
      //   e.component.selectRows(e.row.key, false)
      //   var selectedRowData = this.dataGrid.getSelectedRowsData();
      //   this.props.MapaKseg.ajustarZoom(selectedRowData[0]);

      //   console.log(e.row.key);
      //   alert(e.row.key);
      // }
    }
    
    return (      
        this.state.loading ? "":
          <div>
            
            <div className="left-side">
              <SelectBox
                // items={
                //   this.state.gruposPoligono_list.map((grupo) =>
                //       grupo.nome+'  '
                //   )}
                items={this.state.grupos_list}
                value={this.state.filterStatus}
                onValueChanged={this.onValueChanged} 
                />
            </div>
            <div className="right-sidee">
                  <div className="logo">
                    &nbsp;
                  </div>
            </div>

            <ContextMenu
                dataSource={this.state.items}
                width={180}
                target="#dtGridAreas"
                itemRender={this.ItemTemplate}
                onItemClick={this.itemClick} />
            
            <DataGrid
              id="dtGridAreas"
              dataSource={this.props.MapaKseg.state.poligonos_list}
              showBorders={true}
              onRowPrepared={this.onRowPrepared}
              onContextMenuPreparing={onContextMenuPreparing}
              ref={this.dataGridRef}
              onSelectionChanged={this.onSelectionChanged} 
              height={480}   
            //   onRowExpanding={this.onRowExpanding}    
              >

                <Selection mode="single" />

                
              
            </DataGrid>
          </div>     
    );
  }
}
