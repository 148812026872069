import React, {Component} from 'react';
import {Modal, Spinner} from 'react-bootstrap';
import './estilo.css';
import {LineChartTemp} from './LineChartTemp';
import {GetTemperaturasVei} from './GetTemperaturasVei';
// import mergeImages from 'merge-images';
 
export class RenderTemperatura extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true, 
            showSheet:true,
            tempGridModalShow:false,
            clockVisibility:"not-visible",
            imagem_carreta:null,
            sensorr1: null,
            sensorr2: null,
            sensorr3: null,
            sensores:null,
            renderLoading: true,
            temperaturaList:null,
            list: [],
            nomeFaixa: "llalala",
            minLine: "",
            maxLine: "",
            // dialogClassNn:"temperatura-modal",
        };
      }
      //--------------------
      async componentDidMount() {
        // ToastStore.success("componentDidMount()");
        // if(this.props.mapaKseg !== null)
        //     this.props.mapaKseg.setState({lineChartTemp: this});
            
        var instance = new GetTemperaturasVei(this.props, this, null); 
        instance.getTemperaturas(this.props.veiculo_id);
    };
    //--------------------
    setFaixaData = (nomeFaixa, minLine, maxLine) => {
        this.setState({nomeFaixa:nomeFaixa
                    , minLine: minLine
                    , maxLine: maxLine});
    }
    //---------------------
    render(){
        return (
            <div>
                {/* {this.props.splashScreenModalShow ?
                    ""
                    : */}                    
                        {this.state.renderLoading  ?  
                            <div className="centro">
                                <Spinner animation="border"/>
                            </div>
                        :
                            <div>
                                <div className="image_carreta" >
                                    {/* <img src='' className="abc" /> */}
                                    {/* <img src={this.state.imagem_carreta} className="carreta_center" /> */}
                                    <img src={'img/carreta_temperatura/carreta_'+this.state.sensores+'.jpg'} className="carreta_center2" />
                                    {/* <img src={'img/carreta_temperatura/carreta_'+'010'+'.jpg'}  className="carreta_center" /> */}
                                    <table className="table_porcima2">
                                        <tr>
                                            <td className="back_sensores2">  <div className="bold">Sensor_1</div> {this.state.sensorr1} C°</td>
                                            <td className="back_sensores2">  <div className="bold">Sensor_2</div> {this.state.sensorr2} C°</td>
                                            <td className="back_sensores2">  <div className="bold">Sensor_3</div> {this.state.sensorr3} C°</td>
                                        </tr>
                                    </table>
                                                
                                </div>

                                <div className="carreta_center3" >
                                <LineChartTemp
                                    mapaKseg={this.props.mapaKseg}
                                    placa={this.props.placa}
                                    veiculo_id={this.props.veiculo_id}
                                    renderTemperatura={this}
                                    temperaturaModal={null}
                                    loading={true}
                                    temperaturaList={this.state.temperaturaList}
                                    list={this.state.list}
                                    nomeFaixa={this.state.nomeFaixa}
                                    minLine={this.state.minLine}
                                    maxLine={this.state.maxLine}
                                />
                            </div>
                        </div>
                        }
                                                                                                            
                {/* } */}
            </div>
        );
    }
}