import  {Component} from 'react';
import api from './api';
import {ToastStore} from 'react-toasts';
import Moment from 'moment';

// import {Polyline} from 'google-maps-react';

export class GetComandosVeiculo extends Component{
    constructor(props, MapaKseg){
        super(props);
        this.state = {
            comando: {},
            comandos_list: [],
             loading:true,
             MapaKseg:MapaKseg,
       }
    }
    //---------------------
    toastStore = (texto) => {
        ToastStore.success("teste: "+texto);
    };
    //---------------------
    setIcone = (tipo_comando_id) => {       
        switch(tipo_comando_id) {
            case 1:
              return require("./img/ic_aviso_cabine.png"); 
            case 2:
                return require("./img/alert_circle48.png");  
            case 3:
                return require("./img/shield_lock48.png");   
            case 4:
                return require("./img/alert_circle48.png");                       
            case 5:
                return require("./img/location_marker48.png");                     
            
            case 42:
                return require("./img/ic_panic_off.png");  
            case 43:
                return require("./img/temperature_timer.png");    
            case 44:
                return require("./img/faixa_temperatura.png");                     
            case 45:
                return require("./img/restart48.png");     
            
            case 50:
                return require("./img/account_card_search48.png"); 
            case 51:
                return require("./img/account_card_lock48.png");                 
            case 52:
                return require("./img/account_card_details48.png");                     
            case 53:
                return require("./img/account_card_add48.png");       
            case 54:
                return require("./img/account_card_remove48.png"); 
            case 55:
                return require("./img/account_card_bell48.png");                 
            case 56:
                return require("./img/account_card_on48.png");                     
            case 57:
                return require("./img/account_card_off48.png");                                   
            case 58:
                return require("./img/timer48.png");                     
            case 59:
                return require("./img/timer_search48.png");  



            default:
              return require("./img/alert_circle48.png");  
          }
    };
    //--------------------
    getComandosVei = async () => {
        try {
            let body = new FormData()
            body.append('veiculo_id', this.props.veiculo_id)
            body.append('token', localStorage.getItem('token'))

            // console.log("  ***datetime: "+datetime)

            const response = await api.post('/comando_api/getComandosDoVeiculo', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);

                
                let comando = this.state.comando;
                let comandos_list = this.state.comandos_list;                

                // ToastStore.success(" Comandos length: "+ String(resposta.length));
                for (var i = 0; i < resposta.length; i++) {                        
                    comando = {    
                        tipo_comando_id: resposta[i].tipo_comando_id,
                        descricao: resposta[i].descricao,
                        descricao_icone: resposta[i].descricao_icone,
                        equipamento_id: resposta[i].equipamento_id,
                        equipamento_descricao: resposta[i].equipamento_descricao,
                        versao_equipamento_id: resposta[i].versao_equipamento_id,
                        versao: resposta[i].versao,
                        icone: null,
                        configuravel: resposta[i].configuravel,
                        opcional: resposta[i].opcional,
                        administrador: resposta[i].administrador
                    };

                    comando.icone = this.setIcone(comando.tipo_comando_id);

                    if (comando.administrador){
                        if (parseInt(localStorage.getItem('pessoa_id'))===1)  //Kanohi
                        comandos_list.push(comando);                      
                    }else comandos_list.push(comando);


                    // comandos_list.push(comando);
                    this.setState({comandos_list: comandos_list});
                }

                this.setState({loading: false});
                console.log("    ****  pessoa_id: "+ String(localStorage.getItem('pessoa_id')))
                
            }else{
            }

        } finally {
                // ToastStore.success("GetPo coords.length: "+ String(coords.length));
                // ToastStore.success("PPlaca: "+ String(lastPosition.data_receb));
                // this.state.mapaKseg.setCoords(coords, lastPosition, markerEvento_list);
                this.state.MapaKseg.setState({comandos_list: this.state.comandos_list});

            }
    };
    //--------------------------
    async componentDidMount() {
        ToastStore.success("getPosicoesVeiculo componentDidMount()");
        this.getComandosVei();
    };
}