import React, {Component} from 'react';
import {Modal, Form, Button as Button_bootstrap, Row, Col, Spinner, Carousel, Navbar, Container, Image} from 'react-bootstrap';
import '../estilo.css';
import api from '../api';
import CustomScroll from 'react-customscroll';
// import { parseString} from "xml2js";
// import service from './data.js';


export  class XmlModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true, 
            showSheet:true,
            texto: "",
            loading: true,
            img64: '',
            coleta_obj: {cliente_id: 0, cnpj: '', cnpj_result: '', nome: '', nome_result: '', cep: '', cep_result: '' ,municipio_id:0 , municipio: '',municipio_result: ''},
            entrega_obj: {cliente_id: 0, cnpj: '', cnpj_result: '', nome: '', nome_result: '', cep: '', cep_result: '' ,municipio_id:0, municipio: '',municipio_result: ''},
            load_xml_ok: false,
            activeIndexCarousel:0,
            height_modal:400,
            comprimindo:false,
            xml_text:"",
            render_xml_text:false,
        };
      }   
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeXmlModal();
    };
    //------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarXmlModal();
    };
    //--------------------------
    async componentDidMount() {
        // this.props.MapaKseg.toastError(this.props.obj_selected.canhoto+" - "+this.props.obj_selected.canhoto64);
        // console.log(this.props.obj_selected);
        if(this.props.tipo === "visualizar"){
                this.setState({activeIndexCarousel:0, loading: false});
                this.xmlToJson();
        }else this.setState({activeIndexCarousel:1, loading: false});

        // this.setState({texto: this.props.texto, tipo: this.props.tipo});
        // document.getElementById("ControlTextArea1").focus();
        
    };
    //---------------------------------------------------------------
    xmlToJson = ()=>{        
        // let xml = service.getOrders();
        let xml = this.state.xml_text;

        let _json = '';
        // var parseString = require('xml2js').parseString;        
        // parseString(xml, function (err, result) {
        //     console.dir(result);
        //     _json = result;
        // });

        let _coleta_obj = this.state.coleta_obj;
        _coleta_obj.cnpj = _json.cteProc.CTe[0].infCte[0].rem[0].CNPJ;
        _coleta_obj.nome = _json.cteProc.CTe[0].infCte[0].rem[0].xNome;
        _coleta_obj.cep = _json.cteProc.CTe[0].infCte[0].rem[0].enderReme[0].CEP;
        _coleta_obj.municipio = _json.cteProc.CTe[0].infCte[0].rem[0].enderReme[0].xMun;

        let _entrega_obj = this.state.entrega_obj;
        _entrega_obj.cnpj = _json.cteProc.CTe[0].infCte[0].dest[0].CNPJ;
        _entrega_obj.nome = _json.cteProc.CTe[0].infCte[0].dest[0].xNome;
        _entrega_obj.cep = _json.cteProc.CTe[0].infCte[0].dest[0].enderDest[0].CEP;
        _entrega_obj.municipio = _json.cteProc.CTe[0].infCte[0].dest[0].enderDest[0].xMun;
        

        this.setState({coleta_obj: _coleta_obj, entrega_obj: _entrega_obj},            
            () => {         
                this.getCliente("coleta", _coleta_obj);        
                this.getCliente("entrega", _entrega_obj);
                this.setState({activeIndexCarousel:0, loading: false});
            }
        )
    };
    //---------------------------------------------------------------
    getCliente = async (tipo, obj) => {
        try {       
            let body = new FormData()
            // body.append('cnpj_cpf', this.props.obj_selected.entrega_id);
            body.append('cnpj_cpf', obj.cnpj);
            body.append('municipio', obj.municipio);
            body.append('token', localStorage.getItem('token'));

            let response = await api.post('/coletaweb_api/getCliente', body);
            // let response = await api.post('/teste_api/getEntregas', body);
            
            var responseJson = null;

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);

                if(tipo === "coleta"){
                    let coleta_obj = obj;                

                    for (var i = 0; i < resposta.length; i++) {   
                        coleta_obj.cnpj_result = resposta[i].cnpj_cpf;
                        coleta_obj.cliente_id = resposta[i].cliente_id;
                        coleta_obj.nome_result = resposta[i].nome;
                        coleta_obj.municipio_id = resposta[i].municipio_id;
                        coleta_obj.municipio_result = resposta[i].municipio;
                        coleta_obj.cep_result = '';                                                          
                    }

                    this.setState({coleta_obj: coleta_obj});
                }else{
                    let entrega_obj = obj;
                    let list = this.state.list;                

                    for (var i = 0; i < resposta.length; i++) {  
                        entrega_obj.cnpj_result = resposta[i].cnpj_cpf;
                        entrega_obj.cliente_id = resposta[i].cliente_id;
                        entrega_obj.nome_result = resposta[i].nome;
                        entrega_obj.municipio_id = resposta[i].municipio_id;
                        entrega_obj.municipio_result = resposta[i].municipio;
                        entrega_obj.cep_result = '';                                                          
                    }

                    this.setState({entrega_obj: entrega_obj},            
                        () => {         
                            this.setState({load_xml_ok:true});        
                        })
                }
                
            }
        } finally {      
            this.setState({loading: false});
        }
    };
    //------------------------
    okImagem = async()=>{
        this.setState({load_xml_ok:true}); 
    };
    onInputClick = async (e) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => { 
          const text = (e.target.result)
        //   console.log(text)
        //   alert(text)
            this.setState({xml_text:text},            
                () => {         
                    // this.setState({render_xml_text:true}); 
                    this.xmlToJson();       
                });
        };
        reader.readAsText(e.target.files[0])
      }
    //---------------------------------
    //------------------------- 
    render(){
        return(
            <div className="splashscreen-modal1">
                <Modal  centered
                    {...this.props}
                    backdrop="static"
                    
                    aria-labelledby="example-custom-modal-styling-title"
                    scrollable="True"
                    dialogClassName="temperatura-modal2"    
                    >
                    <div >
                        <strong className="cursor">
                            <div className="cabecalho"> 
                            <label className="titulo2" > {this.props.titulo} </label>
                                {/* <label className="titulo2" > Aviso! </label> */}
                            </div>
                        </strong>

                        {this.state.loading ?
                            <Spinner className="centro3" animation="border" role="status"/>
                            :
                            <div >
                                <div style={{height: this.state.height_modal, overflow:'hidden'}}>
                                    <CustomScroll  scrollWidth="15px" scrollBarRadius="0">
                                        <Carousel  interval={null}  controls={false} indicators={false} activeIndex={this.state.activeIndexCarousel}>                                
                                            <Carousel.Item >                                 
                                                {/* <img src={`data:image/png;base64,${this.state.img64}`} width="450" height="500"/> */}
                                                {this.state.load_xml_ok ? 
                                                <div className="espaco3">
                                                    <Navbar bg="secondary" variant="dark">
                                                        <Container>
                                                        <Navbar.Brand href="#">Coleta</Navbar.Brand>
                                                        </Container>
                                                    </Navbar>
                                                    <Form className="espaco3">
                                                        <Form.Group className="mb-2" >
                                                            <Form.Label>CNPJ</Form.Label>
                                                            <Form.Control type="text" disabled  value={this.state.coleta_obj.cnpj} />
                                                            <Row>
                                                                <Col>
                                                                    <Form.Control type="text" disabled  value={this.state.coleta_obj.cnpj_result} /> 
                                                                </Col>
                                                                <Col xs={2}>
                                                                    {this.state.coleta_obj.cnpj_result ===""?
                                                                        <Image src={require('../img/close_thick24_red.png')} width='20' /> 
                                                                     : <Image src={require('../img/checkbold24_green.png')} width='20' />    
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        <Form.Group className="mb-2" >
                                                            <Form.Label>Nome</Form.Label>
                                                            <Form.Control type="text" disabled  value={this.state.coleta_obj.nome} />
                                                            <Row>
                                                                <Col>
                                                                    <Form.Control type="text" disabled  value={this.state.coleta_obj.nome_result} /> 
                                                                </Col>
                                                                <Col xs={2}>
                                                                    {this.state.coleta_obj.nome_result ===""?
                                                                        <Image src={require('../img/close_thick24_red.png')} width='20' /> 
                                                                     : <Image src={require('../img/checkbold24_green.png')} width='20' />    
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        <Form.Group className="mb-2" >
                                                            <Form.Label>CEP</Form.Label>
                                                            <Form.Control type="text" disabled  value={this.state.coleta_obj.cep} />
                                                            <Row>
                                                                <Col>
                                                                    <Form.Control type="text" disabled  value={this.state.coleta_obj.cep_result} /> 
                                                                </Col>
                                                                <Col xs={2}>
                                                                    {this.state.coleta_obj.cep_result ===""?
                                                                        <Image src={require('../img/close_thick24_red.png')} width='20' /> 
                                                                     : <Image src={require('../img/checkbold24_green.png')} width='20' />    
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        <Form.Group className="mb-2" >
                                                            <Form.Label>Município</Form.Label>
                                                            <Form.Control type="text" disabled  value={this.state.coleta_obj.municipio} />
                                                            <Row>
                                                                <Col>
                                                                    <Form.Control type="text" disabled  value={this.state.coleta_obj.municipio_result} /> 
                                                                </Col>
                                                                <Col xs={2}>
                                                                    {this.state.coleta_obj.municipio_result ===""?
                                                                        <Image src={require('../img/close_thick24_red.png')} width='20' /> 
                                                                     : <Image src={require('../img/checkbold24_green.png')} width='20' />    
                                                                    }
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                    </Form>

                                                    <Navbar bg="secondary" variant="dark">
                                                        <Container>
                                                        <Navbar.Brand href="#">Entrega</Navbar.Brand>
                                                        </Container>
                                                    </Navbar>
                                                    <Form className="espaco3">
                                                        <Form.Group className="mb-2" >
                                                            <Form.Label>CNPJ</Form.Label>
                                                            <Form.Control type="text" disabled  value={this.state.entrega_obj.cnpj} />
                                                            <Row>
                                                                <Col>
                                                                    <Form.Control type="text" disabled  value={this.state.entrega_obj.cnpj_result} /> 
                                                                </Col>
                                                                <Col xs={2}>
                                                                    {this.state.entrega_obj.cnpj_result ===""?
                                                                        <Image src={require('../img/close_thick24_red.png')} width='20' /> 
                                                                     : <Image src={require('../img/checkbold24_green.png')} width='20' />    
                                                                    } 
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        <Form.Group className="mb-2" >
                                                            <Form.Label>Nome</Form.Label>
                                                            <Form.Control type="text" disabled  value={this.state.entrega_obj.nome} />
                                                            <Row>
                                                                <Col>
                                                                    <Form.Control type="text" disabled  value={this.state.entrega_obj.nome_result} /> 
                                                                </Col>
                                                                <Col xs={2}>
                                                                    {this.state.entrega_obj.nome_result ===""?
                                                                        <Image src={require('../img/close_thick24_red.png')} width='20' /> 
                                                                     : <Image src={require('../img/checkbold24_green.png')} width='20' />    
                                                                    }  
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        <Form.Group className="mb-2" >
                                                            <Form.Label>CEP</Form.Label>
                                                            <Form.Control type="text" disabled value={this.state.entrega_obj.cep} />
                                                            <Row>
                                                                <Col>
                                                                    <Form.Control type="text" disabled  value={this.state.entrega_obj.cep_result} /> 
                                                                </Col>
                                                                <Col xs={2}>
                                                                    {this.state.entrega_obj.cep_result ===""?
                                                                        <Image src={require('../img/close_thick24_red.png')} width='20' /> 
                                                                     : <Image src={require('../img/checkbold24_green.png')} width='20' />    
                                                                    }   
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        <Form.Group className="mb-2" >
                                                            <Form.Label>Município</Form.Label>
                                                            <Form.Control type="text" disabled value={this.state.entrega_obj.municipio} />
                                                            <Row>
                                                                <Col>
                                                                    <Form.Control type="text" disabled  value={this.state.entrega_obj.municipio_result} /> 
                                                                </Col>
                                                                <Col xs={2}>
                                                                    {this.state.entrega_obj.municipio_result ===""?
                                                                        <Image src={require('../img/close_thick24_red.png')} width='20' /> 
                                                                     : <Image src={require('../img/checkbold24_green.png')} width='20' />    
                                                                    }   
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                    </Form>
                                                </div>
                                                    
                                                : <Spinner className="centro3" animation="border" role="status"/>}
                                                

                                                {/* {this.state.json.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.root}</td>                                         
                                                    </tr>
                                                    ))} */}
                                            </Carousel.Item>          

                                            <Carousel.Item>                                                                 
                                                    {/* <input id="fileItem" type="file" accept="image/*" onChange={this.handleChange} onClick={()=> this.onInputClick()}></input> */}
                                                    <input type="file" onChange={(e) => this.onInputClick(e)} />
                                                    {this.state.render_xml_text ?
                                                        <text>{this.state.xml_text}</text>
                                                    :""}                                                                       
                                            </Carousel.Item>   
                                            
                                        </Carousel>                                                                                     
                                    </CustomScroll>
                                </div>             
                            </div>                                
                            
                        }
                    </div>


                    <Modal.Footer>
                        {/* {this.props.cancelar ?
                            <Button_bootstrap className="left" onClick={this.props.onHide}>Cancelar</Button_bootstrap> : ""
                        } */}
                        {/* <Button_bootstrap  onClick={this.fecharClick}> Baixar</Button_bootstrap> */}
                        { this.state.img64 === '' ?
                            <Button_bootstrap onClick={this.okImagem}>
                                {this.state.comprimindo
                                    ? <Spinner size="sm" as="span" animation="grow" role="status" aria-hidden="true" />                                                                
                                    :<div> Ok </div>
                                }                                                                
                            </Button_bootstrap> : ""
                            // <Button_bootstrap  onClick={this.okImagem}> Ok</Button_bootstrap> : ""
                        }

                        { this.state.img64 !== '' ?
                            <Button_bootstrap  onClick={this.abrirImagem}> Abrir</Button_bootstrap> : ""
                        }
                        
                        <Button_bootstrap variant="outline-secondary" onClick={this.fecharModal}> Fechar</Button_bootstrap>                
                    </Modal.Footer>

                </Modal>

            </div>
        );
    }
}