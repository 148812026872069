import React, {Component} from 'react';
import {Container, Row, Col, Button, ListGroup, Card} from 'react-bootstrap';
// import {ComandoModal} from './ComandoModal';
import GetComandoVeiculo from './GetComandoVeiculo';
import EventoCard from './EventoCard';
import {ToastStore} from 'react-toasts';
 
export class RenderEventos extends Component{
    constructor(props){
        super(props);
        this.state = {
            comando: {},
            eventos_list: [],
            list: [],
            loading:true,
            comandos_list: [],
            comandoModalShow:false,
       }
    }
    //--------------------
    setEventos_list = (evt_list) => {
        this.setState({eventos_list: evt_list});
    };
    //--------------------
    showComandoModal = () => {
        this.setState({comandoModalShow:true});
    };
    //-------------------
    comandoModalClose = () =>{
        this.setState({comandoModalShow:false});
    };  
    //---------------------
    renderEventosModal = () => {
        // return(
        //     <div>
        //         <ComandoModal  
        //             show={this.state.comandoModalShow}
        //             onHide={this.comandoModalClose}
        //             comando={this.state.comando}
        //             comandoConfiguracao_list = {this.state.comandoConfiguracao_list}
        //             comandosVei_list = {this.state.comandosVei_list}
        //         />
        //     </div>);
    };
    //---------------------
    comandoClick = (comando) => {
        // this.props.mapaKseg.callToast(comando.tipo_comando_id+" - "+comando.descricao);
        this.setState({comando: comando});
        // this.showComandoModal();

        var instance = new GetComandoVeiculo(comando, this); 
        instance.getComandoVei();
    };
    //---------------------
    toastStore = () => {
        ToastStore.success("teste: ");
    };   
    //---------------------
    render(){
        return (
            
                <ListGroup className="center4">
                    {this.props.mapaKseg.state.eventos_list.map(evento =>
                                                
                            <EventoCard value={evento} MapaKseg={this.props.mapaKseg} showMapIcon={true}/>                    

                        
                        // <ListGroup.Item>{evento.descricao} </ListGroup.Item>

                        // <Card  border="primary" style={{ width: '8rem', height: '5rem' }}>
                        //     <Card.Img variant="left" src={require("./img/alert_circle48.png")} />
                        //     <Card.Body>
                        //         <Card.Text>
                        //             {evento.descricao}
                        //         </Card.Text>
                        //     </Card.Body>
                        // </Card>

                        )}
                      {/* <ListGroup.Item>Cras justo </ListGroup.Item>
                      <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                      <ListGroup.Item>Morbi leo risus</ListGroup.Item>
                      <ListGroup.Item>Porta ac consectetur ac</ListGroup.Item>
                      <ListGroup.Item>Vestibulum at eros</ListGroup.Item> */}
                </ListGroup>
            
        );
    }
}