import  {Component} from 'react';
import api from '../api';

export default class GetGrupoVeiculos extends Component{
    constructor(props, objeto, MapaKseg, grupo_id){
        super(props);
        this.state = {
             list: [],
             objeto: objeto,
             MapaKseg: MapaKseg,
             grupo_id: grupo_id
       }
    }
    //------------------------------------------------
    get = async () => {
        try {
            let body = new FormData()
            
            if(this.state.grupo_id !==0) 
                body.append('grupo_id', this.state.grupo_id)

            body.append('token', localStorage.getItem('token'))

            let response = response = await api.post('/cadastro_api/getGrupoVeiculos', body); 
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let obj = null;
                let list = this.state.list;                

                // ToastStore.success("resposta.length: "+ String(resposta.length));
                for (var i = 0; i < resposta.length; i++) {                        
                    obj = {    
                        index: i+1,
                        grupo_veiculo_id: resposta[i].grupo_veiculo_id,
                        veiculo_id: resposta[i].veiculo_id,
                        placa: resposta[i].placa,
                        operacao:0,                    
                    };                   

                    list.push(obj);
                    this.setState({list: list});
                }                
            }
        } finally {   
            this.state.objeto.setListVeiculos(this.state.list);
            }
    };
}