import React, {Component} from 'react';
import './estilo.css';
import { ToastStore} from 'react-toasts';
import Spinner from 'react-bootstrap/Spinner';
import ListGroup from 'react-bootstrap/ListGroup';
import GetGruposVeiculo from './GetGruposVeiculo';
// import ReactDOM from 'react-dom';
import {CabecalhoModal} from './CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import api from './api';

export class GruposVeiculoModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true,
            showSheet:true,
            tempGridModalShow:false,
            clockVisibility:"not-visible",
            gruposVei_list:[],
            loading:true,
            animation: "zoom",
            height_modal:0,
            scrollOffset:0,
            // dialogClassNn:"temperatura-modal",
        };
        this.anchors = {};
      }
    //------------------------
    async componentDidMount() {
        // ToastStore.success("criado GruposVeiculoModal");
        // ToastStore.success("usuario_id: "+String(this.props.usuario_id));

        var instance = new GetGruposVeiculo(this.props.usuario_id, this, this.props.MapaKseg);
        instance.getGruposVei();

        if(this.props.Modals.state.GridColetaEntrega != null)
            this.props.Modals.state.GridColetaEntrega.visibleFilterrowFalse();
      }
    //--------------------
    toastStore2 = () => {
        ToastStore.success("teste2222");
    };
  //-------------------------
    onComplete = () => {
        this.setState({clockVisibility:"visible"});
    };
    //-------------------------
    expand = () => {
         this.toastStore2();
        // this.setState({dialogClassNn:"temperatura-modal2"});
    };
    //--------------------------------------------------------------
    postGrupoVeiculoAtual = async (usuario_id, grupo_id) => {  
        try {
            let body = new FormData();
            body.append('grupo_id', grupo_id);
            body.append('usuario_id', usuario_id);
            body.append('token', localStorage.getItem('token'));

            const response = await api.post('/login_api/setGrupoVeiculoAtual', body);            
            var responseJson = null
            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);      
                
                // this.props.MapaKseg.toast("Atualizado GrupoVeiculoAtual!");
            }

        } finally {

            }
    };
    //------------------------------------------
    setGrupoId = (grupo_id)=>{
        let listaVazia = [];        
        // this.props.MapaKseg.toast(grupo_id);
        this.props.MapaKseg.setGrupoId(grupo_id);
        localStorage.setItem('grupo_id', grupo_id);
        this.props.MapaKseg.setState({ grupo_id: grupo_id}, () => {
            this.postGrupoVeiculoAtual(this.props.MapaKseg.state.usuario_id, grupo_id);
            this.props.MapaKseg.recarregarPosicoesClick();
        });
        this.props.MapaKseg.setState({ coords: [] });
        this.props.MapaKseg.setState({ markerPlus: {} });
        this.props.MapaKseg.setState({markerPosicao_list: listaVazia});
        this.props.MapaKseg.setState({markerEvento_list: listaVazia});
        this.props.MapaKseg.closeBottomSheet();
        this.fecharModal();
    }
    //-------------------
    minimizarModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "slideLeft"});
        this.props.Modals.gruposVeiculodModalClose();

        if(this.props.Modals.state.GridColetaEntrega != null)
            this.props.Modals.state.GridColetaEntrega.visibleFilterrow();
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.gruposVeiculodModalClose();

        if(this.props.Modals.state.GridColetaEntrega != null)
            this.props.Modals.state.GridColetaEntrega.visibleFilterrow();
    };
    //---------close----------
    desativarModal = () =>{
        if(this.props.show === false)
            this.props.Modals.gruposVeiculodModalDesativar();
    };
    //-------------------------
    sequenciaUpdate = () => {
        this.props.Modals.setModalSelecionado("GruposVeiculoModal");
    };
    //-------------------------
    desativarFilterrow = () =>{    
        if(this.props.Modals.state.GridColetaEntrega != null)
            this.props.Modals.state.GridColetaEntrega.visibleFilterrowFalse();
    };
    //-------------------------
    render(){
        //--------------------
        return(
            <div className="drag1" onClick={()=>this.sequenciaUpdate()}>
                <Draggable   handle="strong"  >
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={480}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >
                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false} titulo={"Grupos de Veículos"} Modal={this}/></strong>
                                <div className="padding_modal">
                                    {this.state.loading ?
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    :
                                        <div style={{height: '390px', overflow: 'hidden'}}>
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0" >
                                                <ListGroup>{
                                                    this.state.gruposVei_list.map((grupoVei) =>
                                                        grupoVei.selecionada===false ?
                                                            <ListGroup.Item action variant="" onClick={()=>this.setGrupoId(grupoVei.grupo_id)}>
                                                                <div>
                                                                    <input type="radio" className="espaco_right" />
                                                                    {grupoVei.nome+'  '}
                                                                </div>
                                                            </ListGroup.Item>
                                                        :
                                                            <ListGroup.Item action variant="dark">
                                                                <div >
                                                                    <input type="radio" className="espaco_right" checked />
                                                                    {grupoVei.nome+'  '}
                                                                    { this.state.scrollOffset===0 ?
                                                                        grupoVei.index_scroll > 70?
                                                                            this.setState({scrollOffset: grupoVei.index_scroll})
                                                                        : ""
                                                                    : ""}
                                                                </div>
                                                            </ListGroup.Item>
                                                    )}
                                                    </ListGroup>
                                                </CustomScroll>
                                            </div>
                                    }
                                </div>
                            </div>
                        </Rodal>
                    </div>
                </Draggable>
            </div>
        );
    }
}
