import React, {Component} from 'react';
import {Modal, Form, Button as Button_bootstrap} from 'react-bootstrap';
import './estilo.css';
import { ToastStore} from 'react-toasts';
import {RenderComandos} from './RenderComandos';
import {CabecalhoModal} from './CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import Spinner from 'react-bootstrap/Spinner';
import CustomScroll from 'react-customscroll';


export  class ComandoGridModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true, 
            showSheet:true,
        };
      }   
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeComandoGridModal();
    };
    //------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarComandoGridModal();
    };
    //------------------------
    okClick = ()=>{
        if(this.props.tipo_mensagem===1){
            this.fecharModal();        
        }else if(this.props.tipo_mensagem===2){ // deletar poligono
            this.props.objeto.postDeletePoligono();            
        }

        this.fecharModal();
    };
    //-------------------------  <img src={this.props.comando.icone} /> {this.props.comando.descricao}
    render(){
        return(
            <div className="drag4" >
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={500}
                            height={500}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                    {this.props.MapaKseg.state.comandos_list.length < 1 ?
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    :
                                        <div style={{height: '390px', overflow: 'hidden'}}>
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0" >
                                                {this.props.MapaKseg.state.comandos_list.length>0 ? <RenderComandos MapaKseg={this.props.MapaKseg} /> : ""}
                                            </CustomScroll>

                                        </div>    
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>                                
        );
    }
}