import React from 'react';
import { DataGrid, Column, HeaderFilter, MasterDetail, FilterRow, SearchPanel, ColumnChooser, Selection } from 'devextreme-react/data-grid';
import {Spinner, Button} from 'react-bootstrap'; 
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { locale } from 'devextreme/localization';
import service from './data.js';
import './estilo.css';
import notify from 'devextreme/ui/notify';
import {GetPosicoes} from './GetPosicoes';
import ContextMenu from 'devextreme-react/context-menu';

const tasks = service.getTasks();
const dataGridRef = React.createRef();
const saleAmountEditorOptions = { format: 'currency', showClearButton: true };
var moment = require('moment');


const items = [
  // {
  //   text: 'Share',
  //   items: [
  //     { text: 'Facebook' },
  //     { text: 'Twitter' }]
  // },
  // { text: 'Posições anteriores', icon: require("./img/command24.png") },
  { text: ' Nada', icon: "dx-icon-export"  },
];


function cellRender(data) {
  if(data.value !== null)
    return <img src={data.value} width="20"  height="20"/>;
  else return <img src={require("./img/transparent.png")} width="20"  height="20"/>;
}


class GridVeiculoDetail extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      posicoes_list:[],
      eventos_list:[],
      showFilterRow: true,
      showHeaderFilter: true,
      focusedRowKey: 1,
      backup:true,
      icone_visible:true,
      veiculo: props.data.data,
      evento:{},
      loading:true,
      instanceGetPosicoes: null,
      eventos_count: [],
      locale: this.getLocale(),
    };
    locale(this.state.locale);
    this.dataGrid = null;
    this.orderHeaderFilter = this.orderHeaderFilter.bind(this);
    this.onShowFilterRowChanged = this.onShowFilterRowChanged.bind(this);
    this.onShowHeaderFilterChanged = this.onShowHeaderFilterChanged.bind(this);
    this.onCurrentFilterChanged = this.onCurrentFilterChanged.bind(this);
    this.onTaskIdChanged = this.onTaskIdChanged.bind(this);

    this.dataSource = this.getTasks(props.data.key);
  }
  //---------------------------------------
  getLocale() {
    const storageLocale = sessionStorage.getItem('locale');
    return storageLocale != null ? storageLocale : 'pt';
  }
  //-----------------
  onTaskIdChanged(e) {
    if(e.event && e.value > 0) {
      this.setState({ focusedRowKey: e.value });
    }
  }
  //--------------------
  formatarDateTimeGrid(dtTime){
    //  sex 21:47:10   03-07-2020  
    if(dtTime===null)
        return "";
    let y = this.state.veiculo.data_receb.substring(21,25);
    let mm = this.state.veiculo.data_receb.substring(18,20);
    let dd = this.state.veiculo.data_receb.substring(15,17);
    let hm = this.state.veiculo.data_receb.substring(3,13);    
    const datetime = " "+dd+"/"+mm+"/"+y+"   "+hm;  // "Y-MM-DD H:M"
    return datetime;  
  }
    //--------------------------------------------------------------------------------
    getPosicoesUltimaParada = () => {
      let y = this.state.veiculo.data_receb.substring(21,25);
      let mm = this.state.veiculo.data_receb.substring(18,20);
      let dd = this.state.veiculo.data_receb.substring(15,17);
      let hm = this.state.veiculo.data_receb.substring(3,9);
      
      let datetime = moment(y+"-"+mm+"-"+dd+" "+hm+"-03:00").format('Y-MM-DD HH:mm');

      let body = new FormData();
            body.append('grupo_id', this.state.grupo_id);
            body.append('veiculo_id', this.state.veiculo.veiculo_id);
            body.append('data_final', String(datetime));
            body.append('token', localStorage.getItem('token'));
      var tipo_chamada =1;

      var instanceGetPosicoes = new GetPosicoes(this.state.veiculo
                                    , this.state.veiculo.MapaKseg
                                    , "/posicoesVeiculoParada"
                                    , body
                                    , tipo_chamada
                                    , null
                                    ,this);
      
      this.setState({instanceGetPosicoes: instanceGetPosicoes});                              
    
      instanceGetPosicoes.getPosicoes();
    };
    //--------------------------------------------------------------------------------
    getPosicoesData = async (startDate, endDate) => {
      this.setState({loading:true});
      this.state.instanceGetPosicoes.getPosicoesData(startDate, endDate, this.state.posicoes_list);
    }
    //-------------------------------------------------------------------
    ItemTemplate (e) {
      return (
        <React.Fragment>
          <span className={ e.icon } />
          { e.items ? <span className="dx-icon-spinright" /> : null }
          { e.text }
        </React.Fragment>
      );
    }
     //---------------------------------------------------------------
    itemClick = (e) => {
      if (!e.itemData.items) {
        // console.log(e);
      // alert(e+"  Detail");
        // notify(`The "${ e.itemData.text }" item was clicked`+" shitt", 'success', 1500);     
        // var selectedRowData = this.dataGrid.getSelectedRowsData();    
        // this.props.MapaKseg.setState({veiculoPosicao_selecionado: selectedRowData[0]});
        // this.props.MapaKseg.state.Modals.showComandoGridModal(selectedRowData[0]);
        

        // var instance = new GetComandosVeiculo(selectedRowData[0], this.props.MapaKseg); 
        // instance.getComandosVei();   

      }
    }
    //-------------------------------------------------
    showRangeData=()=>{
      let endDate = this.state.posicoes_list[this.state.posicoes_list.length-1].data_receb;
      this.state.veiculo.MapaKseg.state.Modals.showRangeDataModal(endDate, this);
    }
    //-------------------------------------------------------------------
    renderDetail=(props)=> {
      // let dataSource = this.getTasks(props.data.key);
      let dataSource = this.getTasks(props.data.recebimento_id);
  
      // if (props.data.eventos_count===0){
      //   return(<div></div>);
      // }
  
      return (
        <div >
          <DataGrid
            dataSource={dataSource}
            showBorders={false}
            columnAutoWidth={true}
            showRowLines={false}
            rowAlternationEnabled={true}
          >
            <Column dataField="icone"
              caption="Ícone"
              width={50}
              allowSorting={false}
              cellRender={cellRender}
              visible={true}
              >          
            </Column>
  
            <Column dataField="recebimento_id"            
                caption="recebimento_id"
                alignment="left"
                visible={false}>
                <HeaderFilter groupInterval={10000} />
              </Column>

            <Column dataField="descricao"
              caption="Evento"
              >
              <HeaderFilter allowSearch={true} />
            </Column>            

            <Column dataField="data_inicio"
                alignment="left"
                dataType="date"
                // format="M/d/yyyy, HH:mm"
                caption="Data inicial"
                width={160}
                format= 'dd/MM/yyyy HH:mm'
            />

            <Column dataField="data_final"
                alignment="left"
                dataType="date"
                // format="M/d/yyyy, HH:mm"
                caption="Data final"
                width={160}
                format= 'dd/MM/yyyy HH:mm'
            />
  
            <Column dataField="dateDiff"
              caption="Duração Evento">
              <HeaderFilter allowSearch={true} />
            </Column> 
          </DataGrid>
        </div>
      );
    }
    //-------------------------------------------------------------------
  render() {
    // let { veiculo_id, velocidade } = this.props.data.data;
    const onContextMenuPreparing = (e) =>{
      // e.row.key.MapaKseg = this.props.MapaKseg;
      if (e.target !== "content") 
          return;
      // e.items = [];
      if(e.row){
        e.component.selectRows(e.row.key, false)
        this.state.veiculo.MapaKseg.setState({
              veiculoPosicao_selecionado: e.row.key
          });
      //   console.log(e.row.key);
      // alert(e.row.key);
  
      }
    }
    return (
      <React.Fragment>
        {this.state.loading ? 
          <div className="centro">
            <Spinner animation="border" />
          </div>
        :
        
          <div>
            {/* <div className="centro2">
              <Button variant="outline-primary" title="Ver no Mapa" onClick={()=>this.showRangeData()} > <img src={require("./img/map_location48.png")}  width="20"/> </Button>
            </div> */}

            {/* <ContextMenu
              dataSource={items}
              width={180}
              target="#gridDetail"
              itemRender={this.ItemTemplate}
            onItemClick={this.itemClick} /> */}

            <DataGrid id="gridDetail"
              ref={dataGridRef}
              // ref={(ref) => this.dataGrid = ref}
            //   dataSource={this.orders}
              dataSource={this.state.posicoes_list}
              showBorders={true}
              focusedRowEnabled={false}
              showRowLines={true}
              rowAlternationEnabled={true}
              keyExpr="recebimento_id"
              
              hoverStateEnabled={true}
              allowColumnResizing={true}
              columnResizingMode={"nextColumn"}
              onContextMenuPreparing={onContextMenuPreparing}
              // focusedRowKey={2737879}
              // focusedRowKey={this.state.focusedRowKey}
              >
                
              <MasterDetail showBorders={false} enabled={true} render={this.renderDetail} />

              {/* <FilterRow visible={this.state.showFilterRow}
                applyFilter={this.state.currentFilter} />

              <HeaderFilter visible={this.state.showHeaderFilter} /> */}

              <SearchPanel visible={false}
                width={240}
                placeholder="Pesquisar..." />

              <Selection mode="single" />
              {/* <ColumnChooser enabled={true} title="Escolha a Coluna"/> */}

              <Column dataField="eventos_count_icon"
                caption="Eventos"
                width={50}
                allowSorting={false}
                cellRender={cellRender}
                visible={this.state.icone_visible}
                >          
              </Column>

              <Column dataField="eventos_count"            
                caption="Eventos count"
                alignment="left"
                visible={false}>
                <HeaderFilter groupInterval={100} />
              </Column>
              
              <Column dataField="id" caption="ID" alignment="left" width={50} />

              <Column dataField="recebimento_id"            
                caption="recebimento_id"
                alignment="left"
                visible={false}>
                <HeaderFilter groupInterval={10000} />
              </Column>

              <Column dataField="municipio"
                caption="Município">
                <HeaderFilter allowSearch={true} />
              </Column>

              <Column dataField="estado"
                caption="Estado"
                alignment="left"
                >
                <HeaderFilter allowSearch={true} />
              </Column>

              <Column dataField="pais"
                caption="País"
                alignment="left"
                >
                <HeaderFilter allowSearch={true} />
              </Column>

              <Column dataField="velocidade2"
                caption="velocidade"
                alignment="left">
              </Column>
    
              {/* <Column dataField="data_receb" alignment="left" dataType="date" caption="Data" width={160} format= 'dd/MM/yyyy HH:mm'/> */}
              {/* <Column dataField="data_receb2" caption={"Data"} format="EE  dd/MM/yyyy HH:mm" dataType="datetime" /> */}
              <Column dataField="data_receb2" caption={"Data"} format= 'MM/dd/yyyy HH:mm' dataType="datetime" />

              <Column dataField="latlng"
                alignment="right"
                caption="lat, lng"  
                editorOptions={saleAmountEditorOptions}
                visible={false} >
                <HeaderFilter dataSource={this.saleAmountHeaderFilter} />
              </Column>

              <Column dataField="backup" caption="Backup">
                <HeaderFilter allowSearch={true} />
              </Column>
              

            </DataGrid>
            <div className="centro2">
              <Button variant="primary" onClick={()=>this.showRangeData()} >CARREGAR MAIS</Button>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
  completedValue(rowData) {
    return rowData.Status === 'Completed';
  }

  calculateFilterExpression(value, selectedFilterOperations, target) {
    let column = this;
    if (target === 'headerFilter' && value === 'weekends') {
      return [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]];
    }
    return column.defaultCalculateFilterExpression.apply(this, arguments);
  }
  orderHeaderFilter(data) {
    data.dataSource.postProcess = (results) => {
      results.push({
        text: 'Weekends',
        value: 'weekends'
      });
      return results;
    };
  }
  onShowFilterRowChanged(e) {
    this.setState({
      showFilterRow: e.value
    });
    this.clearFilter();
  }
  onShowHeaderFilterChanged(e) {
    this.setState({
      showHeaderFilter: e.value
    });
    this.clearFilter();
  }
  onCurrentFilterChanged(e) {
    this.setState({
      currentFilter: e.value
    });
  }
  clearFilter() {
    this.dataGrid.instance.clearFilter();
  }

  componentDidMount() {
    // ToastStore.success("getPosicoesVeiculo componentDidMount()");
    // notify("shitt", 'success', 1500);
    // console.log(this.props.data);
    // this.props.MapaKseg.callToast("MEGA FuCK ");

    this.state.veiculo.MapaKseg.setState({
                  coords:[]
                    ,posicoesVeiculo: []
                    ,markerPlus: {}
                    ,markerEvento_list: []
                    ,carregarPolyline:false
                });

    this.getPosicoesUltimaParada();
    this.state.veiculo.MapaKseg.ajustarZoom(this.state.veiculo);
  };

  componentDidUpdate(prevProps) {
    // const { productId } = this.props;
    // if(prevProps.productId !== productId) {
    //   this.setState({
    //     orderHistoryStore: createStore({
    //       key: 'OrderID',
    //       loadParams: { ProductID: productId },
    //       loadUrl: `${url}/GetOrdersByProduct`
    //     })
    //   });
    // }
  }

    //-------------------
    getTasks=(key)=> {
      var dataSource = new DataSource({
        store: new ArrayStore({
          data: this.state.eventos_list,
          key: 'recebimento_id'
        }),
        filter: ['recebimento_id', '=', key]
      });
  
      return dataSource;
    }



}

function getOrderDay(rowData) {
return (new Date(rowData.OrderDate)).getDay();
}


function renderDetail2(props) {
let { recebimento_id, estado } = props.data;
return (
  <div >
    <p >{recebimento_id}</p>
    <p >{estado}</p>
  </div>
);
}

// function cellRender(data) {
//   if(data.value !== null)
//     return <img src={data.value} width="20"  height="20"/>;
//   else return <img src={require("./img/transparent.png")} width="20"  height="20"/>;
// }

export default GridVeiculoDetail;