import React, {Component} from 'react';
import './estilo.css';
import GetGruposPoligono from './GetGruposPoligono';
import {CabecalhoModal} from './CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import RangeSelector,{ Margin, Scale, MinorTick, SliderMarker, Format, Label, Subtitle, ScaleLabel } from 'devextreme-react/range-selector';
import {Modal, Button as Button_bootstrap} from 'react-bootstrap';

var moment = require('moment');

export class RangeDataModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true,
            showSheet:true,
            tempGridModalShow:false,
            clockVisibility:"not-visible",
            loading:true,
            animation: "zoom",
            height_modal:300,
          
            startValue : new Date(2011, 1, 1),
            endValue : new Date(2011, 6, 1),
            
            startRange: new Date(2011, 1, 5),
            endRange: new Date(2011, 3, 5),
        };
        this.processRange = this.processRange.bind(this);
      }
    //------------------------
    async componentDidMount() {
        let y = this.props.endDate.substring(21,25);
        let mm = this.props.endDate.substring(18,20);
        let dd = this.props.endDate.substring(15,17);
        let hm = this.props.endDate.substring(3,9);
        let hour = this.props.endDate.substring(3,6);
        let min = this.props.endDate.substring(7,9);
        mm = mm-1;

        // const d = new Date("2015-03-25T12:00:00Z");
        // Date and time is separated with a capital T.
        // UTC time is defined with a capital letter Z.
        // If you want to modify the time relative to UTC, remove the Z and add +HH:MM or -HH:MM instead:
        // const d = new Date("2015-03-25T12:00:00-06:30");

        // alert(hm+ "  "+ h+"  "+m+"  ");
        
        // let datetime = moment(y+"-"+mm+"-"+dd+" "+hm+"-03:00").format('Y-MM-DD HH:mm');

        this.setState({startValue: new Date(y, mm, dd, hour-5, min), endValue: new Date(y, mm, dd, hour, min)
            , startRange: new Date(y, mm, dd, hour-1, min ),   endRange: new Date(y, mm, dd, hour, min)})

      }
    //-------------------
    minimizarModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "slideLeft"});
        this.props.MapaKseg.state.Modals.closeRangeDataModal();
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.MapaKseg.state.Modals.closeRangeDataModal();
    };
    //---------close----------
    desativarModal = () =>{
        if(this.props.show === false)
        this.props.MapaKseg.state.Modals.desativarRangeDataModal();
    };
    //-------------------------
    sequenciaUpdate = () => {
        this.props.MapaKseg.state.Modals.setModalSelecionado("RangeDataModal");
    };
    //-------------------------
    onPesquisar = () => {
        let startDate = moment(this.state.startRange).format('Y-MM-DD HH:mm');

        let y = this.props.endDate.substring(21,25);
        let mm = this.props.endDate.substring(18,20);
        let dd = this.props.endDate.substring(15,17);
        let hm = this.props.endDate.substring(3,9);
        
        let endDate = moment(y+"-"+mm+"-"+dd+" "+hm+"-03:00").subtract(1, 'seconds').format('Y-MM-DD HH:mm');
        // alert(endDate);

        if(this.props.GridOperacionalDetail !== null)
            this.props.GridOperacionalDetail.getPosicoesData(startDate, endDate);
        else this.props.MapaKseg.getPosicoesData(startDate, endDate);
            
        this.fecharModal();
    };
    //--------------------------
    processRange(e) {
        // alert(e.value[0]);
        //alert(this.state.endRange);
        this.setState({startRange: e.value[0]});
        this.setState({endRange: this.state.endValue});
        //alert(this.state.startRange);
        //e.value[1] = new Date(2011, 6, 1);
      //this.setState({
      //  totalProduction: calculateTotalProduction(e.value)
      //});
    }
    //-------------------------
    render(){
        return(
            <div className="drag1" >
                <Draggable   handle="strong"  >
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={400}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >
                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false} titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                    <RangeSelector
                                        id="range-selector"
                                        title="Quantas horas atrás?"
                                        value={[this.state.startRange, this.state.endRange]}
                                            onValueChanged={this.processRange}
                                        >
                                        <Margin top={10}  />
                                        {/* <Scale startValue={this.state.startValue} endValue={this.state.endValue} minorTickInterval="day" 
                                            tickInterval="day" minRange="day" maxRange="week">
                                            <MinorTick visible={false} />
                                        </Scale>
                                        <SliderMarker format="monthAndDay" /> */}

                                        <Scale valueType="datetime" startValue={this.state.startValue} endValue={this.state.endValue} minorTickInterval="hour" 
                                            tickInterval="hour" minRange="hour" maxRange="day">
                                            <MinorTick visible={false} />
                                            {/* <Format type="fixedPoint" precision={4} /> */}
                                            <Label format={"HH:mm"+ "\r\n" +"d MMMM"} />
                                        </Scale>
                                        <SliderMarker format={"HH:mm"} />
                                        {/* <ScaleLabel visible={false} /> */}
                                        {/* <ScaleLabel visible={false} /> */}

                                    </RangeSelector>
                                </div>
                            </div>

                            <Modal.Footer>                        
                                <Button_bootstrap className="left" onClick={this.props.onHide}>Cancelar</Button_bootstrap>
                                <Button_bootstrap onClick={this.onPesquisar}> Pesquisar</Button_bootstrap>                
                            </Modal.Footer>

                        </Rodal>
                    </div>
                </Draggable>
            </div>
        );
    }
}
