
import React, {Component} from 'react';
import SideNav, {  NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
// import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import {TempGridModal} from './TempGridModal';
import {GruposVeiculoModal} from './GruposVeiculoModal';
import {ListaModal} from './ListaModal';
import {RelPosicoesVeiModal} from './RelPosicoesVeiModal';
import {RelColetaModal} from './relatorio/rel_coleta_modal';
import {RelColetaEntregaModal} from './relatorio/rel_coleta_entrega_modal';
import {RelEntregaModal} from './relatorio/rel_entrega_modal';
import {RelCaixaModal} from './relatorio/rel_caixa_modal';
import {RelJornadaMotoristaModal} from './relatorio/rel_jornada_motorista_modal';
import {CadastroFaixas} from './cadastroFaixas';
import {TemperaturaModal} from './TemperaturaModal';
import {UsuarioModal} from './UsuarioModal';
import {PoligonoOpcoesModal} from './PoligonoOpcoesModal';
import {PoligonosModal} from './PoligonosModal';
import {AddNotificacaoVeiModal} from './AddNotificacaoVeiModal';
import ReactRoundedImage from "react-rounded-image";
import {MensagemModal} from './MensagemModal';
import {ObservacaoModal} from './modal/observacao_modal';
import {FormModal} from './modal/form_modal';
import {GraficosModal} from './GraficosModal';
import {VeiculoInfoModal} from './VeiculoInfoModal';
import {NotificacaoModal} from './NotificacaoModal';
import {ComandoGridModal} from './ComandoGridModal';
import {RangeDataModal} from './RangeDataModal';
import {CadastroModal} from './modal/cadastro_modal';
import {CadCliente} from './CadCliente';
import {CadColetaModal} from './modal/cadcoleta_modal';
import {CadColetaSimplificadaModal} from './modal/cadcoleta_simp_modal';
import {CadEntregaModal} from './modal/cadentrega_modal';
import {EntregaModal} from './modal/entrega_modal';
import {ColetaEntregaModal} from './modal/coleta_entrega_modal';
import {GeralListModal} from './modal/gerallist_modal';
import {UsuarioCadModal} from './modal/usuario_cad_modal';
import {GrupoCadModal} from './modal/grupo_cad_modal';
import {PessoaCadModal} from './modal/pessoa_cad_modal';
import {LocalCadModal} from './modal/local_cad_modal';
import {MunicipioCadModal} from './modal/municipio_cad_modal';
import {FilialCadModal} from './modal/filial_cad_modal';
import {RotaCadModal} from './modal/rota_cad_modal';
import {ClienteCadModal} from './modal/cliente_cad_modal';
import {ImageCropperModal} from './modal/image_cropper_modal';
import {PesquisaColetaModal} from './modal/pesquisa_coleta_modal';
import {PesquisaEntregaModal} from './modal/pesquisa_entrega_modal';
import {ConcluirColetaEntregaModal} from './modal/concluir_coleta_entrega_modal';
import {PesquisarModal} from './modal/pesquisar_modal';
import {ComprovanteModal} from './modal/comprovante_modal';
import {XmlModal} from './modal/xml_modal';
import {PagoAtualizarModal} from './modal/pago_atualizar_modal';


import {ToastContainer, ToastStore} from 'react-toasts';

import base64 from 'react-native-base64'
// var binaryToBase64 = require('binaryToBase64');
import './estilo.css';
import { Button, Image } from 'react-bootstrap';


export class Modals extends Component{
    constructor(props){
        super(props);
        this.state = {
            divWidth: {
               width: 1900
            },
            divHeight: {
                height:500
             },

             tempGridModalShow:false,
             tempGridModalAtivo:false,

             gruposVeiculoModalShow:false,
             gruposVeiculoModalAtivo:false,
             gruposVeiculoModal:null,

             monitoresModalShow:false,
             monitoresModalAtivo:false,

             relatoriosModalShow:false,
             relatoriosModalAtivo:false,                   

             relPosicoesVeiModalShow:false,
             relPosicoesVeiModalAtivo:false,

             relColetaModalShow:false,
             relColetaModalAtivo:false,

             relColetaEntregaModalShow:false,
             relColetaEntregaModalAtivo: false,

             relEntregaModalShow:false,
             relEntregaModalAtivo:false,
             coletaEntregaModalShow:false,
             coletaEntregaModalAtivo:false,

             relEventosVeiModalShow:false,
             relEventosVeiModalAtivo:false,

             relCaixaModalShow:false,
             relCaixaModalAtivo:false,
             

             relJornadaMotoristaModalShow:false,
             relJornadaMotoristaModalAtivo:false,

             cadastroFaixasModalShow:false,
             cadastroFaixasModalAtivo:false,
             TempGridModal: null,

             temperaturaModalShow:false,
             temperaturaModalAtivo:false,

             temperaturaList:null,
             temperatura_obj:null,

             updateRender: false,
             sequenceRenderList:[],
             modalSelecionado:null,
             gruposSeq:0,

             usuarioModalShow:false,
             usuarioModalAtivo:false,

             poligonoOpcoesModalShow:false,
             poligonoOpcoesModalAtivo:false,
             poligonosModalShow:false,
             poligonosModalAtivo:false,
             grupo_poligono_id:0,

             PoligonosModal:null,
             
             addNotificacaoVeiModalShow: false,
             addNotificacaoVeiModalAtivo:false,

             mensagemModalShow:false,
             mensagemModalAtivo:false,

             observacaoModalShow:false,
             observacaoModalAtivo:false,

             formModalShow:false,
             formModalAtivo:false,

             campos:[],

             texto:"",
             botao_cancelar:false,
             botao_cadastrar:false,

             graficosListaModalShow: false,
             graficosListaModalAtivo:false,

             graficosModalShow:false,
             graficosModalAtivo:false,

             graficosKmPercorridaModalShow:false,
             graficosKmPercorridaModalAtivo:false,

             graficosVelocidadeMediaModalShow:false,
             graficosVelocidadeMediaModalAtivo:false,

             graficosQuantidadePosicoesModalShow:false,
             graficosQuantidadePosicoesModalAtivo:false,

             graficosQuantidadeEventosModalShow:false,
             graficosQuantidadeEventosModalAtivo:false,

             graficosColetasEntregasModalShow:false,
             graficosColetasEntregasModalAtivo:false,

             graficosColetasClientesModalShow:false,
             graficosColetasClientesModalAtivo:false,

             graficosEntregasClientesModalShow:false,
             graficosEntregasClientesModalAtivo:false,

             graficosColetasMotoristasModalShow:false,
             graficosColetasMotoristasModalAtivo:false,

             graficosEntregasMotoristasModalShow:false,
             graficosEntregasMotoristasModalAtivo:false,

             veiculoInfoModalShow:false,
             veiculoInfoModalAtivo:false,

             veiculo_selecionado:null,

             notificacaoModalShow:false,
             notificacaoModalAtivo:false,
             NotificacaoModal: null,

             comandoGridModalShow:false,
             comandoGridModalAtivo:false,
             ComandoGridModal: null,

             selectedRowData:null,

             rangeDataModalShow:false,
             rangeDataModalAtivo:false,
             RangeDataModal:null,
             endDate:null,
             GridOperacionalDetail:null,

             cadastroModalShow:false,
             cadastroModalAtivo:false,
             cadastroModal: null,

             cadClienteShow:false,
             cadClienteAtivo:false,
             poligono_selecionado:{},

             cadColetaShow:false,
             cadColetaAtivo:false,

             cadColetaSimplificadaShow:false,
             cadColetaSimplificadaAtivo:false,

             entregaModalShow:false,
             entregaModalAtivo:false,

             cadEntregaModalShow:false,
             cadEntregaModalAtivo:false,

             geralListShow:false,
             geralListAtivo:false,

             objeto:null,
             obj_selected: null,
             titulo:"",
             tipo:0,

             usuarioCadModalShow:false,
             usuarioCadModalAtivo:false,

             grupoCadModalShow:false,
             grupoCadModalAtivo:false,

             pessoaCadShow:false,
             pessoaCadAtivo:false,

             localCadShow:false,
             localCadAtivo:false,

             municipioCadShow:false,
             municipioCadAtivo:false,

             filialCadShow:false,
             filialCadAtivo:false,

             rotaCadShow:false,
             rotaCadAtivo:false,

             clienteCadShow:false,
             clienteCadAtivo:false,

             imageCropperShow:false,
             imageCropperAtivo:false,

             GridColetaEntrega:null,

             pesquisaColetaModalShow: false,
             pesquisaColetaModalAtivo: false,

             pesquisaEntregaModalShow: false,
             pesquisaEntregaModalAtivo: false,

             concluirColetaEntregaModalShow: false,
             concluirColetaEntregaModalAtivo:false,

             pesquisarModalShow: false,
             pesquisarModalAtivo:false,

             comprovanteModalShow:false, 
             comprovanteModalAtivo:false,

             xmlModalShow:false, 
             xmlModalAtivo:false,

             pagoAtualizarModalShow:false, 
             pagoAtualizarModalAtivo:false,

             imagem:"",

       }
    }
    //------------------------
    async componentDidMount() {
        this.props.MapaKseg.setState({Modals:this});
        // var sequenceRenderList =[
        //     {seq: 0, class:"GruposVeiculoModal", ativoModal: this.state.gruposVeiculoModalAtivo, renderModal: this.renderGrupoVeiculoModal()}
        //     ,{seq: 1, class:"TempGridModal", ativoModal: this.state.tempGridModalAtivo, renderModal: this.renderTempGridModal()}
        //     ,{seq: 2, class:"MonitoresModal", ativoModal: this.state.monitoresModalAtivo, renderModal: this.renderMonitoresModal()}
        //     ,{seq: 3, class:"RelatoriosModal", ativoModal: this.state.relatoriosModalAtivo, renderModal: this.renderRelatoriosModal()}
        //     ,{seq: 4, class:"RelPosicoesVeiModal", ativoModal: this.state.relPosicoesVeiModalAtivo, renderModal: this.renderRelPosicoesVeiModal()}
        //     ,{seq: 5, class:"CadastroFaixasModal", ativoModal: this.state.cadastroFaixasModalAtivo, renderModal: this.renderCadastroFaixasModal()}
        //     ,{seq: 6, class:"TemperaturaModal", ativoModal: this.state.temperaturaModalAtivo, renderModal: this.renderTemperaturaModal()}
        // ];

        // this.setState({sequenceRenderList: sequenceRenderList});
        //             ,updateRender:true});

        this.setState({updateRender:true});

        // ToastStore.success("componentDidMount() ");

        // this.setState({notificacaoModalAtivo:true});
    }
    //--------------------------------------------------TempGridModal-----------------------------------------------------------------    
    renderTempGridModal = () => {
        return(
            <div>
                <TempGridModal
                    show={this.state.tempGridModalShow}
                    SideNavDrawer={this}
                    MapaKseg={this.props.MapaKseg}
                />
            </div>);
    };
    //--------------------
    showTempGridModal = () => {
        this.setState({tempGridModalShow:true});
        this.setState({tempGridModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({tempGridModalAtivo:true});
    };
    //-------------------
    tempGridModalClose = () =>{
        this.setState({tempGridModalShow:false});
    };
    //-------------------------
    tempGridModalDesativar = () =>{
        this.setState({tempGridModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({tempGridModalAtivo:false});
    };
    //--------------------------------------------------GrupoVeiculoModal-----------------------------------------------------------------    
    renderGrupoVeiculoModal = () => {
        return(
            <div>
                <GruposVeiculoModal
                    show={this.state.gruposVeiculoModalShow}
                    usuario_id={this.props.usuario_id}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                />
            </div>);
    };
    //--------------------
    showGruposVeiculoModal = () => {

        // var sequenceRenderList =[            
        //     {seq: 1, class:"TempGridModal", ativoModal: this.state.tempGridModalAtivo, renderModal: this.renderTempGridModal()}
        //     ,{seq: 2, class:"MonitoresModal", ativoModal: this.state.monitoresModalAtivo, renderModal: this.renderMonitoresModal()}
        //     ,{seq: 3, class:"RelatoriosModal", ativoModal: this.state.relatoriosModalAtivo, renderModal: this.renderRelatoriosModal()}
        //     ,{seq: 4, class:"RelPosicoesVeiModal", ativoModal: this.state.relPosicoesVeiModalAtivo, renderModal: this.renderRelPosicoesVeiModal()}
        //     ,{seq: 5, class:"CadastroFaixasModal", ativoModal: this.state.cadastroFaixasModalAtivo, renderModal: this.renderCadastroFaixasModal()}
        //     ,{seq: 6, class:"TemperaturaModal", ativoModal: this.state.temperaturaModalAtivo, renderModal: this.renderTemperaturaModal()}
        //     ,{seq: 0, class:"GruposVeiculoModal", ativoModal: this.state.gruposVeiculoModalAtivo, renderModal: this.renderGrupoVeiculoModal()}
        // ];

        // this.setState({sequenceRenderList: sequenceRenderList});
    
        this.setState({gruposVeiculoModalShow:true});
        this.setState({gruposVeiculoModalAtivo:true});
        // this.setState({fuuck:true});
        // this.setState({abrirModal:true});

        if(this.state.gruposVeiculoModal !== null)
            this.state.gruposVeiculoModal.desativarFilterrow();
    };
    //-------------------
    gruposVeiculodModalClose = () =>{
        this.setState({gruposVeiculoModalShow:false});
        // this.setState({abrirModal:false});
       };
    //-------------------
    gruposVeiculodModalDesativar = () =>{
        this.setState({gruposVeiculoModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({gruposVeiculoModalAtivo:false});
        // this.setState({abrirModal:false});
       };   
    //--------------------------------------------------RelatoriosModal-----------------------------------------------------------------    
    renderMonitoresModal = () => {
        return(
            <div>
                <ListaModal  
                    show={this.state.monitoresModalShow}
                    titulo={"Monitor Operacional"}
                    tipoChamadaLista={1}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                />    
            </div>);
    };
    //--------------------
    showMonitoresModal = () => {

        var sequenceRenderList =[
            {seq: 0, class:"GruposVeiculoModal", ativoModal: this.state.gruposVeiculoModalAtivo, renderModal: this.renderGrupoVeiculoModal()}
            ,{seq: 1, class:"TempGridModal", ativoModal: this.state.tempGridModalAtivo, renderModal: this.renderTempGridModal()}        
            ,{seq: 3, class:"RelatoriosModal", ativoModal: this.state.relatoriosModalAtivo, renderModal: this.renderRelatoriosModal()}
            ,{seq: 4, class:"RelPosicoesVeiModal", ativoModal: this.state.relPosicoesVeiModalAtivo, renderModal: this.renderRelPosicoesVeiModal()}
            ,{seq: 5, class:"CadastroFaixasModal", ativoModal: this.state.cadastroFaixasModalAtivo, renderModal: this.renderCadastroFaixasModal()}
            ,{seq: 6, class:"TemperaturaModal", ativoModal: this.state.temperaturaModalAtivo, renderModal: this.renderTemperaturaModal()}
            ,{seq: 2, class:"MonitoresModal", ativoModal: this.state.monitoresModalAtivo, renderModal: this.renderMonitoresModal()}
        ];
        
        this.setState({monitoresModalShow:true});
        this.setState({monitoresModalAtivo:true});
    };
    //-----------------------
    closeMonitoresModal = () =>{
        this.setState({monitoresModalShow:false});
    };   
    //-------------------
    desativarMonitoresModal = () =>{
        this.setState({monitoresModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({monitoresModalAtivo:false});
    };
    //--------------------------------------------------RelatoriosModal-----------------------------------------------------------------    
    renderRelatoriosModal = () => {
        return(
            <div>
                <ListaModal
                    show={this.state.relatoriosModalShow}
                    titulo={"Relatórios"}
                    tipoChamadaLista={2}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                />    
            </div>);
    };
    //--------------------
    showRelatoriosModal = () => {
        this.setState({relatoriosModalShow:true});
        this.setState({relatoriosModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({relatoriosModalShow:true});
    };
    //-----------------------
    closeRelatoriosModal = () =>{
        this.setState({relatoriosModalShow:false});
    };   
    //-------------------
    desativarRelatoriosModal = () =>{
        this.setState({relatoriosModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({relatoriosModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({relatoriosModalShow:false});
    };
    //--------------------------------------------------showRelPosicoesVeiModal-----------------------------------------------------------------    
    renderRelPosicoesVeiModal = () => {
        var TIPO_CHAMADA_POSICOES =1;
        return(
            <div>
                <RelPosicoesVeiModal
                    show={this.state.relPosicoesVeiModalShow}
                    filter_list={this.props.filter_list}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    SideNavDrawer={this}
                    tipo_chamada={TIPO_CHAMADA_POSICOES}
                    titulo={"Posições do Veículo"}
                    Modals={this}
                />
            </div>);
    };
    //----------------------
    showRelPosicoesVeiModal = () => {
        this.setState({relPosicoesVeiModalShow:true});
        this.setState({relPosicoesVeiModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({relPosicoesVeiModalAtivo:true});
    }; 
    //-------------------
    relPosicoesVeiModalClose = () =>{
        this.setState({relPosicoesVeiModalShow:false});
    };
    //-------------------
    relPosicoesVeiModalDesativar = () =>{
        this.setState({relPosicoesVeiModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({relPosicoesVeiModalAtivo:false});
    };
    //--------------------------------------------------showRelEventosVeiModal-----------------------------------------------------------------    
    renderRelEventosVeiModal = () => {
        var TIPO_CHAMADA_EVENTOS=2;
        return(
            <div>
                <RelPosicoesVeiModal
                    show={this.state.relEventosVeiModalShow}
                    filter_list={this.props.filter_list}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    SideNavDrawer={this}
                    tipo_chamada={TIPO_CHAMADA_EVENTOS}
                    titulo={"Eventos do Veículo"}
                    Modals={this}
                />
            </div>);
    };
    //----------------------
    showRelEventosVeiModal = () => {
        this.setState({relEventosVeiModalShow:true});
        this.setState({relEventosVeiModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({relEventosVeiModalAtivo:true});
    }; 
    //-------------------
    relEventosVeiModalClose = () =>{
        this.setState({relEventosVeiModalShow:false});
    };
    //-------------------
    relEventosVeiModalDesativar = () =>{
        this.setState({relEventosVeiModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({relEventosVeiModalAtivo:false});
    };
    //--------------------------------------------------showRelEntregaModal-----------------------------------------------------------------    
    renderRelColetaModal = () => {
        return(
            <div>
                <RelColetaModal
                    show={this.state.relColetaModalShow}
                    filter_list={this.props.filter_list}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}                
                    titulo={"Relatório Coletas"}
                />
            </div>);
    };
    //----------------------
    showRelColetaModal = () => {
        this.setState({relColetaModalShow:true});
        this.setState({relColetaModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({relColetaModalAtivo:true});
    }; 
    //-------------------
    closeRelColetaModal = () =>{
        this.setState({relColetaModalShow:false});
    };
    //-------------------
    desativarRelColetaModal = () =>{
        this.setState({relColetaModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({relColetaModalAtivo:false});
    };
    //--------------------------------------------------showRelEntregaModal-----------------------------------------------------------------    
    renderRelEntregaModal = () => {
        return(
            <div>
                <RelEntregaModal
                    show={this.state.relEntregaModalShow}
                    filter_list={this.props.filter_list}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    titulo={"Relatório Entrega"}
                />
            </div>);
    };
    //----------------------
    showRelEntregaModal = () => {
        this.setState({relEntregaModalShow:true});
        this.setState({relEntregaModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({relEntregaModalAtivo:true});
    }; 
    //-------------------
    closeRelEntregaModal = () =>{
        this.setState({relEntregaModalShow:false});
    };
    //-------------------
    desativarRelEntregaModal = () =>{
        this.setState({relEntregaModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({relEntregaModalAtivo:false});
    };    
    //--------------------------------------------------showRelColetaEntregaModal-----------------------------------------------------------------    
    renderRelColetaEntregaModal = () => {
        return(
            <div>
                <RelColetaEntregaModal
                    show={this.state.relColetaEntregaModalShow}
                    filter_list={this.props.filter_list}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}                
                    titulo={"Relatório Geral Coletas/Entregas"}
                />
            </div>);
    };
    //----------------------
    showRelColetaEntregaModal = () => {
        this.setState({relColetaEntregaModalShow:true});
        this.setState({relColetaEntregaModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({relColetaEntregaModalAtivo:true});
    }; 
    //-------------------
    closeRelColetaEntregaModal = () =>{
        this.setState({relColetaEntregaModalShow:false});
    };
    //-------------------
    desativarRelColetaEntregaModal= () =>{
        this.setState({relColetaEntregaModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({relColetaEntregaModalAtivo:false});
    };
    //--------------------------------------------------relCaixaModal-----------------------------------------------------------------    
    renderRelCaixaModal = () => {
        return(
            <div>
                <RelCaixaModal
                    show={this.state.relCaixaModalShow}
                    filter_list={this.props.filter_list}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}                
                    titulo={"Relatório de Caixas"}
                />
            </div>);
    };
    //----------------------
    showRelCaixaModal = () => {
        this.setState({relCaixaModalShow:true});
        this.setState({relCaixaModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({relCaixaModalAtivo:true});

        // this.setState({relCaixaModalAtivo:false},
        //     () => {         
        //         this.setState({relCaixaModalShow:true, relCaixaModalAtivo:true});        
        //         this.props.MapaKseg.state.SideNavDrawer.setState({relCaixaModalAtivo:true});
        //     }
        // );
    }; 
    //-------------------
    closeRelCaixaModal = () =>{
        this.setState({relCaixaModalShow:false});
    };
    //-------------------
    desativarRelCaixaModal= () =>{
        this.setState({relCaixaModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({relCaixaModalAtivo:false});
    };    
    //--------------------------------------------------relJornadaMotoristaModal-----------------------------------------------------------------    
    renderRelJornadaMotoristaModal = () => {
        return(
            <div>
                <RelJornadaMotoristaModal
                    show={this.state.relJornadaMotoristaModalShow}
                    filter_list={this.props.filter_list}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}                
                    titulo={"Relatório Jornada Motorista"}
                />
            </div>);
    };
    //----------------------
    showRelJornadaMotoristaModal = () => {
        this.setState({relJornadaMotoristaModalShow:true});
        this.setState({relJornadaMotoristaModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({relJornadaMotoristaModalAtivo:true});

        // this.setState({relJornadaMotoristaModalAtivo:false},
        //     () => {         
        //         this.setState({relJornadaMotoristaModalShow:true, relJornadaMotoristaModalAtivo:true});        
        //         this.props.MapaKseg.state.SideNavDrawer.setState({relJornadaMotoristaModalAtivo:true});
        //     }
        // );
    }; 
    //-------------------
    closeRelJornadaMotoristaModal = () =>{
        this.setState({relJornadaMotoristaModalShow:false});
    };
    //-------------------
    disableRelJornadaMotoristaModal= () =>{
        this.setState({relJornadaMotoristaModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({relJornadaMotoristaModalAtivo:false});
    };     
    //--------------------------------------------------CadastroFaixasModal-----------------------------------------------------------------    
    renderCadastroFaixasModal = () => {
        return(
            <div>
                <CadastroFaixas
                    show={this.state.cadastroFaixasModalShow}
                    TempGridModal={this.state.TempGridModal}
                    MapaKseg={this.props.MapaKseg}
                /> 
            </div>);
    };
    //--------------------
    showCadastroFaixasModal = (TempGridModal) => {
        this.setState({TempGridModal:TempGridModal});
        this.setState({cadastroFaixasModalShow:true});
        this.setState({cadastroFaixasModalAtivo:true});
    };
    //-------------------
    cadastroFaixasClose = () =>{
        this.setState({cadastroFaixasModalShow:false});
    };
    //-------------------------
    cadastroFaixasDesativar = () =>{
        this.setState({cadastroFaixasModalAtivo:false});
    };
    //--------------------------------------------------TemperaturaModal-----------------------------------------------------------------    
    renderTemperaturaModal = () => {
        return(
            <div>
                <TemperaturaModal
                    show={this.state.temperaturaModalShow}                
                    temperaturaList={this.state.temperaturaList}
                    TempGridModal={this.state.TempGridModal}
                    SideNavDrawer={this}
                    temperatura_obj={this.state.temperatura_obj}
                    MapaKseg={this.props.MapaKseg}
                /> 
            </div>);
    };
    //--------------------
    showTemperaturaModal = (temperatura_obj) => {
        this.setState({temperatura_obj:temperatura_obj});

        // this.setState({temperaturaList:temperaturaList});    
        this.setState({temperaturaModalShow:true});
        this.setState({temperaturaModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({temperaturaModalAtivo:true});
    };
    //-------------------
    temperaturaModalClose = () =>{
        this.setState({temperaturaModalShow:false});
    };
    //-------------------------
    temperaturaModalDesativar = () =>{
        this.setState({temperaturaModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({temperaturaModalAtivo:false});
    };
    //--------------------------------------------------UsuarioModal-----------------------------------------------------------------    
    renderUsuarioModal = () => {
        return(
            <div>
                <UsuarioModal
                    show={this.state.usuarioModalShow}                
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    usuario_id={this.props.usuario_id}
                /> 
            </div>);
    };
    //--------------------
    showUsuarioModal = () => {    
        this.setState({usuarioModalShow:true});
        this.setState({usuarioModalAtivo:true});
    };
    //-------------------
    closeUsuarioModal = () =>{
        this.setState({usuarioModalShow:false});
    };
    //-------------------------
    usuarioModalDesativar = () =>{
        this.setState({usuarioModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({usuarioModalAtivo:false});
    };
    //--------------------------------------------------PoligonoOpcoesModal-----------------------------------------------------------------    
    renderPoligonoOpcoesModal = () => {
        return(
            <div>
                <PoligonoOpcoesModal
                    show={this.state.poligonoOpcoesModalShow}                
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    usuario_id={this.props.usuario_id}
                    polygon={this.props.MapaKseg.state.selectedPolygon}
                    PoligonosModal={this.state.PoligonosModal}
                /> 
            </div>);
    };
    //--------------------
    showPoligonoOpcoesModal = () => {    
        this.setState({poligonoOpcoesModalShow:true});
        this.setState({poligonoOpcoesModalAtivo:true});
    };
    //-------------------
    closePoligonoOpcoesModal = () =>{
        this.setState({poligonoOpcoesModalShow:false});
    };
    //-------------------------
    poligonoOpcoesModalDesativar = () =>{
        this.setState({poligonoOpcoesModalAtivo:false});
        // this.props.MapaKseg.state.SideNavDrawer.setState({poligonoOpcoesModalAtivo:false});
    };
    //--------------------------------------------------PoligonosModal-----------------------------------------------------------------    
    renderPoligonosModal = () => {
        return(
            <div>
                <PoligonosModal
                    show={this.state.poligonosModalShow}                
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    usuario_id={this.props.usuario_id}
                    grupo_id={this.state.grupo_poligono_id}
                    poligonos_list={this.props.MapaKseg.state.poligonos_list}
                /> 
            </div>);
    };
    //--------------------
    showPoligonosModal = (grupo_id) => {    
        this.setState({poligonosModalShow:true, grupo_poligono_id: grupo_id});
        this.setState({poligonosModalAtivo:true});
    };
    //-------------------
    closePoligonosModal = () =>{
        this.setState({poligonosModalShow:false});
    };
    //-------------------------
    poligonosModalDesativar = () =>{
        this.setState({poligonosModalAtivo:false});
        // this.props.MapaKseg.state.SideNavDrawer.setState({poligonoOpcoesModalAtivo:false});
    };
    //--------------------------------------------------AddNotificacaoVeiModal-----------------------------------------------------------------    
    renderAddNotificacaoVeiModal = () => {
        return(
            <div>
                <AddNotificacaoVeiModal
                    show={this.state.addNotificacaoVeiModalShow}                
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    usuario_id={this.props.usuario_id}
                /> 
            </div>);
    };
    //--------------------
    showAddNotificacaoVeiModal = () => {    
        this.setState({addNotificacaoVeiModalShow:true});
        this.setState({addNotificacaoVeiModalAtivo:true});
    };
    //-------------------
    closeAddNotificacaoVeiModal = () =>{
        this.setState({addNotificacaoVeiModalShow:false});
    };
    //-------------------------
    desativarAddNotificacaoVeiModal = () =>{
        this.setState({addNotificacaoVeiModalAtivo:false});
        // this.props.MapaKseg.state.SideNavDrawer.setState({poligonoOpcoesModalAtivo:false});
    };
    //--------------------------------------------------MensagemModal-----------------------------------------------------------------    
    renderMensagemModal = () => {
        return(
            <div>
                <MensagemModal
                    show={this.state.mensagemModalShow}
                    onHide={this.closeMensagemModal}
                    cancelar={this.state.botao_cancelar}
                    MapaKseg = {this.props.MapaKseg}
                    Modals={this}  
                    objeto={this.state.objeto}
                    titulo={this.state.titulo}
                    texto={this.state.texto}
                    imagem={this.state.imagem}
                />            
            </div>);
    };
    //--------------------
    showMensagemModal = (objeto, titulo, texto, botao_cancelar, imagem) => {
        this.setState({objeto: objeto, texto: texto, botao_cancelar: botao_cancelar, imagem: imagem, titulo:titulo});
        this.setState({mensagemModalShow:true});      
        this.setState({mensagemModalAtivo:true});      
    };
    //-------------------
    closeMensagemModal = () =>{
        this.setState({mensagemModalShow:false});
        this.setState({mensagemModalAtivo:false});
    };
    //-------------------------
    desativarMensagemModal = () =>{
        this.setState({mensagemModalAtivo:false});
    };
    //--------------------------------------------------FormModal-----------------------------------------------------------------    
    renderFormModal = () => {
        return(
            <div>
                <FormModal
                    show={this.state.formModalShow}
                    onHide={this.closeFormModal}
                    MapaKseg = {this.props.MapaKseg}
                    Modals={this}  
                    objeto={this.state.objeto}
                    titulo={this.state.titulo}
                    texto={this.state.texto}
                    campos={this.state.campos}
                />            
            </div>);
    };
    //--------------------
    showFormModal = (objeto, titulo, texto, campos) => {
        this.setState({objeto:objeto, titulo:titulo, texto: texto, campos});
        this.setState({formModalShow:true});      
        this.setState({formModalAtivo:true});      
    };
    //-------------------
    closeFormModal = () =>{
        this.setState({formModalShow:false});
        this.setState({formModalAtivo:false});
    };
    //-------------------------
    desativarFormModal = () =>{
        this.setState({mensagemModalAtivo:false});
    };
    //--------------------------------------------------GraficosListaModal-----------------------------------------------------------------    
    renderGraficosListaModal = () => {
        return(
            <div>
                <ListaModal
                    show={this.state.graficosListaModalShow}
                    titulo={"Gráficos"}
                    tipoChamadaLista={3}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                />      
            </div>);
    };
    //--------------------
    showGraficosListaModal = () => {
        this.setState({graficosListaModalShow:true});
        this.setState({graficosListaModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosListaModalShow:true});
    };
    //-----------------------
    closeGraficosListaModal = () =>{
        this.setState({graficosListaModalShow:false});
    };   
    //-------------------
    desativarGraficosListaModal = () =>{
        this.setState({graficosListaModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosListaModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosListaModalShow:false});
    };     
    //--------------------------------------------------GraficosKmPercorridaModal-----------------------------------------------------------------    
    renderGraficosKmPercorridaModal = () => {
        return(
            <div>
                <GraficosModal
                    show={this.state.graficosKmPercorridaModalShow}
                    titulo={"Quilometragem Percorrida"}
                    tipo_chamada={1}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    filter_veiculo={false}
                />    
            </div>);
    };
    //--------------------
    showGraficosKmPercorridaModal = () => {
        this.setState({graficosKmPercorridaModalShow:true});
        this.setState({graficosKmPercorridaModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosKmPercorridaModalShow:true});
    };
    //-----------------------
    closeGraficosKmPercorridaModal = () =>{
        this.setState({graficosKmPercorridaModalShow:false});
    };   
    //-------------------
    desativarGraficosKmPercorridaModal = () =>{
        this.setState({graficosKmPercorridaModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosKmPercorridaModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosKmPercorridaModalShow:false});
    };   
    //--------------------------------------------------GraficosVelocidadeMediaModal-----------------------------------------------------------------    
    renderGraficosVelocidadeMediaModal = () => {
        return(
            <div>
                <GraficosModal
                    show={this.state.graficosVelocidadeMediaModalShow}
                    titulo={"Velocidade Média"}
                    tipo_chamada={2}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                />    
            </div>);
    };
    //--------------------
    showGraficosVelocidadeMediaModal = () => {
        this.setState({graficosVelocidadeMediaModalShow:true});
        this.setState({graficosVelocidadeMediaModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosVelocidadeMediaModalShow:true});
    };
    //-----------------------
    closeGraficosVelocidadeMediaModal = () =>{
        this.setState({graficosVelocidadeMediaModalShow:false});
    };   
    //-------------------
    desativarGraficosVelocidadeMediaModal = () =>{
        this.setState({graficosVelocidadeMediaModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosVelocidadeMediaModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosVelocidadeMediaModalShow:false});
    };
    //--------------------------------------------------GraficosQuantidadePosicoesModal-----------------------------------------------------------------    
    renderGraficosQuantidadePosicoesModal = () => {
        return(
            <div>
                <GraficosModal
                    show={this.state.graficosQuantidadePosicoesModalShow}
                    titulo={"Quantidade de Posições"}
                    tipo_chamada={3}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                />    
            </div>);
    };
    //--------------------
    showGraficosQuantidadePosicoesModal = () => {
        this.setState({graficosQuantidadePosicoesModalShow:true});
        this.setState({graficosQuantidadePosicoesModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosQuantidadePosicoesModalShow:true});
    };
    //-----------------------
    closeGraficosQuantidadePosicoesModal = () =>{
        this.setState({graficosQuantidadePosicoesModalShow:false});
    };   
    //-------------------
    desativarGraficosQuantidadePosicoesModal = () =>{
        this.setState({graficosQuantidadePosicoesModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosQuantidadePosicoesModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosQuantidadePosicoesModalShow:false});
    };    
    //--------------------------------------------------GraficosQuantidadeEventosModal-----------------------------------------------------------------    
    renderGraficosQuantidadeEventosModal = () => {
        return(
            <div>
                <GraficosModal
                    show={this.state.graficosQuantidadeEventosModalShow}
                    titulo={"Quantidade de Eventos"}
                    tipo_chamada={4}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                />    
            </div>);
    };
    //--------------------
    showGraficosQuantidadeEventosModal = () => {
        this.setState({graficosQuantidadeEventosModalShow:true});
        this.setState({graficosQuantidadeEventosModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosQuantidadeEventosModalShow:true});
    };
    //-----------------------
    closeGraficosQuantidadeEventosModal = () =>{
        this.setState({graficosQuantidadeEventosModalShow:false});
    };   
    //-------------------
    desativarGraficosQuantidadeEventosModal = () =>{
        this.setState({graficosQuantidadeEventosModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosQuantidadeEventosModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosQuantidadeEventosModalShow:false});
    };        
    //--------------------------------------------------GraficosColetasEntregasModal-----------------------------------------------------------------    
    renderGraficosColetasEntregasModal = () => {
        return(
            <div>
                <GraficosModal
                    show={this.state.graficosColetasEntregasModalShow}
                    titulo={"Coletas e Entregas"}
                    tipo_chamada={5}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                />    
            </div>);
    };
    //--------------------
    showGraficosColetasEntregasModal = () => {
        this.setState({graficosColetasEntregasModalShow:true});
        this.setState({graficosColetasEntregasModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosColetasEntregasModalShow:true});
    };
    //-----------------------
    closeGraficosColetasEntregasModal = () =>{
        this.setState({graficosColetasEntregasModalShow:false});
    };   
    //-------------------
    desativarGraficosColetasEntregasModal = () =>{
        this.setState({graficosColetasEntregasModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosColetasEntregasModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosColetasEntregasModalShow:false});
    };        
    //--------------------------------------------------GraficosColetasClientesModal-----------------------------------------------------------------    
    renderGraficosColetasClientesModal = () => {
        return(
            <div>
                <GraficosModal
                    show={this.state.graficosColetasClientesModalShow}
                    titulo={"Coletas por Clientes"}
                    tipo_chamada={6}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                />    
            </div>);
    };
    //--------------------
    showGraficosColetasClientesModal = () => {
        this.setState({graficosColetasClientesModalShow:true});
        this.setState({graficosColetasClientesModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosColetasClientesModalShow:true});
    };
    //-----------------------
    closeGraficosColetasClientesModal = () =>{
        this.setState({graficosColetasClientesModalShow:false});
    };   
    //-------------------
    desativarGraficosColetasClientesModal = () =>{
        this.setState({graficosColetasClientesModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosColetasClientesModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosColetasClientesModalShow:false});
    };            
    //--------------------------------------------------GraficosEntregasClientesModal-----------------------------------------------------------------    
    renderGraficosEntregasClientesModal = () => {
        return(
            <div>
                <GraficosModal
                    show={this.state.graficosEntregasClientesModalShow}
                    titulo={"Entregas por Clientes"}
                    tipo_chamada={7}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                />    
            </div>);
    };
    //--------------------
    showGraficosEntregasClientesModal = () => {
        this.setState({graficosEntregasClientesModalShow:true});
        this.setState({graficosEntregasClientesModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosEntregasClientesModalShow:true});
    };
    //-----------------------
    closeGraficosEntregasClientesModal = () =>{
        this.setState({graficosEntregasClientesModalShow:false});
    };   
    //-------------------
    desativarGraficosEntregasClientesModal = () =>{
        this.setState({graficosEntregasClientesModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosEntregasClientesModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosEntregasClientesModalShow:false});
    };                
    //--------------------------------------------------GraficosColetasMotoristasModal-----------------------------------------------------------------    
    renderGraficosColetasMotoristasModal = () => {
        return(
            <div>
                <GraficosModal
                    show={this.state.graficosColetasMotoristasModalShow}
                    titulo={"Coletas por Motoristas"}
                    tipo_chamada={8}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                />    
            </div>);
    };
    //--------------------
    showGraficosColetasMotoristasModal = () => {
        this.setState({graficosColetasMotoristasModalShow:true});
        this.setState({graficosColetasMotoristasModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosColetasMotoristasModalShow:true});
    };
    //-----------------------
    closeGraficosColetasMotoristasModal = () =>{
        this.setState({graficosColetasMotoristasModalShow:false});
    };   
    //-------------------
    desativarGraficosColetasMotoristasModal= () =>{
        this.setState({graficosColetasMotoristasModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosColetasMotoristasModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosColetasMotoristasModalShow:false});
    };           
    //--------------------------------------------------GraficosEntregasMotoristasModal-----------------------------------------------------------------    
    renderGraficosEntregasMotoristasModal = () => {
        return(
            <div>
                <GraficosModal
                    show={this.state.graficosEntregasMotoristasModalShow}
                    titulo={"Entregas por Motoristas"}
                    tipo_chamada={9}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                />    
            </div>);
    };
    //--------------------
    showGraficosEntregasMotoristasModal = () => {
        this.setState({graficosEntregasMotoristasModalShow:true});
        this.setState({graficosEntregasMotoristasModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosEntregasMotoristasModalShow:true});
    };
    //-----------------------
    closeGraficosEntregasMotoristasModal = () =>{
        this.setState({graficosEntregasMotoristasModalShow:false});
    };   
    //-------------------
    desativarGraficosEntregasMotoristasModal= () =>{
        this.setState({graficosEntregasMotoristasModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosEntregasMotoristasModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosEntregasMotoristasModalShow:false});
    };        
    //--------------------------------------------------GraficosModal-----------------------------------------------------------------    
    renderGraficosModal = () => {
        return(
            <div>
                <GraficosModal
                    show={this.state.graficosModalShow}
                    titulo={"Gráficos"}
                    tipo_chamada={5}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                />    
            </div>);
    };
    //--------------------
    showGraficosModal = () => {
        this.setState({graficosModalShow:true});
        this.setState({graficosModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosModalShow:true});
    };
    //-----------------------
    closeGraficosModal = () =>{
        this.setState({graficosModalShow:false});
    };   
    //-------------------
    desativarGraficosModal = () =>{
        this.setState({graficosModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({graficosModalShow:false});
    };     
    //--------------------------------------------------VeiculoInfoModal-----------------------------------------------------------------    
    renderVeiculoInfoModal = () => {
        return(
            <div>
                <VeiculoInfoModal
                    show={this.state.veiculoInfoModalShow}
                    titulo={"Cadastro do Veículo"}
                    tipoChamadaLista={2}
                    list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    veiculo={this.state.veiculo_selecionado}
                />    
            </div>);
    };
    //--------------------
    showVeiculoInfoModal = (veiculo_selecionado) => {
        this.setState({veiculoInfoModalShow:true});
        this.setState({veiculoInfoModalAtivo:true});
        this.setState({veiculo_selecionado: veiculo_selecionado});
        // this.props.MapaKseg.state.SideNavDrawer.setState({veiculoInfoModalShow:true});
    };
    //-----------------------
    closeVeiculoInfoModal = () =>{
        this.setState({veiculoInfoModalShow:false});
    };   
    //-------------------
    desativarVeiculoInfoModal = () =>{
        this.setState({veiculoInfoModalAtivo:false});
        // this.props.MapaKseg.state.SideNavDrawer.setState({veiculoInfoModalAtivo:false});
        // this.props.MapaKseg.state.SideNavDrawer.setState({veiculoInfoModalShow:false});
    };       
    //-------------------------------------------------------NotificacaoModal----------------------------------------------------------------
    renderNotificacaoModal = () => {
        return(
            <div>
                <NotificacaoModal  
                    show={this.state.notificacaoModalShow}
                    onHide={this.closeNotificacaoModal}
                    Modals={this}
                    MapaKseg={this.props.MapaKseg}
                />    
            </div>);
    };
    //--------------------
    showNotificacaoModal = () => {
        this.setState({notificacaoModalShow:true});
        this.setState({notificacaoModalAtivo:true});
    };
    //-------------------
    closeNotificacaoModal = () =>{
        this.setState({notificacaoModalShow:false});
    };   
    //-------------------
    desativarNotificacaoModal = () =>{
        this.setState({notificacaoModalAtivo:false});
        // this.setState({abrirModal:false});
       };
    //-------------------------------------------------------ComandoGridModal----------------------------------------------------------------
    renderComandoGridModal = () => {
        return(
            <div>
                <ComandoGridModal  
                    show={this.state.comandoGridModalShow}
                    onHide={this.closeComandoGridModal}
                    Modals={this}
                    MapaKseg={this.props.MapaKseg}
                    selectedRowData={this.state.selectedRowData}
                    titulo={this.state.selectedRowData.placa}
                />    
            </div>);
    };
    //--------------------
    showComandoGridModal= (selectedRowData) => {
        this.setState({comandoGridModalShow:true, selectedRowData: selectedRowData});
        this.setState({comandoGridModalAtivo:true});
    };
    //-------------------
    closeComandoGridModal = () =>{
        this.setState({comandoGridModalShow:false});
    };   
    //-------------------
    desativarComandoGridModal = () =>{
        this.setState({comandoGridModalAtivo:false});
        // this.setState({abrirModal:false});
       };       
    //-------------------------------------------------------RangeDataModal----------------------------------------------------------------
    renderRangeDataModal = () => {
        return(
            <div>
                <RangeDataModal  
                    show={this.state.rangeDataModalShow}
                    onHide={this.closeRangeDataModal}
                    Modals={this}
                    MapaKseg={this.props.MapaKseg}
                    titulo={""}
                    endDate={this.state.endDate}
                    GridOperacionalDetail={this.state.GridOperacionalDetail}
                />    
            </div>);
    };
    //--------------------
    showRangeDataModal= (endDate, GridOperacionalDetail) => {
        this.setState({rangeDataModalShow:true, endDate: endDate, GridOperacionalDetail: GridOperacionalDetail});
        this.setState({rangeDataModalAtivo:true});
    };
    //-------------------
    closeRangeDataModal = () =>{
        this.setState({rangeDataModalShow:false});
    };   
    //-------------------
    desativarRangeDataModal = () =>{
        this.setState({rangeDataModalAtivo:false});
        // this.setState({abrirModal:false});
       };             
    //-------------------------------------------------------CadastroModal----------------------------------------------------------------
    renderCadastroModal = () => {
        return(
            <div>
                <CadastroModal  
                    show={this.state.cadastroModalShow}
                    onHide={this.closeCadastroModal}
                    Modals={this}
                    MapaKseg={this.props.MapaKseg}
                    titulo={"Cadastro"}
                />    
            </div>);
    };
    //--------------------
    showCadastroModal= () => {
        // ToastStore.success("showCadastroModal ");
        this.setState({cadastroModalShow:true});
        this.setState({cadastroModalAtivo:true});
        this.props.MapaKseg.state.SideNavDrawer.setState({cadastroModalAtivo:true});

        if(this.state.cadastroModal !== null)
            this.state.cadastroModal.desativarFilterrow();
    };
    //-------------------
    closeCadastroModal = () =>{
        this.setState({cadastroModalShow:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({cadastroModalAtivo:false});
    };   
    //-------------------
    desativarCadastroModal = () =>{
        this.setState({cadastroModalAtivo:false});
        this.props.MapaKseg.state.SideNavDrawer.setState({cadastroModalAtivo:false});
    };                    
    //--------------------------------------------------CadCliente-----------------------------------------------------------------    
    renderCadCliente= () => {
        // console.log("poligono_selecionado Modal: ");
        // console.log(this.state.poligono_selecionado);
        return(
            <div>
                <CadCliente
                    show={this.state.cadClienteShow}
                    titulo={"Cadastro do Cliente"}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    poligono_selecionado={this.state.poligono_selecionado}
                />    
            </div>);
    };
    //--------------------
    showCadCliente = (poligono_selecionado) => {        
        this.setState({poligono_selecionado: poligono_selecionado});
        this.setState({cadClienteShow:true});
        this.setState({cadClienteAtivo:true});
    };
    //-----------------------
    closeCadCliente = () =>{
        this.setState({cadClienteShow:false});
    };   
    //-------------------
    desativarCadCliente = () =>{
        this.setState({cadClienteAtivo:false});
    };           
    //--------------------------------------------------CadColetaModal-----------------------------------------------------------------    
    renderCadColetaModal = () => {
        return(
            <div>
                <CadColetaModal
                    show={this.state.cadColetaShow}
                    titulo={"Coleta"}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    objeto={this.state.objeto}
                    obj_selected={this.state.obj_selected}
                />    
            </div>);
    };
    //--------------------
    showCadColeta= (objeto, obj_selected) => {        
        this.setState({objeto: objeto, obj_selected: obj_selected});

        this.setState({cadColetaAtivo:false},
            () => {         
                this.setState({cadColetaShow:true, cadColetaAtivo:true});        
            }
        );
    };
    //-----------------------
    closeCadColeta = () =>{
        this.setState({cadColetaShow:false});
    };   
    //-------------------
    desativarCadColeta = () =>{
        this.setState({cadColetaAtivo:false});
    };
    //--------------------------------------------------CadColetaSimplificadaModal-----------------------------------------------------------------    
    renderCadColetaSimplificadaModal = () => {
        return(
            <div>
                <CadColetaSimplificadaModal
                    show={this.state.cadColetaSimplificadaShow}
                    titulo={"Coleta Simplificada"}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    objeto={this.state.objeto}
                    obj_selected={this.state.obj_selected}
                />    
            </div>);
    };
    //--------------------
    showCadColetaSimplificadaModal= (objeto, obj_selected) => {        
        this.setState({objeto: objeto, obj_selected: obj_selected});

        this.setState({cadColetaSimplificadaAtivo:false},
            () => {         
                this.setState({cadColetaSimplificadaShow:true, cadColetaSimplificadaAtivo:true});        
            }
        );
    };
    //-----------------------
    closeCadColetaSimplificadaModal = () =>{
        this.setState({cadColetaSimplificadaShow:false});
    };   
    //-------------------
    desativarCadColetaSimplificadaModal = () =>{
        this.setState({cadColetaSimplificadaAtivo:false});
    };
    //--------------------------------------------------CadEntregaModal-----------------------------------------------------------------    
    renderCadEntregaModal = () => {
        return(
            <div>
                <CadEntregaModal
                    show={this.state.cadEntregaModalShow}
                    titulo={"Entrega"}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    objeto={this.state.objeto}
                    obj_selected={this.state.obj_selected}
                />    
            </div>);
    };
    //--------------------
    showCadEntregaModal = (objeto, obj_selected) => {        
        this.setState({objeto: objeto, obj_selected: obj_selected});

        this.setState({cadEntregaModalAtivo:false},
            () => {         
                this.setState({cadEntregaModalShow:true, cadEntregaModalAtivo:true});        
            }
        );
    };
    //-----------------------
    closeCadEntregaModal = () =>{
        this.setState({cadEntregaModalShow:false});
    };   
    //-------------------
    desativarCadEntregaModal = () =>{
        this.setState({cadEntregaModalAtivo:false});
    };    
    //--------------------------------------------------EntregaModal-----------------------------------------------------------------    
    renderEntregaModal = () => {
        return(
            <div>
                <EntregaModal
                    show={this.state.entregaModalShow}
                    titulo={"Entrega"}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    objeto={this.state.objeto}
                    obj_selected={this.state.obj_selected}
                />    
            </div>);
    };
    //--------------------
    showEntregaModal= (objeto, obj_selected) => {        
        this.setState({objeto: objeto, obj_selected:obj_selected});

        this.setState({entregaModalAtivo:false},
            () => {         
                this.setState({entregaModalShow:true, entregaModalAtivo:true});        
            }
        );
    };
    //-----------------------
    closeEntregaModal = () =>{
        this.setState({entregaModalShow:false});
    };   
    //-------------------
    desativarEntregaModal = () =>{
        this.setState({entregaModalAtivo:false});
    };
    //--------------------------------------------------ColetaEntregaModal-----------------------------------------------------------------    
    renderColetaEntregaModal = () => {
        return(
            <div>
                <ColetaEntregaModal
                    show={this.state.coletaEntregaModalShow}
                    titulo={"Coleta/Entrega"}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    objeto={this.state.objeto}
                    obj_selected={this.state.obj_selected}
                />    
            </div>);
    };
    //--------------------
    showColetaEntregaModal= (objeto, obj_selected) => {        
        this.setState({objeto: objeto, obj_selected:obj_selected});

        this.setState({coletaEntregaModalAtivo:false},
            () => {         
                this.setState({coletaEntregaModalShow:true, coletaEntregaModalAtivo:true});        
            }
        );
    };
    //-----------------------
    closeColetaEntregaModal = () =>{
        this.setState({coletaEntregaModalShow:false});
    };   
    //-------------------
    desativarColetaEntregaModal = () =>{
        this.setState({coletaEntregaModalAtivo:false});
    };
    //--------------------------------------------------GeralList-----------------------------------------------------------------    
    renderGeralListModal = () => {
        return(
            <div>
                <GeralListModal
                    show={this.state.geralListShow}
                    titulo={this.state.titulo}
                    // tipoChamadaLista={2}
                    // list={this.props.MapaKseg.state.list}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    objeto={this.state.objeto}
                    tipo={this.state.tipo}
                    botao_cadastrar={this.state.botao_cadastrar}
                    filtro={this.state.texto}
                    // veiculo={this.state.veiculo_selecionado}
                />    
            </div>);
    };
    //--------------------
    showGeralList= (objeto, titulo, tipo, botao_cadastrar=false, filtro='') => {        
        this.setState({objeto:objeto, titulo: titulo, tipo: tipo, geralListShow:true, botao_cadastrar: botao_cadastrar, texto: filtro},
            () => {         
                this.setState({geralListAtivo:true});
            })     
    };
    //-----------------------
    closeGeralList = () =>{ 
        this.setState({geralListShow:false});
    };   
    //-------------------
    desativarGeralList = () =>{
        this.setState({geralListAtivo:false});
    };    
    //--------------------------------------------------UsuarioCadModal-----------------------------------------------------------------    
    renderUsuarioCadModal = () => {
        return(
            <div>
                <UsuarioCadModal
                    show={this.state.usuarioCadModalShow}
                    titulo={"Usuário Cadastro"}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    objeto={this.state.objeto}
                    obj_selected={this.state.obj_selected}
                />    
            </div>);
    };
    //--------------------
    showUsuarioCadModal= (objeto, obj_selected) => {        
        this.setState({objeto: objeto, obj_selected: obj_selected, usuarioCadModalShow:true, usuarioCadModalAtivo:true});
    };
    //-----------------------
    closeUsuarioCadModal= () =>{
        this.setState({usuarioCadModalShow:false});
    };   
    //-------------------
    desativarUsuarioCadModal= () =>{
        this.setState({usuarioCadModalAtivo:false});
    };
    //--------------------------------------------------GrupoCadModal-----------------------------------------------------------------    
    renderGrupoCadModal = () => {
        return(
            <div>
                <GrupoCadModal
                    show={this.state.grupoCadModalShow}
                    titulo={"Grupo Cadastro"}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    objeto={this.state.objeto}
                    obj_selected={this.state.obj_selected}
                />    
            </div>);
    };
    //--------------------
    showGrupoCadModal= (objeto, obj_selected) => {        
        this.setState({objeto: objeto, obj_selected: obj_selected, grupoCadModalShow:true, grupoCadModalAtivo:true});
    };
    //-----------------------
    closeGrupoCadModal= () =>{
        this.setState({grupoCadModalShow:false});
    };   
    //-------------------
    desativarGrupoCadModal= () =>{
        this.setState({grupoCadModalAtivo:false});
    };
    //--------------------------------------------------PessoaCadModal-----------------------------------------------------------------    
    renderPessoaCadModal = () => {
        return(
            <div>
                <PessoaCadModal
                    show={this.state.pessoaCadShow}
                    titulo={"Pessoa Cadastro"}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    objeto={this.state.objeto}
                    obj_selected={this.state.obj_selected}
                />    
            </div>);
    };
    //--------------------
    showPessoaCadModal= (objeto, obj_selected) => {        
        this.setState({objeto: objeto, obj_selected: obj_selected, pessoaCadShow:true, pessoaCadAtivo:true});
    };
    //-----------------------
    closePessoaCadModal= () =>{
        this.setState({pessoaCadShow:false});
    };   
    //-------------------
    desativarPessoaCadModal= () =>{
        this.setState({pessoaCadAtivo:false});
    };
    //--------------------------------------------------LocalCadModal-----------------------------------------------------------------    
    renderLocalCadModal = () => {
        return(
            <div>
                <LocalCadModal
                    show={this.state.localCadShow}
                    titulo={"Local Cadastro"}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    objeto={this.state.objeto}
                    obj_selected={this.state.obj_selected}
                />    
            </div>);
    };
    //--------------------
    showLocalCadModal= (objeto, obj_selected) => {        
        this.setState({objeto: objeto, obj_selected: obj_selected, localCadShow:true, localCadAtivo:true});
    };
    //-----------------------
    closeLocalCadModal= () =>{
        this.setState({localCadShow:false});
    };   
    //-------------------
    desativarLocalCadModal= () =>{
        this.setState({localCadAtivo:false});
    };    
    //--------------------------------------------------MunicipioCadModal-----------------------------------------------------------------    
    renderMunicipioCadModal = () => {
        return(
            <div>
                <MunicipioCadModal
                    show={this.state.municipioCadShow}
                    titulo={"Município Cadastro"}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    objeto={this.state.objeto}
                    obj_selected={this.state.obj_selected}
                />    
            </div>);
    };
    //--------------------
    showMunicipioCadModal= (objeto, obj_selected) => {        
        this.setState({objeto: objeto, obj_selected: obj_selected, municipioCadShow:true, municipioCadAtivo:true});
    };
    //-----------------------
    closeMunicipioCadModal= () =>{
        this.setState({municipioCadShow:false});
    };   
    //-------------------
    desativarMunicipioCadModal= () =>{
        this.setState({municipioCadAtivo:false});
    };     
    //--------------------------------------------------FilialCadModal-----------------------------------------------------------------    
    renderFilialCadModal = () => {
        return(
            <div>
                <FilialCadModal
                    show={this.state.filialCadShow}
                    titulo={"Filial Cadastro"}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    objeto={this.state.objeto}
                    obj_selected={this.state.obj_selected}
                />    
            </div>);
    };
    //--------------------
    showFilialCadModal= (objeto, obj_selected) => {        
        this.setState({objeto: objeto, obj_selected: obj_selected, filialCadShow:true, filialCadAtivo:true});
    };
    //-----------------------
    closeFilialCadModal= () =>{
        this.setState({filialCadShow:false});
    };   
    //-------------------
    desativarFilialCadModal= () =>{
        this.setState({filialCadAtivo:false});
    };          
    //--------------------------------------------------RotaCadModal-----------------------------------------------------------------    
    renderRotaCadModal = () => {
        return(
            <div>
                <RotaCadModal
                    show={this.state.rotaCadShow}
                    titulo={"Rota Cadastro"}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    objeto={this.state.objeto}
                    obj_selected={this.state.obj_selected}
                />    
            </div>);
    };
    //--------------------
    showRotaCadModal= (objeto, obj_selected) => {        
        this.setState({objeto: objeto, obj_selected: obj_selected, rotaCadShow:true, rotaCadAtivo:true});
    };
    //-----------------------
    closeRotaCadModal= () =>{
        this.setState({rotaCadShow:false});
    };   
    //-------------------
    desativarRotaCadModal= () =>{
        this.setState({rotaCadAtivo:false});
    };      
    //--------------------------------------------------ClienteCadModal-----------------------------------------------------------------    
    renderClienteCadModal = () => {
        return(
            <div>
                <ClienteCadModal
                    show={this.state.clienteCadShow}
                    titulo={"Cliente Cadastro"}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    objeto={this.state.objeto}
                    obj_selected={this.state.obj_selected}
                />    
            </div>);
    };
    //--------------------
    showClienteCadModal= (objeto, obj_selected) => {        
        this.setState({objeto: objeto, obj_selected: obj_selected, clienteCadShow:true, clienteCadAtivo:true});
    };
    //-----------------------
    closeClienteCadModal= () =>{
        this.setState({clienteCadShow:false});
    };   
    //-------------------
    desativarClienteCadModal= () =>{
        this.setState({clienteCadAtivo:false});
    };     
    //--------------------------------------------------ImageCropperModal-----------------------------------------------------------------    
    renderImageCropperModal = () => {
        return(
            <div>
                <ImageCropperModal
                    show={this.state.imageCropperShow}
                    titulo={"Imagem"}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}
                    objeto={this.state.objeto}
                    obj_selected={this.state.obj_selected}
                />    
            </div>);
    };
    //--------------------
    showImageCropperModal= (objeto, obj_selected) => {        
        this.setState({objeto: objeto, obj_selected: obj_selected, imageCropperShow:true, imageCropperAtivo:true});
    };
    //-----------------------
    closeImageCropperModal= () =>{
        this.setState({imageCropperShow:false});
    };   
    //-------------------
    desativarImageCropperModal= () =>{
        this.setState({imageCropperAtivo:false});
    };        
    //--------------------------------------------------ObservacaoModal-----------------------------------------------------------------    
    renderObservacaoModal = () => {
        return(
            <div>
                <ObservacaoModal
                    show={this.state.observacaoModalShow}
                    onHide={this.closeObservacaoModal}
                    cancelar={this.state.botao_cancelar}
                    MapaKseg = {this.props.MapaKseg}
                    Modals={this}  
                    objeto={this.state.objeto}
                    titulo={this.state.titulo}
                    texto={this.state.texto}
                    tipo={this.state.tipo}
                />            
            </div>);
    };
    //--------------------
    showObservacaoModal = (objeto, titulo, texto, botao_cancelar, tipo) => {
        this.setState({objeto: objeto, texto: texto, botao_cancelar: botao_cancelar, titulo:titulo, tipo: tipo});  

        this.setState({observacaoModalAtivo:false},
            () => {         
                this.setState({observacaoModalShow:true, observacaoModalAtivo:true});        
            }
        )
    };
    //-------------------
    closeObservacaoModal = () =>{
        this.setState({observacaoModalShow:false});
    };
    //-------------------------
    desativarObservacaoModal = () =>{
        this.setState({observacaoModalAtivo:false});
    };
    //--------------------------------------------------ComprovanteModal-----------------------------------------------------------------    
    renderComprovanteModal = () => {
        return(
            <div>
                <ComprovanteModal
                    show={this.state.comprovanteModalShow}
                    onHide={this.closeComprovanteModal}
                    cancelar={this.state.botao_cancelar}
                    MapaKseg = {this.props.MapaKseg}
                    Modals={this}  
                    objeto={this.state.objeto}
                    titulo={this.state.titulo}
                    obj_selected={this.state.obj_selected}
                    tipo={this.state.tipo}
                />            
            </div>);
    };
    //--------------------
    showComprovanteModal = (objeto, titulo, obj_selected, tipo) => {
        this.setState({objeto: objeto, titulo:titulo, obj_selected: obj_selected, tipo: tipo});  

        this.setState({comprovanteModalAtivo:false},
            () => {         
                this.setState({comprovanteModalShow:true, comprovanteModalAtivo:true});        
            }
        )
    };
    //-------------------
    closeComprovanteModal = () =>{
        this.setState({comprovanteModalShow:false});
    };
    //-------------------------
    desativarComprovanteModal = () =>{
        this.setState({comprovanteModalAtivo:false});
    };    
    //--------------------------------------------------XmlModal-----------------------------------------------------------------    
    renderXmlModal = () => {
        return(
            <div>
                <XmlModal
                    show={this.state.xmlModalShow}
                    onHide={this.closeXmlModal}
                    cancelar={this.state.botao_cancelar}
                    MapaKseg = {this.props.MapaKseg}
                    Modals={this}  
                    objeto={this.state.objeto}
                    titulo={this.state.titulo}
                    obj_selected={this.state.obj_selected}
                    tipo={this.state.tipo}
                />            
            </div>);
    };
    //--------------------
    showXmlModal = (objeto, titulo, obj_selected, tipo) => {
        this.setState({objeto: objeto, titulo:titulo, obj_selected: obj_selected, tipo: tipo});  

        this.setState({xmlModalAtivo:false},
            () => {         
                this.setState({xmlModalShow:true, xmlModalAtivo:true});        
            }
        )
    };
    //-------------------
    closeXmlModal = () =>{
        this.setState({xmlModalShow:false});
    };
    //-------------------------
    desativarXmlModal = () =>{
        this.setState({xmlModalAtivo:false});
    };        
    //--------------------------------------------------PagoAtualizarModal-----------------------------------------------------------------    
    renderPagoAtualizarModal = () => {
        return(
            <div>
                <PagoAtualizarModal
                    show={this.state.pagoAtualizarModalShow}
                    onHide={this.closePagoAtualizarModal}
                    cancelar={this.state.botao_cancelar}
                    MapaKseg = {this.props.MapaKseg}
                    Modals={this}  
                    objeto={this.state.objeto}
                    titulo={this.state.titulo}
                    obj_selected={this.state.obj_selected}
                    tipo={this.state.tipo}
                />            
            </div>);
    };
    //--------------------
    showPagoAtualizarModal = (objeto, titulo, obj_selected, tipo) => {
        this.setState({objeto: objeto, titulo:titulo, obj_selected: obj_selected, tipo: tipo});  

        this.setState({pagoAtualizarModalAtivo:false},
            () => {         
                this.setState({pagoAtualizarModalShow:true, pagoAtualizarModalAtivo:true});        
            }
        )
    };
    //-------------------
    closePagoAtualizarModal = () =>{
        this.setState({pagoAtualizarModalShow:false});
    };
    //-------------------------
    desativarPagoAtualizarModal = () =>{
        this.setState({pagoAtualizarModalAtivo:false});
    };     
    //--------------------------------------------------PesquisaColetaModal-----------------------------------------------------------------    
    renderPesquisaColetaModal = () => {
        return(
            <div>
                <PesquisaColetaModal
                    show={this.state.pesquisaColetaModalShow}
                    onHide={this.closePesquisaColetaModal}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}   
                    objeto={this.state.objeto}             
                    titulo={"Pesquisar Coleta"}
                />            
            </div>);
    };
    //--------------------
    showPesquisaColetaModal = (objeto) => {
        this.setState({pesquisaColetaModalShow:true, objeto: objeto});      
        this.setState({pesquisaColetaModalAtivo:true});      
    };
    //-------------------
    closePesquisaColetaModal = () =>{
        this.setState({pesquisaColetaModalShow:false});
    };
    //-------------------------
    desativarPesquisaColetaModal = () =>{
        this.setState({pesquisaColetaModalAtivo:false});
    };
    //--------------------------------------------------PesquisaEntregaModal-----------------------------------------------------------------    
    renderPesquisaEntregaModal = () => {
        return(
            <div>
                <PesquisaEntregaModal
                    show={this.state.pesquisaEntregaModalShow}
                    onHide={this.closePesquisaEntregaModal}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}   
                    objeto={this.state.objeto}             
                    titulo={"Pesquisar Entrega"}
                />            
            </div>);
    };
    //--------------------
    showPesquisaEntregaModal = (objeto) => {
        this.setState({objeto: objeto});           
        this.setState({pesquisaEntregaModalAtivo:false},
            () => {         
                this.setState({pesquisaEntregaModalShow:true, pesquisaEntregaModalAtivo:true});        
            }
        );
    };
    //-------------------
    closePesquisaEntregaModal = () =>{
        this.setState({pesquisaEntregaModalShow:false});
    };
    //-------------------------
    desativarPesquisaEntregaModal = () =>{
        this.setState({pesquisaEntregaModalAtivo:false});
    };
    //--------------------------------------------------ConcluirColetaEntregaModal-----------------------------------------------------------------    
    renderConcluirColetaEntregaModal = () => {
        return(
            <div>
                <ConcluirColetaEntregaModal
                    show={this.state.concluirColetaEntregaModalShow}
                    onHide={this.closeConcluirColetaEntregaModal}
                    MapaKseg={this.props.MapaKseg}
                    Modals={this}   
                    objeto={this.state.objeto}             
                    titulo={this.state.titulo}
                    obj_selected={this.state.obj_selected}
                />            
            </div>);
    };
    //--------------------
    showConcluirColetaEntregaModal = (objeto, titulo, obj_selected) => {
        this.setState({concluirColetaEntregaModalShow:true, objeto: objeto, titulo: titulo, obj_selected});      
        this.setState({concluirColetaEntregaModalAtivo:true});      
    };
    //-------------------
    closeConcluirColetaEntregaModal = () =>{
        this.setState({concluirColetaEntregaModalShow:false});
    };
    //-------------------------
    desativarConcluirColetaEntregaModal = () =>{
        this.setState({concluirColetaEntregaModalAtivo:false});
    };
    //--------------------------------------------------PesquisarModal-----------------------------------------------------------------    
    renderPesquisarModal = () => {
        return(
            <div>
                <PesquisarModal
                    show={this.state.pesquisarModalShow}
                    onHide={this.closePesquisarModal}
                    cancelar={this.state.botao_cancelar}
                    MapaKseg = {this.props.MapaKseg}
                    Modals={this}  
                    objeto={this.state.objeto}
                    titulo={this.state.titulo}
                    tipo={this.state.tipo}
                />            
            </div>);
    };
    //--------------------
    showPesquisarModal = (objeto, titulo, botao_cancelar, tipo) => {
        this.setState({objeto: objeto, botao_cancelar: botao_cancelar, titulo:titulo, tipo: tipo});  

        this.setState({pesquisarModalAtivo:false},
            () => {         
                this.setState({pesquisarModalShow:true, pesquisarModalAtivo:true});        
            }
        ) 
    };
    //-------------------
    closePesquisarModal = () =>{        
        this.setState({pesquisarModalShow:false });  
    };
    //-------------------------
    desativarPesquisarModal = () =>{
        this.setState({pesquisarModalAtivo:false});
    };
//---------------------------------------------------------------------------------------------------------------------------------------------- 
    setModalSelecionado = async(modalSelecionado) =>{         
        this.setState({modalSelecionado: modalSelecionado, gruposSeq:8});
        this.setState({updateRender:true});
        await this.sleep(500);
        this.setState({updateRender:false});
        // ToastStore.success("setModalSelecionado ");
    };
    //-------------------------
    setExpanded= async(expanded) =>{         
        this.props.MapaKseg.setState({sideNavDrawer_expanded: expanded});
    };
    //-----------------
    sleep (milliseconds) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    }
    //-------------------------
    sequenceRenderModal = () =>{   
        // var sequenceRenderList=this.state.sequenceRenderList;
        // ToastStore.success("modalSelecionado: "+this.state.modalSelecionado);
        if(this.state.sequenceRenderList !==null)
            ToastStore.success("sequenceRenderModal len: "+String(this.state.sequenceRenderList.length));
        // if(this.state.modalSelecionado === null){
        // if(this.state.sequenceRenderList.length ===0){
             var sequenceRenderList =[
                {seq: 0, class:"GruposVeiculoModal", ativoModal: this.state.gruposVeiculoModalAtivo, renderModal: this.renderGrupoVeiculoModal()}
                ,{seq: 1, class:"TempGridModal", ativoModal: this.state.tempGridModalAtivo, renderModal: this.renderTempGridModal()}
                ,{seq: 2, class:"MonitoresModal", ativoModal: this.state.monitoresModalAtivo, renderModal: this.renderMonitoresModal()}
                ,{seq: 3, class:"RelatoriosModal", ativoModal: this.state.relatoriosModalAtivo, renderModal: this.renderRelatoriosModal()}
                ,{seq: 4, class:"RelPosicoesVeiModal", ativoModal: this.state.relPosicoesVeiModalAtivo, renderModal: this.renderRelPosicoesVeiModal()}
                ,{seq: 5, class:"CadastroFaixasModal", ativoModal: this.state.cadastroFaixasModalAtivo, renderModal: this.renderCadastroFaixasModal()}
                ,{seq: 6, class:"TemperaturaModal", ativoModal: this.state.temperaturaModalAtivo, renderModal: this.renderTemperaturaModal()}
            ];
            // this.setState({sequenceRenderList:sequenceRenderList});
            // await this.sleep(10000);
            ToastStore.success("modalSelecionado: "+this.state.modalSelecionado);
        // }

        if(this.state.modalSelecionado !== null){
            // var sequenceRenderList = this.state.sequenceRenderList;
            var seq_maior=0;
            for(var i=0; i<sequenceRenderList.length; i++){
                if(sequenceRenderList[i].seq>seq_maior)
                    seq_maior=sequenceRenderList[i].seq;
            }

            for(var i=0; i<sequenceRenderList.length; i++){
                if(sequenceRenderList[i].class=== this.state.modalSelecionado)
                    sequenceRenderList[i].seq= seq_maior+1
            }

            sequenceRenderList.sort((a,b) => a.seq - b.seq);

            // this.setState({modalSelecionado: null});

            // this.setState({sequenceRenderList:sequenceRenderList});
        }
        // this.setState({updateRender:false});

        return(<div>{sequenceRenderList.map(item =>  item.ativoModal ? item.renderModal: ""  )}</div>);
        
        // ToastStore.error("sequenceRenderModal len: "+String(sequenceRenderList.length));
    };
    //-------------------------
    renderModais = () =>{         
        // ToastStore.success("renderModais");
        var sequenceRenderList = this.state.sequenceRenderList;   
        ToastStore.success("sequenceRenderModal len: "+String(sequenceRenderList.length));
        // this.setState({updateRender:false});
        return(<div>{sequenceRenderList.map(item =>  item.ativoModal ? item.renderModal: ""  )}</div>);
    };
    //----------------------------sequenceRenderList.sort((a,b) => a.seq - b.seq)
    sortModals = (sequenceRenderList) =>{     
        ToastStore.error("sortModals");
        // var seqRenderList = sequenceRenderList.sort((a,b) => a.seq - b.seq)
        var seqRenderList = sequenceRenderList;
        return seqRenderList;
    };
    //------------------------------------------------------------------------------------------------------------------------------
    render(){
        var sequenceRenderList =[
            {seq: this.state.gruposSeq, class:"GruposVeiculoModal", ativoModal: this.state.gruposVeiculoModalAtivo, renderModal: this.renderGrupoVeiculoModal()}
            ,{seq: 1, class:"TempGridModal", ativoModal: this.state.tempGridModalAtivo, renderModal: this.renderTempGridModal()}
            ,{seq: 2, class:"MonitoresModal", ativoModal: this.state.monitoresModalAtivo, renderModal: this.renderMonitoresModal()}
            ,{seq: 3, class:"RelatoriosModal", ativoModal: this.state.relatoriosModalAtivo, renderModal: this.renderRelatoriosModal()}
            ,{seq: 4, class:"RelPosicoesVeiModal", ativoModal: this.state.relPosicoesVeiModalAtivo, renderModal: this.renderRelPosicoesVeiModal()}
            ,{seq: 5, class:"CadastroFaixasModal", ativoModal: this.state.cadastroFaixasModalAtivo, renderModal: this.renderCadastroFaixasModal()}
            ,{seq: 6, class:"TemperaturaModal", ativoModal: this.state.temperaturaModalAtivo, renderModal: this.renderTemperaturaModal()}
        ];
        return(        
            <div>


        {this.state.tempGridModalAtivo ? this.renderTempGridModal(): ""} 
        {this.state.gruposVeiculoModalAtivo ? this.renderGrupoVeiculoModal(): ""}         
        {this.state.monitoresModalAtivo ? this.renderMonitoresModal() : ""}
        {this.state.relatoriosModalAtivo ? this.renderRelatoriosModal() : ""}    
        {this.state.relPosicoesVeiModalAtivo ? this.renderRelPosicoesVeiModal(): ""}
        {this.state.relEventosVeiModalAtivo ? this.renderRelEventosVeiModal() : ""}
        {this.state.relColetaModalAtivo ? this.renderRelColetaModal() : ""}
        {this.state.relEntregaModalAtivo ? this.renderRelEntregaModal() : ""}
        {this.state.relColetaEntregaModalAtivo ? this.renderRelColetaEntregaModal() : ""}
        {this.state.relCaixaModalAtivo ? this.renderRelCaixaModal() : ""}
        {this.state.relJornadaMotoristaModalAtivo ? this.renderRelJornadaMotoristaModal() : ""}
        {this.state.cadastroFaixasModalAtivo ? this.renderCadastroFaixasModal() : ""}   
        {this.state.temperaturaModalAtivo? this.renderTemperaturaModal() :""}        
        {this.state.poligonoOpcoesModalAtivo? this.renderPoligonoOpcoesModal() :""}
        {this.state.poligonosModalAtivo? this.renderPoligonosModal() :""} 
        {this.state.addNotificacaoVeiModalAtivo? this.renderAddNotificacaoVeiModal() :""}
        {this.state.mensagemModalAtivo? this.renderMensagemModal() :""} 
        {this.state.graficosListaModalAtivo? this.renderGraficosListaModal() :""} 
        {this.state.graficosKmPercorridaModalAtivo? this.renderGraficosKmPercorridaModal() :""} 
        {this.state.graficosVelocidadeMediaModalAtivo? this.renderGraficosVelocidadeMediaModal() :""} 
        {this.state.graficosQuantidadePosicoesModalAtivo? this.renderGraficosQuantidadePosicoesModal() :""} 
        {this.state.graficosQuantidadeEventosModalAtivo? this.renderGraficosQuantidadeEventosModal() :""} 
        {this.state.graficosColetasEntregasModalAtivo? this.renderGraficosColetasEntregasModal() :""} 
        {this.state.graficosColetasClientesModalAtivo? this.renderGraficosColetasClientesModal() :""} 
        {this.state.graficosEntregasClientesModalAtivo? this.renderGraficosEntregasClientesModal() :""} 
        {this.state.graficosColetasMotoristasModalAtivo? this.renderGraficosColetasMotoristasModal() :""} 
        {this.state.graficosEntregasMotoristasModalAtivo? this.renderGraficosEntregasMotoristasModal() :""} 
        {this.state.graficosModalAtivo? this.renderGraficosModal() :""} 
        {this.state.notificacaoModalAtivo ? this.renderNotificacaoModal() : ""}        
        {this.state.rangeDataModalAtivo ? this.renderRangeDataModal() : ""}
        {this.state.cadastroModalAtivo ? this.renderCadastroModal() : ""}
        {this.state.usuarioModalAtivo? this.renderUsuarioModal() :""}
        {this.state.cadClienteAtivo ? this.renderCadCliente() : ""}
        {this.state.pesquisaColetaModalAtivo? this.renderPesquisaColetaModal() :""}
        {this.state.pesquisaEntregaModalAtivo? this.renderPesquisaEntregaModal() :""}
        {this.state.concluirColetaEntregaModalAtivo? this.renderConcluirColetaEntregaModal() :""}
        {this.state.cadColetaAtivo? this.renderCadColetaModal() :""} 
        {this.state.cadColetaSimplificadaAtivo? this.renderCadColetaSimplificadaModal() :""} 
        {this.state.cadEntregaModalAtivo? this.renderCadEntregaModal() :""} 
        {this.state.entregaModalAtivo? this.renderEntregaModal() :""} 
        {this.state.coletaEntregaModalAtivo? this.renderColetaEntregaModal() :""} 
        {this.state.comandoGridModalAtivo ? this.renderComandoGridModal() : ""}
        {this.state.veiculoInfoModalAtivo? this.renderVeiculoInfoModal() :""}         
        {this.state.usuarioCadModalAtivo? this.renderUsuarioCadModal() :""}                 
        {this.state.grupoCadModalAtivo? this.renderGrupoCadModal() :""}     
        {this.state.pessoaCadAtivo? this.renderPessoaCadModal() :""}
        {this.state.localCadAtivo? this.renderLocalCadModal() :""}
        {this.state.municipioCadAtivo? this.renderMunicipioCadModal() :""}
        {this.state.filialCadAtivo? this.renderFilialCadModal() :""}
        {this.state.rotaCadAtivo? this.renderRotaCadModal() :""}
        {this.state.clienteCadAtivo? this.renderClienteCadModal() :""}
        {this.state.imageCropperAtivo? this.renderImageCropperModal() :""}
        {this.state.formModalAtivo? this.renderFormModal() :""}
        {this.state.observacaoModalAtivo? this.renderObservacaoModal() :""}        
        {this.state.pesquisarModalAtivo? this.renderPesquisarModal() :""}  
        {this.state.comprovanteModalAtivo? this.renderComprovanteModal() :""}  
        {this.state.xmlModalAtivo? this.renderXmlModal() :""}  
        {this.state.pagoAtualizarModalAtivo? this.renderPagoAtualizarModal() :""}  

            
        {this.state.geralListAtivo? this.renderGeralListModal() :""}
        {/* {this.state.updateRender ? this.sequenceRenderModal(): ""} */}

        {/* {this.state.updateRender ? sequenceRenderList=this.sortModals(sequenceRenderList) : ""} */}

        {/* <div>
        {this.state.sequenceRenderList.map(item =>  item.ativoModal ? item.renderModal: ""  )}
        </div> */}
        

        {/* condição ? true : false. */}

        </div>
        );
    }
}