import  {Component} from 'react';
import api from './api';
import {ToastStore} from 'react-toasts';
import Moment from 'moment';

// import {Polyline} from 'google-maps-react';

export default class GetGruposVeiculo extends Component{
    constructor(props, objeto, MapaKseg){
        super(props);
        this.state = {
             grupo: {},
             list: [],
             loading:true,
             objeto: objeto,
             usuario_id: props,
             MapaKseg: MapaKseg,
       }
    }
    //---------------------
    toastStore = (texto) => {
        ToastStore.success("teste: "+texto);
    };
    //--------------------
    getGruposVei = async () => {
        try {
            // ToastStore.success("usuario_id: "+String(this.state.usuario_id));
            let body = new FormData()
            body.append('usuario_id', this.state.usuario_id)
            body.append('token', localStorage.getItem('token'))

            // console.log("  ***datetime: "+datetime)

            const response = await api.post('/login_api/gruposVeiculos', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);

                
                let grupo = this.state.grupo;
                let list = this.state.list;                

                // ToastStore.success("resposta.length: "+ String(resposta.length));
                for (var i = 0; i < resposta.length; i++) {                        
                    grupo = {    
                        grupo_id: resposta[i].grupo_id,
                        nome: resposta[i].nome,
                        tipo_grupo_id: resposta[i].tipo_grupo_id,
                        selecionada: false,
                        index_scroll: (i+2)*25,
                    };

                    if(localStorage.getItem('grupo_id') === String(grupo.grupo_id) )
                        grupo.selecionada=true;

                    list.push(grupo);
                    this.setState({list: list});
                }

                this.setState({loading: false});
                
            }else{
            }

        } finally {
                // ToastStore.success("GetPo coords.length: "+ String(coords.length));
                // ToastStore.success("PPlaca: "+ String(lastPosition.data_receb));
                // this.state.objeto.setCoords(coords, lastPosition, markerEvento_list);
                this.state.objeto.setState({gruposVei_list: this.state.list});

                let height_modal = 120;
                // if(this.state.list.length === 2)
                //     height_modal = 160;
                // if(this.state.list.length > 2)
                //     height_modal = 55 * this.state.list.length;
                if(this.state.list.length < 6 && this.state.list.length > 1)
                    height_modal = 80 * this.state.list.length;
                else if(this.state.list.length >= 6)
                    height_modal = 35 * this.state.list.length;

                let grupos_nome_list =[];
                for (var i = 0; i < this.state.list.length; i++)
                    grupos_nome_list.push(this.state.list[i].nome);    

                var list_aux = this.state.list.filter((item,index) =>{  
                    return item.grupo_id === Number(localStorage.getItem('grupo_id'));
                  })

                let nome = '';
                // if(list_aux[0].nome !== undefined)
                //     nome = list_aux[0].nome 

                grupos_nome_list = grupos_nome_list.sort((a,b) => a.localeCompare(b));       

                if(list_aux.length > 0)
                    if(list_aux[0].nome !== undefined)
                        nome = list_aux[0].nome
                                

                this.state.objeto.setState({height_modal: height_modal, grupos_nome_list: grupos_nome_list, filterStatus: nome});
                this.state.objeto.setState({loading: false});
                // this.state.MapaKseg.setState({grupo_id: this.state.list[0].grupo_id});                
                // this.state.MapaKseg.getPosicoesList();

            }
    };
    //--------------------------
    async componentDidMount() {
        this.getGruposVei();
    };
}