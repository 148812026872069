import React, {Component} from 'react';
import {Modal, Image, Spinner, Button, Form, Row,Col,Card,Nav,InputGroup,FormControl} from 'react-bootstrap';
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import GetRotas from '../services/get_rotas';
import GetFilial from '../services/get_filial';
import GetRotaMunicipio from '../services/get_rota_municipio';
import GetRotaUsuario from '../services/get_rota_usuario';
import PostRotaCad from '../services/post_rota_cad';
import {RotaMunicipioGrid} from '../datagrid/rota_municipio_grid';
import {UsuariosGrupoGrid} from '../datagrid/usuarios_grupo_grid';

const FILIAL =1;

export class RotaCadModal extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            load:true,
            minimizar:false,
            changed:false,
            rota:null,

            GeralListModal:null,            
            RotaGrid:null,

            height_modal:500,
            height_grid:0,
            items:[],
            editados: {},
            grupo:{},

            salvando:false,            
        };

         // -------------------------------
        this.dataGridRef = React.createRef();
    
        this.selectedRowsData = [];

        this.getSelectedData = () => {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData();
            this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            });
        }
      }
    //---------------------------------------------------------------
    get dataGrid() {
        return this.dataGridRef.current.instance;
    }
    //-------------------------------------------------------------------
    async componentDidMount() {
        // INICIALIZAR VARIÁVEIS

        this.setState({RotaGrid: this.props.objeto});

        let editados = {
              descricao:false
            , filial:false
            , municipios: false
            , usuarios: false
             };

        let rota = this.props.obj_selected;
        rota.municipios = [];
        let grupo = {};        
        grupo.usuarios = [];

        this.setState({
            rota: rota       
            ,grupo:grupo    
            ,editados: editados        
            , height_grid: this.state.height_modal-320},
            () => { 
                this.getRotaMunicipio()
                this.getRotaUsuario()
                this.setState({load:false}) }
          )
    }; 
    //----------------------------------------------------------------
    getRota = () =>{
        var instance = new GetRotas(null, this, this.props.MapaKseg,0, this.state.rota.rota_id );
        instance.get();   
    };    
    //----------------------------------------------------------------
    getRotaMunicipio = () =>{
        var instance = new GetRotaMunicipio(null, this, this.props.MapaKseg, 0, this.state.rota.rota_id );
        instance.get();   
    }; 
    //----------------------------------------------------------------
    getRotaUsuario = () =>{
        var instance = new GetRotaUsuario(null, this, this.props.MapaKseg, 0, this.state.rota.rota_id );
        instance.get();   
    };     
    //--------------------------------------------------------------------
    setListRota = (_list) => {
        let rota = this.state.rota;
        let obj = _list[0];

        rota.rota_id = obj.rota_id;
        rota.descricao = obj.descricao;
        rota.filial_id = obj.filial_id;
        rota.filial = obj.filial;

        this.setState({rota: rota});
    } 
    //-------------------------------------------------------------
    setListUsuarios =  (_list) => {  
        let grupo = this.state.grupo;
        grupo.usuarios = _list;
        this.setState({grupo:grupo});
    }; 
    //------------------------------------------------------------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closeRotaCadModal();  
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeRotaCadModal();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarRotaCadModal();
    };    
    //------------------------------------------------------
    updateInputValue(event) {
        var editados = this.state.editados;
        var rota = this.state.rota;

        if(event.target.id==="formDescricao"){
            rota.descricao = event.target.value;
            editados.descricao = true;
        }else 
        if(event.target.id==="formFilial"){
            rota.filial_id = event.target.value;
            editados.filial = true;
        }

        this.setState({rota: rota});
        this.setState({editados: editados, changed: true});
     }
         //-------------------------------------------------------------------
    showGeralList= (e) => {   
        if(e.target.getAttribute('value') == FILIAL)
            this.props.Modals.showGeralList(this,"Filial", FILIAL);           
    };
    //-----------------------------------------------------
    getList= (geralListModal, tipo) => { 
        this.setState({GeralListModal: geralListModal});  

        if(tipo == FILIAL){             
            var instance = new GetFilial(null, this, this.props.MapaKseg, FILIAL, 0);
            instance.get(); 
        }
    };
    //-------------------------------------------------------------
    setListFilial =  (_list, tipo) => {  
        let array =[]
        let sel=false;
        for (var i = 0; i < _list.length; i++) { 
            if(this.state.rota.filial_id == _list[i].filial_id) sel=true;
            else sel=false;

            array.push({
                index: _list[i].index
                , id: _list[i].filial_id
                , descricao: _list[i].descricao
                , selecionada: sel
                , index_scroll: 0});
        }
        this.state.GeralListModal.setList(array, tipo);
    };   
    //-------------------------------------------------------------
    setListRotaMunicipio =  (_list) => {  
        let rota = this.state.rota;
        rota.municipios = _list;
        this.setState({rota:rota});
    }; 
    //-------------------------------------------------------------
    setListRotaUsuario =  (_list) => {  
        let grupo = this.state.grupo;
        grupo.usuarios = _list;
        this.setState({grupo:grupo});
    };     
    //---------------------------------------------------
    setObjeto= (objeto, tipo) => { 
        if(tipo == FILIAL){
            let rota = this.state.rota;
            rota.filial_id = objeto.id;
            rota.filial = objeto.descricao;            
            this.setState({rota: rota});            

            let editados =this.state.editados;
            editados.filial =  true;
            this.setState({editados: editados });
        }

        this.setState({changed:true});
    };
    //--------------------------------------
    concluido=()=> {
        this.getRota();
        this.getRotaMunicipio();
        this.getRotaUsuario();
        this.setState({salvando:false, changed:false });     
        this.state.RotaGrid.setRota(this.state.rota);  
    }
    //--------------------------------------
    validar=()=> {
        let valido = true;

        if(String(this.state.rota.descricao).trim()==="" && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe a Rota!");
        }

        if(this.state.rota.filial_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe a Filial!");
        }         
    
        if(valido==true){
            this.setState({salvando:true, changed:true});
            var instance = new PostRotaCad(null, this, this.props.MapaKseg);
            instance.post(); 
        }
    }
    //-------------------------
    render(){
        return(
            <div className="drag1">
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            closeOnEsc={true}
                            showCloseButton={false}
                            duration={600}
                            width={600}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.load ?
                                        <Spinner className="centro3" animation="border" role="status">
                                            <span className="sr-only"></span>
                                        </Spinner>
                                    :
                                        <div style={{height: this.state.height_modal, overflow:'hidden'}}>
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                                                {/*------------------------------------ */}
                                                <Form>                                                       
                                                    <Form.Group className="mb-1" as={Row} controlId="formDescricao">
                                                        <Form.Label column sm={3}>
                                                            Rota
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>{this.state.rota.rota_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <FormControl aria-describedby="basic-addon1" value={this.state.rota.descricao}  onChange={this.updateInputValue.bind(this)}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>        

                                                    <Form.Group className="mb-1" as={Row} controlId="formFilial">
                                                        <Form.Label column sm={3}>
                                                        Filial
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <InputGroup className="mb-3" >
                                                                <InputGroup.Prepend >
                                                                    <Button id="formFilialBt" value={FILIAL} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                        <Image value={FILIAL} src={require('../img/text_box_search48.png')} width='20'/>
                                                                    </Button>
                                                                </InputGroup.Prepend>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text onChange={this.updateInputValue.bind(this)} >{this.state.rota.filial_id}</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control disabled aria-describedby="basic-addon1" value={this.state.rota.filial}  />
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group>     
                                                    {/* ----------------Tab---------------------------------------------------- */}
                                                    <Card>
                                                        <Card.Header>
                                                            <Nav variant="tabs" defaultActiveKey="#Usuarios">   
                                                                <Nav.Item >
                                                                    <Nav.Link  data-toggle="tab" href="#Usuarios">Usuários</Nav.Link>
                                                                </Nav.Item>                                               
                                                                <Nav.Item >
                                                                    <Nav.Link  data-toggle="tab" href="#Municipios">Municípios</Nav.Link>
                                                                </Nav.Item>                                                                                                                                            
                                                            </Nav>
                                                        </Card.Header>

                                                        <div class="tab-content">      
                                                            <Card.Body id="Usuarios" className="tab-pane active">
                                                                <UsuariosGrupoGrid  
                                                                    objeto={this}
                                                                    height_grid={this.state.height_grid} 
                                                                    MapaKseg={this.props.MapaKseg} 
                                                                    Modals={this.props.Modals}/>                                                                                                                                   
                                                            </Card.Body>   

                                                            <Card.Body id="Municipios" className="tab-pane fade">
                                                                <RotaMunicipioGrid  
                                                                    objeto={this}
                                                                    height_grid={this.state.height_grid} 
                                                                    MapaKseg={this.props.MapaKseg} 
                                                                    Modals={this.props.Modals}/>                                                                                                                                   
                                                            </Card.Body>                                                                                                                                                                          
                                                        </div>
                                                    </Card>                                                                                                                                                                                                                                                                               

                                                </Form>
                                                {/* ----------------------------------- */}
                                            </CustomScroll>


                                            <Modal.Footer >
                                                <div className="align_bottom_right">
                                                    <Button disabled={!this.state.changed} onClick={this.validar}>
                                                        {this.state.salvando
                                                            ? <Spinner size="sm"  as="span" animation="grow" role="status" aria-hidden="true"/>                                                                
                                                        :
                                                        <div>
                                                            <img src={require('../img/checkbold24.png')}/> Salvar 
                                                        </div>
                                                        }                                                                
                                                    </Button>
                                                </div> 
                                            </Modal.Footer>

                                        </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}