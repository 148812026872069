import  {Component} from 'react';
import api from '../api';
import {ToastStore} from 'react-toasts';
import Moment from 'moment';

// import {Polyline} from 'google-maps-react';

export default class GetColetas extends Component{
    constructor(props, objeto, MapaKseg, rotas){
        super(props);
        this.state = {
             rota_filial: {},
             list: [],
             loading:true,
             objeto: objeto,
             rotas: rotas,
             coleta:null,
             MapaKseg: MapaKseg,
             count_status: [{status:'Aberta', count:0}, {status:'Fechada', count:0}, {status:'Atrasada', count:0}]
       }
    }
    //-----------------------------------------------------------------------------------------
    setEntregasCount = (count) =>{
        if(count===1) return  require("../img/circle1.png");
        else if(count===2) return require("../img/circle2.png")
        else if(count===3) return require("../img/circle3.png")
        else if(count===4) return require("../img/circle4.png")
        else if(count===5) return require("../img/circle5.png")
        else if(count>5) return require("../img/circle5plus.png")
        else if(count>9) return require("../img/circle9plus.png")                                            
    };
    //--------------------------------------------------------------
    somarFreteTDA =(_frete, _tda) =>{
        _frete = parseFloat(String(_frete).replace(".", "").replace(",", "."));
  
        if (String(_tda) === "undefined")  _tda = 0;
        
        if (_tda !== "") _tda = parseFloat(String(_tda).replace(".", "").replace(",", "."));
        else _tda = 0;
  
        let total = _frete + _tda;
        total = total.toLocaleString('pt-br', {minimumFractionDigits: 2});
        return total;        
      }    
    //------------------------------------------------
    get = async () => {
        try {
            var rotas = this.state.rotas;
            var rotas_id_str="";
            for (var i = 0; i < rotas.length; i++) {  
                if (i==0) rotas_id_str+=rotas[i].rota_id;
                else rotas_id_str+=","+rotas[i].rota_id;
            }

            if(rotas_id_str === "") rotas_id_str = "0"

            // ToastStore.success("rotas_id_str: "+ String(rotas_id_str))   

            let body = new FormData();
            body.append('rotas_id_str', rotas_id_str);
            body.append('pessoa_id', this.state.MapaKseg.state.pessoa_id);
            body.append('token', localStorage.getItem('token'));

            const response = await api.post('/coletaweb_api/getColetas1', body);
            // const response = await api.post('/teste_api/getColetas1', body);
            // const response = await api.post('/coleta_api/getColetas1', body); 
            
            var responseJson = null;

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let coleta = this.state.coleta;
                let list = this.state.list;      
                let hoje = new Date();        
                let count_status = this.state.count_status;  

                // ToastStore.success("resposta.length: "+ String(resposta.length));
                for (var i = 0; i < resposta.length; i++) {                        
                    coleta = {    
                        index: i+1,
                        coleta_id: resposta[i].coleta_id,
                        cliente_id: resposta[i].cliente_id,
                        cliente: resposta[i].cliente,
                        rota_id: resposta[i].rota_id,
                        rota: resposta[i].rota,
                        data_previsao: resposta[i].data_previsao,
                        data_previsao2: resposta[i].data_previsao2,
                        data_coleta: resposta[i].data_coleta,
                        data_coleta2: resposta[i].data_coleta2,
                        tipo_pagamento_id: resposta[i].tipo_pagamento_id,
                        tipo_pagamento: resposta[i].tipo_pagamento,
                        valor: resposta[i].valor,
                        tda: resposta[i].tda,
                        total_frete_tda: 0, 
                        local_id: resposta[i].local_id,
                        local: resposta[i].local,
                        entrega_cliente: resposta[i].entrega_cliente,
                        usuario: resposta[i].usuario,
                        entregas: resposta[i].entregas,                        
                        municipio: resposta[i].municipio,
                        observacao: resposta[i].observacao,
                        cte: resposta[i].cte,
                        pago: resposta[i].pago,
                        nota_fiscal: resposta[i].nota_fiscal,
                        pago_str: '',
                        canhoto: resposta[i].comprovante,
                        canhoto64: '',

                        status:2,
                        selecionada: false,
                        index_scroll: (i+2)*25,
                        entregas_count_icon: null,
                    };                    

                    if(String(coleta.data_coleta).trim() !== ""){
                        coleta.status = 1 // 1 - fechada
                        count_status[1].count +=1;
                    }else if( new Date(coleta.data_previsao).getTime() < hoje.getTime()){                        
                        coleta.status = 3 // 3 - atrasada                    
                        count_status[2].count +=1;
                    }else{
                        count_status[0].count +=1;
                    }    

                    if(coleta.entregas)
                        coleta.entregas_count_icon = this.setEntregasCount(coleta.entregas.length);

                    if(coleta.pago === true)
                        coleta.pago_str = 'Foi Pago'
                    else if(coleta.pago === false)    
                        coleta.pago_str = 'Não Pago'

                    if(coleta.tda === "None") coleta.tda = "";   

                    if(coleta.nota_fiscal === "None") coleta.nota_fiscal = "";
                    
                    coleta.total_frete_tda = this.somarFreteTDA(coleta.valor, coleta.tda);

                    list.push(coleta);                    
                }

                list = list.sort((a,b) => new Date(b.data_previsao).getTime() - new Date(a.data_previsao).getTime() );                

                this.setState({list: list, count_status: count_status});

                this.setState({loading: false});
                
            }
        } finally {      
            this.state.objeto.setState({coletas: this.state.list, count_status: this.state.count_status});
            this.state.objeto.setState({load: false});
            // this.state.MapaKseg.toast(this.state.list.length);
            }
    };
    //--------------------------
}


// coleta = {    
//     coleta_id: resposta[i].rota_id,
//     descricao: resposta[i].descricao,
//     data_previsao: resposta[i].data_previsao,
//     data_coleta: resposta[i].data_coleta,
//     data_cadastro: resposta[i].data_cadastro,
//     valor: resposta[i].valor,
//     usuario_id: resposta[i].usuario_id,
//     rota_id: resposta[i].rota_id,
//     cliente_id: resposta[i].cliente_id,
//     entrega_cliente_id: resposta[i].entrega_cliente_id,
//     entrega_data_previsao: resposta[i].entrega_data_previsao,
//     entrega_rota_id: resposta[i].entrega_rota_id,
//     entrega_local_id: resposta[i].entrega_local_id,
//     tipo_pagamento_id: resposta[i].tipo_pagamento_id,
//     urgente: resposta[i].urgente,
//     usuario_cadastro_id: resposta[i].usuario_cadastro_id,
//     status:1,


//     selecionada: false,
//     index_scroll: (i+2)*25,
//     Status:1,
// };