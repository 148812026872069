import  {Component} from 'react';
import api from '../api';

export default class GetFilial extends Component{
    constructor(props, objeto, MapaKseg, tipo, filial_id){
        super(props);
        this.state = {
             list: [],
             objeto: objeto,
             MapaKseg: MapaKseg,
             tipo:tipo,
             filial_id:filial_id
       }
    }
    //------------------------------------------------
    get = async () => {
        try {
            let body = new FormData()
            if(this.state.filial_id !==0)
                body.append('filial_id', this.state.filial_id);
                
            body.append('token', localStorage.getItem('token'));

            const response = await api.post('/cadastro_api/getFilial', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let obj = null;
                let list = this.state.list;                
                
                for (var i = 0; i < resposta.length; i++) {                        
                    obj = {    
                        index: i+1,
                        filial_id: resposta[i].filial_id,                        
                        descricao: resposta[i].descricao,
                        pessoa_id: resposta[i].pessoa_id,
                        nome_social: resposta[i].nome_social,
                        municipio_id: resposta[i].municipio_id,
                        municipio: resposta[i].municipio,
                        comissao: resposta[i].comissao,
                        comissao_str: '',
                        operacao:0
                    };                   

                    if(obj.comissao === "None") obj.comissao = "";
                    else{ 
                        obj.comissao = parseFloat(obj.comissao);
                        obj.comissao_str = String(parseFloat(obj.comissao)*100)+'%';
                    }

                    list.push(obj);
                    
                }   
                this.setState({list: list});             
            }
        } finally {      
            this.state.objeto.setListFilial(this.state.list, this.state.tipo);
            // this.state.MapaKseg.toast(this.state.list.length);
            }
    };
}