import React, {Component} from 'react';
import '../estilo.css';
import 'rodal/lib/rodal.css';
import GetTabs from '../services/get_tabs';
import DataGrid, { Column, Scrolling, Selection } from 'devextreme-react/data-grid';
import ContextMenu from 'devextreme-react/context-menu';

const TABS_TIPO =2;

const INSERT =1;
const DELETE =2;
const UPDATE =3;

export class UsuarioTabsGrid extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            GeralListModal:null,
            height_grid:0,
            items:[],
            load:true,
        };
        // -------------------------------
        this.dataGridRef = React.createRef();
    
        this.selectedRowsData = [];

        this.getSelectedData = () => {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData();
            this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            });
        }
      }

    //---------------------------------------------------------------
    get dataGrid() {
        return this.dataGridRef.current.instance;
    }
    //-------------------------------------------------------------------
    async componentDidMount() {        
        this.carregarItems();
    }; 
    //----------------------------------------------------------------
    //-----------------------------------------------------------------------
    carregarItems() {  
        let items = this.state.items;
        items.push({ text: ' Add', icon: "dx-icon-add"  });
        items.push({ text: ' Remover', icon: "dx-icon-remove"  });
        items.push({ text: ' Marcar como Principal', icon: "dx-icon-todo"  });

        this.setState({ items: items  }, 
            () => { this.setState({load:false})})
    };
    //-------------------------------------------------------------------
    ItemTemplate (e) {
        return (
        <React.Fragment>
            <span className={ e.icon } />
            { e.items ? <span className="dx-icon-spinright" /> : null }
            { e.text }
        </React.Fragment>
        );
    }
    //---------------------------------------------------------------
    itemClick = (e) => {
        if (!e.itemData.items) {
        let selectedRowData = this.dataGrid.getSelectedRowsData(); 
        // alert(e.itemData.text+"  "+selectedRowData[0].nome);

        if(e.itemData.text === this.state.items[0].text){
            this.props.Modals.showGeralList(this,"Tabs", 0);    
        }else 
            if(e.itemData.text === this.state.items[1].text)
                this.remover(selectedRowData[0]);
        else 
            if(e.itemData.text === this.state.items[2].text)
                this.marcarPrincipalTab(selectedRowData[0]);
               

        }
    }
    //-------------------------------------------------------------------
    marcarPrincipalTab (obj) {
        // alert(obj.tab_id);
        let tabs = this.props.objeto.state.usuario.tabs;

        for (var j = 0; j < tabs.length; j++) { 
            if(tabs[j].tab_id == obj.tab_id){
                tabs[j].principal = true;

                if(tabs[j].usuario_tab_id != 0 )
                    tabs[j].operacao = UPDATE;
                
            }else{  
                if(tabs[j].principal == true){
                    tabs[j].principal = false;
                    if(tabs[j].usuario_tab_id != 0 )
                        tabs[j].operacao = UPDATE;
                }
            }
        }

        let editados = this.props.objeto.state.editados;
        editados.tabs = true;

        let usuario = this.props.objeto.state.usuario;
        usuario.tabs = tabs;

        usuario.tabs = usuario.tabs.concat(); // para não precisar dar reload no grid

        this.props.objeto.setState({ 
                usuario: usuario
                , changed: true
                , editados: editados});

        // this.setState({load: true}, () => { this.setState({load:false})})
    }
    //-------------------------------------------------------------------
    procurarOutroObj (list) {     
        let obj = null;
        for (var i = 0; i < list.length; i++) { 
            if(list[i].principal==false){
                if(list[i].operacao != DELETE){
                    obj = list[i];
                    break;
                }
            }
        }
        return obj;
    }
    //-------------------------------------------------------------------
    remover (obj) {   
        let tabs = this.props.objeto.state.usuario.tabs;
        let obj_aux = null;

        if(tabs.length == 1) this.props.MapaKseg.toastError("Não pode remover o único!");
        else
         if(obj.usuario_tab_id === 0 ){
            tabs = tabs.filter((g,index) =>{ return g.tab_id !== obj.tab_id; });
        }else{
            for (var j = 0; j < tabs.length; j++) { 
                if(tabs[j].tab_id === obj.tab_id){                                                             
                        obj_aux = this.procurarOutroObj(tabs.filter((g,index) =>{ return g.tab_id !== tabs[j].tab_id ;}));

                        if(obj_aux == null){
                            this.props.MapaKseg.toastError("Não pode remover todos!");
                            break;
                        }

                        tabs[j].operacao = DELETE;
                }
            }            
        }        

        let editados = this.props.objeto.state.editados;
        editados.tabs =  true;

        let usuario = this.props.objeto.state.usuario;
        usuario.tabs = tabs;
        usuario.tabs = usuario.tabs.concat(); // para não precisar dar reload no grid

        this.props.objeto.setState({ 
            usuario: usuario
            , changed: true
            , editados: editados});

        // this.setState({load: true}, () => { this.setState({load:false})})
    }
    //------------------------------------------------------------------------    
    onRowPrepared(e) {  
        if(e.data !== undefined){
            if (e.data.operacao === 0 ){
                if(e.data.index %2 === 0)
                    e.rowElement.style.backgroundColor = "#F5F5F5";
                else e.rowElement.style.backgroundColor = "#FFFFFF";                

            }else if (e.data.operacao === 1 || e.data.operacao === 3)
                    e.rowElement.style.backgroundColor = "#92d1b0";
            else if(e.data.operacao===2)
                e.rowElement.style.backgroundColor = "#f593a0";     
        }      
    } 
    //-----------------------------------------------------
    getList= (geralListModal, tipo) => { 
        this.setState({GeralListModal: geralListModal});  

        var instance = new GetTabs(null, this, this.props.MapaKseg, 0);
        instance.get(); 
    };
    //-------------------------------------------------------------
    setListTab =  (_list, tipo) => {  
        let array =[]
        let adicionar=true;
        let tabs = this.props.objeto.state.usuario.tabs;

        for (var i = 0; i < _list.length; i++) { 
            for (var j = 0; j < tabs.length; j++) { 
                if(tabs[j].tab_id == _list[i].tab_id){
                    adicionar=false;
                    break;
                }else adicionar=true;
            }

            if(adicionar==true){
                array.push({id: _list[i].tab_id
                    , descricao: _list[i].descricao
                    , selecionada: false
                    , index_scroll: 0});
            }
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //---------------------------------------------------
    setObjeto= (objeto, tipo) => { 
        let principal =false;
        let tabs1 = this.props.objeto.state.usuario.tabs;

        if (tabs1.length == 0) principal =true;

        let tab ={
            usuario_tab_id: 0
            , tab_id: objeto.id
            , descricao: objeto.descricao
            , principal: principal
            , operacao: INSERT};

        let tabs = this.props.objeto.state.usuario.tabs;
        // tabs = tabs.concat(tab);
        let lista = [];
        lista.push(tab);
        lista = lista.concat(tabs);

        let usuario = this.props.objeto.state.usuario;
        usuario.tabs = lista;

        this.props.objeto.setState({usuario: usuario});

        let editados = this.props.objeto.state.editados;
        editados.tabs =  true;

        this.props.objeto.setState({
                editados: editados 
                ,changed:true});
        
    };
    //------------------------------------------------------------------------------
    render(){
        const columns = ['tab_id','descricao','principal'];

        const onContextMenuPreparing = (e) =>{  // precisa desse para selecionar a linha com o botao direito
            try{
              e.component.selectRows(e.row.key, false)
              var selectedRowData = this.dataGrid.getSelectedRowsData();
              this.props.MapaKseg.ajustarZoom(selectedRowData[0]);
      
            //   console.log(e.row.key);
              // alert(e.row.key);
            }catch (response){}
          }

        return(
            this.state.load? ""
            :<div >                
                 <ContextMenu
                    dataSource={this.state.items}
                    width={180}
                    target="#UsuarioTabsGrid"
                    itemRender={this.ItemTemplate}
                    onItemClick={this.itemClick} 
                    value={"Tabss"}/>

                
                    <DataGrid
                        id="UsuarioTabsGrid"
                        ref={this.dataGridRef}
                        dataSource={this.props.objeto.state.usuario.tabs}
                        showBorders={true}
                        // rowAlternationEnabled={true}
                        defaultColumns={columns}
                        onRowPrepared={this.onRowPrepared}
                        columnAutoWidth={true}
                        onContextMenuPreparing={onContextMenuPreparing}
                        height={this.props.height_grid}
                        > 
                        <Selection mode="single" />        
                        <Scrolling mode="virtual" />
                    </DataGrid>                       
            </div>        
        );
    }
}