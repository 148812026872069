import React, {Component} from 'react';
import {Modal, Form, Button as Button_bootstrap, Row, Col} from 'react-bootstrap';
import './estilo.css';
import { ToastStore} from 'react-toasts';


export  class MensagemModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true, 
            showSheet:true,
            texto_list:[]
        };
      }   
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeMensagemModal();
    };
    //------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarMensagemModal();
    };
    //-----------------------------------------------------------------------
    async componentDidMount() {
        let texto_list = this.props.texto.split('\n');
        this.setState({texto_list: texto_list});
    }
    //------------------------
    okClick = ()=>{
        this.props.objeto.confirmarMensagem(this.props.titulo);
        this.fecharModal();
    };
    //-------------------------  <img src={this.props.comando.icone} /> {this.props.comando.descricao}
    render(){
        return(
            <div className="splashscreen-modal1">
                <Modal  centered
                    {...this.props}
                    
                    aria-labelledby="example-custom-modal-styling-title"
                    scrollable="True"
                    dialogClassName="temperatura-modal2"    
                    >
                    <div >
                        <strong className="cursor">
                            <div className="cabecalho"> 
                            <label className="titulo2" > {this.props.titulo} </label>
                                {/* <label className="titulo2" > Aviso! </label> */}
                            </div>
                        </strong>
                            
                        <Modal.Body>           
                            <Form.Group as={Row} >                                                                  
                                {this.props.imagem !== ""?
                                    <Col sm={3}>
                                        <img  src={this.props.imagem} width="100"/>
                                    </Col>
                                :""}    

                                <Col sm={8}>
                                    {/* <text>{this.props.texto.replace('<br/>', '\n')}</text> */}
                                    {/* <text>{this.props.texto.replace('\n', <br/>)}</text> */}

                                    {this.state.texto_list.map((texto) => (
                                                            <text>{texto} <br/> </text>
                                                            
                                                        ))}
                                </Col>
                            </Form.Group>              

                                
                        </Modal.Body>
                        
                        {/* <Modal.Body>                                                                        
                            {this.props.tipo_mensagem===1?
                                <div className="horizontal">
                                    <img className="espaco_right" src={require('./img/img_flechas.png')}/>
                                    <p className="recuo">O Carregamento de muitas Setas de posição ocasionam sobrecarga no mapa.<br></br> Para evitar lentidão, ative as Setas somente quando for necessário.</p>
                                </div>
                            : this.props.tipo_mensagem===2?
                                <div >
                                    <img className="espaco_right" src={require('./img/trash24.png')}/>
                                    Deseja realmente excluir área <b>{this.props.texto}</b> ?
                                </div>
                            :""}
                        </Modal.Body> */}
                    </div>


                    <Modal.Footer>
                        {this.props.cancelar ?
                            <Button_bootstrap className="left" onClick={this.props.onHide}>Cancelar</Button_bootstrap> : ""
                        }
                        <Button_bootstrap onClick={this.okClick}> Confirmar</Button_bootstrap>                
                    </Modal.Footer>

                </Modal>

            </div>
        );
    }
}