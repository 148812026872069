import React, {Component} from 'react';
import './estilo.css';
import { ToastStore} from 'react-toasts';
import {CabecalhoModal} from './CabecalhoModal';
import {PostPoligono} from './PostPoligono';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import { Modal, Button, Carousel, ListGroup, Form, Spinner } from 'react-bootstrap';
import api from './api';

const VISUALIZAR_POLIGONO =0;
const NOVO_POLIGONO =1;
const EDITAR_POLIGONO =2;

export class PoligonosModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            animation: "zoom",
            scrollOffset: 0,
            activeIndexCarousel:0,
            point_list: [],
            salvando:false,
            descricao:"",
            modo_poligono: VISUALIZAR_POLIGONO,
            poligono_id_selecionado:0,

            temas: [
                {id:1, nome:"Editar", selecionado:false, img: require('./img/edit_48dp.png'), index_scroll:0}
                ,{id:2, nome:"Excluir", selecionado:false, img: require('./img/delete_48dp.png'), index_scroll:0}
                ,{id:3, nome:"Notificação", selecionado:false, img: require('./img/plus.png'), index_scroll:0}
            ],
        };
      }
    //-------------------
    minimizarModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.setState({animation: "slideLeft"});
        this.props.Modals.closePoligonosModal();
    };
    //---------close----------
    fecharModal = () =>{
        // this.setState({relPosicoesVeiModalShow:false});
        this.props.MapaKseg.setState({poligonos_list:[], carregarPolygon: false
            , carregarPolygonNovo:false, poligono_novo_list:[], poligono_novo_latlngs:[]
            ,modo_poligono: VISUALIZAR_POLIGONO});
        this.setState({animation: "zoom"});
        this.props.Modals.closePoligonosModal();
    };
    //---------close----------
    postNovoPoligono = () =>{
        if(!this.state.salvando){
            if(this.state.descricao==="")
                return this.props.MapaKseg.callToastError("Informe o nome da Área!");
            if(this.props.MapaKseg.state.poligono_novo_list.length<3) 
                return this.props.MapaKseg.callToastError("Adicione mais pontos da Área!");
                
            this.setState({salvando:true});
            let poligono = { tipo_poligono_id: this.state.tipo_poligono_id
                            ,descricao: this.state.descricao
                            , grupo_id: this.props.grupo_id
            };
            var instance = new PostPoligono(poligono, this.props.MapaKseg); 
            instance.postPoligono(this.state.modo_poligono, this);
        }
    };
    //------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.poligonosModalDesativar();
    };
    //-------------------------
    onVoltarClick = ()=>{
        // if(this.state.file){
        //     this.state.ImageCropper.setState({imageDestination:""});
        //     this.setState({file:null, render_image:false});
        // }
        this.setState({activeIndexCarousel:0});
        this.props.MapaKseg.setState({ carregarPolygonNovo:false, poligono_novo_list:[], poligono_novo_latlngs:[], modo_poligono: VISUALIZAR_POLIGONO});
    }
    //------------------------------------------
    setStateTema = (id) =>{
        // Step 1: Find the element
        const elementsIndex = this.state.temas.findIndex(element => element.id == id );
        // Step 2: Create a copy of the state array
        let newArray = [...this.state.temas];
        // Step 3: Update the one value
        newArray[elementsIndex] = {...newArray[elementsIndex], selecionado: !newArray[elementsIndex].selecionado};
        // Step 4: SetState
        this.setState({temas: newArray,});
        // this.setState({temas: newArray, scrollOffset: newArray[elementsIndex].index_scroll, });
    };
    //------------------------------------------
    disableEnableTema = (id_disable, id_enable) =>{
        // Step 1: Find the element
        const index_disable = this.state.temas.findIndex(element => element.id == id_disable );
        const index_enable = this.state.temas.findIndex(element => element.id == id_enable );
        // Step 2: Create a copy of the state array
        let newArray = [...this.state.temas];
        // Step 3: Update the one value
        newArray[index_disable] = {...newArray[index_disable], selecionado: !newArray[index_disable].selecionado};
        newArray[index_enable] = {...newArray[index_enable], selecionado: !newArray[index_enable].selecionado};
        // Step 4: SetState
        this.setState({temas: newArray,});
    };
    //------------------------------------------
    onItemClick = (poligono) =>{
        // this.props.MapaKseg.callToast("onItemClick "+String(poligono.poligono_id));
        // this.props.MapaKseg.ajustarZoom(poligono.dados[0]);
        this.props.MapaKseg.setState({
            zooom: this.props.MapaKseg.state.zooom+20,
            center: {
              lat: poligono.dados[0].latitude,
              lng: poligono.dados[0].longitude
            }});
        // if(tema_mapa !== this.props.MapaKseg.state.tema_mapa){
        //     this.disableEnableTema(this.props.MapaKseg.state.tema_mapa, tema_mapa);
        //     this.props.MapaKseg.setTemaMapa(tema_mapa)
        // }
    };  
    //---------------------
    setCor = (tipo_poligono_id) =>{    
        switch(tipo_poligono_id) {
            case 1:
                return "#ED4145"; // vermelho
            case 2:
                return "#008000";  // verde                                         
            case 3:
                return "#0277bd"; // azul
            case 4:
                return "#FFFF00";  //amarelo                     
            case 5:
                return "#FFA500";  //laranja
            case 6:
                return "#808080"; // cinza  
            default:
                return "#808080";  
        }
    };
    //---------------------
    setFlag = (tipo_poligono_id) =>{    
        switch(tipo_poligono_id) {
            case 1:
                return require('./img/poligono/ic_flag_red.png'); // vermelho
            case 2:
                return require('./img/poligono/ic_flag_green.png');  // verde                                         
            case 3:
                return require('./img/poligono/ic_flag_blue.png'); // azul
            case 4:
                return require('./img/poligono/ic_flag_yellow.png');  //amarelo                     
            case 5:
                return require('./img/poligono/ic_flag_orange.png');  //laranja
            case 6:
                return require('./img/poligono/ic_flag_grey.png'); // cinza  
            default:
                return require('./img/poligono/ic_flag_grey.png');  
        }
    };  
     //------------------------------------------
     onNovoItemClick = (tipo_poligono_id) =>{

        this.setState({activeIndexCarousel: 1, tipo_poligono_id: tipo_poligono_id, descricao:"", modo_poligono: NOVO_POLIGONO});
        
        var poligono_novo_list = this.props.MapaKseg.state.poligono_novo_list;    
        var latitude = this.props.MapaKseg.state.list[0].latitude;
        var longitude = this.props.MapaKseg.state.list[0].longitude;
        var poligono = {
            id:1
            ,poligono_id : 111
            ,descricao : ""
            ,tipo_poligono_id: tipo_poligono_id
            ,flag: this.setFlag(tipo_poligono_id)
            , cor: this.setCor(tipo_poligono_id)
            ,latitude: latitude, longitude:longitude, lat_lng: String(latitude+", "+longitude)
            ,selecionado: true,};
            
        poligono_novo_list.push(poligono);

        // for (var i = 0; i < resposta.length; i++) {                        
        //     poligono = {  
        //         poligono_id : resposta[i].poligono_id,
        //         descricao : resposta[i].descricao,
        //         tipo_poligono_id : resposta[i].tipo_poligono_id,
        //         sequencia : resposta[i].sequencia,
        //         latitude : resposta[i].latitude,
        //         longitude : resposta[i].longitude,
        //         poligono_coordenada_id : resposta[i].poligono_coordenada_id,
        //         selecionado: false,
        //     };

        var poligono_novo_latlngs = this.props.MapaKseg.state.poligono_novo_latlngs;
        if (this.props.MapaKseg.state.poligono_novo_latlngs.length===0){
            let lat_lng_list = [];
            lat_lng_list.push({lat: latitude, lng: longitude});

            poligono_novo_latlngs = {id:1, tipo_poligono_id: tipo_poligono_id
                ,flag: this.setFlag(tipo_poligono_id), cor: this.setCor(tipo_poligono_id)
                ,latitude: latitude, longitude:longitude
                , lat_lng_list: lat_lng_list}; 
        }else
            poligono_novo_latlngs.lat_lng_list.push({lat: latitude, lng: longitude});   

        this.props.MapaKseg.setState({poligono_novo_list:poligono_novo_list, poligono_novo_latlngs: poligono_novo_latlngs, carregarPolygonNovo: true});


    };
    //------------------------
    async componentDidMount() {
        this.props.Modals.setState({PoligonosModal:this});
    }
    //-------------------------
    async componentDidUpdate() {}
    //------------------------------------------
    setCarouselItem = (item) =>{
        this.setState({activeIndexCarousel:item});
    }; 
    //---------------------
    toastStore = (texto) => {
        ToastStore.success("teste: "+texto);
    };  
    //----------------------
    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({descricao: value});
      }
    //-----------------
    removeItem=async(id)=>{
        if(this.props.MapaKseg.state.poligono_novo_list.length>3){
            var itemLatLng = this.props.MapaKseg.state.poligono_novo_list.filter((item,index) =>{
                return item.id === id;
            })
            // this.props.MapaKseg.callToast(String(itemLatLng[0].latitude)+", "+String(itemLatLng[0].longitude));
            //------------remover das Flag----------
            var newItem = this.props.MapaKseg.state.poligono_novo_list.filter((item,index) =>{
                return item.id !== id;
            })
        
            this.props.MapaKseg.setState({
                poligono_novo_list: [...newItem]
            })
            //------------remover do poligono----------

            newItem = this.props.MapaKseg.state.poligono_novo_latlngs.lat_lng_list.filter((item,index) =>{
                // return index !== id-1;
                return (item.lat !== itemLatLng[0].latitude && item.lng !== itemLatLng[0].longitude);
                // return String(item.lat) !== String(itemLatLng[0].latitude);
            });

            var poligono_novo_latlngs = this.props.MapaKseg.state.poligono_novo_latlngs;
            poligono_novo_latlngs.lat_lng_list = newItem;
        
            this.props.MapaKseg.setState({
                poligono_novo_latlngs: poligono_novo_latlngs
            })
        }else this.props.MapaKseg.callToastError("Não pode remover mais pontos!");
    }        
    //----------------------------------------
    postDeletePoligono = async () => {
        try {
            var poligono_id = this.state.poligono_id_selecionado;
            let body = new FormData()
            body.append('poligono_id', poligono_id);
            body.append('token', localStorage.getItem('token'));
 
            const response = await api.post('/poligono_api/deletePoligono', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);

                var poligonos_list = this.props.MapaKseg.state.poligonos_list;

                poligonos_list = poligonos_list.filter((item,index) =>{  
                    return item.poligono_id !== poligono_id;
                })

                this.props.MapaKseg.setState({poligonos_list: poligonos_list});

                this.props.MapaKseg.callToast(String(resposta));
            }
                

        } finally {
            this.setState({loading: false});     
            this.setState({salvando:false});
            }
    };
    //------------------------------------------------
    mensagemConfirmacao = (poligono) => {
        this.setState({poligono_id_selecionado: poligono.poligono_id});
        let imagem = require('./img/delete_100dp.png');
        let texto = "Deseja realmente excluir área "+String(poligono.descricao);
        this.props.Modals.showMensagemModal(this, "Excluir Área", texto, true, imagem);
    }
    //------------------------------------------------
    confirmarMensagem = (texto) => {
        this.postDeletePoligono();
    }
    //-------------------------
    render(){
        //--------------------
        return(
            <div className="drag6" >
                <Draggable   handle="strong"  >                
                    <div >                    
                        <Rodal 
                            visible={this.props.show}
                            animation={this.state.animation}
                            showCloseButton={false}
                            duration={600}
                            width={330}
                            height={400}
                            onAnimationEnd={()=>this.desativarModal()}
                            >  
                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false} titulo={this.props.poligonos_list.length>0? this.props.poligonos_list[0].nome :"Áreas"} Modal={this}/></strong>
                                <div className="padding_modal">
                                <Carousel  interval={null}  controls={false} indicators={false} activeIndex={this.state.activeIndexCarousel}>                                    
                                    <Carousel.Item >                                                                      
                                        <div style={{height: '250px', overflow: 'hidden'}}>  
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                                                <ListGroup>{
                                                    this.props.poligonos_list.map((poligono) =>
                                                    poligono.dados[0].selecionado?
                                                            <ListGroup.Item action variant="dark" >
                                                                <div >
                                                                    <img className="margin_cantos5" src={poligono.flag} onClick={()=>this.onItemClick(poligono)} width="30"/>
                                                                    <text onClick={()=>this.onItemClick(poligono)} >{poligono.descricao+'  '}  </text>

                                                                    <span class="texto_canto_dir" >
                                                                        <img src={require("./img/trash24_gray.png")} width="24" onClick={()=>this.mensagemConfirmacao(poligono)}/>
                                                                    </span>                                                                                                          
                                                                </div>
                                                            </ListGroup.Item>
                                                            :                                            
                                                            <ListGroup.Item action variant="" >
                                                                <div >                                    
                                                                    <img className="margin_cantos5" src={poligono.flag} onClick={()=>this.onItemClick(poligono)} width="30"/>
                                                                    <text onClick={()=>this.onItemClick(poligono)} >{poligono.descricao+'  '}  </text>
                            
                                                                    <span  className="texto_canto_dir">
                                                                        <img src={require("./img/trash24_gray.png")} width="24" onClick={()=>this.mensagemConfirmacao(poligono)}/>
                                                                    </span> 
                                                             
                                                                </div>
                                                            </ListGroup.Item>
                                                        )}
                                                </ListGroup>   
                                            </CustomScroll>                                                
                                        </div>                                                  
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <div>
                                            {this.state.activeIndexCarousel===1?
                                                <Form.Control type="text" placeholder="Nome da área" onChange={this.handleChange.bind(this)} value={this.state.descricao}/>
                                            :""}
                                        </div>
                                        <div style={{height: '250px', overflow: 'hidden'}}>  
                                            <CustomScroll  scrollTo={this.state.scrollOffset} scrollWidth="15px" scrollBarRadius="0">
                                                {/* {this.props.MapaKseg.state.poligono_novo_list.length>0 ?
                                                    <GridFlags  dataSource={this.props.MapaKseg.state.poligono_novo_list}/>
                                                :""} */}
                                                <ListGroup>{
                                                    this.props.MapaKseg.state.poligono_novo_list.map((poligono) =>
                                                            <ListGroup.Item action >
                                                                <div >
                                                                    {poligono.id+'  '}
                                                                    <img className="margin_cantos5" src={poligono.flag} width="30"/>
                                                                    <img className="espaco" src={require('./img/trash24_gray.png')} width="20" onClick={()=>this.removeItem(poligono.id)}/>                                   
                                                                </div>
                                                            </ListGroup.Item>
                                         
                                                        )}
                                                </ListGroup>   
                                            </CustomScroll>                                                
                                        </div>                                                                 
                                    </Carousel.Item>
                                    
                                </Carousel>                        

                                    <Modal.Footer >                                                
                                                {this.state.activeIndexCarousel===0? 
                                                    <div>
                                                        <div className="align_bottom_left">
                                                            <Button onClick={this.fecharModal}>Fechar</Button>
                                                        </div>
                                                        <div className="align_bottom_right">
                                                            <DropdownButton id="dropdown-basic-button" title="+ Nova área">
                                                                <Dropdown.Item onClick={()=>this.onNovoItemClick(1)} > <img src={require('./img/poligono/ic_flag_red.png')} width="20" />Área de risco </Dropdown.Item>
                                                                <Dropdown.Item onClick={()=>this.onNovoItemClick(2)}> <img src={require('./img/poligono/ic_flag_green.png')} width="20" />Área segura </Dropdown.Item>
                                                                <Dropdown.Item onClick={()=>this.onNovoItemClick(3)}> <img src={require('./img/poligono/ic_flag_blue.png')} width="20" />Unidade </Dropdown.Item>
                                                                <Dropdown.Item onClick={()=>this.onNovoItemClick(4)}> <img src={require('./img/poligono/ic_flag_yellow.png')} width="20" />Oficina </Dropdown.Item>
                                                                <Dropdown.Item onClick={()=>this.onNovoItemClick(5)}> <img src={require('./img/poligono/ic_flag_orange.png')} width="20" />Clientes </Dropdown.Item>
                                                                <Dropdown.Item onClick={()=>this.onNovoItemClick(6)}> <img src={require('./img/poligono/ic_flag_grey.png')} width="20" />Outros </Dropdown.Item>
                                                            </DropdownButton>  
                                                            {/* <Button onClick={this.onSalvarClick}><img src={require('./img/checkbold24.png')}/>Salvar</Button> */}
                                                        </div> 
                                                    </div>
                                                :""}
                                                {/* ---------------------- */}                                                    
                                                {this.state.activeIndexCarousel===1?
                                                    <div>
                                                        <div className="align_bottom_left">
                                                        <Button onClick={this.onVoltarClick}><img src={require('./img/arrow_left24.png')} title={"Voltar"}/></Button>
                                                        </div>
                                                        <div className="align_bottom_right">
                                                            <Button variant="success" onClick={this.postNovoPoligono}>
                                                                {this.state.salvando?
                                                                    <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                  />
                                                                  : "Salvar"
                                                                }                                                                
                                                            </Button>
                                                        </div>
                                                    </div>
                                                :""}
                                                
                                            </Modal.Footer>                                    
                                </div>                                
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>                   

            </div>
        
        );
    }
}