import React, {Component} from 'react';
import QRCode from 'qrcode.react';
import {Spinner, Image, Row, Col, Container} from 'react-bootstrap';
// import api from './api';

import { browserName, osName} from "react-device-detect";


class LoginQrcode extends Component{
    constructor(props) {
        super(props);
        this.state = {
        };
      }
    //-----------------
    sleep (milliseconds) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
      }
    //-----------------
    iniciarNovoQrcode = async () => {
        try {
            await this.sleep(2000);
    
        } finally {
            // this.setState({qrcodeload:false})
            // this.props.qrcodeload=false;
            // this.setProps({qrcodeload:false})
            // LoginQrcode({qrcodeload:false})
            // this.setState({fuck:false})
            this.props.onQrcodeloadChange(false)
        }
    };  
    //------------------
    carregarNovoQrCode(){
        return(
            <div className="qrcode_login">
                <div className="qrcode">
                    <QRCode  value="0" size="200"/>
                </div>
                <Container className="image_acima">
                    <Row>
                        <Col xs={6} md={4}>
                            <a href="">
                                <Image  src="img/bg_button.png"  onClick={()=>this.props.onQrcodeloadChange(true)}  roundedCircle />
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>    
        );
    }
    //-----------------
    carregarLoadImagem(){
        return(
            <div className="qrcode_login">
                <div className="qrcode">
                    <QRCode  value="0" size="200"/>
                </div>
                <Spinner  className="spinner_acima" animation="border"  />
            </div>
        );
    }
    //------------------
    async componentDidMount() {
        this.iniciarNovoQrcode();
    }
    //-----------------
    randomNumber() {
        const min = 1;
        const max = 1000000;
        const random = min + (Math.random() * (max - min));
        return parseInt(random, 10)
    }
    //----------------
    gerarHash = () =>{
        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+' '+time;
        var user = {name: this.randomNumber(), data: dateTime, browser: browserName, os: osName  };
        var objectHash = require('object-hash');
        var hash = objectHash(user);
        localStorage.setItem('hash_kseg_web', hash);
        return hash;
    }
    //----------------
    render(){
        // var today = new Date();
        // var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        // var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        // var dateTime = date+' '+time;

        // var hash = require('object-hash');
        // var user = {name: this.randomNumber(), data: dateTime, browser: browserName, os: osName  };

        return(
            <div className="relative">
                <div className="absolute_head"></div>

                <div className="center">                  
                    <div className="logo_kseg_login">
                        <img className="bar_kseg_login" src="img/kseg_branco.png" alt=""  />
                        
                        <p className="bar_kseg_login2">KSEG WEB</p>
                    </div>                
                </div>
                
                <div className="absolute">
                    <div className="texto_login">
                        <h2>Para usar o Kseg no seu computador:</h2>
                        <p className="margin_top">
                            1. Abra o Kseg em seu celular                        
                        </p>
                        <p>
                            2. Deslize o Menu lateral e selecione Kseg Web            
                        </p>
                        <p>
                            3. Aponte seu celular para essa tela para capturar o código
                        </p>
                    </div>


               {this.props.qrcodeload ? 
                    this.props.botaoNovoQrCode ? this.carregarNovoQrCode(): this.carregarLoadImagem()
                    : <QRCode className="qrcode_login" value={this.gerarHash()} size="200"/>
                }      

                </div>
                
            </div>
        );
    }  
}

export default LoginQrcode;
