import React from 'react';
import DataGrid, { Column, RowDragging, Scrolling, Lookup } from 'devextreme-react/data-grid';

class GridVeiculo extends React.Component {
  constructor(props) {
    super(props);

    this.priorities = [{
      id: 1, text: 'Low'
    }, {
      id: 2, text: 'Normal'
    }, {
      id: 3, text: 'High'
    }, {
      id: 4, text: 'Urgent'
    }];
    this.filterExpr = ['status', '=', this.props.status];

    this.onAdd = this.onAdd.bind(this);

    this.dataSource = {
      store: this.props.tasksStore,
      reshapeOnPush: true
    };
  }

  onAdd(e) {
    var key = e.itemData.veiculo_id;

    for (var i = 0; i < this.props.tasksStore.length; i++) {                      
      if(this.props.tasksStore[i].veiculo_id === key){      
        this.props.tasksStore[i].status = 1;   
        this.props.tasksStore[i].entrada = false; 
        this.props.tasksStore[i].saida = false; 
        this.props.tasksStore[i].editado = true;  
      }
    }       

    this.props.AddNotificacaoVeiModal.setState({grid_list: this.props.tasksStore, loading:true});
    this.props.AddNotificacaoVeiModal.setState({loading:false});
  }

  render() {
    return (
      <DataGrid
        dataSource={this.dataSource}
        height={200}
        showBorders={true}
        filterValue={this.filterExpr}
      >
         <RowDragging
          data={this.props.status}
          group="tasksGroup"
          onAdd={this.onAdd}
        />
       
        <Scrolling mode="virtual" />
        <Column
          dataField="placa"
          dataType="string"
        />

        <Column
          dataField="veiculo_id"
          dataType="number"
          visible={false}
        />

       <Column
          dataField="status"
          dataType="number"
          visible={false}
        />
      </DataGrid>
    );
  }
}

export default GridVeiculo;
