import  {Component} from 'react';
import api from '../api';

export default class GetRotaPessoa extends Component{
    constructor(props, objeto, MapaKseg, tipo, municipio_id, cliente_id){
        super(props);
        this.state = {
             list: [],
             loading:true,
             objeto: objeto,
             obj:null,
             MapaKseg: MapaKseg,
             tipo: tipo,
             municipio_id: municipio_id,
             cliente_id: cliente_id
       }
    }
    //------------------------------------------------
    get = async () => {
        try {        
            let body = new FormData()

            if(this.state.municipio_id !== 0)
                body.append('municipio_id', this.state.municipio_id);
            
            if(this.state.cliente_id !== null)
                body.append('cliente_id', this.state.cliente_id);    

            body.append('pessoa_id', this.state.MapaKseg.state.pessoa_id);
            body.append('token', localStorage.getItem('token'))

            let url = '/coletaweb_api/getRotas';

            if(this.state.cliente_id !== null){ 
                url = '/coletaweb_api/getRotaClienteColeta';
                if(this.state.tipo == 6) // ENTREGA_ROTA_TIPO=6
                    url = '/coletaweb_api/getRotaClienteEntrega';
            }

            const response = await api.post(url, body);
            // const response = await api.post('/teste_api/getRotas', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let obj = this.state.obj;
                let list = this.state.list;                

                // ToastStore.success("resposta.length: "+ String(resposta.length));
                for (var i = 0; i < resposta.length; i++) {                        
                    obj = {    
                        rota_id: resposta[i].rota_id,                        
                        descricao: resposta[i].descricao,                        
                        filial: resposta[i].filial,
                        municipios: resposta[i].municipios,
                        selecionada: false,
                        index_scroll: (i+2)*25,
                    };
                    list.push(obj);
                    this.setState({list: list});
                }
                this.setState({loading: false});                
            }
        } finally {      
            if(this.state.municipio_id !== 0){
                if(this.state.list.length> 0)
                    this.state.objeto.setRota(this.state.list[0]);                

            }else this.state.objeto.setListRotas(this.state.list, this.state.tipo);
            // this.state.objeto.setState({loading: false});
            // this.state.MapaKseg.toast(this.state.list.length);
            }
    };
    //--------------------------
}