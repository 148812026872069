import React, {Component, useState} from 'react';
import {Modal, ListGroup, Spinner, Button, Carousel} from 'react-bootstrap';
import './estilo.css';
import { ToastStore} from 'react-toasts';
import api from './api';
import {CadFaixaForm} from './cadFaixaForm';
import { throws } from 'assert';
import {CabecalhoModal} from './CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';


export class CadastroFaixas extends Component{
    constructor(props) {
        super(props);
        this.state = {
            faixa: {},
            list:[],
            loading:true,
            activeIndexCarousel:0,
            nome:null,
            faixa_min: -10,
            faixa_max: 10,
            showAlertDismissible: false,
            faixa_temperatura_id:0,
            faixaDesc: "",

            animation: "zoom",
            scrollOffset: 0,
            heightRodal:650,
        };
      }
    //------------------
    getFaixasList = async () => {
        try {
        let body = new FormData()
        body.append('token', localStorage.getItem('token'))
        body.append('pessoa_id',localStorage.getItem('pessoa_id'))

        const response = await api.post('/temperatura_api/faixaTemperaturaPessoa', body);
        // ToastStore.success("response.status: "+ String(response.status));
        var responseJson = null
        if (response.data !== null  &&  response.status !== 403){
            responseJson = JSON.stringify(response.data)
            var req = { json: responseJson };
            var resposta = JSON.parse(req.json);           
            // ToastStore.success("max: "+ String(responseJsonFaixa.faixa_max));

            let faixa = this.state.faixa;
            let sem_faixa;
            let list = this.state.list;

            // ToastStore.success("resposta.length: "+ String(resposta.length));
            for (var i = resposta.length-1; i >=0; i--) {   

                if(i===resposta.length-1)
                    list.push({faixa_temperatura_id: 0, descricao: "", faixa_min: 0, faixa_max: 0, faixa_min_max: "", selecionada: false});

                faixa = {
                    faixa_temperatura_id: resposta[i].faixa_temperatura_id,
                    descricao: resposta[i].descricao,
                    faixa_min: resposta[i].faixa_min,
                    faixa_max: resposta[i].faixa_max,
                    faixa_min_max: resposta[i].faixa_min+'      a    ' +resposta[i].faixa_max,
                    selecionada: false               
                    };

                if (this.props.TempGridModal.state.faixaSelId === resposta[i].faixa_temperatura_id)                        
                    faixa.selecionada=true;
                
                if(resposta[i].faixa_min!==null)    
                    list.push(faixa);
                else list[0]=faixa;
                
            }
            // list.push(sem_faixa);
            this.setState({list: list});   
            this.setState({loading: false}); 
            

        }else{
        // this.getToken();
        // this.getPosicoesList();
        }


        } finally {
            // ToastStore.success("list.length: "+ String(this.state.list.length));
            
        }
    
    };
    //------------------
    postCadFaixa = async () => {
        try {
        let body = new FormData()
        body.append('token', localStorage.getItem('token'))
        body.append('pessoa_id', localStorage.getItem('pessoa_id'))
        body.append('descricao', this.state.nome)
        body.append('faixa_min', this.state.faixa_min)
        body.append('faixa_max', this.state.faixa_max)        

        const response = await api.post('/temperatura_api/cadastrarFaixaTemperatura', body);
        // ToastStore.success("response.status: "+ String(response.status));
        var responseJson = null
        if (response.data !== null  &&  response.status !== 403){
            responseJson = JSON.stringify(response.data)
            var req = { json: responseJson };
            var resposta = JSON.parse(req.json);           
            // ToastStore.success("max: "+ String(resposta.descricao));

            let faixa = this.state.faixa;
            let list = this.state.list;

            faixa = {
                    faixa_temperatura_id: resposta.faixa_temperatura_id,
                    descricao: resposta.descricao,
                    faixa_min: resposta.faixa_min,
                    faixa_max: resposta.faixa_max,
                    faixa_min_max: resposta.faixa_min+'      a    ' +resposta.faixa_max,
                    selecionada: true           
                    };

            list.push(faixa);
            this.setState({list: list});                       
            
            this.setState({loading: false}); 
            
        }else{
        // this.getToken();
        // this.getPosicoesList();
        }


        } finally {
            // ToastStore.success("list.length: "+ String(this.state.list.length));
            
        }
    
    };
    //------------------
    postAssociarFaixaVeiculo = async () => {
        try {
            let response=null;
            let body = new FormData()            
            body.append('token', localStorage.getItem('token'))
            body.append('pessoa_id', localStorage.getItem('pessoa_id'))
            body.append('veiculo_id', this.props.TempGridModal.state.veiculo_id_selecionada)

            if(this.props.TempGridModal.state.faixa_index===0){
                body.append('faixa_temperatura_id', this.state.faixa_temperatura_id)
                response = await api.post('/temperatura_api/associarFaixaTemperaturaVeiculo', body);
            }else if(this.props.TempGridModal.state.faixa_index===1){
                body.append('faixa1_id', this.state.faixa_temperatura_id)
                response = await api.post('/temperatura_api/associarFaixaVeiculoFracionada', body);
            }else if(this.props.TempGridModal.state.faixa_index===2){
                body.append('faixa2_id', this.state.faixa_temperatura_id)
                response = await api.post('/temperatura_api/associarFaixaVeiculoFracionada', body);
            }else if(this.props.TempGridModal.state.faixa_index===3){
                body.append('faixa3_id', this.state.faixa_temperatura_id)
                response = await api.post('/temperatura_api/associarFaixaVeiculoFracionada', body);
            }

            // ToastStore.success("response.status: "+ String(response.status));
            var responseJson = null
            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);                              
                
                this.setState({loading: false}); 
                
            }else{
            // this.getToken();
            // this.getPosicoesList();
            }

            this.ajustarVinculado();
            this.props.onHide();

        } catch (response){
            // ToastStore.success(response.data);
            // ToastStore.success("Ocorreu uma irregularidade. Tente novamente!");
            
        }
    
    };  
    //--------------------------------
    async componentDidMount() {
        // ToastStore.success("componentDidMount()  Faixas");
        this.getFaixasList();
    };
    //------------------------
    onCadastroClick = ()=>{
        this.setState({activeIndexCarousel:1});
    }
    //------------------------
    onSalvarClick = ()=>{
        if(this.state.nome===null || this.state.nome==="")
            ToastStore.error("Informe o Nome da faixa!");
        else{
            this.setState({activeIndexCarousel:0, scrollOffset:1000});
            this.postCadFaixa();
        }

    }
    //------------------------
    ajustarVinculado=()=>{
        let list = this.state.list;
        let parametros ={};

        for (var i=0; i < list.length; i++) {                
            if (this.state.faixa_temperatura_id === list[i].faixa_temperatura_id){                        
                list[i].selecionada=true;
                parametros ={
                    veiculo_id: this.props.TempGridModal.state.veiculo_id_selecionada,
                    faixa_min_max: list[i].faixa_min_max, 
                    descricao: list[i].descricao,
                    faixa_min: list[i].faixa_min,
                    faixa_max: list[i].faixa_max,
                    faixa_temperatura_id: list[i].faixa_temperatura_id,
                    faixa_index: this.props.TempGridModal.state.faixa_index
                }
                this.props.TempGridModal.atualizarDescFaixa(parametros);
            }else list[i].selecionada=false;     
               
        }

        this.setState({list: list, showAlertDismissible: false})
    }
    //-------------------------
    setNome = (nome)=>{
        this.setState({nome: nome});
    }
    //-------------------------
    setFaixa = (faixa_min, faixa_max)=>{
        this.setState({faixa_min: faixa_min, faixa_max: faixa_max});
    }
    //--------------------------
    onClickItem = ()=>{
        ToastStore.success("click");
    }
    //--------------------------
    showModal = (faixa)=>{
        if(this.props.TempGridModal.state.tipoChamada===1)
            this.setState({showAlertDismissible:true, faixa_temperatura_id: faixa.faixa_temperatura_id,
                faixaDesc: faixa.descricao + '   '+faixa.faixa_min_max});
    }
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.TempGridModal.cadastroFaixasClose();
    };
    //------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.TempGridModal.cadastroFaixasDesativar();
    };
    //-------------------------
    render(){
        return(
            <div className="drag1" >
                <Draggable   handle="strong"  >                
                    <div >                    
                        <Rodal 
                            visible={this.props.show}
                            animation={this.state.animation}
                            showCloseButton={false}
                            duration={600}
                            width={500}
                            height={this.state.heightRodal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >  
                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}
                                    titulo={this.props.TempGridModal.state.tipoChamada===0? <font>Cadastro de Faixa</font> : <font>Associar Faixa</font>} 
                                    Modal={this}/></strong>
                                <div className="padding_modal">

                                <Carousel interval={null}  controls={false} indicators={false} activeIndex={this.state.activeIndexCarousel}>
                                    <Carousel.Item>
                                        
                                    {   this.state.loading ? 
                                            <div className="centro">
                                                <Spinner  className="espaco_topo"  animation="border" />
                                            </div>

                                        : 
                                        <div style={{height: '500px', overflow: 'hidden'}}>                                    
                                            <CustomScroll  scrollTo={this.state.scrollOffset}>
                                                <ListGroup>{
                                                    this.state.list.map((faixa) =>  
                                                        faixa.selecionada===false ?
                                                            <ListGroup.Item action variant="" onClick={()=>this.showModal(faixa)}>
                                                                <div>
                                                                {faixa.descricao+'  '}
                                                                <span class="texto_canto_dir">
                                                                    {faixa.faixa_min!==null ? ''+faixa.faixa_min_max+ '': ""}
                                                                    </span>
                                                                </div>
                                                            </ListGroup.Item>

                                                        : <ListGroup.Item action variant="dark">
                                                                <div>
                                                                {faixa.descricao+'  '}
                                                                <span class="texto_canto_dir">
                                                                    {faixa.faixa_min!==null ? ''+faixa.faixa_min_max : ""}
                                                                    </span>
                                                                </div>
                                                            </ListGroup.Item>    
                                                    )}
                                                </ListGroup>
                                            </CustomScroll>                                        
                                        </div> 
                                        }
                                        

                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div>
                                        <CadFaixaForm cadastroFaixas={this} />                                    
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                        className="d-block w-100"
                                        src="holder.js/800x400?text=Third slide&bg=20232a"
                                        alt="Third slide"
                                        />

                                        <Carousel.Caption>
                                        <h3>Third slide label</h3>
                                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>

                        
                        {/* ------------------ */}
                        <Modal show={this.state.showAlertDismissible}  centered>
                            <Modal.Body className="modalBg" >
                                 Deseja vincular a faixa <text className="letrasRoxo">{this.state.faixaDesc}</text> ao <text className="letrasRoxo">{this.props.TempGridModal.state.placa_selecionada}</text> ?
                             </Modal.Body>
                            <Modal.Footer className="modalBg" >
                                <Button variant="secondary" onClick={()=>this.setState({showAlertDismissible: false})}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" onClick={()=>this.postAssociarFaixaVeiculo()}>
                                    Salvar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        {/* ------------------ */}

                                                                             
                    
 
                    <Modal.Footer> 
                        {this.props.TempGridModal.state.tipoChamada===0 ?
                            this.state.activeIndexCarousel===0 ?
                                <Button onClick={this.onCadastroClick}><img src={require('./img/thermometer_plus24.png')}/></Button>
                                : <Button onClick={this.onSalvarClick}><img src={require('./img/checkbold24.png')}/>Salvar</Button>
                              : ""                
                            }
                        
                    </Modal.Footer>

                    </div>                                
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>
        );
    }
}