import  {Component} from 'react';
import api from './api';
import {ToastStore} from 'react-toasts';
import Moment from 'moment';

// import {Polyline} from 'google-maps-react';

export default class GetPoligonos extends Component{
    constructor(MapaKseg, grupo_id, nome, tipo_poligono_id){
        super(MapaKseg);
        this.state = {
             grupo: {},
             list: [],
             poligonos_list: [],
             loading:true,
             MapaKseg:MapaKseg,
             grupo_id: grupo_id,
             nome: nome,
             tipo_poligono_id: tipo_poligono_id,
       }
    }
    //---------------------
    setCor = (tipo_poligono_id) =>{    
        switch(tipo_poligono_id) {
            case 1:
                return "#ED4145"; // vermelho
            case 2:
                return "#008000";  // verde                                         
            case 3:
                return "#0277bd"; // azul
            case 4:
                return "#FFFF00";  //amarelo                     
            case 5:
                return "#FFA500";  //laranja
            case 6:
                return "#808080"; // cinza  
            default:
                return "#808080";  
        }
    };
    //---------------------
    setFlag = (tipo_poligono_id) =>{    
        switch(tipo_poligono_id) {
            case 1:
                return require('./img/poligono/ic_flag_red.png'); // vermelho
            case 2:
                return require('./img/poligono/ic_flag_green.png');  // verde                                         
            case 3:
                return require('./img/poligono/ic_flag_blue.png'); // azul
            case 4:
                return require('./img/poligono/ic_flag_yellow.png');  //amarelo                     
            case 5:
                return require('./img/poligono/ic_flag_orange.png');  //laranja
            case 6:
                return require('./img/poligono/ic_flag_grey.png'); // cinza  
            default:
                return require('./img/poligono/ic_flag_grey.png');  
        }
    };    
    //--------------------
    getPoligonos = async () => {
        try {
            // ToastStore.success("usuario_id: "+String(this.state.usuario_id));
            let body = new FormData()
            if(this.state.tipo_poligono_id !== null)
                body.append('tipo_poligono_id', this.state.tipo_poligono_id)    

            body.append('grupo_id', this.state.grupo_id)
            body.append('token', localStorage.getItem('token'))

            // console.log("  ***datetime: "+datetime)

            const response = await api.post('/poligono_api/getPoligonoCoordenada', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);

                
                let poligono = {};
                let list = this.state.list;  
                let list2 = [];    
                let poligonos_list = this.state.poligonos_list;
                let lat_lng_list = [];
                let poligono_coordenada_id_list = [];
                // console.log(resposta);
                // ToastStore.success("resposta.length: "+ String(resposta.length));                
                for (var i = 0; i < resposta.length; i++) {                        
                    poligono = {  
                        poligono_id : resposta[i].poligono_id,
                        descricao : resposta[i].descricao,
                        tipo_poligono_id : resposta[i].tipo_poligono_id,
                        sequencia : resposta[i].sequencia,
                        latitude : resposta[i].latitude,
                        longitude : resposta[i].longitude,
                        poligono_coordenada_id : resposta[i].poligono_coordenada_id,
                        selecionado: false,
                        flag: null,
                        tipo_raio : resposta[i].tipo_raio,
                        raio : resposta[i].raio,
                    };

                    // if(localStorage.getItem('grupo_id') === String(grupo.grupo_id) )
                    //     grupo.selecionada=true;

                    // console.log(resposta[i].descricao);
                    list.push(poligono);
                    lat_lng_list.push({lat: poligono.latitude, lng: poligono.longitude});
                    poligono_coordenada_id_list.push(poligono.poligono_coordenada_id);

                    // if(poligono.sequencia===1){       
                    //     list2 =[];
                    // }

                    // if(poligono.tipo_raio) console.log(" tipo_raio true");
                    // else console.log(" tipo_raio false")

                    list2.push(poligono);

                    if (i+1 < resposta.length ){
                        // console.log(resposta[i].descricao);
                        if(resposta[i+1].sequencia === 1  && i!==0){
                            // console.log(resposta[i].descricao);
                            poligonos_list.push({dados: list2
                                                ,poligono_id: list2[0].poligono_id
                                                ,descricao: list2[0].descricao
                                                ,poligono_coordenada_id_list: poligono_coordenada_id_list
                                                ,lat_lng_list: lat_lng_list                                                
                                                ,cor: this.setCor(list2[0].tipo_poligono_id)
                                                ,flag: this.setFlag(list2[0].tipo_poligono_id)
                                                ,nome: this.state.nome});
                            
                            // console.log({dados: list2
                            //     ,poligono_id: list2[0].poligono_id
                            //     ,descricao: list2[0].descricao
                            //     ,poligono_coordenada_id_list: poligono_coordenada_id_list
                            //     ,lat_lng_list: lat_lng_list
                            //     ,cor: this.setCor(list2[0].tipo_poligono_id)
                            //     ,flag: this.setFlag(list2[0].tipo_poligono_id)
                            //     ,nome: this.state.nome});

                            list2 =[];
                            lat_lng_list=[];
                            poligono_coordenada_id_list=[];
                        }
                    }else if(i===resposta.length-1){
                        poligonos_list.push({dados: list2
                            ,poligono_id: list2[0].poligono_id
                            ,descricao: list2[0].descricao
                            ,poligono_coordenada_id_list: poligono_coordenada_id_list
                            ,lat_lng_list: lat_lng_list
                            ,cor: this.setCor(list2[0].tipo_poligono_id)
                            ,flag: this.setFlag(list2[0].tipo_poligono_id)
                            ,nome: this.state.nome});
                            list2 =[];
                        lat_lng_list=[];
                        poligono_coordenada_id_list=[];
                    }

                    
                }

                // poligonos_list = poligonos_list.sort((a,b) => b.descricao - a.descricao);
                var poligonos_list_ordenado = poligonos_list.sort((a, b) => a.descricao.localeCompare(b.descricao));
                this.setState({list: list, poligonos_list: poligonos_list_ordenado});
                this.setState({loading: false});        
                
            }else{
            }

        } finally {
            let poligonos_list = this.state.poligonos_list;

            if(this.state.tipo_poligono_id !== null){
                if(this.state.tipo_poligono_id === 5)
                    this.state.MapaKseg.setState({poligonos_cliente_list: poligonos_list, carregarPolygon:true});
            }else this.state.MapaKseg.setState({poligonos_list: poligonos_list, carregarPolygon:true});    

            // if(poligonos_list.length===0){                
            //     poligonos_list.push({nome: this.state.nome});
            // }
                        
            // await this.sleep(10000);
            this.state.MapaKseg.setState({carregarPolygon:true});
            
            if(this.state.MapaKseg.state.modoMapa)
                this.state.MapaKseg.state.Modals.showPoligonosModal(this.state.grupo_id);
            // this.state.MapaKseg.callToast("grupo_id: "+this.state.grupo_id);

            }
    };
    //-----------------
    sleep (milliseconds) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    }
    //--------------------------
    
    async componentDidMount() {
        // ToastStore.success("getGruposVeiculo componentDidMount()");
        // this.getGruposPoligono();
    };
}