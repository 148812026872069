
import React, {Component} from 'react';
import SideNav, {  NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import ReactRoundedImage from "react-rounded-image";


import base64 from 'react-native-base64'
// var binaryToBase64 = require('binaryToBase64');
import './estilo.css';
import { Button, Image } from 'react-bootstrap';


export class SideNavDrawer extends Component{
    constructor(props){
        super(props);
        this.state = {
            divWidth: {
               width: 1900
            },
            divHeight: {
                height:500
             },
             gruposVeiculoModalAtivo:false,
             tempGridModalAtivo:false,
             monitoresModalAtivo:false,
             relatoriosModalAtivo:false,
             relPosicoesVeiModalAtivo:false,
             relEventosVeiModalAtivo:false,
             temperaturaModalAtivo:false,
             relatoriosModalShow:false,
             graficosListaModalAtivo:false,
       }
    }
    //------------------------
    async componentDidMount() {
        this.props.MapaKseg.setState({SideNavDrawer:this});
    }
    //--------------------------------------------------TempGridModal-----------------------------------------------------------------    
    showTempGridModal = () => {
        this.props.MapaKseg.state.Modals.setState({tempGridModalShow:true});
        this.props.MapaKseg.state.Modals.setState({tempGridModalAtivo:true});
        this.setState({tempGridModalAtivo:true});
    };
    //--------------------------------------------------GrupoVeiculoModal----------------------------------------------------------------------
    showGruposVeiculoModal = () => {   
        this.props.MapaKseg.state.Modals.setState({gruposVeiculoModalShow:true});
        this.props.MapaKseg.state.Modals.setState({gruposVeiculoModalAtivo:true});
        this.setState({gruposVeiculoModalAtivo:true});
    };
    //--------------------------------------------------RelatoriosModal-----------------------------------------------------------------    
    showMonitoresModal = () => {
        this.props.MapaKseg.state.Modals.setState({monitoresModalShow:true});
        this.props.MapaKseg.state.Modals.setState({monitoresModalAtivo:true});
        this.setState({monitoresModalAtivo:true});
    };
    //--------------------------------------------------RelatoriosModal-----------------------------------------------------------------    
    showRelatoriosModal = () => {
        this.props.MapaKseg.state.Modals.setState({relatoriosModalShow:true});
        this.props.MapaKseg.state.Modals.setState({relatoriosModalAtivo:true});
        this.setState({relatoriosModalAtivo:true});        
    };
    //--------------------------------------------------showRelPosicoesVeiModal-----------------------------------------------------------------    
    showRelPosicoesVeiModal = () => {
        this.props.MapaKseg.state.Modals.setState({relPosicoesVeiModalShow:true});
        this.props.MapaKseg.state.Modals.setState({relPosicoesVeiModalAtivo:true});
        this.setState({relPosicoesVeiModalAtivo:true});
    }; 
    //--------------------------------------------------showRelEventosVeiModal-----------------------------------------------------------------    
    showRelEventosVeiModal = () => {
        this.props.MapaKseg.state.Modals.setState({relEventosVeiModalShow:true});
        this.props.MapaKseg.state.Modals.setState({relEventosVeiModalAtivo:true});
        this.setState({relEventosVeiModalAtivo:true});
    }; 
    //--------------------------------------------------showGraficosModal-----------------------------------------------------------------    
    showGraficosListaModal = () => {
        this.props.MapaKseg.state.Modals.setState({graficosListaModalShow:true});
        this.props.MapaKseg.state.Modals.setState({graficosListaModalAtivo:true});
        this.setState({graficosListaModalAtivo:true});
    };     
    //--------------------------------------------------CadastroFaixasModal-----------------------------------------------------------------    
    showCadastroFaixasModal = (TempGridModal) => {
        this.props.MapaKseg.state.Modals.setState({TempGridModal:TempGridModal});
        this.props.MapaKseg.state.Modals.setState({cadastroFaixasModalShow:true});
        this.props.MapaKseg.state.Modals.setState({cadastroFaixasModalAtivo:true});
    };
    //--------------------------------------------------TemperaturaModal-----------------------------------------------------------------
    showTemperaturaModal = (temperatura_obj) => {
        this.props.MapaKseg.state.Modals.setState({temperatura_obj:temperatura_obj});

        // this.setState({temperaturaList:temperaturaList});    
        this.props.MapaKseg.state.Modals.setState({temperaturaModalShow:true});
        this.props.MapaKseg.state.Modals.setState({temperaturaModalAtivo:true});
        this.setState({temperaturaModalAtivo:true});
    };
    //--------------------------------------------------UsuarioModal-----------------------------------------------------------------    
    showUsuarioModal = () => {    
        this.props.MapaKseg.state.Modals.setState({usuarioModalShow:true});
        this.props.MapaKseg.state.Modals.setState({usuarioModalAtivo:true});
    };
    //---------------------------------------------------------------
    setExpanded= async(expanded) =>{         
        this.props.MapaKseg.setState({sideNavDrawer_expanded: expanded});
    };
    //--------------------------------------------------GrupoVeiculoModal----------------------------------------------------------------------
    showCadastroModal = () => {   
        this.props.MapaKseg.state.Modals.showCadastroModal();
        this.setState({cadastroModalAtivo:true});
        // this.setState({gruposVeiculoModalAtivo:true});
    };
    //------------------------------------------------------------------------------------------------------------------------------
    render(){
        return(        
            <div>
            <SideNav className="sidenav_margin_top" 
              onSelect={(selected) => {
                  // Add your code here
                }}                
                expanded={this.props.MapaKseg.state.sideNavDrawer_expanded}
                onToggle={(expanded) => {
                    this.setExpanded(expanded);
                }}
                >

              <SideNav.Nav componentClass='nav' >
                <NavItem active={true} navitemStyle={{height:110}}    eventKey="usuario" >
                    {this.props.MapaKseg.state.sideNavDrawer_expanded?
                      <NavIcon className="backg_user" onClick={()=> this.setExpanded(false)} > 
                        <div >
                            <button className="button1"  onClick={()=> this.setExpanded(false)}>
                                <img src={require("./img/arrow_collapse_left.png")} width="20" />
                            </button>
                            <button className="button2"  onClick={()=> this.showUsuarioModal()}>
                                <div className="espaco_top_left">
                                    <ReactRoundedImage 
                                        image={this.props.MapaKseg.state.foto_usuario}
                                        roundedColor="#8b60db"                  
                                        imageWidth="50"
                                        imageHeight="50"
                                        roundedSize="0"
                                    />
                                </div>
                            </button>                                                    
                            <text className="left3" onClick={()=> this.showUsuarioModal()} >{localStorage.getItem('login')}</text>
                        </div>

                      </NavIcon>
                      :
                      <NavIcon > 
                        <button className="button1"  onClick={()=> this.setExpanded(true)} title={localStorage.getItem('login')}>                        
                            {this.props.MapaKseg.state.foto_usuario===""?
                                <div className="espaco_top_left2">
                                    <ReactRoundedImage  image={require("./img/user.png")}
                                        roundedColor="#8b60db"                             
                                        imageWidth="50"
                                        imageHeight="50"
                                        roundedSize="10"
                                        />
                                </div>
                                :
                                    <div className="espaco_top_left2">
                                        <ReactRoundedImage image={this.props.MapaKseg.state.foto_usuario}
                                            roundedColor="#8b60db"                           
                                            imageWidth="50"
                                            imageHeight="50"
                                            roundedSize="0"
                                        />
                                    </div>
                            }
                        </button>    
                      </NavIcon>
                    }
                  </NavItem>

                  {this.props.MapaKseg.state.pessoa_id===1 || this.props.MapaKseg.state.gerente===true ?
                    <NavItem eventKey="cadastro" title="Cadastro" src='./img/ic_desengate256.png' onClick={()=> this.showCadastroModal()}>
                        <NavIcon >
                            {this.props.MapaKseg.state.Modals ?
                                this.state.cadastroModalAtivo? 
                                    <img src='./img/circle.png' className="espaco_right"/> : ""
                            :""}
                            <img src='./img/folder_open48.png' alt="" Width="30"/>
                            <i  className="fa fa-fw fa-home" style={{ fontSize: '1.75em'}} />
                        </NavIcon>
                        <NavText>
                            Cadastro
                        </NavText>
                    </NavItem>
                  :""}

                  <NavItem eventKey="gruposVeiculos" title="Grupos de Veículos" src='./img/ic_desengate256.png' onClick={()=> this.showGruposVeiculoModal()}>
                      <NavIcon >
                        {this.props.MapaKseg.state.Modals ?
                            this.props.MapaKseg.state.Modals.state.gruposVeiculoModalAtivo? 
                                <img src='./img/circle.png' className="espaco_right"/> : ""
                         :""}
                          <img src='./img/ic_car_group-web.png' alt="" Width="30"/>
                          <i  className="fa fa-fw fa-home" style={{ fontSize: '1.75em'}} />
                      </NavIcon>
                      <NavText>
                          Grupos de Veículos
                      </NavText>
                  </NavItem>

                  {/* <NavItem eventKey="angedamento" title="Agendamento de Viagem">
                      <NavIcon>                          
                          <img src='img/agendamento_viagem.png' alt="" Width="30"/>
                          <i className="fahome" style={{ fontSize: '1.75em' }} />
                      </NavIcon>
                      <NavText>
                      Agendamento de Viagem
                      </NavText>
                  </NavItem> */}

                  <NavItem eventKey="gridOperacional" title="Grid Operacional"  onClick={()=>this.showMonitoresModal()}>
                      <NavIcon>
                         {this.props.MapaKseg.state.Modals ?
                            this.props.MapaKseg.state.Modals.state.monitoresModalAtivo || this.props.MapaKseg.state.Modals.state.tempGridModalAtivo ? 
                                <img src='./img/circle.png' className="espaco_right"/> : ""
                         : ""}       
                          <img src='img/table48.png' alt="" Width="30"/>
                          <i className="fahome" style={{ fontSize: '1.75em' }} />
                      </NavIcon>                      
                      <NavText >
                      Grid Operacional
                      </NavText>

                      {/* <NavItem eventKey="gridOperacional/temperatura" onClick={()=> this.setState({tempGridModalShow:true})}> */}
                      {this.state.tempGridModalAtivo ?
                        <NavItem eventKey="gridOperacional/temperatura" onClick={()=> this.showTempGridModal()}>                          
                            <NavText>
                                <text>{"              Temperatura"}</text>
                            </NavText>
                        </NavItem>
                        :""}
                        {this.state.jammerGridModalAtivo ?
                            <NavItem eventKey="gridOperacional/jammer">
                                <NavText>
                                    Jammer
                                </NavText>
                            </NavItem>
                        :""}
                  </NavItem>

                  <NavItem eventKey="relatorios" title="Relatórios" onClick={()=>this.showRelatoriosModal()}>
                      <NavIcon>
                        {this.props.MapaKseg.state.Modals ?
                          this.props.MapaKseg.state.Modals.state.relatoriosModalShow 
                          || this.props.MapaKseg.state.Modals.state.relPosicoesVeiModalAtivo 
                          || this.props.MapaKseg.state.Modals.state.relEventosVeiModalAtivo
                          || this.props.MapaKseg.state.Modals.state.relColetaEntregaModalAtivo
                          || this.props.MapaKseg.state.Modals.state.relCaixaAtivo
                          || this.props.MapaKseg.state.Modals.state.relJornadaMotoristaModalAtivo?
                                <img src='./img/circle.png' className="espaco_right"/> : ""
                        : ""}                         
                          <img src='img/file_document48.png' alt="" Width="30" />
                          <i className="fa fa-fw fa-line-chart" style={{ fontSize: '1.75em' }} />
                      </NavIcon>
                      <NavText>
                          Relatórios
                      </NavText>  

                      {this.state.relPosicoesVeiModalAtivo?
                        <NavItem eventKey="gridOperacional/temperatura" onClick={()=> this.showRelPosicoesVeiModal()}>                          
                            <NavText>
                                {"Posições do Veículo"}
                            </NavText>
                        </NavItem>
                        :""}

                      {this.state.relEventosVeiModalAtivo?
                        <NavItem eventKey="gridOperacional/evento" onClick={()=> this.showRelEventosVeiModal()}>                          
                            <NavText>
                                {"Eventos do Veículo"}
                            </NavText>
                        </NavItem>
                        :""}

                  </NavItem>


                  <NavItem eventKey="graficos" title="Gráficos" onClick={()=>this.showGraficosListaModal()}>
                      <NavIcon>
                        {this.props.MapaKseg.state.Modals ?
                          this.props.MapaKseg.state.Modals.state.graficosListaModalAtivo|| this.props.MapaKseg.state.Modals.state.graficosModalAtivo
                          || this.props.MapaKseg.state.Modals.state.graficosKmPercorridaModalAtivo
                          || this.props.MapaKseg.state.Modals.state.graficosVelocidadeMediaModalAtivo
                          || this.props.MapaKseg.state.Modals.state.graficosQuantidadePosicoesModalAtivo
                          || this.props.MapaKseg.state.Modals.state.graficosQuantidadeEventosModalAtivo 
                          || this.props.MapaKseg.state.Modals.state.graficosColetasEntregasModalAtivo 
                          || this.props.MapaKseg.state.Modals.state.graficosColetasClientesModalAtivo
                          || this.props.MapaKseg.state.Modals.state.graficosEntregasClientesModalAtivo
                          || this.props.MapaKseg.state.Modals.state.graficosColetasMotoristasModalAtivo
                          || this.props.MapaKseg.state.Modals.state.graficosEntregasMotoristasModalAtivo ?
                                <img src='./img/circle.png' className="espaco_right"/> : ""
                        : ""}                         
                          <img src='img/ic_finance.png' alt="" Width="30" />
                          <i className="fa fa-fw fa-line-chart" style={{ fontSize: '1.75em' }} />
                      </NavIcon>
                      <NavText>
                          Estatísticas
                      </NavText>  
            

                  </NavItem>


                  <NavItem eventKey="contato" title="Contato" >
                      <NavIcon>
                          <img src='img/phone.png' alt="" Width="30"/>
                          <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
                      </NavIcon>
                      <NavText>
                      Contato
                      </NavText>
                  </NavItem>

                  <NavItem eventKey="sair" title="Sair" onClick={()=>window.location.reload()}>
                      <NavIcon>
                          <img src='img/door48.png' alt="" Width="30"/>
                          <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
                      </NavIcon>
                      <NavText>
                      Sair
                      </NavText>
                  </NavItem>

              </SideNav.Nav>
          </SideNav>

        </div>
        );
    }
}