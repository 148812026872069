import  {Component} from 'react';
import api from '../api';

export default class GetClientePessoa extends Component{
    constructor(props, objeto, MapaKseg, tipo, filtro=''){
        super(props);
        this.state = {
             list: [],
             loading:true,
             objeto: objeto,
             cliente:null,
             MapaKseg: MapaKseg,
             tipo: tipo,
             filtro: filtro
       }
    }
    //------------------------------------------------
    get = async () => {
        try {        
            let body = new FormData()
            body.append('pessoa_id', this.state.MapaKseg.state.pessoa_id);            
            body.append('token', localStorage.getItem('token'))

            if (this.state.filtro !== '') body.append('nome', this.state.filtro);            

            const response = await api.post('/coletaweb_api/getClientes', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let cliente = this.state.cliente;
                let list = this.state.list;                

                // ToastStore.success("resposta.length: "+ String(resposta.length));
                for (var i = 0; i < resposta.length; i++) {                        
                    cliente = {    
                        cliente_id: resposta[i].cliente_id,
                        nome: resposta[i].nome,
                        cliente_tda: resposta[i].cliente_tda,
                        descricao: resposta[i].descricao,
                        local_id: resposta[i].local_id,
                        local: resposta[i].local,
                        cnpj_cpf: resposta[i].cnpj_cpf,
                        municipio_id: resposta[i].municipio_id,
                        municipio: resposta[i].municipio,
                        municipio_tda: resposta[i].municipio_tda,
                        tipo_pagamento_id: resposta[i].tipo_pagamento_id,
                        tipo_pagamento: resposta[i].tipo_pagamento,
                        rota_id: resposta[i].rota_id,
                        rota: resposta[i].rota,
                        cliente_frete_minimo: resposta[i].cliente_frete_minimo,
                        municipio_frete_minimo: resposta[i].municipio_frete_minimo,
                        selecionada: false,
                        index_scroll: (i+2)*25,
                    };

                    // console.log("cliente.frete_minimo");
                    // console.log(cliente.nome);
                    // console.log(cliente.frete_minimo);

                    if(cliente.municipio_tda === null) cliente.municipio_tda = '';

                    if(cliente.cliente_tda === null) cliente.cliente_tda = '';

                    list.push(cliente);
                    this.setState({list: list});
                }

                this.setState({loading: false});
                
            }
        } finally {      
            this.state.objeto.setListClientes(this.state.list, this.state.tipo);
            // this.state.objeto.setState({loading: false});
            // this.state.MapaKseg.toast(this.state.list.length);
            }
    };
    //--------------------------
}