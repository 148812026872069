import  {Component} from 'react';
import api from './api';
import {ToastStore} from 'react-toasts';
import Moment from 'moment';

// import {Polyline} from 'google-maps-react';

export default class GetComandoVeiculo extends Component{
    constructor(props, RenderComandos){
        super(props);
        this.state = {
             comandoConfiguracao: {},
             comando: {},
             coman: props,
             comandoConfiguracao_list: [],
             comandosVei_list: [],
             loading:true,
             RenderComandos:RenderComandos,
       }
    }
    //---------------------
    toastStore = (texto) => {
        ToastStore.success("teste: "+texto);
    };
    //--------------------
    getComandoVei = async () => {
        try {
            let body = new FormData()
            body.append('token', localStorage.getItem('token'))
            body.append('tipo_comando_id', this.state.coman.tipo_comando_id)
            body.append('versao_equipamento_id', this.state.coman.versao_equipamento_id)

            // console.log("  ***datetime: "+datetime)

            const response = await api.post('/comando_api/getComandosVei', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                var comandosVeiJO = resposta[0];
                var comandoConfiguracaoJO = resposta[1];

                
                let comandoConfiguracao = this.state.comandoConfiguracao;
                let comandoConfiguracao_list = this.state.comandoConfiguracao_list;       
                
                let comando = this.state.comando;
                let comandosVei_list = this.state.comandosVei_list;

                // ToastStore.success(" comandoConfiguracao length: "+ String(comandoConfiguracaoJO.length)
                // +"\n comandosVei_list length: "+ String(comandosVeiJO.length));

                for (var i = 0; i < comandoConfiguracaoJO.length; i++) {                   

                    comandoConfiguracao = {    
                        comando_configuracao_id: comandoConfiguracaoJO[i].comando_configuracao_id,
                        comando_id: comandoConfiguracaoJO[i].comando_id,
                        descricao: comandoConfiguracaoJO[i].descricao,
                        valor_maximo: comandoConfiguracaoJO[i].valor_maximo,
                        tipo_comando_envio_id: comandoConfiguracaoJO[i].tipo_comando_envio_id
                    };

                    comandoConfiguracao_list.push(comandoConfiguracao);
                    this.setState({comandoConfiguracao_list: comandoConfiguracao_list});
                }

                for (var i = 0; i < comandosVeiJO.length; i++) {               

                    comando = {
                        comando_id: comandosVeiJO[i].comando_id,
                        descricao: comandosVeiJO[i].descricao,
                        comando: comandosVeiJO[i].comando,
                        tipo_comando_id: comandosVeiJO[i].tipo_comando_id,
                        tipo_comando_envio_id: comandosVeiJO[i].tipo_comando_envio_id,
                        configuravel: comandosVeiJO[i].configuravel,
                        opcional: comandosVeiJO[i].opcional,
                        administrador: comandosVeiJO[i].administrador                      
                    };

                    // console.log("   *** comando_id: "+String(comando.comando_id)+"\n     descricao: "+comando.descricao + "\n   administrador: "+String(comando.administrador));

                    // if (comando.administrador){
                    //     if (localStorage.getItem('pessoa_id')===1)  //Kanohi
                    //         comandosVei_list.push(comando);                      
                    // }else comandosVei_list.push(comando);

                    comandosVei_list.push(comando);
                    this.setState({comandosVei_list: comandosVei_list});
                }

                this.setState({loading: false});
                
            }else{
            }

        } finally {
                // ToastStore.success("GetPo coords.length: "+ String(coords.length));
                // ToastStore.success("PPlaca: "+ String(lastPosition.data_receb));
                // this.state.mapaKseg.setCoords(coords, lastPosition, markerEvento_list);
                // this.state.mapaKseg.setState({comandos_list: this.state.list});
                this.state.RenderComandos.setState({comandoConfiguracao_list: this.state.comandoConfiguracao_list});
                this.state.RenderComandos.setState({comandosVei_list: this.state.comandosVei_list});
                this.state.RenderComandos.showComandoModal();

            }
    };
    //--------------------------
    async componentDidMount() {
        this.getComandoVei();
    };
}