import React from 'react';

import 'devextreme/data/odata/store';
import DataGrid, { Column, Scrolling, Selection, MasterDetail, HeaderFilter, FilterPanel, FilterRow  } from 'devextreme-react/data-grid';
import {Nav, Navbar, Form,Card, Spinner, NavDropdown, FormControl, Button, Container} from 'react-bootstrap';
import '../estilo.css';
import ContextMenu from 'devextreme-react/context-menu';
import GetRotas from '../services/get_rotas';

import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

export class RotaGrid extends React.Component {
  constructor(props) {
    super(props);
    // this.onRowPrepared = this.logEvent.bind(this, 'RowInserting');
    this.onRowPrepared = this.onRowPrepared.bind(this); 

    this.state = {
      props: props,
      list:[],
      // height_grid:1500,

      bloqueado_consultar:false,
      load:true,
      items:[]
    }

  // -------------------------------
    this.dataGridRef = React.createRef();
 
    this.selectedRowsData = [];

    this.getSelectedData = () => {
        this.selectedRowsData = this.dataGrid.getSelectedRowsData();
        this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
        });
    }
    //-----
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onRowExpanding = this.onRowExpanding.bind(this);

  }
//------------------------------------------
  get dataGrid() {
    return this.dataGridRef.current.instance;
  }
//------------------------------------------
  onRowPrepared(e) {  
    if(e.data !== undefined){
      if (e.data.operacao === 0 ){
          if(e.data.index %2 === 0)
              e.rowElement.style.backgroundColor = "#F5F5F5";
          else e.rowElement.style.backgroundColor = "#FFFFFF";                

      }else if (e.data.operacao === 1 || e.data.operacao === 3)
              e.rowElement.style.backgroundColor = "#92d1b0";
      else if(e.data.operacao===2)
          e.rowElement.style.backgroundColor = "#f593a0";     
   }       
  } 
  //-------------------------------------------------------------------
  ItemTemplate (e) {
    return (
      <React.Fragment>
        <span className={ e.icon } />
        { e.items ? <span className="dx-icon-spinright" /> : null }
        { e.text }
      </React.Fragment>
    );
  }
  //---------------------------------------------------------------
  itemClick = (e) => {
    if (!e.itemData.items) {
      let selectedRowData = this.dataGrid.getSelectedRowsData(); 

      if(e.itemData.text === this.state.items[0].text){
        let rota = {rota_id:0, descricao:"", filial_id:0, filial:""}; // Novo
        this.props.MapaKseg.state.Modals.showRotaCadModal(this, rota);
      }else if(e.itemData.text === this.state.items[1].text){
        if(this.state.list.length > 0)
          this.props.MapaKseg.state.Modals.showRotaCadModal(this, selectedRowData[0]);
      }else if(e.itemData.text === ' Excel Exportar'){  
        this.exportDataGridToxlsx("Rotas");        
      }
    }
  }
    //------------------------------------------------------------------
    exportDataGridToxlsx = (nome) =>  {

      let today = new Date();
      let time = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
      let date = today.getDate() + "-" + (today.getMonth() + 1) + '-' + today.getFullYear() + '  '+time;
  
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
  
      let compo = this.dataGridRef.current.instance;
      let nomeArquivo = nome+date+".xlsx";
  
      exportDataGrid({
          // component: e.component,
          component: compo,
          worksheet: worksheet
      }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), nomeArquivo);
           });
      });
    
    }
  //---------------------------------------------------------------
  setRota = (rota) => {
    let list = this.state.list;
    let encontrou =false;

    for (var i = 0; i < list.length; i++) {    
      if(list[i].rota_id == rota.rota_id){
        encontrou=true;
        list[i].rota_id = rota.rota_id;
        list[i].descricao = rota.descricao;
        list[i].municipio_id = rota.municipio_id;
        list[i].municipio = rota.municipio;
        list[i].pessoa_id = rota.pessoa_id;
        list[i].nome_social = rota.nome_social;
        list[i].operacao = 1;
        break;
      }
    }

    if(encontrou == false){
      let list_aux = [];
      rota.operacao = 1;
      list_aux.push(rota);
      list_aux = list_aux.concat(list);
      this.setState({list: list_aux});
    }else{
      list = list.concat(); // para não precisar dar reload no grid
      this.setState({list: list});
    }

  }
  //--------------------------------------------------------------
  onSelectionChanged({ selectedRowsData }) {
    // this.props.MapaKseg.ajustarZoom(selectedRowsData[0]);
  }
  //-------------------------------------------------------------
  onRowExpanding= (e) => {
    e.component.selectRows(e.key, false);
  }
  //-----------------------------------------
  componentDidMount() {  
    let items = this.state.items;
    items.push({ text: ' Novo', icon: "dx-icon-add"  });
    items.push({ text: ' Cadastro', icon: "dx-icon-activefolder"  });
    items.push({ text: ' Excel Exportar', icon: "dx-icon-exportxlsx"  });

    this.setState({items: items, load:false});
  };
  //--------------------------------------------------------------------
  getApi = () => {
    this.setState({bloqueado_consultar: true, load:true});
    var instance = new GetRotas(null, this, this.props.MapaKseg, 0, 0);
    instance.get(); 
  }  
  //--------------------------------------------------------------------
  setListRota = (_list, tipo) => {
    this.setState({list: _list, bloqueado_consultar: false, load: false});
  }  
  //---------------------------------------------------------------
  render() {    
    const onContextMenuPreparing = (e) =>{  // precisa desse para selecionar a linha com o botao direito
      try{
        e.component.selectRows(e.row.key, false)
        var selectedRowData = this.dataGrid.getSelectedRowsData();

        // console.log(e.row.key);
        // alert(e.row.key);
      }catch (response){}
    }
          
    // const columns = ['index', 'rota_id','descricao','pessoa_id', 'nome_social', 'municipio_id', 'municipio'];
    const columns = ['index', 'rota_id','descricao', 'filial'];

    return (      
      <div>
        <Navbar bg="light" expand="lg">
              <Container>
                  <Navbar.Brand href="#home">{this.props.titulo}</Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />

                  <Form autoFocus className="d-flex">
                      {/* <FormControl type="search" placeholder="Nome" className="me-2" aria-label="Search" /> */}
                      <Button variant="outline-primary" 
                          onClick={()=>this.getApi()} disabled={this.state.bloqueado_consultar} >
                          <div>Consultar</div>
                      </Button>
                  </Form>
              </Container>
          </Navbar>

        {this.state.load?
          <Form  style={{height: '390px', overflow: 'hidden'}}>
            <div className="centro">
                <div className="espaco_topo3">
                    <Spinner animation="border" />
                </div>
            </div>                                        
          </Form> 
        :       
          <div >                                    
            <ContextMenu
              dataSource={this.state.items}
              width={180}
              target="#rotagrid"
              itemRender={this.ItemTemplate}
              onItemClick={this.itemClick} />

              <DataGrid
                id="rotagrid"
                ref={this.dataGridRef}
                dataSource={this.state.list}
                showBorders={true}
                defaultColumns={columns}
                onRowPrepared={this.onRowPrepared}
                columnAutoWidth={true}
                onContextMenuPreparing={onContextMenuPreparing}
                height={this.props.height_grid}                
              > 
                <Selection mode="single" />        
                <Scrolling mode="virtual" />
                <HeaderFilter visible={true} />
                <FilterRow visible={true} />
              </DataGrid>
    
          </div>
        }       
      </div>
    );
  }
}
