import React, {Component} from 'react';
import {Modal, Form, Button as Button_bootstrap, Row, Col, Spinner, Carousel} from 'react-bootstrap';
import '../estilo.css';
import api from '../api';
// import fs from 'fs';
import ImageCropper from "../imagecropper";
import Resizer from 'react-image-file-resizer';
import CustomScroll from 'react-customscroll';

function blobToFile(theBlob, fileName){
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}

function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

export  class ComprovanteModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true, 
            showSheet:true,
            texto: "",
            loading: true,
            img64: '',
            activeIndexCarousel:0,
            height_modal:400,
            comprimindo:false,
        };
      }   
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeComprovanteModal();
    };
    //------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarComprovanteModal();
    };
    //--------------------------
    async componentDidMount() {
        // this.props.MapaKseg.toastError(this.props.obj_selected.canhoto+" - "+this.props.obj_selected.canhoto64);
        // console.log(this.props.obj_selected);
        // if(this.props.obj_selected.canhoto === "Sim"){
        // this.props.MapaKseg.toastError(String(this.props.obj_selected.canhoto));
        // this.props.MapaKseg.toastError(String(this.props.obj_selected.canhoto));
        if(this.props.obj_selected.canhoto === true){                
            this.setState({activeIndexCarousel:0});
            if(this.props.obj_selected.canhoto64 !== "")
                this.setState({img64: this.props.obj_selected.canhoto64, loading: false});   
            else this.getImagemComprovante();
        }else{
            if(this.props.tipo === "visualizar")
                this.setState({activeIndexCarousel:0, loading: false});
            else this.setState({activeIndexCarousel:1, loading: false});
        }

        // this.setState({texto: this.props.texto, tipo: this.props.tipo});
        // document.getElementById("ControlTextArea1").focus();
    };
    //---------------------------------------------------------------
    getImagemComprovante = async () => {
        try {       
            
            let body = new FormData()
            body.append('entrega_id', this.props.obj_selected.entrega_id);
            body.append('coleta_id', this.props.obj_selected.coleta_id);
            body.append('token', localStorage.getItem('token'));

            let dir = "getImagemCanhoto";

            if(this.props.titulo === "Comprovante Coleta") dir = "getComprovanteColeta";            

            let response = await api.post('/coletaweb_api/'+dir, body);
            // let response = await api.post('/teste_api/getEntregas', body);
            
            var responseJson = null;

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);

                this.setState({img64: resposta.canhoto_base64});
                console.log("resposta.canhoto_base64:");
                console.log(resposta.canhoto_base64);

                let obj_selected = this.props.obj_selected;
                // obj_selected.canhoto = true;
                // obj_selected.canhoto = "Sim";
                obj_selected.canhoto64 = resposta.canhoto_base64;
                this.props.objeto.setState({objeto: obj_selected});
                this.setState({activeIndexCarousel:0});
                
            }
        } finally {      
            this.setState({loading: false});
        }
    };
    //------------------------
    abrirImagem = ()=>{
        if(this.state.img64 !== '')
            this.openBase64NewTab();
    };
    //-------------------------
    getBase64= async(file)=> {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        return reader.result;
     }
    //------------------------
    okImagem = async()=>{
        this.setState({comprimindo: true});
        var img64 = this.state.ImageCropper.state.imageDestination;
        const base64Response = await fetch(img64);
        let blob = await base64Response.blob();

        blob = await this.compressFile(blob);

        img64 = await toBase64(blob);

        var pieces = img64.split(",");
        img64 = pieces[1];

        let obj_selected = this.props.objeto.state.objeto;
        // obj_selected.canhoto = true;
        // obj_selected.canhoto = "Sim";
        obj_selected.canhoto64 = img64;
        this.props.objeto.setState({objeto: obj_selected, changed: true});
        this.fecharModal();
    };
    //-------------------------------------------------------
    compressFile = async(file)=>{
        let quality = 100;
        const resFile = (file) => new Promise(resolve => {
            Resizer.imageFileResizer(file, 1660, 1660, 'JPEG', quality, 0,
            uri => {
                resolve(uri);
                var myFile = blobToFile(uri, "my_image.png");
                this.setState({fileObjectURL: URL.createObjectURL(myFile)
                                    , render_image:true, file: myFile});
                // console.log(uri);
            },
            'blob'
            );
        });

        let image = await resFile(file);

        while (image.size > 732266){
            quality = quality - 10;
            image = await resFile(file);
            // console.log("quality: "+String(quality));
            // console.log("image:");
            // console.log(image);
        }

        return image;
    }
    //---------------------------
    handleChange = async(event) =>{
        if(event.target.files){

            this.setState({fileObjectURL: URL.createObjectURL(event.target.files[0])
                , render_image:true, file: event.target.files[0]});
        }
    }
    //-------------------------
    onInputClick = ()=>{
        this.setState({fileObjectURL:null, render_image:false});
    }
    //---------------------------------
    openBase64NewTab = ()=> {
        let base64Image = 'data:image/png;base64,'+this.state.img64;
        // let base64image = base64String.split(';base64,').pop();
        const parts = base64Image.split(';base64,');
        // Hold the content type
        const imageType = parts[0].split(':')[1];
        // Decode Base64 string
        const decodedData = window.atob(parts[1]);
        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);
        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }
        // Return BLOB image after conversion
        // return new Blob([uInt8Array], { type: imageType });
        //-------
        var blob = new Blob([uInt8Array], { type: imageType });
        // var blob = this.base64toBlob(this.state.img64);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
        } else {
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl);
        }
      }
      //---------------------------------------
      base64toBlob(base64Data) {
        const sliceSize = 1024;
        const byteCharacters = atob('data:image/png;base64,'+base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);
      
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
          const begin = sliceIndex * sliceSize;
          const end = Math.min(begin + sliceSize, bytesLength);
      
          const bytes = new Array(end - begin);
          for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
          }
          byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: "application/pdf" });
      }
    //------------------------- 
    render(){
        return(
            <div className="splashscreen-modal1">
                <Modal  centered
                    {...this.props}
                    backdrop="static"
                    
                    aria-labelledby="example-custom-modal-styling-title"
                    scrollable="True"
                    dialogClassName="temperatura-modal2"    
                    >
                    <div >
                        <strong className="cursor">
                            <div className="cabecalho"> 
                            <label className="titulo2" > {this.props.titulo} </label>
                                {/* <label className="titulo2" > Aviso! </label> */}
                            </div>
                        </strong>

                        {this.state.loading ?
                            <Spinner className="centro3" animation="border" role="status"/>
                            :
                            <div id="outer2">
                                <div style={{height: this.state.height_modal, overflow:'hidden'}}>
                                    <CustomScroll  scrollWidth="15px" scrollBarRadius="0">
                                        <Carousel  interval={null}  controls={false} indicators={false} activeIndex={this.state.activeIndexCarousel}>                                
                                            <Carousel.Item >                                 
                                                {/* <img src={`data:image/png;base64,${this.state.img64}`} width="450" height="500"/> */}
                                                {this.state.img64 === ""?
                                                    <h3 style={{padding:50, textAlignVertical: "center",textAlign: "center",}}>Sem Comprovante!</h3>
                                                    :
                                                    <img src={`data:image/png;base64,${this.state.img64}` } height="450"  /> }
                                            </Carousel.Item>          

                                            <Carousel.Item>                                                                 
                                                    <input id="fileItem" type="file" accept="image/*" onChange={this.handleChange} onClick={()=> this.onInputClick()}></input>
                                                    {this.state.render_image ?
                                                        // <img src={`data:image/png;base64,${this.state.img64}`} width="450" height="500"/>
                                                        // <img src={this.state.fileObjectURL} width="450" height="500"/>
                                                        <ImageCropper aspectRatio={0} UsuarioModal={this} src={this.state.fileObjectURL}></ImageCropper>
                                                    :""}                                                                       
                                            </Carousel.Item>   
                                            
                                        </Carousel>                                                                                     
                                    </CustomScroll>
                                </div>             
                            </div>                                
                            
                            // this.state.img64 == ''?
                            //     <h3 style={{padding:50, textAlignVertical: "center",textAlign: "center",}}>Sem Comprovante!</h3>
                            // :
                            //     <Modal.Body>           
                            //         <img src={`data:image/png;base64,${this.state.img64}`} width="450" height="500"/>
                            //     </Modal.Body>
                        }
                    </div>


                    <Modal.Footer>
                        {/* {this.props.cancelar ?
                            <Button_bootstrap className="left" onClick={this.props.onHide}>Cancelar</Button_bootstrap> : ""
                        } */}
                        {/* <Button_bootstrap  onClick={this.fecharClick}> Baixar</Button_bootstrap> */}
                        { this.state.img64 === '' ?
                            <Button_bootstrap onClick={this.okImagem}>
                                {this.state.comprimindo
                                    ? <Spinner size="sm" as="span" animation="grow" role="status" aria-hidden="true" />                                                                
                                    :<div> Ok </div>
                                }                                                                
                            </Button_bootstrap> : ""
                            // <Button_bootstrap  onClick={this.okImagem}> Ok</Button_bootstrap> : ""
                        }

                        { this.state.img64 !== '' ?
                            <Button_bootstrap  onClick={this.abrirImagem}> Abrir</Button_bootstrap> : ""
                        }
                        
                        <Button_bootstrap variant="outline-secondary" onClick={this.fecharModal}> Fechar</Button_bootstrap>                
                    </Modal.Footer>

                </Modal>

            </div>
        );
    }
}