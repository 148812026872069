import  {Component} from 'react';
import api from '../api';

export default class PostColeta extends Component{
    constructor(props, objeto, MapaKseg ){
        super(props);
        this.state = {
             list: [],
             loading:true,
             objeto: objeto,
             coleta_id:null,
             MapaKseg: MapaKseg
       }
    }
    //------------------------------------------------
    post = async () => {
        let coleta_idd= 0;
        try {        
            let editados = this.state.objeto.state.editados;
            let coleta = this.state.objeto.state.coleta;
            coleta_idd = coleta.coleta_id;

            let response = null;
            let responseJson = null;
            let formData =null;  
            let coleta_lancada = false;

            if(editados.cliente || editados.local || editados.rota || editados.pagamento || editados.valor 
                || editados.previsao || editados.observacao || editados.cte || editados.pago || editados.tda
                || editados.nota_fiscal){
                formData = new FormData()      

                if(editados.cliente) formData.append('cliente_id', coleta.cliente_id);
                if(editados.local) formData.append('local_id', coleta.local_id);
                if(editados.rota) formData.append('rota_id', coleta.rota_id);
                if(editados.pagamento) formData.append('tipo_pagamento_id', coleta.tipo_pagamento_id);
                if(editados.valor) formData.append('valor', coleta.valor);
                if(editados.previsao) formData.append('data_previsao', coleta.data_previsao);
                if(editados.observacao) formData.append('observacao', coleta.observacao);
                if(editados.cte) formData.append('cte', coleta.cte);
                if(editados.pago) formData.append('pago', coleta.pago);
                if(editados.tda) formData.append('tda', coleta.tda);
                if(editados.nota_fiscal) formData.append('nota_fiscal', coleta.nota_fiscal);
                
                formData.append('coleta_id', coleta.coleta_id); 
                formData.append('usuario_cadastro_id', this.state.MapaKseg.state.usuario_id);
                formData.append('pessoa_id', this.state.MapaKseg.state.pessoa_id);
                formData.append('token', localStorage.getItem('token'));

                // console.log(" formData: ");
                // for (var value of formData.values()) {
                //     console.log(value);
                //  }

                // response = await api.post('/teste_api/postColeta', formData);
                response = await api.post('/coletaweb_api/postColeta', formData);


                if (response.data !== null  &&  response.status === 200){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json); 
                    // console.log(resposta.poligono_id);
                    // var coleta_id = resposta.coleta_id;        
                    coleta_idd = resposta.coleta_id;     
                    coleta_lancada = true;
                }else{
                    //  this.state.MapaKseg.toastError(" Erro ao Inserir Alguma Informação na Coleta! ");

                    let texto = "Alguma Informação Incorreta!"
                    +" \n -  "+String(coleta.cliente)
                    +"\n \n   Insira a Coleta Novamente!";
                    let imagem = require('../img/close_100dp.png');
                    this.state.MapaKseg.state.Modals.showMensagemModal(this, "Coleta Incorreta", texto, false, imagem);
                }
                
            }

            console.log(" coleta_idd: "+String(coleta_idd));

            if(coleta_idd != 0){
                if(editados.entregas==true){

                    let entregas = [];
                    entregas = entregas.concat(coleta.entregas);
                    // console.log(" entregas: ");
                    // console.log(entregas);
                    entregas = entregas.filter((entrega,index) =>{ return entrega.operacao !== 0;});        

                    if(entregas.length >0){
                        entregas.sort((a,b) => a.operacao - b.operacao);
                        let list_json =[];
                        let entrega_tipo_pagamento_id = coleta.tipo_pagamento_id;              

                        for (var i = 0; i < entregas.length; i++) {   

                            if (String(entregas[i].tipo_pagamento_id) !== "0")
                                entrega_tipo_pagamento_id = entregas[i].tipo_pagamento_id;

                            list_json.push(JSON.stringify({
                                entrega_id: entregas[i].entrega_id
                                , coleta_id: coleta_idd
                                , cliente_id: entregas[i].cliente_id
                                , rota_id: entregas[i].rota_id
                                , local_id: entregas[i].local_id
                                , tipo_pagamento_id: entrega_tipo_pagamento_id
                                , valor: entregas[i].valor
                                , data_previsao: entregas[i].data_previsao
                                , observacao: entregas[i].observacao
                                , cte: entregas[i].cte
                                , pago: entregas[i].pago
                                , operacao: entregas[i].operacao
                                , tda: entregas[i].tda 
                                , nota_fiscal: entregas[i].nota_fiscal }));
                        }

                        formData = new FormData();
                        formData.append('token', localStorage.getItem('token'));
                        formData.append('pessoa_id', this.state.MapaKseg.state.pessoa_id);
                        formData.append('list_json', list_json);

                        // response = await api.post('/teste_api/postColetaEntregas', formData);
                        response = await api.post('/coletaweb_api/postColetaEntregas', formData);

                        if (response.data !== null  &&  response.status === 200){
                        responseJson = JSON.stringify(response.data)
                        var req = { json: responseJson };
                        var resposta = JSON.parse(req.json); 
                            // console.log(resposta.poligono_id);
                            // var coleta_id = resposta.coleta_id;    
                        // this.state.MapaKseg.toast(" "+String(resposta.resposta ));


                        let texto = "";

                        if(coleta_lancada == true){
                            texto = "Coleta Lançada!"
                            +" \n -  Coleta id: "+String(coleta_idd)
                            +" \n -  Cliente: "+String(coleta.cliente)
                            +" \n -  Rota: "+String(coleta.rota)
                            +" \n -  Pagamento: "+String(coleta.tipo_pagamento)
                            +" \n -  Previsão: "+String(coleta.data_previsao);
                            let imagem = require('../img/checkbold_100dp.png');
                            this.state.MapaKseg.state.Modals.showMensagemModal(this, "Lançada", texto, false, imagem);
                        }else{
                            texto = "Entrega Lançada!"
                            let imagem = require('../img/checkbold_100dp.png');
                            this.state.MapaKseg.state.Modals.showMensagemModal(this, "Lançada", texto, false, imagem);
                        }

                        }else{
                            let texto = "Alguma Informação Incorreta!"
                            +"\n   Insira a Entrega Novamente!";
                            let imagem = require('../img/close_100dp.png');
                            this.state.MapaKseg.state.Modals.showMensagemModal(this, "Entrega Incorreta", texto, false, imagem);
                        }
                    }
                }    
            }

  
        } finally {      
            let coleta = this.state.objeto.state.coleta;
            coleta.coleta_id = coleta_idd;
            this.state.objeto.setState({coleta: coleta});
            this.state.objeto.concluido();
            this.state.objeto.concluido(coleta);
            }
    };
    //-------------------------
    //------------------------------------------------
    confirmarMensagem = (texto) => {};
    //--------------------------
}