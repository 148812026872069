import React, {Component} from 'react';
import {Modal, Spinner} from 'react-bootstrap';
import './estilo.css';
import { ToastStore} from 'react-toasts';

import {LineChartTemp} from './LineChartTemp';
// import mergeImages from 'merge-images';
import {GetTemperaturasVei} from './GetTemperaturasVei';

import {CabecalhoModal} from './CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';

export class TemperaturaModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bottomSheet:true, 
            showSheet:true,
            tempGridModalShow:false,
            clockVisibility:"not-visible",
            imagem_carreta:null,
            renderLoading: props.renderLoading,
            temperaturaList:null,
            list: [],
            loading : true,
            animation: "zoom",
        };
      }
    //------------------------
    async componentDidMount() {
    }
    //--------------------
    getTemperaturas = () => {
        this.props.temperatura_obj.getTemperaturas=false;
        var instance = new GetTemperaturasVei(this.props, null, this); 
        instance.getTemperaturas(this.props.temperatura_obj.veiculo_id);
    }
    //--------------------
    setData = (temperaturaList) => {
        this.props.temperatura_obj.renderLoading=false;
        this.setState({temperaturaList: temperaturaList});
    }
    //--------------------
    toastStore2 = () => {
        ToastStore.success("teste2222");
    };
    //--------------------
    toastStore3 = () => {
        // this.state.TempGridModal.setState({temperaturaModalShow:true});
        this.props.TempGridModal.showTemperaturaModal2();
    };
  //-------------------------
    onComplete = () => {
        this.setState({clockVisibility:"visible"});
        this.toastStore2();
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.SideNavDrawer.temperaturaModalClose();
    };
    //------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.SideNavDrawer.temperaturaModalDesativar();
    };
    //-------------------------
    render(){
        const imagem=null;
        return(
            <div className="drag1" >
                <Draggable   handle="strong"  >                
                    <div >                    
                        <Rodal 
                            visible={this.props.show}
                            animation={this.state.animation}
                            showCloseButton={false}
                            duration={600}
                            width={600}
                            height={580}
                            onAnimationEnd={()=>this.desativarModal()}
                            >  
                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={true} titulo={this.props.temperatura_obj.placa} Modal={this}/></strong>
                                <div className="padding_modal">
                                    <div className="image_carreta" >
                                        <img src={'img/carreta_temperatura/carreta_'+this.props.temperatura_obj.sensores+'.jpg'} className="carreta_center" />
                                        <table className="table_porcima">
                                            <tr>
                                                <td className="back_sensores">  <div className="bold">Sensor_1</div> {this.props.temperatura_obj.sensor1} C°</td>
                                                <td className="back_sensores">  <div className="bold">Sensor_2</div> {this.props.temperatura_obj.sensor2} C°</td>
                                                <td className="back_sensores">  <div className="bold">Sensor_3</div> {this.props.temperatura_obj.sensor3} C°</td>
                                            </tr>
                                        </table>                                 
                                    </div>
                                    
                                    {this.props.temperatura_obj.renderLoading  ?  
                                            <div className="centro">
                                                {this.props.temperatura_obj.getTemperaturas ? this.getTemperaturas() : ""}
                                                <Spinner className="margin_top" animation="border"/>
                                            </div>
                                        :
                                            <div>                                                                                                            
                                                <LineChartTemp
                                                    placa={this.props.placa}
                                                    veiculo_id={this.props.temperatura_obj.veiculo_id}
                                                    renderTemperatura={null}
                                                    temperaturaList={this.state.temperaturaList}
                                                    list={this.state.list}
                                                    nomeFaixa={this.props.temperatura_obj.nomeFaixa}
                                                    minLine={this.props.temperatura_obj.minLine}
                                                    maxLine={this.props.temperatura_obj.maxLine}
                                                />
                                            </div>           
                                    }                            
                                </div>                                
                            </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>
        );
    }
}