import  {Component} from 'react';
import api from '../api';

export default class GetGrupoUsuario extends Component{
    constructor(props, objeto, MapaKseg, usuario_id, tipo_grupo_id){
        super(props);
        this.state = {
             list: [],
             list2:[],
             objeto: objeto,
             MapaKseg: MapaKseg,
             usuario_id: usuario_id,
             tipo_grupo_id: tipo_grupo_id
       }
    }
    //------------------------------------------------
    get = async () => {
        try {
            let body = new FormData()
            body.append('usuario_id', this.state.usuario_id)
            body.append('tipo_grupo_id', this.state.tipo_grupo_id)
            body.append('token', localStorage.getItem('token'))

            const response = await api.post('/cadastro_api/getGrupoUsuario', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let obj = null;
                let list = this.state.list; 
                let list2 = this.state.list2;                 
                
                for (var i = 0; i < resposta.length; i++) {                        
                    obj = {    
                        index: 0,
                        usuario_grupo_id: resposta[i].usuario_grupo_id,                        
                        grupo_id: resposta[i].grupo_id,                        
                        nome: resposta[i].nome,
                        tipo_grupo_id: resposta[i].tipo_grupo_id,   
                        operacao:0                     
                    };                   

                    if(obj.tipo_grupo_id==1){
                        obj.index = list.length+1;
                        list.push(obj);
                    }else if(obj.tipo_grupo_id==2){
                        obj.index = list2.length+1;
                        list2.push(obj);    
                    }

                    this.setState({list: list, list2: list2});
                }                
            }
        } finally {      
            let usuario = this.state.objeto.state.usuario;
            usuario.grupos = this.state.list;
            usuario.areas = this.state.list2;
            this.state.objeto.setState({usuario: usuario});                  
            }
    };
}