import  {Component} from 'react';
import api from '../api';

export default class GetPessoa extends Component{
    constructor(props, objeto, MapaKseg, tipo, tipo_usuario_id){
        super(props);
        this.state = {
             list: [],
             objeto: objeto,
             MapaKseg: MapaKseg,
             tipo:tipo,
             tipo_usuario_id:tipo_usuario_id,
       }
    }
    //------------------------------------------------
    get = async () => {
        try {
            let body = new FormData()

            if(this.state.pessoa_id !== 0)
                body.append('tipo_usuario_id', this.state.tipo_usuario_id)

            body.append('token', localStorage.getItem('token'))

            const response = await api.post('/cadastro_api/getTipoUsuario', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let obj = null;
                let list = this.state.list;                
                
                for (var i = 0; i < resposta.length; i++) {                        
                    obj = {    
                        index: i+1,
                        tipo_usuario_id: resposta[i].tipo_usuario_id,                        
                        descricao: resposta[i].descricao,   
                        operacao:0
                    };                   

                    list.push(obj);
                    this.setState({list: list});
                }                
            }
        } finally {      
            this.state.objeto.setListTipoUsuario(this.state.list, this.state.tipo);                  
            }
    };
}