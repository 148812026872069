import React from 'react';
import DataGrid, { Column, FilterRow, HeaderFilter, SearchPanel, Export, ColumnChooser } from 'devextreme-react/data-grid';
import { SelectBox, CheckBox, Button } from 'devextreme-react';
import service from './data_datagrid.js';
import { Container, Row,Col } from 'react-bootstrap';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';


import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
const dataGridRef = React.createRef();

const saleAmountEditorOptions = { format: 'currency', showClearButton: true };

export class DataGridEventos extends React.Component {
  constructor(props) {
    super(props);
    this.orders = service.getOrders();
    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately'
    }, {
      key: 'onClick',
      name: 'On Button Click'
    }];
    this.saleAmountHeaderFilter = [{
      text: 'Less than $3000',
      value: ['SaleAmount', '<', 3000]
    }, {
      text: '$3000 - $5000',
      value: [
        ['SaleAmount', '>=', 3000],
        ['SaleAmount', '<', 5000]
      ]
    }, {
      text: '$5000 - $10000',
      value: [
        ['SaleAmount', '>=', 5000],
        ['SaleAmount', '<', 10000]
      ]
    }, {
      text: '$10000 - $20000',
      value: [
        ['SaleAmount', '>=', 10000],
        ['SaleAmount', '<', 20000]
      ]
    }, {
      text: 'Greater than $20000',
      value: ['SaleAmount', '>=', 20000]
    }];
    this.state = {
      showFilterRow: true,
      showHeaderFilter: true,
      currentFilter: this.applyFilterTypes[0].key,
      focusedRowKey: 1,
      icone_visible:true,
    };
    this.dataGrid = null;
    this.orderHeaderFilter = this.orderHeaderFilter.bind(this);
    this.onShowFilterRowChanged = this.onShowFilterRowChanged.bind(this);
    this.onShowHeaderFilterChanged = this.onShowHeaderFilterChanged.bind(this);
    this.onCurrentFilterChanged = this.onCurrentFilterChanged.bind(this);
    this.onTaskIdChanged = this.onTaskIdChanged.bind(this);
    this.verPosicaoMapaClick = this.verPosicaoMapaClick.bind(this);    
  }
  //-----------------

  //-----------------
  onTaskIdChanged(e) {
    if(e.event && e.value > 0) {
      this.setState({ focusedRowKey: e.value });
    }
  }
  //------------------
  verPosicaoMapaClick(e) {
  
    // var employees = this.state.employees.slice(),
    //   clonedItem = Object.assign({}, e.row.data, { ID: service.getMaxID() });

    // employees.splice(e.row.rowIndex, 0, clonedItem);
    // this.setState({ employees: employees });
    // e.event.preventDefault();


    this.props.RelPosicoesVeiModal.minimizarModal();
    var veiculoPosicao = {latitude: e.row.data.latitude, longitude: e.row.data.longitude
            ,tipo_recebimento_id:1
            ,data_receb: e.row.data.data_receb
            ,velocidade: e.row.data.velocidade};
    this.props.MapaKseg.setInfoWindow(veiculoPosicao);
    this.props.MapaKseg.ajustarZoom(veiculoPosicao);
  }
  //-----------------
  exportGrid=()=> {
    this.setState({icone_visible: !this.state.icone_visible});
    const doc = new jsPDF();
    const dataGrid = dataGridRef.current.instance;
  
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: dataGrid
    }).then(() => {
      doc.save(this.props.placa+'.pdf');
      this.setState({icone_visible: !this.state.icone_visible});
    });    
  }
    //-----------------------
    get gridContainer() {
      return dataGridRef.current.instance;
    }
    //----------------
    exportDataGridToxlsx = () =>  {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
      const context = this;
  
  
      exportDataGrid({
        // component: e.component,
        component: context.gridContainer,
        worksheet: worksheet
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), this.props.placa+'.xlsx');
        });
      });
      // e.cancel = true;
    }
    //-----------------
  //-----------------
  verTrechoMapa=()=> {
    var coords = [];
    for (var i = 0; i < this.props.orders.length; i++) {
      coords.push({lat: this.props.orders[i].latitude, lng: this.props.orders[i].longitude});
    }
    this.props.MapaKseg.pushTrechoPosicao(coords, this.props.orders);

    // this.props.MapaKseg.setState({trecho_evento_list: this.props.orders});

    var veiculoPosicao = {latitude: this.props.orders[0].latitude, longitude: this.props.orders[0].longitude
      ,tipo_recebimento_id: this.props.orders[0].tipo_recebimento_id
      ,data_receb: this.props.orders[0].data_receb
      ,velocidade: this.props.orders[0].velocidade};
      
    this.props.MapaKseg.ajustarZoom(veiculoPosicao);
    this.props.RelPosicoesVeiModal.minimizarModal();
        
  }
  //-----------------
  render() {
    return (
      <div className="wrap">       
        <DataGrid id="gridContainer"
          ref={dataGridRef}
        //   dataSource={this.orders}
          dataSource={this.props.orders}
          showBorders={true}
          focusedRowEnabled={false}
          keyExpr="recebimento_id"
          
          hoverStateEnabled={true}
          allowColumnResizing={true}
          columnResizingMode={"nextColumn"}
          // focusedRowKey={2737879}
          // focusedRowKey={this.state.focusedRowKey}
          >
            

          <FilterRow visible={this.state.showFilterRow}
            applyFilter={this.state.currentFilter} />

          <HeaderFilter visible={this.state.showHeaderFilter} />

          <SearchPanel visible={false}
            width={240}
            placeholder="Pesquisar..." />

          <ColumnChooser enabled={true} title="Escolha a Coluna"/>

          <Column dataField="id" caption="ID" alignment="left" width={50}/>

          <Column dataField="recebimento_id"
            caption="Posição id"
            alignment="left"
            visible={false}>
            <HeaderFilter groupInterval={10000} />
          </Column>

          <Column dataField="mensagem_externo_id"
            caption="Msg Número"
            alignment="left"
            visible={false}>
            <HeaderFilter groupInterval={10000} />
          </Column>

          <Column dataField="placa"
            caption="Placa"
            >
            <HeaderFilter allowSearch={true} />
          </Column>

          <Column dataField="municipio"
            caption="Município"
            >
            <HeaderFilter allowSearch={true} />
          </Column>

          <Column dataField="estado"
            caption="Estado"
            alignment="left"
            >
            <HeaderFilter allowSearch={true} />
          </Column>

          <Column dataField="pais"
            caption="País"
            alignment="left"
            >
            <HeaderFilter allowSearch={true} />
          </Column>

          <Column dataField="velocidade"
            caption="Km/h"
            alignment="left"
            format="###"
            >
            <HeaderFilter groupInterval={300} />
          </Column>

          {/* <Column dataField="ignicao" 
            caption="Ignição"
            width={80}>
            <HeaderFilter allowSearch={true} />
          </Column> */}
 
          <Column dataField="data_inicial_format"
            alignment="right"
            // dataType="datetime"
            // format="EEE, dd/MM/yy HH:mm:ss"
            caption="Data inicial"
            width={160}/>

          <Column dataField="data_final_format"
            alignment="right"
            // dataType="datetime"
            // format="M/d/yyyy, HH:mm"
            caption="Data final"
            width={160} />

          <Column dataField="data_receb"
            alignment="right"
            // dataType="datetime"
            // format="M/d/yyyy, HH:mm"
            caption="data2"            
            visible={false} />

          <Column dataField="dateDiff"
            caption="Duração Evento"
            >
            <HeaderFilter allowSearch={true} />
          </Column> 

         <Column dataField="tipo_evento_id"
            width={80}
            caption="tipo evento"
            alignment="left"
            visible={false}>
            <HeaderFilter groupInterval={10000}/>
          </Column>

          <Column dataField="icone_marker"
            caption="Ícone"
            width={50}
            allowSorting={false}
            cellRender={cellRender}
            visible={this.state.icone_visible}
            >          
          </Column>

         <Column dataField="evento"
            caption="Evento"
            >
            <HeaderFilter allowSearch={true} />
          </Column>  

          <Column dataField="valor"
            caption="valor"
            />

          <Column dataField="latlng"
            alignment="right"
            caption="lat, lng"  
            editorOptions={saleAmountEditorOptions}
            visible={false} >
            <HeaderFilter dataSource={this.saleAmountHeaderFilter} />
          </Column>

          <Column type="buttons" width={110}
            caption="Ver no mapa" 
            buttons={['edit', 'delete', {
              hint: 'ver',
              icon: require("./img/location_marker20.png"),
              visible: this.isCloneIconVisible,
              onClick: this.verPosicaoMapaClick
            }]} />

          <Column dataField="backup" caption="Backup">
            <HeaderFilter allowSearch={true} />
          </Column>
          

        </DataGrid>
        


        {/* -----------Row---------------- */}
        <Container className="button-container">
          <Row >
            <Col md={1} bsPrefix={"espaco_right"}>
            <Button
              id='exportButton'
              icon='exportpdf'
              text='PDF'
              onClick={this.exportGrid}   />
            </Col>   
            {this.props.MapaKseg.state.pessoa_id===1 ?
              <Col md={2} >
                <Button
                  id='exportButton2'
                  icon='exportxlsx'
                  text='xlsx'
                  onClick={this.exportDataGridToxlsx}                
                />
              </Col>  
            :""}  

            {this.props.MapaKseg.state.pessoa_id===1 ?
              <Col md={2} >
                <Button 
                  id='vertrechomapa'
                  icon={require("./img/road3.png")}
                  text='Mapa'
                  onClick={this.verTrechoMapa}
                />
              </Col>  
            :""}            
          </Row>
        </Container>


      
      </div>
    );
  }
  calculateFilterExpression(value, selectedFilterOperations, target) {
    let column = this;
    if (target === 'headerFilter' && value === 'weekends') {
      return [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]];
    }
    return column.defaultCalculateFilterExpression.apply(this, arguments);
  }
  orderHeaderFilter(data) {
    data.dataSource.postProcess = (results) => {
      results.push({
        text: 'Weekends',
        value: 'weekends'
      });
      return results;
    };
  }
  onShowFilterRowChanged(e) {
    this.setState({
      showFilterRow: e.value
    });
    this.clearFilter();
  }
  onShowHeaderFilterChanged(e) {
    this.setState({
      showHeaderFilter: e.value
    });
    this.clearFilter();
  }
  onCurrentFilterChanged(e) {
    this.setState({
      currentFilter: e.value
    });
  }
  clearFilter() {
    this.dataGrid.instance.clearFilter();
  }
}

function getOrderDay(rowData) {
  return (new Date(rowData.OrderDate)).getDay();
}

function cellRender(data) {
  return <img src={data.value} width="20"  height="20"/>;
}




// export default DtGrid;