import  {Component} from 'react';
import api from '../api';
import {ToastStore} from 'react-toasts';
import Moment from 'moment';

// import {Polyline} from 'google-maps-react';

export default class GetEntrega extends Component{
    constructor(props, objeto, MapaKseg, rotas){
        super(props);
        this.state = {
             rota_filial: {},
             list: [],
             loading:true,
             objeto: objeto,
             rotas: rotas,
             entrega:null,
             MapaKseg: MapaKseg,
       }
    }
    //--------------------------------------------------------------
    somarFreteTDA =(_frete, _tda) =>{
        _frete = parseFloat(String(_frete).replace(".", "").replace(",", "."));
            
        if (String(_tda) === "undefined")  _tda = 0;
        
        if (_tda !== "") _tda = parseFloat(String(_tda).replace(".", "").replace(",", "."));
        else _tda = 0;
    
        let total = _frete + _tda;
        total = total.toLocaleString('pt-br', {minimumFractionDigits: 2});
        return total;        
        }    
    //------------------------------------------------
    get = async () => {
        try {
            var rotas = this.state.rotas;
            var rotas_id_str="";
            for (var i = 0; i < rotas.length; i++) {  
                if (i==0) rotas_id_str+=rotas[i].rota_id;
                else rotas_id_str+=","+rotas[i].rota_id;
            }
            
            if(rotas_id_str === "") rotas_id_str = "0"

            let body = new FormData()
            body.append('rotas_id_str', rotas_id_str);
            body.append('pessoa_id', this.state.MapaKseg.state.pessoa_id);
            body.append('token', localStorage.getItem('token'));

            let response = await api.post('/coletaweb_api/getEntregas', body);
            // let response = await api.post('/teste_api/getEntregas', body);
            
            var responseJson = null;

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let entrega = this.state.entrega;
                let list = this.state.list;      
                let hoje = new Date();                    

                // ToastStore.success("resposta.length: "+ String(resposta.length));
                for (var i = 0; i < resposta.length; i++) {                        
                    entrega = {    
                        index: i+1,
                        entrega_id: resposta[i].entrega_id,
                        cliente_id: resposta[i].cliente_id,
                        cliente: resposta[i].cliente,
                        rota: resposta[i].rota,
                        data_previsao: resposta[i].data_previsao,
                        data_previsao2: resposta[i].data_previsao2,
                        data_entrega: resposta[i].data_entrega,
                        data_entrega2: resposta[i].data_entrega2,
                        tipo_pagamento_id: resposta[i].tipo_pagamento_id,
                        tipo_pagamento: resposta[i].tipo_pagamento,
                        valor: resposta[i].valor,
                        tda: resposta[i].tda,
                        total_frete_tda:0,
                        local: resposta[i].local,     
                        usuario: resposta[i].usuario,           
                        municipio: resposta[i].municipio,    
                        coleta_id: resposta[i].coleta_id,
                        observacao: resposta[i].observacao,
                        cte: resposta[i].cte,
                        pago: resposta[i].pago,
                        pago_str: '',
                        rastreio_codigo: resposta[i].rastreio_codigo,
                        nota_fiscal: resposta[i].nota_fiscal,
                        canhoto: resposta[i].canhoto,
                        canhoto64: '',

                        status:2,
                        selecionada: false,
                        index_scroll: (i+2)*25,
                    };

                    if(String(entrega.data_entrega).trim() !== "")
                        entrega.status = 1 // 1 - concluido
                    else if( new Date(entrega.data_previsao).getTime() < hoje.getTime())
                        entrega.status = 3 // 1 - Atrasada     


                    if(entrega.pago === true)
                        entrega.pago_str = 'Foi Pago'
                    else if(entrega.pago === false)    
                        entrega.pago_str = 'Não Pago'

                    if(entrega.tda === "None") entrega.tda = "";
                    else if(entrega.tda === "0") entrega.tda = "";

                    if(entrega.nota_fiscal === "None") entrega.nota_fiscal = "";
                    else if(entrega.nota_fiscal === "0") entrega.nota_fiscal = "";

                    // if(entrega.canhoto == true) entrega.canhoto = 'Sim';
                    // else if(entrega.canhoto == false) entrega.canhoto = '';
                    

                    entrega.total_frete_tda = this.somarFreteTDA(entrega.valor, entrega.tda);

                    list.push(entrega);
                    
                }

                list = list.sort((a,b) => new Date(b.data_previsao).getTime() - new Date(a.data_previsao).getTime() );                

                this.setState({list: list});
                this.setState({loading: false});
                
            }
        } finally {      
            this.state.objeto.setState({entregas: this.state.list});
            this.state.objeto.setState({load: false});
            // this.state.MapaKseg.toast(this.state.list.length);
            }
    };
    //--------------------------
}