import React from 'react';

import 'devextreme/data/odata/store';
import DataGrid, { Column, Scrolling, Selection, HeaderFilter, FilterPanel, FilterRow } from 'devextreme-react/data-grid';
import {Nav, Navbar, Form,Card, Spinner, NavDropdown, FormControl, Button, Container} from 'react-bootstrap';
import '../estilo.css';
import ContextMenu from 'devextreme-react/context-menu';
import SelectBox from 'devextreme-react/select-box';
import GetRotaFilial from '../services/get_rotafilial';
import GetVeiculos from '../services/get_veiculos';
import {GetComandosVeiculo} from '../GetComandosVeiculo';

import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

export class VeiculosGrid extends React.Component {
  constructor(props) {
    super(props);
    // this.onRowPrepared = this.logEvent.bind(this, 'RowInserting');
    this.onRowPrepared = this.onRowPrepared.bind(this); 

    this.state = {
      countNotificacao: 0,
      props: props,
      list:[],
      veiculos:[],
      cadColetaModalAtivo:false,
      // height_grid:1500,

      bloqueado_consultar:false,
      load:true,
      items:[],
    }

    this.onValueRotaChanged = this.onValueRotaChanged.bind(this);

  // -------------------------------
    this.dataGridRef = React.createRef();
 
    this.selectedRowsData = [];

    this.getSelectedData = () => {
        this.selectedRowsData = this.dataGrid.getSelectedRowsData();

        // ===== or when deferred selection is used =====
        this.dataGrid.getSelectedRowsData().then((selectedRowsData) => {
            // Your code goes here
        });
    }
    //-----
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onRowExpanding = this.onRowExpanding.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);

  }
//------------------------------------------
  get dataGrid() {
    return this.dataGridRef.current.instance;
  }
//------------------------------------------
  onRowPrepared(e) {  
    // if (e.rowType !== "data")  
    //     return  
    // alert(JSONString);

    if(e.data !== undefined){
      if (e.data.status === 1)
        e.rowElement.style.backgroundColor = "#5ed194";
      else if(e.data.status===2)
        e.rowElement.style.backgroundColor = "#ebd12d";
      else if(e.data.status===3)
        e.rowElement.style.backgroundColor = "#f26b7d";        
    }  

    // if (e.rowType === "data")  
    //   e.data.selectRows([5, 10, 12], true);
     
  } 
  //---------------------------------------------------------------
  addMenuItems(e) {
    if (e.target == 'header') {
        // e.items can be undefined
        if (!e.items_coleta) e.items_coleta = [];

        // Add a custom menu item
        e.items_coleta.push({
            text: 'Log Column Caption',
            onItemClick: () => {
                // alert(e.column.caption);
                console.log(e.column.caption);
            }
        });
    }
  }
  //-------------------------------------------------------------------
  ItemTemplate (e) {
    return (
      <React.Fragment>
        <span className={ e.icon } />
        { e.items_coleta ? <span className="dx-icon-spinright" /> : null }
        { e.text }
      </React.Fragment>
    );
  }
  //---------------------------------------------------------------
  itemClick = (e) => {
    if (!e.itemData.items) {
      let selectedRowData = this.dataGrid.getSelectedRowsData();       

      if(this.state.veiculos.length !== 0){
        this.props.MapaKseg.setState({veiculoPosicao_selecionado: selectedRowData[0]});

        if(e.itemData.text === this.state.items[0].text){
          this.props.MapaKseg.state.Modals.showComandoGridModal(selectedRowData[0]);
          var instance = new GetComandosVeiculo(selectedRowData[0], this.props.MapaKseg); 
          instance.getComandosVei();             
        }else if(e.itemData.text === this.state.items[1].text){
          this.props.MapaKseg.state.Modals.showVeiculoInfoModal(selectedRowData[0]);          
        }else if(e.itemData.text === ' Excel Exportar'){  
          this.exportDataGridToxlsx("Veículos");        
        }
      }
    }
  }
  //------------------------------------------------------------------
  exportDataGridToxlsx = (nome) =>  {
    let today = new Date();
    let time = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
    let date = today.getDate() + "-" + (today.getMonth() + 1) + '-' + today.getFullYear() + '  '+time;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    let compo = this.dataGridRef.current.instance;
    let nomeArquivo = nome+date+".xlsx";

    exportDataGrid({
        // component: e.component,
        component: compo,
        worksheet: worksheet
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), nomeArquivo);
          });
    });  
  }  
  //--------------------------------------------------------------
  onSelectionChanged({ selectedRowsData }) {
    // this.props.MapaKseg.ajustarZoom(selectedRowsData[0]);
  }
  //-------------------------------------------------------------
  onRowExpanding= (e) => {
    e.component.selectRows(e.key, false);
  }
   //-----------------------------------------
   componentDidMount() {
    let items = this.state.items;
    items.push({ text: ' Enviar comando', icon: "dx-icon-export"  });
    items.push({ text: ' Cadastro Veículo', icon: "dx-icon-activefolder"  });
    items.push({ text: ' Excel Exportar', icon: "dx-icon-exportxlsx"  });
    this.setState({items: items, load:false});
    // this.props.MapaKseg.setState({GridVeiculos: this});
  };
  //------------------------------------------
  carregarItemsColetaContextMenu() {
    var items_coleta = this.state.items_coleta;
    items_coleta.push({ text: ' Nova Coleta', icon: "dx-icon-add"  });

    // if(this.props.MapaKseg.state.pessoa_id===1)
    //   items.push({ text: ' Nova Coleta', icon: "dx-icon-activefolder"  });

    this.setState({items_coleta: items_coleta}); 
    }
  //------------------------------------------
  carregarItemsEntregaContextMenu() {
    var items_entrega = this.state.items_entrega;
    items_entrega.push({ text: ' Nova Entrega', icon: "dx-icon-add"  });

    // if(this.props.MapaKseg.state.pessoa_id===1)
    //   items.push({ text: ' Nova Coleta', icon: "dx-icon-activefolder"  });

    this.setState({items_entrega: items_entrega}); 
    } 
  //--------------------------------------------------------------------
  getVeiculos = () => {
    this.setState({bloqueado_consultar: true, load:true});
    var instance = new GetVeiculos(null, this, this.props.MapaKseg);
    instance.get(); 
  }
  //--------------------------------------------------------------------------------
  onValueChangedGrupo(filial_id ) {
    var filiais = this.state.filiais;
    var list_aux = filiais.filter((item,index) =>{  
      return item.filial_id === filial_id;
    });
    this.setState({filterStatusFilial: list_aux[0].descricao});
  }
  //--------------------------  
  onValueChanged({ value }) {
      console.log("value:");
      console.log(value);
      console.log(value.trim());
      // this.props.MapaKseg.toast(value.trim());

      var filiais = this.state.filiais;
      var list_aux = filiais.filter((item,index) =>{  
        return item.descricao.trim() === value.trim();
      })
  
      console.log("list_aux:");
      console.log(list_aux);
  
      // if (value === 'All') {
      //   dataGrid.clearFilter();
      // } else {
      //   dataGrid.filter(['nome', '=', value]);
      // }
  
      this.setState({filterStatusFilial: value});
      // localStorage.setItem('grupo_id', list_aux[0].grupo_id);
      // this.props.MapaKseg.setState({grupo_id: list_aux[0].grupo_id});
      // this.postGrupoVeiculoAtual(this.props.MapaKseg.state.usuario_id, list_aux[0].grupo_id);
      // this.props.MapaKseg.recarregarPosicoesClick();
      this.getRotaFilial(list_aux[0].filial_id);
    }
  //--------------------------  
  onValueRotaChanged= (e) => {
    this.setState(state =>{
      rotas:state.rotas.map(obj => obj.descricao === e.target.name 
                               ? (obj.selecionada = e.target.value, obj)                               
                               : obj)
    },)
  }    
  //------------------------------------------
  getRotaFilial(filial_id) {
    var instance = new GetRotaFilial(null, this, this.props.MapaKseg, filial_id);
    instance.get(); 
  }  
  //-------------------------------------------------------------
  pushGridNovaColeta= (nova_coleta) => {
    let coletas = this.state.coletas;
    coletas = coletas.concat(nova_coleta);
    this.setState({coletas: coletas});

  }
  ativar= () => {
    // e.component.selectRows(e.key, false);
    this.setState({ load:false});
    // this.props.MapaKseg.ajustarZoom(selectedRowsData[0]);
  }
  //---------------------------------------------------------------
  render() {    
    const onContextMenuPreparing = (e) =>{
      try{
        e.component.selectRows(e.row.key, false)
        var selectedRowData = this.dataGrid.getSelectedRowsData();
        this.props.MapaKseg.ajustarZoom(selectedRowData[0]);

        console.log(e.row.key);
        // alert(e.row.key);
      }catch (response){}
  }

    const filialSelectBox =       
        <SelectBox
          items={this.state.filiais_descricao}
          value={this.state.filterStatusFilial}
          onValueChanged={this.onValueChanged} 
          />  ;
    
    return (      
      <div>
        <Navbar bg="light" expand="lg">
              <Container>
                  <Navbar.Brand href="#home">{this.props.titulo}</Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />

                  <Form className="d-flex">
                      {/* <FormControl type="search" placeholder="Nome" className="me-2" aria-label="Search" /> */}
                      <Button variant="outline-primary" 
                          onClick={()=>this.getVeiculos()} disabled={this.state.bloqueado_consultar} >
                          <div>Consultar</div>
                      </Button>              
                  </Form>
              </Container>
          </Navbar>

        {this.state.load?
          <Form  style={{height: '390px', overflow: 'hidden'}}>
            <div className="centro">
                <div className="espaco_topo3">
                    <Spinner animation="border" />
                </div>
            </div>                                        
          </Form> 
        :       
          <div >                                    
            <ContextMenu
                dataSource={this.state.items}
                width={180}
                target="#veiculosGrid"
                ref={this.dataGridRef}
                itemRender={this.ItemTemplate}
                onItemClick={this.itemClick} />

                <DataGrid
                  id="veiculosGrid"
                  ref={this.dataGridRef}
                  dataSource={this.state.veiculos}
                  showBorders={true}
                  rowAlternationEnabled={true}
                //   onRowPrepared={this.onRowPrepared}
                  columnAutoWidth={true}
                  onContextMenuPreparing={onContextMenuPreparing}
                  onSelectionChanged={this.onSelectionChanged}    
                  height={this.props.height_grid}
                > 
                  <Selection mode="single" /> 
                  <Scrolling mode="virtual" />
                  <HeaderFilter visible={true} />
                  <FilterRow visible={true} />
                </DataGrid>
    
          </div>
        }       
      </div>
    );
  }
}
