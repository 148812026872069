import  {Component} from 'react';
import api from './api';
import {ToastStore} from 'react-toasts';
import base64 from 'react-native-base64'
// import {Polyline} from 'google-maps-react';

export class GetFotoUsuario extends Component{
    constructor(props, usuario_id, email, mapaKseg){
        super(props);
        this.state = {
             loading: true,
             usuario_id: usuario_id,
             email: email,
             mapaKseg: mapaKseg,
             resposta:'',
       }
    }
    //---------------------
    toastStore = (texto) => {
        ToastStore.success("teste: "+texto);
    };
    //--------------------
    getFoto = async (mapaKseg) => {
        try {
            var resposta="";
            let body = new FormData()
            body.append('usuario_id', localStorage.getItem('usuario_id'))
            body.append('usuario_login', this.state.email)
            body.append('token', localStorage.getItem('token'))

            const response = await api.post('/login_api/getImgUsuario2', body);
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                resposta = JSON.parse(req.json);
                this.setState({resposta: resposta});    
                resposta = "data:image/png;base64,"+resposta;
                this.setState({loading: false});                                   
            }else{
            }

        } finally {
                mapaKseg.setFotoUsuario(resposta);

            }
    };
    //--------------------------
    async componentDidMount() {
        // this.getFoto();
    };
}