import React from 'react';

import 'devextreme/data/odata/store';
import {Nav, Navbar, NavDropdown, Row, Col, Container, Carousel, Spinner, Card} from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton'
import ReactCountdownClock  from 'react-countdown-clock';
import Badge from '@material-ui/core/Badge';
import './estilo.css';
import {  Button as ButtonFab, lightColors } from 'react-floating-action-button'

import {GridVeiculos} from './GridVeiculos';
import {GridClientes} from './GridClientes';
import {GridAreas} from './GridAreas';
import {GridColetaEntrega} from './GridColetaEntrega';
import {JornadaGrid} from './painel/JornadaGrid';

import ScrollView from 'devextreme-react/scroll-view';
import api from './api';

import GetTabUsuario from './services/get_tabusuario';

var tabClientes_enable=false;
var tabAreas_enable=false;
var tabColetaEntrega_enable=false;
var tabJornada_enable=false;


export class PainelGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        countNotificacao: 0,
        activeKey:"",

        tabClientes_enable:false,
        tabAreas_enable:false,
        tabColetaEntrega_enable:false,
        tabJornada_enable:false,
        activeIndexCarouselGrids: 0,
        acaoFechar:false,
        tabs:[],
        tabs2:[],
        tab_load:true,
        GridColetaEntrega: null,
        JornadaGrid: null,
    }

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }
//------------------------------------------
  handleClick =(event) =>  {
    let tabs_aux = this.state.tabs;
    let tabs_aux2 = this.state.tabs2;
    for (var i = 0; i < tabs_aux.length; i++) {     
      if (tabs_aux[i].descricao === event.target.getAttribute('value')){
        tabs_aux[i].enable=true;
        this.setState({ activeIndexCarouselGrids: tabs_aux[i].index});
        tabs_aux2.push(tabs_aux[i]);
      }
    }
    this.setState({tabs: tabs_aux});
    this.setState({tabs2: tabs_aux2});
    this.setState({activeKey: event.target.getAttribute('value')});

    let index=0;
    this.setState(state =>{
      tabs2:state.tabs2.map(obj => ((obj.index= index, obj), 
                                (index+=1 )))                                
    },);

    if(event.target.getAttribute('value') === "Clientess"){
        tabClientes_enable=true;
        // this.props.MapaKseg.setState({tabClientes_enable:true, activeIndexCarouselGrids: 1});
        this.setState({tabClientes_enable:true, activeIndexCarouselGrids: 1});
        this.setState({activeKey:"Clientes"});
        
    }else if(event.target.getAttribute('value') === "areas_tab"){
        tabAreas_enable=true;
        // this.props.MapaKseg.setState({tabAreas_enable:true, activeIndexCarouselGrids: 2});
        this.setState({tabAreas_enable:true, activeIndexCarouselGrids: 2});
        this.setState({activeKey:"areas_tab"});
    }else if(event.target.getAttribute('value') === "coleta_entrega_tab"){
      tabColetaEntrega_enable=true;
      this.setState({tabColetaEntrega_enable:true, activeIndexCarouselGrids: 3});
      this.setState({activeKey:"coleta_entrega_tab"});
    }else if(event.target.getAttribute('value') === "jornada_tab"){
      tabJornada_enable=true;
      this.setState({tabJornada_enable:true, activeIndexCarouselGrids: 5});
      this.setState({activeKey:"jornada_tab"});
    }
  };
  //------------------------------------------
  handleSelect = (eventKey) =>{
    let tabs_aux = this.state.tabs2;
    for (var i=0; i < tabs_aux.length; i++) {     
      if (tabs_aux[i].descricao === eventKey.target.getAttribute('value')){
        this.setState({ activeIndexCarouselGrids: tabs_aux[i].index});
      }
    }
    // this.setState({tabs: tabs_aux});
    this.setState({activeKey: eventKey.target.getAttribute('value')});       
  }
  //------------------------------------------
  handleClose2 =({currentTarget})=>  {   
    this.setState(() => ({ activeIndexCarouselGrids: 0, activeKey: this.state.tabs[0].descricao }))

    // console.log(" state "+this.state.activeIndexCarouselGrids+" - "+this.state.activeKey);
  }
  //------------------------------------------
  handleClose({currentTarget})  {    
    this.setState({tabs2: this.state.tabs2.filter(function(person) { 
      return person.descricao !== currentTarget.value  
  })});
    
    this.setState(state =>{
      tabs:state.tabs.map(obj => obj.descricao === currentTarget.value 
                               ? (obj.enable = !obj.enable, obj)                               
                               : obj)
    },)


    let index=0;
    this.setState(state =>{
      tabs2:state.tabs2.map(obj => ((obj.index= index, obj), 
                                (index+=1 )))                                
    },);

    let activeIndexCarousel=0;
    let activeKeydescricao="";

    let tabs_aux = this.state.tabs2;
    for (var i=0; i < tabs_aux.length; i++) {     
      if ((tabs_aux[i].descricao.trim() !== currentTarget.value.trim())){
        // console.log(" achou "+tabs_aux[i].index+" - "+tabs_aux[i].descricao);
        activeIndexCarousel = tabs_aux[i].index;
        activeKeydescricao = tabs_aux[i].descricao
      }
    }

    this.setState(() => ({ activeIndexCarouselGrids: activeIndexCarousel
                            ,activeKey: activeKeydescricao }));

    // console.log(" let "+activeIndexCarousel+" - "+activeKeydescricao);
    // console.log(" state "+this.state.activeIndexCarouselGrids+" - "+this.state.activeKey);
  }
  //--------------------------------------------------------------
  postMapaAtivo = async (usuario_id, mapa_ativo) => {  
    try {
      let body = new FormData();
      body.append('mapa_ativo', mapa_ativo);
      body.append('usuario_id', usuario_id);
      body.append('token', localStorage.getItem('token'));
      const response = await api.post('/login_api/setMapaAtivo', body);            
      var responseJson = null
      if (response.data !== null  &&  response.status !== 403){
          responseJson = JSON.stringify(response.data)
          var req = { json: responseJson };
          var resposta = JSON.parse(req.json);                  
          // this.props.MapaKseg.toast("Atualizado GrupoVeiculoAtual!");
      }
    } finally {}
  };
  //------------------------------------------
  clickMapaOnOff =(event) =>  {
    if(this.props.MapaKseg.state.mapa_ativo){
      // this.props.MapaKseg.setState({mapa_ativo: false, splitPaneSize:1000, SplitPaneAllowResize:false, grid_size:715});
      this.props.MapaKseg.setState({mapa_ativo: false, splitPaneSize:1000, SplitPaneAllowResize:false});
      this.postMapaAtivo(this.props.MapaKseg.state.usuario_id, false);
    }else{
    //  this.props.MapaKseg.setState({mapa_ativo: true, splitPaneSize:500, SplitPaneAllowResize:true, grid_size:480});  
     this.props.MapaKseg.setState({mapa_ativo: true, splitPaneSize:500, SplitPaneAllowResize:true});  
     this.postMapaAtivo(this.props.MapaKseg.state.usuario_id, true);
    }
  
    
  };
  //----------------------------------------
  async componentDidMount() {
    var instance = new GetTabUsuario(null,this, this.props.MapaKseg);
    instance.get();
  }
  //----------------------------------------
  componentDidUpdate(prevProps) {
    if(this.state.acaoFechar){
        this.setState({activeIndexCarouselGrids: 0, activeKey:"veiculos_tab"});
        this.setState({acaoFechar:false});
    }
  }
  //-------------------------------------------------------------------
  setTab (tab_id) {
    let renderTab = null;
    if(tab_id===1){
      renderTab = <GridVeiculos list={this.props.MapaKseg.state.list} MapaKseg={this.props.MapaKseg}/> ;
    }else if(tab_id===2){
      renderTab = <GridColetaEntrega MapaKseg={this.props.MapaKseg} PainelGrid={this}/>;
    }else if(tab_id===3){
      renderTab = <GridClientes MapaKseg={this.props.MapaKseg}/>;
    }else if(tab_id===4){
      renderTab = <GridAreas MapaKseg={this.props.MapaKseg}/>;
    }
    return renderTab;
  }

  //-------------------------------------------------------------------
  retornarTab () {
    if(this.state.tabs.length > 0){
      let descricao = this.state.activeKey;
      let tabs_aux = this.state.tabs.filter(function(tab) { 
        return tab.descricao === descricao  
      });

      // console.log("tabs_aux: ");
      // console.log(tabs_aux);

      if(tabs_aux[0].tab_id===1){
        return (<GridVeiculos list={this.props.MapaKseg.state.list} MapaKseg={this.props.MapaKseg}/> );
      }else if(tabs_aux[0].tab_id===2){
        return (<GridColetaEntrega MapaKseg={this.props.MapaKseg} PainelGrid={this} /> );
      }else if(tabs_aux[0].tab_id===3){
        return (<GridClientes MapaKseg={this.props.MapaKseg}/> );
      }else if(tabs_aux[0].tab_id===4){
        return (<GridAreas MapaKseg={this.props.MapaKseg}/> );
      }else if(tabs_aux[0].tab_id===5){
        return (<JornadaGrid MapaKseg={this.props.MapaKseg}  PainelGrid={this} /> );
      }
    }
  }
  //-------------------------------------------------------------------
  retornarTab2 (tab_id) {
    if(tab_id===1){
      return (<GridVeiculos list={this.props.MapaKseg.state.list} MapaKseg={this.props.MapaKseg}/> );
    }else if(tab_id===2){
      return (<GridColetaEntrega MapaKseg={this.props.MapaKseg} PainelGrid={this} /> );
    }else if(tab_id===3){
      return (<GridClientes MapaKseg={this.props.MapaKseg}/> );
    }else if(tab_id===4){
      return (<GridAreas MapaKseg={this.props.MapaKseg}/> );
    }else if(tab_id===5){
      return (<JornadaGrid MapaKseg={this.props.MapaKseg}/> );
    }
  }
  //--------------------------------------
  countdownComplete=()=> {    
    for (var i = 0; i < this.state.tabs2.length; i++) {          
      if(this.state.tabs2[i].enable){
        if(this.state.tabs2[i].tab_id===1){
          this.props.MapaKseg.recarregarPosicoesCountdown();
        }else if(this.state.tabs2[i].tab_id===2){
          this.state.GridColetaEntrega.getColetas();
          this.state.GridColetaEntrega.getEntregas();
          this.props.MapaKseg.setState({completions: this.props.MapaKseg.state.completions + 1});
        }else if(this.state.tabs2[i].tab_id===5){
          this.state.JornadaGrid.getDadosJornadaMotorista();
          this.props.MapaKseg.setState({completions: this.props.MapaKseg.state.completions + 1});
        }
        // else if(this.state.tabs2[i].tab_id===3){}
        // else if(this.state.tabs2[i].tab_id===4){}
      }
    }
  }
  //--------------------------------------
  countdownClick=()=> {    

    for (var i = 0; i < this.state.tabs2.length; i++) {          
      if(this.state.tabs2[i].enable){
        if(this.state.tabs2[i].tab_id===1){
          this.props.MapaKseg.recarregarPosicoesClick();
        }else if(this.state.tabs2[i].tab_id===2){
          this.state.GridColetaEntrega.getColetas();
          this.state.GridColetaEntrega.getEntregas();
          this.props.MapaKseg.setState({completions: this.props.MapaKseg.state.completions + 1});
        }else if(this.state.tabs2[i].tab_id===5){
          this.state.JornadaGrid.getDadosJornadaMotorista();
          this.props.MapaKseg.setState({completions: this.props.MapaKseg.state.completions + 1});
        }
        // else if(this.state.tabs2[i].tab_id===3){}
        // else if(this.state.tabs2[i].tab_id===4){}
      }
    }
  }
  //----------------------------------------
  render() {    
    const renderTab = this.retornarTab();

    return (        
        <div className="espaco_esq_tabs_grid"> 
          <Row>
            <Col  md={10}>
              {this.state.tab_load? <Spinner className="height_top_center4" animation="border" />
                :<Nav  variant="tabs" activeKey={this.state.activeKey} defaultActiveKey= {this.state.tabs[0].descricao} className="espaco_esq1" >
                  {this.state.tabs2.map((tab) => 
                    // tab.enable?
                    this.state.tabs2.length > 1                   
                      ?<Nav.Item >
                          <Nav.Link eventKey={tab.descricao} >
                              <Row>        
                                  <Col value={tab.descricao}  onClick={this.handleSelect}> {tab.descricao} </Col>
                                  <Col> <CloseButton aria-label="Hide" eventKey={tab.descricao} value={tab.descricao} onClick={this.handleClose}/> </Col>                            
                              </Row> 
                          </Nav.Link>                   
                      </Nav.Item>

                      :<Nav.Item >
                        <Nav.Link eventKey={tab.descricao}  >{tab.descricao}</Nav.Link>
                      </Nav.Item>
                    // :""
                  )}                            

                    {this.state.tabs.length>1?
                      <NavDropdown title={<b>+</b>}  id="nav-dropdown">
                        {this.state.tabs.map((tab) => (
                          !tab.enable?
                          <NavDropdown.Item  eventKey={tab.descricao} value={tab.descricao} onClick={this.handleClick}>{tab.descricao}</NavDropdown.Item>
                          :""
                        ))}  
                      </NavDropdown>         
                    :""}

                </Nav>
              }
            </Col>

            <Col  md={2} >
              <Container>
                <Row>
                  <div className="espaco_bottom4">
                    <ButtonFab 
                      // className={this.props.MapaKseg.state.buttonClockTransp}
                      tooltip="Clique para recarregar!"
                      styles={{backgroundColor: "transparent", color: lightColors.white}}
                      // onClick={()=>this.recarregarPosicoes()}
                      >

                      <div className="padd_dir" >
                          <ReactCountdownClock               
                              key={this.props.MapaKseg.state.completions}
                              seconds={this.props.MapaKseg.state.segundos}
                              showMilliseconds={false}
                              color="#6342d7"
                              size={50}
                              onComplete={()=>this.countdownComplete()}
                              onClick={()=>this.countdownClick() } /> 
                      </div>
                    </ButtonFab>       
                  </div>

                  <div className="espaco6">
                    <ButtonFab 
                      styles={{backgroundColor: "white", width:55, height:55  }}
                      onClick={()=>this.props.MapaKseg.state.Modals.showNotificacaoModal()}>
                        
                      <div>
                          <img src={require("./img/bell_ring48.png")} width="35"/>
                      </div>                                
                    
                      <Badge
                          anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                          }}
                          //  badgeContent={this.state.count_nao_visualizado} color="primary">
                          badgeContent={this.props.MapaKseg.state.count_nao_visualizado} color="primary">
                      </Badge>
                    </ButtonFab>       
                  </div>

                  <div className="espaco6">

                  <NavDropdown title={<img src={require("./img/settings48.png")} width="20"/>}  id="nav-dropdown">
                    <NavDropdown.Item  eventKey="mapa_onoff" value="mapa_onoff" onClick={this.clickMapaOnOff}>Mapa On/Off</NavDropdown.Item>
                  </NavDropdown>  

                    
                  </div>

                </Row>
              </Container>
            </Col>
          </Row>

          <ScrollView width='100%' height='100%'>
          
            <Carousel interval={null}  controls={false} indicators={false} activeIndex={this.state.activeIndexCarouselGrids}>

            {
              this.state.tabs2.map((tab) => (
                  <Carousel.Item>    
                    {renderTab}          
                    {/* { tab.renderTab} */}
                    {/* {this.retornarTab(tab.tab_id)} */}
                  </Carousel.Item> 
              ))
            }
  

            </Carousel>
  
          </ScrollView>
        
        </div>
    
    );
  }
}
