import React, {Component} from 'react';
import {Modal, Image, Spinner, Button, Form, Row,Col,Card,Nav,InputGroup,FormControl, Tab, Tabs} from 'react-bootstrap';
import { Switch } from 'devextreme-react/switch';
import '../estilo.css';
import {CabecalhoModal} from '../CabecalhoModal';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Draggable from 'react-draggable';
import CustomScroll from 'react-customscroll';
import api from '../api';
import GetClientePessoa from '../services/get_cliente_pessoa';
import GetRotaPessoa from '../services/get_rota_pessoa';
import { DateBox } from 'devextreme-react';
import { dinheiroMask } from '../dinheiro_mask'

const ENTREGA_CLIENTE_TIPO=4;
const ENTREGA_CLIENTE_LOCAL_TIPO=5;
const ENTREGA_ROTA_TIPO=6;


export class CadEntregaModal extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            animation: "zoom",
            list:[],
            loading:true,
            minimizar:false,
            bloqueado_salvar:true,
            GeralListModal:null,
            // tipo_pagamentos: [{id: 2, descricao:"FOB"},{id: 4, descricao:"AR FOB"}],
            tipo_pagamentos: [{tipo_pagamento_id: 1, descricao:"CIF"},{tipo_pagamento_id: 2, descricao:"FOB"}
            , {tipo_pagamento_id: 3, descricao:"AR CIF"}, {tipo_pagamento_id: 4, descricao:"AR FOB"}
            , {tipo_pagamento_id: 5, descricao:"PIX CIF"}, {tipo_pagamento_id: 6, descricao:"PIX FOB"} ],

            tipo_pagamentos2: [{tipo_pagamento_id: 2, descricao:"FOB"}, {tipo_pagamento_id: 4, descricao:"AR FOB"}
                                , {tipo_pagamento_id: 6, descricao:"PIX FOB"} ],

            
            data_previsao_coleta: null,
            valor: null,
            disable_pagamento: false,
            disable_valor:false,
            disable_pago: false,

            entrega: {entrega_id:0, cliente_id:0, cliente:"", local_id:0, local:"", rota_id:0, rota:""
            , tipo_pagamento_id:0, tipo_pagamento:"", valor:"", data_previsao:"", observacao:""
            , cte:"", pago:false, tda:"", nota_fiscal:"", cliente_tda:"", municipio_tda:""
            , municipio_frete_minimo:"", cliente_frete_minimo:""},

            activeKey:"Coleta",
            nova_entrega:{},
            salvando:false,
            height_modal:495,
            items_entrega:[],
            entregas:[]
        };
        this.showGeralList = this.showGeralList.bind(this);
        // this.setInputFocus = this.setInputFocus.bind(this);
        this.myInputRef = React.createRef();

        // -------------------------------
    }

    //--------------------------
    async componentDidMount() {
        this.setState({CadColetaModal: this.props.objeto});
        this.setState({minDateValue:  new Date((new Date()).getTime() - 1000 * 60 * 24 * 3)});        

        let entrega = this.state.entrega;
        entrega.tipo_pagamento_id = this.props.objeto.state.coleta.tipo_pagamento_id;
        entrega.tipo_pagamento = this.props.objeto.state.coleta.tipo_pagamento;                
        entrega.valor=0;
        entrega.tda=0;

        if(this.props.objeto.state.coleta.tipo_pagamento_id===1 
            || this.props.objeto.state.coleta.tipo_pagamento_id===3
            || this.props.objeto.state.coleta.tipo_pagamento_id===5){                
                this.setState({ disable_pagamento: true, disable_valor: true, disable_pago: true}); 
        }else{
            this.setState({tipo_pagamentos: this.state.tipo_pagamentos2});
        }

        if(this.props.objeto.state.coleta.pago===true ){      
            entrega.pago = true;
        }else if( this.props.objeto.state.coleta.tipo_pagamento_id===3 || this.props.objeto.state.coleta.tipo_pagamento_id===4 ){
            entrega.pago = true;
        }

        this.setState({entrega: entrega,loading: false}); 
    };
    //-------------------
    minimizarModal = () =>{
        this.setState({animation: "slideLeft"});
        this.setState({minimizar:true});
        this.props.Modals.closeCadEntregaModal();  
    };
    //---------close----------
    fecharModal = () =>{
        this.setState({animation: "zoom"});
        this.props.Modals.closeCadEntregaModal();
        // this.props.Modals.desativarCadEntregaModal();
    };
    //-------------------------
    desativarModal = () =>{    
        if(this.props.show === false)
            this.props.Modals.desativarCadEntregaModal();
    };   
    //-----------------------------------------------
    setPagamento(event) {        
        var list_aux = this.state.tipo_pagamentos.filter((item,index) =>{  return item.tipo_pagamento_id === parseInt(event.target.value)});
        if(list_aux.length > 0){
            let entrega = this.state.entrega;
            entrega.tipo_pagamento_id = list_aux[0].tipo_pagamento_id;
            entrega.tipo_pagamento = list_aux[0].descricao;

            if(event.target.value==3 || event.target.value==4){
                entrega.pago = true;
                this.setState({entrega:entrega});  
            }else{
                entrega.pago = false;
                this.setState({entrega:entrega});
            }            

            this.setState({entrega: entrega });
        }
      }
    //--------------------------------------------
    setDataPrevisaoEntrega = (e) => {
        let entrega = this.state.entrega;
        entrega.data_previsao = this.formatarDateTime(e.value);
        this.setState({entrega: entrega});
    }      
    //----------
    formatarDateTime(dtTime){    
        const y = String(dtTime).substring(11,15);
        const mm = this.formatarMes(String(dtTime).substring(4,7));
        const dd = String(dtTime).substring(8,10);
        const hm = String(dtTime).substring(16,21);

        // console.log("  *** dtTime: "+String(dtTime));
        // console.log("  *** y: "+y);
        // console.log("  *** mm: "+mm);
        // console.log("  *** mm: "+String(this.formatarMes(mm)));
        // console.log("  *** dd: "+dd);
        // console.log("  *** hm: "+hm);  
        const datetime = y+"-"+mm+"-"+dd+" "+hm;  // "Y-MM-DD H:M"
        return datetime;                  
      }
      //-------------------------
      formatarMes(mes){    
        if(mes==="Jan")
            return 1;
        else if (mes==="Feb")
            return 2;
        else if (mes==="Mar")
            return 3;     
        else if (mes==="Apr")
            return 4;     
        else if (mes==="May")
            return 5;     
        else if (mes==="Jun")
            return 6; 
        else if (mes==="Jul")
            return 7; 
        else if (mes==="Aug")
            return 8; 
        else if (mes==="Sep")
            return 9; 
        else if (mes==="Oct")
            return 10;    
        else if (mes==="Nov")
            return 11;
        else if (mes==="Dec")
            return 12;             
      }  
    //-------------------------
    setValorPagamento(event) {             
        let entrega = this.state.entrega;
        entrega.valor =  dinheiroMask(event.target.value); 
        this.setState({entrega: entrega});
    }
    //-------------------------
    setTDA(event) {             
        let entrega = this.state.entrega;
        entrega.tda =  dinheiroMask(event.target.value); 
        this.setState({entrega: entrega});
    }
    //-------------------------
    somarFreteTDA =(_frete, _tda) =>{
        _frete = parseFloat(String(_frete).replace(".", "").replace(",", "."));

        if (_tda !== "") _tda = parseFloat(String(_tda).replace(".", "").replace(",", "."));
        else _tda = 0;

        let total = '';

        if (String(_frete) === 'NaN') _frete = 0;
        if (String(_tda) === 'NaN') _tda = 0;

        total = _frete + _tda;
        total = total.toLocaleString('pt-br', {minimumFractionDigits: 2});

        return total;        
    }   
    //--------------------
    showGeralList= (e) => {   
        if(e.target.getAttribute('value') == ENTREGA_CLIENTE_TIPO){
            // this.props.Modals.showGeralList(this,"Clientes Entrega", ENTREGA_CLIENTE_TIPO, true);
            this.props.Modals.showPesquisarModal(this,"Clientes Entrega", true, 'Clientes Entrega');
        }else if(e.target.getAttribute('value') == ENTREGA_CLIENTE_LOCAL_TIPO)
            this.props.Modals.showGeralList(this,"Local da Entrega", ENTREGA_CLIENTE_LOCAL_TIPO);
        else if(e.target.getAttribute('value') == ENTREGA_ROTA_TIPO)
            this.props.Modals.showGeralList(this,"Rotas Entrega", ENTREGA_ROTA_TIPO);             
    }; 
    //-----------------------------------------------------
    getList= (geralListModal, tipo, filtro='') => { 
        this.setState({GeralListModal: geralListModal});  

        if(tipo == ENTREGA_CLIENTE_TIPO){ 
            var instance = new GetClientePessoa(null, this, this.props.MapaKseg, ENTREGA_CLIENTE_TIPO, filtro);
            instance.get(); 
        }else if(tipo == ENTREGA_CLIENTE_LOCAL_TIPO){ 
            var instance = new GetClientePessoa(null, this, this.props.MapaKseg, ENTREGA_CLIENTE_TIPO, filtro);
            instance.get(); 
        }else if(tipo == ENTREGA_ROTA_TIPO){ 
            var instance = new GetRotaPessoa(null, this, this.props.MapaKseg, ENTREGA_ROTA_TIPO, 0, null);
            instance.get(); 
        }
    }; 
    //--------------------------------------------------------------
    setListClientes= (_list, tipo) => {  
        let array =[];
        let descr = "";

        for (var i = 0; i < _list.length; i++) { 

            if(_list[i].cnpj_cpf == null) descr = _list[i].nome + " ("+_list[i].local+")"
            else descr = _list[i].nome +" ("+_list[i].cnpj_cpf +") ("+_list[i].local+")"

            array.push({
                index: _list[i].index
                  ,id: _list[i].cliente_id
                , descricao: descr
                , descricao2: _list[i].nome
                , cliente_tda : _list[i].cliente_tda
                , local_id: _list[i].local_id
                , local: _list[i].local
                , municipio_id : _list[i].municipio_id
                , municipio : _list[i].municipio
                , municipio_tda : _list[i].municipio_tda
                , rota_id: _list[i].rota_id
                , rota: _list[i].rota
                , cliente_frete_minimo: _list[i].cliente_frete_minimo
                , municipio_frete_minimo: _list[i].municipio_frete_minimo
                , selecionada: _list[i].selecionada
                , index_scroll: _list[i].index_scroll});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //--------------------
    setObjeto= (objeto, tipo) => { 
        if(tipo == ENTREGA_CLIENTE_TIPO){
            let entrega = this.state.entrega;

            entrega.cliente_id = objeto.id;
            entrega.cliente = objeto.descricao2;
            entrega.local_id = objeto.local_id;
            entrega.local = objeto.local;
            entrega.municipio = objeto.municipio;
            entrega.rota_id = objeto.rota_id;
            entrega.rota = objeto.rota;
            entrega.municipio_tda = objeto.municipio_tda;
            entrega.cliente_tda = objeto.cliente_tda;
            entrega.municipio_frete_minimo = objeto.municipio_frete_minimo;
            entrega.cliente_frete_minimo = objeto.cliente_frete_minimo;

            if(this.state.disable_valor===false){
                if(objeto.municipio_frete_minimo === '0') entrega.valor = '';
                else entrega.valor = objeto.municipio_frete_minimo;
    
                if(objeto.cliente_frete_minimo !== '0') entrega.valor = objeto.cliente_frete_minimo;
                // ------
                entrega.tda = objeto.municipio_tda;
                if(objeto.cliente_tda !== '') entrega.tda = objeto.cliente_tda;
            }
            
            this.setState({entrega: entrega});

            if(entrega.rota_id == 0){
                var instance = new GetRotaPessoa(null, this, this.props.MapaKseg, ENTREGA_ROTA_TIPO, objeto.municipio_id, entrega.cliente_id);
                instance.get(); 
            }

        }
        else if(tipo == ENTREGA_CLIENTE_LOCAL_TIPO){
            let entrega = this.state.entrega;
            entrega.local_id = objeto.local_id;
            entrega.local = objeto.local;
            this.setState({entrega: entrega});
        }
        else if(tipo == ENTREGA_ROTA_TIPO){
            let entrega = this.state.entrega;
            entrega.rota_id = objeto.id;
            entrega.rota = objeto.descricao2;
            this.setState({entrega: entrega});
        }

        this.setState({bloqueado_salvar: false});
    }
    //---------------------------------------------------
    setRota= (objeto) => { 
        let entrega = this.state.entrega;
        entrega.rota_id = objeto.rota_id;
        entrega.rota = objeto.descricao;
        this.setState({entrega: entrega});
        
        this.setState({bloqueado_salvar: false});
    }
    //---------------------------------------------
    setPago= (e) => {
        let entrega = this.state.entrega;
        entrega.pago = e.value;
    
        this.setState({entrega: entrega, bloqueado_salvar: false});
    }
     //--------------------
     setListRotas= (_list, tipo) => {  
        let array =[]
        for (var i = 0; i < _list.length; i++) { 
            array.push({
                index: _list[i].index
                , id: _list[i].rota_id
                , descricao: _list[i].descricao + " ("+_list[i].municipios+")"
                , descricao2: _list[i].descricao
                , selecionada: _list[i].selecionada
                , index_scroll: _list[i].index_scroll});
        }
        this.state.GeralListModal.setList(array, tipo);
    }; 
    //---------------------------------------------
    validarFrete =(_valor, _frete_minimo) =>{
        let resultado = false;

        _valor = parseFloat(String(_valor).replace(".", "").replace(",", "."));
        _frete_minimo = parseFloat(String(_frete_minimo).replace(".", "").replace(",", "."));

        if(_valor < _frete_minimo){
            _frete_minimo = _frete_minimo.toLocaleString('pt-br', {minimumFractionDigits: 2});
            this.props.MapaKseg.toastError("O Frete Mínimo é R$ "+String(_frete_minimo));
        }else resultado = true;

        return resultado;        
    }
    //---------------------------------------------
    validar = () => {              
        let valido=true;
   
        if(this.state.entrega.cliente_id==0 && valido){
            valido=false;            
            this.props.MapaKseg.toastError("Informe o Cliente da Entrega!");
            this.handleSelect("Entrega");
        }
        if(this.state.entrega.rota_id==0 && valido){
            valido=false;
            this.props.MapaKseg.toastError("Informe o Rota da Entrega!");
        }

        if(this.state.disable_valor===false){
            if(this.state.entrega.municipio_frete_minimo !=='0' || this.state.entrega.cliente_frete_minimo !=='0'){
                if(valido){
                    let frete_minimo = this.state.entrega.municipio_frete_minimo;
            
                    if(this.state.entrega.cliente_frete_minimo !== '0') frete_minimo = this.state.entrega.cliente_frete_minimo;
                    

                    valido = this.validarFrete(this.state.entrega.valor, frete_minimo);  
                }
            }
        }

        if(this.state.entrega.tipo_pagamento_id==0 && valido){
            valido=false;
            this.props.MapaKseg.toastError("Informe o Pagamento!");
        }

        if(this.state.entrega.data_previsao=="" && valido){
            valido=false;
            this.props.MapaKseg.toastError("Informe a Data de Previsão da Entrega!");
        }

        if(this.state.entrega.tipo_pagamento_id == 2 || this.state.entrega.tipo_pagamento_id == 4 || this.state.entrega.tipo_pagamento_id == 6)  // FOB
            if(this.state.disable_valor==false && valido){
                if(this.state.entrega.valor=="" || this.state.entrega.valor=="0" || this.state.entrega.valor==0 || this.state.entrega.valor==null ){
                    valido=false;
                    this.props.MapaKseg.toastError("Informe o Frete R$ !");
                }
            }    
        
        if(this.state.entrega.tipo_pagamento_id === undefined){
            valido=false;
            this.props.MapaKseg.toastError("Informe o Tipo de Pagamento novamente!");
        }    

        if(this.state.entrega.rota_id === undefined){
            valido=false;
            this.props.MapaKseg.toastError("Informe a Rota Novamente!");
        }    

        if(valido){
            // this.props.MapaKseg.toast("Válido!");
            this.setState({salvando:true, bloqueado_salvar:true});
            let nova_entrega = this.state.nova_entrega;
            let coleta = this.state.CadColetaModal.state.coleta;
            let entregas = [];
            entregas = entregas.concat(coleta.entregas);

            nova_entrega = {
                index: entregas.length+1
                , entrega_id:0
                , cliente_id: this.state.entrega.cliente_id
                , cliente: this.state.entrega.cliente
                , rota_id: this.state.entrega.rota_id
                , rota: this.state.entrega.rota
                , local_id: this.state.entrega.local_id 
                , local: this.state.entrega.local
                , tipo_pagamento_id: this.state.entrega.tipo_pagamento_id
                , tipo_pagamento: this.state.entrega.tipo_pagamento
                , valor: this.state.entrega.valor
                , data_previsao: this.state.entrega.data_previsao
                , municipio: this.state.entrega.municipio
                , observacao: this.state.entrega.observacao
                , pago: this.state.entrega.pago
                , cte: this.state.entrega.cte
                , tda: this.state.entrega.tda
                , nota_fiscal: this.state.entrega.nota_fiscal
                , status:2
                , operacao:1
            }
            this.setState({nova_entrega: nova_entrega});
            // console.log("nova_entrega");
            // console.log(nova_entrega);

            this.addEntrega(nova_entrega);
        }
    }; 
    //--------------------------------------
    addEntrega=(nova_entrega)=> {
        this.setState({salvando:false, bloqueado_salvar:false});
        this.setState({nova_entrega: nova_entrega});
        this.state.CadColetaModal.pushGridEntrega(nova_entrega);
        console.log("nova_entrega:");
        console.log(nova_entrega);
        this.fecharModal();
    }
    //---------------------------------------------------------------
    setCliente = (cliente) => {
        cliente.id = cliente.cliente_id;
        cliente.descricao2 = cliente.nome;
        this.setObjeto(cliente, ENTREGA_CLIENTE_TIPO);
    }
    //---------------------------------------------------------------
    showObservacao= (e) => {   
        this.props.Modals.showObservacaoModal(this, "Observação da Entrega", this.state.entrega.observacao, true, 'observacao');             
    }; 
    //---------------------------------------------------------------
    showCTE= (e) => {   
        this.props.Modals.showObservacaoModal(this, "CTE da Entrega", this.state.entrega.cte, true, 'cte');             
    }; 
    //--------------------
    showNotaFiscal= (e) => {   
        this.props.Modals.showObservacaoModal(this, "Nota Fiscal da Entrega", this.state.entrega.nota_fiscal, true, 'nota_fiscal');             
    };    
    //---------------------------------------------------------------
    confirmarObservacao(texto){
        let entrega = this.state.entrega;
        entrega.observacao = texto;

        // let editados = this.state.editados;
        // editados.observacao = true;

        // this.setState({entrega: entrega, editados: editados, changed: true});        
        this.setState({entrega: entrega, changed: true});  
    };
    //---------------------------------------------------------------
    confirmarCte(texto){
        let entrega = this.state.entrega;
        entrega.cte = texto;

        // let editados = this.state.editados;
        // editados.cte = true;

        // this.setState({coleta: coleta, editados: editados, changed: true});        
        this.setState({entrega: entrega, changed: true});  
    };
    //---------------------------------------------------------------
    confirmarNotaFiscal(texto){
        let entrega = this.state.entrega;
        entrega.nota_fiscal = texto;

        this.setState({entrega: entrega, changed: true});  
    };    
    //--------------------------------------
    handleSelect=(selectedKey)=> {
        this.setState({activeKey: selectedKey});
      }
    
    //---------------------------------------------
    render(){
        return(
            <div className="drag1_1">
                <Draggable  handle="strong"  >                
                    <div >
                        <Rodal
                            visible={this.props.show}
                            animation={this.state.animation}
                            showCloseButton={false}
                            duration={600}
                            width={580}
                            height={this.state.height_modal}
                            onAnimationEnd={()=>this.desativarModal()}
                            >

                            <div >
                                <strong className="cursor"><CabecalhoModal minimizar={false} maximizar={false}  titulo={this.props.titulo} Modal={this}/></strong>
                                <div className="padding_modal">
                                {this.state.loading ?
                                        <Spinner className="centro3" animation="border" role="status"/>
                                    :
                                        <div style={{height: "397px", overflow:'hidden'}}>
                                            <CustomScroll  flex="1">
                                                {/*------------------------------------ */}
                                                <Form>                                                     
                                                    <Form.Group as={Row} controlId="formEntregaID">
                                                        <Form.Label column sm={3}>
                                                            Entrega ID
                                                        </Form.Label>
                                                        <Col sm={3}>
                                                            <InputGroup className="mb-1" >
                                                                <Form.Control disabled aria-describedby="basic-addon1" value={this.state.entrega.entrega_id}   />                                                                         
                                                            </InputGroup>
                                                        </Col>
                                                    </Form.Group> 

                                                    <Form.Group as={Row} controlId="formClienteEntrega">                                                                
                                                        <Form.Label column sm={3}>
                                                                Cliente Entrega
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <InputGroup className="mb-1" >
                                                                    <InputGroup.Prepend >
                                                                        <Button  value={ENTREGA_CLIENTE_TIPO} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                            <Image value={ENTREGA_CLIENTE_TIPO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                        </Button>
                                                                    </InputGroup.Prepend>

                                                                    <Form.Control  aria-describedby="basic-addon1" value={this.state.entrega.cliente} / >                                                                         
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group> 

                                                        <Form.Group as={Row} controlId="formLocalEntrega">
                                                            <Form.Label column sm={3}>
                                                                Local Entrega
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <InputGroup className="mb-1" >
                                                                    <InputGroup.Prepend >
                                                                        <Button disabled value={ENTREGA_CLIENTE_LOCAL_TIPO} onClick={this.showGeralList} variant="outline-secondary" >                                                                                   
                                                                            <Image value={ENTREGA_CLIENTE_LOCAL_TIPO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                        </Button>
                                                                    </InputGroup.Prepend>

                                                                    <Form.Control disabled aria-describedby="basic-addon1" value={this.state.entrega.local}   />                                                                         

                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group> 

                                                        <Form.Group as={Row} controlId="formRotaEntrega">
                                                            <Form.Label column sm={3}>
                                                                Rota Entrega
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <InputGroup className="mb-1" >
                                                                    <InputGroup.Prepend >
                                                                        <Button  value={ENTREGA_ROTA_TIPO} onClick={this.showGeralList} variant="outline-secondary" >
                                                                            <Image value={ENTREGA_ROTA_TIPO} src={require('../img/text_box_search48.png')} width='20'/>
                                                                        </Button>
                                                                    </InputGroup.Prepend>

                                                                    <Form.Control  aria-describedby="basic-addon1" value={this.state.entrega.rota} >                                                                                     

                                                                    </Form.Control>
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group> 

                                                        <Form.Group as={Row} controlId="formPagamento">
                                                            <Form.Label column sm={3}>
                                                                Pagamento
                                                            </Form.Label>
                                                            <Col sm={6}>
                                                                <InputGroup className="mb-1" >
                                                                    <Form.Control as="select" aria-describedby="basic-addon1"  
                                                                        disabled={this.state.disable_pagamento}
                                                                        // defaultValue={this.state.tipo_pagamentos[1]} 
                                                                        value={this.state.entrega.tipo_pagamento_id} 
                                                                        custom onChange={this.setPagamento.bind(this)}>
                                                                                        <option value={null}>
                                                                                            {}
                                                                                        </option>                                                                       
                                                                                        {this.state.tipo_pagamentos.map(paga =>
                                                                                            <option value={paga.tipo_pagamento_id}>
                                                                                                {paga.descricao}
                                                                                            </option>
                                                                                        )}
                                                                        </Form.Control>
                                                                
                                                                    <Form.Label column sm={4} className="padding_left" > Pago </Form.Label>
                                                                    <Switch className="padding_top" disabled={this.state.disable_pago} defaultValue={this.state.entrega.pago} value={this.state.entrega.pago}  switchedOnText={"Sim"} switchedOffText={"Não"} onValueChanged={this.setPago}/>
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>                                                                

                                                        <Form.Group as={Row} controlId="formValor">
                                                            <Form.Label column sm={3}>
                                                                Frete R$
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <InputGroup className="mb-1" >
                                                                    <FormControl type="text" disabled={this.state.disable_valor}  aria-describedby="basic-addon1" value={this.state.entrega.valor}  onChange={this.setValorPagamento.bind(this)}/>
                                                                
                                                                    <Form.Label column sm={1.1} className="padding_left_right" > TDA </Form.Label>
                                                                    <FormControl type="text" disabled={this.state.disable_valor}  aria-describedby="basic-addon1" value={this.state.entrega.tda}  onChange={this.setTDA.bind(this)}/>
                                                                
                                                                    <Form.Label column sm={1.1} className="padding_left_right" >Total </Form.Label>
                                                                        <FormControl type="text" disabled  aria-describedby="basic-addon1" 
                                                                            value={this.somarFreteTDA(this.state.entrega.valor, this.state.entrega.tda) }  
                                                                            defaultValue={0} />

                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} controlId="formObservacao">
                                                            <Form.Label column sm={3}>
                                                                Observação
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <InputGroup className="mb-1" >
                                                                    <InputGroup.Prepend >
                                                                        <Button id="formObservacaoBt" onClick={this.showObservacao} variant="outline-secondary" >                                                                                   
                                                                            <Image  src={require('../img/pencil_48.png')} width='20'/>
                                                                        </Button>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control  aria-describedby="basic-addon1" disabled value={this.state.entrega.observacao} /> 
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group> 

                                                        <Form.Group as={Row} controlId="formCTE">
                                                            <Form.Label column sm={3}>
                                                                CTE
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <InputGroup className="mb-1" >
                                                                    <InputGroup.Prepend >
                                                                        <Button id="formCTEBt" onClick={this.showCTE} variant="outline-secondary" >                                                                                   
                                                                            <Image  src={require('../img/pencil_48.png')} width='20'/>
                                                                        </Button>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control  aria-describedby="basic-addon1" disabled value={this.state.entrega.cte} />
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} controlId="formNotaFiscal">
                                                            <Form.Label column sm={3}>
                                                                Nota Fiscal
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <InputGroup className="mb-1" >
                                                                    <InputGroup.Prepend >
                                                                        <Button id="formNotaFiscalBt" onClick={this.showNotaFiscal} variant="outline-secondary" >                                                                                   
                                                                            <Image  src={require('../img/pencil_48.png')} width='20'/>
                                                                        </Button>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control  aria-describedby="basic-addon1" disabled value={this.state.entrega.nota_fiscal} />
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>                                                          

                                                        <Form.Group as={Row} controlId="formPrevisaoEntrega">
                                                            <Form.Label column sm={3}>
                                                                Previsão
                                                            </Form.Label>
                                                            <Col sm={8}>                                                                    
                                                                <InputGroup className="mb-1" >
                                                                    <DateBox displayFormat="dd/MM/yyyy HH:mm"  width={200}
                                                                        className="headerDateTime"  type="datetime" 
                                                                        onValueChanged={this.setDataPrevisaoEntrega}
                                                                        // min={this.state.minDateValue}
                                                                        /> 
                                                                </InputGroup>
                                                            </Col>
                                                    </Form.Group>                                                                                                                                                         
                                                </Form>
                                                {/* ----------------------------------- */}
                                            </CustomScroll>


                                            <Modal.Footer >
                                                        {/* <div className="align_bottom_left">
                                                            <Button onClick={this.onVoltarClick}><img src={require('./img/arrow_left24.png')} title={"Voltar"}/></Button>
                                                        </div> */}
                                                            <div className="align_bottom_right_top">
                                                                <Button disabled={this.state.bloqueado_salvar} onClick={this.validar}>
                                                                    {this.state.salvando
                                                                        ? <Spinner as="span" animation="grow" role="status" aria-hidden="true"/>                                                                
                                                                    :
                                                                    <div>
                                                                        <img src={require('../img/checkbold24.png')}/> 
                                                                    </div>
                                                                    }                                                                
                                                                </Button>
                                                            </div> 
    

                                            </Modal.Footer>

                                        </div>
                                    }
                                </div>
                             </div>                            
                        </Rodal>
                    </div>
                </Draggable>        
            </div>        
        );
    }
}