import  {Component} from 'react';
import api from '../api';

export default class GetTipoGrupo extends Component{
    constructor(props, objeto, MapaKseg, tipo){
        super(props);
        this.state = {
             list: [],
             objeto: objeto,
             MapaKseg: MapaKseg,
             tipo: tipo
       }
    }
    //------------------------------------------------
    get = async () => {
        try {
            let body = new FormData()
            body.append('token', localStorage.getItem('token'))

            let response = response = await api.post('/cadastro_api/getTipoGrupo', body); 
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let obj = null;
                let list = this.state.list;                

                // ToastStore.success("resposta.length: "+ String(resposta.length));
                for (var i = 0; i < resposta.length; i++) {                        
                    obj = {    
                        index: i+1,
                        tipo_grupo_id: resposta[i].tipo_grupo_id,
                        descricao: resposta[i].descricao,             
                        operacao:0,                    
                    };                   

                    list.push(obj);
                    this.setState({list: list});
                }                
            }
        } finally {   
            this.state.objeto.setListTipoGrupo(this.state.list, this.state.tipo);
            }
    };
}