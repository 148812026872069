import  {Component} from 'react';
import api from '../api';

export default class GetUsuariosGrupo extends Component{
    constructor(props, objeto, MapaKseg, grupo_id){
        super(props);
        this.state = {
             list: [],
             objeto: objeto,
             MapaKseg: MapaKseg,
             grupo_id: grupo_id
       }
    }
    //------------------------------------------------
    get = async () => {
        try {
            let body = new FormData()
            body.append('grupo_id', this.state.grupo_id)
            body.append('token', localStorage.getItem('token'))

            let response = response = await api.post('/cadastro_api/getUsuariosGrupo', body); 
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let obj = null;
                let list = this.state.list;                

                // ToastStore.success("resposta.length: "+ String(resposta.length));
                for (var i = 0; i < resposta.length; i++) {                        
                    obj = {    
                        index: i+1,
                        usuario_grupo_id: resposta[i].usuario_grupo_id,
                        usuario_id: resposta[i].usuario_id,
                        nome: resposta[i].nome,
                        login: resposta[i].login,
                        ativo: resposta[i].ativo,
                        operacao:0,                    
                    };                   

                    list.push(obj);
                    this.setState({list: list});
                }                
            }
        } finally {   
            this.state.objeto.setListUsuarios(this.state.list);
            }
    };
}