const employees = [
  {
    'recebimento_id': 4106176,
    'Prefix': 'Mr.',
    'FirstName': 'Kevin',
    'LastName': 'Carter',
    'Position': 'Shipping Manager',
    'State': 'California',
    'BirthDate': '1978/01/09'
  },
  {
    'recebimento_id': 4107881,
    'Prefix': 'Ms.',
    'FirstName': 'Cynthia',
    'LastName': 'Stanwick',
    'Position': 'HR Assistant',
    'State': 'Arkansas',
    'BirthDate': '1985/06/05'
  },
  {
    'recebimento_id': 4108116	,
    'Prefix': 'Dr.',
    'FirstName': 'Kent',
    'LastName': 'Samuelson',
    'Position': 'Ombudsman',
    'State': 'Missouri',
    'BirthDate': '1972/09/11'
  }];
  
  const tasks = [{
    'recebimento_id': 4108116	,
    'Subject': 'Prepare 2013 Financial',
    'StartDate': '2013/01/15',
    'DueDate': '2013/01/31',
    'Status': 'Completed',
    'Priority': 'High',
    'Completion': 100,
    'EmployeeID': 8
  },
  {
    'recebimento_id': 4107881,
    'Subject': 'Prepare 3013 Marketing Plan',
    'StartDate': '2013/01/01',
    'DueDate': '2013/01/31',
    'Status': 'Completed',
    'Priority': 'High',
    'Completion': 100,
    'EmployeeID': 5
  },
  {
    'recebimento_id': 4106202,
    'Subject': 'Update Personnel Files',
    'StartDate': '2013/02/03',
    'DueDate': '2013/02/28',
    'Status': 'Completed',
    'Priority': 'High',
    'Completion': 100,
    'EmployeeID': 2
  },
  {
    'recebimento_id': 4106436,
    'Subject': 'Choose between PPO and HMO Health Plan',
    'StartDate': '2013/02/15',
    'DueDate': '2013/04/15',
    'Status': 'In Progress', 'Priority': 'High',
    'Completion': 75,
    'EmployeeID': 1
  },
  {
    'recebimento_id': 4107897,
    'Subject': 'Google AdWords Strategy',
    'StartDate': '2013/02/16',
    'DueDate': '2013/02/28',
    'Status': 'Completed',
    'Priority': 'High',
    'Completion': 100,
    'EmployeeID': 1
  },
  {
    'recebimento_id': 4108116,
    'Subject': 'Final Budget Review',
    'StartDate': '2014/03/26',
    'DueDate': '2014/03/27',
    'Status': 'In Progress',
    'Priority': 'High',
    'Completion': 25,
    'EmployeeID': 6
  }];
  
  export default {
    getEmployees() {
      return employees;
    },
    getTasks() {
      return tasks;
    }
  };

