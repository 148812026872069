import React, {Component} from 'react';
import {ToastStore} from 'react-toasts';
import {Polyline, Marker} from 'google-maps-react';


export class TrechoPosicao extends Component{
    constructor(props){
        super(props);
        this.state = {
            evento: {},
            list: [],
            loading:true,
            markerPosicao_list:[],
       }
    }
    //---------------------
    toastStore = (texto) => {
        ToastStore.success("teste: "+texto);
    }; 
    //--------------------------
    renderPolyline =  () => {
        // ToastStore.success("renderPolyline coordes.lenght: "+String(this.state.coords.lenght));
        return(
            <Polyline
                path={this.state.coords}
                strokeColor={this.state.polylineColor}
                strokeOpacity={1.8}
                strokeWeight={5} />                                
        )
    };  
    //--------------------------
    async componentDidMount() {
        // ToastStore.success("getPosicoesVeiculo componentDidMount()");
        this.toastStore(String(this.props.list.length))
    };
    //--------------------------------      
    render() {
        var marcador="";
        var marker="";
        return (           
                <div>
                {this.props.list.map(posVeiculo => ( 
                    <Marker 
                      key={posVeiculo.recebimento_id}
                      // title={posVeiculo.recebimento_id}
                      // title={"  lat: "+String(posVeiculo.latitude)+ "  lng: "+String(posVeiculo.longitude)}
                      title={String(posVeiculo.data_receb)}
                      // title={"  lat: "+String(posVeiculo.latitude)+ "  lng: "+String(posVeiculo.longitude)+ "\n    "+String(posVeiculo.data_receb)}
                      // name={posVeiculo.direcao}
                      name={posVeiculo.recebimento_id}
                      position={{lat: posVeiculo.latitude, lng: posVeiculo.longitude}}
                      // onClick={this.toastss(posVeiculo.direcao)}
                      onClick={this.onMarkerPosicaoClick}
    
                      {...posVeiculo.direcao===0 ? marcador=require('./img/point_position.png') : marcador=require('./img/point_position4_3.png')}
                      {...posVeiculo.backup ? marcador=require('./img/point_backup_arrow.png') : ""}
                      // {...posVeiculo.tipo_recebimento_id===6 ? marcador=require('./img/point_position_red_arrow.png') : ""}
                      {...posVeiculo.tipo_evento_id===1 ? 
                          posVeiculo.direcao===0 ?
                            marcador=require('./img/point_position_red2.png') 
                          : marcador=require('./img/point_position_red_arrow.png') 
                        : ""}
    
                        
                      {...posVeiculo.tipo_evento_id===68 ? 
                        posVeiculo.direcao===0 ?
                          marcador=require('./img/point_position_yellow.png') 
                        : marcador=require('./img/point_position_yellow_arrow.png') 
                      : ""}  
    
                      {...marker=
                        'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" '
                      +' height="20px" width="20px" > ' 
                      +' <g transform="rotate('+posVeiculo.direcao+' 10 10)"  >'
                      +'  <image xlink:href="'+marcador+'"  />   '  
                      +' </g >'
                      +''                                   
                      +''
                      + '   </svg> '
                    }
                    
                    icon={{url: marker
                        ,anchor: new this.props.google.maps.Point(10,10)}}
                      
                      />
                    ))
                  }
                  </div>             

                
        )
    }
}
