import React from "react";
import { isAuthenticated } from './auth';
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import MapaKseg from './mapaKseg';
import LoginQrcode from './loginQrcode';

class Routes2 extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            autenticado:false,
        };
      }
    //--------------   
    sleep (milliseconds) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
      }
    //---------------
    isAuth = async() => {
        console.log("isAuth Autenticado: "+localStorage.getItem('autenticado'))        
    
        if(parseInt(localStorage.getItem('autenticado'))===0){
            for (var i=0; i < 20; i++) {
                console.log("i:"+i);
                // await this.sleep(5000)
                isAuthenticated();
                if(localStorage.getItem('autenticado') !== null){
                    console.log("isAuth Autenticado: "+localStorage.getItem('autenticado'))
                    console.log("state1 Autenticado: "+this.state.autenticado)
                    // this.setState({autenticado:true});
                    if (parseInt(localStorage.getItem('autenticado'))===1){
                        this.setState({autenticado:true});
                        console.log("state2 Autenticado: "+this.state.autenticado)                    
                        return true
                        // return(this.Routes())
                    }
                    //  else return false    
                }        
                await this.sleep(3000)
            }
        }
        return false;
    };
      
    //-------------- 
    PrivateRoute = ({ component: Component, ...rest}) => ( // rest operator
        <Route 
        
            {...rest} 
            render={props =>this.state.autenticado ? (
            // render={props =>this.isAuth()? (
            // render={props =>()=>this.isAuth()? (
                    // <Component  {...props }  /> 

                    <Redirect to={{pathname: "/app", state: {from: ""}}}/>

                    // <Component  {...props }  >
                    //     {/* {this.isAuth()} */}
                    // </Component>

                    // this.props.history.push('/app')
                ) : (                    
                    <Redirect to={{pathname: "/login", state: {from: props.location}}}>
                        {this.isAuth()}
                    </Redirect>
                    // <Component  {...props }  >
                    //     {this.isAuth()}
                    // </Component>
                    
                )        
            } 
        />
    );
    //-------------- 
    Routes = () =>(
        <BrowserRouter>
            <Switch>
                <Route exact path="/login" component={() => <LoginQrcode/>} />
                {/* <Route exact path="/" component={this.isAuth()} /> */}
                <Route  exact path="/app" component={() => <MapaKseg/>} />
                {/* <Route exact path="/" component={() => <LoginQrcode/>} /> */}            
                <this.PrivateRoute   path="/" component={() => <MapaKseg/>}/>
            </Switch>
        </BrowserRouter>
    );
    //-------------- 
    render(){
        // return this.state.rota
        return(
            <div>
                {localStorage.setItem('autenticado', 0)}
                {/* {this.isAuth()} */}
                {/* {()=>this.isAuth()} */}
                {/* {isAuth()} */}
                {/* {this.state.autenticado ? this.Routes(): this.isAuth()} */}
                {this.state.autenticado ? <MapaKseg keey={"fuuu"}/> : this.Routes()}
                {/* {this.state.autenticado ? this.Routes() : this.Routes()} */}
                {/* {this.state.autenticado ? this.Routes(): ()=>isAuth()} */}
            </div>
        )
    }
}

export default Routes2;
