import React from 'react';
import DataGrid, { Column, FilterRow, HeaderFilter, SearchPanel, Export, ColumnChooser, FieldChooser } from 'devextreme-react/data-grid';
import { SelectBox, CheckBox, Button } from 'devextreme-react';
import service from './data_datagrid.js';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Container, Row,Col } from 'react-bootstrap';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
const dataGridRef = React.createRef();


const saleAmountEditorOptions = { format: 'currency', showClearButton: true };

export class DataGridTemperatura extends React.Component {
  constructor(props) {
    super(props);
    this.orders = service.getOrders();
    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately'
    }, {
      key: 'onClick',
      name: 'On Button Click'
    }];
    this.saleAmountHeaderFilter = [{
      text: 'Less than $3000',
      value: ['SaleAmount', '<', 3000]
    }, {
      text: '$3000 - $5000',
      value: [
        ['SaleAmount', '>=', 3000],
        ['SaleAmount', '<', 5000]
      ]
    }, {
      text: '$5000 - $10000',
      value: [
        ['SaleAmount', '>=', 5000],
        ['SaleAmount', '<', 10000]
      ]
    }, {
      text: '$10000 - $20000',
      value: [
        ['SaleAmount', '>=', 10000],
        ['SaleAmount', '<', 20000]
      ]
    }, {
      text: 'Greater than $20000',
      value: ['SaleAmount', '>=', 20000]
    }];
    this.state = {
      showFilterRow: true,
      showHeaderFilter: true,
      currentFilter: this.applyFilterTypes[0].key,
      focusedRowKey: 1,
    };
    this.dataGrid = null;
    this.orderHeaderFilter = this.orderHeaderFilter.bind(this);
    this.onShowFilterRowChanged = this.onShowFilterRowChanged.bind(this);
    this.onShowHeaderFilterChanged = this.onShowHeaderFilterChanged.bind(this);
    this.onCurrentFilterChanged = this.onCurrentFilterChanged.bind(this);
    this.onTaskIdChanged = this.onTaskIdChanged.bind(this);
    // this.onExporting = this.onExporting.bind(this);
  }
  //-----------------
  onTaskIdChanged(e) {
    if(e.event && e.value > 0) {
      this.setState({ focusedRowKey: e.value });
    }
  }
  //--------------------------------------------------------
  cloneIconClick(e) {
    // this.props.RelPosicoesVeiModal.minimizarModal();
    // var veiculoPosicao = {latitude: e.row.data.latitude, longitude: e.row.data.longitude
    //         ,tipo_recebimento_id:1
    //         ,data_receb: e.row.data.data_receb
    //         ,velocidade: e.row.data.velocidade};
    // this.props.MapaKseg.setInfoWindow(veiculoPosicao);
    // this.props.MapaKseg.ajustarZoom(veiculoPosicao);
  }
  //-----------------
  exportGrid=()=> {
    this.setState({icone_visible: !this.state.icone_visible});
    const doc = new jsPDF();
    const dataGrid = dataGridRef.current.instance;
  
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: dataGrid
    }).then(() => {
      doc.save(this.props.placa+'.pdf');
      this.setState({icone_visible: !this.state.icone_visible});
    });    
  }

  //-----------------------
  get gridContainer() {
    return dataGridRef.current.instance;
  }
  //----------------
  exportDataGridToxlsx = () =>  {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    const context = this;


    exportDataGrid({
      // component: e.component,
      component: context.gridContainer,
      worksheet: worksheet
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), this.props.placa+'.xlsx');
      });
    });
    // e.cancel = true;
  }
  //-----------------
  render() {
    return (
      <div className="wrap">

        <DataGrid id="gridContainer"
          ref={dataGridRef}
          // ref={(ref) => this.dataGrid = ref}
        //   dataSource={this.orders}
          dataSource={this.props.orders}
          showBorders={true}
          focusedRowEnabled={false}
          keyExpr="recebimento_id"
          // selection={{ mode: 'single' }}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          columnResizingMode={"nextColumn"}
          // focusedRowKey={2737879}
          // focusedRowKey={this.state.focusedRowKey}
          // onExporting={this.onExporting}
          >
            
          {/* <Export enabled={true} /> */}

          <FilterRow visible={this.state.showFilterRow}
            applyFilter={this.state.currentFilter} />
          <HeaderFilter visible={this.state.showHeaderFilter} />
          <SearchPanel visible={false}
            width={240}
            placeholder="Pesquisar..." />
             

          <ColumnChooser enabled={true} title="Escolha a Coluna"/>

          <Column dataField="id" caption="ID" alignment="left" width={50}/>

          <Column dataField="recebimento_id"
            caption="Posição id"
            alignment="left"
            visible={false}>
            <HeaderFilter groupInterval={10000} />
          </Column>

          <Column dataField="placa"
            caption="Placa"
            >
            <HeaderFilter allowSearch={true} />
          </Column>

          <Column dataField="municipio"
            caption="Município"
            >
            <HeaderFilter allowSearch={true} />
          </Column>

          <Column dataField="estado"
            caption="Estado"
            alignment="left"
            >
            <HeaderFilter allowSearch={true} />
          </Column>

          <Column dataField="pais"
            caption="País"
            alignment="left"
            >
            <HeaderFilter allowSearch={true} />
          </Column>

          <Column dataField="velocidade"
            caption="Km/h"
            alignment="left"
            format="###"
            >
            <HeaderFilter groupInterval={100} />
          </Column>

          {/* <Column dataField="ignicao" 
            caption="Ignição ThermoKing"
            width={80}>
            <HeaderFilter allowSearch={true} />
          </Column> */}
 
          <Column dataField="data_format"
            alignment="right"
            // dataType="datetime"
            // format="M/d/yyyy, HH:mm"
            caption="Data"
            width={160} />

          <Column dataField="data_receb"
            alignment="right"
            // dataType="datetime"
            // format="M/d/yyyy, HH:mm"
            caption="Data"
            width={160}
            visible={false} />

          <Column dataField="sensor1"
            caption="Sensor 1"
            alignment="left"
            visible={true}>
            <HeaderFilter groupInterval={10000} />
          </Column>

          <Column dataField="sensor2"
            caption="Sensor 2"
            alignment="left"
            visible={true}>
            <HeaderFilter groupInterval={10000} />
          </Column>

          <Column dataField="sensor3"
            caption="Sensor 3"
            alignment="left"
            visible={true}>
            <HeaderFilter groupInterval={10000} />
          </Column>

          <Column dataField="sensores"
            alignment="right"
            caption="Temperatura"
            visible={false}>
            <HeaderFilter allowSearch={true} />
          </Column>

          <Column dataField="latlng"
            alignment="right"
            caption="lat, lng"  
            editorOptions={saleAmountEditorOptions}
            visible={false}>
            <HeaderFilter dataSource={this.saleAmountHeaderFilter} />
          </Column>


          <Column type="buttons" width={90}
            caption="Ver no mapa" 
            buttons={['edit', 'delete', {
              hint: 'ver',
              icon: require("./img/location_marker20.png"),
              visible: this.isCloneIconVisible,
              onClick: this.cloneIconClick
            }]} />

          <Column dataField="backup" 
            caption="Backup">
            <HeaderFilter allowSearch={true} />
          </Column>

        </DataGrid>

      {/* -----------Row---------------- */}
        <Container className="button-container">
          <Row >
            <Col md={1} bsPrefix={"espaco_right"}>
              <Button
                id='exportButton'
                icon='exportpdf'
                text='PDF'
                onClick={this.exportGrid}                
              />
            </Col>            
            {this.props.MapaKseg.state.pessoa_id===1 ?
              <Col md={2} >
                <Button
                  id='exportButton2'
                  icon='exportxlsx'
                  text='xlsx'
                  onClick={this.exportDataGridToxlsx}                
                />
              </Col>  
            :""}            
          </Row>
        </Container>


        

        

      </div>
    );
  }
  calculateFilterExpression(value, selectedFilterOperations, target) {
    let column = this;
    if (target === 'headerFilter' && value === 'weekends') {
      return [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]];
    }
    return column.defaultCalculateFilterExpression.apply(this, arguments);
  }
  orderHeaderFilter(data) {
    data.dataSource.postProcess = (results) => {
      results.push({
        text: 'Weekends',
        value: 'weekends'
      });
      return results;
    };
  }
  onShowFilterRowChanged(e) {
    this.setState({
      showFilterRow: e.value
    });
    this.clearFilter();
  }
  onShowHeaderFilterChanged(e) {
    this.setState({
      showHeaderFilter: e.value
    });
    this.clearFilter();
  }
  onCurrentFilterChanged(e) {
    this.setState({
      currentFilter: e.value
    });
  }
  clearFilter() {
    this.dataGrid.instance.clearFilter();
  }
}

function getOrderDay(rowData) {
  return (new Date(rowData.OrderDate)).getDay();
}

// export default DtGrid;