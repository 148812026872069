import  {Component} from 'react';
import api from '../api';

export default class GetPessoa extends Component{
    constructor(props, objeto, MapaKseg, tipo, pessoa_id){
        super(props);
        this.state = {
             list: [],
             objeto: objeto,
             MapaKseg: MapaKseg,
             tipo:tipo,
             pessoa_id:pessoa_id,
       }
    }
    //------------------------------------------------
    get = async () => {
        try {
            let body = new FormData()

            if(this.state.pessoa_id !== 0)
                body.append('pessoa_id', this.state.pessoa_id)

            body.append('token', localStorage.getItem('token'))

            const response = await api.post('/cadastro_api/getPessoa', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let obj = null;
                let list = this.state.list;                
                
                for (var i = 0; i < resposta.length; i++) {                        
                    obj = {    
                        index: i+1,
                        pessoa_id: resposta[i].pessoa_id,                        
                        nome_social: resposta[i].nome_social,
                        nome_fantasia: resposta[i].nome_fantasia,
                        pessoa_juridica: resposta[i].pessoa_juridica,
                        cnpj: resposta[i].cnpj,
                        cpf: resposta[i].cpf,
                        operacao:0
                    };                   

                    list.push(obj);
                    this.setState({list: list});
                }                
            }
        } finally {      
            this.state.objeto.setListPessoa(this.state.list, this.state.tipo);                  
            }
    };
}