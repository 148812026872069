import  {Component} from 'react';
import api from '../api';

export default class GetMunicipio extends Component{
    constructor(props, objeto, MapaKseg,tipo, municipio_id){
        super(props);
        this.state = {
             list: [],
             objeto: objeto,
             MapaKseg: MapaKseg,
             tipo:tipo,
             municipio_id: municipio_id,
       }
    }
    //------------------------------------------------
    get = async () => {
        try {
            let body = new FormData()
            if(this.state.municipio_id !==0) 
                body.append('municipio_id', this.state.municipio_id);
            body.append('token', localStorage.getItem('token'))

            const response = await api.post('/cadastro_api/getMunicipio', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let obj = null;
                let list = this.state.list;                
                
                for (var i = 0; i < resposta.length; i++) {                        
                    obj = {    
                        index: i+1,
                        municipio_id: resposta[i].municipio_id,                        
                        descricao: resposta[i].descricao,
                        codigo: resposta[i].codigo,
                        estado_id: resposta[i].estado_id,
                        estado: resposta[i].estado,
                        local_id: resposta[i].local_id,
                        local: resposta[i].local,
                        tda: resposta[i].tda,
                        operacao:0,
                    };                   

                    list.push(obj);
                    this.setState({list: list});
                }                
            }
        } finally {      
            // this.state.objeto.setState({list: this.state.list});
            // this.state.objeto.setState({bloqueado_consultar:false, load: false});
            this.state.objeto.setListMunicipio(this.state.list, this.state.tipo);
            // this.state.MapaKseg.toast(this.state.list.length);
            }
    };
}