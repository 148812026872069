import  {Component} from 'react';
import api from '../api';

export default class PostFilialCad extends Component{
    constructor(props, objeto, MapaKseg ){
        super(props);
        this.state = {
             objeto: objeto,
             MapaKseg: MapaKseg
       }
    }
    //------------------------------------------------
    post = async () => {
        let filial_idd =0;
        try {                    
            let editados = this.state.objeto.state.editados;
            let filial = this.state.objeto.state.filial;
            filial_idd = filial.filial_id;
            let grupo = this.state.objeto.state.grupo;

            let response = null;
            let responseJson = null;
            let body =null;            

            if(editados.descricao || editados.municipio || editados.pessoa || editados.comissao){

                body = new FormData();
                body.append('token', localStorage.getItem('token'));
                body.append('filial_id', filial.filial_id);

                if(editados.descricao) 
                    body.append('descricao', filial.descricao);
                if(editados.municipio) 
                    body.append('municipio_id', filial.municipio_id);
                if(editados.pessoa) 
                    body.append('pessoa_id', filial.pessoa_id);  
                if(editados.comissao) 
                    body.append('comissao', filial.comissao);          

                response = await api.post('/cadastro_api/postFilial', body);

                if (response.data !== null  &&  response.status !== 403){
                    responseJson = JSON.stringify(response.data)
                    var req = { json: responseJson };
                    var resposta = JSON.parse(req.json); 
                    filial_idd = resposta.filial_id; 
                }

            }

            if(filial_idd != 0){
                if(editados.usuarios==true ){
                    let usuarios = grupo.usuarios.filter((grupo,index) =>{ return grupo.operacao !== 0;});            

                    if(usuarios.length >0){
                        usuarios.sort((a,b) => a.operacao - b.operacao);
                        let list_json =[];

                        for (var i = 0; i < usuarios.length; i++) {   
                            list_json.push(JSON.stringify({
                                usuario_id: usuarios[i].usuario_id
                                , filial_id: filial_idd
                                , operacao: usuarios[i].operacao }));
                        }

                        body = new FormData();
                        body.append('token', localStorage.getItem('token'));
                        body.append('list_json', list_json);

                        response = await api.post('/cadastro_api/postFilialUsuarios', body);

                        if (response.data !== null  &&  response.status !== 403){
                        responseJson = JSON.stringify(response.data)
                        var req = { json: responseJson };
                        var resposta = JSON.parse(req.json); 
                            // console.log(resposta.poligono_id);
                            // var coleta_id = resposta.coleta_id;    
                            // nova_coleta.coleta_id = resposta.resposta; 
                        // this.state.MapaKseg.toast(" "+String(resposta.resposta ));

                        }
                    }
                } 

                // if(editados.rotas==true ){
                //     let rotas = grupo.rotas.filter((grupo,index) =>{ return grupo.operacao !== 0;});            

                //     if(rotas.length >0){
                //         rotas.sort((a,b) => a.operacao - b.operacao);
                //         let list_json =[];

                //         for (var i = 0; i < rotas.length; i++) {   
                //             list_json.push(JSON.stringify({
                //                 rota_id: rotas[i].rota_id
                //                 , filial_id: filial_idd
                //                 , operacao: rotas[i].operacao }));
                //         }

                //         body = new FormData();
                //         body.append('token', localStorage.getItem('token'));
                //         body.append('list_json', list_json);

                //         response = await api.post('/cadastro_api/postFilialRotas', body);

                //         if (response.data !== null  &&  response.status !== 403){
                //         responseJson = JSON.stringify(response.data)
                //         var req = { json: responseJson };
                //         var resposta = JSON.parse(req.json); 
                //         }
                //     }
                // } 
            }


             

        } finally {      
            let filial = this.state.objeto.state.filial;
            filial.filial_id = filial_idd;
            this.state.objeto.setState({ filial: filial   },
               () => { this.state.objeto.concluido()  });  

            }
    };
}