import  {Component} from 'react';
import api from '../api';

export default class GetEstados extends Component{
    constructor(props, objeto, MapaKseg, tipo, estado_id){
        super(props);
        this.state = {
             list: [],
             objeto: objeto,
             MapaKseg: MapaKseg,
             tipo: tipo,
             estado_id: estado_id,
       }
    }
    //------------------------------------------------
    get = async () => {
        try {
            let body = new FormData()
            if(this.state.estado_id !==0) 
                body.append('estado_id', this.state.estado_id);
            body.append('token', localStorage.getItem('token'))

            const response = await api.post('/cadastro_api/getEstado', body);
            
            var responseJson = null

            if (response.data !== null  &&  response.status !== 403){
                responseJson = JSON.stringify(response.data)
                var req = { json: responseJson };
                var resposta = JSON.parse(req.json);
                
                let obj = null;
                let list = this.state.list;                
                
                for (var i = 0; i < resposta.length; i++) {                        
                    obj = {    
                        index: i+1,
                        estado_id: resposta[i].estado_id,                        
                        descricao: resposta[i].descricao,                        
                        sigla: resposta[i].sigla,
                        local_id: resposta[i].local_id,
                        pais_id: resposta[i].pais_id,
                        operacao:0
                    };                   

                    list.push(obj);
                    this.setState({list: list});
                }                
            }
        } finally {      
            this.state.objeto.setListEstado(this.state.list, this.state.tipo);
            }
    };
}