import { create } from 'apisauce';
import { ToastStore} from 'react-toasts';

// notify = (texto) => ToastStore.success(texto);
//-----------------
const api = create({
    // baseURL: 'https://cpro41800.publiccloud.com.br',
    baseURL: 'https://kseg.net.br',
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept": "application/json"
      },
    // login: ':5000/login'
});

api.addResponseTransform( response => {
  // if (!response.ok) ToastStore.error(" : "+String(response.status));
});

export default api;
